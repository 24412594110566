import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { convertToPascal, displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { restaurantListRequest, updateBranchRequest, updateBranchRequestStatus, countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import Select from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom';
import notificationTone from "../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../redux/socket/socket.action'
import CommonButton from "../../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import { CurrencyLabel } from "../../../../utils/CurrencyLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import ButtonRow from "../../../../components/button/button-row/buttonRow";

const EditLocationScreen = props => {
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [name, setName] = useState('')
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError,
        updateBranchSuccess, updateBranchError, updateBranchStatus, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { state } = useLocation()
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [paymentModeList, setPaymentModeList] = useState(null)
    const [paymentMode, setPaymentMode] = useState('')
    const [paymentModeAccountId, setPaymentModeAccountId] = useState('')
    let navigate = useNavigate()

    const [allPaymentMode, setAllPaymentMode] = useState(null)

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        const data = {
            screen: 'EDIT_LOCATION',
            screenClass: 'EDIT_LOCATION'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        dispatch(countryListRequest())
        if (isLoggedInUserBusiness(user) === true) {
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(restaurantListRequest(data))
        }
        if (!isEmpty(state.currencyLabel)) {
            const currencyInfo = CurrencyLabel.find(e => e.code === state.currencyLabel)
            console.log('currencyInfo', currencyInfo)
            if (!isNull(currencyInfo))
                setSelectedCurrency(currencyInfo)
        }
        setName(state.name)
        if (!isNull(state.orderPaymentModes))
            setPaymentModeList(state.orderPaymentModes)


    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess) && !isNull(state)) {
            const selRest = restuarantListSuccess.data.find(rest => rest.businessId === state.businessId)
            setSelectedRestaurant(selRest)
        }
    }, [restuarantListSuccess])

    useEffect(() => {
        findAvailablePaymentModes()
    }, [selectedRestaurant])

    const findAvailablePaymentModes = () => {
        if (!isNull(selectedRestaurant) && !isEmpty(paymentModeList) && !isNull(state)) {
            let nonSelectedPaymentModes = selectedRestaurant.orderPaymentModes.filter(obj1 => !paymentModeList.some(obj2 => obj1.gatewayType === obj2.gatewayType))
            setAllPaymentMode(nonSelectedPaymentModes)
        }
    }

    useEffect(() => {
        if (!isNull(countryListSuccess) && !isEmpty(state.country)) {
            if (!isNull(state)) {
                const selCount = countryListSuccess.find(rest => rest.name === state.country)
                setCountryDropdown(selCount)
            }
        }
    }, [countryListSuccess])

    useEffect(() => {
        if (!isNull(stateListSuccess) && !isEmpty(state.state)) {
            if (!isNull(state)) {
                const selState = stateListSuccess.find(rest => rest.name === state.state)
                setStateDropdown(selState)
            }
        }
    }, [stateListSuccess])

    useEffect(() => {
        if (!isNull(cityListSuccess) && !isEmpty(state.city)) {
            if (!isNull(state)) {
                const selRest = cityListSuccess.find(rest => rest.name === state.city)
                setCityDropdown(selRest)
            }
        }
    }, [cityListSuccess])

    const fetchStateList = (countryId) => {
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryDropdown)) {
            setCountryCode(countryDropdown.iso2)
            fetchStateList(countryDropdown.iso2)
        }
    }, [countryDropdown])

    useEffect(() => {
        if (!isNull(stateDropdown)) {
            fetchCityList(countryCode, stateDropdown.iso2)
        }
    }, [stateDropdown])

    useEffect(() => {
        if (updateBranchStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateBranchSuccess.message)
            navigate(-1)
        } else if (updateBranchStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateBranchError.message)
        }

        if (updateBranchStatus.length) {
            dispatch(updateBranchRequestStatus(''))
        }
    }, [updateBranchSuccess, updateBranchError, updateBranchStatus])

    const clearFields = () => {
        setName('')
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Location name cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Select restaurant')
        } else if (isNull(paymentModeList)) {
            displayErrorToast('Payment mode list cannot be empty')
        } else {
            setLoading(true)
            const data = {
                name: name,
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                locationId: state.locationId,
                orderPaymentModes: paymentModeList
            }
            if (!isNull(countryDropdown)) {
                data.country = countryDropdown.name
            }
            if (!isNull(stateDropdown)) {
                data.state = stateDropdown.name
            }
            if (!isNull(cityDropdown)) {
                data.city = cityDropdown.name
            }
            if (!isNull(selectedCurrency)) {
                data.currencyLabel = selectedCurrency.code
                data.currencySymbol = selectedCurrency.symbol
                data.currencyRegion = selectedCurrency.region
            }
            dispatch(updateBranchRequest(data))
        }
    }

    const handleRemovePaymentMode = item => {
        let paymentModes = paymentModeList.filter(e => e.gatewayType !== item.gatewayType)
        setPaymentModeList(paymentModes)
    }

    const handleAddPaymentMode = item => {
        let currentPaymentModes = paymentModeList
        currentPaymentModes.push(item)
        setPaymentModeList(currentPaymentModes)
        findAvailablePaymentModes()
    }

    useEffect(() => {
        if (!isEmpty(paymentModeList)) {
            findAvailablePaymentModes()
        }
    }, [paymentModeList])

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Update Details' />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Enter details to update location details' />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Location Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        isRequired={true}
                    />
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div>No Restaurant found</div>}
                    <FormFieldLabel className='up-bold-label' label='Currency Information' />
                    {CurrencyLabel && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedCurrency}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setSelectedCurrency(e)
                        }} />}
                    <FormFieldLabel className='up-bold-label' label='Available Payment Modes' />
                    {paymentModeList && <div className="eloc-payment-mode-row-container">{
                        paymentModeList.map(e => (
                            <div className="eloc-payment-mode-n-item">
                                <div>{e.label ? e.label : convertToPascal(e.gatewayType)}</div>
                                <div className="eloc-payment-img-container">
                                    <img src={require('../../../../assets/close.png')} alt="close" className="eloc-img" onClick={() => { handleRemovePaymentMode(e) }} />
                                </div>
                            </div>
                        ))
                    }</div>}
                    {allPaymentMode && allPaymentMode.length > 0 && <div className="eloc-field-text-label">Available Payment Modes</div>}
                    {allPaymentMode && allPaymentMode.length > 0 && <div className="eloc-payment-mode-row-container">{
                        allPaymentMode.map(e => (
                            <div className="eloc-payment-mode-n-item">
                                <div>{e.label ? e.label : convertToPascal(e.gatewayType)}</div>
                                <div className="eloc-payment-img-container">
                                    <img src={require('../../../../assets/plus.png')} alt="plus" className="eloc-img" onClick={() => { handleAddPaymentMode(e) }} />
                                </div>
                            </div>
                        ))
                    }</div>}
                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    {countryListSuccess && <Select
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e);
                        }} />}
                    {stateListSuccess && <Select
                        className='select-full-width-no-border'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);

                        }} />}
                    {cityListSuccess && <Select
                        className='select-full-width-no-border'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                    <ButtonRow>
                        <CommonButton label='Update'
                            onClick={() => submitHandler()} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EditLocationScreen