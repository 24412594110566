import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addTerminalRequest, addTerminalRequestStatus } from '../../../../redux/delivery-terminal/delivery-terminal.action'
import { API_CONSTANTS } from "../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";

const AddDeliveryTerminalScreen = props => {
    const [name, setName] = useState('')
    const [terminalCode, setTerminalCode] = useState('')
    const [passcode, setPasscode] = useState('')
    const [confirmPasscode, setConfirmPasscode] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError } = useSelector(state => state.user)
    const { addTerminalSuccess, addTerminalError, addTerminalStatus } = useSelector(state => state.deliveryTerminal)
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()

    useEffect(() => {
        const data = {
            screen: 'ADD_DELIVERY_TERMINAL',
            screenClass: 'ADD_DELIVERY_TERMINAL'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        setLoading(true)
        const data1 = {
            userId: user.data.userDetails.userId
        }
        dispatch(restaurantListRequest(data1))
    }, [])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(restuarantListSuccess)) {
            setLoading(false)
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        } else if (!isNull(branchListSuccess)) {
            setLoading(false)
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError])

    useEffect(() => {
        if (addTerminalStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addTerminalSuccess.message)
            clearFields()
        } else if (addTerminalStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addTerminalError.message)
        }

        if (addTerminalStatus.length) {
            dispatch(addTerminalRequestStatus(''))
        }
    }, [addTerminalSuccess, addTerminalError, addTerminalStatus])

    const clearFields = () => {
        setName('')
        setTerminalCode('')
        setPasscode('')
        setConfirmPasscode('')
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Terminal name cannot be blank')
        } else if (isEmpty(terminalCode)) {
            displayErrorToast('Username cannot be blank')
        } else if (isEmpty(passcode)) {
            displayErrorToast('Password cannot be blank')
        } else if (isEmpty(confirmPasscode)) {
            displayErrorToast('Confirm password cannot be blank')
        } else if (passcode !== confirmPasscode) {
            displayErrorToast('Password and confirm password does not match')
        } else if (isEmpty(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isEmpty(selectedLocation)) {
            displayErrorToast('Restayrant location not selected')
        } else {
            setLoading(true)
            const data = {
                title: name,
                terminalCode: terminalCode,
                passCode: passcode,
                businessId: selectedRestaurant.businessId,
                locationId: selectedLocation.locationId,
                productCode: user.data.userDetails.productCode
            }
            dispatch(addTerminalRequest(data))
        }
    }

    return (
        <>
            <div className="adt-container">
                <ScreenLabel label='Add Delivery Terminal' />
                <CommonForm>
                    <SubTextLabel label='Enter details to add new delivery terminal' />
                    <div className="form-floating adt-input">
                        <input
                            type="name"
                            className="form-control adt-input-text"
                            id="name"
                            placeholder="Terminal Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required />
                        <label for="name">Terminal Name</label>
                    </div>
                    <div className="form-floating adt-input">
                        <input
                            type="name"
                            className="form-control adt-input-text"
                            id="name"
                            placeholder="Username"
                            value={terminalCode}
                            onChange={e => setTerminalCode(e.target.value)}
                            required />
                        <label for="name">Username</label>
                    </div>
                    <div className="form-floating adt-input">
                        <input
                            type="password"
                            className="form-control adt-input-text"
                            id="name"
                            placeholder="Password"
                            value={passcode}
                            onChange={e => setPasscode(e.target.value)}
                            required />
                        <label for="name">Password</label>
                    </div>
                    <div className="form-floating adt-input">
                        <input
                            type="name"
                            className="form-control adt-input-text"
                            id="name"
                            placeholder="Confirm Password"
                            value={confirmPasscode}
                            onChange={e => setConfirmPasscode(e.target.value)}
                            required />
                        <label for="name">Confirm Password</label>
                    </div>
                    <div className="adt-select-heading">Select Restaurant</div>
                    {restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="top"
                        className='adt-select'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {!restuarantListSuccess && <div className="adt-text">No restaurant found</div>}
                    <div className="adt-select-heading mt-2">Select Location</div>
                    {branchListSuccess && branchListSuccess.data && <Select
                        menuPlacement="top"
                        className='adt-select'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {!branchListSuccess && <div className="adt-text">No location found</div>}
                    <div className='adt-button-row'>
                        <button type='button' className='btn adt-button' onClick={() => submitHandler()}>Add Terminal</button>
                    </div>

                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddDeliveryTerminalScreen