import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog, displayCurrencySymbolLeft,
    displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, isEmpty, isNull, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { productListRequest } from '../../../redux/product/product.action'
import { config } from '../../../config'
import { addItemInCart, displayAddCartBannerAct } from '../../../redux/cart/cart.action'
import Modal from 'react-modal';
import ReactStars from "react-rating-stars-component";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import { PRODUCT_MEASUREMENT_UNIT_LIST } from "../../../utils/ReservationType";
import CommonButton from "../../../components/button/common-button/commonButton";

const MenuProduct = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const { restaurantDetailSuccess } = useSelector(state => state.user)
    const { productListSuccess, productListError } = useSelector(state => state.product)
    const { state } = useLocation()
    const [allProduct, setAllProduct] = useState(true)
    const [isBreakfast, setIsBreakfast] = useState(false)
    const [isLunch, setIsLunch] = useState(false)
    const [isDinner, setIsDinner] = useState(false)
    const [is24Hour, setIs24Hour] = useState(false)
    const [showReview, setShowReview] = useState(false)
    let navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState()
    const [showOptionPriceModal, setShowOptionPriceModal] = useState(false)

    // useEffect(() => {
    //     dispatch({ type: APP_CONSTANTS.PRODUCT_CLEAR_DATA })
    //     // fetchProductList()
    // }, [])

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_MENU_PRODUCT',
            screenClass: 'CUSTOMER_MENU_PRODUCT'
        }
        screenCaptureAnalytics(data)
    }, [])

    const fetchProductList = () => {
        dispatch({ type: APP_CONSTANTS.PRODUCT_CLEAR_DATA })
        setLoading(true)
        const data = {
            productCode: APP_CONSTANTS.PRODUCT_NAME,
            categoryId: state
        }
        if (isBreakfast === true) {
            data.isBreakfast = isBreakfast
        }
        if (isLunch === true) {
            data.isLunch = isLunch
        }
        if (isDinner === true) {
            data.isDinner = isDinner
        }
        if (is24Hour === true) {
            data.is24Hour = is24Hour
        }
        dispatch(productListRequest(data))
    }

    useEffect(() => {
        if (!isNull(productListSuccess)) {
            setLoading(false)
        } else if (!isNull(productListError)) {
            setLoading(false)
            if (productListError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                navigate(-1)
            }
        }
    }, [productListSuccess, productListError])

    const addClick = item => {
        setSelectedItem(item)
        if (!item.isOptionBasedPricing) {
            addItem(item)
        } else {
            setShowOptionPriceModal(true)
        }

    }

    const addItem = item => {
        if (isNull(item)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            displayConsoleLog('===item', item)
            const data = {
                itemId: item.itemId,
                itemCode: item.itemCode,
                categoryId: item.categoryId,
                name: item.name,
                isOptionBasedPricing: false,
                price: item.price,
                finalPrice: item.finalPrice,
                quantity: 1,
                isFromCart: false,
                discount: item.discount,
                tax: item.isTaxable === true ? item.tax : 0,
                isTaxable: item.isTaxable,
                currencyLabel: item.currencyLabel,
                currencySymbol: item.currencySymbol,
                currencyRegion: item.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: item.measurementUnit,
                measurementQuantity: item.measurementQuantity
            }
            if (!isEmpty(item.points)) {
                data.points = Number(item.points)
            } else {
                data.points = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast('Item added in cart')
            dispatch(displayAddCartBannerAct(''))
        }
    }

    const handleAddWithPrice = item => {
        if (isNull(selectedItem)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            const data = {
                itemId: selectedItem.itemId,
                itemCode: selectedItem.itemCode,
                categoryId: selectedItem.categoryId,
                name: `${selectedItem.name} - ${item.label}`,
                isOptionBasedPricing: true,
                price: item.price,
                finalPrice: item.price,
                quantity: 1,
                isFromCart: false,
                discount: 0,
                tax: 0,
                isTaxable: false,
                currencyLabel: selectedItem.currencyLabel,
                currencySymbol: selectedItem.currencySymbol,
                currencyRegion: selectedItem.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: selectedItem.measurementUnit,
                measurementQuantity: selectedItem.measurementQuantity
            }
            if (!isEmpty(item.points)) {
                data.points = Number(item.points)
            } else {
                data.points = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast('Item added in cart')
            dispatch(displayAddCartBannerAct(''))
            setShowOptionPriceModal(false)
        }
    }

    useEffect(() => {
        if (allProduct === true) {
            fetchProductList()
        }
    }, [allProduct])

    useEffect(() => {
        if (isBreakfast === true) {
            fetchProductList()
        }
    }, [isBreakfast])

    useEffect(() => {
        if (isDinner === true) {
            fetchProductList()
        }
    }, [isDinner])

    useEffect(() => {
        if (isLunch === true) {
            fetchProductList()
        }
    }, [isLunch])

    useEffect(() => {
        if (is24Hour === true) {
            fetchProductList()
        }
    }, [is24Hour])

    const allCheckHandle = () => {
        setAllProduct(true)
        setIs24Hour(false)
        setIsBreakfast(false)
        setIsDinner(false)
        setIsLunch(false)
    }

    const breakfastHandle = () => {
        setAllProduct(false)
        setIsBreakfast(true)
        setIs24Hour(false)
        setIsDinner(false)
        setIsLunch(false)
    }

    const dinnerHandler = () => {
        setAllProduct(false)
        setIsDinner(true)
        setIs24Hour(false)
        setIsBreakfast(false)
        setIsLunch(false)
    }

    const lunchHandle = () => {
        setAllProduct(false)
        setIsLunch(true)
        setIs24Hour(false)
        setIsBreakfast(false)
        setIsDinner(false)
    }

    const hour24Handle = () => {
        setAllProduct(false)
        setIs24Hour(true)
        setIsBreakfast(false)
        setIsDinner(false)
        setIsLunch(false)
    }

    const calculateDiscountedPrice = (price, discount) => {
        const discountAmt = price * discount / 100
        const finalPrice = price - discountAmt
        return finalPrice.toFixed(2)
    }

    const handleReviewAndRatingClick = element => {
        sessionStorage.setItem(APP_CONSTANTS.FB_PRODUCT_ITEM, element.itemId)
        setShowReview(true)
    }

    const calculateAvgRatings = () => {
        let rating = 0
        if (!isNull(productListSuccess)) {
            const item = productListSuccess.data.find(item => item.itemId === Number(sessionStorage.getItem(APP_CONSTANTS.FB_PRODUCT_ITEM)))

            if (!isNull(item))
                item.usersFeedback.forEach(element => {
                    rating = +rating + +element.rating
                });
        }
        return rating
    }

    const userFeedback = () => {
        if (!isNull(productListSuccess)) {
            const item = productListSuccess.data.find(item => item.itemId === Number(sessionStorage.getItem(APP_CONSTANTS.FB_PRODUCT_ITEM)))
            if (!isNull(item))
                return item.usersFeedback
            else return null
        }
        return null
    }

    const isNoImage = (item) => {
        return item.includes("no-image")
    }

    const getImageClassName = index => {
        const lastDigit = index % 10;
        // return `cmc-img-container${lastDigit}`
        return `cmc-img-container-white`
    }

    const displayCustomizeButton = (item) => {
        if (isEmpty(item.addOns) && isEmpty(item.options))
            return false
        else return true
    }

    const handleOpenCustomizeScreen = (item) => {
        navigate(ROUTES.menuProductCustomize, { state: item })
    }

    return (
        <>
            <div className="pl-container mb-4">
                {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                    <div className="pl-label-container">
                        <div className="pl-logo-container">
                            {/* <img className="pl-img" src={require('../../../assets/GoMoBiteslogo.png')} /> */}
                            <img className="pl-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />
                        </div>
                        <ScreenLabel label={`${restaurantDetailSuccess.data.businessName}`} />

                    </div>
                ) :
                    (<div className="pl-label-container">
                        <ScreenLabel label='Products' />
                    </div>)}
                <div className="cmc-content-container">
                    <div className="mp-row-checkbox">
                        <div className="mp-check-box">
                            <input
                                type="radio"
                                className="form-check-input mp-input-text"
                                id="name"
                                checked={allProduct}
                                value={allProduct}
                                onChange={() => {
                                    allCheckHandle()
                                }}
                                required />
                            <label for="name" className="mp-checkbox-label">All</label>
                        </div>
                        <div className="mp-check-box">
                            <input
                                type="radio"
                                className="form-check-input mp-input-text"
                                id="name"
                                checked={isBreakfast}
                                value={isBreakfast}
                                onChange={() => {
                                    breakfastHandle()
                                }}
                                required />
                            <label for="name" className="mp-checkbox-label">Breakfast</label>
                        </div>
                        <div className="mp-check-box">
                            <input
                                type="radio"
                                className="form-check-input mp-input-text"
                                id="name"
                                checked={isLunch}
                                value={isLunch}
                                onChange={() => {
                                    lunchHandle()
                                }}
                                required />
                            <label for="name" className="mp-checkbox-label">Lunch</label>
                        </div>
                        <div className="mp-check-box">
                            <input
                                type="radio"
                                className="form-check-input mp-input-text"
                                id="name"
                                checked={isDinner}
                                value={isDinner}
                                onChange={() => {
                                    dinnerHandler()
                                }}
                                required />
                            <label for="name" className="mp-checkbox-label">Dinner</label>
                        </div>
                        <div className="mp-check-box">
                            <input
                                type="radio"
                                className="form-check-input mp-input-text"
                                id="name"
                                checked={is24Hour}
                                value={is24Hour}
                                onChange={() => {
                                    hour24Handle()
                                }}
                                required />
                            <label for="name" className="mp-checkbox-label">24 Hours</label>
                        </div>
                    </div>

                    <div className="pl-card-container">
                        {productListSuccess && productListSuccess.data && productListSuccess.data.map((element, index) => (
                            <div className="pl-row-container">
                                <div className="pl-img-container">
                                    {/* <img src={`${config.imageUrl}${element.image}`} className="pl-img" alt="..." /> */}
                                    {!isNoImage(element.image) ? (<img src={`${config.imageUrl}${element.image}`} className="pl-img" alt="..." />) : (
                                        <div className={`${getImageClassName(index)}`} />
                                    )}
                                </div>
                                <div className="pl-column-container">
                                    {element.isVeg === true ? (<div className="mpc-option-type-container">VEG</div>) : null}
                                    {element.isNonVeg === true ? (<div className="mpc-option-type-container">NON VEG</div>) : null}
                                    {element.isVegan === true ? (<div className="mpc-option-type-container">VEGAN</div>) : null}
                                    <div className="pl-card-title">{element.name}</div>
                                    <div className="pl-card-description">{element.description}</div>
                                    {!element.isOptionBasedPricing && <div>
                                        {element.discount <= 0 && <div> {displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                            (<div className="pl-price-label">{element.currencySymbol} {element.price.toFixed(2)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)
                                            : (<div className="pl-price-label">{element.price.toFixed(2)} {element.currencySymbol} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                                        </div>}
                                    </div>}
                                    {!element.isOptionBasedPricing && <div>
                                        {element.discount > 0 && <div>
                                            {displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                                (<div className="pl-price-label">{element.currencySymbol} <span className="pl-price-label-striked">{element.price.toFixed(2)}</span> {calculateDiscountedPrice(element.price, element.discount)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)
                                                : (<div className="pl-price-label">{element.price.toFixed(2)} {element.currencySymbol} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                                        </div>}
                                    </div>}
                                    {element.isOptionBasedPricing && <div>
                                        {element.optionBasedPricing.map(e => (
                                            <div>
                                                {displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                                    (<div className="pl-price-label">{e.label} - {element.currencySymbol} {e.price.toFixed(2)}</div>)
                                                    : (<div className="pl-price-label">{e.label} - {e.price.toFixed(2)} {element.currencySymbol}</div>)}
                                            </div>
                                        ))}
                                    </div>}
                                    <div className="pl-review-container">
                                        {!isNull(element.usersFeedback) ?
                                            (<div onClick={() => { handleReviewAndRatingClick(element) }} className="mp-review-rating-label"> {element.usersFeedback.length} {element.usersFeedback.length > 1 ? (<span>Reviews &amp; Ratings</span>) : (<span>Review &amp; Rating</span>)}</div>) :
                                            (<div className="mp-review-no-rating-label">No Ratings</div>)
                                        }
                                        {element.status === 0 && <div className="out-of-stock">Out Of Stock</div>}
                                        {!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && restaurantDetailSuccess.data.allowOnlineOrdering === true && element.status === 1 && displayCustomizeButton(element) === false && <button type="button" className="btn pl-btn" onClick={() => addClick(element)}>Add</button>}
                                        {element.status === 1 && displayCustomizeButton(element) === true && <button type="button" className="btn pl-btn" onClick={() => handleOpenCustomizeScreen(element)}>Customize</button>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {productListError && <div className="no-product-label">No Product Found</div>}
            </div>
            <Modal
                isOpen={showReview}
                className='mp-modal'
                overlayClassName='mp-modal-overlay'
                contentLabel="  ">
                <div className="mp-modal-container">
                    <div className="mp-image-container" onClick={() => setShowReview(!showReview)}>
                        <img className="pl-img" src={require('../../../assets/close.png')} alt="close" />
                    </div>
                    <div className="d-flex justify-content-center">
                        <ReactStars
                            count={5}
                            value={calculateAvgRatings()}
                            size='45'
                            activeColor="#ffd700"
                            isHalf={true}
                            edit={false}
                        />
                    </div>
                    <div className="pl-card-container">
                        {userFeedback() && userFeedback().map(element => (
                            <div className="mp-review-container">
                                <ReactStars
                                    count={5}
                                    value={element.rating}
                                    size='22'
                                    activeColor="#ffd700"
                                    isHalf={true}
                                    edit={false}
                                />
                                <div className="mp-review-text">{element.review}</div>
                                <div className="mp-review-user-text">{element.userName}</div>

                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showOptionPriceModal}
                className='mp-modal'
                overlayClassName='mp-modal-overlay'
                contentLabel="  ">
                <div className="mp-modal-container">
                    <div className="mp-image-container" onClick={() => setShowOptionPriceModal(!showOptionPriceModal)}>
                        <img className="pl-img" src={require('../../../assets/close.png')} alt="close" />
                    </div>
                    <div className="mp-button-container">
                        {!isNull(selectedItem) && selectedItem.optionBasedPricing.map(e => (
                            <CommonButton
                                label={`${e.label} - ${e.price}`}
                                onClick={() => { handleAddWithPrice(e) }}
                            />
                        ))}
                    </div>

                </div>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default MenuProduct