import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { placeReservationRequest, placeReservationRequestStatus } from '../../../../redux/reservation/reservation.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { ReservationType } from '../../../../utils/ReservationType'
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
import CommonButton from '../../../../components/button/common-button/commonButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";
import ButtonRow from "../../../../components/button/button-row/buttonRow";

const AddReservationScreen = props => {
    const [customerName, setCustomerName] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')
    const [eventName, setEventName] = useState('')
    const [guestCount, setGuestCount] = useState(null)
    const [eventDescription, setEventDescription] = useState('')
    const [mobile, setMobile] = useState()
    const [reservationDateTime, setReservationDateTime] = useState(new Date())
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { placeReservationSuccess, placeReservationError, placeReservationReqStatus } = useSelector(state => state.reservation)
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [selectedReservationType, setSelectedReservationType] = useState()
    const [isReservationConfirmed, setReservationIsConfirmed] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'PLACE_RESERVATION',
            screenClass: 'PLACE_RESERVATION'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            setLoading(true)
            let data1 = {
                userId: user.data.userDetails.userId
            }
            dispatch(restaurantListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (placeReservationReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(placeReservationSuccess.message)
            clearFields()
        } else if (placeReservationReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(placeReservationError.message)
        }

        if (placeReservationReqStatus.length) {
            dispatch(placeReservationRequestStatus(''))
        }
    }, [placeReservationSuccess, placeReservationError, placeReservationReqStatus])

    const clearFields = () => {
        setCustomerName('')
        setMobile()
        setReservationDateTime(new Date())
    }

    const submitHandler = () => {
        if (isEmpty(customerName)) {
            displayErrorToast('Customer name name cannot be blank')
        } else if (isEmpty(mobile)) {
            displayErrorToast('Customer mobile cannot be blank')
        } else if (isEmpty(guestCount)) {
            displayErrorToast('Guest count cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else if (isEmpty(selectedReservationType)) {
            displayErrorToast('Reservation type not selected')
        } else if (selectedReservationType.key === APP_CONSTANTS.RESERVATION_TYPE_EVENT && isEmpty(eventName)) {
            displayErrorToast('Reservation event name cannot be empty')
        } else if (selectedReservationType.key === APP_CONSTANTS.RESERVATION_TYPE_EVENT && isEmpty(eventDescription)) {
            displayErrorToast('Reservation event description cannot be empty')
        } else {
            setLoading(true)
            const mobileNum = mobile.replace('+', "")
            const data = {
                customerName: customerName,
                customerMobile: Number(mobileNum),
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                locationId: isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation,
                productCode: user.data.userDetails.productCode,
                customerEmail: isEmpty(customerEmail) ? '' : customerEmail,
                employeeId: user.data.userDetails.userId,
                reservationType: selectedReservationType.key,
                guestCount: guestCount,
                reservationDateTime: moment(reservationDateTime).utc().format("YYYY-MM-DD hh:mm:ss A"),
                reservationStatus: isReservationConfirmed === true ? APP_CONSTANTS.RESERVATION_STATUS.CONFIRMED : APP_CONSTANTS.RESERVATION_STATUS.PENDING
            }
            if (!isEmpty(eventName)) {
                data.eventName = eventName
            }
            if (!isEmpty(eventDescription)) {
                data.eventDescription = eventDescription
            }
            dispatch(placeReservationRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Place Reservation' />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Enter details to place new reservation' />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Customer Name"
                        value={customerName}
                        onChange={e => setCustomerName(e.target.value)}
                        isRequired={true}
                    />
                    <CommonInputRow>
                        <CommonInputHalf
                            type="name"
                            id="email"
                            placeholder="Email Id"
                            value={customerEmail}
                            onChange={e => setCustomerEmail(e.target.value)}
                            required
                        />
                        <div className="select-half-width">
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={setMobile} />
                        </div>
                    </CommonInputRow>
                    <CommonInputFull
                        type="number"
                        id="name"
                        placeholder="Guest count"
                        value={guestCount}
                        onChange={e => setGuestCount(e.target.value)}
                        isRequired={true}
                    />
                    <div className="aresv-select-heading">Select Reservation Date And Time</div>
                    <DateTimePicker onChange={setReservationDateTime} value={reservationDateTime} />
                    <div className="aresv-select-heading mt-2">Select Reservation Type</div>
                    {ReservationType && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border'
                        value={selectedReservationType}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={ReservationType}
                        placeholder='Select Reservation Type'
                        onChange={e => setSelectedReservationType(e)} />}
                    {!isNull(selectedReservationType) && selectedReservationType.key === APP_CONSTANTS.RESERVATION_TYPE_EVENT &&
                        <CommonInputFull
                            type="text"
                            id="name"
                            placeholder="Event Name"
                            value={eventName}
                            onChange={e => setEventName(e.target.value)}
                            isRequired={true}
                        />
                    }
                    {!isNull(selectedReservationType) && selectedReservationType.key === APP_CONSTANTS.RESERVATION_TYPE_EVENT &&
                        <CommonInputFull
                            type="text"
                            id="name"
                            placeholder="Event Description"
                            value={eventDescription}
                            onChange={e => setEventDescription(e.target.value)}
                            isRequired={true}
                        />
                    }
                    <div className="aresv-input margin-top-10">
                        <input
                            type="checkbox"
                            className="form-check-input aresv-input-text"
                            id="name"
                            checked={isReservationConfirmed}
                            value={isReservationConfirmed}
                            onChange={() => {
                                setReservationIsConfirmed(!isReservationConfirmed)
                            }}
                            required />
                        <label for="name" className="checkbox-label">Reservation confirmed?</label>
                    </div>
                    {isLoggedInUserBusiness(user) === true && <div className="aresv-select-heading">Select Restaurant</div>}
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="aresv-text">No restaurant found</div>}
                    {isLoggedInUserBusiness(user) === true && <div className="aresv-select-heading mt-2">Select Location</div>}
                    {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="aresv-text">No location found</div>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => submitHandler()} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddReservationScreen