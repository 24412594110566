import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import { invCategoryListRequest } from '../../../../redux/inventory/category/category.action'
import { invProductListRequest } from '../../../../redux/inventory/product/product.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";

const InvProductList = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { invCategoryListSuccess, invCategoryListError } = useSelector(state => state.invCategory)
    const { invProductListSuccess, invProductListError } = useSelector(state => state.invProduct)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    let isFirstTime = false
    let navigate = useNavigate();
    const PRODUCT_LIST = 'PRODUCT_LIST'
    const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
    const ADD_PRODUCT = 'ADD_PRODUCT'

    useEffect(() => {
        if (!isNull(invCategoryListSuccess)) {
            setLoading(false)
        } else if (!isNull(invCategoryListError)) {
            setLoading(false)
        }
    }, [invCategoryListSuccess, invCategoryListError])

    useEffect(() => {
        if (!isNull(invProductListSuccess)) {
            setLoading(false)
        } else if (!isNull(invProductListError)) {
            setLoading(false)
        }
    }, [invProductListSuccess, invProductListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.INVENTORY.PRODUCT_LIST, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchCategoryList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(invCategoryListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(invCategoryListRequest(data))
        }
    }

    const fetchProductList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId,
                    invCategoryId: selectedCategory.invCategoryId
                }
                dispatch(invProductListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation,
                invCategoryId: selectedCategory.invCategoryId
            }
            dispatch(invProductListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.INVENTORY.PRODUCT_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.INVENTORY.PRODUCT_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchCategoryList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (!isNull(selectedCategory)) {
            fetchProductList()
        }
    }, [selectedCategory])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchCategoryList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.INVENTORY.PRODUCT_LIST && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            displayConsoleLog('itemObj', itemObj)
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === PRODUCT_LIST) {
                    if (itemObj.productList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === UPDATE_PRODUCT) {
                    if (itemObj.updateProduct === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === ADD_PRODUCT) {
                    if (itemObj.addProduct === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    const handleAddProductClick = () => {
        if (checkFunctionalityPermission(ADD_PRODUCT) === true) {
            navigate(ROUTES.inventory.addProduct)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleCardClick = (item) => {
        navigate(ROUTES.inventory.productDetail, { state: item.invProductId })
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Products' />
                    <TopButton label='Add Product'
                        isSmall={true}
                        onClick={() => { handleAddProductClick() }} />
                </CommonInputRow>
                {isLoggedInUserBusiness(user) === true && <CommonInputRow>
                    <div className="incl-select-container">
                        <div className="incl-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                            }}
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="content-error">No restaurant found</div>}
                    </div>
                    <div className="incl-select-container">
                        <div className="incl-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                            }}
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="content-error">No location found</div>}
                    </div>
                </CommonInputRow>}
                {isLoggedInUserBusiness(user) === true && invCategoryListSuccess && invCategoryListSuccess.data && <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                    }}
                    menuPlacement="auto"
                    className='select-full-width-no-border'
                    value={selectedCategory}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e}
                    options={invCategoryListSuccess.data}
                    placeholder='Select Category'
                    onChange={e => setSelectedCategory(e)} />}
                {isLoggedInUserBusiness(user) === true && !isEmpty(invCategoryListError) && !isEmpty(invCategoryListError.data) && <div className="content-error">{invCategoryListError.message}</div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === true && <div className="table-scroll-container">
                    {!isEmpty(invProductListSuccess) && !isEmpty(invProductListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="incl-table-col-title">Name</th>
                                <th scope="col" className="incl-table-col-title text-center">Brand</th>
                                <th scope="col" className="incl-table-col-title text-center">Type</th>
                                <th scope="col" className="incl-table-col-title text-center">Sub-Type</th>
                                <th scope="col" className="incl-table-col-title text-center">Available Quantity</th>
                                <th scope="col" className="incl-table-col-title text-center">Status</th>
                                <th scope="col" className="incl-table-col-title text-center">Date</th>
                                <th scope="col" className="incl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invProductListSuccess.data.map(element => (
                                <tr className="incl-table-row-container">
                                    <td className="incl-table-col-item">{element.name}</td>
                                    <td className="incl-table-col-item text-center">{element.brand}</td>
                                    <td className="incl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.productType)}</td>
                                    <td className="incl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.productSubType)}</td>
                                    <td className="incl-table-col-item text-center">{element.availabelQuantity} ({element.productQuantityType})</td>
                                    <td className="incl-table-col-item text-center">{element.isAvailable ? 'Active' : 'In-Active'}</td>
                                    <td className="incl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="incl-table-col-item text-center align-middle">
                                        <div className="incl-btn-container" onClick={() => { handleCardClick(element) }}>Details</div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === true && !isEmpty(invProductListError) && <div className="content-error">{invProductListError.message}</div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === false && invProductListError && <div className="content-error">You are not authorized to view category list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvProductList