import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addFeatureRequestReq, addFeatureRequestReqStatusUpdate } from '../../../redux/feature-request/featureRequest.action'
import CommonForm from "../../../components/common-form/commonForm";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonInputFull from "../../../components/common-input-full/commonInputFull"
import CommonButton from "../../../components/button/common-button/commonButton";
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { FEATURE_REQUEST_TYPE } from "../../../utils/ReservationType";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import ButtonRow from "../../../components/button/button-row/buttonRow";

const AddFeatureRequest = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { addFeatureRequestSuccess, addFeatureRequestError,
        addFeatureRequestReqStatus } = useSelector(state => state.featureRequest)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [images, setImages] = useState([])
    const [otherImage, setOtherImage] = useState(null);
    const [selectedImagesList, setSelectedImagesList] = useState([])
    const [selectedSuggestionType, setSelectedSuggestionType] = useState('')

    useEffect(() => {
        if (addFeatureRequestReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addFeatureRequestSuccess.message)
        } else if (addFeatureRequestReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addFeatureRequestError.message)
        }

        if (addFeatureRequestReqStatus.length) {
            dispatch(addFeatureRequestReqStatusUpdate(''))
        }
    }, [addFeatureRequestSuccess, addFeatureRequestError, addFeatureRequestReqStatus])

    const handleSubmitClick = () => {
        displayConsoleLog(selectedSuggestionType)
        if (isEmpty(title)) {
            displayErrorToast('Title cannot be blank')
        } else if (isEmpty(message)) {
            displayErrorToast('Suggestion description cannot be blank')
        } else if (isEmpty(selectedSuggestionType)) {
            displayErrorToast('Suggestion type not selected')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('title', title)
            fd.append('description', message)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('priority', selectedSuggestionType)
            if (images.length > 0) {
                images.forEach(element => {
                    fd.append('multipleImage', element)
                })

            }
            dispatch(addFeatureRequestReq(fd))
        }
    }

    useEffect(() => {
        if (otherImage !== undefined && otherImage !== null) {
            const existingList = images
            existingList.push(otherImage)
            setImages(existingList)
            setOtherImage(null)
        }
    }, [otherImage])

    const handleOtherImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setOtherImage(event.target.files[0])
            let currentImages = selectedImagesList
            currentImages.push(event.target.files[0].name)
            setSelectedImagesList(currentImages)
        }
    }

    const handleRadioButtonChange = e => {
        setSelectedSuggestionType(e.target.value)
    }

    return (
        <>
            <div className="afr-container">
                <div className="su-img-contain">
                    <img src={require('../../../assets/NewLogo1.png')} className="img" alt="logo" />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Fill below details to add your feature request' />
                    <CommonInputFull
                        id='title'
                        onChange={e => setTitle(e.target.value)}
                        placeholder='Title'
                        value={title}
                        type='text' />
                    <CommonInputFull
                        id='message'
                        onChange={e => setMessage(e.target.value)}
                        placeholder='Add Description'
                        value={message}
                        type='text' />
                    <div className='sbmu-field-label'>Select suggestion type</div>
                    <fieldset className="mp-row-checkbox">
                        {FEATURE_REQUEST_TYPE.map(element => (
                            <div className="mp-check-box">
                                <input type="radio"
                                    className="form-check-input mp-input-text"
                                    id="requiredType"
                                    name="featureRequestType"
                                    value={element.key}
                                    checked={selectedSuggestionType === element.key}
                                    onChange={e => handleRadioButtonChange(e)} />
                                <label for="requiredType" className="mp-checkbox-label">{element.value}</label>
                            </div>
                        ))}
                    </fieldset>
                    <div className='sbmu-field-label'>Click camera icon to add images</div>
                    <div className="sbmu-icon-container">
                        <label htmlFor="productFileInputOtherImg">
                            <img src={require('../../../assets/camera_icon.png')} alt="camera" className="img" />
                        </label>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            onChange={handleOtherImageChange}
                            id='productFileInputOtherImg'
                        />
                    </div>
                    {selectedImagesList && selectedImagesList.length > 0 && <div className="sbmu-field-label">Selected Images</div>}
                    {selectedImagesList && selectedImagesList.length > 0 && <table class="table table-striped table-hover mt-1">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">SNo.</th>
                                <th scope="col" className="rl-table-col-title">Image Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedImagesList.map((element, index) => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{index + 1}</td>
                                    <td className="rl-table-col-item">{element}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => handleSubmitClick()} />
                    </ButtonRow>
                </CommonColorForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddFeatureRequest