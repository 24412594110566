export const FeatureRequestActionTypes = {
    LOGOUT: 'LOGOUT',

    FEATURE_REQUEST_ADD_REQUEST: 'FEATURE_REQUEST_ADD_REQUEST',
    FEATURE_REQUEST_ADD_REQUEST_SUCCESS: 'FEATURE_REQUEST_ADD_REQUEST_SUCCESS',
    FEATURE_REQUEST_ADD_REQUEST_ERROR: 'FEATURE_REQUEST_ADD_REQUEST_ERROR',
    FEATURE_REQUEST_ADD_REQUEST_STATUS: 'FEATURE_REQUEST_ADD_REQUEST_STATUS',

    FEATURE_REQUEST_LIST_REQUEST: 'FEATURE_REQUEST_LIST_REQUEST',
    FEATURE_REQUEST_LIST_REQUEST_SUCCESS: 'FEATURE_REQUEST_LIST_REQUEST_SUCCESS',
    FEATURE_REQUEST_LIST_REQUEST_ERROR: 'FEATURE_REQUEST_LIST_REQUEST_ERROR',

    FEATURE_REQUEST_DETAIL_REQUEST: 'FEATURE_REQUEST_DETAIL_REQUEST',
    FEATURE_REQUEST_DETAIL_REQUEST_SUCCESS: 'FEATURE_REQUEST_DETAIL_REQUEST_SUCCESS',
    FEATURE_REQUEST_DETAIL_REQUEST_ERROR: 'FEATURE_REQUEST_DETAIL_REQUEST_ERROR',

    FEATURE_REQUEST_ADD_COMMENT_REQUEST: 'FEATURE_REQUEST_ADD_COMMENT_REQUEST',
    FEATURE_REQUEST_ADD_COMMENT_REQUEST_SUCCESS: 'FEATURE_REQUEST_ADD_COMMENT_REQUEST_SUCCESS',
    FEATURE_REQUEST_ADD_COMMENT_REQUEST_ERROR: 'FEATURE_REQUEST_ADD_COMMENT_REQUEST_ERROR',
    FEATURE_REQUEST_ADD_COMMENT_REQUEST_STATUS: 'FEATURE_REQUEST_ADD_COMMENT_REQUEST_STATUS',

    FEATURE_REQUEST_UPVOTE_REQUEST: 'FEATURE_REQUEST_UPVOTE_REQUEST',
    FEATURE_REQUEST_UPVOTE_REQUEST_SUCCESS: 'FEATURE_REQUEST_UPVOTE_REQUEST_SUCCESS',
    FEATURE_REQUEST_UPVOTE_REQUEST_ERROR: 'FEATURE_REQUEST_UPVOTE_REQUEST_ERROR',
    FEATURE_REQUEST_UPVOTE_REQUEST_STATUS: 'FEATURE_REQUEST_UPVOTE_REQUEST_STATUS'
}
