import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../../components/screen-label/screenLabel";
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select'
import { branchListRequest } from '../../../../../redux/user/user.action'
import { displayCurrencySymbolLeft, isNull, getProductMeasurmentUnitOrder, displayErrorToast, priceWithSymbol } from "../../../../../utils/Utils";
import { productListRequest } from '../../../../../redux/product/product.action'
import { config } from "../../../../../config";
import CommonInputRow from "../../../../../components/common-input-row/commonInputRow";
import TopButton from "../../../../../components/button/top-button/topButton";
import { APP_CONSTANTS, ROUTES } from "../../../../../utils/AppConstants";
// import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas'
import Modal from 'react-bootstrap/Modal';
import { toPng } from "html-to-image";
import download from "downloadjs";
import QRCode from 'qrcode.react';
import CommonInputFull from "../../../../../components/common-input-full/commonInputFull";
import CommonButton from "../../../../../components/button/common-button/commonButton";

const OperatorMenuList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, branchListSuccess, branchListError } = useSelector((state) => state.user)
    const { productListSuccess, productListError } = useSelector(state => state.product)
    const dispatch = useDispatch()
    const { state } = useLocation()
    let navigate = useNavigate()
    const [selectedLocation, setSelectedLocation] = useState()
    const [showQrCodeModal, setShowQrCodeModal] = useState(false)
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const svgRef = useRef(null);
    const ariaLabel = { 'aria-label': 'description' };
    const [qrSize, setQrSize] = useState(256)
    const [labelSize, setLabelSize] = useState(14)

    useEffect(() => {
        setLoading(true)
        const data = {
            businessId: Number(state)
        }
        dispatch(branchListRequest(data))
    }, [])

    useEffect(() => {
        if (!isNull(branchListSuccess)) {
            setLoading(false)
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchProductList()
        }
    }, [selectedLocation])

    const fetchProductList = () => {
        setLoading(true)
        const data = {
            productCode: user.data.userDetails.productCode,
            businessId: Number(state),
            locationId: selectedLocation.locationId
        }
        dispatch(productListRequest(data))
    }

    useEffect(() => {
        if (!isNull(productListSuccess)) {
            setLoading(false)
        } else if (!isNull(productListError)) {
            setLoading(false)
        }
    }, [productListSuccess, productListError])

    const handleCardClick = item => {
        navigate(ROUTES.operatorEditMenuItem, { state: item.itemId });
    }

    const handleAddProductClick = () => {
        navigate(ROUTES.operatorAddMenuItem, {
            state: state
        })
    }

    const handleGetQRCodeClick = () => {
        setQrSize(256)
        setLabelSize(14)
        let qrCode = `${config.qrCodeUrl}/${state}/${selectedLocation.locationId}?restaurantId=${state}&locationId=${selectedLocation.locationId}`
        sessionStorage.setItem(APP_CONSTANTS.QR_CODE, qrCode)
        console.log('url', qrCode);
        setQRCodeUrl(qrCode)
        setShowQrCodeModal(true)
    }

    const downloadQR = () => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        } else {
            const width = svgRef.current.offsetWidth;
            toPng(svgRef.current, { width })
                .then(function (dataUrl) {
                    download(dataUrl, "qr-code.png");
                })
                .catch(function (error) {
                    console.error("oops, something went wrong!", error);
                });
        }
    }

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newLabelSize = 4 + (4 * percentChange)
        setLabelSize(newLabelSize / 2)
    }, [qrSize])

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow>
                    <ScreenLabel label='Menu' />
                    <TopButton label='Add Product'
                        onClick={() => handleAddProductClick()} />
                </CommonInputRow>

                <div className="p-list-select-heading">Select Location</div>
                {branchListSuccess && branchListSuccess.data && <Select
                    className='select-full-width-no-border'
                    value={selectedLocation}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e}
                    options={branchListSuccess.data}
                    placeholder='Select Location'
                    onChange={e => setSelectedLocation(e)} />}
                {!branchListSuccess && <div className="p-text">No location found</div>}

                {branchListSuccess && branchListSuccess.data && !isNull(selectedLocation) && <div className="oml-qr-btn-container">
                    <TopButton label='Get QR Code'
                        onClick={() => { handleGetQRCodeClick() }}
                    />
                </div>}
                {productListSuccess && productListSuccess.data &&
                    <div className="p-price-warning mt-4">*Price including tax and discount</div>
                }

                {<div className="table-scroll-container">
                    {productListSuccess && productListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Image</th>
                                <th scope="col" className="rl-table-col-title text-center">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Category</th>
                                <th scope="col" className="rl-table-col-title text-center">Price</th>
                                <th scope="col" className="rl-table-col-title text-center">Option Based Price</th>
                                <th scope="col" className="rl-table-col-title text-center">Serving</th>
                                <th scope="col" className="rl-table-col-title text-center">Availability</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productListSuccess.data.map(element => (
                                <tr className="rl-table-row-container" onClick={() => { handleCardClick(element) }}>
                                    <td className="rl-table-col-item">
                                        <div className="p-image-container">
                                            <img src={`${config.imageUrl}${element.image}`} className="img" alt="..." />
                                        </div>
                                    </td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.name}</td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.categoryDetails.name}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        {!element.isOptionBasedPricing && <div>{priceWithSymbol(element, element.finalPrice)}</div>}
                                        {element.isOptionBasedPricing && <div>
                                            {element.optionBasedPricing.map(e => (
                                                <div>{e.label} - {priceWithSymbol(element, e.price)}</div>
                                            ))}
                                        </div>}

                                    </td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.isOptionBasedPricing ? 'Yes' : 'No'}</td>
                                    <td className="rl-table-col-item p-item-container text-center">{getProductMeasurmentUnitOrder(element.measurementQuantity, element.measurementUnit)}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        <div className="p-availability-item-container">
                                            {element.isBreakfast === true && <div className="p-availability-container">B</div>}
                                            {element.isLunch === true && <div className="p-availability-container">L</div>}
                                            {element.isDinner === true && <div className="p-availability-container">D</div>}
                                            {element.is24Hour === true && <div className="p-availability-container">24h</div>}
                                        </div>
                                    </td>
                                    {/* <td className="rl-table-col-item text-center">{element.locationList ? element.locationList.length : 0}</td> */}
                                    <td className={`rl-table-col-item p-item-container text-center ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        <div className="d-flex flex-row justify-content-evenly align-items-center">
                                            <div className="btn p-card-btn-full" onClick={() => handleCardClick(element)}>Detail</div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowQrCodeModal(false) }}
                show={showQrCodeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Download QR Code
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="oml-modal-container">
                        <div id="qrCode" className="oml-qr-container mb-3" ref={svgRef}>
                            <div className="oml-qr-container">
                                <QRCode value={qrCodeUrl} size={qrSize < 38 ? 38 : qrSize}
                                    renderAs='svg'
                                />
                                <div className="oml-powered-label" style={{ fontSize: labelSize }}>Powered By <span className="qr-qme-name-label" style={{ fontSize: labelSize }}>GoMoBites</span></div>
                            </div>
                        </div>
                        <CommonInputFull
                            onChange={t => setQrSize(t.target.value)}
                            placeholder='Desired QR Code Size (In pixels)'
                            type='number'
                            value={qrSize}
                            isSubtext={true}
                            subText='Minimum size should be 38px (1cm)' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => downloadQR()}
                        label='Download' />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OperatorMenuList