import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, isNull,
    isValidEmail, screenCaptureAnalytics
} from '../../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import {
    userRegistrationRequest, displaySideBarAct, subscriptionListRequest, ipLocationRequest, countryListRequest,
    stateListRequest, cityListRequest, ipLocationRequestSuccess
} from '../../../redux/user/user.action'
import { useNavigate, useLocation } from 'react-router-dom';
import CommonButton from "../../../components/button/common-button/commonButton";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/common-input-half/commonInputHalf";
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import Select from 'react-select'
import { config } from "../../../config";
import FormFieldLabel from "../../../components/form-field-label/formFieldLabel";

const Registration = props => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState()
    const [agentPhoneNumber, setAgentPhoneNumber] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, loginErrorData, isLogin, subscriptionListSuccess, subscriptionListError,
        ipLocationSuccess, ipLocationError, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    let navigate = useNavigate();
    const [isReferred, setIsReferred] = useState(false)
    const [displayLocationModal, setDisplayLocationModal] = useState(false)
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const { state } = useLocation()
    const [selectedSubscription, setSelectedSubscription] = useState()

    useEffect(() => {
        const data = {
            screen: 'SIGN_UP',
            screenClass: 'SIGN_UP'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('User registration successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS) {
                dispatch(displaySideBarAct(false))
                // if (isNull(ipLocationSuccess)) {
                //     const locationData = {
                //         currency: '',
                //         country_code: countryDropdown.iso2,
                //         region_code: stateDropdown.iso2,
                //         city: cityDropdown.name
                //     }
                //     dispatch(ipLocationRequestSuccess(locationData))
                // }
                navigate(ROUTES.updatProfile, { replace: true });
            } else {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.sRequestTicketList, { replace: true });
            }
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
        setLoading(true)
        dispatch(subscriptionListRequest())
    }, [])

    useEffect(() => {
        if (!isNull(subscriptionListSuccess)) {
            setLoading(false)
            if (!isEmpty(subscriptionListSuccess.data)) {
                if (!isEmpty(state) && !isEmpty(state.subscriptionId)) {
                    const subscriptionObj = subscriptionListSuccess.data.find(item => parseFloat(item.subscriptionId) === parseFloat(state.subscriptionId))
                    if (!isEmpty(subscriptionObj)) {
                        setSelectedSubscription(subscriptionObj)
                    } else {
                        setSelectedSubscription(subscriptionListSuccess.data[0])
                    }
                } else {
                    setSelectedSubscription(subscriptionListSuccess.data[0])
                }
            }
        } else if (!isNull(subscriptionListError)) {
            setLoading(false)
        }
    }, [subscriptionListSuccess, subscriptionListError])

    useEffect(() => {
        if (!isNull(ipLocationSuccess)) {
            if (isLogin === false && isNull(user)) {
                callRegisterRequest()
            }
        } else if (!isNull(ipLocationError)) {
            setLoading(true)
            dispatch(countryListRequest())
            setDisplayLocationModal(true)
        }
    }, [ipLocationSuccess, ipLocationError])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
            if (!isNull(ipLocationSuccess)) {
                let selCountry = countryListSuccess.find(e => e.iso2 === ipLocationSuccess.country_code)
                if (!isNull(selCountry))
                    handleCountrySelectChange(selCountry)
            }
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
            if (!isNull(ipLocationSuccess)) {
                let selState = stateListSuccess.find(e => e.iso2 === ipLocationSuccess.region_code)
                if (!isNull(selState)) {
                    setStateDropdown(selState);
                    setCityDropdown(null)
                    fetchCityList(countryCode, selState.iso2)
                }
            }
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
            if (!isNull(ipLocationSuccess)) {
                let selCity = cityListSuccess.find(e => e.name === ipLocationSuccess.city)
                if (!isNull(selCity)) {
                    setCityDropdown(selCity);
                }
            }
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    const handleCreateClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank', true)
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank', true)
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank', true)
        } else if (!isValidEmail(email)) {
            displayErrorToast('Invalid email id', true)
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank', true)
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast('Confirm pasword cannot be blank', true)
        } else if (password !== confirmPassword) {
            displayErrorToast('Password does not match', true)
        } else if (isNull(mobile)) {
            displayErrorToast('Invalid mobile number', true)
        } else if (isEmpty(selectedSubscription)) {
            displayErrorToast('Subscription plan not selected')
        } else {
            // setLoading(true)
            // dispatch(ipLocationRequest(''))
            callRegisterRequest()
            // console.log('selectedSubscription', selectedSubscription.subscriptionId)
        }
    }

    const callRegisterRequest = () => {
        if (isLoading === false) {
            setLoading(true)
        }
        const mobileWithoutPlusSymbol = mobile?.replace('+', "")
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            mobile: parseInt(mobileWithoutPlusSymbol),
            subscriptionId: selectedSubscription.subscriptionId,
            productCode: APP_CONSTANTS.PRODUCT_NAME,
            userType: APP_CONSTANTS.USER_TYPE.BUSINESS
        }
        if (isReferred === true) {
            const agentMobileWithoutPlusSymbol = agentPhoneNumber?.replace('+', "")
            data.agentPhoneNumber = parseInt(agentMobileWithoutPlusSymbol)
        }
        if (!isNull(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN))) {
            data.notificationToken = localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN)
        }
        // if (!isNull(ipLocationSuccess)) {
        //     data.countryName = ipLocationSuccess.country_name ? ipLocationSuccess.country_name : ''
        //     data.countryCode = ipLocationSuccess.country_code ? ipLocationSuccess.country_code : ''
        //     data.stateName = ipLocationSuccess.region ? ipLocationSuccess.region : ''
        //     data.stateCode = ipLocationSuccess.region_code ? ipLocationSuccess.region_code : ''
        //     data.city = ipLocationSuccess.city ? ipLocationSuccess.city : ''
        // } else {
        //     if (!isNull(countryDropdown) && !isNull(stateDropdown) && !isNull(cityDropdown)) {
        //         data.countryName = countryDropdown.name
        //         data.countryCode = countryDropdown.iso2
        //         data.stateName = stateDropdown.name
        //         data.stateCode = stateDropdown.iso2
        //         data.city = cityDropdown.name
        //     } else {
        //         data.countryName = ''
        //         data.countryCode = ''
        //         data.stateName = ''
        //         data.stateCode = ''
        //         data.city = ''
        //     }

        // }
        dispatch(userRegistrationRequest(data))
    }

    const handleLocationSelectionSubmitClick = () => {
        setDisplayLocationModal(false)
        callRegisterRequest()
    }

    return (
        <>
            <div className="sign-up-container">
                <div className="sign-up-img-layout-container">
                    <img src={require('../../../assets/sign-in-banner.jpg')} className='sign-up-img-container' />
                    <img src={require('../../../assets/sign-in-banner-tab1.jpg')} className='sign-up-tab-img-container' />
                    {/* <div className="sign-up-brand-label">GOMOBITES</div> */}

                </div>
                <div className="sign-up-content-layout-container">
                    <div className="sign-up-logo-container" onClick={() => { navigateToHome() }}>
                        <img src={require('../../../assets/NewLogo1.png')} alt='logo' className="img" />
                    </div>
                    <div className='sign-in-heading'>Create Account <span className='sign-in-heading-label'>GoMoBites</span></div>
                    <FormFieldLabel label='Enter details to register your restaurant on GoMoBites' className='sign-in-sub-text-label margin-bottom-10' />
                    <CommonInputRow>
                        <CommonInputHalf
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder='First Name'
                            type='text'
                            value={firstName} />
                        <CommonInputHalf
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name'
                            type='text'
                            value={lastName} />
                    </CommonInputRow>
                    <CommonInputFull id='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Email'
                        type='text'
                        value={email} />
                    <div className="r-phone-input">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <CommonInputRow>
                        <CommonInputHalf
                            id='password'
                            onChange={e => setPassword(e.target.value)}
                            placeholder='Password'
                            type='password'
                            value={password} />
                        <CommonInputHalf
                            id='confirmPassword'
                            onChange={e => setConfirmPassword(e.target.value)}
                            placeholder='Confirm Password'
                            type='text'
                            value={confirmPassword} />
                    </CommonInputRow>
                    {!isEmpty(subscriptionListSuccess) && <FormFieldLabel label='Select Subscription Plan' />}
                    {!isEmpty(subscriptionListSuccess) && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedSubscription}
                        getOptionLabel={e => e.title}
                        getOptionValue={e => e}
                        options={subscriptionListSuccess.data}
                        placeholder='Select Subscription'
                        onChange={e => {
                            setSelectedSubscription(e)
                        }} />}
                    <div className="su-input-full">
                        <input
                            type="checkbox"
                            className="form-check-input su-input-text"
                            id="name"
                            checked={isReferred}
                            value={isReferred}
                            onChange={() => {
                                setIsReferred(!isReferred)
                            }}
                            required />
                        <label for="name" className="su-checkbox-label">Check if referred by someone</label>
                    </div>
                    {isReferred && <div className="mt-2">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Referrance Mobile Number"
                            value={agentPhoneNumber}
                            onChange={setAgentPhoneNumber} />
                    </div>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            isBig={true}
                            onClick={() => { handleCreateClick() }} />
                    </ButtonRow>
                    <div className="r-condition-label">By clicking submit you agree to our <a href={`${config.webBaseUrl}${ROUTES.termsOfService}`} target='_blank' className="tc-a-link">Terms &amp; Conditions</a></div>
                </div>

            </div>
            <Modal
                onHide={() => { setDisplayLocationModal(false) }}
                show={displayLocationModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Select Location
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width up-select-margin'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            setCityDropdown(null)
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width up-select-margin'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}


                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => handleLocationSelectionSubmitClick()}
                        label='Continue' />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )

}

export default Registration