import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { categoryListRequest } from '../../../redux/category/category.action'
import { productListRequest } from '../../../redux/product/product.action'
import { addItemInCart, displayAddCartBannerAct } from '../../../redux/cart/cart.action'
import { restaurantDetailRequest, displayBottomBarRequest, customerRestLocRequest, branchDetailRequest } from '../../../redux/user/user.action'
import { config } from '../../../config'
import {
    displayConsoleLog,
    displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, isEmpty, isImageAvailable, isNull, priceWithSymbol, screenCaptureAnalytics,
    calc,
    calculateDiscountedPrice
} from '../../../utils/Utils'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonButton from "../../../components/button/common-button/commonButton";
import Modal from 'react-bootstrap/Modal';

const MenuCategoryProduct = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const { restaurantDetailSuccess, restaurantDetailError, branchDetailSuccess, branchDetailError } = useSelector(state => state.user)
    const { categoryList, categoryListError } = useSelector(state => state.category)
    const { productListSuccess, productListError } = useSelector(state => state.product)
    let { restaurantId, locationId } = useParams();
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedItem, setSelectedItem] = useState()
    const [showOptionPriceModal, setShowOptionPriceModal] = useState(false)
    const [showLocationNonOperational, setShowLocationNonOperational] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_MENU_CATEGORY_PRODUCT',
            screenClass: 'CUSTOMER_MENU_CATEGORY_PRODUCT'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch(displayBottomBarRequest(true))
        if (isEmpty(restaurantId) && isEmpty(locationId)) {
            sessionStorage.clear()
            dispatch({ type: APP_CONSTANTS.LOGOUT })
            navigate(ROUTES.home)
        } else {
            const data = {
                restaurantId: Number(restaurantId),
                locationId: Number(locationId)
            }
            dispatch(customerRestLocRequest(data))
            fetchRestaurantData()
        }
    }, [])

    const fetchRestaurantData = () => {
        setLoading(true)
        const data = {
            businessId: restaurantId
        }
        dispatch(restaurantDetailRequest(data))
    }

    const fetchRestaurantLocationData = () => {
        setLoading(true)
        dispatch(branchDetailRequest(locationId))
    }

    const fetchMenu = () => {
        setLoading(true)
        const data1 = {
            businessId: restaurantId,
            locationId: locationId
        }
        dispatch(categoryListRequest(data1))
    }

    useEffect(() => {
        if (!isNull(restaurantDetailSuccess)) {
            setLoading(false)
            if (!isNull(restaurantDetailSuccess.data)) {
                if (Number(restaurantDetailSuccess.data.status) === 1) {
                    fetchRestaurantLocationData()
                } else {
                    setShowLocationNonOperational(true)
                }
            }
        } else if (!isNull(restaurantDetailError)) {
            setLoading(false)
            displayErrorToast('Some error occurred. Please try refreshing the page and then try again.')
        }
    }, [restaurantDetailSuccess, restaurantDetailError])

    useEffect(() => {
        if (!isNull(branchDetailSuccess)) {
            setLoading(false)
            if (!isNull(branchDetailSuccess.data)) {
                if (Number(branchDetailSuccess.data.status) === 1) {
                    fetchMenu()
                } else {
                    setShowLocationNonOperational(true)
                }
            }
        } else if (!isNull(branchDetailError)) {
            setLoading(false)
            displayErrorToast('Some error occurred. Please try refreshing the page and then try again.')
        }
    }, [branchDetailSuccess, branchDetailError])

    useEffect(() => {
        if (!isNull(categoryList)) {
            setLoading(false)
            setSelectedCategory(categoryList.data[0])
        } else if (!isNull(categoryListError)) {
            setLoading(false)
            if (categoryListError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                fetchRestaurantData()
            }
        }
    }, [categoryList, categoryListError])

    useEffect(() => {
        if (!isNull(productListSuccess)) {
            setLoading(false)
        } else if (!isNull(productListError)) {
            setLoading(false)
            if (productListError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                navigate(-1)
            }
        }
    }, [productListSuccess, productListError])

    const fetchProductList = (categoryId) => {
        setLoading(true)
        const data = {
            productCode: APP_CONSTANTS.PRODUCT_NAME,
            categoryId: categoryId
        }
        dispatch(productListRequest(data))
    }

    useEffect(() => {
        if (!isNull(selectedCategory)) {
            fetchProductList(selectedCategory.categoryId)
        }
    }, [selectedCategory])

    const getImageClassName = index => {
        const lastDigit = index % 10;
        // return `cmc-img-container${lastDigit}`
        return `cmc-img-container-white`
    }

    const handleCategoryClick = e => {
        setSelectedCategory(e)
    }

    const displayCustomizeButton = (item) => {
        if (isEmpty(item.addOns) && isEmpty(item.options))
            return false
        else return true
    }

    const handleOpenCustomizeScreen = (item) => {
        navigate(ROUTES.menuProductCustomize, { state: item })
    }

    const addClick = item => {
        setSelectedItem(item)
        if (!item.isOptionBasedPricing) {
            addItem(item)
        } else {
            setShowOptionPriceModal(true)
        }

    }

    const addItem = item => {
        if (isNull(item)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            displayConsoleLog('===item', item)
            const data = {
                itemId: item.itemId,
                itemCode: item.itemCode,
                categoryId: item.categoryId,
                name: item.name,
                isOptionBasedPricing: false,
                price: item.price,
                finalPrice: item.finalPrice,
                quantity: 1,
                isFromCart: false,
                discount: item.discount,
                tax: item.isTaxable === true ? item.tax : 0,
                isTaxable: item.isTaxable,
                currencyLabel: item.currencyLabel,
                currencySymbol: item.currencySymbol,
                currencyRegion: item.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: item.measurementUnit,
                measurementQuantity: item.measurementQuantity
            }
            if (!isEmpty(item.points)) {
                data.points = Number(item.points)
                data.productPoints = Number(item.points)
            } else {
                data.points = 0
                data.productPoints = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast('Item added in cart')
            dispatch(displayAddCartBannerAct(''))
        }
    }

    const handleAddWithPrice = item => {
        if (isNull(selectedItem)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            const data = {
                itemId: selectedItem.itemId,
                itemCode: selectedItem.itemCode,
                categoryId: selectedItem.categoryId,
                name: `${selectedItem.name} - ${item.label}`,
                isOptionBasedPricing: true,
                price: item.price,
                finalPrice: item.price,
                quantity: 1,
                isFromCart: false,
                discount: 0,
                tax: 0,
                isTaxable: false,
                currencyLabel: selectedItem.currencyLabel,
                currencySymbol: selectedItem.currencySymbol,
                currencyRegion: selectedItem.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: selectedItem.measurementUnit,
                measurementQuantity: selectedItem.measurementQuantity
            }
            if (!isEmpty(selectedItem.points)) {
                data.points = Number(selectedItem.points)
                data.productPoints = Number(selectedItem.points)
            } else {
                data.points = 0
                data.productPoints = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast('Item added in cart')
            dispatch(displayAddCartBannerAct(''))
            setShowOptionPriceModal(false)
        }
    }

    const isValidPoints = points => {
        if (!isEmpty(points) && points > 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <CommonScreenContent>
                {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                    <div className="cmc-label-container mt-2">
                        <div className="mcp-logo-container">
                            {isImageAvailable(restaurantDetailSuccess.data.image) ?
                                (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} alt="logo" className="img" />) :
                                <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />}
                        </div>
                        <div className="mcp-restaurant-label">{restaurantDetailSuccess.data.businessName}</div>
                    </div>
                ) :
                    (<div className="cmc-label-container">
                        <ScreenLabel label='Menu Category' />
                    </div>)
                }
                {!isNull(categoryList) && <div className="mcp-category-parent-container">
                    <div className="mcp-category-scroll-container">
                        {categoryList.data.map(e => (
                            <div className={`mcp-category-item ${selectedCategory?.categoryId === e.categoryId ? 'mcp-category-item-selected' : ''}`}
                                onClick={() => { handleCategoryClick(e) }}>{e.name}</div>
                        ))}
                    </div>
                </div>}
                {!isNull(categoryListError) && <div className="content-error">{categoryListError.message}</div>}
                {!isNull(productListError) && <div className="content-error">{productListError.message}</div>}
                {!isNull(productListSuccess) && !isEmpty(productListSuccess.data) && <div className="mcp-product-list-parent-container">
                    {productListSuccess.data.map((element, index) => (
                        <div className="mcp-product-row-container">
                            {/* {element.isVeg === true ? (<div className="mcp-option-type-container-green">VEG</div>) : null} */}
                            {/* {element.isNonVeg === true ? (<div className="mcp-option-type-container-red">NON VEG</div>) : null} */}
                            {/* {element.isVegan === true ? (<div className="mcp-option-type-container-green">VEGAN</div>) : null} */}
                            <div className="mcp-product-row-item-container">
                                <div className="mcp-product-text-parent-container">

                                    <div className="mcp-product-text-container">
                                        <div className="mcp-product-name">{element.name}</div>
                                        {element.status === 1 && !element.isOptionBasedPricing && <div>
                                            {element.discount > 0 ? (<div className="mcp-product-price-name"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>) : (<div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                                        </div>}
                                        {/* {element.status === 1 && !element.isOptionBasedPricing && <div>
                                            {element.discount <= 0 && <div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>}
                                        </div>}
                                        {element.status === 1 && !element.isOptionBasedPricing &&
                                            <div>
                                                {element.discount > 0 && <div className="mcp-product-price-name"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>}
                                            </div>} */}
                                        {element.status === 1 && isValidPoints(element.points) && <div className="mcp-points-container">&#9733; Earn {element.points ? element.points.toLocaleString('en-US') : '0'} points</div>}
                                        {element.status === 1 && element.isOptionBasedPricing && <div className="mcp-option-based-pricing-container">
                                            {element.optionBasedPricing.map(e => (
                                                <div className="mcp-option-price-label">{e.label} - {priceWithSymbol(element, e.price)}</div>
                                            ))}
                                        </div>}
                                        {element.status === 0 && <div className="out-of-stock">Out Of Stock</div>}
                                    </div>
                                </div>
                                {/* <div className="pl-column-container">
                                {element.isVeg === true ? (<div className="mpc-option-type-container">VEG</div>) : null}
                                {element.isNonVeg === true ? (<div className="mpc-option-type-container">NON VEG</div>) : null}
                                {element.isVegan === true ? (<div className="mpc-option-type-container">VEGAN</div>) : null}
                                <div className="pl-card-title">{element.name}</div>
                                <div className="pl-card-description">{element.description}</div>
                                {!element.isOptionBasedPricing && <div>
                                    {element.discount <= 0 && <div className="pl-price-label">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>}
                                </div>}
                                {!element.isOptionBasedPricing &&
                                    <div>
                                        {element.discount > 0 && <div className="pl-price-label"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>}
                                    </div>}
                                {element.isOptionBasedPricing && <div>
                                    {element.optionBasedPricing.map(e => (
                                        <div className="pl-price-label">{e.label} - {priceWithSymbol(element, e.price)}</div>
                                    ))}
                                </div>}
                                <div className="pl-review-container">
                                    {element.status === 0 && <div className="out-of-stock">Out Of Stock</div>}
                                    {!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && restaurantDetailSuccess.data.allowOnlineOrdering === true && element.status === 1 && displayCustomizeButton(element) === false && <button type="button" className="btn pl-btn" onClick={() => addClick(element)}>Add</button>}
                                    {element.status === 1 && displayCustomizeButton(element) === true && <button type="button" className="btn pl-btn" onClick={() => handleOpenCustomizeScreen(element)}>Customize</button>}
                                </div>
                            </div> */}
                                <div className="mcp-product-image-container">
                                    {isImageAvailable(element.image) ? (<img src={`${config.imageUrl}${element.image}`} className="pl-img" alt="..." />) : (
                                        <div className={`${getImageClassName(index)}`} />
                                    )}
                                    {!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && restaurantDetailSuccess.data.allowOnlineOrdering === true && element.status === 1 && displayCustomizeButton(element) === false && <div className="edit-image-button-bl" onClick={() => addClick(element)}>Add</div>}
                                    {!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && restaurantDetailSuccess.data.allowOnlineOrdering === true && element.status === 1 && displayCustomizeButton(element) === true && <div className="edit-image-button-bl" onClick={() => handleOpenCustomizeScreen(element)}>Customize</div>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
            </CommonScreenContent >
            <Modal
                show={showOptionPriceModal}
                onHide={() => setShowOptionPriceModal(!showOptionPriceModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="mp-button-container">
                        {!isNull(selectedItem) && selectedItem.optionBasedPricing.map(e => (
                            <CommonButton
                                label={`${e.label} - ${e.price}`}
                                onClick={() => { handleAddWithPrice(e) }}
                            />
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showLocationNonOperational}
                onHide={() => setShowLocationNonOperational(!showLocationNonOperational)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>This Restaurant is currently non-operational. Please check with staff for further information.</div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default MenuCategoryProduct