import { ReservationActionTypes } from './reservation.types'

//Place Reservation
export const placeReservationRequest = data => ({
    type: ReservationActionTypes.PLACE_RESERVATION_REQUEST,
    payload: data
})

export const placeReservationRequestSuccess = data => ({
    type: ReservationActionTypes.PLACE_RESERVATION_REQUEST_SUCCESS,
    payload: data
})

export const placeReservationRequestError = data => ({
    type: ReservationActionTypes.PLACE_RESERVATION_REQUEST_ERROR,
    payload: data
})

export const placeReservationRequestStatus = data => ({
    type: ReservationActionTypes.PLACE_RESERVATION_REQUEST_STATUS,
    payload: data
})

//Reservation List
export const reservationListRequest = data => ({
    type: ReservationActionTypes.RESERVATION_LIST_REQUEST,
    payload: data
})

export const reservationListRequestSuccess = data => ({
    type: ReservationActionTypes.RESERVATION_LIST_REQUEST_SUCCESS,
    payload: data
})

export const reservationListRequestError = data => ({
    type: ReservationActionTypes.RESERVATION_LIST_REQUEST_ERROR,
    payload: data
})

//Reservation Detail
export const reservationDetailRequest = data => ({
    type: ReservationActionTypes.RESERVATION_DETAIL_REQUEST,
    payload: data
})

export const reservationDetailRequestSuccess = data => ({
    type: ReservationActionTypes.RESERVATION_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const reservationDetailRequestError = data => ({
    type: ReservationActionTypes.RESERVATION_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update Reservation Status
export const updateReservationStatusRequest = data => ({
    type: ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST,
    payload: data
})

export const updateReservationStatusRequestSuccess = data => ({
    type: ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const updateReservationStatusRequestError = data => ({
    type: ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST_ERROR,
    payload: data
})

export const updateReservationStatusRequestStatus = data => ({
    type: ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST_STATUS,
    payload: data
})