export const RequestTicketActionTypes = {
    RAISE_REQUEST_TICKET_REQUEST: 'RAISE_REQUEST_TICKET_REQUEST',
    RAISE_REQUEST_TICKET_REQUEST_SUCCESS: 'RAISE_REQUEST_TICKET_REQUEST_SUCCESS',
    RAISE_REQUEST_TICKET_REQUEST_ERROR: 'RAISE_REQUEST_TICKET_REQUEST_ERROR',
    RAISE_REQUEST_TICKET_REQUEST_STATUS: 'RAISE_REQUEST_TICKET_REQUEST_STATUS',

    REQUEST_TICKET_LIST_REQUEST: 'REQUEST_TICKET_LIST_REQUEST',
    REQUEST_TICKET_LIST_REQUEST_SUCCESS: 'REQUEST_TICKET_LIST_REQUEST_SUCCESS',
    REQUEST_TICKET_LIST_REQUEST_ERROR: 'REQUEST_TICKET_LIST_REQUEST_ERROR',

    REQUEST_TICKET_UPDATE_DETAIL_REQUEST: 'REQUEST_TICKET_UPDATE_DETAIL_REQUEST',
    REQUEST_TICKET_UPDATE_DETAIL_REQUEST_SUCCESS: 'REQUEST_TICKET_UPDATE_DETAIL_REQUEST_SUCCESS',
    REQUEST_TICKET_UPDATE_DETAIL_REQUEST_ERROR: 'REQUEST_TICKET_UPDATE_DETAIL_REQUEST_ERROR',
    REQUEST_TICKET_UPDATE_DETAIL_REQUEST_STATUS: 'REQUEST_TICKET_UPDATE_DETAIL_REQUEST_STATUS',

    REQUEST_TICKET_DETAIL_REQUEST: 'REQUEST_TICKET_DETAIL_REQUEST',
    REQUEST_TICKET_DETAIL_REQUEST_SUCCESS: 'REQUEST_TICKET_DETAIL_REQUEST_SUCCESS',
    REQUEST_TICKET_DETAIL_REQUEST_ERROR: 'REQUEST_TICKET_DETAIL_REQUEST_ERROR',

    LOGOUT: 'LOGOUT',

}
