import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import {
    employeeListRequest, removeEmployeeRequest, removeEmployeeRequestStatus
} from '../../../redux/employee/employee.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../components/button/top-button/topButton'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";

const EmployeeListScreen = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { employeeListSuccess, employeeListError,
        removeEmployeeSuccess, removeEmployeeError, removeEmployeeStatus } = useSelector(state => state.employee)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    let navigate = useNavigate();

    const EMPLOYEE_LIST = 'EMPLOYEE_LIST'
    const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
    const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE'

    useEffect(() => {
        const data = {
            screen: 'EMPLOYEE_LIST',
            screenClass: 'EMPLOYEE_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(employeeListSuccess)) {
            setLoading(false)
        } else if (!isNull(employeeListError)) {
            setLoading(false)

        }
    }, [employeeListSuccess, employeeListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.EMPLOYEE_LIST, true)
            isFirstTime = true
            const data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchEmployeeList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(employeeListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(employeeListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.EMPLOYEE_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.EMPLOYEE_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (removeEmployeeStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(removeEmployeeSuccess.message)
            fetchEmployeeList()
        }
        if (removeEmployeeStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displaySuccessToast(removeEmployeeError.message)
        }
        if (removeEmployeeStatus.length) {
            dispatch(removeEmployeeRequestStatus(''))
        }
    }, [removeEmployeeSuccess, removeEmployeeError, removeEmployeeStatus])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedLocation)) {
            fetchEmployeeList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchEmployeeList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const handleCardClick = item => {
        if (checkFunctionalityPermission(REMOVE_EMPLOYEE) === true) {
            const data = {
                employeeId: item.userId
            }
            if (isLoggedInUserBusiness(user) === true) {
                data.businessId = selectedRestaurant.businessId
                data.locationId = selectedLocation.locationId
            }
            if (isLoggedInUserEmployee(user) === true) {
                data.businessId = employeeSelectedRestaurant
                data.locationId = employeeSelectedLocation
            }
            setLoading(true)
            dispatch(removeEmployeeRequest(data))
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleAddEmpClick = () => {
        if (checkFunctionalityPermission(ADD_EMPLOYEE) === true) {
            navigate(ROUTES.addEmployee)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.EMPLOYEE && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === EMPLOYEE_LIST) {
                    if (itemObj.employeeList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === ADD_EMPLOYEE) {
                    if (itemObj.addEmployee === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === REMOVE_EMPLOYEE) {
                    if (itemObj.removeEmployee === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Employees' />
                    <TopButton label='Add Employee'
                        onClick={() => { handleAddEmpClick() }} />
                </CommonInputRow>
                {isLoggedInUserBusiness(user) === true && <CommonInputRow className='margin-top-10'>
                    <div className="el-select-container">
                        <div className="el-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='el-list-select'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    <div className="el-select-container">
                        <div className="el-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='el-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>
                </CommonInputRow>}
                {checkFunctionalityPermission(EMPLOYEE_LIST) === true && <div className="table-scroll-container">
                    {employeeListSuccess && employeeListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Mobile</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.otherDetails[0].firstName} {element.otherDetails[0].lastName}</td>
                                    <td className="rl-table-col-item text-center">{element.otherDetails[0].mobile}</td>
                                    <td className={`rl-table-col-item text-center ${element.otherDetails[0].status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.otherDetails[0].status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.otherDetails[0].createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>Remove</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(EMPLOYEE_LIST) === true && employeeListError && <div className="content-error">No Employee Found</div>}
                {checkFunctionalityPermission(EMPLOYEE_LIST) === false && <div className="content-error">You are not authorized to view employee list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeListScreen