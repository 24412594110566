import React, { useState, useEffect } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { addSubscriptionRequest, addSubscriptionRequestSuccess } from '../../../../redux/user/user.action'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import TopButton from "../../../../components/button/top-button/topButton";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import { displayErrorToast, displaySuccessToast, isEmpty } from "../../../../utils/Utils";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import CommonButton from "../../../../components/button/common-button/commonButton";
import ButtonRow from "../../../../components/button/button-row/buttonRow";

const AddSubscription = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, addSubscriptionSuccess, addSubscriptionError, addSubscriptionReqStatus } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [title, setTitle] = useState('')
    const [restaurantCount, setRestaurantCount] = useState('')
    const [price, setPrice] = useState('')
    const [priceType, setPriceType] = useState('')
    const [feature, setFeature] = useState('')
    const [featureList, setFeatureList] = useState([])
    const [isCustomize, setIsCustomize] = useState(false)
    const [isFree, setIsFree] = useState(false)
    const [isOnlineMenu, setIsOnlineMenu] = useState(false)
    const [isOnlineOrdering, setIsOnlineOrdering] = useState(false)
    const [isReservation, setIsReservation] = useState(false)
    const [isEmployee, setIsEmployee] = useState(false)

    useEffect(() => {
        if (addSubscriptionReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addSubscriptionSuccess.message)
            clearFields()
        } else if (addSubscriptionReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addSubscriptionError.message)
        }
        if (addSubscriptionReqStatus?.length > 0) {
            dispatch(addSubscriptionRequestSuccess(''))
        }
    }, [addSubscriptionSuccess, addSubscriptionError, addSubscriptionReqStatus])

    const clearFields = () => {
        setTitle('')
        setRestaurantCount()
        setPrice('')
        setPriceType('')
        setFeature('')
        setFeatureList([])
        setIsCustomize(false)
    }

    const handleSubmitClick = () => {
        if (isEmpty(title)) {
            displayErrorToast('Title cannot be blank')
        } else if (isCustomize === false && isEmpty(restaurantCount)) {
            displayErrorToast('Restaurant limit cannot be blank')
        } else if ((isCustomize === false || isFree === false) && isEmpty(price)) {
            displayErrorToast('Plan price cannot be blank')
        } else if (isEmpty(featureList)) {
            displayErrorToast('Feature list cannot be blank')
        } else {
            setLoading(true)
            const permissionList = ['RESTAURANT', 'LOCATION']
            const data = {
                title: title,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                userId: user.data.userDetails.userId,
                isCustomize: isCustomize,
                isTrailSubscription: isFree,
                durationType: 'MONTHLY',
                duration: 1,
                restaurantCount: restaurantCount,
                priceType: priceType,
                features: featureList,
                price: price
            }
            if (isOnlineMenu === true) {
                permissionList.push('ONLINE_MENU')
            }
            if (isOnlineOrdering === true) {
                permissionList.push('ONLINE_ORDERING')
            }
            if (isReservation === true) {
                permissionList.push('RESERVATION_MANAGEMENT')
            }
            if (isEmployee === true) {
                permissionList.push('EMPLOYEE_MANAGEMENT')
            }
            data.permissions = permissionList
            // if (isCustomize === false && isFree === false) {
            //     data.price = price
            // }
            dispatch(addSubscriptionRequest(data))
        }
    }

    const handleAddFeatureClick = () => {
        if (isEmpty(feature)) {
            displayErrorToast('Feature cannot be blank')
        } else {
            setFeatureList([...featureList, feature])
            setFeature('')
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add New Plan' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new subscription plan' />
                    <CommonInputFull
                        type='text'
                        id='title'
                        placeholder='Title'
                        value={title}
                        onChange={e => setTitle(e.target.value)} />
                    <CommonInputFull
                        type='number'
                        id='restaurantCount'
                        placeholder='Restaurant limit'
                        value={restaurantCount}
                        onChange={e => setRestaurantCount(e.target.value)} />
                    <CommonInputRow>
                        <CommonInputHalf
                            type='number'
                            id='price'
                            placeholder='Price'
                            value={price}
                            onChange={e => setPrice(e.target.value)}
                        />
                        <CommonInputHalf
                            type='text'
                            id='priceType'
                            placeholder='Price Type'
                            value={priceType}
                            onChange={e => setPriceType(e.target.value)}
                        />
                    </CommonInputRow>
                    <div className="as-permission-cb-row-item">
                        <input
                            type="checkbox"
                            className="form-check-input p-input-text"
                            id="name"
                            checked={isCustomize}
                            value={isCustomize}
                            onChange={() => {
                                setIsCustomize(!isCustomize)
                            }}
                            required />
                        <label for="name" className="p-checkbox-label">Is Customized Price</label>
                    </div>
                    <FormFieldLabel label='Permissions' />
                    <div className="as-permission-cb-row-container">
                        <div className="as-permission-cb-row-item">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isOnlineMenu}
                                value={isOnlineMenu}
                                onChange={() => {
                                    setIsOnlineMenu(!isOnlineMenu)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Online Menu</label>
                        </div>
                        <div className="as-permission-cb-row-item">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isOnlineOrdering}
                                value={isOnlineOrdering}
                                onChange={() => {
                                    setIsOnlineOrdering(!isOnlineOrdering)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Online Ordering</label>
                        </div>
                        <div className="as-permission-cb-row-item">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isReservation}
                                value={isReservation}
                                onChange={() => {
                                    setIsReservation(!isReservation)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Reservation Management</label>
                        </div>
                        <div className="as-permission-cb-row-item">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isEmployee}
                                value={isEmployee}
                                onChange={() => {
                                    setIsEmployee(!isEmployee)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Employee Management</label>
                        </div>
                    </div>

                    <div className="as-feature-input-container">
                        <CommonInputFull
                            type='text'
                            id='feature'
                            onChange={e => setFeature(e.target.value)}
                            placeholder='Feature'
                            value={feature} />
                        <div className="as-feature-add-btn" onClick={() => { handleAddFeatureClick() }}>
                            Add
                        </div>
                    </div>
                    <div className="as-feature-item-row-container">
                        {!isEmpty(featureList) && featureList.map(item => (<div className="as-feature-item-row-item">{item}</div>))}
                    </div>
                    <ButtonRow>
                        <CommonButton label='Submit' onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>

                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddSubscription