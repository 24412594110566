import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addComment, addFeatureRequest, featureRequestDetail, featureRequestList,
    upVoteFeatureRequest
} from '../api-requests/featureRequest';
import {
    addFeatureRequestReqError, addFeatureRequestReqSuccess, featureRequestAddCommentReqError,
    featureRequestAddCommentReqSuccess, featureRequestListReqError, featureRequestListReqSuccess,
    feautreRequestDetailReqError, feautreRequestDetailReqSuccess,
    featureRequestUpVoteReqError, featureRequestUpVoteReqSuccess
} from './featureRequest.action';
import { FeatureRequestActionTypes } from './featureRequest.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleFeatureRequestUpVote({ payload }) {
    try {
        const response = yield call(upVoteFeatureRequest, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(featureRequestUpVoteReqSuccess(decryptedData))
            } else {
                yield put(featureRequestUpVoteReqError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(featureRequestUpVoteReqError(error.response.data))
    }
}

export function* featureRequestUpVoteReq() {
    yield takeLatest(FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST, handleFeatureRequestUpVote)
}

function* handleFeatureRequestList({ payload }) {
    try {
        const response = yield call(featureRequestList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(featureRequestListReqSuccess(decryptedData))
            } else {
                yield put(featureRequestListReqError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(featureRequestListReqError(error.response.data))
    }
}

export function* featureRequestListReq() {
    yield takeLatest(FeatureRequestActionTypes.FEATURE_REQUEST_LIST_REQUEST, handleFeatureRequestList)
}

function* handleFeatureRequestDetail({ payload }) {
    try {
        const response = yield call(featureRequestDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(feautreRequestDetailReqSuccess(decryptedData))
            } else {
                yield put(feautreRequestDetailReqError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(feautreRequestDetailReqError(error.response.data))
    }
}

export function* featureRequestDetailReq() {
    yield takeLatest(FeatureRequestActionTypes.FEATURE_REQUEST_DETAIL_REQUEST, handleFeatureRequestDetail)
}

function* handleAddFeatureRequest({ payload }) {
    try {
        const response = yield call(addFeatureRequest, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addFeatureRequestReqSuccess(decryptedData))
            } else {
                yield put(addFeatureRequestReqError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addFeatureRequestReqError(error.response.data))
    }
}

export function* addFeatureRequestReq() {
    yield takeLatest(FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST, handleAddFeatureRequest)
}

function* handleAddFeatureRequestComment({ payload }) {
    try {
        const response = yield call(addComment, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(featureRequestAddCommentReqSuccess(decryptedData))
            } else {
                yield put(featureRequestAddCommentReqError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(featureRequestAddCommentReqError(error.response.data))
    }
}

export function* addFeatureRequestCommentReq() {
    yield takeLatest(FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST, handleAddFeatureRequestComment)
}