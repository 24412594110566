import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog,
    displayErrorToast, displaySuccessToast, isNull
} from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { categoryListRequest, subCategoryListRequest } from '../../../../redux/category/category.action'
import { config } from '../../../../config'
import Select from 'react-select'
import ScreenLabel from "../../../../components/screen-label/screenLabel";

const SubCategoryListScreen = props => {
    const [selectedCat, setSelectedCat] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { categoryList, categoryListError, subCategoryList, subCategoryListError } = useSelector(state => state.category)
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            businessId: user.data.userDetails.userId
        }
        dispatch(categoryListRequest(data))
    }, [])

    useEffect(() => {
        if (!isNull(subCategoryList)) {
            setLoading(false)
        } else if (!isNull(subCategoryListError)) {
            setLoading(false)
        }
    }, [subCategoryList, subCategoryListError])

    useEffect(() => {
        if (!isNull(selectedCat)) {
            setLoading(true)
            const data = {
                categoryId: selectedCat.categoryId
            }
            dispatch(subCategoryListRequest(data))
        }
    }, [selectedCat])

    const addSubCategoryClickHandle = () => {
        navigate(ROUTES.addSubCategory)
    }

    const handleSelectedCatChange = event => {
        setSelectedCat(event)
    }

    const enableClickHandle = () => {

    }
    const disableClickHandle = () => {

    }

    return (
        <>
            <div className="sc-list-container">
                <ScreenLabel label='Subcategories' />
                <div className="add-sc-btn-container">
                    {categoryList && categoryList.data && <Select
                        className='marg-right'
                        value={selectedCat}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={categoryList.data}
                        placeholder='Select Category'
                        onChange={handleSelectedCatChange} />}
                    {!categoryList && <div>No Category found</div>}
                    <div className="btn add-sc-btn" onClick={() => addSubCategoryClickHandle()}>Add Subcategory</div>
                </div>
                <div className="sc-card-container">
                    {subCategoryList && subCategoryList.data && subCategoryList.data.map(element => (
                        <div className="card sc-card">
                            <img src={`${config.imageUrl}${element.image}`} className="card-img-top" alt="..." />
                            <div className="sc-card-title">{element.name}</div>
                        </div>
                    ))}
                </div>
                {categoryListError && <div>No Category Found</div>}
                {subCategoryListError && <div>No Sub-Category Found</div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SubCategoryListScreen