import axios from 'axios'
import { config } from '../../../config'
import { APP_CONSTANTS } from '../../../utils/AppConstants'
import API_ENDPOINTS from '../endpoints'

export function addProduct(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.product.addProduct}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function productList(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.product.productList}`,
        params: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function productDetails(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.product.productDetails}/${payload}`,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateProduct(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.product.updateProduct}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateProductConsumption(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.product.updateProductConsumption}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}