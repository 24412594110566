import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog,
    displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { subCategoryListRequest } from '../../../redux/category/category.action'
import { config } from '../../../config'
import ScreenLabel from "../../../components/screen-label/screenLabel";

const SubMenuCategory = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const { userDetailSuccess } = useSelector(state => state.user)
    const { subCategoryList, subCategoryListError } = useSelector(state => state.category)
    let navigate = useNavigate();
    const { state } = useLocation()
    displayConsoleLog('---state', state)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_MENU_SUB_CATEGORY',
            screenClass: 'CUSTOMER_MENU_SUB_CATEGORY'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        setLoading(true)
        const data1 = {
            categoryId: state
        }
        dispatch(subCategoryListRequest(data1))
    }, [])

    useEffect(() => {
        if (!isNull(subCategoryList)) {
            setLoading(false)
        } else if (!isNull(subCategoryListError)) {
            setLoading(false)
        }
    }, [subCategoryList, subCategoryListError])

    const itemClick = item => {
        navigate(ROUTES.menuItemList, { state: { categoryId: item.categoryId, subCat: item.subCategoryId } });
    }

    return (
        <>
            <div className='smc-container mb-4'>
                {(!isNull(userDetailSuccess) && !isNull(userDetailSuccess.data)) ? (
                    <div className="smc-label-container">
                        <div className="smc-logo-container">
                            <img className="smc-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />
                        </div>
                        <ScreenLabel label={`${userDetailSuccess.data.businessName} Menu`} />
                    </div>
                ) :
                    (<div className="smc-label-container">
                        <ScreenLabel label='Menu Subcategory' />
                    </div>)}
                <div className="smc-card-container">
                    {subCategoryList && subCategoryList.data && subCategoryList.data.map(element => (
                        <div className="smc-row-container" onClick={() => { itemClick(element) }}>
                            <div className="smc-img-container">
                                <img src={`${config.imageUrl}${element.image}`} className="smc-img" alt="..." />
                            </div>
                            <div className="smc-card-title">{element.name}</div>
                            <div className="smc-icon-container">
                                <img src={require('../../../assets/right_arrow.png')} className="smc-img" alt="..." />
                            </div>

                        </div>
                    ))}
                </div>
                {subCategoryListError && <div>No Subcategory Found</div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SubMenuCategory