import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function findPayAtEndOrderAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.findPayAtEndOrder}`,
        params: payload
    })
}

export function placeOrderAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.placeOrder}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateCustomerActionAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.updateCustomerAction}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateExistingOrderAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.updateExistingOrder}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function acceptPayAtEndPaymentAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.acceptPayAtEndPayment}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function orderListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.orderList}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function orderDetailAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.orderDetail}/${payload.orderId}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateOrderStatusAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.updateStatus}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function acceptRejectOrderAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.acceptOrRejectOrder}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateOrderTransactionAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.updateTransactionStatus}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function orderDashboardAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.dashboard}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function orderGeneratePaymentLinkAPI(payload) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.order.orderPaymentLinkCreate}`,
        data: payload
    })
}