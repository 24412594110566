import { APP_CONSTANTS, ROUTES } from "./utils/AppConstants";

const local = {
    baseUrl: 'http://localhost:3006/gomobites/v1',
    imageUrl: 'http://localhost:3006/',
    socketUrl: 'http://localhost:3006',
    qrCodeUrl: `http://localhost:3000${ROUTES.menuCatList}`,
    orderListBoardUrl: `http://localhost:3000${ROUTES.orderListBoard}`,
    loginUrl: `http://localhost:3000${ROUTES.signIn}`,
    clientUrl: `http://localhost:3000`,
    webBaseUrl: 'http://localhost:3000',
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.LOCAL,
    KEY: 'GOMOBITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

const localCustomer = {
    baseUrl: 'https://dev-api.gomobites.com/gomobites/v1',
    imageUrl: 'https://dev-api.gomobites.com/',
    socketUrl: 'https://dev-api.gomobites.com',
    qrCodeUrl: `https://localhost:3000${ROUTES.menuCatList}`,
    orderListBoardUrl: `https://localhost:3000${ROUTES.orderListBoard}`,
    loginUrl: `http://localhost:3000${ROUTES.signIn}`,
    clientUrl: `http://localhost:3000`,
    webBaseUrl: `http://localhost:3000`,
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.LOCAL_CUSTOMER,
    KEY: 'GOMOBITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

const dev = {
    baseUrl: 'https://dev-api.gomobites.com/gomobites/v1',
    imageUrl: 'https://dev-api.gomobites.com/',
    socketUrl: 'https://dev-api.gomobites.com',
    qrCodeUrl: `https://try.gomobites.com${ROUTES.menuCatList}`,
    orderListBoardUrl: `https://try.gomobites.com${ROUTES.orderListBoard}`,
    loginUrl: `https://try.gomobites.com${ROUTES.signIn}`,
    clientUrl: `https://try.gomobites.com`,
    webBaseUrl: `https://try.gomobites.com`,
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.DEV,
    KEY: 'GOMOBITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

const prod = {
    baseUrl: 'https://api.gomobites.com/gomobites/v1',
    imageUrl: 'https://api.gomobites.com/',
    socketUrl: 'https://api.gomobites.com',
    qrCodeUrl: `https://www.gomobites.com${ROUTES.menuCatList}`,
    orderListBoardUrl: `https://www.gomobites.com${ROUTES.orderListBoard}`,
    loginUrl: `https://www.gomobites.com${ROUTES.signIn}`,
    clientUrl: `https://www.gomobites.com`,
    webBaseUrl: `https://www.gomobites.com`,
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.PROD,
    KEY: 'GOMOBITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

export const config = prod