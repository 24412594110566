import { FeatureRequestActionTypes } from './featureRequest.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addFeatureRequestSuccess: null,
    addFeatureRequestError: null,
    addFeatureRequestReqStatus: '',
    featureRequestListSuccess: null,
    featureRequestListError: null,
    featureRequestDetailSuccess: null,
    featureRequestDetailError: null,
    featureRequestAddCommentSuccess: null,
    featureRequestAddCommentError: null,
    featureRequestAddCommentReqStatus: '',
    upVoteReqSuccess: null,
    upVoteReqError: null,
    upVoteReqStaus: ''
}

const featureRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST_SUCCESS:
            return {
                ...state,
                addFeatureRequestSuccess: action.payload,
                addFeatureRequestError: null,
                addFeatureRequestReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST_ERROR:
            return {
                ...state,
                addFeatureRequestSuccess: null,
                addFeatureRequestError: action.payload,
                addFeatureRequestReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST_STATUS:
            return {
                ...state,
                addFeatureRequestReqStatus: ''
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                featureRequestListSuccess: action.payload,
                featureRequestListError: null
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_LIST_REQUEST_ERROR:
            return {
                ...state,
                featureRequestListSuccess: null,
                featureRequestListError: action.payload
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                featureRequestDetailSuccess: action.payload,
                featureRequestDetailError: null
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                featureRequestDetailSuccess: null,
                featureRequestDetailError: action.payload
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST_SUCCESS:
            return {
                ...state,
                featureRequestAddCommentSuccess: action.payload,
                featureRequestAddCommentError: null,
                featureRequestAddCommentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST_ERROR:
            return {
                ...state,
                featureRequestAddCommentSuccess: null,
                featureRequestAddCommentError: action.payload,
                featureRequestAddCommentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST_STATUS:
            return {
                ...state,
                featureRequestAddCommentReqStatus: ''
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST_SUCCESS:
            return {
                ...state,
                upVoteReqSuccess: action.payload,
                upVoteReqError: null,
                upVoteReqStaus: API_CONSTANTS.SUCCESS_STATUS
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST_ERROR:
            return {
                ...state,
                upVoteReqSuccess: null,
                upVoteReqError: action.payload,
                upVoteReqStaus: API_CONSTANTS.ERROR_STATUS
            }
        case FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST_STATUS:
            return {
                ...state,
                upVoteReqStaus: ''
            }
        case FeatureRequestActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default featureRequestReducer