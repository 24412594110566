export const ContactUsActionTypes = {
    LOGOUT: 'LOGOUT',

    CONTACT_US_ADD_REQUEST: 'CONTACT_US_ADD_REQUEST',
    CONTACT_US_ADD_REQUEST_SUCCESS: 'CONTACT_US_ADD_REQUEST_SUCCESS',
    CONTACT_US_ADD_REQUEST_ERROR: 'CONTACT_US_ADD_REQUEST_ERROR',
    CONTACT_US_ADD_REQUEST_STATUS: 'CONTACT_US_ADD_REQUEST_STATUS',

    CONTACT_US_LIST_REQUEST: 'CONTACT_US_LIST_REQUEST',
    CONTACT_US_LIST_REQUEST_SUCCESS: 'CONTACT_US_LIST_REQUEST_SUCCESS',
    CONTACT_US_LIST_REQUEST_ERROR: 'CONTACT_US_LIST_REQUEST_ERROR',

    CONTACT_US_DETAIL: 'CONTACT_US_DETAIL',
    CONTACT_US_DETAIL_SUCCESS: 'CONTACT_US_DETAIL_SUCCESS',
    CONTACT_US_DETAIL_ERROR: 'CONTACT_US_DETAIL_ERROR',

    CONTACT_US_UPDATE_STATUS_REQUEST: 'CONTACT_US_UPDATE_STATUS_REQUEST',
    CONTACT_US_UPDATE_STATUS_REQUEST_SUCCESS: 'CONTACT_US_UPDATE_STATUS_REQUEST_SUCCESS',
    CONTACT_US_UPDATE_STATUS_REQUEST_ERROR: 'CONTACT_US_UPDATE_STATUS_REQUEST_ERROR',
    CONTACT_US_UPDATE_STATUS_REQUEST_STATUS: 'CONTACT_US_UPDATE_STATUS_REQUEST_STATUS'
}
