import { ReservationActionTypes } from './reservation.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    placeReservationSuccess: null,
    placeReservationError: null,
    placeReservationReqStatus: '',
    reservationListSuccess: null,
    reservationListError: null,
    reservationDetailSuccess: null,
    reservationDetailError: null,
    updateReservationSuccess: null,
    updateReservationError: null,
    updateReservationReqStatus: ''
}

const reservationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReservationActionTypes.PLACE_RESERVATION_REQUEST_SUCCESS:
            return {
                ...state,
                placeReservationSuccess: action.payload,
                placeReservationError: null,
                placeReservationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ReservationActionTypes.PLACE_RESERVATION_REQUEST_ERROR:
            return {
                ...state,
                placeReservationSuccess: null,
                placeReservationError: action.payload,
                placeReservationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ReservationActionTypes.PLACE_RESERVATION_REQUEST_STATUS:
            return {
                ...state,
                placeReservationReqStatus: ''
            }
        case ReservationActionTypes.RESERVATION_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                reservationListSuccess: action.payload,
                reservationListError: null
            }
        case ReservationActionTypes.RESERVATION_LIST_REQUEST_ERROR:
            return {
                ...state,
                reservationListSuccess: null,
                reservationListError: action.payload
            }
        case ReservationActionTypes.RESERVATION_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                reservationDetailSuccess: action.payload,
                reservationDetailError: null
            }
        case ReservationActionTypes.RESERVATION_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                reservationDetailSuccess: null,
                reservationDetailError: action.payload
            }
        case ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateReservationSuccess: action.payload,
                updateReservationError: null,
                updateReservationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateReservationSuccess: null,
                updateReservationError: action.payload,
                updateReservationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateReservationReqStatus: ''
            }
        case ReservationActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default reservationReducer