import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { invOrderInvoiceListRequest } from '../../../../redux/inventory/order-invoice/orderInvoice.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";

const InvOrderInvoiceList = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { invOrderInvoiceListSuccess, invOrderInvoiceListError } = useSelector(state => state.invOrderInvoice)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    let navigate = useNavigate();
    const ORDER_INVOICE_LIST = 'ORDER_INVOICE_LIST'
    const UPDATE_ORDER_INVOICE = 'UPDATE_ORDER_INVOICE'
    const ADD_ORDER_INVOICE = 'ADD_ORDER_INVOICE'

    useEffect(() => {
        if (!isNull(invOrderInvoiceListSuccess)) {
            setLoading(false)
        } else if (!isNull(invOrderInvoiceListError)) {
            setLoading(false)
        }
    }, [invOrderInvoiceListSuccess, invOrderInvoiceListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ORDER_INVOICE_LIST, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchOrderInvoiceList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(invOrderInvoiceListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(invOrderInvoiceListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.INVENTORY.ORDER_INVOICE_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ORDER_INVOICE_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchOrderInvoiceList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchOrderInvoiceList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const handleCardClick = (item) => {
        if (checkFunctionalityPermission(UPDATE_ORDER_INVOICE) === true) {
            navigate(ROUTES.inventory.orderInvoiceDetail, { state: item.invOrderInvoiceId })
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleAddOrderInvoiceClick = () => {
        if (checkFunctionalityPermission(ADD_ORDER_INVOICE) === true) {
            navigate(ROUTES.inventory.addOrderInvoice)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.INVENTORY.ORDER_INVOICE && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            displayConsoleLog('itemObj', itemObj)
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === ORDER_INVOICE_LIST) {
                    if (itemObj.orderInvoiceList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === UPDATE_ORDER_INVOICE) {
                    if (itemObj.updateOrderInvoice === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === ADD_ORDER_INVOICE) {
                    if (itemObj.addOrderInvoice === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Order Invoices' />
                    <TopButton label='Add Order Invoice'
                        isSmall={true}
                        onClick={() => { handleAddOrderInvoiceClick() }} />
                </CommonInputRow>
                {isLoggedInUserBusiness(user) === true && <CommonInputRow>
                    <div className="incl-select-container">
                        <div className="incl-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='select-full-width-no-border'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="content-error">No restaurant found</div>}
                    </div>
                    <div className="incl-select-container">
                        <div className="incl-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="content-error">No location found</div>}
                    </div>
                </CommonInputRow>}
                {checkFunctionalityPermission(ORDER_INVOICE_LIST) === true && <div className="table-scroll-container">
                    {!isEmpty(invOrderInvoiceListSuccess) && !isEmpty(invOrderInvoiceListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="incl-table-col-title">Invoice Number</th>
                                <th scope="col" className="incl-table-col-title text-center">Amount</th>
                                <th scope="col" className="incl-table-col-title text-center">Payment Status</th>
                                <th scope="col" className="incl-table-col-title text-center">Payment Mode</th>
                                <th scope="col" className="incl-table-col-title text-center">Date</th>
                                <th scope="col" className="incl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invOrderInvoiceListSuccess.data.map(element => (
                                <tr className="incl-table-row-container">
                                    <td className="incl-table-col-item">{element.orderInvoiceNumber}</td>
                                    <td className="incl-table-col-item text-center">{element.billAmountFinalPrice}</td>
                                    <td className="incl-table-col-item text-center">{!isEmpty(element.orderInvoicePaymentStatus) ? removeSpecialCharAndChangeToPascal(element.orderInvoicePaymentStatus) : '-'}</td>
                                    <td className="incl-table-col-item text-center">{!isEmpty(element.orderInvoicePaymentMode) ? element.orderInvoicePaymentMode : '-'}</td>
                                    <td className="incl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="incl-table-col-item text-center align-middle">
                                        <div className="incl-btn-container" onClick={() => { handleCardClick(element) }}>Details</div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(ORDER_INVOICE_LIST) === true && !isEmpty(invOrderInvoiceListError) && <div className="content-error">{invOrderInvoiceListError.message}</div>}
                {checkFunctionalityPermission(ORDER_INVOICE_LIST) === false && invOrderInvoiceListError && <div className="content-error">You are not authorized to view order invoice list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvOrderInvoiceList