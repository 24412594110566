import React, { useEffect, useState, useRef, useContext } from 'react'
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics
} from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS } from '../../../../utils/AppConstants';
import Peer from 'simple-peer';
import { SocketContext } from '../../../../SocketCtx'
import CommonForm from '../../../../components/common-form/commonForm';

const DeliveryTerminalDashboardScreen = props => {
    const [isLoading, setLoading] = useState(false)
    const { loginTerminalSuccess, loginTerminalError, user } = useSelector((state) => state.user)
    const { userCalled, callEnded } = useSelector(state => state.socket)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const socket = useContext(SocketContext)
    const [stream, setStream] = useState()
    const [receivingCall, setReceivingCall] = useState(false)
    const [caller, setCaller] = useState("")
    const [callAccepted, setCallAccepted] = useState(false)
    const [callEndedd, setCallEndedd] = useState(false)
    const [name, setName] = useState("")
    const myVideo = useRef()
    const userVideo = useRef()
    const connectionRef = useRef()

    useEffect(() => {
        const data = {
            screen: 'TERMINAL_DASHBOARD',
            screenClass: 'TERMINAL_DASHBOARD'
        }
        screenCaptureAnalytics(data)
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then((stream) => {
                setStream(stream)
                if (myVideo.current)
                    myVideo.current.srcObject = stream
            })
    }, [])

    useEffect(() => {
        if (!isNull(callEnded)) {
            console.log('callEnded', callEnded);
            console.log('user.data.userDetails.terminalId', user.data.userDetails.terminalId);
            if (callEnded.to === user.data.userDetails.terminalId) {
                setCallEndedd(true)
                connectionRef.current.destroy()
                dispatch({ type: APP_CONSTANTS.UPDATE_CALL_STATE })
                window.location.reload()
            }
        }
    }, [callEnded])

    useEffect(() => {
        if (!isNull(userCalled)) {
            if (userCalled.to === user.data.userDetails.terminalId) {
                if (!isNull(stream)) {
                    setCaller(userCalled.from)
                    setCallAccepted(true)
                    const peer = new Peer({
                        initiator: false,
                        trickle: false,
                        stream: stream,
                        config: {
                            iceServers: [
                                {
                                    urls: 'turn:turn.gomobites.com:3478',
                                    credential: '4321',
                                    username: 'guest'
                                },
                                {
                                    urls: 'stun:stun.gomobites.com:3478',
                                    credential: '4321',
                                    username: 'guest'
                                }]
                        }
                    })
                    connectionRef.current = peer
                    console.log('---caller-userCalled.from', userCalled.from);
                    console.log('---caller', caller);
                    connectionRef.current.on("signal", (data) => {
                        socket.emit("answerCall", { signal: data, to: caller })
                    })
                    // connectionRef.current.on("stream", (stream) => {
                    //     console.log('--stream', stream);
                    //     userVideo.current.srcObject = stream
                    // })
                    connectionRef.current.signal(userCalled.signal)
                } else {
                    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                        .then((stream) => {
                            setStream(stream)
                            if (myVideo.current)
                                myVideo.current.srcObject = stream

                            setCaller(userCalled.from)
                            setCallAccepted(true)
                            const peer = new Peer({
                                initiator: false,
                                trickle: false,
                                stream: stream
                            })
                            connectionRef.current = peer
                            console.log('---caller-userCalled.from', userCalled.from);
                            console.log('---caller', caller);
                            peer.on("signal", (data) => {
                                socket.emit("answerCall", { signal: data, to: caller })
                            })
                            peer.on("stream", stream => {
                                userVideo.current.srcObject = stream;
                            });
                            peer.signal(userCalled.signal)
                        })
                }
            }
        }
    }, [userCalled])

    useEffect(() => {
        if (connectionRef.current) {
            connectionRef.current.on("stream", (stream) => {
                console.log('--stream', stream);
                userVideo.current.srcObject = stream

                // var video = document.querySelector('video')

                // if ('srcObject' in video) {
                //     video.srcObject = stream
                // } else {
                //     video.src = window.URL.createObjectURL(stream) // for older browsers
                // }

                // video.play()
            })
        }
    })

    const handleLogoutClick = () => {
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }

    return (
        <>
            <div className='contentAreaLDTD'>
                <div className="ldt-img-contain">
                    <img src={require('../../../../assets/NewLogo1.png')} className="ldt-img" alt='logo' />
                </div>
                <CommonForm>
                    <div className='ldtd-screen-label'>Welcome to</div>
                    <div className='ldtd-rest-label'>{loginTerminalSuccess.data.userDetails.businessDetails.businessName}</div>
                    <div className='ldtd-loc-label'>{loginTerminalSuccess.data.userDetails.locationDetails.city}, {loginTerminalSuccess.data.userDetails.locationDetails.state}, {loginTerminalSuccess.data.userDetails.locationDetails.country}</div>
                    <div className='ldtd-loc-label mt-5'>Order Point</div>
                    <div className='ldtd-rest-label'>{loginTerminalSuccess.data.userDetails.title}</div>
                </CommonForm>

                <div className='ldtd-button-container'>
                    <button type='button' className='btn ldtd-button' onClick={() => { handleLogoutClick() }}>Logout</button>
                </div>
                {stream && <div>
                    <video playsInline ref={myVideo} autoPlay className='ldtd-list-video-player' />
                </div>}
                {/* {remoteStream && <div>
                    <video playsInline ref={remoteVideo} autoPlay className='ldtd-list-remote-video-player' />
                </div>} */}
                {callAccepted && !callEndedd ?
                    <div className='ldtd-list-remote-video-player-container'> <video playsInline ref={userVideo} className='ldtd-list-remote-video-player cover' autoPlay /></div> :
                    null}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default DeliveryTerminalDashboardScreen