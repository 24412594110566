import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addEmployee, employeeDetail, employeeList, removeEmployee
} from '../api-requests/employee';
import {
    addEmployeeRequestError, addEmployeeRequestSuccess, employeeDetailRequestError,
    employeeDetailRequestSuccess, employeeListRequestError, employeeListRequestSuccess,
    removeEmployeeRequestError, removeEmployeeRequestSuccess
} from './employee.action';
import { EmployeeActionTypes } from './employee.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleRemoveEmployee({ payload }) {
    try {
        const response = yield call(removeEmployee, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(removeEmployeeRequestSuccess(decryptedData))
            } else {
                yield put(removeEmployeeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(removeEmployeeRequestError(error.response.data))
    }
}

export function* removeEmployeeReq() {
    yield takeLatest(EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST, handleRemoveEmployee)
}

function* handleEmployeeList({ payload }) {
    try {
        const response = yield call(employeeList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(employeeListRequestSuccess(decryptedData))
            } else {
                yield put(employeeListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(employeeListRequestError(error.response.data))
    }
}

export function* employeeListReq() {
    yield takeLatest(EmployeeActionTypes.EMPLOYEE_LIST_REQUEST, handleEmployeeList)
}

function* handleEmployeeDetail({ payload }) {
    try {
        const response = yield call(employeeDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(employeeDetailRequestSuccess(decryptedData))
            } else {
                yield put(employeeDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(employeeDetailRequestError(error.response.data))
    }
}

export function* employeeDetailReq() {
    yield takeLatest(EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST, handleEmployeeDetail)
}

function* handleAddEmployee({ payload }) {
    try {
        const response = yield call(addEmployee, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addEmployeeRequestSuccess(decryptedData))
            } else {
                yield put(addEmployeeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addEmployeeRequestError(error.response.data))
    }
}

export function* addEmployeeReq() {
    yield takeLatest(EmployeeActionTypes.ADD_EMPLOYEE_REQUEST, handleAddEmployee)
}