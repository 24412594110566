export const UserActionTypes = {
    USER_INFO: 'USER_INFO',
    LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_REQUEST_SUCCESS: 'REGISTER_REQUEST_SUCCESS',
    REGISTER_REQUEST_ERROR: 'REGISTER_REQUEST_ERROR',
    REGISTER_REQUEST_STATUS: 'REGISTER_REQUEST_STATUS',

    CUSTOMER_LOGIN_REQUEST: 'CUSTOMER_LOGIN_REQUEST',
    CUSTOMER_LOGIN_REQUEST_SUCCESS: 'CUSTOMER_LOGIN_REQUEST_SUCCESS',
    CUSTOMER_LOGIN_REQUEST_ERROR: 'CUSTOMER_LOGIN_REQUEST_ERROR',

    SUBSCRIPTION_LIST_REQUEST: 'SUBSCRIPTION_LIST_REQUEST',
    SUBSCRIPTION_LIST_REQUEST_SUCCESS: 'SUBSCRIPTION_LIST_REQUEST_SUCCESS',
    SUBSCRIPTION_LIST_REQUEST_ERROR: 'SUBSCRIPTION_LIST_REQUEST_ERROR',

    ADD_SUBSCRIPTION_REQUEST: 'ADD_SUBSCRIPTION_REQUEST',
    ADD_SUBSCRIPTION_REQUEST_SUCCESS: 'ADD_SUBSCRIPTION_REQUEST_SUCCESS',
    ADD_SUBSCRIPTION_REQUEST_ERROR: 'ADD_SUBSCRIPTION_REQUEST_ERROR',
    ADD_SUBSCRIPTION_REQUEST_STATUS: 'ADD_SUBSCRIPTION_REQUEST_STATUS',

    BRANCH_LIST_REQUEST: 'BRANCH_LIST_REQUEST',
    BRANCH_LIST_REQUEST_SUCCESS: 'BRANCH_LIST_REQUEST_SUCCESS',
    BRANCH_LIST_REQUEST_ERROR: 'BRANCH_LIST_REQUEST_ERROR',

    USER_DETAIL_REQUEST: 'USER_DETAIL_REQUEST',
    USER_DETAIL_REQUEST_SUCCESS: 'USER_DETAIL_REQUEST_SUCCESS',
    USER_DETAIL_REQUEST_ERROR: 'USER_DETAIL_REQUEST_ERROR',

    RESTAURANT_LIST_REQUEST: 'RESTAURANT_LIST_REQUEST',
    RESTAURANT_LIST_REQUEST_SUCCESS: 'RESTAURANT_LIST_REQUEST_SUCCESS',
    RESTAURANT_LIST_REQUEST_ERROR: 'RESTAURANT_LIST_REQUEST_ERROR',

    ADD_RESTAURANT_REQUEST: 'ADD_RESTAURANT_REQUEST',
    ADD_RESTAURANT_REQUEST_SUCCESS: 'ADD_RESTAURANT_REQUEST_SUCCESS',
    ADD_RESTAURANT_REQUEST_ERROR: 'ADD_RESTAURANT_REQUEST_ERROR',
    ADD_RESTAURANT_REQUEST_STATUS: 'ADD_RESTAURANT_REQUEST_STATUS',

    ADD_BRANCH_REQUEST: 'ADD_BRANCH_REQUEST',
    ADD_BRANCH_REQUEST_SUCCESS: 'ADD_BRANCH_REQUEST_SUCCESS',
    ADD_BRANCH_REQUEST_ERROR: 'ADD_BRANCH_REQUEST_ERROR',
    ADD_BRANCH_REQUEST_STATUS: 'ADD_BRANCH_REQUEST_STATUS',

    UPDATE_BRANCH_REQUEST: 'UPDATE_BRANCH_REQUEST',
    UPDATE_BRANCH_REQUEST_SUCCESS: 'UPDATE_BRANCH_REQUEST_SUCCESS',
    UPDATE_BRANCH_REQUEST_ERROR: 'UPDATE_BRANCH_REQUEST_ERROR',
    UPDATE_BRANCH_REQUEST_STATUS: 'UPDATE_BRANCH_REQUEST_STATUS',

    RESTAURANT_DETAIL_REQUEST: 'RESTAURANT_DETAIL_REQUEST',
    RESTAURANT_DETAIL_REQUEST_SUCCESS: 'RESTAURANT_DETAIL_REQUEST_SUCCESS',
    RESTAURANT_DETAIL_REQUEST_ERROR: 'RESTAURANT_DETAIL_REQUEST_ERROR',

    DISPLAY_BOTTOM_BAR: 'DISPLAY_BOTTOM_BAR',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_REQUEST_SUCCESS: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
    FORGOT_PASSWORD_REQUEST_ERROR: 'FORGOT_PASSWORD_REQUEST_ERROR',
    FORGOT_PASSWORD_REQUEST_STATUS: 'FORGOT_PASSWORD_REQUEST_STATUS',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_REQUEST_SUCCESS: 'CHANGE_PASSWORD_REQUEST_SUCCESS',
    CHANGE_PASSWORD_REQUEST_ERROR: 'CHANGE_PASSWORD_REQUEST_ERROR',
    CHANGE_PASSWORD_REQUEST_STATUS: 'CHANGE_PASSWORD_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',
    CUSTOMER_LOGOUT: 'CUSTOMER_LOGOUT',

    CUSTOMER_RESTAURANT_LOCATION: 'CUSTOMER_RESTAURANT_LOCATION',

    COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
    COUNTRY_LIST_REQUEST_SUCCESS: 'COUNTRY_LIST_REQUEST_SUCCESS',
    COUNTRY_LIST_REQUEST_ERROR: 'COUNTRY_LIST_REQUEST_ERROR',

    STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
    STATE_LIST_REQUEST_SUCCESS: 'STATE_LIST_REQUEST_SUCCESS',
    STATE_LIST_REQUEST_ERROR: 'STATE_LIST_REQUEST_ERROR',

    CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
    CITY_LIST_REQUEST_SUCCESS: 'CITY_LIST_REQUEST_SUCCESS',
    CITY_LIST_REQUEST_ERROR: 'CITY_LIST_REQUEST_ERROR',

    RESET_STATE_CITY: 'RESET_STATE_CITY',

    AGENT_LOGIN_REQUEST: 'AGENT_LOGIN_REQUEST',
    AGENT_LOGIN_REQUEST_SUCCESS: 'AGENT_LOGIN_REQUEST_SUCCESS',
    AGENT_LOGIN_REQUEST_ERROR: 'AGENT_LOGIN_REQUEST_ERROR',

    BUSINESS_LIST_REQUEST: 'BUSINESS_LIST_REQUEST',
    BUSINESS_LIST_REQUEST_SUCCESS: 'BUSINESS_LIST_REQUEST_SUCCESS',
    BUSINESS_LIST_REQUEST_ERROR: 'BUSINESS_LIST_REQUEST_ERROR',

    TERMINAL_LOGIN_REQUEST: 'TERMINAL_LOGIN_REQUEST',
    TERMINAL_LOGIN_REQUEST_SUCCESS: 'TERMINAL_LOGIN_REQUEST_SUCCESS',
    TERMINAL_LOGIN_REQUEST_ERROR: 'TERMINAL_LOGIN_REQUEST_ERROR',

    EMPLOYEE_LOGIN_REQUEST: 'EMPLOYEE_LOGIN_REQUEST',
    EMPLOYEE_LOGIN_REQUEST_SUCCESS: 'EMPLOYEE_LOGIN_REQUEST_SUCCESS',
    EMPLOYEE_LOGIN_REQUEST_ERROR: 'EMPLOYEE_LOGIN_REQUEST_ERROR',

    ORDER_PLACE_SUCCESS_PAYLOAD: 'ORDER_PLACE_SUCCESS_PAYLOAD',

    EMPLOYEE_RESTAURANT_LOCATION_SELECTION: 'EMPLOYEE_RESTAURANT_LOCATION_SELECTION',


    SUPPORT_REGISTRATION_REQUEST: 'SUPPORT_REGISTRATION_REQUEST',
    SUPPORT_REGISTRATION_REQUEST_SUCCESS: 'SUPPORT_REGISTRATION_REQUEST_SUCCESS',
    SUPPORT_REGISTRATION_REQUEST_ERROR: 'SUPPORT_REGISTRATION_REQUEST_ERROR',
    SUPPORT_REGISTRATION_REQUEST_STATUS: 'SUPPORT_REGISTRATION_REQUEST_STATUS',

    DISPLAY_TOUR: 'DISPLAY_TOUR',

    IP_LOCATION_REQUEST: 'IP_LOCATION_REQUEST',
    IP_LOCATION_REQUEST_SUCCESS: 'IP_LOCATION_REQUEST_SUCCESS',
    IP_LOCATION_REQUEST_ERROR: 'IP_LOCATION_REQUEST_ERROR',

    USER_REGISTRATION_REQUEST: 'USER_REGISTRATION_REQUEST',
    USER_REGISTRATION_REQUEST_SUCCESS: 'USER_REGISTRATION_REQUEST_SUCCESS',
    USER_REGISTRATION_REQUEST_ERROR: 'USER_REGISTRATION_REQUEST_ERROR',

    DISPLAY_SIDE_BAR: 'DISPLAY_SIDE_BAR',

    UPDATE_NOTIFICATION_TOKEN_REQUEST: 'UPDATE_NOTIFICATION_TOKEN_REQUEST',
    UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS: 'UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS',
    UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR: 'UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR',

    RESTAURANT_ACCESS_REGISTER_REQUEST: 'RESTAURANT_ACCESS_REGISTER_REQUEST',
    RESTAURANT_ACCESS_REGISTER_REQUEST_SUCCESS: 'RESTAURANT_ACCESS_REGISTER_REQUEST_SUCCESS',
    RESTAURANT_ACCESS_REGISTER_REQUEST_ERROR: 'RESTAURANT_ACCESS_REGISTER_REQUEST_ERROR',
    RESTAURANT_ACCESS_REGISTER_REQUEST_STATUS: 'RESTAURANT_ACCESS_REGISTER_REQUEST_STATUS',

    BRANCH_DETAIL_REQUEST: 'BRANCH_DETAIL_REQUEST',
    BRANCH_DETAIL_REQUEST_SUCCESS: 'BRANCH_DETAIL_REQUEST_SUCCESS',
    BRANCH_DETAIL_REQUEST_ERROR: 'BRANCH_DETAIL_REQUEST_ERROR',

    OPERATOR_REGISTRATION_REQUEST: 'OPERATOR_REGISTRATION_REQUEST',
    OPERATOR_REGISTRATION_REQUEST_SUCCESS: 'OPERATOR_REGISTRATION_REQUEST_SUCCESS',
    OPERATOR_REGISTRATION_REQUEST_ERROR: 'OPERATOR_REGISTRATION_REQUEST_ERROR',
    OPERATOR_REGISTRATION_REQUEST_STATUS: 'OPERATOR_REGISTRATION_REQUEST_STATUS',

    OPERATOR_LIST_REQUEST: 'OPERATOR_LIST_REQUEST',
    OPERATOR_LIST_REQUEST_SUCCESS: 'OPERATOR_LIST_REQUEST_SUCCESS',
    OPERATOR_LIST_REQUEST_ERROR: 'OPERATOR_LIST_REQUEST_ERROR',

    OPERATOR_DETAIL_REQUEST: 'OPERATOR_DETAIL_REQUEST',
    OPERATOR_DETAIL_REQUEST_SUCCESS: 'OPERATOR_DETAIL_REQUEST_SUCCESS',
    OPERATOR_DETAIL_REQUEST_ERROR: 'OPERATOR_DETAIL_REQUEST_ERROR',

    UPDATE_OPERATOR_LOCATIONS_REQUEST: 'UPDATE_OPERATOR_LOCATIONS_REQUEST',
    UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS: 'UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS',
    UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR: 'UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR',
    UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS: 'UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',

    BUSINESS_LIST_BY_LOCATION_REQUEST: 'BUSINESS_LIST_BY_LOCATION_REQUEST',
    BUSINESS_LIST_BY_LOCATION_REQUEST_SUCCESS: 'BUSINESS_LIST_BY_LOCATION_REQUEST_SUCCESS',
    BUSINESS_LIST_BY_LOCATION_REQUEST_ERROR: 'BUSINESS_LIST_BY_LOCATION_REQUEST_ERROR',

    UPDATE_BUSINESS_ORDERING_STATUS_REQUEST: 'UPDATE_BUSINESS_ORDERING_STATUS_REQUEST',
    UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_SUCCESS: 'UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_SUCCESS',
    UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_ERROR: 'UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_ERROR',
    UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_STATUS: 'UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_STATUS',

    UPDATE_BRANCH_ORDERING_STATUS_REQUEST: 'UPDATE_BRANCH_ORDERING_STATUS_REQUEST',
    UPDATE_BRANCH_ORDERING_STATUS_REQUEST_SUCCESS: 'UPDATE_BRANCH_ORDERING_STATUS_REQUEST_SUCCESS',
    UPDATE_BRANCH_ORDERING_STATUS_REQUEST_ERROR: 'UPDATE_BRANCH_ORDERING_STATUS_REQUEST_ERROR',
    UPDATE_BRANCH_ORDERING_STATUS_REQUEST_STATUS: 'UPDATE_BRANCH_ORDERING_STATUS_REQUEST_STATUS',

    REGISTER_BUSINESS_USER_REQUEST: 'REGISTER_BUSINESS_USER_REQUEST',
    REGISTER_BUSINESS_USER_REQUEST_SUCCESS: 'REGISTER_BUSINESS_USER_REQUEST_SUCCESS',
    REGISTER_BUSINESS_USER_REQUEST_ERROR: 'REGISTER_BUSINESS_USER_REQUEST_ERROR',
    REGISTER_BUSINESS_USER_REQUEST_STATUS: 'REGISTER_BUSINESS_USER_REQUEST_STATUS',

    MAP_USER_WITH_BUSINESS_REQUEST: 'MAP_USER_WITH_BUSINESS_REQUEST',
    MAP_USER_WITH_BUSINESS_REQUEST_SUCCESS: 'MAP_USER_WITH_BUSINESS_REQUEST_SUCCESS',
    MAP_USER_WITH_BUSINESS_REQUEST_ERROR: 'MAP_USER_WITH_BUSINESS_REQUEST_ERROR',
    MAP_USER_WITH_BUSINESS_REQUEST_STATUS: 'MAP_USER_WITH_BUSINESS_REQUEST_STATUS',

    BUSINESS_USER_LIST_REQUEST: 'BUSINESS_USER_LIST_REQUEST',
    BUSINESS_USER_LIST_REQUEST_SUCCESS: 'BUSINESS_USER_LIST_REQUEST_SUCCESS',
    BUSINESS_USER_LIST_REQUEST_ERROR: 'BUSINESS_USER_LIST_REQUEST_ERROR',

    UPDATE_CUSTOMER_DETAILS_REQUEST: 'UPDATE_CUSTOMER_DETAILS_REQUEST',
    UPDATE_CUSTOMER_DETAILS_REQUEST_SUCCESS: 'UPDATE_CUSTOMER_DETAILS_REQUEST_SUCCESS',
    UPDATE_CUSTOMER_DETAILS_REQUEST_ERROR: 'UPDATE_CUSTOMER_DETAILS_REQUEST_ERROR',
    UPDATE_CUSTOMER_DETAILS_REQUEST_STATUS: 'UPDATE_CUSTOMER_DETAILS_REQUEST_STATUS',

    SHOW_CALL_WAITER_REQUEST: 'SHOW_CALL_WAITER_REQUEST',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE',

    UPDATE_RESTAURANT_DETAILS_REQUEST: 'UPDATE_RESTAURANT_DETAILS_REQUEST',
    UPDATE_RESTAURANT_DETAILS_REQUEST_SUCCESS: 'UPDATE_RESTAURANT_DETAILS_REQUEST_SUCCESS',
    UPDATE_RESTAURANT_DETAILS_REQUEST_ERROR: 'UPDATE_RESTAURANT_DETAILS_REQUEST_ERROR',
    UPDATE_RESTAURANT_DETAILS_REQUEST_STATUS: 'UPDATE_RESTAURANT_DETAILS_REQUEST_STATUS'
}
