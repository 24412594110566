import React, { useEffect, useState } from "react";
import { ORGANISATION_DETAIL, ROUTES } from "../../../utils/AppConstants";
import './styles.css'
import CommonButton from "../../../components/button/common-button/commonButton";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_CONSTANTS } from '../../../utils/AppConstants'
import PartnerComponent from "../partner-component/partnerComponent";
import ScreenFooter from "../screen-footer/screenFooter";
import FeatureContainer from "../feature-container/featureContainer";
import NavBarComponent from "../navbar-component/navBarComponent";
import { isEmpty, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import Loader from '../../../components/loader/loader.component'
import { subscriptionListRequest } from '../../../redux/user/user.action'

const HomeNew = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, subscriptionListSuccess, subscriptionListError } = useSelector(state => state.user)
    let navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            screen: 'HOME_SCREEN',
            screenClass: 'HOME_SCREEN'
        }
        screenCaptureAnalytics(data)

    }, [])

    useEffect(() => {
        if (!isEmpty(subscriptionListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(subscriptionListError)) {
            setLoading(false)
        }
    }, [subscriptionListSuccess, subscriptionListError])

    useEffect(() => {
        sessionStorage.clear()
        localStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
        setLoading(true)
        dispatch(subscriptionListRequest())
    }, [])

    const handleGetStartedClick = (subscriptionId) => {
        navigate(ROUTES.signUp, { state: { subscriptionId: subscriptionId } })
    }

    const handleContactUsClick = () => {
        navigate(ROUTES.contactUs)
    }

    const handleMoreFeatureClick = () => {
        navigate(ROUTES.features)
    }

    return (
        <>
            <div>
                <NavBarComponent navigate={navigate} />
                <header className="hero">
                    <div class="content">
                        <h1>Restaurant Management, Table Ordering, Reservation, And Many More...</h1>
                        <p>A solution to manage your restaurant seamlessly</p>
                        <CommonButton label='Get Started'
                            onClick={() => { handleGetStartedClick(undefined) }} />
                    </div>
                </header>
                <div className="feature-section-container">
                    <div className="feature-label">Some Features <span className="feature-sub-label">we offer in GoMoBites</span></div>
                    <div className="container">
                        <div className="row">
                            <FeatureContainer
                                image={require('../../../assets/ic_feature_location.png')}
                                label='Location Based Menu'
                                description='Create and manage restaurant menu for multiple location for restaurant.' />
                            <FeatureContainer
                                image={require('../../../assets/ic_feature_order.png')}
                                label='Order Management'
                                description='Manage incoming orders filtered by location. Option for accepting and cancelling order.' />
                            <FeatureContainer
                                image={require('../../../assets/ic_feature_employee.png')}
                                label='Employee Access'
                                description='Provide console access to added employee for seamless operation.' />
                            <div className="col-sm-2 feature-item-container feature-item-content" onClick={() => { handleMoreFeatureClick() }}>
                                <div>View More</div>
                                <div className="feature-more-img">
                                    <img src={require('../../../assets/more_icon.png')} className='img' alt="more" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote-section-container">
                    <h3>Dining in, made easy</h3>
                    <h6>GoMoBites</h6>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 section-item-image">
                            <div className="section-item-image">
                                <img src={require('../../../assets/menu1.png')} className='img' alt="menu" />
                            </div>
                        </div>
                        <div className="col-sm-6 feature-section-container section-item-label">
                            <div className="food-vendor-label">Food Venue Operator <div className="h-line-gray" /></div>
                            <div className="food-vendor-item">&#x26AC; The operator takes pictures of the items add description, price, and assign categories as per their current menu.</div>
                            <div className="food-vendor-item">&#x26AC; Physically place QR code on each table and at the window of the restaurant/eatery.</div>
                            <div className="food-vendor-item">&#x26AC; Assigns and displays numbers to the table.</div>
                            <div className="food-vendor-item">&#x26AC; Assign queue number to each order for pickup.</div>
                            <div className="food-vendor-item">&#x26AC; Confirms order acceptance.</div>
                            <div className="food-vendor-item">&#x26AC; Acknowledges any special request or comments and response.</div>
                            <div className="food-vendor-item">&#x26AC; Delivers the order and closes the order.</div>
                        </div>
                    </div>
                </div>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-sm-6 feature-section-container section-item-label">
                            <div className="food-vendor-label">Consumers <div className="h-line-gray" /></div>
                            <div className="food-vendor-item">&#x26AC; Consumer scans QR code, placed at the restaurant table.</div>
                            <div className="food-vendor-item">&#x26AC; The browser opens up the url with the vendor menu.</div>
                            <div className="food-vendor-item">&#x26AC; The customer selects items and manually inputs the table number.</div>
                            <div className="food-vendor-item">&#x26AC; Adds any special comment like no ice in water or extra spicy particular dish etc.</div>
                            <div className="food-vendor-item">&#x26AC; Confirms the order items and pay via card/cash.</div>
                            <div className="food-vendor-item">&#x26AC; Order is sent to the restaurant operator and they confirm approx time.</div>
                            <div className="food-vendor-item">&#x26AC; The waiter delivers the menu items at the relevant table with the cash.</div>
                        </div>
                        <div className="col-sm-6 section-item-image">
                            <div className="section-item-image">
                                <img src={require('../../../assets/order_1.png')} className='img' alt="order" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="feature-section-price-container">
                    <div className="fspc-label">Pricing Plans</div>
                    {!isEmpty(subscriptionListSuccess) && !isEmpty(subscriptionListSuccess.data) && <div className="pricing-row-container">
                        {subscriptionListSuccess.data.map(item => (<div className="pricing-row-container-item">
                            <div className='pricing-label'>{item.title}</div>
                            {item.isCustomize === false && <div className='pricing-price-parent-container'>
                                <div className='pricing-starting-label'>{removeSpecialCharAndChangeToPascal(item.durationType)}</div>
                                <div className='pricing-price-container'>
                                    <div className='price-currency-label'>USD</div>
                                    <div className='price-price-label'>{item.finalPrice}</div>
                                    <div className='price-price-hourly'>{item.priceType}</div>
                                </div>
                            </div>}

                            <div className={item.isCustomize === false ? "pricing-feature-parent-container" : 'pricing-feature-parent-customize-container'}>
                                {item.features.map(e1 => (
                                    <div className='pricing-feature-container'>
                                        <div className='pricing-feature-check-container'>
                                            <img src={require('../../../assets/ic_tick.png')} className='img' alt='...' />
                                        </div>
                                        <div className='pricing-feature-label'>{e1}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="pricing-btn-container">
                                {item.isCustomize === false &&
                                    <CommonButton isBig={true} label='Get Started' onClick={() => { handleGetStartedClick(item.subscriptionId) }} />
                                }
                                {item.isCustomize === true &&
                                    <CommonButton isBig={true} label='Contact Us' onClick={() => { handleContactUsClick() }} />
                                }
                            </div>
                        </div>))}
                    </div>}
                    {!isEmpty(subscriptionListError) && <div className="content-error">{subscriptionListError.message}</div>}
                </div>
                <PartnerComponent />
                <ScreenFooter navigate={navigate} />
            </div >
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default HomeNew