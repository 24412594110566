import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { orderDashboardRequest } from '../../../redux/order/order.action'
import { displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, priceWithSymbol, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest, displayTourAction } from '../../../redux/user/user.action'
import Select from 'react-select'
import notificationTone from "../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../redux/socket/socket.action'
import Modal from 'react-modal';
import RefreshButton from '../../../components/button/refresh-button/refreshButton'
import ScreenLabel from '../../../components/screen-label/screenLabel'

const Dashboard = props => {
    const { isLogin, user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { dashboardSuccess, dashboardError } = useSelector(state => state.order)
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    const DATE_OPTIONS = { weekday: 'short', month: 'short', day: 'numeric' };

    useEffect(() => {
        const data = {
            screen: 'DASHBOARD',
            screenClass: 'DASHBOARD'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        fetchDashboardData()
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        fetchDashboardData()
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            isFirstTime = true
            sessionStorage.setItem(APP_CONSTANTS.DASHBOARD, true)
            const data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchDashboardData = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(orderDashboardRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(orderDashboardRequest(data))
        }
    }

    const handleShowTourClick = () => {
        dispatch(displayTourAction(false))
        setTimeout(() => {
            dispatch(displayTourAction(true))
        }, 500)

    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.DASHBOARD) === 'true') {
                isFirstTime = false
                sessionStorage.setItem(APP_CONSTANTS.DASHBOARD, false)
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedLocation)) {
            fetchDashboardData()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchDashboardData()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    useEffect(() => {
        if (!isNull(dashboardSuccess)) {
            setLoading(false)
        } else if (!isNull(dashboardError)) {
            setLoading(false)
        }
    }, [dashboardSuccess, dashboardError])

    const handleOrderClick = () => {
        navigate(ROUTES.orderList)
    }

    return (
        <>
            <div className="dashboard-container">
                <div className="dash-label-container">
                    <ScreenLabel
                        label='Dashboard' />
                    <RefreshButton label='Refresh'
                        onClick={() => { fetchDashboardData() }} />
                </div>
                {isLoggedInUserBusiness(user) === true && <div className="dash-select-row-container">
                    <div className="dash-select-container">
                        <div className="dash-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='dash-list-select'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    <div className="dash-select-container">
                        <div className="dash-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='dash-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>
                </div>}
                <div className="dash-body">
                    <div className="dash-header-label">Bills - {(new Date()).toLocaleDateString('en-US', DATE_OPTIONS)}</div>
                    <div className="dash-card-row">
                        <div className="dash-card">
                            <div className="dash-header-label-left">Pending Amount</div>
                            {dashboardSuccess && <div className="dash-header-label-val">{priceWithSymbol(dashboardSuccess.data, dashboardSuccess.data.totalUnPaidAmount)}</div>}
                            {!dashboardSuccess && <div className="dash-header-label-val">-</div>}

                        </div>
                        <div className="dash-card">
                            <div className="dash-header-label-left">Received Amount</div>
                            {dashboardSuccess && <div className="dash-header-label-val">{priceWithSymbol(dashboardSuccess.data, dashboardSuccess.data.totalPaidAmount)}</div>}
                            {!dashboardSuccess && <div className="dash-header-label-val">-</div>}
                        </div>
                    </div>
                    <div className="dash-header-label">Orders - {(new Date()).toLocaleDateString('en-US', DATE_OPTIONS)}</div>
                    <div className="dash-card-row">
                        <div className="dash-card" onClick={handleOrderClick}>
                            <div className="dash-header-label-left">New Orders</div>
                            <div className="dash-header-label-val">{dashboardSuccess ? dashboardSuccess.data.newOrder : '-'}</div>
                        </div>
                        <div className="dash-card" onClick={handleOrderClick}>
                            <div className="dash-header-label-left">Ongoing Orders</div>
                            <div className="dash-header-label-val">{dashboardSuccess ? dashboardSuccess.data.ongoingOrder : '-'}</div>
                        </div>
                        <div className="dash-card" onClick={handleOrderClick}>
                            <div className="dash-header-label-left">Delivered Orders</div>
                            <div className="dash-header-label-val">{dashboardSuccess ? dashboardSuccess.data.deliveredOrder : '-'}</div>
                        </div>
                        <div className="dash-card" onClick={handleOrderClick}>
                            <div className="dash-header-label-left">Rejected Orders</div>
                            <div className="dash-header-label-val">{dashboardSuccess ? dashboardSuccess.data.rejectedOrder : '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default Dashboard