import React, { useEffect, useState } from "react";
import NavBarComponent from "../navbar-component/navBarComponent";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ORGANISATION_DETAIL, ROUTES, API_CONSTANTS } from "../../../utils/AppConstants";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import CommonButton from "../../../components/button/common-button/commonButton";
import PartnerComponent from "../partner-component/partnerComponent";
import ScreenFooter from "../screen-footer/screenFooter";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { contactUsAddRecordRequest, contactUsAddRecordRequestStatus } from '../../../redux/contactUs/contactUs.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";

const ContactUs = props => {
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState()
    const [message, setMessage] = useState('')
    let navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { addRecordSuccess, addRecordError, addRecordReqStatus } = useSelector(state => state.contactUs)

    useEffect(() => {
        if (addRecordReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addRecordSuccess.message)
            setName('')
            setMessage('')
        } else if (addRecordReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addRecordError.message)
        }

        if (addRecordReqStatus.length) {
            dispatch(contactUsAddRecordRequestStatus(''))
        }
    }, [addRecordSuccess, addRecordError, addRecordReqStatus])

    const handleSubmitClick = () => {
        if (isEmpty(name)) {
            displayErrorToast('Name cannot be blank')
        } else if (isEmpty(message)) {
            displayErrorToast('Message cannot be blank')
        } else if (isNull(mobile)) {
            displayErrorToast('Mobile cannot be blank')
        } else {
            setLoading(true)
            const mobileWithPlusSymbol = mobile.replace('+', "")
            const data = {
                name: name,
                mobile: mobileWithPlusSymbol,
                message: message,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(contactUsAddRecordRequest(data))
        }
    }

    return (
        <>
            <div>
                <NavBarComponent navigate={navigate} />
                <header className="contact-hero">
                    <div className="content">
                        <div className="contact-label-container">
                            <div className="contact-label">Contact</div>
                            <div className="h-line-white" />
                        </div>
                        <div className="have-question-label">Have a question for us? Go for it.</div>
                        <div className="have-question-sub-text">We’re here to help you. Please complete this form to give a member of our team more information to get in touch with you at earliest.</div>
                    </div>
                </header>
                <div className="need-ques-container">
                    <div className="need-help-label">Fill up the below details. <span className="need-help-label-sub">We're available seven days a week to lend a hand</span></div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 mt-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-5">Email :</div>
                                    <div className="col-7">{ORGANISATION_DETAIL.ORG_SUPPORT_EMAIL}</div>
                                </div>
                                <div className="row">
                                    <div className="col-5"></div>
                                    {/* <div className="col-5">Address :</div> */}
                                    <div className="col-7">
                                        {/* <div>Updating Soon...</div> */}
                                        {/* <div>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE1}</div> */}
                                        {/* <div>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE2}</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <CommonInputFull
                                id='name'
                                onChange={e => setName(e.target.value)}
                                type='text'
                                value={name}
                                placeholder='Name'
                            />
                            <div className="mt-3 mb-3">
                                <PhoneInput
                                    defaultCountry="US"
                                    placeholder="Mobile Number"
                                    value={mobile}
                                    onChange={setMobile} />
                            </div>
                            <CommonInputFull
                                id='name'
                                onChange={e => setMessage(e.target.value)}
                                type='text'
                                value={message}
                                placeholder='Message'
                            />
                            <div className='su-button-row mb-2'>
                                <CommonButton label='Submit'
                                    onClick={() => { handleSubmitClick() }} />
                            </div>
                        </div>
                    </div>
                </div>
                <PartnerComponent />
                <ScreenFooter navigate={navigate} />
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ContactUs