import React, { useEffect, useState } from "react";
import './styles.css'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { requestTicketDetailRequest, requestTicketUpdateDetailRequest, requestTicketUpdateDetailRequestStatus } from '../../../redux/request-ticket/request-ticket.action'
import { convertToPascal, displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { RequestTicketStatus } from '../../../utils/RequestTicketType'
import CommonButton from '../../../components/button/common-button/commonButton'
import ScreenLabel from "../../../components/screen-label/screenLabel";

const SupportRequestTicketDetailScreen = props => {
    const { requestTicketDetailSuccess, requestTicketDetailError,
        requestTicketUpdateDetailSuccess, requestTicketUpdateDetailError, requestTicketUpdateDetailReqStatus } = useSelector(state => state.requestTicket)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { state } = useLocation()
    const [message, setMessage] = useState('')
    const [selectedStatus, setSelectedStatus] = useState()

    useEffect(() => {
        const data = {
            screen: 'SUPPORT_REQUEST_TICKET_DETAIL',
            screenClass: 'SUPPORT_REQUEST_TICKET_DETAIL'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        fetchDetail()
    }, [])

    const fetchDetail = () => {
        setLoading(true)
        dispatch(requestTicketDetailRequest(state))
    }

    useEffect(() => {
        if (!isNull(requestTicketDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(requestTicketDetailError)) {
            setLoading(false)
        }
    }, [requestTicketDetailSuccess, requestTicketDetailError])

    useEffect(() => {
        if (requestTicketUpdateDetailReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(requestTicketUpdateDetailSuccess.message)
            setMessage('')
            fetchDetail()
        } else if (requestTicketUpdateDetailReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(requestTicketUpdateDetailError.message)
        }
        if (requestTicketUpdateDetailReqStatus.length) {
            dispatch(requestTicketUpdateDetailRequestStatus(''))
        }
    }, [requestTicketUpdateDetailSuccess, requestTicketUpdateDetailError, requestTicketUpdateDetailReqStatus])

    const handleSendMessage = () => {
        if (isEmpty(message)) {
            displayErrorToast('Message cannot be blank')
        } else {
            setLoading(true)
            const data = {
                ticketId: state,
                sender: 'Support Team',
                message: message,
                addMessage: 1
            }
            dispatch(requestTicketUpdateDetailRequest(data))
        }
    }

    const handleUpdateStatus = () => {
        if (isNull(selectedStatus)) {
            displayErrorToast('Ticket status not selected')
        } else {
            setLoading(true)
            const data = {
                ticketId: state,
                ticketStatus: selectedStatus.key
            }
            dispatch(requestTicketUpdateDetailRequest(data))
        }
    }

    const isStatusCancelledOrComplete = data => {
        if (data.ticketStatus === APP_CONSTANTS.REQUEST_TICKET_STATUS.COMPLETED) {
            return true
        } else if (data.ticketStatus === APP_CONSTANTS.REQUEST_TICKET_STATUS.CANCELLED) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <div className="srtd-container">
                <ScreenLabel label='Ticket Details' />
                {requestTicketDetailSuccess && requestTicketDetailSuccess.data && <div className="srtd-content">
                    <div className="srtd-select-heading">Title</div>
                    <div className="srtd-content-text">{requestTicketDetailSuccess.data.title}</div>
                    <div className="srtd-select-heading">Description</div>
                    <div className="srtd-content-text">{requestTicketDetailSuccess.data.description}</div>
                    <div className="srtd-select-heading">Business Id</div>
                    <div className="srtd-content-text">{requestTicketDetailSuccess.data.businessDetail ? requestTicketDetailSuccess.data.businessDetail.businessId : ''}</div>
                    <div className="srtd-select-heading">Business Name</div>
                    <div className="srtd-content-text">{requestTicketDetailSuccess.data.businessDetail ? requestTicketDetailSuccess.data.businessDetail.businessName : ''}</div>
                    <div className="srtd-select-heading">Location Id</div>
                    <div className="srtd-content-text">{requestTicketDetailSuccess.data.locationDetail ? requestTicketDetailSuccess.data.locationDetail.locationId : ''}</div>
                    <div className="srtd-select-heading">Location Name</div>
                    <div className="srtd-content-text">{requestTicketDetailSuccess.data.locationDetail ? requestTicketDetailSuccess.data.locationDetail.name : ''}</div>
                    <div className="srtd-select-heading">Ticket Details</div>
                    <div className="srtd-row-container">
                        <div className="srtd-row-item">Ticket Id</div>
                        <div className="srtd-row-item">{requestTicketDetailSuccess.data.ticketId}</div>
                    </div>
                    <div className="srtd-row-container">
                        <div className="srtd-row-item">Contact Person</div>
                        <div className="srtd-row-item">{requestTicketDetailSuccess.data.contactPersonName ? requestTicketDetailSuccess.data.contactPersonName : '-'}</div>
                    </div>
                    <div className="srtd-row-container">
                        <div className="srtd-row-item">Phone Number</div>
                        <div className="srtd-row-item">{requestTicketDetailSuccess.data.contactPersonNumber ? requestTicketDetailSuccess.data.contactPersonNumber : '-'}</div>
                    </div>
                    <div className="srtd-row-container">
                        <div className="srtd-row-item">Priority</div>
                        <div className="srtd-row-item">{convertToPascal(requestTicketDetailSuccess.data.priority)}</div>
                    </div>
                    <div className="srtd-row-container">
                        <div className="srtd-row-item">Status</div>
                        <div className="srtd-row-item">{removeSpecialCharAndChangeToPascal(requestTicketDetailSuccess.data.ticketStatus)}</div>
                    </div>
                    <div className="srtd-row-container">
                        <div className="srtd-row-item">Created On</div>
                        <div className="srtd-row-item">{moment(requestTicketDetailSuccess.data.createdOn).utc().format('DD MMM YYYY')}</div>
                    </div>
                    <div className="srtd-select-heading mt-2">Messages</div>
                    {requestTicketDetailSuccess.data.messages.map(element => (
                        <div className="srtd-message-container">
                            <div className="srtd-content-text">{element.message}</div>
                            <div className="srtd-select-heading">{element.sender}</div>
                        </div>
                    ))}
                    {requestTicketDetailSuccess.data.messages.length === 0 ? (<div className="srtd-content-text">No messages found</div>) : null}
                </div>}
                {requestTicketDetailSuccess && requestTicketDetailSuccess.data && <div className="mt-3">
                    {!isStatusCancelledOrComplete(requestTicketDetailSuccess.data) && <div className="srtd-select-heading">Add Message</div>}
                    {!isStatusCancelledOrComplete(requestTicketDetailSuccess.data) && <div className="form-floating arqt-input">
                        <input
                            type="name"
                            className="form-control arqt-input-text"
                            id="name"
                            placeholder="Message"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            required />
                        <label for="name">Message</label>
                    </div>}
                    {!isStatusCancelledOrComplete(requestTicketDetailSuccess.data) && <div className="srtd-select-heading">Select Ticket Status</div>}
                    <Select
                        menuPlacement="top"
                        className='arqt-select'
                        value={selectedStatus}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={RequestTicketStatus}
                        placeholder='Select Status'
                        onChange={e => setSelectedStatus(e)} />
                    {!isStatusCancelledOrComplete(requestTicketDetailSuccess.data) && <div className='srtd-button-row'>
                        <CommonButton label='Send Message' onClick={() => handleSendMessage()} />
                        <CommonButton label='Update Status' isInverted={true} onClick={() => handleUpdateStatus()} />
                    </div>}
                </div>}

            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SupportRequestTicketDetailScreen