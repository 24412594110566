import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addTerminal, terminalList, updateTerminalStatus
} from '../api-requests/deliveryTerminal';
import {
    addTerminalRequestError, addTerminalRequestSuccess, terminalListRequestError, terminalListRequestSuccess,
    updateTerminalStatusRequestError, updateTerminalStatusRequestSuccess
} from './delivery-terminal.action';
import { DeliveryTerminalActionTypes } from './delivery-terminal.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleTerminalUpdateStatus({ payload }) {
    try {
        const response = yield call(updateTerminalStatus, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateTerminalStatusRequestSuccess(decryptedData))
            } else {
                yield put(updateTerminalStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateTerminalStatusRequestError(error.response.data))
    }
}

export function* updateTerminalStatusReq() {
    yield takeLatest(DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST, handleTerminalUpdateStatus)
}

function* handleTerminalList({ payload }) {
    try {
        const response = yield call(terminalList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(terminalListRequestSuccess(decryptedData))
            } else {
                yield put(terminalListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(terminalListRequestError(error.response.data))
    }
}

export function* terminalListReq() {
    yield takeLatest(DeliveryTerminalActionTypes.TERMINAL_LIST_REQUEST, handleTerminalList)
}

function* handleAddTerminal({ payload }) {
    try {
        const response = yield call(addTerminal, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addTerminalRequestSuccess(decryptedData))
            } else {
                yield put(addTerminalRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addTerminalRequestError(error.response.data))
    }
}

export function* addTerminalReq() {
    yield takeLatest(DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST, handleAddTerminal)
}