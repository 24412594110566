import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { orderListRequest } from '../../../../redux/order/order.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics, convertToPascal, displayCurrencySymbolLeft } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS } from "../../../../utils/AppConstants";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";
import { SocketContext } from '../../../../SocketCtx'
import { clearOrderStatusNotif } from '../../../../redux/socket/socket.action'

const OrderPaymentBoardScreen = props => {
    const { orderListSuccess, orderListError } = useSelector(state => state.order)
    const { orderStatusUpdateNotif } = useSelector(state => state.socket)
    const { user } = useSelector(state => state.user)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [newOrder, setNewOrder] = useState([])
    const socket = useContext(SocketContext)

    useEffect(() => {
        if (!isNull(orderStatusUpdateNotif) && !isNull(user)) {
            if (orderStatusUpdateNotif.restaurantId === user.data.userDetails.restaurantId &&
                orderStatusUpdateNotif.locationId === user.data.userDetails.restaurantLocationId) {
                fetchOrderList()
                dispatch(clearOrderStatusNotif(""))
            }
        }
    }, [orderStatusUpdateNotif])

    useEffect(() => {
        const data = {
            screen: 'ORDER_PAYMENT_BOARD',
            screenClass: 'ORDER_PAYMENT_BOARD'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(orderListSuccess)) {
            setLoading(false)
            const newOrderList = orderListSuccess.data.filter(item => item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.DELIVERED)
            setNewOrder(newOrderList)
        } else if (!isNull(orderListError)) {
            setLoading(false)
        }
    }, [orderListSuccess, orderListError])

    const fetchOrderList = () => {
        setLoading(true)
        const data = {
            businessId: user.data.userDetails.restaurantId,
            locationId: user.data.userDetails.restaurantLocationId
        }
        dispatch(orderListRequest(data))
    }

    useEffect(() => {
        fetchOrderList()
    }, [])

    return (
        <>
            <div className="olb-list-container">
                <ScreenLabel label='Orders' />

                {newOrder && !isEmpty(newOrder) && <table class="table table-striped table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col" className="rl-table-col-title">Order Number</th>
                            <th scope="col" className="rl-table-col-title text-center">Amount</th>
                            <th scope="col" className="rl-table-col-title text-center">Refund</th>
                            <th scope="col" className="rl-table-col-title text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newOrder.map(element => (
                            <tr className="rl-table-row-container">
                                <td className="rl-table-col-item">{element.orderNumber}</td>
                                <td className="rl-table-col-item text-center">{
                                    displayCurrencySymbolLeft(element.currencyRegion) === true ? (<div>{element.currencySymbol} {element.grandTotal}</div>) :
                                        (<div>{element.grandTotal}{element.currencySymbol}</div>)
                                }</td>
                                <td className="rl-table-col-item text-center">{
                                    displayCurrencySymbolLeft(element.currencyRegion) === true ? (<div>{element.currencySymbol} {element.refundAmount}</div>) :
                                        (<div>{element.refundAmount}{element.currencySymbol}</div>)
                                }</td>
                                <td className="rl-table-col-item text-center">
                                    {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PLACED ? <div className="olb-blue-label">{convertToPascal(element.orderStatus)}</div> : null}
                                    {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.ACCEPTED ? <div className="olb-orange-label">{convertToPascal(element.orderStatus)}</div> : null}
                                    {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARING ? <div className="olb-orange-label">{convertToPascal(element.orderStatus)}</div> : null}
                                    {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARED ? <div className="olb-green-label">{convertToPascal(element.orderStatus)}</div> : null}
                                    {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.CANCELLED ? <div className="olb-red-label">{convertToPascal(element.orderStatus)}</div> : null}
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>}
                {orderListError && <div>{orderListError.message}</div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OrderPaymentBoardScreen