import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from '../../../utils/Utils'
import { restaurantListRequest, branchListRequest, employeeRestAndLocSelectionPayload } from '../../../redux/user/user.action'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom';
import ScreenLabel from "../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../components/common-form/commonForm";

const RestaurantSelectionScreen = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError } = useSelector(state => state.user)
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [filteredBranchList, setFilteredBranchList] = useState([])
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'EMPLOYEE_RESTAURANT_SELECTION',
            screenClass: 'EMPLOYEE_RESTAURANT_SELECTION'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        setLoading(true)
        let data1 = {}
        if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
            data1.payload = {
                isEmployee: 1,
                employeeId: user.data.userDetails.userId
            }
            data1.userId = user.data.userDetails.userId
        } else {
            data1.userId = user.data.userDetails.userId
        }
        dispatch(restaurantListRequest(data1))
    }, [])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(restuarantListSuccess)) {
            setLoading(false)
            setSelectedRestaurant(restuarantListSuccess.data[0])
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (!isNull(branchListSuccess)) {
            setLoading(false)
            if (user !== null && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
                let filteredBranchList = branchListSuccess.data.filter(item => user.data.userDetails.employeeDetails.locationId.includes(item.locationId))
                setFilteredBranchList(filteredBranchList)
            } else {
                setSelectedLocation(branchListSuccess.data[0])
            }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(filteredBranchList)) {
            setSelectedLocation(filteredBranchList[0])
        }
    }, [filteredBranchList])

    const submitHandler = () => {
        if (isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else {
            const data = {
                businessId: selectedRestaurant.businessId,
                locationId: selectedLocation.locationId
            }
            dispatch(employeeRestAndLocSelectionPayload(data))
            navigate(ROUTES.orderList, { replace: true });
        }
    }

    return (
        <>
            <div className="rss-container">
                <ScreenLabel label='Work Location' />
                <CommonForm>
                    <SubTextLabel label='Select your work location to continue' />
                    <div className="rss-select-heading">Select Restaurant</div>
                    {restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="top"
                        className='rss-select'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {!restuarantListSuccess && <div className="rss-text">No restaurant found</div>}
                    <div className="rss-select-heading mt-2">Select Location</div>
                    {filteredBranchList && <Select
                        menuPlacement="top"
                        className='rss-select'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={filteredBranchList}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {!branchListSuccess && <div className="rss-text">No location found</div>}
                    <div className='rss-button-row'>
                        <button type='button' className='btn rss-button' onClick={() => submitHandler()}>Continue</button>
                    </div>

                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default RestaurantSelectionScreen