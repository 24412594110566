import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addProduct, productList, updateProduct, updateProductConsumption, productDetails
} from '../../api-requests/inventory/product';
import {
    addInvProductRequestError, addInvProductRequestSuccess, invProductListRequestError,
    invProductListRequestSuccess, updateInvProductConsumptionRequestError, updateInvProductConsumptionRequestSuccess,
    updateInvProductRequestError, updateInvProductRequestSuccess, invProductDetailRequestError,
    invProductDetailRequestSuccess
} from './product.action';
import { ProductActionTypes } from './product.types'
import { displayConsoleLog, isNull } from '../../../utils/Utils'
import { decryptResponseData } from '../../../utils/DataDecryptionUtils';

function* handleInvProductDetail({ payload }) {
    try {
        const response = yield call(productDetails, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invProductDetailRequestSuccess(decryptedData))
            } else {
                yield put(invProductDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(invProductDetailRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(invProductDetailRequestError(error.response.data))
        }
    }
}

export function* invProductDetailReq() {
    yield takeLatest(ProductActionTypes.INV_PRODUCT_DETAIL_REQUEST, handleInvProductDetail)
}

function* handleUpateInvProductConsumption({ payload }) {
    try {
        const response = yield call(updateProductConsumption, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateInvProductConsumptionRequestSuccess(decryptedData))
            } else {
                yield put(updateInvProductConsumptionRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(updateInvProductConsumptionRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(updateInvProductConsumptionRequestError(error.response.data))
        }
    }
}

export function* updateInvProductConsumptionReq() {
    yield takeLatest(ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST, handleUpateInvProductConsumption)
}

function* handleUpateInvProduct({ payload }) {
    try {
        const response = yield call(updateProduct, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateInvProductRequestSuccess(decryptedData))
            } else {
                yield put(updateInvProductRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(updateInvProductRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(updateInvProductRequestError(error.response.data))
        }
    }
}

export function* updateInvProductReq() {
    yield takeLatest(ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST, handleUpateInvProduct)
}

function* handleInvProductList({ payload }) {
    try {
        const response = yield call(productList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invProductListRequestSuccess(decryptedData))
            } else {
                yield put(invProductListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(invProductListRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(invProductListRequestError(error.response.data))
        }
    }
}

export function* invProductListReq() {
    yield takeLatest(ProductActionTypes.INV_PRODUCT_LIST_REQUEST, handleInvProductList)
}

function* handleAddInvProduct({ payload }) {
    try {
        const response = yield call(addProduct, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addInvProductRequestSuccess(decryptedData))
            } else {
                yield put(addInvProductRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(addInvProductRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(addInvProductRequestError(error.response.data))
        }
    }
}

export function* addInvProductReq() {
    yield takeLatest(ProductActionTypes.INV_ADD_PRODUCT_REQUEST, handleAddInvProduct)
}