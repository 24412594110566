import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { reservationListRequest } from '../../../../redux/reservation/reservation.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";

const ReservationListScreen = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { reservationListSuccess, reservationListError } = useSelector(state => state.reservation)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    let navigate = useNavigate();
    const RESERVATION_LIST = 'RESERVATION_LIST'
    const RESERVATION_DETAIL = 'RESERVATION_DETAIL'
    const ADD_RESERVATION = 'ADD_RESERVATION'

    useEffect(() => {
        const data = {
            screen: 'RESERVATION_LIST',
            screenClass: 'RESERVATION_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(reservationListSuccess)) {
            setLoading(false)
        } else if (!isNull(reservationListError)) {
            setLoading(false)

        }
    }, [reservationListSuccess, reservationListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.RESERVATION_LIST, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchReservationList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(reservationListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(reservationListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.RESERVATION_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.RESERVATION_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchReservationList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchReservationList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const handleCardClick = item => {
        if (checkFunctionalityPermission(RESERVATION_DETAIL) === true) {
            navigate(ROUTES.reservationDetail, { state: item.reservationId })
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
        // const data = {
        //     employeeId: item.employeeId,
        //     businessId: selectedRestaurant.businessId,
        //     locationId: selectedLocation.locationId
        // }
        // setLoading(true)
        // dispatch(removeEmployeeRequest(data))
    }

    const handleAddReservationClick = () => {
        if (checkFunctionalityPermission(ADD_RESERVATION) === true) {
            navigate(ROUTES.placeReservation)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.RESERVATION && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            displayConsoleLog('itemObj', itemObj)
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === RESERVATION_LIST) {
                    if (itemObj.reservationList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === RESERVATION_DETAIL) {
                    if (itemObj.updateReservation === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === ADD_RESERVATION) {
                    if (itemObj.addReservation === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Reservations' />
                    <TopButton label='Add Reservation'
                        isSmall={true}
                        onClick={() => { handleAddReservationClick() }} />
                </CommonInputRow>
                {isLoggedInUserBusiness(user) === true && <CommonInputRow>
                    <div className="resl-select-container">
                        <div className="resl-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='select-full-width-no-border'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    <div className="resl-select-container">
                        <div className="resl-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>
                </CommonInputRow>}
                {checkFunctionalityPermission(RESERVATION_LIST) === true && <div className="table-scroll-container">
                    {reservationListSuccess && reservationListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Mobile</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservationListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.customerName}</td>
                                    <td className="rl-table-col-item text-center">{element.customerDetails ? element.customerDetails.mobile : -''}</td>
                                    <td className="rl-table-col-item text-center">{element.reservationStatus}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.reservationDateTime).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center">
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>Details</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(RESERVATION_LIST) === true && reservationListError && <div className="content-error">No Reservations Found</div>}
                {checkFunctionalityPermission(RESERVATION_LIST) === false && reservationListError && <div className="content-error">You are not authorized to view reservation list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ReservationListScreen