import axios from 'axios'
import { config } from '../../../config'
import { APP_CONSTANTS } from '../../../utils/AppConstants'
import API_ENDPOINTS from '../endpoints'

export function addSellerDetails(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.seller.addSeller}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function sellerList(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.seller.sellerList}`,
        params: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}
export function updateSellerDetails(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.seller.updateSellerDetails}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}