import axios from 'axios'
import API_ENDPOINTS from './endpoints'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import { config } from '../../config'

export function placeReservationAPI(data) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.reservation.placeReservation}`,
        data: data
    })
}

export function reservationListAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.reservation.reservationList}`,
        params: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function reservationDetailAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.reservation.reservationDetail}/${data}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateReservationStatusAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.reservation.reservationUpdateStatus}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}