import React, { useState, useEffect } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isEmpty, displayErrorToast, displaySuccessToast, convertToPascal } from '../../../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { updateReservationStatusRequest, updateReservationStatusRequestStatus, reservationDetailRequest } from '../../../../redux/reservation/reservation.action'
import moment from "moment";
import CommonButton from '../../../../components/button/common-button/commonButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";

const ReservationDetail = props => {
    const [isLoading, setLoading] = useState(false)
    const { reservationDetailSuccess, reservationDetailError, updateReservationSuccess,
        updateReservationError, updateReservationReqStatus } = useSelector(state => state.reservation)
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const { state } = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        dispatch(reservationDetailRequest(state))
    }, [])

    useEffect(() => {
        if (reservationDetailSuccess) {
            setLoading(false)
        } else if (reservationDetailError) {
            setLoading(false)
            displayErrorToast(reservationDetailError.message)
        }
    }, [reservationDetailSuccess, reservationDetailError])

    useEffect(() => {
        if (updateReservationReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            displaySuccessToast('Reservation status updated successfully')
            dispatch(reservationDetailRequest(state))
        } else if (updateReservationReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateReservationError.message)
        }

        if (updateReservationReqStatus.length) {
            dispatch(updateReservationStatusRequestStatus(''))
        }
    }, [updateReservationSuccess, updateReservationError, updateReservationReqStatus])

    const processUpdateStatus = (status) => {
        setLoading(true)
        const data = {}
        data.reservationId = state
        data.status = status
        dispatch(updateReservationStatusRequest(data))
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Reservation Details' />
                </div>
                {reservationDetailSuccess && reservationDetailSuccess.data && <CommonColorForm>
                    <div className="resd-dash-header-label-left">{reservationDetailSuccess.data.customerName}</div>
                    {!isEmpty(reservationDetailSuccess.data.customerDetails) ? (<div className="resd-dash-header-label-detail">Mobile : {reservationDetailSuccess.data.customerDetails.mobile}</div>) : null}
                    <div className="resd-row-container">
                        <div className="resd-row-item">Guest Count</div>
                        <div className="resd-row-item">{reservationDetailSuccess.data.guestCount}</div>
                    </div>
                    <div className="resd-row-container">
                        <div className="resd-row-item">Reservation Date</div>
                        <div className="resd-row-item">{moment(reservationDetailSuccess.data.reservationDateTime).utc().format('ddd DD, MMM YYYY')}</div>
                    </div>
                    {reservationDetailSuccess.data.reservationStatus ? (<div className="resd-row-container">
                        <div className="resd-row-item">Status</div>
                        <div className="resd-row-item">{convertToPascal(reservationDetailSuccess.data.reservationStatus)}</div>
                    </div>) : null}
                    {reservationDetailSuccess.data.reservationType ? (<div className="resd-row-container">
                        <div className="resd-row-item">Type</div>
                        <div className="resd-row-item">{convertToPascal(reservationDetailSuccess.data.reservationType)}</div>
                    </div>) : null}
                    {reservationDetailSuccess.data.eventName ? (<div className="resd-row-container">
                        <div className="resd-row-item">Event Name</div>
                        <div className="resd-row-item">{reservationDetailSuccess.data.eventName}</div>
                    </div>) : null}
                    {reservationDetailSuccess.data.eventDescription ? (<div className="resd-row-container">
                        <div className="resd-row-item">Event Description</div>
                        <div className="resd-row-item">{reservationDetailSuccess.data.eventDescription}</div>
                    </div>) : null}
                    {reservationDetailSuccess && reservationDetailSuccess.data && <div className="resd-btn-container">
                        {reservationDetailSuccess.data.reservationStatus === APP_CONSTANTS.RESERVATION_STATUS.PENDING ?
                            (<CommonButton isBig={true} label="Mark Confirmed" onClick={() => processUpdateStatus(APP_CONSTANTS.RESERVATION_STATUS.CONFIRMED)} />)
                            : null
                        }
                        {reservationDetailSuccess.data.reservationStatus === APP_CONSTANTS.RESERVATION_STATUS.PENDING ?
                            (<CommonButton isBig={true} label="Mark Rejected" onClick={() => processUpdateStatus(APP_CONSTANTS.RESERVATION_STATUS.REJECTED)} />)
                            : null
                        }
                        {reservationDetailSuccess.data.reservationStatus === APP_CONSTANTS.RESERVATION_STATUS.PENDING ?
                            (<CommonButton isBig={true} label="Mark Cancelled" onClick={() => processUpdateStatus(APP_CONSTANTS.RESERVATION_STATUS.CANCELLED)} />)
                            : null
                        }
                        {reservationDetailSuccess.data.reservationStatus === APP_CONSTANTS.RESERVATION_STATUS.CONFIRMED ?
                            (<CommonButton isBig={true} label="Mark Cancelled" onClick={() => processUpdateStatus(APP_CONSTANTS.RESERVATION_STATUS.CANCELLED)} />)
                            : null
                        }
                        {reservationDetailSuccess.data.reservationStatus === APP_CONSTANTS.RESERVATION_STATUS.CONFIRMED ?
                            (<CommonButton isBig={true} label="Mark Closed" onClick={() => processUpdateStatus(APP_CONSTANTS.RESERVATION_STATUS.CLOSED)} />)
                            : null
                        }
                    </div>}

                </CommonColorForm>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ReservationDetail