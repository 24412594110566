import React, { useEffect, useState } from 'react'
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { loginUserRequest, displaySideBarAct } from '../../../redux/user/user.action'
import CommonButton from '../../../components/button/common-button/commonButton'
import SubTextLabel from '../../../components/sub-text-label/subTextLabel';
import CommonForm from '../../../components/common-form/commonForm';
import CommonInputFull from '../../../components/common-input-full/commonInputFull';
import CommonScreenContentCenter from '../../../components/screen/common-screen-content-center/commonScreenContentCenter';
import FormFieldLabel from '../../../components/form-field-label/formFieldLabel';
import ButtonRow from '../../../components/button/button-row/buttonRow';

const SignInScreen = props => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const { user, isLogin, loginErrorData } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'SIGN_IN',
            screenClass: 'SIGN_IN'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('Login successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
                navigate(ROUTES.selectWorkLocation, { replace: true });
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.KITCHEN) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.kitchenOrderList, { replace: true });
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ORDER_BOARD) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.orderListBoard, { replace: true });
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ORDER_PAYMENT_BOARD) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.orderPaymentBoard, { replace: true });
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS) {
                if (user.data.userDetails.isProfileUpdated === false) {
                    navigate(ROUTES.updatProfile, { replace: true });
                } else {
                    dispatch(displaySideBarAct(true))
                    navigate(ROUTES.dashboard, { replace: true });
                }
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.SUPPORT) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.sRequestTicketList, { replace: true });
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ADMIN) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.operatorList, { replace: true });
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.OPERATOR) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.operatorBusinessList, { replace: true });
            }
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleSignIn = () => {
        if (email.length && password.length) {
            setLoading(true)
            const data = {
                email: email,
                password: password
            }
            dispatch(loginUserRequest(data))
        } else {
            displayErrorToast('Email or password cannot be blank', true)
        }
    }

    const handleForgotPassClick = () => {
        navigate(ROUTES.forgotPassword)
    }

    const createAccountClick = () => {
        navigate(ROUTES.signUp)
    }

    const empLoginClick = () => {
        navigate(ROUTES.employeeLogin)
    }

    const delTerminalLoginClick = () => {
        navigate(ROUTES.deliveryTerminalLogin)
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    return (
        <>
            <div className='sign-in-parent-container'>
                <div className='sign-in-img-layout-container'>
                    <img src={require('../../../assets/sign-in-banner.jpg')} className='sign-up-img-container' />
                    <img src={require('../../../assets/sign-in-banner-tab1.jpg')} className='sign-up-tab-img-container' />
                    {/* <div className="sign-up-brand-label">GOMOBITES</div> */}
                </div>
                <div className='sign-in-content-layout-container'>
                    <div className="sign-in-logo-container" onClick={() => { navigateToHome() }}>
                        <img src={require('../../../assets/NewLogo1.png')} alt='logo' className="img" />
                    </div>
                    <div className='sign-in-heading'>Login to <span className='sign-in-heading-label'>GoMoBites</span></div>
                    <FormFieldLabel label='Login with your registered restaurant credentials' className='sign-in-sub-text-label margin-bottom-10' />
                    <CommonInputFull
                        id="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email" />
                    <CommonInputFull
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password" />
                    <div className='si-forgot-password-label' onClick={() => handleForgotPassClick()}>FORGOT PASSWORD</div>
                    <ButtonRow>
                        <CommonButton label='Login'
                            onClick={() => handleSignIn()}
                            isBig={true}
                        />
                    </ButtonRow>
                    <div className='sign-in-or-label'>OR</div>
                    <div className='sign-in-create-account-label'>Not a member? <span className='sign-in-create-account-label-italic' onClick={() => { createAccountClick() }}>Create Account</span></div>
                </div>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SignInScreen