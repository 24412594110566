import { CategoryActionTypes } from './category.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addCategorySuccess: null,
    addCategoryError: null,
    addCategoryStatus: '',
    categoryList: null,
    categoryListError: null,
    addSubCategorySuccess: null,
    addSubCategoryError: null,
    addSubCategoryStatus: '',
    subCategoryList: null,
    subCategoryListError: null
}

const categoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CategoryActionTypes.ADD_CATEGORY_REQUEST_SUCCESS:
            return {
                ...state,
                addCategorySuccess: action.payload,
                addCategoryError: null,
                addCategoryStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CategoryActionTypes.ADD_CATEGORY_REQUEST_ERROR:
            return {
                ...state,
                addCategorySuccess: null,
                addCategoryError: action.payload,
                addCategoryStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CategoryActionTypes.ADD_CATEGORY_REQUEST_STATUS:
            return {
                ...state,
                addCategoryStatus: ''
            }
        case CategoryActionTypes.CATEGORY_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
                categoryListError: null
            }
        case CategoryActionTypes.CATEGORY_REQUEST_LIST_ERROR:
            return {
                ...state,
                categoryList: null,
                categoryListError: action.payload
            }
        case CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST_SUCCESS:
            return {
                ...state,
                addSubCategorySuccess: action.payload,
                addSubCategoryError: null,
                addSubCategoryStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST_ERROR:
            return {
                ...state,
                addSubCategorySuccess: null,
                addSubCategoryError: action.payload,
                addSubCategoryStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST_STATUS:
            return {
                ...state,
                addSubCategoryStatus: ''
            }
        case CategoryActionTypes.SUB_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                subCategoryList: action.payload,
                subCategoryListError: null
            }
        case CategoryActionTypes.SUB_CATEGORY_LIST_ERROR:
            return {
                ...state,
                subCategoryList: null,
                subCategoryListError: action.payload
            }
        case CategoryActionTypes.LOGOUT:
            return {
                ...INITIAL_STATE,
                categoryList: state.categoryList,
                categoryListError: state.categoryListError
            }
        case CategoryActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default categoryReducer