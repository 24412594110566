import { ProductActionTypes } from './product.types'

//Add Product
export const addInvProductRequest = data => ({
    type: ProductActionTypes.INV_ADD_PRODUCT_REQUEST,
    payload: data
})

export const addInvProductRequestSuccess = data => ({
    type: ProductActionTypes.INV_ADD_PRODUCT_REQUEST_SUCCESS,
    payload: data
})

export const addInvProductRequestError = data => ({
    type: ProductActionTypes.INV_ADD_PRODUCT_REQUEST_ERROR,
    payload: data
})

export const addInvProductRequestStatus = data => ({
    type: ProductActionTypes.INV_ADD_PRODUCT_REQUEST_STATUS,
    payload: data
})

//Product List
export const invProductListRequest = data => ({
    type: ProductActionTypes.INV_PRODUCT_LIST_REQUEST,
    payload: data
})

export const invProductListRequestSuccess = data => ({
    type: ProductActionTypes.INV_PRODUCT_LIST_REQUEST_SUCCESS,
    payload: data
})

export const invProductListRequestError = data => ({
    type: ProductActionTypes.INV_PRODUCT_LIST_REQUEST_ERROR,
    payload: data
})

//Product Detail
export const invProductDetailRequest = data => ({
    type: ProductActionTypes.INV_PRODUCT_DETAIL_REQUEST,
    payload: data
})

export const invProductDetailRequestSuccess = data => ({
    type: ProductActionTypes.INV_PRODUCT_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const invProductDetailRequestError = data => ({
    type: ProductActionTypes.INV_PRODUCT_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update Product
export const updateInvProductRequest = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST,
    payload: data
})

export const updateInvProductRequestSuccess = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST_SUCCESS,
    payload: data
})

export const updateInvProductRequestError = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST_ERROR,
    payload: data
})

export const updateInvProductRequestStatus = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST_STATUS,
    payload: data
})

//Update Product Consumption
export const updateInvProductConsumptionRequest = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST,
    payload: data
})

export const updateInvProductConsumptionRequestSuccess = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_SUCCESS,
    payload: data
})

export const updateInvProductConsumptionRequestError = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_ERROR,
    payload: data
})

export const updateInvProductConsumptionRequestStatus = data => ({
    type: ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_STATUS,
    payload: data
})