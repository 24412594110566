import { SellerActionTypes } from './seller.types'
import { API_CONSTANTS } from '../../../utils/AppConstants'

const INITIAL_STATE = {
    addInvSellerSuccess: null,
    addInvSellerError: null,
    addInvSellerReqStatus: '',
    invSellerListSuccess: null,
    invSellerListError: null,
    updateInvSellerSuccess: null,
    updateInvSellerError: null,
    updateInvSellerReqStatus: ''
}

const invSellerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SellerActionTypes.INV_ADD_SELLER_REQUEST_SUCCESS:
            return {
                ...state,
                addInvSellerSuccess: action.payload,
                addInvSellerError: null,
                addInvSellerReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case SellerActionTypes.INV_ADD_SELLER_REQUEST_ERROR:
            return {
                ...state,
                addInvSellerSuccess: null,
                addInvSellerError: action.payload,
                addInvSellerReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case SellerActionTypes.INV_ADD_SELLER_REQUEST_STATUS:
            return {
                ...state,
                addInvSellerReqStatus: ''
            }
        case SellerActionTypes.INV_SELLER_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                invSellerListSuccess: action.payload,
                invSellerListError: null
            }
        case SellerActionTypes.INV_SELLER_LIST_REQUEST_ERROR:
            return {
                ...state,
                invSellerListSuccess: null,
                invSellerListError: action.payload
            }
        case SellerActionTypes.INV_UPDATE_SELLER_REQUEST_SUCCESS:
            return {
                ...state,
                updateInvSellerSuccess: action.payload,
                updateInvSellerError: null,
                updateInvSellerReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case SellerActionTypes.INV_UPDATE_SELLER_REQUEST_ERROR:
            return {
                ...state,
                updateInvSellerSuccess: null,
                updateInvSellerError: action.payload,
                updateInvSellerReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case SellerActionTypes.INV_UPDATE_SELLER_REQUEST_STATUS:
            return {
                ...state,
                updateInvSellerReqStatus: ''
            }
        case SellerActionTypes.LOGOUT:
            return INITIAL_STATE
        case SellerActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default invSellerReducer