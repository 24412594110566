export const SocketActionTypes = {
    NEW_ORDER_PLACED: 'NEW_ORDER_PLACED',
    CLEAR_NEW_ORDER_DATA: 'CLEAR_NEW_ORDER_DATA',

    CALL_MADE: 'CALL_MADE',
    ANSWER_MADE: 'ANSWER_MADE',

    USER_CALLED: 'USER_CALLED',
    CALL_ACCEPTED: 'CALL_ACCEPTED',
    CALL_ENDED: 'callEnded',
    UPDATE_CALL_STATE: 'UPDATE_CALL_STATE',
    MAKE_CALL: 'MAKE_CALL',

    ORDER_STATUS_UPDATE: 'ORDER_STATUS_UPDATE',
    CLEAR_ORDER_STATUS_UPDATE: 'CLEAR_ORDER_STATUS_UPDATE',

    COLLECT_ORDER_PAYMENT: 'COLLECT_ORDER_PAYMENT',
    CLEAR_COLLECT_ORDER_PAYMENT: 'CLEAR_COLLECT_ORDER_PAYMENT',

    CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS: 'CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS',
    CLEAR_CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS: 'CLEAR_CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS',

    CALL_WAITER_REQUEST: 'CALL_WAITER_REQUEST',
    CLEAR_CALL_WAITER_REQUEST: 'CLEAR_CALL_WAITER_REQUEST',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE'
}
