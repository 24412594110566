import React, { useEffect, useState, useContext, useRef } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, isEmployeeTypeWaiter, isEmpty, isImageAvailable, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, priceWithSymbol, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import {
    placeOrderRequest, placeOrderRequestStatus, updateExistingOrderRequest,
    updateExistingOrderRequestStatus, findPayAtEndOrderRequest
} from '../../../redux/order/order.action'
import { orderPlaceSuccessPayload } from '../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import { SocketContext } from '../../../SocketCtx'
import { config } from "../../../config";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import { ORDER_DELIVERY_TYPE_LIST } from "../../../utils/ReservationType";
import CommonButton from "../../../components/button/common-button/commonButton";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useReactToPrint } from 'react-to-print';

let stripeAccountId = ''
const CartReviewScreen = props => {
    const dispatch = useDispatch()
    const { user, customerLoginSuccess, restaurantDetailSuccess, locationId, restaurantId,
        employeeSelectedRestaurant, employeeSelectedLocation, branchDetailSuccess } = useSelector(state => state.user)
    const { cartItem, totalAmount, subTotal, taxAmount, discountAmt } = useSelector(state => state.cart)
    const { placeOrderSuccess, placeOrderError, placeOrderStatus, updateExistingOrderSuccess,
        updateExistingOrderError, updateExistingOrderReqStatus,
        findPayAtEndOrderSuccess, findPayAtEndOrderError } = useSelector(state => state.order)
    const [tableNumber, setTableNumber] = useState('')
    const [curbsideNumber, setCurbsideNumber] = useState('')
    const [roomNumber, setRoomNumber] = useState('')
    const [name, setName] = useState('')
    const [isLoading, setLoading] = useState(false)
    let navigate = useNavigate();
    const [selectedDeliveryType, setSelectedDeliveryType] = useState('')
    const [otherInformation, setOtherInformation] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const socket = useContext(SocketContext)
    const [paymentModeOffline, setPaymentModeOffline] = useState(false)
    const [paymentModeAtEnd, setPaymentModeAtEnd] = useState(false)
    const [paymentModeOnline, setPaymentModeOnline] = useState(false)
    const [displayStripePaymentMode, setDisplayStripePaymentMode] = useState(false)
    const [displayOfflinePaymentMode, setDisplayOfflinePaymentMode] = useState(false)
    const [displayPayAtEndPaymentMode, setDisplayPayAtEndPaymentMode] = useState(false)
    const [isCancelOrderSelected, setIsCancelOrderSelected] = useState(false)
    const [isContinueOrderSelected, setIsContinueOrderSelected] = useState(false)
    const [isAskCustomerSelected, setIsAskCustomerSelected] = useState(false)
    const [isOrderAccepted, setIsOrderAccepted] = useState(false)
    const [isOrderPreparing, setIsOrderPreparing] = useState(false)
    const [isOrderPrepared, setIsOrderPrepared] = useState(false)
    const [isOrderServed, setIsOrderServed] = useState(false)
    const [isOrderDelivered, setIsOrderDelivered] = useState(false)
    const [isOrderPaymentPaid, setIsOrderPaymentPaid] = useState(false)
    const [isOrderPaymentPending, setIsOrderPaymentPending] = useState(false)
    const [orderPaymentMode, setOrderPaymentMode] = useState('')
    const [mobileNumber, setMobileNumber] = useState()
    const orderToPrint = useRef(null)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_CART_REVIEW',
            screenClass: 'CUSTOMER_CART_REVIEW'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) {
            if (restaurantDetailSuccess.data.orderPaymentModes) {
                const onlinePG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
                if (!isNull(onlinePG)) {
                    const stripePG = onlinePG.optionType.find(b => b.type === APP_CONSTANTS.STRIPE)
                    if (!isNull(stripePG)) {
                        setDisplayStripePaymentMode(true)
                        stripeAccountId = stripePG.accountId
                    }
                }

                const cashPG = restaurantDetailSuccess.data.orderPaymentModes.filter(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE)
                if (!isNull(cashPG)) {
                    setDisplayOfflinePaymentMode(true)
                }
                const payAtEndPG = restaurantDetailSuccess.data.orderPaymentModes.filter(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END)
                if (!isNull(payAtEndPG)) {
                    setDisplayPayAtEndPaymentMode(true)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (!isNull(findPayAtEndOrderSuccess)) {
            setLoading(false)
            setPaymentModeAtEnd(true)
            displaySuccessToast('Ongoing order details found successfully. Please continue with placing order')
        } else if (!isNull(findPayAtEndOrderError)) {
            setLoading(false)
            displayErrorToast('No ongoing order found. Please continue with placing order')
        }
    }, [findPayAtEndOrderSuccess, findPayAtEndOrderError])

    const getPaymentModelLabel = type => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && !isEmpty(restaurantDetailSuccess.data.orderPaymentModes)) {
            const gatewayName = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === type)
            if (!isNull(gatewayName)) {
                return gatewayName.label ? gatewayName.label : gatewayName.gatewayType
            } else {
                return ''
            }
        } else return ''
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === false && isLoggedInUserEmployee(user) === false) {
            fetchPayAtEndDetails()
        }
    }, [])

    const fetchPayAtEndDetails = () => {
        if (isLoggedInUserEmployee(user) && isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user) && isNull(selectedDeliveryType)) {
            displayErrorToast('Delivery type not selected')
        } else if (isLoggedInUserEmployee(user) &&
            isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user) &&
            !isNull(selectedDeliveryType) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN && isEmpty(tableNumber)) {
            displayErrorToast('Table number cannot be blank')
        } else if (isLoggedInUserEmployee(user) &&
            isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user) &&
            !isNull(selectedDeliveryType) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE && isEmpty(curbsideNumber)) {
            displayErrorToast('Curbside number cannot be blank')
        } else if (isLoggedInUserEmployee(user) &&
            isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user) &&
            !isNull(selectedDeliveryType) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM && isEmpty(roomNumber)) {
            displayErrorToast('Curbside number cannot be blank')
        } else {
            const data = {}
            if (isLoggedInUserBusiness(user) === false && isLoggedInUserEmployee(user) === false &&
                !isEmpty(customerLoginSuccess)) {
                data.customerId = customerLoginSuccess.data.userDetails.userId
            }
            if (isLoggedInUserEmployee(user) && isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user)) {
                data.employeeId = user.data.userDetails?.employeeDetails?.employeeDetailId
                if (!isNull(selectedDeliveryType) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN) {
                    data.dineInTableNumber = tableNumber
                }
                if (!isNull(selectedDeliveryType) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE) {
                    data.curbsideSlotNumber = curbsideNumber
                }
                if (!isNull(selectedDeliveryType) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM) {
                    data.roomNumber = roomNumber
                }
            }
            setLoading(true)
            dispatch(findPayAtEndOrderRequest(data))
        }
    }

    const isOnGoingOrder = () => {
        if (!isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID))) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (placeOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            if (placeOrderSuccess && placeOrderSuccess.data && placeOrderSuccess.data.order) {
                const data = {
                    orderNumber: placeOrderSuccess.data.order.orderNumber,
                    restaurantId: restaurantId,
                    orderNumberId: placeOrderSuccess.data.order.orderId
                }
                dispatch(orderPlaceSuccessPayload(data))
                if (placeOrderSuccess.data.onlinePaymentLink && !isEmpty(placeOrderSuccess.data.onlinePaymentLink)) {
                    dispatch({ type: APP_CONSTANTS.CLEAR_CART })
                    window.open(placeOrderSuccess.data.onlinePaymentLink, '_self')
                } else {
                    if (placeOrderSuccess.data.transaction.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END) {
                        localStorage.setItem(APP_CONSTANTS.SUCCESS_ORDER_ID, placeOrderSuccess.data.transaction.orderId)
                        localStorage.setItem(APP_CONSTANTS.SUCCESS_ORDER_NUMBER, placeOrderSuccess.data.order.orderNumber)
                        localStorage.setItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID, placeOrderSuccess.data.transaction.billId)
                        localStorage.setItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID, placeOrderSuccess.data.transaction.transactionId)
                    }
                    setShowSuccess(true)
                    socket.emit('order-placed-success', data)
                }
            }
        } else if (placeOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(placeOrderError.message)
            if (placeOrderError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                navigate(-2)
            }
        }
        if (placeOrderStatus.length) {
            dispatch(placeOrderRequestStatus(''))
        }
    }, [placeOrderSuccess, placeOrderError, placeOrderStatus])

    useEffect(() => {
        if (updateExistingOrderReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            const data = {
                orderNumber: findPayAtEndOrderSuccess.data.orderNumber,
                restaurantId: restaurantId,
                orderNumberId: findPayAtEndOrderSuccess.data.orderId
            }
            // const data = {
            //     orderNumber: localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_NUMBER),
            //     restaurantId: restaurantId,
            //     orderNumberId: localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)
            // }
            setShowSuccess(true)
            socket.emit('order-placed-success', data)
        } else if (updateExistingOrderReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateExistingOrderError.message)
            if (updateExistingOrderError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                navigate(-2)
            }
        }
        if (updateExistingOrderReqStatus.length) {
            dispatch(updateExistingOrderRequestStatus(''))
        }
    }, [updateExistingOrderSuccess, updateExistingOrderError, updateExistingOrderReqStatus])

    useEffect(() => {
        displayConsoleLog('selectedDeliveryType', selectedDeliveryType)
    }, [selectedDeliveryType])


    const handlePlaceOrderClick = () => {
        if (isNull(cartItem)) {
            displayErrorToast('No item in cart found. Please add and then place order')
        } else {
            handlePlaceOrder()
        }
    }

    const handlePlaceOrder = () => {
        if (!isNull(findPayAtEndOrderSuccess)) {
            updateExistingOrder()
        } else {
            if (isEmpty(name)) {
                displayErrorToast('Customer name cannot be blank')
            } else if (isLoggedInUserBusiness(user) && isEmpty(mobileNumber)) {
                displayErrorToast('Customer number cannot be blank')
            } else if (isEmpty(selectedDeliveryType)) {
                displayErrorToast('Delivery type cannot be blank')
            } else if (selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN && isEmpty(tableNumber)) {
                displayErrorToast('Table number cannot be blank')
            } else if (selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE && isEmpty(curbsideNumber)) {
                displayErrorToast('Curbside number cannot be blank')
            } else if (selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM && isEmpty(roomNumber)) {
                displayErrorToast('Room number cannot be blank')
            } else if (paymentModeOffline === false && paymentModeOnline === false && paymentModeAtEnd === false) {
                displayErrorToast('Payment mode not selected')
            } else if (paymentModeOnline === true && isNull(restaurantDetailSuccess)) {
                displayErrorToast('Error occurred while fetch online payment available modes')
            } else if (!isLoggedInUserBusiness(user) && isCancelOrderSelected === false && isContinueOrderSelected === false && isAskCustomerSelected === false) {
                displayErrorToast('Option not selected if one or some ordered items is not available')
            } else if (isLoggedInUserBusiness(user) && isOrderAccepted === false && isOrderPreparing === false && isOrderPrepared === false && isOrderDelivered === false) {
                displayErrorToast('Order status not selected')
            } else if (isLoggedInUserBusiness(user) && isOrderPaymentPaid === false && isOrderPaymentPending === false) {
                displayErrorToast('Bill Payment information option not selected')
            } else if (isLoggedInUserBusiness(user) && isOrderPaymentPaid === true && isEmpty(orderPaymentMode)) {
                displayErrorToast('Bill payment mode cannot be empty')
            } else {
                const data = {
                    deliveryType: selectedDeliveryType.key,
                    subTotal: subTotal.toFixed(2),
                    discountAmount: discountAmt.toFixed(2),
                    taxAmount: taxAmount.toFixed(2),
                    deliveryCharges: 0,
                    grandTotal: totalAmount.toFixed(2),
                    itemsList: cartItem,
                    contactPersonName: name,
                    currencyLabel: cartItem[0].currencyLabel,
                    currencySymbol: cartItem[0].currencySymbol,
                    currencyRegion: cartItem[0].currencyRegion
                }
                if (!isNull(customerLoginSuccess)) {
                    data.orderProviderType = APP_CONSTANTS.USER_TYPE.OTHER_ROLE.CUSTOMER
                }
                if (isLoggedInUserBusiness(user)) {
                    data.orderProviderType = APP_CONSTANTS.USER_TYPE.BUSINESS
                }
                if (isLoggedInUserEmployee(user)) {
                    if (isEmployeeTypeWaiter(user)) {
                        data.orderProviderType = APP_CONSTANTS.USER_TYPE.OTHER_ROLE.WAITER
                    } else {
                        data.orderProviderType = APP_CONSTANTS.USER_TYPE.EMPLOYEE
                    }
                }
                if (isLoggedInUserBusiness(user) === false &&
                    isLoggedInUserEmployee(user) === false &&
                    !isNull(customerLoginSuccess)) {
                    data.customerId = customerLoginSuccess.data.userDetails.userId
                    data.contactPersonNumber = customerLoginSuccess.data.userDetails.mobile
                }
                if (!isNull(locationId)) {
                    data.locationId = locationId
                }
                if (!isNull(restaurantDetailSuccess)) {
                    data.businessCode = restaurantDetailSuccess.data.businessCode
                    data.businessId = restaurantDetailSuccess.data.businessId
                    data.productCode = restaurantDetailSuccess.data.productCode
                }
                if (!isNull(restaurantId)) {
                    data.businessId = restaurantId
                }
                if (isLoggedInUserEmployee(user) && isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user)) {
                    data.employeeId = user.data.userDetails?.employeeDetails?.employeeDetailId
                }
                if (isLoggedInUserBusiness(user)) {
                    if (!isEmpty(mobileNumber)) {
                        const mobile = mobileNumber.replace('+', "")
                        data.contactPersonNumber = parseFloat(mobile)
                    }
                    if (isOrderAccepted === true) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.ACCEPTED
                    }
                    if (isOrderPreparing === true) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.PREPARING
                    }
                    if (isOrderPrepared === true) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.PREPARED
                    }
                    if (isOrderServed === true) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.SERVED
                    }
                    if (isOrderDelivered === true) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.DELIVERED
                    }
                    if (isOrderPaymentPaid === true) {
                        data.paymentStatus = APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID
                        data.paymentModeName = orderPaymentMode
                    }
                }
                if (isContinueOrderSelected === true) {
                    data.itemNotAvailableOption = APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CONTINUE_WTIH_AVAILABLE_ITEMS
                }
                if (isCancelOrderSelected === true) {
                    data.itemNotAvailableOption = APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CANCEL_ORDER
                }
                if (isAskCustomerSelected === true) {
                    data.itemNotAvailableOption = APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.ASK_THE_CUSTOMER
                }
                if (selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN) {
                    data.dineInTableNumber = tableNumber
                }
                if (selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE) {
                    data.curbsideSlotNumber = curbsideNumber
                }
                if (selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM) {
                    data.roomNumber = roomNumber
                }
                if (!isEmpty(otherInformation)) {
                    data.otherInformation = otherInformation
                }
                if (paymentModeOnline === true) {
                    if (!isNull(restaurantDetailSuccess.data) && !isEmpty(restaurantDetailSuccess.data.orderPaymentModes)) {
                        if (!isLoggedInUserBusiness(user)) {
                            data.orderStatus = ''
                        }
                        data.paymentMode = APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE
                        const onlinePG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
                        if (!isNull(onlinePG)) {
                            const stripePG = onlinePG.optionType.find(b => b.type === APP_CONSTANTS.STRIPE)
                            if (!isNull(stripePG)) {
                                data.paymentModeName = stripePG.type
                                data.stripeAccountId = stripeAccountId
                                data.successUrl = `${config.clientUrl}${ROUTES.orderPaymentSuccessUrl}`
                                data.cancelUrl = `${config.clientUrl}${ROUTES.orderPaymentCancelUrl}`
                            } else {
                                displayErrorToast('Error occurred while fetching online payment mode')
                                return
                            }
                        } else {
                            displayErrorToast('Error occurred while fetching online payment mode')
                            return
                        }
                    } else {
                        displayErrorToast('Error occurred while fetching online payment mode')
                        return
                    }
                }
                if (paymentModeOffline === true) {
                    if (!isLoggedInUserBusiness(user)) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.PLACED
                    }
                    data.paymentMode = APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE
                }
                if (paymentModeAtEnd === true) {
                    if (!isLoggedInUserBusiness(user)) {
                        data.orderStatus = APP_CONSTANTS.ORDER_STATUS.PLACED
                    }
                    data.paymentMode = APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END
                }
                setLoading(true)
                dispatch(placeOrderRequest(data))
            }
        }
    }

    const updateExistingOrder = () => {
        setLoading(true)
        const data = {
            orderId: findPayAtEndOrderSuccess.data.orderId,
            billId: findPayAtEndOrderSuccess.data.billDetails.billId,
            transactionId: findPayAtEndOrderSuccess.data.transactionDetails.transactionId,
            subTotal: subTotal.toFixed(2),
            discountAmount: discountAmt.toFixed(2),
            taxAmount: taxAmount.toFixed(2),
            deliveryCharges: 0,
            grandTotal: totalAmount.toFixed(2),
            itemsList: cartItem
        }
        // const data = {
        //     orderId: localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID),
        //     billId: localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID),
        //     transactionId: localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID),
        //     subTotal: subTotal.toFixed(2),
        //     discountAmount: discountAmt.toFixed(2),
        //     taxAmount: taxAmount.toFixed(2),
        //     deliveryCharges: 0,
        //     grandTotal: totalAmount.toFixed(2),
        //     itemsList: cartItem
        // }
        dispatch(updateExistingOrderRequest(data))

    }

    const handleOrderSuccessModalClose = () => {
        setShowSuccess(false)
        dispatch({ type: APP_CONSTANTS.CLEAR_CART })
        navigate(-2)
    }

    const handlePaymentModeChange = (type) => {
        if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE) {
            setPaymentModeOnline(false)
            setPaymentModeOffline(true)
            setPaymentModeAtEnd(false)
        }
        if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE) {
            setPaymentModeOffline(false)
            setPaymentModeOnline(true)
            setPaymentModeAtEnd(false)
        }
        if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END) {
            setPaymentModeOffline(false)
            setPaymentModeOnline(false)
            setPaymentModeAtEnd(true)
        }
    }

    useEffect(() => {
        if (!isEmpty(selectedDeliveryType) && paymentModeOffline === false && paymentModeOnline === false && paymentModeAtEnd === false) {
            setPaymentModeOffline(true)
        }
    }, [selectedDeliveryType])

    const handleItemNotAvailableChange = (type) => {
        if (type === APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.ASK_THE_CUSTOMER) {
            setIsAskCustomerSelected(true)
            setIsCancelOrderSelected(false)
            setIsContinueOrderSelected(false)
        }
        if (type === APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CANCEL_ORDER) {
            setIsAskCustomerSelected(false)
            setIsCancelOrderSelected(true)
            setIsContinueOrderSelected(false)
        }
        if (type === APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CONTINUE_WTIH_AVAILABLE_ITEMS) {
            setIsAskCustomerSelected(false)
            setIsCancelOrderSelected(false)
            setIsContinueOrderSelected(true)
        }
    }

    const handleOrderStatusChange = (type) => {
        if (type === APP_CONSTANTS.ORDER_STATUS.ACCEPTED) {
            setIsOrderAccepted(true)
            setIsOrderPreparing(false)
            setIsOrderPrepared(false)
            setIsOrderServed(false)
            setIsOrderDelivered(false)
        } else if (type === APP_CONSTANTS.ORDER_STATUS.PREPARING) {
            setIsOrderAccepted(false)
            setIsOrderPreparing(true)
            setIsOrderPrepared(false)
            setIsOrderServed(false)
            setIsOrderDelivered(false)
        } else if (type === APP_CONSTANTS.ORDER_STATUS.PREPARED) {
            setIsOrderAccepted(false)
            setIsOrderPreparing(false)
            setIsOrderPrepared(true)
            setIsOrderServed(false)
            setIsOrderDelivered(false)
        } else if (type === APP_CONSTANTS.ORDER_STATUS.SERVED) {
            setIsOrderAccepted(false)
            setIsOrderPreparing(false)
            setIsOrderPrepared(false)
            setIsOrderServed(true)
            setIsOrderDelivered(false)
        } else if (type === APP_CONSTANTS.ORDER_STATUS.DELIVERED) {
            setIsOrderAccepted(false)
            setIsOrderPreparing(false)
            setIsOrderPrepared(false)
            setIsOrderServed(false)
            setIsOrderDelivered(true)
        }
    }

    const handleOrderPaymentStatusChange = (type) => {
        if (type === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID) {
            setIsOrderPaymentPaid(true)
            setIsOrderPaymentPending(false)
        } else if (type === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PENDING) {
            setIsOrderPaymentPaid(false)
            setIsOrderPaymentPending(true)
        }
    }

    const handleOrderPrint = useReactToPrint({
        content: () => orderToPrint.current
    })

    const ComponentToPrint = React.forwardRef((props, ref) => {
        return (<div className="cr-print-container" ref={ref}>
            {!isEmpty(cartItem) && !isEmpty(restaurantDetailSuccess) && <div className="cr-restaurant-label">{restaurantDetailSuccess.data.businessName}</div>}
            {!isEmpty(cartItem) && !isEmpty(branchDetailSuccess) && <div className="cr-location-label">{branchDetailSuccess.data.name} - {branchDetailSuccess.data.city}, {branchDetailSuccess.data.state}, {branchDetailSuccess.data.country}</div>}
            {!isEmpty(cartItem) && <div className="cr-date-label">{new Date().toDateString()}</div>}
            {!isEmpty(cartItem) && <div className="cr-print-row-parent-container cr-print-row-container">
                <div className="cr-print-row-label cr-print-row-heading">Item</div>
                <div className="cr-print-row-qty cr-print-row-heading">Qty</div>
                <div className="cr-print-row-price cr-print-row-heading">Price</div>
            </div>}
            {!isEmpty(cartItem) && cartItem.map(e => (
                <div className="cr-print-row-parent-container">
                    <div className="cr-print-row-container">
                        <div className="cr-print-row-label">{e.name} ({e.measurementQuantity}/{e.measurementUnit})</div>
                        <div className="cr-print-row-qty"> x {e.quantity}</div>
                        <div className="cr-print-row-price">{priceWithSymbol(e, e.finalPrice)}</div>
                    </div>
                    {!isEmpty(e.options) && <div className="margin-top-10">
                        <div className="crl-customization-label">Options</div>
                        <div className="crl-customization-container">
                            {e.options.map(e1 => (<div className="crl-customization-item">{e1.name}</div>))}
                        </div>
                    </div>}
                    {!isEmpty(e.addOns) && <div className="margin-top-10">
                        <div className="crl-customization-label">Add-ons</div>
                        <div className="crl-customization-container">
                            {e.addOns.map(e1 => (<div className="crl-customization-item">{e1.name}</div>))}
                        </div>
                    </div>}
                </div>))}
            {!isEmpty(cartItem) && <div className="cr-print-col-container">
                <div>Sub Total: {priceWithSymbol(cartItem[0], subTotal)}</div>
                <div>Total Discount: {priceWithSymbol(cartItem[0], discountAmt)}</div>
                <div>Total Tax: {priceWithSymbol(cartItem[0], taxAmount)}</div>
                <div className="cr-print-row-heading">Final Amount: {priceWithSymbol(cartItem[0], totalAmount)}</div>
            </div>}
            {!isEmpty(cartItem) && <div className="cr-restaurant-label cr-location-label margin-top-10">Thank you for your visit! Please come again!</div>}
            {!isEmpty(cartItem) && <div className="cr-location-label">Have a great day!</div>}
            {!isEmpty(cartItem) && <div className="cr-date-label">Powered by: GoMoBites</div>}
        </div>)
    })

    return (
        <>
            <div className="cr-container mb-4">
                {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                    <div className="cmc-label-container mt-2">
                        <div className="mcp-logo-container">
                            {isImageAvailable(restaurantDetailSuccess.data.image) ?
                                (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} alt="restaurant" className="img" />) :
                                <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="restaurant" />}
                        </div>
                        <div className="mcp-restaurant-label">Order Review</div>
                    </div>
                ) :
                    (<div className="cmc-label-container">
                        <ScreenLabel label='Order Review' />
                    </div>)
                }
                {cartItem && <div className="cr-content-container">
                    <div className="cmc-content-container">
                        {cartItem.map(element => (
                            <div className="add-cr-row">
                                <div className="cr-item-label">{element.name}</div>
                                <div className="cr-amount-label-light">{priceWithSymbol(element, element.price)}</div>
                                <div className="cr-amount-label">Total Price: {priceWithSymbol(element, element.finalPrice)}</div>
                                {!isEmpty(element.options) && <div className="mt-1">
                                    <div className="crl-customization-label">Options</div>
                                    <div className="crl-customization-container">
                                        {element.options.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                {!isEmpty(element.addOns) && <div className="mt-1">
                                    <div className="crl-customization-label">Add-ons</div>
                                    <div className="crl-customization-container">
                                        {element.addOns.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                <div className="cr-qty-update-label">Quantity: {element.quantity}</div>
                            </div>
                        ))}
                    </div>

                    <div className="add-cr-modal-row mt-3">
                        <div className="add-cr-modal-row-item">Sub Total</div>
                        <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], subTotal)}</div>
                        {/* {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                            (<div className="add-cr-modal-row-item-value">{cartItem[0].currencySymbol} {subTotal.toFixed(2)}</div>)
                            : (<div className="add-cr-modal-row-item-value">{subTotal.toFixed(2)} {cartItem[0].currencySymbol}</div>)} */}
                    </div>
                    <div className="add-cr-modal-row">
                        <div className="add-cr-modal-row-item">Total Discount</div>
                        <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], discountAmt)}</div>
                        {/* {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                            (<div className="add-cr-modal-row-item-value">{cartItem[0].currencySymbol} {discountAmt.toFixed(2)}</div>)
                            : (<div className="add-cr-modal-row-item-value">{discountAmt.toFixed(2)} {cartItem[0].currencySymbol}</div>)} */}
                    </div>
                    <div className="add-cr-modal-row">
                        <div className="add-cr-modal-row-item">Total Tax</div>
                        <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], taxAmount)}</div>
                        {/* {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                            (<div className="add-cr-modal-row-item-value">{cartItem[0].currencySymbol} {taxAmount.toFixed(2)}</div>)
                            : (<div className="add-cr-modal-row-item-value">{taxAmount.toFixed(2)} {cartItem[0].currencySymbol}</div>)} */}
                    </div>
                    <div className="add-cr-modal-row">
                        <div className="add-cr-modal-row-item">Grand Total</div>
                        <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], totalAmount)}</div>
                        {/* {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                            (<div className="add-cr-modal-row-item-value">{cartItem[0].currencySymbol} {totalAmount.toFixed(2)}</div>)
                            : (<div className="add-cr-modal-row-item-value">{totalAmount.toFixed(2)} {cartItem[0].currencySymbol}</div>)} */}
                    </div>

                    {isNull(findPayAtEndOrderSuccess) && <div className="cr-item-label mt-3">Other Information</div>}
                    {isNull(findPayAtEndOrderSuccess) && <div className="cr-customer-info-container">
                        <CommonInputFull
                            type="text"
                            id="name"
                            placeholder="Customer Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        {isLoggedInUserBusiness(user) && <PhoneInput
                            placeholder="Mobile Number"
                            value={mobileNumber}
                            onChange={setMobileNumber}
                            defaultCountry="US" />}
                    </div>}

                    {isNull(findPayAtEndOrderSuccess) &&
                        <CommonInputFull
                            type="text"
                            id="name"
                            placeholder="Special Request"
                            value={otherInformation}
                            onChange={e => setOtherInformation(e.target.value)}
                        />}

                    {isNull(findPayAtEndOrderSuccess) && <Select
                        className='select-full-width-no-border'
                        value={selectedDeliveryType}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e.key}
                        options={ORDER_DELIVERY_TYPE_LIST}
                        placeholder='Select Delivery Type'
                        menuPlacement='auto'
                        onChange={e => setSelectedDeliveryType(e)} />}
                    {isNull(findPayAtEndOrderSuccess) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN ? (
                        <CommonInputFull
                            type="text"
                            id="email"
                            placeholder="Table Number"
                            value={tableNumber}
                            onChange={e => setTableNumber(e.target.value)}
                            isRequired={true}
                        />) : null}
                    {isNull(findPayAtEndOrderSuccess) && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE ? (
                        <CommonInputFull
                            type="text"
                            id="email"
                            placeholder="Curbside Number"
                            value={curbsideNumber}
                            onChange={e => setCurbsideNumber(e.target.value)}
                            isRequired={true}
                        />) : null}
                    {isNull(findPayAtEndOrderSuccess) && selectedDeliveryType.name === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM ? (
                        <CommonInputFull
                            type="text"
                            id="email"
                            placeholder="Room Number"
                            value={roomNumber}
                            onChange={e => setRoomNumber(e.target.value)}
                            isRequired={true}
                        />) : null}
                    {!isNull(selectedDeliveryType) && isNull(findPayAtEndOrderSuccess) && <div className="cr-item-label">Select Payment Modes</div>}
                    {!isNull(selectedDeliveryType) && isNull(findPayAtEndOrderSuccess) && <div className="cr-payment-mode-container">
                        {(selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE || selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN ||
                            selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.NOT_APPLICABLE || selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM) && displayOfflinePaymentMode && <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE} checked={paymentModeOffline} onChange={e => handlePaymentModeChange(e.target.value)} />
                                <span className="cr-payment-mode-label">{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE)}</span>
                            </label>}
                        {(selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE || selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN ||
                            selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.NOT_APPLICABLE || selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM)
                            && displayStripePaymentMode && <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE} checked={paymentModeOnline} onChange={e => handlePaymentModeChange(e.target.value)} />
                                <span className='cr-payment-mode-label'>{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)}</span>
                            </label>}
                        {displayPayAtEndPaymentMode && selectedDeliveryType.key === APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN && <label className="cr-payment-mode-label-container">
                            <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END} checked={paymentModeAtEnd} onChange={e => handlePaymentModeChange(e.target.value)} />
                            <span className="cr-payment-mode-label">{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END)}</span>
                        </label>}
                    </div>}
                    {!isNull(findPayAtEndOrderSuccess) && <div className="cr-payment-mode-container">
                        {displayPayAtEndPaymentMode && <label className="cr-payment-mode-label-container">
                            <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END} checked={paymentModeAtEnd} onChange={e => handlePaymentModeChange(e.target.value)} />
                            <span className="cr-payment-mode-label">{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END)}</span>
                        </label>}
                    </div>}
                    {!isLoggedInUserBusiness(user) && <div>
                        <div className="cr-item-label">Select option when items not available</div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.ASK_THE_CUSTOMER} checked={isAskCustomerSelected} onChange={e => handleItemNotAvailableChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Ask The Customer</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CONTINUE_WTIH_AVAILABLE_ITEMS} checked={isContinueOrderSelected} onChange={e => handleItemNotAvailableChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Continue with available items</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CANCEL_ORDER} checked={isCancelOrderSelected} onChange={e => handleItemNotAvailableChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Cancel Order</span>
                            </label>
                        </div>
                    </div>}
                    {isLoggedInUserBusiness(user) && <div>
                        <div className="cr-item-label">Select order status</div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.ACCEPTED} checked={isOrderAccepted} onChange={e => handleOrderStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Accepted</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.PREPARING} checked={isOrderPreparing} onChange={e => handleOrderStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Preparing</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.PREPARED} checked={isOrderPrepared} onChange={e => handleOrderStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Prepared</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.SERVED} checked={isOrderServed} onChange={e => handleOrderStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Served</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.DELIVERED} checked={isOrderDelivered} onChange={e => handleOrderStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Delivered</span>
                            </label>
                        </div>
                        <div className="cr-item-label">Payment Information</div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID} checked={isOrderPaymentPaid} onChange={e => handleOrderPaymentStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Bill Payment Paid</span>
                            </label>
                        </div>
                        <div className="cr-payment-mode-container">
                            <label className="cr-payment-mode-label-container">
                                <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_STATUS.PENDING} checked={isOrderPaymentPending} onChange={e => handleOrderPaymentStatusChange(e.target.value)} />
                                <span className="cr-payment-mode-label-light">Bill Payment Pending</span>
                            </label>
                        </div>
                        {isOrderPaymentPaid === true && <div className="margin-top-10">
                            <CommonInputFull
                                type="text"
                                id='orderPaymentMode'
                                onChange={e => setOrderPaymentMode(e.target.value)}
                                placeholder='Payment Mode (Ex. Cash/Card/Other)'
                                value={orderPaymentMode}
                                isRequired={true}
                            />
                        </div>}
                    </div>}
                    {isLoggedInUserBusiness(user) || (isLoggedInUserEmployee(user) && isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user) === true) && <button type="button" className="btn add-cr-btn-inverted" onClick={() => { fetchPayAtEndDetails() }}>Get Ongoing Order Details</button>}
                    <button type="button" className="btn add-cr-btn" onClick={() => { handlePlaceOrderClick() }}>Place Order</button>
                </div>}
                <div style={{ display: 'none' }}><ComponentToPrint ref={orderToPrint} /></div>
                {!cartItem && <div>Your cart is empty</div>}
            </div>
            <Modal
                onHide={() => { handleOrderSuccessModalClose() }}
                show={showSuccess}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Success
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cr-modal-container">
                        <div className="cr-modal-img-container">
                            <img className="img" src={require('../../../assets/success_icon.png')} alt="success" />
                        </div>
                        <div className="cr-modal-order-success">Order Placed Success</div>
                        <div className="cr-modal-order-subtext">Your order is received and will be updated by restaurant shortly.</div>
                        <div className="cr-modal-order-number">Order Number</div>
                        {placeOrderSuccess && placeOrderSuccess.data && placeOrderSuccess.data.order &&
                            <div className="cr-modal-order-number cr-modal-order-number-val">
                                {/* {placeOrderSuccess.data.order.orderNumber ? placeOrderSuccess.data.order.orderNumber :  localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_NUMBER)} */}
                                {placeOrderSuccess.data.order.orderNumber ? placeOrderSuccess.data.order.orderNumber : '-'}
                            </div>}
                        {updateExistingOrderSuccess && updateExistingOrderSuccess.data &&
                            <div className="cr-modal-order-number cr-modal-order-number-val">
                                {/* {placeOrderSuccess.data.order.orderNumber ? placeOrderSuccess.data.order.orderNumber :  localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_NUMBER)} */}
                                {updateExistingOrderSuccess.data.orderNumber ? updateExistingOrderSuccess.data.orderNumber : '-'}
                            </div>}
                        {isLoggedInUserBusiness(user) && <div className="cr-print-btn-container">
                            <div className="cr-print-btn" onClick={handleOrderPrint}>Print</div>
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CartReviewScreen