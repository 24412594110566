import React from "react";
import './styles.css'
import TextField from '@mui/material/TextField';
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { orange, grey } from '@mui/material/colors';

const CommonInputHalf = ({ type, classname = "", placeholder, value, onChange, id, isRequired = false, isSubtext = false, subText }) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#f68d46',
                light: '#f68d46'
            }
        },
        typography: {
            fontSize: 13
        }
    })

    return (
        <div className='common-input-half'>
            <ThemeProvider theme={theme}>
                <TextField
                    className="common-input-full"
                    type={type}
                    required={isRequired}
                    id={id}
                    label={placeholder}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    size="medium"
                    color="primary"
                    inputProps={{ style: { backgroundColor: 'white' } }}
                    helperText={isSubtext ? subText : ''}
                />
            </ThemeProvider>
        </div>
        // <div className={`form-floating common-input-half ${classname}`}>
        //     <input
        //         type={type}
        //         className="form-control"
        //         id={id}
        //         placeholder={placeholder}
        //         value={value}
        //         onChange={onChange} />
        //     <label for={id}>{placeholder}</label>
        // </div>
    )
}

export default CommonInputHalf