import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import notificationTone from "../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../redux/socket/socket.action'
import moment from "moment";
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";

const LocationListScreen = props => {
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, branchListSuccess, branchListError,
        restuarantListSuccess, restuarantListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    let navigate = useNavigate();
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const LOCATION_LIST = 'LOCATION_LIST'
    const EDIT_LOCATION = 'EDIT_LOCATION'
    const ADD_LOCATION = 'ADD_LOCATION'

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        const data = {
            screen: 'LOCATION_LIST',
            screenClass: 'LOCATION_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.LOCATION_LIST, true)
            setLoading(true)
            const data = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.LOCATION_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.LOCATION_LIST, false)
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }

    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (!isNull(branchListSuccess)) {
            setLoading(false)
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant)) {
            fetchBranchListReq(employeeSelectedRestaurant)
        }
    }, [employeeSelectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            fetchBranchListReq(selectedRestaurant.businessId)
        }
    }, [selectedRestaurant])

    const fetchBranchListReq = (restId) => {
        setLoading(true)
        const data = {
            businessId: restId
        }
        dispatch(branchListRequest(data))
    }

    const addLocationClickHandle = () => {
        if (checkFunctionalityPermission(ADD_LOCATION) === true) {
            navigate(ROUTES.addLocation)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const enableClickHandle = () => {

    }
    const disableClickHandle = () => {

    }

    const handleCardClick = element => {
        if (checkFunctionalityPermission(EDIT_LOCATION) === true) {
            navigate(ROUTES.editLocation, { state: element });
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleCardDetailClick = element => {
        if (checkFunctionalityPermission(EDIT_LOCATION) === true) {
            navigate(ROUTES.locationDetail, { state: element.locationId });
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.LOCATION && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === LOCATION_LIST) {
                    if (itemObj.locationList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === EDIT_LOCATION) {
                    if (itemObj.editLocation === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === ADD_LOCATION) {
                    if (itemObj.addLocation === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='mt-2'>
                    <ScreenLabel label='Locations' />
                    <TopButton label='Add Location'
                        onClick={() => addLocationClickHandle()} />
                </CommonInputRow>
                {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                    className='select-full-width-no-border'
                    value={selectedRestaurant}
                    getOptionLabel={e => e.businessName}
                    getOptionValue={e => e}
                    options={restuarantListSuccess.data}
                    placeholder='Select Restaurant'
                    onChange={e => setSelectedRestaurant(e)} />}
                {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="content-error">No Restaurant found</div>}
                <div className="table-scroll-container">
                    {checkFunctionalityPermission(LOCATION_LIST) === true && branchListSuccess && branchListSuccess.data && <table class="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="loc-table-col-title">Name</th>
                                <th scope="col" className="loc-table-col-title">Address</th>
                                <th scope="col" className="loc-table-col-title">Status</th>
                                <th scope="col" className="loc-table-col-title">Date</th>
                                <th scope="col" className="loc-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {branchListSuccess.data.map(element => (
                                <tr className="loc-table-row-container">
                                    <td className="loc-table-col-item">{element.name}</td>
                                    <td className="loc-table-col-item">{element.city ? element.city : '-'}, {element.state ? element.state : '-'}, {element.country ? element.country : '-'}</td>
                                    <td className={`loc-table-col-item ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="loc-table-col-item">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="loc-table-col-item">
                                        <div className="loc-list-button-container">
                                            <div className="loc-list-button" onClick={() => { handleCardDetailClick(element) }}>Detail</div>
                                            <div className="loc-list-button" onClick={() => { handleCardClick(element) }}>Edit</div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {checkFunctionalityPermission(LOCATION_LIST) === true && branchListError && <div className="content-error">No Location Found</div>}
                {checkFunctionalityPermission(LOCATION_LIST) === false && <div className="content-error">You are not authorized to view locations list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default LocationListScreen