import axios from 'axios'
import { config } from '../../../config'
import { APP_CONSTANTS } from '../../../utils/AppConstants'
import API_ENDPOINTS from '../endpoints'

export function addOrderInvoice(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.orderInvoice.addOrderInvoice}`,
        data: payload,
        headers: {
            'authToken': user.authToken,
            "Content-Type": "multipart/form-data",
        }
    })
}

export function orderInvoiceList(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.orderInvoice.orderInvoiceList}`,
        params: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function orderInvoiceDetail(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.orderInvoice.orderInvoiceDetail}/${payload}`,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateOrderInvoicePayment(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.orderInvoice.updateInvoicePaymentDetails}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}