import axios from 'axios'
import API_ENDPOINTS from './endpoints'
import { config } from '../../config'

export function addFeatureRequest(formData) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.featureRequest.addRequest}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export function featureRequestList(data) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.featureRequest.requestList}`,
        params: data
    })
}

export function addComment(data) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.featureRequest.addComment}`,
        data: data
    })
}

export function upVoteFeatureRequest(data) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.featureRequest.upVote}`,
        data: data
    })
}

export function featureRequestDetail(data) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.featureRequest.requestDetail}/${data}`
    })
}