import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addRestaurantRequest, addRestaurantRequestStatus, countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import Select from 'react-select'
import CommonButton from "../../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import { CurrencyLabel } from "../../../../utils/CurrencyLabel";
import { config } from "../../../../config";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import ButtonRow from "../../../../components/button/button-row/buttonRow";

const AddRestaurantScreen = props => {
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [name, setName] = useState('')
    const [brandName, setBrandName] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, addRestaurantSuccess, addRestaurantError,
        addRestaurantStatus, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const [isAddStripePaymentMode, setAddStripePaymentMode] = useState(false)
    const [isAddOfflinePaymentMode, setAddOfflinePaymentMode] = useState(false)
    const [isAddPayAtEndPaymentMode, setAddPayAtEndPaymentMode] = useState(false)
    const [stripeAccountId, setStripeAccountId] = useState('')

    useEffect(() => {
        const data = {
            screen: 'ADD_RESTAURANT',
            screenClass: 'ADD_RESTAURANT'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        dispatch(countryListRequest())
    }, [])

    const fetchStateList = (countryId) => {
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (addRestaurantStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            // displaySuccessToast(addRestaurantSuccess.message)
            displaySuccessToast('Restaurant added successfully. Add Location to the restaurant from location menu.')
            clearFields()
        } else if (addRestaurantStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addRestaurantError.message)
        }

        if (addRestaurantStatus.length) {
            dispatch(addRestaurantRequestStatus(''))
        }
    }, [addRestaurantSuccess, addRestaurantError, addRestaurantStatus])

    const clearFields = () => {
        setName('')
        setBrandName('')
        setImagePath(null)
        setImageName('')
    }

    const handleNameChange = e => {
        const businessName = e.target.value
        setName(businessName)
        let businessNameUp = businessName.toUpperCase()
        businessNameUp = businessNameUp.replace(/[^a-zA-Z]/g, "")
        setBrandName(businessNameUp)
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    const handleBrandNameChange = e => {
        let brandN = e.target.value
        brandN = brandN.replace(/[^a-zA-Z]/g, "")
        setBrandName(brandN)
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Restaurant name cannot be blank')
        } else if (isEmpty(brandName)) {
            displayErrorToast('Brand name cannot be blank')
        } else if (isNull(selectedCurrency)) {
            displayErrorToast('Price currency not selected')
        } else if (isAddOfflinePaymentMode === false && isAddPayAtEndPaymentMode === false && isAddStripePaymentMode === false) {
            displayErrorToast('Payment mode not selected', true)
        } else if (isAddStripePaymentMode === true && isEmpty(stripeAccountId)) {
            displayErrorToast('Stripe account id cannot be blank', true)
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Restaurant country is not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Restaurant state is not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Resturant state is not selected')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('businessCode', brandName)
            fd.append('businessName', name)
            fd.append('userId', user.data.userDetails.userId)
            fd.append('email', user.data.userDetails.email)
            fd.append('productCode', user.data.userDetails.productCode)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            fd.append('countryName', countryDropdown.name)
            fd.append('countryCode', countryDropdown.iso2)
            fd.append('stateName', stateDropdown.name)
            fd.append('stateCode', stateDropdown.iso2)
            fd.append('city', cityDropdown.name)
            fd.append('currencyLabel', selectedCurrency.code)
            fd.append('currencySymbol', selectedCurrency.symbol)
            fd.append('currencyRegion', selectedCurrency.region)
            // fd.append('returnUrl', `${config.loginUrl}`)
            fd.append('returnUrl', `${window.location.href}`)
            fd.append('addHomeLocation', 1)
            fd.append('allowOnlineOrdering', APP_CONSTANTS.YES)
            if (isAddOfflinePaymentMode === true) {
                fd.append('addOfflinePaymentMode', APP_CONSTANTS.YES)
            } else {
                fd.append('addOfflinePaymentMode', APP_CONSTANTS.NO)
            }
            if (isAddPayAtEndPaymentMode === true) {
                fd.append('addPayAtEndPaymentMode', APP_CONSTANTS.YES)
            } else {
                fd.append('addPayAtEndPaymentMode', APP_CONSTANTS.NO)
            }
            if (isAddStripePaymentMode === true) {
                fd.append('addStripePaymentMode', APP_CONSTANTS.YES)
            } else {
                fd.append('addStripePaymentMode', APP_CONSTANTS.NO)
            }
            if (!isEmpty(stripeAccountId)) {
                fd.append('stripeAccountId', stripeAccountId)
            }
            dispatch(addRestaurantRequest(fd))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="mt-2">
                    <ScreenLabel label='Add Restaurant' />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Enter details to add new restaurant' />
                    <FormFieldLabel className='up-bold-label margin-bottom-10' label='Restaurant Information' />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Restaurant Name"
                        value={name}
                        onChange={handleNameChange}
                        isRequired={true} />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Brand Name"
                        value={brandName}
                        onChange={handleBrandNameChange}
                        isRequired={true}
                    />
                    <FormFieldLabel className='up-bold-label' label='Payment Information' />
                    {CurrencyLabel && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border'
                        value={selectedCurrency}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setSelectedCurrency(e)
                        }} />}
                    <FormFieldLabel className='mt-2' label='Select accepted order payment mode(s)' />
                    <div className="input-full mt-2">
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isAddOfflinePaymentMode}
                            value={isAddOfflinePaymentMode}
                            onChange={() => {
                                setAddOfflinePaymentMode(!isAddOfflinePaymentMode)
                            }}
                            required />
                        <label for="name" className="checkbox-label arest-cb-label">Offline Payments (Pay on desk)</label>
                    </div>
                    <div className="input-full mt-2">
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isAddPayAtEndPaymentMode}
                            value={isAddPayAtEndPaymentMode}
                            onChange={() => {
                                setAddPayAtEndPaymentMode(!isAddPayAtEndPaymentMode)
                            }}
                            required />
                        <label for="name" className="checkbox-label arest-cb-label">Pay At End</label>
                    </div>
                    <div className="input-full mt-2">
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isAddStripePaymentMode}
                            value={isAddStripePaymentMode}
                            onChange={() => {
                                setAddStripePaymentMode(!isAddStripePaymentMode)
                            }}
                            required />
                        <label for="name" className="checkbox-label arest-cb-label">Online Payments (Stripe)</label>
                    </div>
                    {isAddStripePaymentMode === true ? (<div className="mt-2">
                        <CommonInputFull
                            type="text"
                            id="name"
                            placeholder="Stripe Account Id"
                            value={stripeAccountId}
                            onChange={e => setStripeAccountId(e.target.value)}
                        />
                    </div>) : null}
                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    {countryListSuccess && <Select
                        className='arest-select'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e);
                            setCountryCode(e.iso2)
                            fetchStateList(e.iso2)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="top"
                        className='arest-select mt-2'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="top"
                        className='arest-select mt-2'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                    <FormFieldLabel className='up-bold-label' label='Restaurant Image' />
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <ButtonRow>
                        <CommonButton
                            label='Submit'
                            onClick={() => submitHandler()} />

                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddRestaurantScreen