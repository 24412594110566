export const DeliveryTerminalActionTypes = {
    ADD_TERMINAL_REQUEST: 'ADD_TERMINAL_REQUEST',
    ADD_TERMINAL_REQUEST_SUCCESS: 'ADD_TERMINAL_REQUEST_SUCCESS',
    ADD_TERMINAL_REQUEST_ERROR: 'ADD_TERMINAL_REQUEST_ERROR',
    ADD_TERMINAL_REQUEST_STATUS: 'ADD_TERMINAL_REQUEST_STATUS',

    TERMINAL_LIST_REQUEST: 'TERMINAL_LIST_REQUEST',
    TERMINAL_LIST_REQUEST_SUCCESS: 'TERMINAL_LIST_REQUEST_SUCCESS',
    TERMINAL_LIST_REQUEST_ERROR: 'TERMINAL_LIST_REQUEST_ERROR',

    UPDATE_TERMINAL_STATUS_REQUEST: 'UPDATE_TERMINAL_STATUS_REQUEST',
    UPDATE_TERMINAL_STATUS_REQUEST_SUCCESS: 'UPDATE_TERMINAL_STATUS_REQUEST_SUCCESS',
    UPDATE_TERMINAL_STATUS_REQUEST_ERROR: 'UPDATE_TERMINAL_STATUS_REQUEST_ERROR',
    UPDATE_TERMINAL_STATUS_REQUEST_STATUS: 'UPDATE_TERMINAL_STATUS_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',

}
