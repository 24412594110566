import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addEmployeeRequest, addEmployeeRequestStatus } from '../../../redux/employee/employee.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, screenCaptureAnalytics } from '../../../utils/Utils'
import { restaurantListRequest, branchListRequest } from '../../../redux/user/user.action'
import Select from 'react-select'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import CommonButton from '../../../components/button/common-button/commonButton'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../components/common-form/commonForm";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/common-input-half/commonInputHalf";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import { EMPLOYEE_TYPE_LIST } from "../../../utils/ReservationType";

const AddEmployeeScreen = props => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState()
    const [email, setEmail] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { addEmployeeSuccess, addEmployeeError, addEmployeeStatus } = useSelector(state => state.employee)
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [isLocationPermission, setIsLocationPermission] = useState(false)
    const [viewLocationList, setViewLocationList] = useState(false)
    const [editLocationDetail, setEditLocationDetail] = useState(false)
    const [addNewLocation, setAddNewLocation] = useState(false)
    const [isQrCodePermission, setQrCodePermission] = useState(false)
    const [isProductPermission, setIsProductPermission] = useState(false)
    const [viewProductListPer, setViewProductListPer] = useState(false)
    const [editProductPer, setEditProductPer] = useState(false)
    const [addProductPer, setAddProductPer] = useState(false)
    const [isOrderPermission, setIsOrderPermission] = useState(false)
    const [viewOrderPer, setViewOrderPer] = useState(false)
    const [updateOrderPer, setUpdateOrderPer] = useState(false)
    const [placeOrderPer, setPlaceOrderPer] = useState(false)
    const [isEmployeePermission, setIsEmployeePermission] = useState(false)
    const [viewEmployeePer, setViewEmployeePer] = useState(false)
    const [addEmployeePer, setAddEmployeePer] = useState(false)
    const [removeEmployeePer, setRemoveEmployeePer] = useState(false)
    const [isReservationPermission, setIsReservationPermission] = useState(false)
    const [reservationListPer, setReservationListPer] = useState(false)
    const [updateReservationPer, setUpdateReservationPer] = useState(false)
    const [addReservationPer, setAddReservationPer] = useState(false)
    const [isDashboardPermission, setIsDashboardPermission] = useState(false)
    const [isRequestTicketPermission, setIsRequestTicketPermission] = useState(false)
    const [viewRequestTicketPer, setViewRequestTicketPer] = useState(false)
    const [addRequestTicketPer, setAddRequestTicketPer] = useState(false)
    const [updateRequestTicketPer, setUpdateRequestTicketPer] = useState(false)
    const [employeeRole, setEmployeeRole] = useState('')
    const [selectedEmployeeType, setSelectedEmployeeType] = useState()

    useEffect(() => {
        const data = {
            screen: 'ADD_EMPLOYEE',
            screenClass: 'ADD_EMPLOYEE'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            setLoading(true)
            const data1 = {
                userId: user.data.userDetails.userId
            }
            dispatch(restaurantListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (addEmployeeStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addEmployeeSuccess.message)
            clearFields()
        } else if (addEmployeeStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addEmployeeError.message)
        }

        if (addEmployeeStatus.length) {
            dispatch(addEmployeeRequestStatus(''))
        }
    }, [addEmployeeSuccess, addEmployeeError, addEmployeeStatus])

    const clearFields = () => {
        setFirstName('')
        setLastName('')
        setIsLocationPermission(false)
        setQrCodePermission(false)
        setIsProductPermission(false)
        setIsOrderPermission(false)
        setIsEmployeePermission(false)
        setIsReservationPermission(false)
    }

    const submitHandler = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email id cannot be blank')
        } else if (isEmpty(mobile)) {
            displayErrorToast('Mobile field cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else if (isEmpty(selectedEmployeeType)) {
            displayErrorToast('Employee type not selected')
        } else if (isLocationPermission === false && isQrCodePermission === false &&
            isProductPermission === false && isOrderPermission === false && isEmployeePermission === false &&
            isReservationPermission === false && isDashboardPermission === false &&
            isRequestTicketPermission === false) {
            displayErrorToast('No permission selected. Please select atleast 1 permission')
        } else {
            setLoading(true)
            const mobileNum = mobile.replace('+', "")
            const data = {
                firstName: firstName,
                lastName: lastName,
                mobile: Number(mobileNum),
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                locationId: isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation,
                productCode: user.data.userDetails.productCode,
                email: email
            }
            if (!isEmpty(employeeRole)) {
                data.role = employeeRole
            }
            data.employeeType = selectedEmployeeType.key
            const empPermissions = []
            if (isLocationPermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.LOCATION,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                if (viewLocationList === true) {
                    obj.locationList = true
                } else {
                    obj.locationList = false
                }
                if (editLocationDetail === true) {
                    obj.editLocation = true
                } else {
                    obj.editLocation = false
                }
                if (addNewLocation === true) {
                    obj.addLocation = true
                } else {
                    obj.addLocation = false
                }
                empPermissions.push(obj)
            }
            if (isQrCodePermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.QRCODE,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                empPermissions.push(obj)
            }
            if (isProductPermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.PRODUCT,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                if (viewProductListPer === true) {
                    obj.productList = true
                } else {
                    obj.productList = false
                }
                if (editProductPer === true) {
                    obj.editProduct = true
                } else {
                    obj.editProduct = false
                }
                if (addProductPer === true) {
                    obj.addProduct = true
                } else {
                    obj.addProduct = false
                }
                empPermissions.push(obj)
            }
            if (isOrderPermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.ORDER,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                if (viewOrderPer === true) {
                    obj.orderList = true
                } else {
                    obj.orderList = false
                }
                if (updateOrderPer === true) {
                    obj.updateOrder = true
                } else {
                    obj.updateOrder = false
                }
                if (placeOrderPer === true) {
                    obj.placeOrder = true
                } else {
                    obj.placeOrder = false
                }
                empPermissions.push(obj)
            }
            if (isEmployeePermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.EMPLOYEE,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                if (viewEmployeePer === true) {
                    obj.employeeList = true
                } else {
                    obj.employeeList = false
                }
                if (addEmployeePer === true) {
                    obj.addEmployee = true
                } else {
                    obj.addEmployee = false
                }
                if (removeEmployeePer === true) {
                    obj.removeEmployee = true
                } else {
                    obj.removeEmployee = false
                }
                empPermissions.push(obj)
            }
            if (isReservationPermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.RESERVATION,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                if (reservationListPer === true) {
                    obj.reservationList = true
                } else {
                    obj.reservationList = false
                }
                if (updateReservationPer === true) {
                    obj.updateReservation = true
                } else {
                    obj.updateReservation = false
                }
                if (addReservationPer === true) {
                    obj.addReservation = true
                } else {
                    obj.addReservation = false
                }
                empPermissions.push(obj)
            }
            if (isDashboardPermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.DASHBOARD,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                empPermissions.push(obj)
            }
            if (isRequestTicketPermission === true) {
                let obj = {
                    type: APP_CONSTANTS.MENU_ITEM_TYPE.REQUEST_TICKET,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                if (viewRequestTicketPer === true) {
                    obj.ticketList = true
                } else {
                    obj.ticketList = false
                }
                if (updateRequestTicketPer === true) {
                    obj.updateRequestTicket = true
                } else {
                    obj.updateRequestTicket = false
                }
                if (addRequestTicketPer === true) {
                    obj.raiseRequestTicket = true
                } else {
                    obj.raiseRequestTicket = false
                }
                empPermissions.push(obj)
            }
            data.permissions = empPermissions
            dispatch(addEmployeeRequest(data))
        }
    }

    useEffect(() => {
        if (isLocationPermission === true) {
            setViewLocationList(true)
            setEditLocationDetail(true)
            setAddNewLocation(true)
        } else {
            setViewLocationList(false)
            setEditLocationDetail(false)
            setAddNewLocation(false)
        }
    }, [isLocationPermission])

    useEffect(() => {
        if (isProductPermission === true) {
            setViewProductListPer(true)
            setEditProductPer(true)
            setAddProductPer(true)
        } else {
            setViewProductListPer(false)
            setEditProductPer(false)
            setAddProductPer(false)
        }
    }, [isProductPermission])

    useEffect(() => {
        if (isOrderPermission === true) {
            setViewOrderPer(true)
            setUpdateOrderPer(true)
            setPlaceOrderPer(true)
        } else {
            setViewOrderPer(false)
            setUpdateOrderPer(false)
            setPlaceOrderPer(false)
        }
    }, [isOrderPermission])

    useEffect(() => {
        if (isEmployeePermission === true) {
            setViewEmployeePer(true)
            setAddEmployeePer(true)
            setRemoveEmployeePer(true)
        } else {
            setViewEmployeePer(false)
            setAddEmployeePer(false)
            setRemoveEmployeePer(false)
        }
    }, [isEmployeePermission])

    useEffect(() => {
        if (isReservationPermission === true) {
            setReservationListPer(true)
            setUpdateReservationPer(true)
            setAddReservationPer(true)
        } else {
            setReservationListPer(false)
            setUpdateReservationPer(false)
            setAddReservationPer(false)
        }
    }, [isReservationPermission])

    useEffect(() => {
        if (viewLocationList === false) {
            setEditLocationDetail(false)
        }
    }, [viewLocationList])

    useEffect(() => {
        if (editLocationDetail === false) {
            setViewLocationList(false)
        } else {
            setViewLocationList(true)
        }
    }, [editLocationDetail])

    useEffect(() => {
        if (viewProductListPer === false) {
            setEditProductPer(false)
        }
    }, [viewProductListPer])

    useEffect(() => {
        if (editProductPer === false) {
            setViewProductListPer(false)
        } else {
            setViewProductListPer(true)
        }
    }, [editProductPer])

    useEffect(() => {
        if (viewOrderPer === false) {
            setUpdateOrderPer(false)
        }
    }, [viewOrderPer])

    useEffect(() => {
        if (updateOrderPer === false) {
            setViewOrderPer(false)
        } else {
            setViewOrderPer(true)
        }
    }, [updateOrderPer])

    useEffect(() => {
        if (viewEmployeePer === false) {
            setRemoveEmployeePer(false)
        }
    }, [viewEmployeePer])

    useEffect(() => {
        if (removeEmployeePer === false) {
            setViewEmployeePer(false)
        } else {
            setViewEmployeePer(true)
        }
    }, [removeEmployeePer])

    useEffect(() => {
        if (reservationListPer === false) {
            setUpdateReservationPer(false)
        }
    }, [reservationListPer])

    useEffect(() => {
        if (updateReservationPer === false) {
            setReservationListPer(false)
        } else {
            setReservationListPer(true)
        }
    }, [updateReservationPer])

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Add Employee' />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Enter details to add new employee' />
                    <CommonInputRow>
                        <CommonInputHalf
                            type="name"
                            id="name"
                            placeholder="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            isRequired={true}
                        />
                        <CommonInputHalf
                            type="name"
                            id="name"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            isRequired={true}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputHalf
                            type="name"
                            id="role"
                            placeholder="Designation"
                            value={employeeRole}
                            onChange={e => setEmployeeRole(e.target.value)}
                        />
                        <CommonInputHalf
                            type="name"
                            id="name"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            isRequired={true}
                        />
                    </CommonInputRow>

                    <div className="margin-bottom-10">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    {isLoggedInUserBusiness(user) === true && <div className="aemp-select-heading">Select Restaurant</div>}
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="aemp-text">No restaurant found</div>}
                    {isLoggedInUserBusiness(user) === true && <div className="aemp-select-heading mt-2">Select Location</div>}
                    {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="aemp-text">No location found</div>}
                    {isLoggedInUserBusiness(user) === true && <div className="aemp-select-heading mt-2">Select Employee Type</div>}
                    {EMPLOYEE_TYPE_LIST && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedEmployeeType}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={EMPLOYEE_TYPE_LIST}
                        placeholder='Select Employee Type'
                        onChange={e => setSelectedEmployeeType(e)} />}
                    <div className="aemp-select-heading mt-2">Select Employee Permissions</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isDashboardPermission}
                            value={isDashboardPermission}
                            onChange={() => {
                                setIsDashboardPermission(!isDashboardPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Dashboard</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isLocationPermission}
                            value={isLocationPermission}
                            onChange={() => {
                                setIsLocationPermission(!isLocationPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Locations</label>
                    </div>
                    {isLocationPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={viewLocationList}
                                    value={viewLocationList}
                                    onChange={() => {
                                        setViewLocationList(!viewLocationList)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Location List</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={editLocationDetail}
                                    value={editLocationDetail}
                                    onChange={() => {
                                        setEditLocationDetail(!editLocationDetail)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Edit Details</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addNewLocation}
                                    value={addNewLocation}
                                    onChange={() => {
                                        setAddNewLocation(!addNewLocation)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add New</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isQrCodePermission}
                            value={isQrCodePermission}
                            onChange={() => {
                                setQrCodePermission(!isQrCodePermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Generate Qr Code</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isProductPermission}
                            value={isProductPermission}
                            onChange={() => {
                                setIsProductPermission(!isProductPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Products</label>
                    </div>
                    {isProductPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={viewProductListPer}
                                    value={viewProductListPer}
                                    onChange={() => {
                                        setViewProductListPer(!viewProductListPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Product List</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={editProductPer}
                                    value={editProductPer}
                                    onChange={() => {
                                        setEditProductPer(!editProductPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Edit Details</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addProductPer}
                                    value={addProductPer}
                                    onChange={() => {
                                        setAddProductPer(!addProductPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add New</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isOrderPermission}
                            value={isOrderPermission}
                            onChange={() => {
                                setIsOrderPermission(!isOrderPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Orders</label>
                    </div>
                    {isOrderPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={viewOrderPer}
                                    value={viewOrderPer}
                                    onChange={() => {
                                        setViewOrderPer(!viewOrderPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Order List</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={updateOrderPer}
                                    value={updateOrderPer}
                                    onChange={() => {
                                        setUpdateOrderPer(!updateOrderPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Update Order</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={placeOrderPer}
                                    value={placeOrderPer}
                                    onChange={() => {
                                        setPlaceOrderPer(!placeOrderPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Place Order</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isEmployeePermission}
                            value={isEmployeePermission}
                            onChange={() => {
                                setIsEmployeePermission(!isEmployeePermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Employees</label>
                    </div>
                    {isEmployeePermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={viewEmployeePer}
                                    value={viewEmployeePer}
                                    onChange={() => {
                                        setViewEmployeePer(!viewEmployeePer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Employee List</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addEmployeePer}
                                    value={addEmployeePer}
                                    onChange={() => {
                                        setAddEmployeePer(!addEmployeePer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add Employee</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={removeEmployeePer}
                                    value={removeEmployeePer}
                                    onChange={() => {
                                        setRemoveEmployeePer(!removeEmployeePer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Remove Employee</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isReservationPermission}
                            value={isReservationPermission}
                            onChange={() => {
                                setIsReservationPermission(!isReservationPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Reservations</label>
                    </div>
                    {isReservationPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={reservationListPer}
                                    value={reservationListPer}
                                    onChange={() => {
                                        setReservationListPer(!reservationListPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Reservation List</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={updateReservationPer}
                                    value={updateReservationPer}
                                    onChange={() => {
                                        setUpdateReservationPer(!updateReservationPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Update Reservation</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addReservationPer}
                                    value={addReservationPer}
                                    onChange={() => {
                                        setAddReservationPer(!addReservationPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add Reservation</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isRequestTicketPermission}
                            value={isRequestTicketPermission}
                            onChange={() => {
                                setIsRequestTicketPermission(!isRequestTicketPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Request Ticket</label>
                    </div>
                    {isRequestTicketPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={viewRequestTicketPer}
                                    value={viewRequestTicketPer}
                                    onChange={() => {
                                        setViewRequestTicketPer(!viewRequestTicketPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Ticket List</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={updateRequestTicketPer}
                                    value={updateRequestTicketPer}
                                    onChange={() => {
                                        setUpdateRequestTicketPer(!updateRequestTicketPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Update Ticket</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addRequestTicketPer}
                                    value={addRequestTicketPer}
                                    onChange={() => {
                                        setAddRequestTicketPer(!addRequestTicketPer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Raise Ticket</label>
                            </div>
                        </div>
                    </div>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => submitHandler()} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddEmployeeScreen