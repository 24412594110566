import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addCategory, categoryList, updateCategory
} from '../../api-requests/inventory/category';
import {
    addInvCategoryRequestError, addInvCategoryRequestSuccess, invCategoryListRequestError,
    invCategoryListRequestSuccess, updateInvCategoryRequestError, updateInvCategoryRequestSuccess
} from './category.action';
import { CategoryActionTypes } from './category.types'
import { displayConsoleLog, isNull } from '../../../utils/Utils'
import { decryptResponseData } from '../../../utils/DataDecryptionUtils';

function* handleUpdateInvCategory({ payload }) {
    try {
        const response = yield call(updateCategory, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateInvCategoryRequestSuccess(decryptedData))
            } else {
                yield put(updateInvCategoryRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(updateInvCategoryRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(updateInvCategoryRequestError(error.response.data))
        }
    }
}

export function* updateInvCategoryReq() {
    yield takeLatest(CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST, handleUpdateInvCategory)
}

function* handleInvCategoryList({ payload }) {
    try {
        const response = yield call(categoryList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invCategoryListRequestSuccess(decryptedData))
            } else {
                yield put(invCategoryListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(invCategoryListRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(invCategoryListRequestError(error.response.data))
        }
    }
}

export function* invCategoryListReq() {
    yield takeLatest(CategoryActionTypes.INV_CATEGORY_REQUEST_LIST, handleInvCategoryList)
}

function* handleAddInvCategory({ payload }) {
    try {
        const response = yield call(addCategory, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addInvCategoryRequestSuccess(decryptedData))
            } else {
                yield put(addInvCategoryRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(addInvCategoryRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(addInvCategoryRequestError(error.response.data))
        }
    }
}

export function* addInvCategoryReq() {
    yield takeLatest(CategoryActionTypes.INV_ADD_CATEGORY_REQUEST, handleAddInvCategory)
}