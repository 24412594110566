import React from "react";
import './styles.css'

const FeatureContainer = ({ image, label, description }) => {
    return (<div className="col-sm-3 feature-item-container feature-item-content-main">
        <div className="feature-more-img">
            <img src={image} className="img" alt="img" />
        </div>
        <h2 className="feature-item-label">{label}</h2>
        <div className="feature-item-content-description">{description}</div>
    </div>)
}

export default FeatureContainer