import React, { createContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { config } from './config';
import { store } from './redux/store'
import {
    newOrderPlacedNotif, answerUserCall, newUserCalling,
    callMadeAct, answerMadeAct, userCalledAct,
    callAcceptedAct, callEndedAct, collectOrderPaymentNotif,
    customerPayAtEndPaymentSuccessNotif,
    orderStatusUpdateNotif, callWaiterRequest
} from './redux/socket/socket.action'

export const socket = io(config.socketUrl)
export const SocketContext = createContext();

socket.on('connect', () => console.log('connected to socket'));
socket.on("new-order-placed", (data) => {
    store.dispatch(newOrderPlacedNotif(data))
})

socket.on("call-waiter-request", (data) => {
    store.dispatch(callWaiterRequest(data))
})

socket.on("order-status-update", (data) => {
    store.dispatch(orderStatusUpdateNotif(data))
})

socket.on("collect-order-payment-desk", (data) => {
    store.dispatch(collectOrderPaymentNotif(data))
})

socket.on("customer-pay-at-end-payment-success", (data) => {
    store.dispatch(customerPayAtEndPaymentSuccessNotif(data))
})

socket.on("call-made", (data) => {
    store.dispatch(callMadeAct(data))
})
socket.on("answer-made", (data) => {
    store.dispatch(answerMadeAct(data))
})

socket.on("userCalled", (data) => {
    store.dispatch(userCalledAct(data))
})

socket.on("callAccepted", (data) => {
    store.dispatch(callAcceptedAct(data))
})

socket.on("callEnded", (data) => {
    store.dispatch(callEndedAct(data))
})

export default SocketContext