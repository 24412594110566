import { RequestTicketActionTypes } from './request-ticket.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    raiseTicketSuccess: null,
    raiseTicketError: null,
    raiseTicketReqStatus: '',
    requestTicketListSuccess: null,
    requestTicketListError: null,
    requestTicketDetailSuccess: null,
    requestTicketDetailError: null,
    requestTicketUpdateDetailSuccess: null,
    requestTicketUpdateDetailError: null,
    requestTicketUpdateDetailReqStatus: ''
}

const requestTicketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST_SUCCESS:
            return {
                ...state,
                raiseTicketSuccess: action.payload,
                raiseTicketError: null,
                raiseTicketReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST_ERROR:
            return {
                ...state,
                raiseTicketSuccess: null,
                raiseTicketError: action.payload,
                raiseTicketReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST_STATUS:
            return {
                ...state,
                raiseTicketReqStatus: ''
            }
        case RequestTicketActionTypes.REQUEST_TICKET_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                requestTicketListSuccess: action.payload,
                requestTicketListError: null
            }
        case RequestTicketActionTypes.REQUEST_TICKET_LIST_REQUEST_ERROR:
            return {
                ...state,
                requestTicketListSuccess: null,
                requestTicketListError: action.payload
            }
        case RequestTicketActionTypes.REQUEST_TICKET_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                requestTicketDetailSuccess: action.payload,
                requestTicketDetailError: null
            }
        case RequestTicketActionTypes.REQUEST_TICKET_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                requestTicketDetailSuccess: null,
                requestTicketDetailError: action.payload
            }
        case RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                requestTicketUpdateDetailSuccess: action.payload,
                requestTicketUpdateDetailError: null,
                requestTicketUpdateDetailReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                requestTicketUpdateDetailSuccess: null,
                requestTicketUpdateDetailError: action.payload,
                requestTicketUpdateDetailReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST_STATUS:
            return {
                ...state,
                requestTicketUpdateDetailReqStatus: ''
            }
        case RequestTicketActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default requestTicketReducer