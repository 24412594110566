import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addCategory, addSubCategory, categoryList, subCategoryList
} from '../api-requests/product';
import {
    addCategoryRequestError, addCategoryRequestSuccess, addSubCategoryRequestError,
    addSubCategoryRequestSuccess, categoryListRequestError, categoryListRequestSuccess,
    subCategoryListRequestError, subCategoryListRequestSuccess
} from './category.action';
import { CategoryActionTypes } from './category.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleSubCategoryList({ payload }) {
    try {
        const response = yield call(subCategoryList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(subCategoryListRequestSuccess(decryptedData))
            } else {
                yield put(subCategoryListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(subCategoryListRequestError(error.response.data))
    }
}

export function* subCategoryListReq() {
    yield takeLatest(CategoryActionTypes.SUB_CATEGORY_LIST, handleSubCategoryList)
}

function* handleCategoryList({ payload }) {
    try {
        const response = yield call(categoryList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(categoryListRequestSuccess(decryptedData))
            } else {
                yield put(categoryListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        yield put(categoryListRequestError(error.response.data))
    }
}

export function* categoryListReq() {
    yield takeLatest(CategoryActionTypes.CATEGORY_REQUEST_LIST, handleCategoryList)
}

function* handleAddSubCategory({ payload }) {
    try {
        const response = yield call(addSubCategory, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addSubCategoryRequestSuccess(decryptedData))
            } else {
                yield put(addSubCategoryRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addSubCategoryRequestError(error.response.data))
    }
}

export function* addSubCategoryReq() {
    yield takeLatest(CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST, handleAddSubCategory)
}

function* handleAddCategory({ payload }) {
    try {
        const response = yield call(addCategory, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addCategoryRequestSuccess(decryptedData))
            } else {
                yield put(addCategoryRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addCategoryRequestError(error.response.data))
    }
}

export function* addCategoryReq() {
    yield takeLatest(CategoryActionTypes.ADD_CATEGORY_REQUEST, handleAddCategory)
}