export const CategoryActionTypes = {
    ADD_CATEGORY_REQUEST: 'ADD_CATEGORY_REQUEST',
    ADD_CATEGORY_REQUEST_SUCCESS: 'ADD_CATEGORY_REQUEST_SUCCESS',
    ADD_CATEGORY_REQUEST_ERROR: 'ADD_CATEGORY_REQUEST_ERROR',
    ADD_CATEGORY_REQUEST_STATUS: 'ADD_CATEGORY_REQUEST_STATUS',

    CATEGORY_REQUEST_LIST: 'CATEGORY_REQUEST_LIST',
    CATEGORY_REQUEST_LIST_SUCCESS: 'CATEGORY_REQUEST_LIST_SUCCESS',
    CATEGORY_REQUEST_LIST_ERROR: 'CATEGORY_REQUEST_LIST_ERROR',

    ADD_SUB_CATEGORY_REQUEST: 'ADD_SUB_CATEGORY_REQUEST',
    ADD_SUB_CATEGORY_REQUEST_SUCCESS: 'ADD_SUB_CATEGORY_REQUEST_SUCCESS',
    ADD_SUB_CATEGORY_REQUEST_ERROR: 'ADD_SUB_CATEGORY_REQUEST_ERROR',
    ADD_SUB_CATEGORY_REQUEST_STATUS: 'ADD_SUB_CATEGORY_REQUEST_STATUS',

    SUB_CATEGORY_LIST: 'SUB_CATEGORY_LIST',
    SUB_CATEGORY_LIST_SUCCESS: 'SUB_CATEGORY_LIST_SUCCESS',
    SUB_CATEGORY_LIST_ERROR: 'SUB_CATEGORY_LIST_ERROR',

    LOGOUT: 'LOGOUT',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE'
}