import { toast } from 'react-toastify';
import { APP_CONSTANTS } from './AppConstants';
import { logEvent } from "firebase/analytics";
import { analytics } from '../fcmAnalytics';
import { PRODUCT_MEASUREMENT_UNIT_LIST, PRODUCT_MEASUREMENT_UNIT_LIST_SYMBOL } from './ReservationType';

export const displayErrorToast = message => {
    toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 123
    });
}

export const displaySuccessToast = message => {
    toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 123
    });
}

export const displayConsoleLog = (tag, message = '') => {
    if (process.env.NODE_ENV === 'development') {
        console.log(tag, message)
    }
}

let trim = (x) => {
    let value = String(x);
    return value.replace(/^\s+|\s+$/gm, "");
};

export const isEmpty = (value) => {
    if (
        value === null ||
        value === undefined ||
        trim(value) === "" ||
        value.length === 0
    ) {
        return true;
    } else {
        return false;
    }
};

export const isNull = (value) => {
    if (value === null ||
        value === undefined ||
        value.length === 0) {
        return true
    } else {
        return false
    }
}

export const isValidEmail = (email) => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(emailRegex)) {
        return true;
    } else {
        return false;
    }
};

export const addItemToCart = (item, updateQty = false, reduceQty = false) => {
    let cartItem = localStorage.getItem(APP_CONSTANTS.CART_ITEM)
    if (isNull(cartItem) || cartItem === 'null') {
        let cartArr = []
        cartArr.push(item)
        localStorage.setItem(APP_CONSTANTS.CART_ITEM, JSON.stringify(cartArr))
    } else {
        let cartArr = JSON.parse(cartItem)
        let newArr = cartArr.map(function (element) {
            if (element.itemId === item.itemId) {
                let qty = element.quantity
                if (reduceQty === true) {
                    qty = qty - 1
                } else {
                    qty = qty + 1
                }
                const amt = element.finalPrice * qty
                return {
                    ...element, quantity: qty, finalPrice: amt
                }
            } else return element
        })

        newArr = newArr.filter(element => element.quantity !== 0)
        localStorage.setItem(APP_CONSTANTS.CART_ITEM, JSON.stringify(newArr))
    }
    return true
}

export const getCartItem = () => {
    let cartItem = localStorage.getItem(APP_CONSTANTS.CART_ITEM)
    if (isNull(cartItem)) {
        return null
    } else {
        return JSON.parse(cartItem)
    }
}

export const displayCurrencySymbolLeft = (region) => {
    if (region === 'Europe') {
        return false
    } else {
        return true
    }
}

export const convertToPascal = (text) => {
    return text.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })
}

export const removeSpecialCharAndChangeToPascal = (text) => {
    let text1 = text.replace(/[^a-zA-Z ]/g, " ")
    return text1.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })
}

export const screenCaptureAnalytics = data => {
    logEvent(analytics, 'screen_view', {
        firebase_screen: data.screen,
        firebase_screen_class: data.screenClass
    })
}

export const isLoggedInUserEmployee = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserBusiness = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserSupport = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.SUPPORT) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserKitchen = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.KITCHEN) {
        return true
    } else {
        return false
    }
}
export const isLoggedInUserOrderBoard = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ORDER_BOARD) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserOrderPaymentBoard = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ORDER_PAYMENT_BOARD) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserAdmin = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ADMIN) {
        return true
    } else {
        return false
    }
}
export const isLoggedInUserOperator = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.OPERATOR) {
        return true
    } else {
        return false
    }
}

export const removeCustomerPayAtEndOrderInfo = () => {
    localStorage.removeItem(APP_CONSTANTS.SUCCESS_ORDER_ID)
    localStorage.removeItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID)
    localStorage.removeItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID)
}

export const getProductMeasurmentUnit = (qty = 0, unit) => {
    if (isEmpty(unit)) {
        return ''
    }
    const unitObj = PRODUCT_MEASUREMENT_UNIT_LIST_SYMBOL.find(e => e.key === unit)
    if (!isNull(unitObj)) {
        if (qty > 1) {
            return `/${qty} ${unitObj.value}`
        } else {
            return `/${unitObj.value}`
        }
        // return `/ ${qty} ${unitObj.value}`
    } else return ''
}

export const getProductMeasurmentUnitOrder = (qty = 0, unit) => {
    if (isEmpty(unit)) {
        return ''
    }
    const unitObj = PRODUCT_MEASUREMENT_UNIT_LIST_SYMBOL.find(e => e.key === unit)
    if (!isNull(unitObj)) {
        return `(${qty} ${unitObj.value})`
    } else return ''
}

function convertToIntegerIfDecimal(number) {
    // Check if the number is a float with two decimal places
    if (`${number}`.includes('.00')) {
        return parseInt(number); // Convert to integer if it has two decimal places
    } else {
        return number; // Return the number as is if it's not .00
    }
}

export const priceWithSymbol = (element, price) => {
    if (!isEmpty(price)) {
        if (!`${price}`.includes('.')) {
            price = price.toFixed(2)
        }
        const priceValue = convertToIntegerIfDecimal(price).toLocaleString('en-US')
        if (!isEmpty(element.currencyRegion)) {
            if (displayCurrencySymbolLeft(element.currencyRegion) === false) {
                return `${priceValue} ${element.currencySymbol}`
            } else {
                return `${element.currencySymbol} ${priceValue}`
            }
        } else {
            return `${priceValue}`
        }
    } else {
        return ''
    }
}

export const calculateDiscountedPrice = (price, discount) => {
    const discountAmt = price * discount / 100
    const finalPrice = price - discountAmt
    return finalPrice.toFixed(2)
}

export const isImageAvailable = (imageName) => {
    if (imageName.includes('no-image')) {
        return false
    } else return true
}

export const isEmployeeTypeWaiter = (businessId, locationId, userObj) => {
    let employeeType = userObj.data.userDetails?.employeeDetails?.employeeType?.find(e => e.businessId === businessId && e.locationId === locationId)
    if (!isNull(employeeType) && employeeType.type === APP_CONSTANTS.EMPLOYEE_TYPE.WAITER) {
        return true
    } else {
        return false
    }
}