import React, { useEffect, useState } from "react";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { useLocation, useNavigate } from 'react-router-dom';
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import {
    branchDetailRequest, restaurantAccessRegisterRequestStatus, restaurantAccessRegisterRequest,
    changePasswordRequest, changePasswordRequestStatus
} from '../../../../redux/user/user.action'
import { convertToPascal, displayErrorToast, displaySuccessToast, isNull, isValidEmail, screenCaptureAnalytics } from "../../../../utils/Utils";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import CommonForm from "../../../../components/common-form/commonForm";
import { isEmpty } from "@firebase/util";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";

const LocationDetail = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, branchDetailSuccess, branchDetailError,
        restaurantRegisterRequestSuccess, restaurantRegisterRequestError, restaurantRegisterRequestReqStatus,
        changePasswordSuccess, changePasswordError, changePasswordStatus } = useSelector(state => state.user)
    const { state } = useLocation()
    const [kitchenEmail, setKitchenEmail] = useState('')
    const [kitchenPassword, setKitchenPassword] = useState('')
    const [orderBoardEmail, setOrderBoardEmail] = useState('')
    const [orderBoardPassword, setOrderBoardPassword] = useState('')
    const [paymentBoardEmail, setPaymentBoardEmail] = useState('')
    const [paymentBoardPassword, setPaymentBoardPassword] = useState('')
    const [displayKitchenCreateAccess, setDisplayKitchenCreateAccess] = useState(false)
    const [displayOrderBoardCreateAccess, setDisplayOrderBoardCreateAccess] = useState(false)
    const [displayPaymentBoardCreateAccess, setDisplayPaymentBoardCreateAccess] = useState(false)
    const [displayKitchenUpdatePassword, setDisplayKitchenUpdatePassword] = useState(false)
    const [kitchenNewPassword, setKitchenNewPassword] = useState('')
    const [kitchenNewConfirmPassword, setKitchenNewConfirmPassword] = useState('')
    const [displayOrderBoardUpdatePassword, setDisplayOrderBoardUpdatePassword] = useState(false)
    const [orderBoardNewPassword, setOrderBoardNewPassword] = useState('')
    const [orderBoardNewConfirmPassword, setOrderBoardNewConfirmPassword] = useState('')
    const [displayPaymentBoardUpdatePassword, setDisplayPaymentBoardUpdatePassword] = useState(false)
    const [paymentBoardNewPassword, setPaymentBoardNewPassword] = useState('')
    const [paymentBoardNewConfirmPassword, setPaymentBoardNewConfirmPassword] = useState('')

    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'LOCATION_DETAIL',
            screenClass: 'LOCATION_DETAIL'
        }
        screenCaptureAnalytics(data)
        fetchLocationDetail()
    }, [])

    const fetchLocationDetail = () => {
        setLoading(true)
        dispatch(branchDetailRequest(state))
    }

    useEffect(() => {
        if (!isNull(branchDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(branchDetailError)) {
            setLoading(false)
        }
    }, [branchDetailSuccess, branchDetailError])

    const handleEditDetailsClick = () => {
        navigate(ROUTES.editLocation, { state: branchDetailSuccess.data })
    }

    useEffect(() => {
        if (restaurantRegisterRequestReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            displaySuccessToast(restaurantRegisterRequestSuccess.message)
            fetchLocationDetail()
        } else if (restaurantRegisterRequestReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(restaurantRegisterRequestError.message)
        }
        if (restaurantRegisterRequestReqStatus.length) {
            dispatch(restaurantAccessRegisterRequestStatus(''))
        }
    }, [restaurantRegisterRequestSuccess, restaurantRegisterRequestError, restaurantRegisterRequestReqStatus])

    useEffect(() => {
        if (changePasswordStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(changePasswordSuccess.message)
            fetchLocationDetail()
        } else if (changePasswordStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(changePasswordError.message)
        }

        if (changePasswordStatus.length) {
            dispatch(changePasswordRequestStatus(''))
        }
    }, [changePasswordSuccess, changePasswordError, changePasswordStatus])

    const handleKitchenAccessSubmitClick = () => {
        if (isEmpty(kitchenEmail)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(kitchenEmail)) {
            displayErrorToast('Invalid email id. Please check')
        } else if (isEmpty(kitchenPassword)) {
            displayErrorToast('Password cannot be blank')
        } else {
            setDisplayKitchenCreateAccess(false)
            handleRestaurantAccessAPICall(kitchenEmail, kitchenPassword, APP_CONSTANTS.USER_TYPE.KITCHEN)
        }
    }

    const handleOrderBoardAccessSubmitClick = () => {
        if (isEmpty(orderBoardEmail)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(orderBoardEmail)) {
            displayErrorToast('Invalid email id. Please check')
        } else if (isEmpty(orderBoardPassword)) {
            displayErrorToast('Password cannot be blank')
        } else {
            setDisplayOrderBoardCreateAccess(false)
            handleRestaurantAccessAPICall(orderBoardEmail, orderBoardPassword, APP_CONSTANTS.USER_TYPE.ORDER_BOARD)
        }
    }

    const handlePaymentBoardAccessSubmitClick = () => {
        if (isEmpty(paymentBoardEmail)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(paymentBoardEmail)) {
            displayErrorToast('Invalid email id. Please check')
        } else if (isEmpty(paymentBoardPassword)) {
            displayErrorToast('Password cannot be blank')
        } else {
            setDisplayPaymentBoardCreateAccess(false)
            handleRestaurantAccessAPICall(paymentBoardEmail, paymentBoardPassword, APP_CONSTANTS.USER_TYPE.ORDER_PAYMENT_BOARD)
        }
    }

    const handleRestaurantAccessAPICall = (email, password, userType) => {
        setLoading(true)
        const data = {
            email: email,
            password: password,
            userType: userType,
            productCode: APP_CONSTANTS.PRODUCT_NAME,
            restaurantId: branchDetailSuccess.data.businessId,
            restaurantLocationId: branchDetailSuccess.data.locationId
        }
        dispatch(restaurantAccessRegisterRequest(data))
    }

    const handleKitchenUpdatePasswordSubmitClick = () => {
        if (isEmpty(kitchenNewPassword)) {
            displayErrorToast('New password cannot be blank')
        } else if (isEmpty(kitchenNewConfirmPassword)) {
            displayErrorToast('Confirm password cannot be blank')
        } else if (kitchenNewPassword !== kitchenNewConfirmPassword) {
            displayErrorToast('New password and confirm password does not match')
        } else {
            setDisplayKitchenUpdatePassword(false)
            processUpdatePassword(kitchenNewPassword, branchDetailSuccess.data.kitchenUser.email)
        }
    }

    const handleOrderBoardUpdatePasswordSubmitClick = () => {
        if (isEmpty(orderBoardNewPassword)) {
            displayErrorToast('New password cannot be blank')
        } else if (isEmpty(orderBoardNewConfirmPassword)) {
            displayErrorToast('Confirm password cannot be blank')
        } else if (orderBoardNewPassword !== orderBoardNewConfirmPassword) {
            displayErrorToast('New password and confirm password does not match')
        } else {
            setDisplayOrderBoardUpdatePassword(false)
            processUpdatePassword(orderBoardNewPassword, branchDetailSuccess.data.orderBoardUser.email)
        }
    }

    const handlePaymentBoardUpdatePasswordSubmitClick = () => {
        if (isEmpty(paymentBoardNewPassword)) {
            displayErrorToast('New password cannot be blank')
        } else if (isEmpty(paymentBoardNewConfirmPassword)) {
            displayErrorToast('Confirm password cannot be blank')
        } else if (paymentBoardNewPassword !== paymentBoardNewConfirmPassword) {
            displayErrorToast('New password and confirm password does not match')
        } else {
            setDisplayPaymentBoardUpdatePassword(false)
            processUpdatePassword(paymentBoardNewPassword, branchDetailSuccess.data.orderPaymentBoardUser.email)
        }
    }

    const processUpdatePassword = (password, email) => {
        setLoading(true)
        const data = {
            email: email,
            accessToken: APP_CONSTANTS.PRODUCT_NAME,
            password: password
        }
        dispatch(changePasswordRequest(data))
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Location Detail' />
                {!isNull(branchDetailSuccess) && !isNull(branchDetailSuccess.data) &&
                    <>
                        <CommonColorForm>
                            <FormFieldLabel className='up-bold-label' label='Location Name' />
                            <div className="ld-text">{branchDetailSuccess.data.name}</div>
                            <FormFieldLabel className='up-bold-label' label='Currency Information' />
                            <div className="ld-text">{branchDetailSuccess.data.currencyRegion ? branchDetailSuccess.data.currencyRegion : '-'} - {branchDetailSuccess.data.currencyLabel ? branchDetailSuccess.data.currencyLabel : '-'} ({branchDetailSuccess.data.currencySymbol ? branchDetailSuccess.data.currencySymbol : '-'})</div>
                            <FormFieldLabel className='ld-grey-text' label='Available Payment Modes' />
                            {branchDetailSuccess.data.orderPaymentModes && branchDetailSuccess.data.orderPaymentModes.map((e, index) => (<div>{index + 1}. {e.label ? e.label : convertToPascal(e.gatewayType)}</div>))}
                            <FormFieldLabel className='up-bold-label' label='Location Information' />
                            <div className="ld-text">{branchDetailSuccess.data.city ? branchDetailSuccess.data.city : '-'}, {branchDetailSuccess.data.state ? branchDetailSuccess.data.state : '-'}, {branchDetailSuccess.data.country ? branchDetailSuccess.data.country : '-'}</div>
                            <FormFieldLabel className='up-bold-label' label='Access Information' />
                            <FormFieldLabel className='ld-grey-text' label='Kitchen' />
                            {isNull(branchDetailSuccess.data.kitchenUser) ? (<div className="ld-button-container">
                                <div className="ld-text">No Access Available</div>
                                <div className="ld-create-access-button" onClick={() => {
                                    setDisplayKitchenCreateAccess(!displayKitchenCreateAccess)
                                    setDisplayOrderBoardCreateAccess(false)
                                    setDisplayPaymentBoardCreateAccess(false)
                                }}>{displayKitchenCreateAccess === true ? 'Cancel' : 'Create Access'}</div>
                            </div>) : null}
                            {displayKitchenCreateAccess === true ? (<div className="mt-2">
                                <CommonInputRow>
                                    <CommonInputHalf id='email'
                                        onChange={e => setKitchenEmail(e.target.value)}
                                        placeholder='Email'
                                        type='email'
                                        value={kitchenEmail}
                                    />
                                    <CommonInputHalf id='password'
                                        onChange={e => setKitchenPassword(e.target.value)}
                                        placeholder='Password'
                                        type='password'
                                        value={kitchenPassword}
                                    />
                                </CommonInputRow>
                                <div className="ld-submit-button-container">
                                    <CommonButton label='Submit'
                                        onClick={() => { handleKitchenAccessSubmitClick() }} />
                                </div>
                            </div>) : null}
                            {!isNull(branchDetailSuccess.data.kitchenUser) ? (<div className="ld-button-container">
                                <div className="ld-text">{branchDetailSuccess.data.kitchenUser ? branchDetailSuccess.data.kitchenUser.email : '-'}</div>
                                <div className="ld-create-access-button" onClick={() => {
                                    setDisplayKitchenCreateAccess(false)
                                    setDisplayKitchenUpdatePassword(!displayKitchenUpdatePassword)
                                    setDisplayOrderBoardCreateAccess(false)
                                    setDisplayOrderBoardUpdatePassword(false)
                                    setDisplayPaymentBoardCreateAccess(false)
                                    setDisplayPaymentBoardUpdatePassword(false)
                                }}>{displayKitchenUpdatePassword === true ? 'Cancel' : 'Update Password'}</div>
                            </div>) : null}
                            {displayKitchenUpdatePassword === true ? (<div className="mt-2">
                                <CommonInputRow>
                                    <CommonInputHalf id='password'
                                        onChange={e => setKitchenNewPassword(e.target.value)}
                                        placeholder='New Password'
                                        type='password'
                                        value={kitchenNewPassword}
                                    />
                                    <CommonInputHalf id='confirmPassword'
                                        onChange={e => setKitchenNewConfirmPassword(e.target.value)}
                                        placeholder='Confirm Password'
                                        type='text'
                                        value={kitchenNewConfirmPassword}
                                    />
                                </CommonInputRow>
                                <div className="ld-submit-button-container">
                                    <CommonButton label='Submit'
                                        onClick={() => { handleKitchenUpdatePasswordSubmitClick() }} />
                                </div>
                            </div>) : null}
                            <FormFieldLabel className='ld-grey-text' label='Order Board' />
                            {isNull(branchDetailSuccess.data.orderBoardUser) ? (<div className="ld-button-container">
                                <div className="ld-text">No Access Available</div>
                                <div className="ld-create-access-button" onClick={() => {
                                    setDisplayOrderBoardCreateAccess(!displayOrderBoardCreateAccess)
                                    setDisplayKitchenCreateAccess(false)
                                    setDisplayPaymentBoardCreateAccess(false)
                                }}>{displayOrderBoardCreateAccess === true ? 'Cancel' : 'Create Access'}</div>
                            </div>) : null}
                            {displayOrderBoardCreateAccess === true ? (<div className="mt-2">
                                <CommonInputRow>
                                    <CommonInputHalf id='email'
                                        onChange={e => setOrderBoardEmail(e.target.value)}
                                        placeholder='Email'
                                        type='email'
                                        value={orderBoardEmail}
                                    />
                                    <CommonInputHalf id='password'
                                        onChange={e => setOrderBoardPassword(e.target.value)}
                                        placeholder='Password'
                                        type='password'
                                        value={orderBoardPassword}
                                    />
                                </CommonInputRow>
                                <div className="ld-submit-button-container">
                                    <CommonButton label='Submit'
                                        onClick={() => { handleOrderBoardAccessSubmitClick() }} />
                                </div>
                            </div>) : null}
                            {!isNull(branchDetailSuccess.data.orderBoardUser) ? (<div className="ld-button-container">
                                <div className="ld-text">{branchDetailSuccess.data.orderBoardUser ? branchDetailSuccess.data.orderBoardUser.email : '-'}</div>
                                <div className="ld-create-access-button" onClick={() => {
                                    setDisplayKitchenUpdatePassword(false)
                                    setDisplayKitchenCreateAccess(false)
                                    setDisplayOrderBoardCreateAccess(false)
                                    setDisplayOrderBoardUpdatePassword(!displayOrderBoardUpdatePassword)
                                    setDisplayPaymentBoardCreateAccess(false)
                                    setDisplayPaymentBoardUpdatePassword(false)
                                }}>{displayOrderBoardUpdatePassword === true ? 'Cancel' : 'Update Password'}</div>
                            </div>) : null}
                            {displayOrderBoardUpdatePassword === true ? (<div className="mt-2">
                                <CommonInputRow>
                                    <CommonInputHalf id='password'
                                        onChange={e => setOrderBoardNewPassword(e.target.value)}
                                        placeholder='New Password'
                                        type='password'
                                        value={orderBoardNewPassword}
                                    />
                                    <CommonInputHalf id='confirmPassword'
                                        onChange={e => setOrderBoardNewConfirmPassword(e.target.value)}
                                        placeholder='Confirm Password'
                                        type='text'
                                        value={orderBoardNewConfirmPassword}
                                    />
                                </CommonInputRow>
                                <div className="ld-submit-button-container">
                                    <CommonButton label='Submit'
                                        onClick={() => { handleOrderBoardUpdatePasswordSubmitClick() }} />
                                </div>
                            </div>) : null}
                            <FormFieldLabel className='ld-grey-text' label='Order Payment Board' />
                            {isNull(branchDetailSuccess.data.orderPaymentBoardUser) ? (<div className="ld-button-container">
                                <div className="ld-text">No Access Available</div>
                                <div className="ld-create-access-button" onClick={() => {
                                    setDisplayPaymentBoardCreateAccess(!displayPaymentBoardCreateAccess)
                                    setDisplayKitchenCreateAccess(false)
                                    setDisplayOrderBoardCreateAccess(false)
                                }}>{displayPaymentBoardCreateAccess === true ? 'Cancel' : 'Create Access'}</div>
                            </div>) : null}
                            {displayPaymentBoardCreateAccess === true ? (<div className="mt-2">
                                <CommonInputRow>
                                    <CommonInputHalf id='email'
                                        onChange={e => setPaymentBoardEmail(e.target.value)}
                                        placeholder='Email'
                                        type='email'
                                        value={paymentBoardEmail}
                                    />
                                    <CommonInputHalf id='password'
                                        onChange={e => setPaymentBoardPassword(e.target.value)}
                                        placeholder='Password'
                                        type='password'
                                        value={paymentBoardPassword}
                                    />
                                </CommonInputRow>
                                <div className="ld-submit-button-container">
                                    <CommonButton label='Submit'
                                        onClick={() => { handlePaymentBoardAccessSubmitClick() }} />
                                </div>
                            </div>) : null}
                            {!isNull(branchDetailSuccess.data.orderPaymentBoardUser) ? (<div className="ld-button-container">
                                <div className="ld-text">{branchDetailSuccess.data.orderPaymentBoardUser ? branchDetailSuccess.data.orderPaymentBoardUser.email : '-'}</div>
                                <div className="ld-create-access-button" onClick={() => {
                                    setDisplayKitchenUpdatePassword(false)
                                    setDisplayKitchenCreateAccess(false)
                                    setDisplayOrderBoardCreateAccess(false)
                                    setDisplayOrderBoardUpdatePassword(false)
                                    setDisplayPaymentBoardCreateAccess(false)
                                    setDisplayPaymentBoardUpdatePassword(!displayPaymentBoardUpdatePassword)
                                }}>{displayPaymentBoardUpdatePassword === true ? 'Cancel' : 'Update Password'}</div>
                            </div>) : null}
                            {displayPaymentBoardUpdatePassword === true ? (<div className="mt-2">
                                <CommonInputRow>
                                    <CommonInputHalf id='password'
                                        onChange={e => setPaymentBoardNewPassword(e.target.value)}
                                        placeholder='New Password'
                                        type='password'
                                        value={paymentBoardNewPassword}
                                    />
                                    <CommonInputHalf id='confirmPassword'
                                        onChange={e => setPaymentBoardNewConfirmPassword(e.target.value)}
                                        placeholder='Confirm Password'
                                        type='text'
                                        value={paymentBoardNewConfirmPassword}
                                    />
                                </CommonInputRow>
                                <div className="ld-submit-button-container">
                                    <CommonButton label='Submit'
                                        onClick={() => { handlePaymentBoardUpdatePasswordSubmitClick() }} />
                                </div>
                            </div>) : null}
                            <ButtonRow>
                                <CommonButton label='Edit Details'
                                    onClick={() => { handleEditDetailsClick() }} />
                            </ButtonRow>
                        </CommonColorForm>
                    </>
                }
                {!isNull(branchDetailError) && <div className="content-error">{branchDetailError.message}</div>}
            </CommonScreenContent>

            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default LocationDetail