import { SellerActionTypes } from './seller.types'

//Add Seller 
export const addInvSellerRequest = data => ({
    type: SellerActionTypes.INV_ADD_SELLER_REQUEST,
    payload: data
})

export const addInvSellerRequestSuccess = data => ({
    type: SellerActionTypes.INV_ADD_SELLER_REQUEST_SUCCESS,
    payload: data
})

export const addInvSellerRequestError = data => ({
    type: SellerActionTypes.INV_ADD_SELLER_REQUEST_ERROR,
    payload: data
})

export const addInvSellerRequestStatus = data => ({
    type: SellerActionTypes.INV_ADD_SELLER_REQUEST_STATUS,
    payload: data
})

//Seller List
export const invSellerListRequest = data => ({
    type: SellerActionTypes.INV_SELLER_LIST_REQUEST,
    payload: data
})

export const invSellerListRequestSuccess = data => ({
    type: SellerActionTypes.INV_SELLER_LIST_REQUEST_SUCCESS,
    payload: data
})

export const invSellerListRequestError = data => ({
    type: SellerActionTypes.INV_SELLER_LIST_REQUEST_ERROR,
    payload: data
})

//Update Seller
export const updateInvSellerRequest = data => ({
    type: SellerActionTypes.INV_UPDATE_SELLER_REQUEST,
    payload: data
})

export const updateInvSellerRequestSuccess = data => ({
    type: SellerActionTypes.INV_UPDATE_SELLER_REQUEST_SUCCESS,
    payload: data
})

export const updateInvSellerRequestError = data => ({
    type: SellerActionTypes.INV_UPDATE_SELLER_REQUEST_ERROR,
    payload: data
})

export const updateInvSellerRequestStatus = data => ({
    type: SellerActionTypes.INV_UPDATE_SELLER_REQUEST_STATUS,
    payload: data
})