import React, { useEffect, useState } from 'react'
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { agentLoginRequest } from '../../../redux/user/user.action'
import SubTextLabel from '../../../components/sub-text-label/subTextLabel';
import CommonForm from '../../../components/common-form/commonForm';
import CommonButton from '../../../components/button/common-button/commonButton';

const AgentLoginScreen = props => {
    const [mobile, setMobile] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const { user, isLogin, agentLoginSuccess, agentLoginError } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('Login successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            navigate(ROUTES.businessList, { replace: true });
        } else {
            if (agentLoginError) {
                setLoading(false)
                displayErrorToast(agentLoginError.message)
            }
        }
    }, [user, isLogin, agentLoginSuccess, agentLoginError])

    const handleSignIn = () => {
        if (!isEmpty(mobile)) {
            setLoading(true)
            const data = {
                agentPhoneNumber: mobile,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(agentLoginRequest(data))
        } else {
            displayErrorToast('Phone number cannot be blank', true)
        }
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    return (
        <>
            <div className='contentAreaAgentSignIn'>
                <div className="asi-img-contain" onClick={() => { navigateToHome() }}>
                    {/* <img src={require('../../../assets/GoMoBites.png')} className="asi-img" /> */}
                    <img src={require('../../../assets/NewLogo1.png')} className="asi-img" alt='logo' />
                </div>
                <CommonForm>
                    <SubTextLabel label='Login with your phone number' />
                    <div className="form-floating mb-4 asi-input">
                        <input
                            type="number"
                            className="form-control"
                            id="email"
                            placeholder="Phone number"
                            value={mobile}
                            onChange={e => setMobile(e.target.value)}
                            required />
                        <label for="email">Phone Number</label>
                    </div>
                    <div className='asi-button-row'>
                        <CommonButton label='Login'
                            onClick={() => handleSignIn()} />
                    </div>
                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AgentLoginScreen