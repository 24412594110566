import { RequestTicketActionTypes } from './request-ticket.types'

//Raise Ticket
export const raiseTicketRequest = data => ({
    type: RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST,
    payload: data
})

export const raiseTicketRequestSuccess = data => ({
    type: RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST_SUCCESS,
    payload: data
})

export const raiseTicketRequestError = data => ({
    type: RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST_ERROR,
    payload: data
})

export const raiseTicketRequestStatus = data => ({
    type: RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST_STATUS,
    payload: data
})

//Request Ticket List
export const requestTicketListRequest = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_LIST_REQUEST,
    payload: data
})

export const requestTicketListRequestSuccess = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_LIST_REQUEST_SUCCESS,
    payload: data
})

export const requestTicketListRequestError = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_LIST_REQUEST_ERROR,
    payload: data
})

//Request Ticket detail
export const requestTicketDetailRequest = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_DETAIL_REQUEST,
    payload: data
})

export const requestTicketDetailRequestSuccess = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const requestTicketDetailRequestError = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_DETAIL_REQUEST_ERROR,
    payload: data
})

//Request Ticket Update
export const requestTicketUpdateDetailRequest = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST,
    payload: data
})

export const requestTicketUpdateDetailRequestSuccess = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const requestTicketUpdateDetailRequestError = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST_ERROR,
    payload: data
})

export const requestTicketUpdateDetailRequestStatus = data => ({
    type: RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST_STATUS,
    payload: data
})