import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics, isEmpty } from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES, API_CONSTANTS } from '../../../utils/AppConstants';
import { addItemInCart, reduceItemQty } from '../../../redux/cart/cart.action'
import { SocketContext } from '../../../SocketCtx'
import { placeOrderRequest, placeOrderRequestStatus } from '../../../redux/order/order.action'
import { terminalListRequest } from '../../../redux/delivery-terminal/delivery-terminal.action'
import Select from 'react-select'
import ScreenLabel from "../../../components/screen-label/screenLabel";

const ReviewOrderScreen = props => {
    const { user } = useSelector(state => state.user)
    const { cartItem, totalAmount, subTotal, taxAmount, discountAmt } = useSelector(state => state.cart)
    const { placeOrderSuccess, placeOrderError, placeOrderStatus, selectedRestaurantId, selectedLocationId, selectedRestaurantCode } = useSelector(state => state.order)
    const [isLoading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState()
    const [otherInformation, setOtherInformation] = useState('')
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const socket = useContext(SocketContext)
    const { temrinalListSuccess, temrinalListError } = useSelector(state => state.deliveryTerminal)
    const [selectedDeliveryTerminal, setDeliveryTerminal] = useState()

    useEffect(() => {
        const data = {
            screen: 'EMPLOYEE_REVIEW_ORDER',
            screenClass: 'EMPLOYEE_REVIEW_ORDER'
        }
        screenCaptureAnalytics(data)
        fetchTerminalList()
    }, [])

    const fetchTerminalList = () => {
        if (!isNull(selectedRestaurantId) && !isNull(selectedLocationId)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurantId,
                locationId: selectedLocationId
            }
            dispatch(terminalListRequest(data))
        } else {
            displayErrorToast('Restaurant and location is not selected')
        }
    }

    useEffect(() => {
        if (!isNull(temrinalListSuccess)) {
            setLoading(false)
        } else if (!isNull(temrinalListError)) {
            setLoading(false)

        }
    }, [temrinalListSuccess, temrinalListError])

    useEffect(() => {
        if (placeOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            if (placeOrderSuccess && placeOrderSuccess.data && placeOrderSuccess.data.order) {
                displaySuccessToast(`Order successfully placed. Order number ${placeOrderSuccess.data.order.orderNumber}`)
                const data = {
                    orderNumber: placeOrderSuccess.data.order.orderNumber,
                    restaurantId: selectedRestaurantId
                }
                socket.emit('order-placed-success', data)
                navigate(-1)
            }
        } else if (placeOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(placeOrderError.message)
            if (placeOrderError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
        if (placeOrderStatus.length) {
            dispatch(placeOrderRequestStatus(''))
        }
    }, [placeOrderSuccess, placeOrderError, placeOrderStatus])

    const handlePlaceOrderClick = () => {
        if (isNull(cartItem)) {
            displayErrorToast('No item in cart found. Please add and then place order')
        } else {
            handlePlaceOrder()
        }
    }

    const handlePlaceOrder = () => {
        if (isNull(selectedDeliveryTerminal)) {
            displayErrorToast('Delivery terminal not selected')
        } else if (isNull(selectedRestaurantId)) {
            displayErrorToast('Restaurant detail not available')
        } else if (isNull(selectedLocationId)) {
            displayErrorToast('Restaurant location detail not available')
        } else {
            setLoading(true)
            const data = {
                businessCode: selectedRestaurantCode,
                deliveryType: 'DELIVERY-TERMINAL',
                subTotal: subTotal,
                discountAmount: discountAmt,
                taxAmount: taxAmount,
                deliveryCharges: 0,
                grandTotal: totalAmount,
                businessId: selectedRestaurantId,
                locationId: selectedLocationId,
                itemsList: cartItem,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                currencyLabel: cartItem[0].currencyLabel,
                currencySymbol: cartItem[0].currencySymbol,
                currencyRegion: cartItem[0].currencyRegion,
                deliveryTerminalNumber: selectedDeliveryTerminal.terminalId,
                employeeId: user.data.userDetails.userId
            }
            if (!isEmpty(name)) {
                data.contactPersonName = name
            }
            if (!isEmpty(mobile)) {
                data.contactPersonNumber = mobile
            }
            if (!isEmpty(otherInformation)) {
                data.otherInformation = otherInformation
            }
            dispatch(placeOrderRequest(data))
        }
    }

    const updateQty = element => {
        dispatch(addItemInCart(element))
    }

    const reduceQty = element => {
        dispatch(reduceItemQty(element))
    }

    return (
        <>
            <div className="eor-container">
                <ScreenLabel label='Review Order' />
                {cartItem && <table class="table table-striped table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col" className="eor-table-col-title">Name</th>
                            <th scope="col" className="eor-table-col-title">Quantity</th>
                            <th scope="col" className="eor-table-col-title">Price</th>
                            <th scope="col" className="eor-table-col-title text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItem.map(element => (
                            <tr className="eor-table-row-container">
                                <td className="eor-table-col-item">{element.name}</td>
                                <td className="eor-table-col-item">{element.quantity}</td>
                                {
                                    displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                        (<td className="eor-table-col-item">{element.currencySymbol} {element.price.toFixed(2)}</td>)
                                        : (<td className="eor-table-col-item">{element.price.toFixed(2)} {element.currencySymbol}</td>)}
                                <td className="eor-table-col-item">
                                    <div className="eor-update-qty-container">
                                        <div className="eor-img-container" onClick={() => reduceQty(element)}>
                                            <img className="eor-img" src={require('../../../assets/minus.png')} alt="minus" />
                                        </div>
                                        <div className="eor-img-container" onClick={() => updateQty(element)}>
                                            <img className="eor-img" src={require('../../../assets/plus.png')} alt="plus" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>}
                {cartItem && <div className="eor-row mt-3">
                    <div className="eor-row-item">Sub Total</div>
                    {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                        (<div className="eor-row-item-value">{cartItem[0].currencySymbol} {subTotal.toFixed(2)}</div>)
                        : (<div className="eor-row-item-value">{subTotal.toFixed(2)} {cartItem[0].currencySymbol}</div>)}
                </div>}
                {cartItem && <div className="eor-row">
                    <div className="eor-row-item">Total Discount</div>
                    {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                        (<div className="eor-row-item-value">{cartItem[0].currencySymbol} {discountAmt.toFixed(2)}</div>)
                        : (<div className="eor-row-item-value">{discountAmt.toFixed(2)} {cartItem[0].currencySymbol}</div>)}
                </div>}
                {cartItem && <div className="eor-row">
                    <div className="eor-row-item">Total Tax</div>
                    {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                        (<div className="eor-row-item-value">{cartItem[0].currencySymbol} {taxAmount.toFixed(2)}</div>)
                        : (<div className="eor-row-item-value">{taxAmount.toFixed(2)} {cartItem[0].currencySymbol}</div>)}
                </div>}
                {cartItem && <div className="eor-row">
                    <div className="eor-row-item">Grand Total</div>
                    {displayCurrencySymbolLeft(cartItem[0].currencyRegion) === true ?
                        (<div className="eor-row-item-value">{cartItem[0].currencySymbol} {totalAmount.toFixed(2)}</div>)
                        : (<div className="eor-row-item-value">{totalAmount.toFixed(2)} {cartItem[0].currencySymbol}</div>)}
                </div>}
                {cartItem && <div>
                    <div className="cr-item-label mt-3">Other Information</div>
                    <div className="form-floating mt-2">
                        <input
                            type="text"
                            className="form-control eor-input"
                            id="name"
                            placeholder="Customer Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required />
                        <label for="name">Customer Name</label>
                    </div>
                    <div className="form-floating mt-2 mb-2">
                        <input
                            type="text"
                            className="form-control eor-input"
                            id="name"
                            placeholder="Special Request"
                            value={otherInformation}
                            onChange={e => setOtherInformation(e.target.value)}
                            required />
                        <label for="name">Special Request</label>
                    </div>
                    {temrinalListSuccess && temrinalListSuccess.data && <Select
                        className='ct-select mb-2'
                        value={selectedDeliveryTerminal}
                        getOptionLabel={e => e.title}
                        getOptionValue={e => e}
                        options={temrinalListSuccess.data}
                        placeholder='Select Delivery Terminal'
                        menuPlacement='top'
                        onChange={e => setDeliveryTerminal(e)} />}
                </div>}
                {!isNull(cartItem) && <div className='eor-button-row'>
                    <button type='button' className='btn eor-button' onClick={() => handlePlaceOrderClick()}>Place Order</button>
                </div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ReviewOrderScreen