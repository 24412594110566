import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { addInvSellerRequest, addInvSellerRequestStatus } from '../../../../redux/inventory/seller/seller.action'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";

const InvAddSeller = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const { addInvSellerSuccess, addInvSellerError, addInvSellerReqStatus } = useSelector(state => state.invSeller)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    let navigate = useNavigate();
    const [sellerName, setSellerName] = useState('')
    const [sellerAddress, setSellerAddress] = useState('')
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [contactPersonName, setContactPersonName] = useState('')
    const [contactPersonNumber, setContactPersonNumber] = useState('')
    const [contactPersonAltNumber, setContactPersonAltNumber] = useState('')
    const [contactPersonEmail, setContactPersonEmail] = useState('')

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_SELLER, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        dispatch(countryListRequest())
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.INVENTORY.ADD_CATEGORY) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_CATEGORY, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (addInvSellerReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addInvSellerSuccess.message)
            clearFields()
        } else if (addInvSellerReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addInvSellerError.message)
        }
        if (addInvSellerReqStatus?.length) {
            dispatch(addInvSellerRequestStatus(''))
        }
    }, [addInvSellerSuccess, addInvSellerError, addInvSellerReqStatus])

    const clearFields = () => {
        setSellerName('')
        setSellerAddress('')
        setContactPersonName('')
        setContactPersonNumber('')
        setContactPersonAltNumber('')
        setContactPersonEmail('')
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    const handleSubmitClick = () => {
        if (isEmpty(sellerName)) {
            displayErrorToast('Seller name cannot be blank')
        } else if (isEmpty(sellerAddress)) {
            displayErrorToast('Seller address not selected')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Seller address country is not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Seller address state is not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Seller address state is not selected')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else {
            setLoading(true)
            const data = {
                sellerName: sellerName,
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                locationId: isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation,
                productCode: user.data.userDetails.productCode,
                sellerAddress: sellerAddress,
                sellerCity: cityDropdown.name,
                sellerState: stateDropdown.name,
                sellerCountry: countryDropdown.name
            }
            if (!isEmpty(contactPersonName)) {
                data.contactPersonName = contactPersonName
            }
            if (!isEmpty(contactPersonNumber)) {
                data.contactPersonNumber = contactPersonNumber
            }
            if (!isEmpty(contactPersonAltNumber)) {
                data.contactPersonAltNumber = contactPersonAltNumber
            }
            if (!isEmpty(contactPersonEmail)) {
                data.contactPersonEmail = contactPersonEmail
            }
            dispatch(addInvSellerRequest(data))
        }
    }

    const fetchStateList = (countryId) => {
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Add Seller' />
                </div>
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new seller details' />
                    <CommonInputFull
                        type='text'
                        id='name'
                        onChange={e => setSellerName(e.target.value)}
                        placeholder='Seller Name'
                        value={sellerName}
                    />
                    {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Restaurant" />}
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="content-error">No restaurant found</div>}
                    {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Location" />}
                    {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="content-error">No location found</div>}
                    <FormFieldLabel className='up-bold-label' label='Contact Information' />
                    <CommonInputRow>
                        <CommonInputHalf
                            type='text'
                            id='contactPersonName'
                            onChange={e => setContactPersonName(e.target.value)}
                            placeholder='Person Name'
                            value={contactPersonName}
                        />
                        <CommonInputHalf
                            type='text'
                            id='contactPersonEmail'
                            onChange={e => setContactPersonEmail(e.target.value)}
                            placeholder='Email Address'
                            value={contactPersonEmail}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputHalf
                            type='text'
                            id='contactPersonNumber'
                            onChange={e => setContactPersonNumber(e.target.value)}
                            placeholder='Contact Number'
                            value={contactPersonNumber}
                        />
                        <CommonInputHalf
                            type='text'
                            id='contactPersonAltNumber'
                            onChange={e => setContactPersonAltNumber(e.target.value)}
                            placeholder='Alternate Number'
                            value={contactPersonAltNumber}
                        />
                    </CommonInputRow>
                    <FormFieldLabel className='up-bold-label' label='Address Information' />
                    <CommonInputFull
                        type='text'
                        id='name'
                        onChange={e => setSellerAddress(e.target.value)}
                        placeholder='Seller Address'
                        value={sellerAddress}
                    />
                    {countryListSuccess && <Select
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e);
                            setCountryCode(e.iso2)
                            fetchStateList(e.iso2)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            isBig={true}
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonFormBorder>

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvAddSeller