import React, { useEffect, useState } from 'react'
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { supportRegisterRequest, supportRegisterRequestStatus } from '../../../redux/user/user.action'
import CommonButton from '../../../components/button/common-button/commonButton';
import SubTextLabel from '../../../components/sub-text-label/subTextLabel';
import CommonForm from '../../../components/common-form/commonForm';

const SupportSignUpScreen = props => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const { supportRegistrationSuccess, supportRegistrationError, supportRegistrationReqStatus } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'SUPPORT_REGISTRATION',
            screenClass: 'SUPPORT_REGISTRATION'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (supportRegistrationReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(supportRegistrationSuccess.message)
            navigate(ROUTES.signIn, { replace: true });
        } else if (supportRegistrationReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(supportRegistrationError.message)
        }

        if (supportRegistrationReqStatus.length) {
            dispatch(supportRegisterRequestStatus(''))
        }
    }, [supportRegistrationSuccess, supportRegistrationError, supportRegistrationReqStatus])

    const handleSignUp = () => {
        if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank')
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank')
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast('Confirm password cannot be blank')
        } else if (password !== confirmPassword) {
            displayErrorToast('Password and confirm password does not match')
        } else {
            setLoading(true)
            const data = {
                email: email,
                password: password
            }
            dispatch(supportRegisterRequest(data))
        }
    }

    const handlePasswordChange = e => {
        const text = e.target.value
        setPassword(text)
    }

    const handleEmailChange = e => {
        const text = e.target.value
        setEmail(text)
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    return (
        <>
            <div className='ss-contentAreaSignIn'>
                <div className="ss-sign-in-img-contain" onClick={() => { navigateToHome() }}>
                    {/* <img src={require('../../../assets/GoMoBites.png')} className="sign-in-img" alt="logo" /> */}
                    <img src={require('../../../assets/NewLogo1.png')} className="ss-sign-in-img" alt='logo' />
                </div>
                <CommonForm>
                    <SubTextLabel label='Enter details to register new support user' />
                    <div className="form-floating mb-2 ss-sign-in-input">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="name@example.com"
                            value={email}
                            onChange={handleEmailChange}
                            required />
                        <label for="email">Email address</label>
                    </div>
                    <div className="form-floating mb-2 ss-sign-in-input">
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder='Password'
                            required />
                        <label for="password">Password</label>
                    </div>
                    <div className="form-floating mb-2 ss-sign-in-input">
                        <input
                            type="email"
                            className="form-control"
                            id="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            placeholder='Confirm Password'
                            required />
                        <label for="password">Confirm Password</label>
                    </div>
                    <div className='ss-sign-in-button-row'>
                        <CommonButton label='Register' onClick={() => handleSignUp()} />

                    </div>
                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SupportSignUpScreen