import React from "react";
import { ROUTES } from "../../../utils/AppConstants";
import './styles.css'

const NavBarComponent = ({ navigate }) => {
    const TYPE_FEATURE = 1
    const TYPE_ABOUT = 2
    const TYPE_CONTACT_US = 3
    const TYPE_SIGN_IN = 4
    const TYPE_SIGN_UP = 5
    const TYPE_HOME = 6

    const handleItemClick = type => {
        switch (type) {
            case TYPE_FEATURE:
                navigate(ROUTES.features)
                break;
            case TYPE_ABOUT:
                navigate(ROUTES.about)
                break;

            case TYPE_CONTACT_US:
                navigate(ROUTES.contactUs)
                break;

            case TYPE_SIGN_IN:
                navigate(ROUTES.signIn)
                break;

            case TYPE_SIGN_UP:
                navigate(ROUTES.signUp)
                break;
            case TYPE_HOME:
                navigate(ROUTES.home)
                break;

            default:
                break;
        }
    }

    return (<nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
            <div className="home-logo-container" onClick={() => { handleItemClick(TYPE_HOME) }}>
                <img src={require('../../../assets/NewLogo1.png')} className="img" alt="logo" />
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-lg-0">
                </ul>
                <ul className="navbar-nav">
                    <li class="nav-item nav-item-label">
                        <a class="nav-link active" aria-current="page" onClick={() => { handleItemClick(TYPE_FEATURE) }}>Features</a>
                    </li>
                    <li class="nav-item nav-item-label">
                        <a class="nav-link active" aria-current="page" onClick={() => { handleItemClick(TYPE_ABOUT) }}>About</a>
                    </li>
                    <li class="nav-item nav-item-label">
                        <a class="nav-link active" aria-current="page" onClick={() => { handleItemClick(TYPE_CONTACT_US) }}>Contact Us</a>
                    </li>
                    <li class="nav-item nav-item-label">
                        <a class="nav-link active" aria-current="page" onClick={() => { handleItemClick(TYPE_SIGN_IN) }}>Sign In</a>
                    </li>
                    <li class="nav-item-label-btn">
                        <a class="nav-link nav-btn" aria-current="page" onClick={() => { handleItemClick(TYPE_SIGN_UP) }}>Get Started</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>)
}

export default NavBarComponent