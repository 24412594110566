import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { subscriptionListRequest } from '../../../../redux/user/user.action'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import TopButton from "../../../../components/button/top-button/topButton";
import { ROUTES } from "../../../../utils/AppConstants";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import { isEmpty } from "../../../../utils/Utils";
import moment from "moment";

const SubscriptionList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, subscriptionListSuccess, subscriptionListError } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        dispatch(subscriptionListRequest())
    }, [])

    useEffect(() => {
        if (!isEmpty(subscriptionListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(subscriptionListError)) {
            setLoading(false)
        }
    }, [subscriptionListSuccess, subscriptionListError])

    const handlerAddPlan = () => {
        navigate(ROUTES.subscription.addSubscriptionPlan)
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Subscription Plans' />
                    <TopButton isSmall={true} label='Add Plan' onClick={() => { handlerAddPlan() }} />
                </CommonInputRow>
                <div className="table-scroll-container">
                    {subscriptionListSuccess && subscriptionListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Title</th>
                                <th scope="col" className="rl-table-col-title text-center">Restaurant Limit</th>
                                <th scope="col" className="rl-table-col-title text-center">Price</th>
                                <th scope="col" className="rl-table-col-title text-center">Customized Pricing</th>
                                <th scope="col" className="rl-table-col-title text-center">Features</th>
                                <th scope="col" className="rl-table-col-title text-center">Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptionListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.title}</td>
                                    <td className="rl-table-col-item text-center">{element.restaurantCount}</td>
                                    <td className="rl-table-col-item text-center">{element.isTrailSubscription === true ? 'Free' : `${element.price} ${element.priceType}`}</td>
                                    <td className="rl-table-col-item text-center">{element.isCustomize === true ? 'Yes' : 'No'}</td>
                                    <td className="rl-table-col-item text-center">{element.features.map(item => (<div>{item}</div>))}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {!isEmpty(subscriptionListError) && <div className="content-error">{subscriptionListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SubscriptionList