import React, { useEffect, useState } from 'react'
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, screenCaptureAnalytics
} from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { loginTerminalRequest } from '../../../../redux/user/user.action'
import SubTextLabel from '../../../../components/sub-text-label/subTextLabel';
import CommonForm from '../../../../components/common-form/commonForm';

const DeliveryTerminalLoginScreen = props => {
    const [terminalCode, setTerminalCode] = useState('')
    const [passcode, setPasscode] = useState('')
    const [isLoading, setLoading] = useState(false)
    const { loginTerminalSuccess, loginTerminalError, user, isLogin } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'TERMINAL_LOGIN',
            screenClass: 'TERMINAL_LOGIN'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('Login successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            navigate(ROUTES.terminalDashboard, { replace: true });
        } else {
            if (loginTerminalError) {
                setLoading(false)
                displayErrorToast(loginTerminalError.message)
            }
        }
    }, [user, isLogin, loginTerminalError])

    const handleSignIn = () => {
        if (terminalCode.length && passcode.length) {
            setLoading(true)
            const data = {
                terminalCode: terminalCode,
                passCode: passcode
            }
            dispatch(loginTerminalRequest(data))
        } else {
            displayErrorToast('Terminal code and passcode cannot be blank', true)
        }
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    return (
        <>
            <div className='contentAreaLDT'>
                <div className="ldt-img-contain" onClick={() => { navigateToHome() }}>
                    {/* <img src={require('../../../assets/GoMoBites.png')} className="ldt-img" /> */}
                    <img src={require('../../../../assets/NewLogo1.png')} className="ldt-img" alt='logo' />
                </div>
                <CommonForm>
                    <SubTextLabel label='Login with terminal credentials' />
                    <div className="form-floating mb-4 ldt-input">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Username"
                            value={terminalCode}
                            onChange={e => setTerminalCode(e.target.value)}
                            required />
                        <label for="email">Username</label>
                    </div>
                    <div className="form-floating mb-1 ldt-input">
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={passcode}
                            onChange={e => setPasscode(e.target.value)}
                            placeholder='Password'
                            required />
                        <label for="password">Password</label>
                    </div>
                    <div className='ldt-button-row'>
                        <button type='button' onClick={() => handleSignIn()} className='btn ldt-button'>Login</button>
                    </div>
                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default DeliveryTerminalLoginScreen