import { takeLatest, call, put } from 'redux-saga/effects'
import {
    placeReservationAPI, reservationDetailAPI, reservationListAPI, updateReservationStatusAPI
} from '../api-requests/reservation';
import {
    placeReservationRequestError, placeReservationRequestSuccess, reservationDetailRequestError,
    reservationDetailRequestSuccess, reservationListRequestError, reservationListRequestSuccess,
    updateReservationStatusRequestError, updateReservationStatusRequestSuccess
} from './reservation.action';
import { ReservationActionTypes } from './reservation.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleUpdateReservationStatus({ payload }) {
    try {
        const response = yield call(updateReservationStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateReservationStatusRequestSuccess(decryptedData))
            } else {
                yield put(updateReservationStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateReservationStatusRequestError(error.response.data))
    }
}

export function* updateReservationStatusReq() {
    yield takeLatest(ReservationActionTypes.UPDATE_RESERVATION_STATUS_REQUEST, handleUpdateReservationStatus)
}

function* handleReservationDetail({ payload }) {
    try {
        const response = yield call(reservationDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(reservationDetailRequestSuccess(decryptedData))
            } else {
                yield put(reservationDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(reservationDetailRequestError(error.response.data))
    }
}

export function* reservationDetailReq() {
    yield takeLatest(ReservationActionTypes.RESERVATION_DETAIL_REQUEST, handleReservationDetail)
}

function* handleReservationList({ payload }) {
    try {
        const response = yield call(reservationListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(reservationListRequestSuccess(decryptedData))
            } else {
                yield put(reservationListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(reservationListRequestError(error.response.data))
    }
}

export function* reservationListReq() {
    yield takeLatest(ReservationActionTypes.RESERVATION_LIST_REQUEST, handleReservationList)
}

function* handlePlaceReservation({ payload }) {
    try {
        const response = yield call(placeReservationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(placeReservationRequestSuccess(decryptedData))
            } else {
                yield put(placeReservationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(placeReservationRequestError(error.response.data))
    }
}

export function* placeReservationReq() {
    yield takeLatest(ReservationActionTypes.PLACE_RESERVATION_REQUEST, handlePlaceReservation)
}