import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import React, { useEffect, useContext, useState, useRef } from 'react';
import './App.css';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import RouteGuard from './components/route-guard/route-guard.component'
import { APP_CONSTANTS, ROUTES } from './utils/AppConstants'
import { useSelector, useDispatch } from 'react-redux';
import HomeScreen from './pages/home/homeScreen'
import Dashboard from './pages/business/dashboard/dashboard'
import AddCategoryScreen from './pages/business/category/add-category/addCategoryScreen'
import CategoryListScreen from './pages/business/category/category-list/categoryListScreen'
import OrderListScreen from './pages/business/order/order-list/orderListScreen'
import AddProductScreen from './pages/business/product/add-product/addProductScreen'
import ProductListScreen from './pages/business/product/product-list/productListScreen'
import SignInScreen from './pages/business/sign-in/signInScreen'
import SignUpScreen from './pages/business/sign-up/signUpScreen'
import AddSubCategoryScreen from './pages/business/sub-category/add-sub-category/addSubCategoryScreen'
import SubCategoryListScreen from './pages/business/sub-category/sub-category-list/subCategoryListScreen'
import CustomSidebar from './components/sidebar/sidebar.component'
import QrCodeGenerate from './pages/business/qr-code/qrCode'
import MenuCategory from './pages/customer/menu-category/menuCategory';
import SubMenuCategory from './pages/customer/menu-sub-category/menuSubCategory';
import MenuProduct from './pages/customer/menu-product/menuProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faShoppingCart, faCartArrowDown, faSignOutAlt, faComments, faUserAlt } from '@fortawesome/free-solid-svg-icons';

import { Nav, NavItem } from 'reactstrap';
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, removeCustomerPayAtEndOrderInfo } from './utils/Utils';
import CartList from './pages/customer/cart-list/cartList';
import CustomerOrderList from './pages/customer/order-list/customerOrderList';
import CustomerOrderDetail from './pages/customer/order-detail/orderDetail';
import EditProductScreen from './pages/business/product/edit-product/editProductScreen'
import RestaurantListScreen from './pages/business/restaurant/restaurant-list/restaurant-list';
import AddRestaurantScreen from './pages/business/restaurant/add-restaurant/addRestaurant';
import LocationListScreen from './pages/business/location/location-list/locationList';
import AddLocationScreen from './pages/business/location/add-location/addLocation';
import PrivacyPolicyPage from './pages/privacy-policy/privacyPolicyScreen'
import CookiePolicyScreen from './pages/cookie-policy/cookiePolicyScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CartReviewScreen from './pages/customer/cart-review/cartReview';
import ForgotPassword from './pages/business/forgot-password/forgotPassword'
import EditLocationScreen from './pages/business/location/edit-location/editLocationScreen'
import AgentLoginScreen from './pages/agent/agent-login/agentLogin'
import BusinessList from './pages/agent/business-list/businessList'
import CustomerOrderViewBill from './pages/customer/view-bill/viewBill'
import EmployeeListScreen from './pages/employee/employee-list/employee-list'
import AddEmployeeScreen from './pages/employee/add-employee/add-employee'
import DeliveryTerminalListScreen from './pages/business/delivery-terminal/delivery-terminal-list/delivery-terminal-list'
import AddDeliveryTerminalScreen from './pages/business/delivery-terminal/add-delivery-terminal/add-delivery-terminal'
import DeliveryTerminalLoginScreen from './pages/business/delivery-terminal/login-delivery-terminal/login-delivery-terminal'
import DeliveryTerminalDashboardScreen from './pages/business/delivery-terminal/terminal-dashboard/terminal-dashboard'
import EmployeeLoginScreen from './pages/employee/employee-login/employee-login'
import PlaceOrderScreen from './pages/employee/employee-place-order/place-order'
import ReviewOrderScreen from './pages/employee/employee-review-order/employee-review-order'
import OrderListBoardScreen from './pages/business/order/order-list-board/orderListBoard'
import OrderPaymentCancelScreen from './pages/customer/order-payment-cancel/orderPaymentCancel'
import OrderPaymentSuccessScreen from './pages/customer/order-payment-success/orderPaymentSuccess'
import AddReservationScreen from './pages/business/reservation/add-reservation/add-reservation'
import ReservationListScreen from './pages/business/reservation/reservation-list/reservation-list'
import ReservationDetail from './pages/business/reservation/reservation-detail/reservation-detail'
import RestaurantSelectionScreen from './pages/employee/restaurant-selection/restaurant-selection'
import RequestTicketListScreen from './pages/request-ticket/request-ticket-list/request-ticket-list'
import RaiseRequestTicketScreen from './pages/request-ticket/add-request-ticket/add-request-ticket'
import RequestTicketDetailScreen from './pages/request-ticket/request-ticket-detail/request-ticket-detail'
import SupportSignUpScreen from './pages/support/support-signup/support-signup'
import SupportRequestTicketListScreen from './pages/support/support-request-ticket-list/support-request-ticket-list'
import SupportRequestTicketDetailScreen from './pages/support/support-request-ticket-detail/support-request-ticket-detail'
// import { io } from "socket.io-client";
import { config } from './config';
import Peer from "simple-peer"
import { SocketContext } from './SocketCtx'
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import { hideAddCartBannerAct } from './redux/cart/cart.action'
import { updateUserNotificationTokenRequest, callWaiterRequest } from './redux/user/user.action'
import HomeNew from './pages/home-page/home-new/homeNew'
import ContactUs from './pages/home-page/contact-us/contactUs'
import FeaturesList from './pages/home-page/feature-list/featureList'
import AboutPage from './pages/home-page/about/about'
import SupportBulkMenuUpload from './pages/support/support-bulk-menu-upload/supportBulkMenuUpload'
import ContactUsList from './pages/support/contact-us/contact-us-list/contactUsList'
import ContactUsDetail from './pages/support/contact-us/contact-us-detail/contactUsDetail'
import FeatureRequest from './pages/feature-request/feature-request-list/feature-request'
import AddFeatureRequest from './pages/feature-request/add-feature-request/addFeatureRequest'
import FeatureRequestDetail from './pages/feature-request/feature-request-detail/featureRequestDetail'
import { clearCustomerPayAtEndPaymentSuccessNotif } from './redux/socket/socket.action'
import notificationTone from "./assets/notification.mp3"
import Joyride from 'react-joyride';
import Registration from './pages/business/registration/registration'
import UpdateProfile from './pages/business/update-profile/updateProfile'
import MenuProductCustomize from './pages/customer/menu-product-customize/menuProductCustomize'
import { onMessageListener, messaging } from './fcmAnalytics'
import { getToken } from "firebase/messaging";
import CommonButton from './components/button/common-button/commonButton';
import KitchenOrderList from './pages/kitchen/kitchen-order-list/kitchenOrderList'
import LocationDetail from './pages/business/location/location-detail/locationDetail'
import OrderPaymentBoardScreen from './pages/business/order/order-payment-board/orderPaymentBoard'
import TermsAndCondition from './pages/terms-condition/termsAndCondition'
import OperatorList from './pages/operator/operator-list/operatorList'
import OperatorDetail from './pages/operator/operator-detail/operatorDetail'
import AddOperator from './pages/operator/add-operator/addOperator'
import OperatorAddBusiness from './pages/operator/business/business/add-business/addBusiness'
import OperatorBusinessDetail from './pages/operator/business/business/business-detail/businessDetail'
import OperatorBusinessList from './pages/operator/business/business/business-list/businessList'
import OperatorMenuList from './pages/operator/business/menu/menu-list/menuList'
import AddMenuItem from './pages/operator/business/menu/add-menu-item/addMenuItem'
import EditMenuItemOperator from './pages/operator/business/menu/edit-menu-item/editMenuItem'
import AddBusinessOwner from './pages/operator/business/business/add-business-owner/addBusinessOwner'
import MenuCategoryProduct from './pages/customer/menu-category-product/menuCategoryProduct'
import CustomerProfile from './pages/customer/customer-profile/customerProfile'
import SubTextLabel from './components/sub-text-label/subTextLabel';
import CommonInputFull from './components/common-input-full/commonInputFull';
import NotFoundPage from './pages/not-found/notFoundPage'
import InvAddCategory from './pages/inventory/category/add-category/addCategory'
import InvCategoryList from './pages/inventory/category/category-list/categoryList'
import InvAddOrderInvoice from './pages/inventory/order-invoice/add-order-invoice/addOrderInvoice'
import InvOrderInvoiceDetail from './pages/inventory/order-invoice/order-invoice-detail/orderInvoiceDetail'
import InvOrderInvoiceList from './pages/inventory/order-invoice/order-invoice-list/orderInvoiceList'
import InvAddProduct from './pages/inventory/product/add-product/addProduct'
import InvProductDetail from './pages/inventory/product/product-detail/productDetail'
import InvProductList from './pages/inventory/product/product-list/productList'
import InvAddSeller from './pages/inventory/seller/add-seller/addSeller'
import InvSellerList from './pages/inventory/seller/seller-list/sellerList'
import AddSubscription from './pages/admin/subscription/add-subscription/addSubscription'
import SubscriptionList from './pages/admin/subscription/subscription-list/subscriptionList'

function App() {
  const { isLogin, isCustomerLogin, displayBottomBar, restaurantId,
    locationId, user, restuarantListSuccess, displayEmployeeSidebar, customerLoginSuccess,
    displayTour, displaySideBar, showCallWaiterRequest } = useSelector((state) => state.user)
  const { cartItem, displayAddCartBanner } = useSelector(state => state.cart)
  const { dashboardSuccess, dashboardError } = useSelector(state => state.order)
  let navigate = useNavigate();
  let dispatch = useDispatch()
  const { makeCall, callAccepted, customerPayAtEndSuccessNotif, callWaiterRequestNotif } = useSelector(state => state.socket)
  const [stream, setStream] = useState()
  const [callEnded, setCallEnded] = useState(false)
  const [callAcceptedd, setCallAcceptedd] = useState(false)
  const myVideo = useRef()
  const userVideo = useRef()
  const terminalId = useRef()
  const connectionRef = useRef()
  const [audioMuted, setAudioMuted] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const socket = useContext(SocketContext)
  const [showStripeDialog, setShowStripeDialog] = useState(false)
  const [showCookieConsent, setShowCookieConsent] = useState(true)
  const [displayCartLabel, setDisplayCartLabel] = useState(false)
  const [steps, setSteps] = useState([
    {
      target: '.id-dashboard',
      content: 'This will show selected restaurant and location orders information',
      title: 'Dashboard'
    },
    {
      target: '.id-restaurant',
      content: 'Here you can view and add new restaurants.',
      title: 'Restaurants'
    },
    {
      target: '.id-location',
      content: 'Here you can view, edit and add new restaurant location(s).',
      title: 'Location'
    },
    {
      target: '.id-qr-code',
      content: 'Click this option to generate QR code for customer to place order.',
      title: 'Qr Code'
    },
    {
      target: '.id-menu',
      content: 'Select this option to create your restaurant digital menu.',
      title: 'Menu'
    },
    {
      target: '.id-order',
      content: 'All the incoming orders can be accessed here for further action',
      title: 'Orders'
    },
    {
      target: '.id-employee',
      content: 'View, edit and add your employee(s)',
      title: 'Employee'
    },
    {
      target: '.id-reservation',
      content: 'Click this option to view, update and add new reservations.',
      title: 'Reservations'
    },
    {
      target: '.id-request-ticket',
      content: 'Here you can view, edit and raise new ticket for any issues related to GoMoBites.',
      title: 'Request Ticket'
    }
  ])

  const [notification, setNotification] = useState({ title: '', body: '' });
  const [fcmToken, setFcmToken] = useState('')
  const location = useLocation();
  const [showLiveDemoLink, setShowLiveDemoLink] = useState(false)
  const [showCallWaitModal, setShowCallWaiterModal] = useState(false)
  const [tableNumber, setTableNumber] = useState('')
  const [roomNumber, setRoomNumber] = useState('')
  const [waiterMessage, setWaiterMessage] = useState('')

  const [waiterRequestFromTable, setWaiterRequestFromTable] = useState(false)
  const [waiterRequestFromRoom, setWaiterRequestFromRoom] = useState(false)

  useEffect(() => {
    getToken(messaging, { vapidKey: 'BNQPh3Ebk_ixLn87PdiJkMqjquyUFwIKzgbFFJnx7QQzg82loyCLdVDirrb-zLQbiR3b9TpLcZG5hQKGn7kGXNg' })
      .then((currentToken) => {
        if (currentToken) {
          setFcmToken(currentToken)
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          displayConsoleLog('No registration token available. Request permission to generate one.');
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        displayConsoleLog('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      })

  }, [])

  useEffect(() => {
    if (showCallWaiterRequest === true) {
      setRoomNumber('')
      setTableNumber('')
      setWaiterMessage('')
      setShowCallWaiterModal(true)
    } else {
      setShowCallWaiterModal(false)
    }
  }, [showCallWaiterRequest])

  useEffect(() => {
    if (!isNull(location)) {
      if (location.pathname === '/') {
        setShowLiveDemoLink(true)
        dispatch({ type: APP_CONSTANTS.CLEAR_GLOBAL_STORE })
      } else {
        setShowLiveDemoLink(false)
      }
    }
  }, [location])

  useEffect(() => {
    if (!isEmpty(fcmToken)) {
      localStorage.setItem(APP_CONSTANTS.KEY_FCM_TOKEN, fcmToken)
      displayConsoleLog('token', fcmToken)
      if (isLogin === true) {
        sendFcmTokenToServer()
      }

      if (!isNull(customerLoginSuccess)) {
        sendFcmTokenToServer()
      }

    }
  }, [fcmToken])

  // fetchToken(setTokenFound)

  useEffect(() => {
    if (!isNull(customerLoginSuccess) && !isEmpty(fcmToken)) {
      localStorage.setItem(APP_CONSTANTS.KEY_FCM_TOKEN, fcmToken)
      sendFcmTokenToServer()
    }
  }, [customerLoginSuccess])

  useEffect(() => {
    if (isLogin === true && !isEmpty(fcmToken)) {
      localStorage.setItem(APP_CONSTANTS.KEY_FCM_TOKEN, fcmToken)
      sendFcmTokenToServer()
    }
  }, [isLogin])

  onMessageListener().then(payload => {
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    // displayConsoleLog('payload', payload)
    // displaySuccessToast(payload.notification.body)
  }).catch(err => displayConsoleLog('failed', err))

  // useEffect(() => {
  //   if (!isNull(user) && !isNull(user.data)) {
  // if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
  //       navigator.mediaDevices.getUserMedia({ audio: true, video: true })
  //         .then((stream) => {
  //           setStream(stream)
  //           myVideo.current.srcObject = stream
  //         })
  //     }
  //   }
  // }, [user])

  useEffect(() => {
    console.log('displayTour', displayTour)
  }, [displayTour])

  useEffect(() => {
    caches.keys().then(cs => {
      cs.forEach(c => caches.delete(c))
    })
  }, [])

  const sendFcmTokenToServer = () => {
    const data = {
      notificationToken: localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN)
    }
    if (!isNull(customerLoginSuccess)) {
      data.userId = customerLoginSuccess.data.userDetails.userId
    }
    if (!isNull(user)) {
      data.userId = user.data.userDetails.userId
    }
    dispatch(updateUserNotificationTokenRequest(data))
  }

  // useEffect(() => {
  //   if (!isNull(user) && !isNull(user.data)) {
  //     if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS && !isNull(user.data.userDetails.businessList)) {
  //       let isStripPG = false
  //       user.data.userDetails.businessList.forEach(element => {
  //         const stripePG = element.orderPaymentModes.filter(b => b.gatewayType === APP_CONSTANTS.STRIPE)
  //         if (!isNull(stripePG)) {
  //           isStripPG = true
  //         }
  //       });
  //       if (isStripPG === true) {
  //         setShowStripeDialog(true)
  //       }
  //     }
  //   }
  // }, [user])

  useEffect(() => {
    if (customerPayAtEndSuccessNotif !== null) {
      if (!isNull(customerLoginSuccess) && !isNull(customerLoginSuccess.data) && !isNull(customerLoginSuccess.data.userDetails)) {
        if (customerPayAtEndSuccessNotif.customerId === customerLoginSuccess.data.userDetails.customerId) {
          const audio = new Audio(notificationTone)
          audio.play()
          displaySuccessToast(customerPayAtEndSuccessNotif.message)
          removeCustomerPayAtEndOrderInfo()
          dispatch(clearCustomerPayAtEndPaymentSuccessNotif(""))
        }
      }
    }
  }, [customerPayAtEndSuccessNotif])

  useEffect(() => {
    if (!isNull(dashboardSuccess)) {
      if (dashboardSuccess.data !== null && dashboardSuccess.data.stripeAccountAvailable === true) {
        if (dashboardSuccess.data.charges_enabled === false || dashboardSuccess.data.payouts_enabled === false)
          setShowStripeDialog(true)
      }
    }
  }, [dashboardSuccess])

  useEffect(() => {
    if (!isNull(makeCall)) {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
        setStream(stream);
        // setCallingFriend(true)
        // setCaller(id)
        if (myVideo.current) {
          myVideo.current.srcObject = stream;
        }
        const peer = new Peer({
          initiator: true,
          trickle: false,
          stream: stream,
          config: {
            iceServers: [
              {
                urls: 'turn:turn.gomobites.com:3478',
                credential: '4321',
                username: 'guest'
              },
              {
                urls: 'stun:stun.gomobites.com:3478',
                credential: '4321',
                username: 'guest'
              }]
          }
        });

        connectionRef.current = peer;
        terminalId.current = makeCall
        peer.on("signal", data => {
          socket.emit("callUser", {
            userToCall: makeCall,
            signalData: data,
            from: user.data.userDetails.userId
          })
        })

        peer.on("stream", stream => {
          // if (userVideo.current) {
          //   userVideo.current.srcObject = stream;
          // }
          if ("srcObject" in userVideo.current) {
            userVideo.current.srcObject = stream;
          }
          else {
            userVideo.current.src = window.URL.createObjectURL(stream);
          }
        });

        // peer.on('error', (err) => {
        //   endCall()
        // })

        // socket.current.on("callAccepted", signal => {
        //   setCallAccepted(true);
        //   peer.signal(signal);
        // })
      })
        .catch(() => {
          displayErrorToast('You cannot make a call without granting video and audio permissions.')
        })
    }
  }, [makeCall])

  useEffect(() => {
    if (!isNull(user) && !isNull(callAccepted) && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE && !isNull(connectionRef.current)) {
      connectionRef.current.on("stream", stream => {
        console.log('stream', stream);
        // if (userVideo.current) {
        //   userVideo.current.srcObject = stream;
        // }
        if ("srcObject" in userVideo.current) {
          userVideo.current.srcObject = stream;
        }
        else {
          userVideo.current.src = window.URL.createObjectURL(stream);
        }
      });
    }
  })

  useEffect(() => {
    if (!isNull(user) && !isNull(callAccepted) && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
      setCallAcceptedd(true)
      connectionRef.current.signal(callAccepted.signal)
    }
  }, [callAccepted])

  const endCall = () => {
    connectionRef.current.destroy()
    console.log('terminalId.current', terminalId.current);
    socket.emit("endCall", {
      to: terminalId.current
    })
    window.location.reload()
  }

  const muteAudio = () => {
    if (stream) {
      setAudioMuted(!audioMuted)
      stream.getAudioTracks()[0].enabled = audioMuted
    }
  }

  function stopWebcam() {
    if (stream) {
      setVideoMuted(!videoMuted)
      stream.getVideoTracks()[0].enabled = videoMuted
    }
  }

  const shareScreen = () => {
    navigator.mediaDevices.getDisplayMedia({ cursor: true })
      .then(screenStream => {
        connectionRef.current.replaceTrack(stream.getVideoTracks()[0], screenStream.getVideoTracks()[0], stream)
        myVideo.current.srcObject = screenStream
        screenStream.getTracks()[0].onended = () => {
          connectionRef.current.replaceTrack(screenStream.getVideoTracks()[0], stream.getVideoTracks()[0], stream)
          myVideo.current.srcObject = stream
        }
      })
  }

  const customerBottomTabs = [
    {
      route: '',
      label: 'Home',
      icon: faHome
    },
    {
      route: '',
      label: 'Cart',
      icon: !isNull(cartItem) ? faCartArrowDown : faShoppingCart
    },
    {
      route: '',
      label: 'Profile',
      icon: faUserAlt
    }
  ]

  const customerBottomTabsLogin = [
    {
      route: '',
      label: 'Home',
      icon: faHome
    },
    {
      route: '',
      label: 'Cart',
      icon: !isNull(cartItem) ? faCartArrowDown : faShoppingCart
    },
    {
      route: '',
      label: 'Orders',
      icon: faList
    },
    {
      route: '',
      label: 'Profile',
      icon: faUserAlt
    }
  ]

  const handleOpenCartClick = () => {
    dispatch(hideAddCartBannerAct(''))
    navigate(ROUTES.cartList)
  }

  const handleCloseCartLabelClick = () => {
    dispatch(hideAddCartBannerAct(''))
  }

  const onTabClick = (tab) => {
    if (tab.label === 'Home') {
      navigate(`${ROUTES.menuCatList}/${restaurantId}/${locationId}`, { replace: true });
    } else if (tab.label === 'Orders') {
      navigate(ROUTES.customerOrderList)
    } else if (tab.label === 'Cart') {
      navigate(ROUTES.cartList)
    } else if (tab.label === 'Logout') {
      removeCustomerPayAtEndOrderInfo()
      dispatch({ type: APP_CONSTANTS.CUSTOMER_LOGOUT })
    } else if (tab.label === 'Profile') {
      navigate(ROUTES.customerProfile)
    }
  }

  const handleCookieConsentClick = () => {
    localStorage.setItem(APP_CONSTANTS.DISPLAY_COOKIE_CONSENT, "YES")
    setShowCookieConsent(false)
    // setDisplayCookieConsent(false)
  }

  const handleCookieLabelClick = () => {
    navigate(ROUTES.cookiePolicy)
  }

  const displayCookieConsent = () => {
    if (!isEmpty(localStorage.getItem(APP_CONSTANTS.DISPLAY_COOKIE_CONSENT)) && localStorage.getItem(APP_CONSTANTS.DISPLAY_COOKIE_CONSENT) === 'YES') {
      return false
    }
    return true
  }

  const handleDialogClose = () => {
    setShowStripeDialog(false)
  }

  const handleVisitStripeClick = () => {
    window.open('https://dashboard.stripe.com/login', '_blank')
  }

  const handleViewDemoClick = () => {
    window.open('https://try.gomobites.com/', '_blank')
  }

  const handleCallWaiterSubmitClick = () => {
    if (waiterRequestFromTable === true && isEmpty(tableNumber)) {
      displayErrorToast('Table number cannot be blank')
    } else if (waiterRequestFromRoom === true && isEmpty(roomNumber)) {
      displayErrorToast('Room number cannot be blank')
    } else if (isEmpty(waiterMessage)) {
      displayErrorToast('Message cannot be blank')
    } else {
      const data = {
        message: waiterMessage,
        restaurantId: restaurantId,
        locationId: locationId
      }
      if (waiterRequestFromTable === true) {
        data.tableNumber = tableNumber
      }
      if (waiterRequestFromRoom === true) {
        data.roomNumber = roomNumber
      }
      socket.emit('request-call-waiter', data)
      dispatch(callWaiterRequest(false))
    }
  }

  const handleWaiterRequestTypeChoiceChange = type => {
    if (type === APP_CONSTANTS.WAITER_REQUEST_TYPE.ROOM_NUMBER) {
      setWaiterRequestFromRoom(true)
      setWaiterRequestFromTable(false)
    } else if (type === APP_CONSTANTS.WAITER_REQUEST_TYPE.TABLE_NUMBER) {
      setWaiterRequestFromRoom(false)
      setWaiterRequestFromTable(true)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: isLogin ? 'row' : 'column', height: '100vh' }}>
      {isLogin && user && user.data.userDetails.userType !== APP_CONSTANTS.USER_TYPE.EMPLOYEE && displaySideBar === true && <div >
        <CustomSidebar />
      </div>}
      {isLogin && user && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE && displayEmployeeSidebar === true && <div >
        <CustomSidebar />
      </div>}
      <div className={isLogin ? "content-area-full" : displayBottomBar ? "content-area" : "content-area-full"}>
        <Routes>
          <Route path={ROUTES.homeNew} element={<HomeNew />} />
          <Route path={ROUTES.about} element={<AboutPage />} />
          <Route path={ROUTES.features} element={<FeaturesList />} />
          <Route path={ROUTES.agentLogin} element={<AgentLoginScreen />} />
          <Route path={ROUTES.deliveryTerminalLogin} element={<DeliveryTerminalLoginScreen />} />
          <Route path={ROUTES.employeeLogin} element={<EmployeeLoginScreen />} />
          <Route path={ROUTES.signIn} element={<SignInScreen />} />
          {/* <Route path={ROUTES.signUp} element={<SignUpScreen />} /> */}
          <Route path={ROUTES.signUp} element={<Registration />} />
          {/* <Route path={ROUTES.home} element={<HomeScreen />} /> */}
          <Route path={ROUTES.home} element={<HomeNew />} />
          <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={ROUTES.cookiePolicy} element={<CookiePolicyScreen />} />
          <Route path={ROUTES.termsOfService} element={<TermsAndCondition />} />
          <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />
          <Route path={ROUTES.supportRegistration} element={<SupportSignUpScreen />} />
          <Route path={ROUTES.contactUs} element={<ContactUs />} />
          <Route path={ROUTES.featureRequest} element={<FeatureRequest />} />
          <Route path={ROUTES.addFeatureRequest} element={<AddFeatureRequest />} />
          <Route path={ROUTES.featureRequestDetail} element={<FeatureRequestDetail />} />
          {/* <Route path={ROUTES.updatProfile} element={<UpdateProfile />} /> */}
          <Route path={ROUTES.updatProfile} element={
            <RouteGuard>
              <UpdateProfile />
            </RouteGuard>
          } />
          <Route path={ROUTES.terminalDashboard} element={
            <RouteGuard>
              <DeliveryTerminalDashboardScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.dashboard} element={
            <RouteGuard>
              <Dashboard />
            </RouteGuard>
          } />
          <Route path={ROUTES.addCategory} element={
            <RouteGuard>
              <AddCategoryScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.categoryList} element={
            <RouteGuard>
              <CategoryListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.addProduct} element={
            <RouteGuard>
              <AddProductScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.updateProduct} element={
            <RouteGuard>
              <EditProductScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.productList} element={
            <RouteGuard>
              <ProductListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.addSubCategory} element={
            <RouteGuard>
              <AddSubCategoryScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.subCategoryList} element={
            <RouteGuard>
              <SubCategoryListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.qrCode} element={
            <RouteGuard>
              <QrCodeGenerate />
            </RouteGuard>
          } />
          <Route path={ROUTES.orderList} element={
            <RouteGuard>
              <OrderListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.restaurantList} element={
            <RouteGuard>
              <RestaurantListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.addRestaurant} element={
            <RouteGuard>
              <AddRestaurantScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.locationList} element={
            <RouteGuard>
              <LocationListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.addLocation} element={
            <RouteGuard>
              <AddLocationScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.editLocation} element={
            <RouteGuard>
              <EditLocationScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.businessList} element={
            <RouteGuard>
              <BusinessList />
            </RouteGuard>
          } />
          <Route path={ROUTES.employeeList} element={
            <RouteGuard>
              <EmployeeListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.addEmployee} element={
            <RouteGuard>
              <AddEmployeeScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.deliveryTerminalList} element={
            <RouteGuard>
              <DeliveryTerminalListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.addDeliveryTerminal} element={
            <RouteGuard>
              <AddDeliveryTerminalScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.placeOrder} element={
            <RouteGuard>
              <PlaceOrderScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.employeeReviewOrder} element={
            <RouteGuard>
              <ReviewOrderScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.placeReservation} element={
            <RouteGuard>
              <AddReservationScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.reservationList} element={
            <RouteGuard>
              <ReservationListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.reservationDetail} element={
            <RouteGuard>
              <ReservationDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.requestTicketList} element={
            <RouteGuard>
              <RequestTicketListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.raiseRequestTicket} element={
            <RouteGuard>
              <RaiseRequestTicketScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.requestTicketDetail} element={
            <RouteGuard>
              <RequestTicketDetailScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.sRequestTicketList} element={
            <RouteGuard>
              <SupportRequestTicketListScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.sRequestTicketDetail} element={
            <RouteGuard>
              <SupportRequestTicketDetailScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.supportBulkMenuUpload} element={
            <RouteGuard>
              <SupportBulkMenuUpload />
            </RouteGuard>
          } />
          <Route path={ROUTES.contactUsList} element={
            <RouteGuard>
              <ContactUsList />
            </RouteGuard>
          } />
          <Route path={ROUTES.contactUsDetail} element={
            <RouteGuard>
              <ContactUsDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.kitchenOrderList} element={
            <RouteGuard>
              <KitchenOrderList />
            </RouteGuard>
          } />
          <Route path={ROUTES.locationDetail} element={
            <RouteGuard>
              <LocationDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.orderListBoard} element={
            <RouteGuard>
              <OrderListBoardScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.orderPaymentBoard} element={
            <RouteGuard>
              <OrderPaymentBoardScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorList} element={
            <RouteGuard>
              <OperatorList />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorDetail} element={
            <RouteGuard>
              <OperatorDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorRegister} element={
            <RouteGuard>
              <AddOperator />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorAddBusiness} element={
            <RouteGuard>
              <OperatorAddBusiness />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorBusinessDetail} element={
            <RouteGuard>
              <OperatorBusinessDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorBusinessList} element={
            <RouteGuard>
              <OperatorBusinessList />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorMenuList} element={
            <RouteGuard>
              <OperatorMenuList />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorAddMenuItem} element={
            <RouteGuard>
              <AddMenuItem />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorEditMenuItem} element={
            <RouteGuard>
              <EditMenuItemOperator />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorAddBusinessOwner} element={
            <RouteGuard>
              <AddBusinessOwner />
            </RouteGuard>
          } />
          <Route path={ROUTES.subscription.addSubscriptionPlan} element={
            <RouteGuard>
              <AddSubscription />
            </RouteGuard>
          } />
          <Route path={ROUTES.subscription.subscriptionPlanList} element={
            <RouteGuard>
              <SubscriptionList />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.addCategory} element={
            <RouteGuard>
              <InvAddCategory />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.categoryList} element={
            <RouteGuard>
              <InvCategoryList />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.addOrderInvoice} element={
            <RouteGuard>
              <InvAddOrderInvoice />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.orderInvoiceDetail} element={
            <RouteGuard>
              <InvOrderInvoiceDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.orderInvoiceList} element={
            <RouteGuard>
              <InvOrderInvoiceList />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.addProduct} element={
            <RouteGuard>
              <InvAddProduct />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.productDetail} element={
            <RouteGuard>
              <InvProductDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.productList} element={
            <RouteGuard>
              <InvProductList />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.addSeller} element={
            <RouteGuard>
              <InvAddSeller />
            </RouteGuard>
          } />
          <Route path={ROUTES.inventory.sellerList} element={
            <RouteGuard>
              <InvSellerList />
            </RouteGuard>
          } />
          <Route path={`${ROUTES.menuCatList}/:restaurantId/:locationId`} element={<MenuCategoryProduct />} />
          <Route path={`${ROUTES.menuSubCatList}`} element={<SubMenuCategory />} />
          <Route path={`${ROUTES.menuItemList}`} element={<MenuProduct />} />
          <Route path={`${ROUTES.menuProductCustomize}`} element={<MenuProductCustomize />} />
          <Route path={ROUTES.cartList} element={<CartList />} />
          <Route path={ROUTES.customerOrderList} element={<CustomerOrderList />} />
          <Route path={ROUTES.customerOrderDetail} element={<CustomerOrderDetail />} />
          <Route path={ROUTES.cartReview} element={<CartReviewScreen />} />
          <Route path={ROUTES.customerProfile} element={<CustomerProfile />} />
          <Route path={ROUTES.viewBill} element={<CustomerOrderViewBill />} />
          <Route path={ROUTES.orderPaymentCancelUrl} element={<OrderPaymentCancelScreen />} />
          <Route path={ROUTES.orderPaymentSuccessUrl} element={<OrderPaymentSuccessScreen />} />
          <Route path={ROUTES.selectWorkLocation} element={<RestaurantSelectionScreen />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </div>
      {displayAddCartBanner === true && <div className='goToCartContainer'>
        <div className='cartItemLabel'>Item added to cart</div>
        <div className='goToCartContentContainer'>
          <button type='button' className='btn goToCartBtn' onClick={() => {
            handleOpenCartClick()
          }}>Show Cart</button>
          <div className='cartCloseContainerImg' onClick={() => { handleCloseCartLabelClick() }}>
            <img className='close-img' src={require('./assets/close.png')} alt='Close Image' />
          </div>
        </div>
      </div>}
      {displayBottomBar === true && <nav className='navbar fixed-bottom navbar-light bottom-tab-nav' role='navigation'>
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {isCustomerLogin === true &&
              customerBottomTabsLogin.map((tab, index) => (
                <NavItem key={`tab-${index}`} className='tab-color-active'>
                  {/* <Link to={tab.route} className="nav-link bottom-nav-link" activeClassName="active"> */}
                  <div className="bottom-container" onClick={() => onTabClick(tab)}>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={tab.icon} size="lg" />
                    </div>
                    <div>{tab.label}</div>
                  </div>
                  {/* </Link> */}
                </NavItem>
              ))
            }
            {isCustomerLogin === false &&
              customerBottomTabs.map((tab, index) => (
                <NavItem key={`tab-${index}`} className='tab-color-active'>
                  {/* <Link to={tab.route} className="nav-link bottom-nav-link" activeClassName="active"> */}
                  <div className="bottom-container" onClick={() => onTabClick(tab)}>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={tab.icon} size="lg" />
                    </div>
                    <div>{tab.label}</div>
                  </div>
                  {/* </Link> */}
                </NavItem>
              ))
            }
          </div>
        </Nav>
      </nav>}
      {user && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE && <div className="dtl-video-container">
        <div>
          {stream && <video playsInline ref={myVideo} autoPlay className='dtl-list-video-player' id="local-video" />}
          {callAcceptedd && !callEnded ?
            <video playsInline ref={userVideo} autoPlay className='dtl-list-remote-video-player ms-2' id="remote-video" /> :
            null}
        </div>
        {callAcceptedd && !callEnded && <div className="dtl-call-option-row">
          <div className="dtl-call-option-icon-container" onClick={() => {
            shareScreen()
          }}>
            <img src={require('./assets/startScreenShare.png')} alt='Start screen share' className='dtl-call-option-icon' />
          </div>
          {videoMuted === false ?
            (<div className="dtl-call-option-icon-container" onClick={() => {
              stopWebcam()
            }}>
              <img src={require('./assets/showWebcam.png')} alt='Show webcam' className='dtl-call-option-icon' />
            </div>) :
            (<div className="dtl-call-option-icon-container" onClick={() => {
              stopWebcam()
            }}>
              <img src={require('./assets/stopWebcam.png')} alt='stop webcam' className='dtl-call-option-icon' />
            </div>)}


          {audioMuted === false ?
            (<div className="dtl-call-option-icon-container" onClick={() => {
              muteAudio()
            }}>
              <img src={require('./assets/openMic.png')} alt='Open Mic' className='dtl-call-option-icon' />
            </div>) :
            (<div className="dtl-call-option-icon-container" onClick={() => {
              muteAudio()
            }}>
              <img src={require('./assets/muteMic.png')} alt='Mute Mic' className='dtl-call-option-icon' />
            </div>)}


          <div className="dtl-call-option-icon-container" onClick={() => {
            endCall()
          }}>
            <img src={require('./assets/callEnd.png')} alt='Call End' className='dtl-call-option-icon' />
          </div>
        </div>}
      </div>}
      {displayCookieConsent() === true && showCookieConsent === true ? (<div className='consentContainer'>
        <div className='consentText'>This site uses cookies to store information on your computer. Some are essential to make our site work; others help us improve the user experience. By using the site, you consent to the placement of the cookies. <span className='consentClickLabel' onClick={() => { handleCookieLabelClick() }}>Read our cookie policy to learn more</span></div>
        <div className='consentCloseContainer'>
          <div className='consentCloseContainerImg' onClick={() => { handleCookieConsentClick() }}>
            <img className='close-img' alt='Close' src={require('./assets/close1.png')} />
          </div>
        </div>
      </div>) : null}
      <Modal
        onHide={() => { handleDialogClose() }}
        show={showStripeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Action Required
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cr-modal-order-subtext">Your stripe account setup is incomplete. Kindly update your stripe details to receive order payments.</div>
        </Modal.Body>
        <Modal.Footer>
          <CommonButton onClick={() => handleDialogClose()}
            label='Skip For Now' />
          <CommonButton onClick={() => handleVisitStripeClick()}
            label='Open Stripe' />
        </Modal.Footer>
      </Modal>
      <Modal
        onHide={() => { setShowCallWaiterModal(!showCallWaitModal) }}
        show={showCallWaitModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Call Waiter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubTextLabel label='Enter details to call waiter' />
          <CommonInputFull
            type='text'
            id='waiterMessage'
            onChange={e => setWaiterMessage(e.target.value)}
            placeholder='Message'
            value={waiterMessage}
          />
          <div className="ap-option-item-container">
            <label className="ap-choice-label-container">
              <input type='radio' value={APP_CONSTANTS.WAITER_REQUEST_TYPE.TABLE_NUMBER} checked={waiterRequestFromTable} onChange={e => handleWaiterRequestTypeChoiceChange(e.target.value)} />
              <span className="ap-choice-label">Table Number</span>
            </label>
            <label className="ap-choice-label-container">
              <input type='radio' value={APP_CONSTANTS.WAITER_REQUEST_TYPE.ROOM_NUMBER} checked={waiterRequestFromRoom} onChange={e => handleWaiterRequestTypeChoiceChange(e.target.value)} />
              <span className="ap-choice-label">Room Number</span>
            </label>
          </div>
          {waiterRequestFromRoom === true && <CommonInputFull
            type='text'
            id='roomNumber'
            onChange={e => setRoomNumber(e.target.value)}
            placeholder='Room Number'
            value={roomNumber}
          />}
          {waiterRequestFromTable === true && <CommonInputFull
            type='text'
            id='tableNumber'
            onChange={e => setTableNumber(e.target.value)}
            placeholder='Table Number'
            value={tableNumber}
          />}
        </Modal.Body>
        <Modal.Footer>
          <CommonButton onClick={() => handleCallWaiterSubmitClick()}
            label='Call Waiter' />
        </Modal.Footer>
      </Modal>
      <Joyride
        steps={steps}
        run={displayTour}
      />
      {/* {!isLogin && showLiveDemoLink && config.environment === APP_CONSTANTS.ENVIRONMENT.PROD && <div className='demo-link-container' onClick={() => { handleViewDemoClick() }}>
        View Live Demo
      </div>} */}
      <ToastContainer />
    </div>

  );
}

export default App;