import { combineReducers } from 'redux'
import userReducer from './user/user.reducer'
import categoryReducer from './category/category.reducer'
import productReducer from './product/product.reducer'
import employeeReducer from './employee/employee.reducer'
import deliveryTerminalReducer from './delivery-terminal/delivery-terminal.reducer'
import reservationReducer from './reservation/reservation.reducer'
import cartReducer from './cart/cart.reducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import orderReducer from './order/order.reducer'
import socketReducer from './socket/socket.reducer'
import requestTicketReducer from './request-ticket/request-ticket.reducer'
import contactUsReducer from './contactUs/contactUs.reducer'
import featureRequestReducer from './feature-request/featureRequest.reducer'
import invCategoryReducer from './inventory/category/category.reducer'
import invOrderInvoiceReducer from './inventory/order-invoice/orderInvoice.reducer'
import invProductReducer from './inventory/product/product.reducer'
import invSellerReducer from './inventory/seller/seller.reducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: userReducer,
    category: categoryReducer,
    product: productReducer,
    cart: cartReducer,
    order: orderReducer,
    socket: socketReducer,
    employee: employeeReducer,
    deliveryTerminal: deliveryTerminalReducer,
    reservation: reservationReducer,
    requestTicket: requestTicketReducer,
    contactUs: contactUsReducer,
    featureRequest: featureRequestReducer,
    invCategory: invCategoryReducer,
    invOrderInvoice: invOrderInvoiceReducer,
    invProduct: invProductReducer,
    invSeller: invSellerReducer
})

// export default rootReducer
export default persistReducer(persistConfig, rootReducer)