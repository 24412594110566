import { OrderActionTypes } from './order.types'

//Place order
export const placeOrderRequest = data => ({
    type: OrderActionTypes.PLACE_ORDER_REQUEST,
    payload: data
})

export const placeOrderRequestSuccess = data => ({
    type: OrderActionTypes.PLACE_ORDER_REQUEST_SUCCESS,
    payload: data
})

export const placeOrderRequestError = data => ({
    type: OrderActionTypes.PLACE_ORDER_REQUEST_ERROR,
    payload: data
})

export const placeOrderRequestStatus = data => ({
    type: OrderActionTypes.PLACE_ORDER_REQUEST_STATUS,
    payload: data
})

//Order list
export const orderListRequest = data => ({
    type: OrderActionTypes.ORDER_LIST_REQUEST,
    payload: data
})

export const orderListRequestSuccess = data => ({
    type: OrderActionTypes.ORDER_LIST_REQUEST_SUCCESS,
    payload: data
})

export const orderListRequestError = data => ({
    type: OrderActionTypes.ORDER_LIST_REQUEST_ERROR,
    payload: data
})

//Order detail
export const orderDetailRequest = data => ({
    type: OrderActionTypes.ORDER_DETAIL_REQUEST,
    payload: data
})

export const orderDetailRequestSuccess = data => ({
    type: OrderActionTypes.ORDER_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const orderDetailRequestError = data => ({
    type: OrderActionTypes.ORDER_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update order status
export const updateOrderStatusRequest = data => ({
    type: OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST,
    payload: data
})

export const updateOrderStatusRequestSuccess = data => ({
    type: OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const updateOrderStatusRequestError = data => ({
    type: OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST_ERROR,
    payload: data
})

export const updateOrderStatusRequestStatus = data => ({
    type: OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST_STATUS,
    payload: data
})

//Accept/Reject order
export const acceptRejectOrderRequest = data => ({
    type: OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST,
    payload: data
})

export const acceptRejectOrderRequestSuccess = data => ({
    type: OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST_SUCCESS,
    payload: data
})

export const acceptRejectOrderRequestError = data => ({
    type: OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST_ERROR,
    payload: data
})

export const acceptRejectOrderRequestStatus = data => ({
    type: OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST_STATUS,
    payload: data
})

//Update transaction record
export const updateOrderTransactionRequest = data => ({
    type: OrderActionTypes.UPDATE_TRANSACTION_REQUEST,
    payload: data
})

export const updateOrderTransactionRequestSuccess = data => ({
    type: OrderActionTypes.UPDATE_TRANSACTION_REQUEST_SUCCESS,
    payload: data
})

export const updateOrderTransactionRequestError = data => ({
    type: OrderActionTypes.UPDATE_TRANSACTION_REQUEST_ERROR,
    payload: data
})

export const updateOrderTransactionRequestStatus = data => ({
    type: OrderActionTypes.UPDATE_TRANSACTION_REQUEST_STATUS,
    payload: data
})

//Order dashboard
export const orderDashboardRequest = data => ({
    type: OrderActionTypes.ORDER_DASHBOARD_REQUEST,
    payload: data
})

export const orderDashboardRequestSuccess = data => ({
    type: OrderActionTypes.ORDER_DASHBOARD_REQUEST_SUCCESS,
    payload: data
})

export const orderDashboardRequestError = data => ({
    type: OrderActionTypes.ORDER_DASHBOARD_REQUEST_ERROR,
    payload: data
})

export const selectedRestaurantAndLocIdAct = data => ({
    type: OrderActionTypes.SELECTED_RESTAURANT_AND_LOC_ID,
    payload: data
})

//Order Generate payment link
export const orderGeneratePaymentLinkRequest = data => ({
    type: OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST,
    payload: data
})

export const orderGeneratePaymentLinkRequestSuccess = data => ({
    type: OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST_SUCCESS,
    payload: data
})

export const orderGeneratePaymentLinkRequestError = data => ({
    type: OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST_ERROR,
    payload: data
})

export const orderGeneratePaymentLinkRequestStatus = data => ({
    type: OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST_STATUS,
    payload: data
})

//Update existing order
export const updateExistingOrderRequest = data => ({
    type: OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST,
    payload: data
})

export const updateExistingOrderRequestSuccess = data => ({
    type: OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST_SUCCESS,
    payload: data
})

export const updateExistingOrderRequestError = data => ({
    type: OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST_ERROR,
    payload: data
})

export const updateExistingOrderRequestStatus = data => ({
    type: OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST_STATUS,
    payload: data
})

//Accept pay at end payment
export const acceptPayAtEndPaymentRequest = data => ({
    type: OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST,
    payload: data
})

export const acceptPayAtEndPaymentRequestSuccess = data => ({
    type: OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST_SUCCESS,
    payload: data
})

export const acceptPayAtEndPaymentRequestError = data => ({
    type: OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST_ERROR,
    payload: data
})

export const acceptPayAtEndPaymentRequestStatus = data => ({
    type: OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST_STATUS,
    payload: data
})

//Find pay at end order
export const findPayAtEndOrderRequest = data => ({
    type: OrderActionTypes.FIND_PAY_AT_END_ORDER_REQUEST,
    payload: data
})

export const findPayAtEndOrderRequestSuccess = data => ({
    type: OrderActionTypes.FIND_PAY_AT_END_ORDER_REQUEST_SUCCESS,
    payload: data
})

export const findPayAtEndOrderRequestError = data => ({
    type: OrderActionTypes.FIND_PAY_AT_END_ORDER_REQUEST_ERROR,
    payload: data
})

//Update customer action
export const updateCustomerActionRequest = data => ({
    type: OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST,
    payload: data
})

export const updateCustomerActionRequestSuccess = data => ({
    type: OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST_SUCCESS,
    payload: data
})

export const updateCustomerActionRequestError = data => ({
    type: OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST_ERROR,
    payload: data
})

export const updateCustomerActionRequestStatus = data => ({
    type: OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST_STATUS,
    payload: data
})