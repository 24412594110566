import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../../components/screen-label/screenLabel";

const OperatorBusinessDetail = props => {
    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Restaurant Details' />
            </CommonScreenContent>
        </>
    )
}

export default OperatorBusinessDetail