import React, { useEffect, useState } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { orderListRequest } from '../../../redux/order/order.action'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import { convertToPascal, displayErrorToast, isEmpty, isImageAvailable, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { customerLoginRequest, displayBottomBarRequest } from '../../../redux/user/user.action'
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonButton from "../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../components/form-field-label/formFieldLabel";
import { config } from "../../../config";

const CustomerOrderList = props => {
    const dispatch = useDispatch()
    const { orderListSuccess, orderListError } = useSelector(state => state.order)
    const { customerLoginSuccess, customerLoginError, restaurantDetailSuccess } = useSelector(state => state.user)
    const [isLoading, setLoading] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [mobileNumber, setMobileNumber] = useState()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_ORDER_LIST',
            screenClass: 'CUSTOMER_ORDER_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
        if (isNull(user) || user === 'null') {
            sessionStorage.clear()
            dispatch({ type: APP_CONSTANTS.LOGOUT })
        }
    }, [])

    useEffect(() => {
        if (!isNull(customerLoginSuccess)) {
            setLoading(true)
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(customerLoginSuccess.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, customerLoginSuccess.data.authToken)
            const data = {
                customerId: customerLoginSuccess.data.userDetails.userId
            }
            dispatch(orderListRequest(data))
            dispatch(displayBottomBarRequest(true))
        } else if (!isNull(customerLoginError)) {
            setLoading(false)
            displayErrorToast(customerLoginError.message)
        }

        if (isNull(customerLoginSuccess))
            setShowLogin(true)
    }, [customerLoginSuccess, customerLoginError])

    useEffect(() => {
        if (!isNull(orderListSuccess)) {
            setLoading(false)
        } else if (!isNull(orderListError)) {
            setLoading(false)
            if (orderListError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                navigate(-1)
            }
        }
    }, [orderListSuccess, orderListError])

    const handleSignIn = () => {
        // const numberRegex = new RegExp('^[0-9]+$')
        if (isNull(mobileNumber)) {
            displayErrorToast('Mobile number cannot be blank')
        }
        // else if (!numberRegex.test(mobileNumber)) {
        //     displayErrorToast('Invalid mobile number')
        // }
        else {
            setLoading(true)
            setShowLogin(false)
            const mobile = mobileNumber.replace('+', "")
            const data = {
                mobile: Number(mobile),
                notificationToken: !isEmpty(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN) ? localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN) : '')
            }
            dispatch(customerLoginRequest(data))
        }
    }

    const handleItemClick = item => {
        navigate(ROUTES.customerOrderDetail, { state: item.orderId });
    }
    return (
        <>
            <div className="col-list-container mb-4">
                {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                    <div className="cmc-label-container mt-2">
                        <div className="mcp-logo-container">
                            {isImageAvailable(restaurantDetailSuccess.data.image) ?
                                (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} className="img" alt="logo" />) :
                                <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />}
                        </div>
                        <div className="mcp-restaurant-label">Orders</div>
                    </div>
                ) :
                    (<div className="cmc-label-container">
                        <ScreenLabel label='Orders' />
                    </div>)
                }
                <div className="cmc-content-container">
                    {orderListSuccess && orderListSuccess.data && orderListSuccess.data.map(element => (
                        <div className="add-col-row" onClick={() => handleItemClick(element)}>
                            {(element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PLACED || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED) ?
                                (<div className="col-status-container col-status-container-yellow">{removeSpecialCharAndChangeToPascal(element.orderStatus)}</div>) : null}
                            {(element.orderStatus === APP_CONSTANTS.ORDER_STATUS.ACCEPTED || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARING || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.INPROCESS || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARED) ?
                                (<div className="col-status-container col-status-container-orange">{element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARED ? removeSpecialCharAndChangeToPascal(APP_CONSTANTS.ORDER_STATUS.READY_TO_SERVE) : convertToPascal(element.orderStatus)}</div>) : null}
                            {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.DELIVERED ?
                                (<div className="col-status-container col-status-container-green">{convertToPascal(element.orderStatus)}</div>) : null}
                            {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.CANCELLED ?
                                (<div className="col-status-container">{convertToPascal(element.orderStatus)}</div>) : null}
                            {element.orderNumber && <div className="col-amount-label">Order Number: <span className="col-item-label">{element.orderNumber}</span></div>}
                            <div className="col-icon-row-container">
                                <div className="col-icon-container">
                                    <img src={require('../../../assets/ic_user_icon.png')} className='img' alt="user" />
                                </div>
                                <div className="col-icon-label">{element.contactPersonName}</div>
                            </div>
                            <div className="col-icon-row-container">
                                <div className="col-icon-container">
                                    <img src={require('../../../assets/ic_call_icon.png')} className='img' alt="call" />
                                </div>
                                <div className="col-icon-label">{element.contactPersonNumber}</div>
                            </div>
                            {!isEmpty(element.dineInTableNumber) ? (
                                <div className="col-amount-label">Table Number: {element.dineInTableNumber} </div>
                            ) : null}
                            {!isEmpty(element.curbsideSlotNumber) ? (
                                <div className="col-amount-label">Curbside Number: {element.curbsideSlotNumber} </div>
                            ) : null}
                            {!isEmpty(element.cancelReason) ? (
                                <div className="col-amount-label">Reason: {element.cancelReason} </div>
                            ) : null}
                            {!isEmpty(element.paymentStatus) ? (
                                <div className="col-amount-label">Payment Status: {removeSpecialCharAndChangeToPascal(element.paymentStatus)} </div>
                            ) : null}

                        </div>
                    ))}
                </div>
                {orderListError && <div>No order found</div>}
            </div>
            <Modal
                onHide={() => { setShowLogin(!showLogin) }}
                show={showLogin}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Continue with mobile number' />
                    <PhoneInput
                        defaultCountry="US"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={setMobileNumber} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={handleSignIn} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerOrderList