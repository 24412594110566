import React, { useState, useContext } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import './sidebar.styles.scss'
import { Link } from 'react-router-dom';
import { ROUTES } from '../../utils/AppConstants'
import { FaHome, FaProductHunt, FaMapMarkerAlt, FaClipboardList, FaSignOutAlt, FaBuilding, FaShoppingCart, FaEnvelope, FaQrcode, FaUserAlt, FaSlideshare, FaRegEdit, FaListOl, FaBook, FaReceipt, FaRocketchat, FaBoxes, FaCoins } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { APP_CONSTANTS } from '../../utils/AppConstants'
import { SocketContext } from '../../SocketCtx'
import { isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isLoggedInUserKitchen, isLoggedInUserOrderBoard, isLoggedInUserOrderPaymentBoard, isLoggedInUserSupport, isNull, isLoggedInUserAdmin, isLoggedInUserOperator } from '../../utils/Utils';

const CustomSidebar = props => {
    const [isCollapsed, setCollapsed] = useState(true)
    const { isLogin, user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const socket = useContext(SocketContext)

    const displayMenuItem = (itemType) => {
        if (!isNull(user) &&
            !isNull(user.data) &&
            !isNull(user.data.userDetails) &&
            !isEmpty(user.data.userDetails.userType) &&
            user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => item.type === itemType)
            if (itemObj !== undefined && !isNull(itemObj)) {
                return true
            } else return false
        } else {
            return false
        }
    }

    const isPermissionAllowed = (itemType) => {
        if (isLoggedInUserBusiness(user)) {
            if (!isEmpty(user.data.userDetails.menuPermission)) {
                if (user.data.userDetails.menuPermission.includes(itemType)) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <div style={{
            display: 'flex',
            height: '100%',
            backgroundColor: '#FFFFFF',
            overflowY: 'scroll'
        }}>
            <Sidebar
                backgroundColor="#FFFFFF"
                collapsed={isCollapsed}
            >
                <div className='headerContainer' >
                    <div className={isCollapsed ? 'toggle-container' : 'toggle-container-end'}>
                        <div className='toggle' onClick={() => {
                            setCollapsed(!isCollapsed)
                        }}>
                            {isCollapsed ?
                                (<div>
                                    <img className='img' src={require('../../assets/ic_nav_open1.png')} alt='Hamburger' />
                                </div>) :
                                (<div>
                                    <img className='img' src={require('../../assets/ic_nav_close.png')} alt='Hamburger Back' />
                                </div>)}
                        </div>
                    </div>
                    <div className='logo-container'>
                        {isCollapsed === false ? (<div className='img-container'>
                            <img className='img' src={require('../../assets/NewLogo.png')} alt='Logo' />
                        </div>) : (
                            <div className='img-container-collapse'>
                                <img className='img' src={require('../../assets/NewLogoCircle.png')} alt='Logo circle' />
                            </div>
                        )}
                    </div>
                </div>
                {isLogin && isLoggedInUserAdmin(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaUserAlt />} component={<Link to={ROUTES.operatorList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.OPERATORS}
                        </MenuItem>
                        <MenuItem icon={<FaCoins />} component={<Link to={ROUTES.subscription.subscriptionPlanList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.SUBSCRIPTIONS}
                        </MenuItem>
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserOperator(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaBuilding />} component={<Link to={ROUTES.operatorBusinessList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.RESTAURANTS}
                        </MenuItem>
                        <MenuItem icon={<FaUserAlt />} component={<Link to={ROUTES.operatorAddBusinessOwner} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.ADD_OWNER}
                        </MenuItem>
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserBusiness(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaHome />} component={<Link to={ROUTES.dashboard} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.DASHBOARD}
                        </MenuItem>
                        {isPermissionAllowed('RESTAURANT') === true && <MenuItem icon={<FaBuilding />} component={<Link to={ROUTES.restaurantList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.RESTAURANTS}
                        </MenuItem>}
                        {isPermissionAllowed('RESTAURANT') === true && <MenuItem icon={<FaMapMarkerAlt />} component={<Link to={ROUTES.locationList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOCATIONS}
                        </MenuItem>}
                        {isPermissionAllowed('ONLINE_MENU') === true && <MenuItem icon={<FaQrcode />} component={<Link to={ROUTES.qrCode} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.QRCODE}
                        </MenuItem>}
                        {isPermissionAllowed('ONLINE_MENU') === true && <MenuItem icon={<FaProductHunt />} component={<Link to={ROUTES.productList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.MENU}
                        </MenuItem>}
                        {isPermissionAllowed('ONLINE_ORDERING') === true && <MenuItem icon={<FaClipboardList />} component={<Link to={ROUTES.orderList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.ORDERS}
                        </MenuItem>}
                        {isPermissionAllowed('EMPLOYEE_MANAGEMENT') === true && <MenuItem icon={<FaUserAlt />} component={<Link to={ROUTES.employeeList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.EMPLOYEES}
                        </MenuItem>}
                        {isPermissionAllowed('RESERVATION_MANAGEMENT') === true && <MenuItem icon={<FaBook />} component={<Link to={ROUTES.reservationList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.RESERVATIONS}
                        </MenuItem>}
                        <MenuItem icon={<FaReceipt />} component={<Link to={ROUTES.requestTicketList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.REQUEST_TICKET}
                        </MenuItem>
                        {isPermissionAllowed('INVENTORY_MANAGEMENT') === true && <SubMenu icon={<FaBoxes />} label='Inventory'>
                            <MenuItem component={<Link to={ROUTES.inventory.categoryList} />}>Categories</MenuItem>
                            <MenuItem component={<Link to={ROUTES.inventory.productList} />}>Products</MenuItem>
                            <MenuItem component={<Link to={ROUTES.inventory.sellerList} />}>Sellers</MenuItem>
                            <MenuItem component={<Link to={ROUTES.inventory.orderInvoiceList} />}>Order Invoices</MenuItem>
                        </SubMenu>}
                        {/* <MenuItem icon={<FaSlideshare />} component={<Link to={ROUTES.deliveryTerminalList} />}>
                            Terminal
                        </MenuItem> */}

                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}
                        </MenuItem>
                    </Menu>)}
                {isLogin && user && user.data.userDetails.userType === 'DELIVERY_TERMINAL' && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaHome />} component={<Link to={ROUTES.terminalDashboard} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.DASHBOARD}
                        </MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserEmployee(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.DASHBOARD) === true && <MenuItem icon={<FaHome />} component={<Link to={ROUTES.dashboard} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.DASHBOARD}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.LOCATION) === true && <MenuItem icon={<FaMapMarkerAlt />} component={<Link to={ROUTES.locationList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOCATIONS}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.QRCODE) === true && <MenuItem icon={<FaQrcode />} component={<Link to={ROUTES.qrCode} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.QRCODE}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.PRODUCT) === true && <MenuItem icon={<FaProductHunt />} component={<Link to={ROUTES.productList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.MENU}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.ORDER) === true && <MenuItem icon={<FaListOl />} component={<Link to={ROUTES.orderList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.ORDERS}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.EMPLOYEE) === true && <MenuItem icon={<FaUserAlt />} component={<Link to={ROUTES.employeeList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.EMPLOYEES}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.RESERVATION) === true && <MenuItem icon={<FaBook />} component={<Link to={ROUTES.reservationList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.RESERVATIONS}
                        </MenuItem>}
                        {displayMenuItem(APP_CONSTANTS.MENU_ITEM_TYPE.REQUEST_TICKET) === true && <MenuItem icon={<FaReceipt />} component={<Link to={ROUTES.requestTicketList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.REQUEST_TICKET}
                        </MenuItem>}
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserSupport(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaReceipt />} component={<Link to={ROUTES.sRequestTicketList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.REQUEST_TICKET}
                        </MenuItem>
                        <MenuItem icon={<FaProductHunt />} component={<Link to={ROUTES.supportBulkMenuUpload} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.BULK_MENU_UPLOAD}
                        </MenuItem>
                        <MenuItem icon={<FaRocketchat />} component={<Link to={ROUTES.contactUsList} />}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.CONTACT_MESSAGE_LIST}
                        </MenuItem>
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserKitchen(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserOrderBoard(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {isLogin && isLoggedInUserOrderPaymentBoard(user) === true && (
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                color: '#f68c1d',
                                backgroundColor: '#fcfcfc',
                            };
                        },
                    }}
                        closeOnClick>
                        <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>{APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}</MenuItem>
                    </Menu>)}
                {/* <SidebarFooter
                style={{ textAlign: 'center' }}>
                <div
                    className="sidebar-btn-wrapper"
                    style={{
                        padding: '10px 10px',
                    }}
                >
                    <span className='footer-text'>&#169; Copyright {new Date().getFullYear()}</span>
                </div>
            </SidebarFooter> */}
            </Sidebar>
        </div >
    )
}

export default CustomSidebar