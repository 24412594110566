import { ProductActionTypes } from './product.types'
import { API_CONSTANTS } from '../../../utils/AppConstants'

const INITIAL_STATE = {
    addInvProductSuccess: null,
    addInvProductError: null,
    addInvProductReqStatus: '',
    invProductListSuccess: null,
    invProductListError: null,
    invProductDetailSuccess: null,
    invProductDetailError: null,
    updateInvProductSuccess: null,
    updateInvProductError: null,
    updateInvProductReqStatus: '',
    updateInvProductConsumptionSuccess: null,
    updateInvProductConsumptionError: null,
    updateInvProductConsumptionReqStatus: ''
}

const invProductReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.INV_ADD_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                addInvProductSuccess: action.payload,
                addInvProductError: null,
                addInvProductReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.INV_ADD_PRODUCT_REQUEST_ERROR:
            return {
                ...state,
                addInvProductSuccess: null,
                addInvProductError: action.payload,
                addInvProductReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.INV_ADD_PRODUCT_REQUEST_STATUS:
            return {
                ...state,
                addInvProductReqStatus: ''
            }
        case ProductActionTypes.INV_PRODUCT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                invProductListSuccess: action.payload,
                invProductListError: null
            }
        case ProductActionTypes.INV_PRODUCT_LIST_REQUEST_ERROR:
            return {
                ...state,
                invProductListSuccess: null,
                invProductListError: action.payload
            }
        case ProductActionTypes.INV_PRODUCT_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                invProductDetailSuccess: action.payload,
                invProductDetailError: null
            }
        case ProductActionTypes.INV_PRODUCT_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                invProductDetailSuccess: null,
                invProductDetailError: action.payload
            }
        case ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                updateInvProductSuccess: action.payload,
                updateInvProductError: null,
                updateInvProductReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST_ERROR:
            return {
                ...state,
                updateInvProductSuccess: null,
                updateInvProductError: action.payload,
                updateInvProductReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.INV_UPDATE_PRODUCT_REQUEST_STATUS:
            return {
                ...state,
                updateInvProductReqStatus: ''
            }
        case ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_SUCCESS:
            return {
                ...state,
                updateInvProductConsumptionSuccess: action.payload,
                updateInvProductConsumptionError: null,
                updateInvProductConsumptionReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_ERROR:
            return {
                ...state,
                updateInvProductConsumptionSuccess: null,
                updateInvProductConsumptionError: action.payload,
                updateInvProductConsumptionReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_STATUS:
            return {
                ...state,
                updateInvProductConsumptionReqStatus: ''
            }
        case ProductActionTypes.LOGOUT:
            return INITIAL_STATE
        case ProductActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default invProductReducer