import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    forgotPasswordRequest, forgotPasswordRequestStatus, changePasswordRequest,
    changePasswordRequestStatus
} from '../../../redux/user/user.action'
import { API_CONSTANTS } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isValidEmail, screenCaptureAnalytics } from "../../../utils/Utils";
import { useNavigate } from 'react-router-dom';
import CommonButton from "../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../components/common-form/commonForm";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import CommonInputHalf from "../../../components/common-input-half/commonInputHalf";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";

const ForgotPassword = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [emailId, setEmailId] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [emaildId2, setEmailId2] = useState('')
    const [showAccessToken, setShowAccessToken] = useState(false)
    const { forgotPasswordSuccess, forgotPasswordError, forgotPasswordStatus,
        changePasswordSuccess, changePasswordError, changePasswordStatus } = useSelector(state => state.user)
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'FORGOT_PASSWORD',
            screenClass: 'FORGOT_PASSWORD'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (forgotPasswordStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(forgotPasswordSuccess.message)
            setShowAccessToken(true)
        } else if (forgotPasswordStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(forgotPasswordError.message)
        }

        if (forgotPasswordStatus.length) {
            dispatch(forgotPasswordRequestStatus(''))
        }
    }, [forgotPasswordSuccess, forgotPasswordError, forgotPasswordStatus])

    useEffect(() => {
        if (changePasswordStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(changePasswordSuccess.message)
            navigate(-1)
        } else if (changePasswordStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(changePasswordError.message)
        }

        if (changePasswordStatus.length) {
            dispatch(changePasswordRequestStatus(''))
        }
    }, [changePasswordSuccess, changePasswordError, changePasswordStatus])

    const handleChangePassword = () => {
        if (isEmpty(emaildId2)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(emaildId2)) {
            displayErrorToast('Enter a valid email id')
        } else if (isEmpty(accessToken)) {
            displayErrorToast('Access token cannot be blank')
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank')
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast('Confirm Password cannot be blank')
        } else if (password !== confirmPassword) {
            displayErrorToast('Password does not match')
        } else {
            setLoading(true)
            const data = {
                email: emaildId2,
                accessToken: accessToken,
                password: password
            }
            dispatch(changePasswordRequest(data))
        }
    }

    const handleForgorPassword = () => {
        if (isEmpty(emailId)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(emailId)) {
            displayErrorToast('Enter a valid email address')
        } else {
            setLoading(true)
            const data = {
                email: emailId
            }
            dispatch(forgotPasswordRequest(data))
        }
    }

    return (
        <>
            <div className="fp-container">
                <ScreenLabel label='Forgot Password' />
                <CommonForm>
                    {!showAccessToken && <div>
                        <SubTextLabel label='Enter registered email id to get access token' />
                        <CommonInputFull id="email"
                            placeholder="Email Address"
                            type="email"
                            onChange={e => setEmailId(e.target.value)}
                            value={emailId} />
                        <div className='fp-button-row'>
                            <CommonButton label='Submit'
                                onClick={() => handleForgorPassword()} />
                        </div>
                    </div>}
                    {showAccessToken && <div>
                        <SubTextLabel label='Enter details to change password' />
                        <CommonInputFull
                            id="email"
                            placeholder="Email Address"
                            value={emaildId2}
                            onChange={e => setEmailId2(e.target.value)}
                            type="email" />
                        <CommonInputFull
                            id="accessToken"
                            placeholder="Access Token"
                            value={accessToken}
                            onChange={e => setAccessToken(e.target.value)}
                            type="text" />
                        <CommonInputRow>
                            <CommonInputHalf
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type="password" />
                            <CommonInputHalf
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                type="text" />

                        </CommonInputRow>
                        <div className='fp-button-row'>
                            <CommonButton label='Submit'
                                onClick={() => handleChangePassword()} />
                        </div>
                    </div>}
                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ForgotPassword