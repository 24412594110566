export const ProductActionTypes = {
    INV_ADD_PRODUCT_REQUEST: 'INV_ADD_PRODUCT_REQUEST',
    INV_ADD_PRODUCT_REQUEST_SUCCESS: 'INV_ADD_PRODUCT_REQUEST_SUCCESS',
    INV_ADD_PRODUCT_REQUEST_ERROR: 'INV_ADD_PRODUCT_REQUEST_ERROR',
    INV_ADD_PRODUCT_REQUEST_STATUS: 'INV_ADD_PRODUCT_REQUEST_STATUS',

    INV_PRODUCT_LIST_REQUEST: 'INV_PRODUCT_LIST_REQUEST',
    INV_PRODUCT_LIST_REQUEST_SUCCESS: 'INV_PRODUCT_LIST_REQUEST_SUCCESS',
    INV_PRODUCT_LIST_REQUEST_ERROR: 'INV_PRODUCT_LIST_REQUEST_ERROR',

    INV_UPDATE_PRODUCT_REQUEST: 'INV_UPDATE_PRODUCT_REQUEST',
    INV_UPDATE_PRODUCT_REQUEST_SUCCESS: 'INV_UPDATE_PRODUCT_REQUEST_SUCCESS',
    INV_UPDATE_PRODUCT_REQUEST_ERROR: 'INV_UPDATE_PRODUCT_REQUEST_ERROR',
    INV_UPDATE_PRODUCT_REQUEST_STATUS: 'INV_UPDATE_PRODUCT_REQUEST_STATUS',

    INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST: 'INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST',
    INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_SUCCESS: 'INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_SUCCESS',
    INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_ERROR: 'INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_ERROR',
    INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_STATUS: 'INV_UPDATE_PRODUCT_CONSUMPTION_REQUEST_STATUS',

    INV_PRODUCT_DETAIL_REQUEST: 'INV_PRODUCT_DETAIL_REQUEST',
    INV_PRODUCT_DETAIL_REQUEST_SUCCESS: 'INV_PRODUCT_DETAIL_REQUEST_SUCCESS',
    INV_PRODUCT_DETAIL_REQUEST_ERROR: 'INV_PRODUCT_DETAIL_REQUEST_ERROR',

    LOGOUT: 'LOGOUT',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE'
}