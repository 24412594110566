import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../../utils/Utils";
import { addSubCategoryRequest, addSubCategoryRequestStatus, categoryListRequest } from '../../../../redux/category/category.action'
import { API_CONSTANTS } from "../../../../utils/AppConstants";
import Select from 'react-select'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";

const AddSubCategoryScreen = props => {
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [selectedCat, setSelectedCat] = useState()
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { categoryList, categoryListError, addSubCategorySuccess,
        addSubCategoryError, addSubCategoryStatus } = useSelector(state => state.category)

    useEffect(() => {
        const data = {
            businessId: user.data.userDetails.userId
        }
        dispatch(categoryListRequest(data))
    }, [])

    useEffect(() => {
        if (addSubCategoryStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addSubCategorySuccess.message)
            clearFields()
        } else if (addSubCategoryStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addSubCategoryError.message)
        }

        if (addSubCategoryStatus.length) {
            dispatch(addSubCategoryRequestStatus(''))
        }
    }, [addSubCategorySuccess, addSubCategoryError, addSubCategoryStatus])

    const clearFields = () => {
        setName('')
        setCode('')
        setSelectedCat()
        setImagePath(null)
        setImageName('')
    }

    const nameHandle = e => {
        const name = e.target.value
        setName(name)
        let nameUp = name.toUpperCase()
        nameUp = nameUp.replace(/[^a-zA-Z]/g, "")
        setCode(nameUp)
    }

    const codeHandle = e => {
        const name = e.target.value
        let nameUp = name.toUpperCase()
        nameUp = nameUp.replace(/[^a-zA-Z]/g, "")
        setCode(nameUp)
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    const handleSelectedCatChange = event => {
        setSelectedCat(event)
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Sub-category name cannot be blank')
        } else if (isEmpty(code)) {
            displayErrorToast('Sub-category code cannot be blank')
        } else if (isNull(selectedCat)) {
            displayErrorToast('Select category')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('categoryId', selectedCat.categoryId)
            fd.append('subCategoryCode', code)
            fd.append('name', name)
            fd.append('businessId', user.data.userDetails.userId)
            fd.append('productCode', user.data.userDetails.productCode)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            dispatch(addSubCategoryRequest(fd))
        }
    }

    return (
        <>
            <div className="add-sc-container">
                <ScreenLabel label='Add Subcategory' />
                <CommonForm>
                    <SubTextLabel label='Enter details to add new sub category' />
                    <div className="form-floating sc-input">
                        <input
                            type="name"
                            className="form-control sc-input-text"
                            id="name"
                            placeholder="Sub-Category Name"
                            value={name}
                            onChange={nameHandle}
                            required />
                        <label for="name">Name</label>
                    </div>
                    <div className="form-floating sc-input">
                        <input
                            type="name"
                            className="form-control sc-input-text"
                            id="name"
                            placeholder="Sub-Category Code"
                            value={code}
                            onChange={codeHandle}
                            required />
                        <label for="name">Subcategory Code</label>
                    </div>
                    {categoryList && categoryList.data && <Select
                        className='asc-marg-right'
                        value={selectedCat}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={categoryList.data}
                        placeholder='Select Category'
                        onChange={handleSelectedCatChange} />}
                    {!categoryList && <div>No Category found</div>}
                    <label className="sc-field-label">Image</label>
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <div className='sc-button-row'>
                        <button type='button' className='btn sc-button' onClick={() => submitHandler()}>Add Subcategory</button>
                    </div>

                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddSubCategoryScreen