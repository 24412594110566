import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { restaurantListRequest, addBranchRequest, addBranchRequestStatus, countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import Select from 'react-select'
import notificationTone from "../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../redux/socket/socket.action'
import CommonButton from "../../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import { CurrencyLabel } from "../../../../utils/CurrencyLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import ButtonRow from "../../../../components/button/button-row/buttonRow";

const AddLocationScreen = props => {
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [name, setName] = useState('')
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        addBranchSuccess, addBranchError, addBranchStatus,
        countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [paymentModeList, setPaymentModeList] = useState([])
    const [paymentMode, setPaymentMode] = useState('')

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        const data = {
            screen: 'ADD_LOCATION',
            screenClass: 'ADD_LOCATION'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        dispatch(countryListRequest())
        if (isLoggedInUserBusiness(user) === true) {
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(restaurantListRequest(data))
        }
    }, [])

    const fetchStateList = (countryId) => {
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (addBranchStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addBranchSuccess.message)
            clearFields()
        } else if (addBranchStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addBranchError.message)
        }

        if (addBranchStatus.length) {
            dispatch(addBranchRequestStatus(''))
        }
    }, [addBranchSuccess, addBranchError, addBranchStatus])

    const clearFields = () => {
        setName('')
        setPaymentModeList([])
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Location name cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Select restaurant')
        } else if (isNull(selectedCurrency)) {
            displayErrorToast('Price currency not selected')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Location country is not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Location state is not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Location state is not selected')
        } else {
            setLoading(true)
            const data = {
                name: name,
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                productCode: user.data.userDetails.productCode,
                currencyLabel: selectedCurrency.code,
                currencySymbol: selectedCurrency.symbol,
                currencyRegion: selectedCurrency.region,
                country: countryDropdown.name,
                state: stateDropdown.name,
                city: cityDropdown.name,
                countryCode: countryDropdown.iso2,
                stateCode: stateDropdown.iso2
            }
            // paymentMode: paymentModeList,
            dispatch(addBranchRequest(data))
        }
    }

    // const handleAddPaymentMode = () => {
    //     if (!isEmpty(paymentMode)) {
    //         paymentModeList.push(paymentMode)
    //         setPaymentMode('')
    //     } else {
    //         displayErrorToast('Payment mode cannot be blank')
    //     }
    // }

    return (
        <>
            <CommonScreenContent>
                <div className="mt-2">
                    <ScreenLabel label='Add Location' />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Enter details to add new restaurant location' />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Location Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        isRequired={true}
                    />
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div>No Restaurant found</div>}
                    <FormFieldLabel className='up-bold-label' label='Currency Information' />
                    {CurrencyLabel && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedCurrency}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setSelectedCurrency(e)
                        }} />}
                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e);
                            setCountryCode(e.iso2)
                            fetchStateList(e.iso2)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => submitHandler()} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddLocationScreen