import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { raiseTicketRequest, raiseTicketRequestStatus } from '../../../redux/request-ticket/request-ticket.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, screenCaptureAnalytics } from '../../../utils/Utils'
import { restaurantListRequest, branchListRequest } from '../../../redux/user/user.action'
import Select from 'react-select'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { RequestTicketType } from '../../../utils/RequestTicketType'
import CommonButton from "../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../components/common-form/commonForm";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/common-input-half/commonInputHalf";
import ButtonRow from "../../../components/button/button-row/buttonRow";

const RaiseRequestTicketScreen = props => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [contactPersonName, setContactPersonName] = useState('')
    const [contactPersonNumber, setContactPersonNumber] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { raiseTicketSuccess, raiseTicketError, raiseTicketReqStatus } = useSelector(state => state.requestTicket)
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [selectedPriority, setSelectedPriority] = useState()
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')

    useEffect(() => {
        const data = {
            screen: 'RAISE_REQUEST_TICKET',
            screenClass: 'RAISE_REQUEST_TICKET'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            setLoading(true)
            const data1 = {
                userId: user.data.userDetails.userId
            }
            dispatch(restaurantListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (raiseTicketReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(raiseTicketSuccess.message)
            clearFields()
        } else if (raiseTicketReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(raiseTicketError.message)
        }

        if (raiseTicketReqStatus.length) {
            dispatch(raiseTicketRequestStatus(''))
        }
    }, [raiseTicketSuccess, raiseTicketError, raiseTicketReqStatus])

    const clearFields = () => {
        setTitle('')
        setDescription('')
        setContactPersonName('')
        setContactPersonNumber()
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    const submitHandler = () => {
        if (isEmpty(title)) {
            displayErrorToast('Title cannot be blank')
        } else if (isEmpty(description)) {
            displayErrorToast('Description cannot be blank')
        } else if (isEmpty(selectedPriority)) {
            displayErrorToast('Ticket priority not selected')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('title', title)
            fd.append('description', description)
            fd.append('businessId', isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant)
            fd.append('locationId', isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation)
            fd.append('productCode', user.data.userDetails.productCode)
            fd.append('priority', selectedPriority.key)
            if (!isNull(imagePath) && !isEmpty(imageName)) {
                fd.append('image', imagePath, imageName)
            }
            if (!isEmpty(contactPersonName))
                fd.append('contactPersonName', contactPersonName)
            if (!isNull(contactPersonNumber)) {
                const mobileNum = contactPersonNumber.replace('+', "")
                fd.append('contactPersonNumber', mobileNum)
            }
            dispatch(raiseTicketRequest(fd))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Raise Ticket' />
                </div>
                <CommonColorForm>
                    <SubTextLabel label='Enter details to raise new request ticket' />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        isRequired={true}
                    />
                    <CommonInputFull
                        type="name"
                        id="name"
                        placeholder="Description"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        isRequired={true}
                    />
                    <CommonInputRow>
                        <CommonInputHalf
                            type="name"
                            id="name"
                            placeholder="Contact Person Name"
                            value={contactPersonName}
                            onChange={e => setContactPersonName(e.target.value)}
                        />
                        <div className="arqt-input-half">
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={contactPersonNumber}
                                onChange={setContactPersonNumber} />
                        </div>
                    </CommonInputRow>
                    <div className="arqt-select-heading">Select Ticket Priority</div>
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedPriority}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={RequestTicketType}
                        placeholder='Select Priority'
                        onChange={e => setSelectedPriority(e)} />
                    {isLoggedInUserBusiness(user) === true && <div className="arqt-select-heading mt-2">Select Restaurant</div>}
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="arqt-text">No restaurant found</div>}
                    {isLoggedInUserBusiness(user) === true && <div className="arqt-select-heading mt-2">Select Location</div>}
                    {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="arqt-text">No location found</div>}
                    <label className="arqt-select-heading mt-2">Additional Image</label>
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => submitHandler()} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default RaiseRequestTicketScreen