import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function restaurantAccessRegisterAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.branch.restaurantAccessRegister}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function userRegistrationAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.userRegistration}`,
        data: payload
    })
}

export function loginAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.signIn}`,
        data: payload
    })
}

export function userDetail(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.userDetail}/${payload.userId}`
    })
}

export function supportRegisterAPI(data) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.supportRegistration}`,
        data: data
    })
}

export function registerAPI(data) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.signUp}`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export function customerLoginAPI(data) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.customerLogin}`,
        data: data
    })
}

export function addSubcriptionAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.addSubscription}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function subscriptionList() {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.subscriptionList}`
    })
}

export function updateCustomerDetailsAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateCustomerDetails}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function operatorRegistrationAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.operatorRegistration}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid,
            "Content-Type": "multipart/form-data"
        }
    })
}

export function operatorList() {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.operatorList}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function operatorDetail(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.operatorDetail}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateOperatorLocations(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateOperatorLocation}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function branchList(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.branch.branchList}/${data.businessId}`,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function branchDetail(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.branch.branchDetail}/${data}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function restaurantList(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.restaurant.restaurantList}/${data.userId}`,
        params: data.payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function addRestaurant(data) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.restaurant.addRestaurant}`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export function updateRestaurantDetails(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.restaurant.updateRestaurantDetails}`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function businessListByLocation(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.restaurant.businessListByLocation}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateBusinessOrderingStatus(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.restaurant.updateBusinessOrderingStatus}`,
        data: data,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function addBranch(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.branch.addBranch}`,
        data: data,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateBranch(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.branch.updateBranch}`,
        data: data,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateBranchOrderingStatus(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.branch.updateLocationOrderingStatus}`,
        data: data,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateUserNotificationToken(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateNotificationToken}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function restaurantDetail(data) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.restaurant.restaurantDetail}/${data.businessId}`
    })
}

export function forgotPassword(data) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.forgotPassword}`,
        data: data
    })
}

export function changePassword(data) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.changePassword}`,
        data: data
    })
}

export function countryListApi() {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function stateListApi(data) {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}/${data.countryId}/${API_ENDPOINTS.location.stateList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function cityListApi(data) {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}/${data.countryId}/${API_ENDPOINTS.location.stateList}/${data.stateId}/${API_ENDPOINTS.location.cityList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function agentLogin(data) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.agent.signIn}`,
        data: data
    })
}

export function businessesList(data) {
    return axios.request({
        method: 'GET',
        url: `${config.baseUrl}/${API_ENDPOINTS.agent.businessList}/${data.userId}`,
        params: data.params
    })
}

export function loginTerminal(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.deliveryTerminal.terminalLogin}`,
        data: payload
    })
}

export function loginEmployee(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.employee.employeeLogin}`,
        data: payload
    })
}

export function ipLocation() {
    return axios.request({
        method: 'get',
        url: 'https://ipapi.co/json/'
    })
}

export function registerBusinessUserAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.registerBusinessUser}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function mapUserWithBusinessAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.mapUserWithBusiness}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function businessUserListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.businessUserList}`,
        params: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}