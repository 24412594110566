import React, { useEffect, useState } from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import NavBarComponent from "../../home-page/navbar-component/navBarComponent";
import ScreenFooter from "../../home-page/screen-footer/screenFooter";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    feautreRequestDetailReq, featureRequestAddCommentReq, featureRequestAddCommentReqStatusReq
} from '../../../redux/feature-request/featureRequest.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import moment from "moment";
import { useLocation } from 'react-router-dom';
import CommonForm from "../../../components/common-form/commonForm";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import CommonButton from "../../../components/button/common-button/commonButton";

const FeatureRequestDetail = props => {
    let navigate = useNavigate();
    const { state } = useLocation()
    const { featureRequestDetailSuccess, featureRequestDetailError,
        featureRequestAddCommentSuccess, featureRequestAddCommentError,
        featureRequestAddCommentReqStatus } = useSelector(state => state.featureRequest)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [senderName, setSenderName] = useState('')
    const [comment, setComment] = useState('')

    useEffect(() => {
        fetchFeatureDetail()
    }, [])

    const fetchFeatureDetail = () => {
        setLoading(true)
        dispatch(feautreRequestDetailReq(state))
    }

    useEffect(() => {
        if (!isNull(featureRequestDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(featureRequestDetailError)) {
            setLoading(false)
        }
    }, [featureRequestDetailSuccess, featureRequestDetailError])

    useEffect(() => {
        if (featureRequestAddCommentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(featureRequestAddCommentSuccess.message)
            fetchFeatureDetail()
            setSenderName('')
            setComment('')
        } else if (featureRequestAddCommentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(featureRequestAddCommentError.message)
        }
        if (featureRequestAddCommentReqStatus.length) {
            dispatch(featureRequestAddCommentReqStatusReq(''))
        }
    }, [featureRequestAddCommentSuccess, featureRequestAddCommentError,
        featureRequestAddCommentReqStatus])

    const handleSubmitClick = () => {
        if (isEmpty(senderName)) {
            displayErrorToast('Name cannot be blank')
        } else if (isEmpty(comment)) {
            displayErrorToast('Comment cannot be blank')
        } else {
            setLoading(true)
            const data = {
                featureRequestId: state,
                senderName: senderName,
                message: comment
            }
            dispatch(featureRequestAddCommentReq(data))
        }
    }

    return (
        <>
            <header className="contact-hero">
                <div className="content">
                    <div className="contact-label-container">
                        <div className="contact-label">Feature Request Detail</div>
                        <div className="h-line-white" />
                    </div>
                    <div className="have-question-label">Customer suggestions detail.</div>
                </div>

            </header>
            {featureRequestDetailSuccess && featureRequestDetailSuccess.data && <div className="frd-content-container">
                <div className="fr-date-label">{moment(featureRequestDetailSuccess.data.createdOn).utc().format('ddd DD, MMM YYYY')}</div>
                <div className="frd-vote-label">{featureRequestDetailSuccess.data.voteCount} {featureRequestDetailSuccess.data.voteCount > 1 ? 'Votes' : 'Vote'}</div>
                <div className="frd-title">{featureRequestDetailSuccess.data.title}</div>
                <div className="frd-description">{featureRequestDetailSuccess.data.description}</div>
                {featureRequestDetailSuccess.data.comments.length === 0 && <div className="frd-no-comment-label">No comments available</div>}
                {featureRequestDetailSuccess.data.comments.length > 0 && <div>
                    <div className="frd-comments-label">Comments</div>
                    {featureRequestDetailSuccess.data.comments.map(element => (
                        <div className="frd-card-container">
                            <div className="fr-date-label">{moment(element.submittedDate).utc().format('ddd DD, MMM YYYY')}</div>
                            <div className="frd-comment-message">{element.message}</div>
                            <div className="frd-comment-message-name">By: {element.senderName}</div>


                        </div>
                    ))}
                </div>}

                <CommonForm>
                    <SubTextLabel label='Add new comment' />
                    <CommonInputFull id='name'
                        onChange={e => setSenderName(e.target.value)}
                        type='text'
                        placeholder='Name'
                        value={senderName}
                    />
                    <CommonInputFull id='message'
                        onChange={e => setComment(e.target.value)}
                        type='text'
                        placeholder='Comment'
                        value={comment}
                    />
                    <div className='su-button-row mb-2'>
                        <CommonButton label='Submit'
                            onClick={() => handleSubmitClick()} />
                    </div>
                </CommonForm>
            </div>}
            {featureRequestDetailError && <div className="fr-error-lable">{featureRequestDetailError.message}</div>}
            <ScreenFooter navigate={navigate} />
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default FeatureRequestDetail