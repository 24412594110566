import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog,
    displayCurrencySymbolLeft,
    displayErrorToast, displaySuccessToast, getProductMeasurmentUnitOrder, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, priceWithSymbol, screenCaptureAnalytics
} from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { productListRequest, productStatusUpdateRequest, productStatusUpdateRequestStatus } from '../../../../redux/product/product.action'
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import { config } from '../../../../config'
import Select from 'react-select'
import notificationTone from "../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../redux/socket/socket.action'
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import { PRODUCT_MEASUREMENT_UNIT_LIST } from "../../../../utils/ReservationType";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";

const ProductListScreen = props => {
    const { productListSuccess, productListError, productStatusUpdateSuccess,
        productStatusUpdateError, productStatusUpdateStatus } = useSelector(state => state.product)
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    const PRODUCT_LIST = 'PRODUCT_LIST'
    const ADD_PRODUCT = 'ADD_PRODUCT'
    const PRODUCT_DETAIL = 'PRODUCT_DETAIL'

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        const data = {
            screen: 'PRODUCT_LIST',
            screenClass: 'PRODUCT_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.PRODUCT_LIST, true)
            isFirstTime = true
            setLoading(true)
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.PRODUCT_LIST) === 'true') {
                isFirstTime = false
                sessionStorage.setItem(APP_CONSTANTS.PRODUCT_LIST, false)
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedLocation)) {
            fetchProductList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchProductList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const fetchProductList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    productCode: user.data.userDetails.productCode,
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(productListRequest(data))
            } else {
                displayErrorToast('Restaurant and location not selected')
            }
        } else {
            setLoading(true)
            const data = {
                productCode: user.data.userDetails.productCode,
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(productListRequest(data))
        }
    }

    useEffect(() => {
        if (!isNull(productListSuccess)) {
            setLoading(false)
        } else if (!isNull(productListError)) {
            setLoading(false)
        }
    }, [productListSuccess, productListError])

    const addProductClickHandle = () => {
        if (checkFunctionalityPermission(ADD_PRODUCT) === true) {
            navigate(ROUTES.addProduct)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const enableClickHandle = item => {
        if (checkFunctionalityPermission(PRODUCT_DETAIL) === true) {
            setLoading(true)
            const data = {
                itemId: item.itemId,
                status: 1
            }
            dispatch(productStatusUpdateRequest(data))
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }
    const disableClickHandle = item => {
        if (checkFunctionalityPermission(PRODUCT_DETAIL) === true) {
            setLoading(true)
            const data = {
                itemId: item.itemId,
                status: 0
            }
            dispatch(productStatusUpdateRequest(data))
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleCardClick = element => {
        if (checkFunctionalityPermission(PRODUCT_DETAIL) === true) {
            displayConsoleLog('--e', element.itemId)
            displayConsoleLog('--ee', element)
            navigate(ROUTES.updateProduct, { state: element.itemId });
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    useEffect(() => {
        if (productStatusUpdateStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(productStatusUpdateSuccess.message)
            fetchProductList()
        } else if (productStatusUpdateStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(productStatusUpdateError.message)
        }

        if (productStatusUpdateStatus.length) {
            dispatch(productStatusUpdateRequestStatus(''))
        }
    }, [productStatusUpdateSuccess, productStatusUpdateError, productStatusUpdateStatus])

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.PRODUCT && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === PRODUCT_LIST) {
                    if (itemObj.productList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === PRODUCT_DETAIL) {
                    if (itemObj.editProduct === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === ADD_PRODUCT) {
                    if (itemObj.addProduct === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    const handleViewCategories = () => {
        navigate(ROUTES.categoryList)
    }

    const getServingValue = (key) => {
        if (isEmpty(key)) {
            return '-'
        }
        const result = PRODUCT_MEASUREMENT_UNIT_LIST.find(e => e.key === key)
        if (!isNull(result)) {
            return result.value
        } else return '-'
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow classname="margin-top-10">
                    <ScreenLabel label='Menu Items' />
                    <TopButton
                        label='Add Product'
                        isInverted={true}
                        onClick={() => addProductClickHandle()} />
                </CommonInputRow>
                <div className="add-p-btn-container">
                    <TopButton
                        label='View Categories'
                        onClick={() => handleViewCategories()} />
                </div>
                {isLoggedInUserBusiness(user) === true &&
                    <CommonInputRow className='margin-top-10'>
                        <div className="p-list-select-container">
                            <div className="p-list-select-heading">Select Restaurant</div>
                            {restuarantListSuccess && restuarantListSuccess.data && <Select
                                className='select-full-width-no-border'
                                value={selectedRestaurant}
                                getOptionLabel={e => e.businessName}
                                getOptionValue={e => e}
                                options={restuarantListSuccess.data}
                                placeholder='Select Restaurant'
                                onChange={e => setSelectedRestaurant(e)} />}
                            {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                        </div>
                        <div className="p-list-select-container">
                            <div className="p-list-select-heading">Select Location</div>
                            {branchListSuccess && branchListSuccess.data && <Select
                                className='select-full-width-no-border'
                                value={selectedLocation}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={branchListSuccess.data}
                                placeholder='Select Location'
                                onChange={e => setSelectedLocation(e)} />}
                            {!branchListSuccess && <div className="p-text">No location found</div>}
                        </div>
                    </CommonInputRow>}
                {checkFunctionalityPermission(PRODUCT_LIST) === true && productListSuccess && productListSuccess.data &&
                    <div className="p-price-warning mt-4">*Price including tax and discount</div>
                }
                {checkFunctionalityPermission(PRODUCT_LIST) === true && <div className="table-scroll-container">
                    {productListSuccess && productListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Image</th>
                                <th scope="col" className="rl-table-col-title text-center">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Category</th>
                                <th scope="col" className="rl-table-col-title text-center">Price</th>
                                <th scope="col" className="rl-table-col-title text-center">Option Based Price</th>
                                <th scope="col" className="rl-table-col-title text-center">Serving</th>
                                <th scope="col" className="rl-table-col-title text-center">Availability</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">
                                        <div className="p-image-container">
                                            <img src={`${config.imageUrl}${element.image}`} className="img" alt="..." />
                                        </div>
                                    </td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.name}</td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.categoryDetails.name}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        {!element.isOptionBasedPricing && <div>{priceWithSymbol(element, element.finalPrice)}</div>}
                                        {element.isOptionBasedPricing && <div>
                                            {element.optionBasedPricing.map(e => (
                                                <div>{e.label} - {priceWithSymbol(element, e.price)}</div>
                                            ))}
                                        </div>}
                                    </td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.isOptionBasedPricing ? 'Yes' : 'No'}</td>
                                    <td className="rl-table-col-item p-item-container text-center">{getProductMeasurmentUnitOrder(element.measurementQuantity, element.measurementUnit)}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        <div className="p-availability-item-container">
                                            {element.isBreakfast === true && <div className="p-availability-container">B</div>}
                                            {element.isLunch === true && <div className="p-availability-container">L</div>}
                                            {element.isDinner === true && <div className="p-availability-container">D</div>}
                                            {element.is24Hour === true && <div className="p-availability-container">24h</div>}
                                        </div>
                                    </td>
                                    {/* <td className="rl-table-col-item text-center">{element.locationList ? element.locationList.length : 0}</td> */}
                                    <td className={`rl-table-col-item p-item-container text-center ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        <div className="d-flex flex-row justify-content-evenly align-items-center">
                                            <div className="btn p-card-btn" onClick={() => handleCardClick(element)}>Detail</div> {element.status === 1 ?
                                                (<div className="btn p-card-btn" onClick={() => disableClickHandle(element)}>Disable</div>) :
                                                (<div className="btn p-card-btn" onClick={() => enableClickHandle(element)}>Enable</div>)}
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === true && productListError && <div className="content-error">No Product Found</div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === false && <div className="content-error">You are not authorized to view products list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ProductListScreen