const API_ENDPOINTS = {
    account: {
        signIn: 'user/login',
        signUp: 'user/businessRegistration',
        customerLogin: 'customer/login',
        forgotPassword: 'user/forgotPassword',
        changePassword: 'user/changePassword',
        subscriptionList: 'subscription/list',
        userDetail: 'user/detail',
        supportRegistration: 'user/supportRegistration',
        userRegistration: 'user/userRegistration',
        updateNotificationToken: 'user/updateNotificationToken',
        operatorRegistration: 'user/operatorRegistration',
        operatorList: 'user/operatorList',
        operatorDetail: 'user/operatorDetail',
        updateOperatorLocation: 'user/updateOperatorLocation',
        registerBusinessUser: 'user/registerBusinessUser',
        businessUserList: 'user/businessUserList',
        mapUserWithBusiness: 'user/mapUserWithBusiness',
        updateCustomerDetails: 'user/updateCustomerDetails',
        addSubscription: 'subscription/add'
    },
    branch: {
        branchList: 'location/list',
        addBranch: 'location/addLocation',
        updateBranch: 'location/update',
        branchDetail: 'location/detail',
        restaurantAccessRegister: 'user/createRestaurantAccess',
        updateLocationOrderingStatus: 'location/updateOrderingStatus'
    },
    category: {
        addCategory: 'item/addCategory',
        categoryList: 'item/categoryList'
    },
    subCategory: {
        addSubCategory: 'item/addSubCategory',
        subCategoryList: 'item/subCategoryList'
    },
    product: {
        addProduct: 'item/addFoodItem',
        productList: 'item/foodItemList',
        updateProductStatus: 'item/updateFoodItemStatus',
        productDetail: 'item/foodItemDetail',
        updateProduct: 'item/updateFoodItem',
        addFeedback: 'item/addFeedback',
        bulkUploadMenu: 'item/updateMenu'
    },
    order: {
        placeOrder: 'order/placeOrder',
        orderList: 'order/list',
        updateStatus: 'order/updateStatus',
        orderDetail: 'order/detail',
        updateTransactionStatus: 'order/updateTransactionStatus',
        acceptOrRejectOrder: 'order/acceptRejectOrder',
        dashboard: 'user/orderDashboard',
        orderPaymentLinkCreate: 'order/orderPaymentLinkCreate',
        updateExistingOrder: 'order/updateExistingOrder',
        acceptPayAtEndPayment: 'order/acceptPayAtEndPayment',
        findPayAtEndOrder: 'order/findPayAtEndOrder',
        updateCustomerAction: 'order/updateCustomerAction'
    },
    restaurant: {
        addRestaurant: 'user/addNewBusiness',
        restaurantList: 'user/userBusinessList',
        restaurantDetail: 'user/businessDetail',
        businessListByLocation: 'user/businessListByLocation',
        updateBusinessOrderingStatus: 'user/updateBusinessOrdering',
        updateRestaurantDetails: 'user/updateBusinessDetails'
    },
    location: {
        countryList: 'https://api.countrystatecity.in/v1/countries',
        stateList: 'states',
        cityList: 'cities'
    },
    agent: {
        signIn: 'user/agentRegistration',
        businessList: 'business/list'
    },
    employee: {
        addEmployee: 'employee/addEmployee',
        employeeList: 'employee/employeeList',
        employeeDetail: 'employee/detail',
        removeEmployee: 'employee/removeEmployee',
        employeeLogin: 'employee/login'
    },
    deliveryTerminal: {
        addTerminal: 'terminal/addTerminal',
        terminalLogin: 'terminal/loginTerminal',
        terminalList: 'terminal/terminalList',
        updateTerminalStatus: 'terminal/updateStatus'
    },
    reservation: {
        placeReservation: 'reservation/placeReservation',
        reservationList: 'reservation/reservationList',
        reservationDetail: 'reservation/reservationDetail',
        reservationUpdateStatus: 'reservation/updateStatus'
    },
    requestTicket: {
        raiseTicket: 'ticket/raiseTicket',
        ticketList: 'ticket/ticketList',
        ticketDetail: 'ticket/ticketDetail',
        updateTicket: 'ticket/updateDetail'
    },
    contactUs: {
        addRecord: 'contactUs/addRecord',
        recordList: 'contactUs/recordList',
        recordDetail: 'contactUs/recordDetail',
        updateStatus: 'contactUs/updateStatus'
    },
    featureRequest: {
        addRequest: 'featureRequest/addRequest',
        requestList: 'featureRequest/list',
        requestDetail: 'featureRequest/detail',
        addComment: 'featureRequest/addComment',
        upVote: 'featureRequest/upVote'
    },
    inventory: {
        category: {
            addCategory: 'inventory/category/addCategory',
            categoryList: 'inventory/category/categoryList',
            updateCategory: 'inventory/category/updateCategory'
        },
        product: {
            addProduct: 'inventory/product/addProduct',
            productList: 'inventory/product/productList',
            updateProduct: 'inventory/product/updateProduct',
            updateProductConsumption: 'inventory/product/updateProductConsumption',
            productDetails: 'inventory/product/productDetails'
        },
        seller: {
            addSeller: 'inventory/seller/addSeller',
            sellerList: 'inventory/seller/sellerList',
            updateSellerDetails: 'inventory/seller/updateSeller'
        },
        orderInvoice: {
            addOrderInvoice: 'inventory/orderInvoice/addOrderInvoice',
            orderInvoiceList: 'inventory/orderInvoice/orderInvoiceList',
            orderInvoiceDetail: 'inventory/orderInvoice/orderInvoiceDetail',
            updateInvoicePaymentDetails: 'inventory/orderInvoice/updateInvoicePaymentDetails'
        }
    }
}

export default API_ENDPOINTS