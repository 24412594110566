import { takeLatest, call, put } from 'redux-saga/effects'
import {
    acceptRejectOrderAPI, orderDetailAPI, orderListAPI, placeOrderAPI,
    updateOrderStatusAPI, updateOrderTransactionAPI, orderDashboardAPI,
    orderGeneratePaymentLinkAPI, updateExistingOrderAPI, acceptPayAtEndPaymentAPI,
    findPayAtEndOrderAPI, updateCustomerActionAPI
} from '../api-requests/order';
import {
    acceptRejectOrderRequestError, acceptRejectOrderRequestSuccess, orderDetailRequestError,
    orderDetailRequestSuccess, orderListRequestSuccess, orderListRequestError,
    placeOrderRequestError, placeOrderRequestSuccess, updateOrderStatusRequestError,
    updateOrderStatusRequestSuccess, updateOrderTransactionRequestError, updateOrderTransactionRequestSuccess,
    orderDashboardRequestSuccess, orderDashboardRequestError, orderGeneratePaymentLinkRequestSuccess,
    orderGeneratePaymentLinkRequestError, updateExistingOrderRequestError,
    updateExistingOrderRequestSuccess, acceptPayAtEndPaymentRequestError, acceptPayAtEndPaymentRequestSuccess,
    findPayAtEndOrderRequestError, findPayAtEndOrderRequestSuccess, updateCustomerActionRequestError,
    updateCustomerActionRequestSuccess
} from './order.action';
import { OrderActionTypes } from './order.types'
import { displayConsoleLog, isNull } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleUpdateCustomerAction({ payload }) {
    try {
        const response = yield call(updateCustomerActionAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateCustomerActionRequestSuccess(decryptedData))
            } else {
                yield put(updateCustomerActionRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateCustomerActionRequestError(error.response.data))
    }
}

export function* updateCustomerActionReq() {
    yield takeLatest(OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST, handleUpdateCustomerAction)
}

function* handleFindPayAtEndOrder({ payload }) {
    try {
        const response = yield call(findPayAtEndOrderAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(findPayAtEndOrderRequestSuccess(decryptedData))
            } else {
                yield put(findPayAtEndOrderRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(findPayAtEndOrderRequestError(error.response.data))
    }
}

export function* findPayAtEndOrderReq() {
    yield takeLatest(OrderActionTypes.FIND_PAY_AT_END_ORDER_REQUEST, handleFindPayAtEndOrder)
}

function* handleAcceptPayAtEndPayment({ payload }) {
    try {
        const response = yield call(acceptPayAtEndPaymentAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(acceptPayAtEndPaymentRequestSuccess(decryptedData))
            } else {
                yield put(acceptPayAtEndPaymentRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(acceptPayAtEndPaymentRequestError(error.response.data))
    }
}

export function* acceptPayAtEndPaymentReq() {
    yield takeLatest(OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST, handleAcceptPayAtEndPayment)
}

function* handleUpdateExistingOrder({ payload }) {
    try {
        const response = yield call(updateExistingOrderAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateExistingOrderRequestSuccess(decryptedData))
            } else {
                yield put(updateExistingOrderRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateExistingOrderRequestError(error.response.data))
    }
}

export function* updateExistingOrderReq() {
    yield takeLatest(OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST, handleUpdateExistingOrder)
}

function* handleOrderGeneratePaymentLink({ payload }) {
    try {
        const response = yield call(orderGeneratePaymentLinkAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(orderGeneratePaymentLinkRequestSuccess(decryptedData))
            } else {
                yield put(orderGeneratePaymentLinkRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(orderGeneratePaymentLinkRequestError(error.response.data))
    }
}

export function* orderGeneratePaymentLinkReq() {
    yield takeLatest(OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST, handleOrderGeneratePaymentLink)
}

function* handleOrderDashboard({ payload }) {
    try {
        const response = yield call(orderDashboardAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(orderDashboardRequestSuccess(decryptedData))
            } else {
                yield put(orderDashboardRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(orderDashboardRequestError(error.response.data))
    }
}

export function* orderDashboardReq() {
    yield takeLatest(OrderActionTypes.ORDER_DASHBOARD_REQUEST, handleOrderDashboard)
}

function* handleUpdateOrderTransaction({ payload }) {
    try {
        const response = yield call(updateOrderTransactionAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateOrderTransactionRequestSuccess(decryptedData))
            } else {
                yield put(updateOrderTransactionRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateOrderTransactionRequestError(error.response.data))
    }
}

export function* updateOrderTransactionReq() {
    yield takeLatest(OrderActionTypes.UPDATE_TRANSACTION_REQUEST, handleUpdateOrderTransaction)
}

function* handleAcceptRejectOrder({ payload }) {
    try {
        const response = yield call(acceptRejectOrderAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(acceptRejectOrderRequestSuccess(decryptedData))
            } else {
                yield put(acceptRejectOrderRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(acceptRejectOrderRequestError(error.response.data))
    }
}

export function* acceptRejectOrderReq() {
    yield takeLatest(OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST, handleAcceptRejectOrder)
}

function* handleUpdateOrderStatus({ payload }) {
    try {
        const response = yield call(updateOrderStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateOrderStatusRequestSuccess(decryptedData))
            } else {
                yield put(updateOrderStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateOrderStatusRequestError(error.response.data))
    }
}

export function* updateOrderStatusReq() {
    yield takeLatest(OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST, handleUpdateOrderStatus)
}

function* handleOrderDetail({ payload }) {
    try {
        const response = yield call(orderDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(orderDetailRequestSuccess(decryptedData))
            } else {
                yield put(orderDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(orderDetailRequestError(error.response.data))
    }
}

export function* orderDetailReq() {
    yield takeLatest(OrderActionTypes.ORDER_DETAIL_REQUEST, handleOrderDetail)
}

function* handleOrderList({ payload }) {
    try {
        const response = yield call(orderListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(orderListRequestSuccess(decryptedData))
            } else {
                yield put(orderListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error) && !isNull(error.response)) {
            displayConsoleLog(error.response.data)
            yield put(orderListRequestError(error.response.data))
        } else {
            const data = {
                message: "Error while fetching order list. Please try again"
            }
            yield put(orderListRequestError(data))
        }
    }
}

export function* orderListReq() {
    yield takeLatest(OrderActionTypes.ORDER_LIST_REQUEST, handleOrderList)
}

function* handlePlaceOrder({ payload }) {
    try {
        const response = yield call(placeOrderAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(placeOrderRequestSuccess(decryptedData))
            } else {
                yield put(placeOrderRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(placeOrderRequestError(error.response.data))
    }
}

export function* placeOrderReq() {
    yield takeLatest(OrderActionTypes.PLACE_ORDER_REQUEST, handlePlaceOrder)
}