import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import {
    orderGeneratePaymentLinkRequest, orderGeneratePaymentLinkRequestStatus,
    updateOrderStatusRequest, updateOrderStatusRequestStatus
} from '../../../redux/order/order.action'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { useNavigate } from 'react-router-dom';
import { config } from "../../../config";
import { SocketContext } from '../../../SocketCtx'

let stripeAccountId = ''
const OrderPaymentCancelScreen = props => {
    const dispatch = useDispatch()
    const { orderNumberId, orderNumber, orderRestaurantId, customerLoginSuccess, restaurantDetailSuccess, locationId, restaurantId } = useSelector(state => state.user)
    const { orderGeneratePaymentLinkReqSuccess, orderGeneratePaymentLinkReqError, orderGeneratePaymentLinkReqStatus,
        updateOrderSuccess, updateOrderError, updateOrderStatus } = useSelector(state => state.order)
    const [isLoading, setLoading] = useState(false)
    const socket = useContext(SocketContext)
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    let navigate = useNavigate();
    const [paymentModeOffline, setPaymentModeOffline] = useState(false)
    const [displayOfflinePaymentMode, setDisplayOfflinePaymentMode] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_ORDER_PAYMENT_CANCEL',
            screenClass: 'CUSTOMER_ORDER_PAYMENT_CANCEL'
        }
        screenCaptureAnalytics(data)
    }, [])

    const isOnGoingOrder = () => {
        if (!isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID))) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) {
            if (restaurantDetailSuccess.data.orderPaymentModes) {
                const onlinePG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
                if (!isNull(onlinePG)) {
                    const stripePG = onlinePG.optionType.find(b => b.type === APP_CONSTANTS.STRIPE)
                    if (!isNull(stripePG)) {
                        stripeAccountId = stripePG.accountId
                    }
                }

                const cashPG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE)
                if (!isNull(cashPG)) {
                    setDisplayOfflinePaymentMode(true)
                }
            }
        }
    }, [])

    const handlePayOfflineClick = () => {
        if (isOnGoingOrder()) {
            const data = {
                restaurantId: orderRestaurantId,
                message: `Kindly collect order payment for order number ${localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_NUMBER) ? localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_NUMBER) : ''}`
            }
            socket.emit('collect-order-payment', data)
            displaySuccessToast('Notified on desk for payment. Someone will be attending you soon')
            setTimeout(() => {
                navigate(`${ROUTES.menuCatList}/${restaurantId}/${locationId}`, { replace: true });
            }, 1000)
        } else {
            const apiData = {
                orderId: orderNumberId,
                orderStatus: APP_CONSTANTS.ORDER_STATUS.PLACED,
                paymentMode: APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE
            }
            setLoading(true)
            dispatch(updateOrderStatusRequest(apiData))
        }
    }

    useEffect(() => {
        if (updateOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            const data = {
                orderNumber: orderNumber,
                restaurantId: orderRestaurantId
            }
            socket.emit('order-placed-success', data)
            setPaymentSuccess(true)
            setTimeout(() => {
                navigate(`${ROUTES.menuCatList}/${restaurantId}/${locationId}`, { replace: true });
            }, 1000)
        } else if (updateOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
        }

        if (updateOrderStatus.length) {
            dispatch(updateOrderStatusRequestStatus(''))
        }
    }, [updateOrderSuccess, updateOrderError, updateOrderStatus])

    useEffect(() => {
        if (orderGeneratePaymentLinkReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            window.open(orderGeneratePaymentLinkReqSuccess.data.onlinePaymentLink, '_self')
        } else if (orderGeneratePaymentLinkReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
        }

        if (orderGeneratePaymentLinkReqStatus.length) {
            dispatch(orderGeneratePaymentLinkRequestStatus(''))
        }
    }, [orderGeneratePaymentLinkReqSuccess, orderGeneratePaymentLinkReqError, orderGeneratePaymentLinkReqStatus])

    const handleTryAgainClick = () => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && !isEmpty(restaurantDetailSuccess.data.orderPaymentModes)) {
            const onlinePG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
            if (!isNull(onlinePG)) {
                const stripePG = onlinePG.optionType.find(b => b.type === APP_CONSTANTS.STRIPE)
                if (!isNull(stripePG)) {
                    setLoading(true)
                    const data = {
                        successUrl: `${config.clientUrl}${ROUTES.orderPaymentSuccessUrl}`,
                        cancelUrl: `${config.clientUrl}${ROUTES.orderPaymentCancelUrl}`,
                        paymentMode: APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE,
                        paymentModeName: stripePG.type,
                        stripeAccountId: stripeAccountId
                    }
                    if (isOnGoingOrder()) {
                        data.orderId = localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)
                    } else {
                        data.orderId = orderNumberId
                    }
                    dispatch(orderGeneratePaymentLinkRequest(data))
                } else {
                    displayErrorToast('Error occurred while fetching online payment mode details.')
                }
            } else {
                displayErrorToast('Error occurred while fetching online payment mode details.')
            }
        } else {
            displayErrorToast('Unable to fetch online payment mode')
        }
    }

    const getPaymentModelLabel = type => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && !isEmpty(restaurantDetailSuccess.data.orderPaymentModes)) {
            const gatewayName = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === type)
            if (!isNull(gatewayName)) {
                return gatewayName.label ? gatewayName.label : gatewayName.gatewayType
            } else {
                return ''
            }
        } else return ''
    }

    return (
        <>
            <div className="opc-container mb-4">
                {paymentSuccess === false ? (<div className="opc-modal-img-container">
                    <img className="add-c-img" src={require('../../../assets/warning_icon.png')} alt="warning" />
                </div>) : (<div className="opc-modal-img-container">
                    <img className="add-c-img" src={require('../../../assets/success_icon.png')} alt="success" />
                </div>)}
                {paymentSuccess === false ? (<div className="opc-modal-order-success">Payment Cancelled</div>) : (
                    <div className="opc-modal-order-success">Order Payment Success</div>
                )}
                {paymentSuccess === false ? (<div className="opc-modal-order-subtext">Your order payment with Stripe is cancelled. Do you want to try again or continue with other payment mode.</div>) : (
                    <div className="opc-modal-order-subtext">Your order payment is success and order status will be updated by restaurant shortly.</div>
                )}
                {paymentSuccess === true ? (<div className="ops-modal-order-number">Order Number</div>) : null}
                {paymentSuccess === true ? (<div className="ops-modal-order-number ops-modal-order-number-val">
                    {orderNumber ? orderNumber : ''}
                </div>) : null}
                {paymentSuccess === true ? (<div className="ops-modal-order-subtext">Redirecting to menu screen.</div>) : null}
                {paymentSuccess === false ? (<div className="opc-try-again-btn" onClick={() => { handleTryAgainClick() }}>Try Again</div>) : null}
                {paymentSuccess === false && displayOfflinePaymentMode === true ? (<div className="opc-close-btn" onClick={() => { handlePayOfflineClick() }}>{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE)}</div>) : null}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OrderPaymentCancelScreen