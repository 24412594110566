import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addCategoryRequest, addCategoryRequestStatus } from '../../../../redux/category/category.action'
import { API_CONSTANTS } from "../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../components/common-form/commonForm";

const AddCategoryScreen = props => {
    const [categoryName, setCategoryName] = useState('')
    const [categoryCode, setCategoryCode] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { addCategorySuccess, addCategoryError, addCategoryStatus } = useSelector(state => state.category)

    useEffect(() => {
        const data = {
            screen: 'ADD_CATEGORY',
            screenClass: 'ADD_CATEGORY'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (addCategoryStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addCategorySuccess.message)
            clearFields()
        } else if (addCategoryStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addCategoryError.message)
        }

        if (addCategoryStatus.length) {
            dispatch(addCategoryRequestStatus(''))
        }
    }, [addCategorySuccess, addCategoryError, addCategoryStatus])

    const clearFields = () => {
        setCategoryName('')
        setCategoryCode('')
        setImagePath(null)
        setImageName('')
    }

    const categoryNameHandle = e => {
        const name = e.target.value
        setCategoryName(name)
        let nameUp = name.toUpperCase()
        nameUp = nameUp.replace(/[^a-zA-Z]/g, "")
        setCategoryCode(nameUp)
    }

    const categoryCodeHandle = e => {
        const name = e.target.value
        let nameUp = name.toUpperCase()
        nameUp = nameUp.replace(/[^a-zA-Z]/g, "")
        setCategoryCode(nameUp)
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    const submitHandler = () => {
        if (isEmpty(categoryName)) {
            displayErrorToast('Category name cannot be blank')
        } else if (isEmpty(categoryCode)) {
            displayErrorToast('Category code cannot be blank')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('categoryCode', categoryCode)
            fd.append('name', categoryName)
            fd.append('businessId', user.data.userDetails.userId)
            fd.append('productCode', user.data.userDetails.productCode)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            dispatch(addCategoryRequest(fd))
        }
    }

    return (
        <>
            <div className="add-cat-container">
                <ScreenLabel label='Add Category' />
                <CommonForm>
                    <SubTextLabel label='Enter details to add new category' />
                    <div className="form-floating cat-input">
                        <input
                            type="name"
                            className="form-control cat-input-text"
                            id="name"
                            placeholder="Category Name"
                            value={categoryName}
                            onChange={categoryNameHandle}
                            required />
                        <label for="name">Category Name</label>
                    </div>
                    <div className="form-floating cat-input">
                        <input
                            type="name"
                            className="form-control cat-input-text"
                            id="name"
                            placeholder="Category Code"
                            value={categoryCode}
                            onChange={categoryCodeHandle}
                            required />
                        <label for="name">Category Code</label>
                    </div>
                    <label className="c-field-label">Category Image</label>
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <div className='c-button-row'>
                        <button type='button' className='btn c-button' onClick={() => submitHandler()}>Add Category</button>
                    </div>

                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddCategoryScreen