import { EmployeeActionTypes } from './employee.types'

//Add Employee
export const addEmployeeRequest = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST,
    payload: data
})

export const addEmployeeRequestSuccess = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_SUCCESS,
    payload: data
})

export const addEmployeeRequestError = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_ERROR,
    payload: data
})

export const addEmployeeRequestStatus = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_STATUS,
    payload: data
})

//Employee list
export const employeeListRequest = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LIST_REQUEST,
    payload: data
})

export const employeeListRequestSuccess = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const employeeListRequestError = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LIST_REQUEST_ERROR,
    payload: data
})

//Employee detail
export const employeeDetailRequest = data => ({
    type: EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST,
    payload: data
})

export const employeeDetailRequestSuccess = data => ({
    type: EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const employeeDetailRequestError = data => ({
    type: EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST_ERROR,
    payload: data
})

//Remove Employee
export const removeEmployeeRequest = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST,
    payload: data
})

export const removeEmployeeRequestSuccess = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_SUCCESS,
    payload: data
})

export const removeEmployeeRequestError = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_ERROR,
    payload: data
})

export const removeEmployeeRequestStatus = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_STATUS,
    payload: data
})