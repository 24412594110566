import { takeLatest, call, put } from 'redux-saga/effects'
import {
    productList, addProduct, updateProduct, updateProductStatus, productDetail, addFeedback,
    bulkUploadMenu
} from '../api-requests/product';
import {
    addProductRequestSuccess, addProductRequestError, productListRequestSuccess, productListRequestError,
    productDetailRequestSuccess, productDetailRequestError, productUpdateRequestSuccess, productUpdateRequestError,
    productStatusUpdateRequestSuccess, productStatusUpdateRequestError, addFeedbackRequestSuccess, addFeedbackRequestError,
    bulkMenuUploadRequestError, bulkMenuUploadRequestSuccess
} from './product.action';
import { ProductActionTypes } from './product.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleBulkMenuUpload({ payload }) {
    try {
        const response = yield call(bulkUploadMenu, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(bulkMenuUploadRequestSuccess(decryptedData))
            } else {
                yield put(bulkMenuUploadRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        yield put(bulkMenuUploadRequestError(error.response.data))
    }
}

export function* bulkMenuUploadReq() {
    yield takeLatest(ProductActionTypes.BULK_UPLOAD_MENU_REQUEST, handleBulkMenuUpload)
}

function* handleAddFeedback({ payload }) {
    try {
        const response = yield call(addFeedback, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addFeedbackRequestSuccess(decryptedData))
            } else {
                yield put(addFeedbackRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        yield put(addFeedbackRequestError(error.response.data))
    }
}

export function* addFeedbackReq() {
    yield takeLatest(ProductActionTypes.ADD_FEEDBACK_REQUEST, handleAddFeedback)
}

function* handleProductStatusUpdate({ payload }) {
    try {
        const response = yield call(updateProductStatus, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(productStatusUpdateRequestSuccess(decryptedData))
            } else {
                yield put(productStatusUpdateRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(productStatusUpdateRequestError(error.response.data))
    }
}

export function* productStatusUpdateReq() {
    yield takeLatest(ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST, handleProductStatusUpdate)
}

function* handleProductUpdate({ payload }) {
    try {
        const response = yield call(updateProduct, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(productUpdateRequestSuccess(decryptedData))
            } else {
                yield put(productUpdateRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(productUpdateRequestError(error.response.data))
    }
}

export function* productUpdateReq() {
    yield takeLatest(ProductActionTypes.PRODUCT_UPDATE_REQUEST, handleProductUpdate)
}

function* handleProductDetail({ payload }) {
    try {
        const response = yield call(productDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(productDetailRequestSuccess(decryptedData))
            } else {
                yield put(productDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(productDetailRequestError(error.response.data))
    }
}

export function* productDetailReq() {
    yield takeLatest(ProductActionTypes.PRODUCT_DETAIL_REQUEST, handleProductDetail)
}

function* handleProductList({ payload }) {
    try {
        const response = yield call(productList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(productListRequestSuccess(decryptedData))
            } else {
                yield put(productListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(productListRequestError(error.response.data))
    }
}

export function* productListReq() {
    yield takeLatest(ProductActionTypes.PRODUCT_LIST_REQUEST, handleProductList)
}

function* handleAddProduct({ payload }) {
    try {
        const response = yield call(addProduct, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addProductRequestSuccess(decryptedData))
            } else {
                yield put(addProductRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addProductRequestError(error.response.data))
    }
}

export function* addProductReq() {
    yield takeLatest(ProductActionTypes.ADD_PRODUCT_REQUEST, handleAddProduct)
}