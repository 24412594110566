import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, priceWithSymbol, calculateDiscountedPrice, getProductMeasurmentUnit, isImageAvailable } from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import Select from 'react-select'
import {
    restaurantListRequest, branchListRequest, restaurantDetailRequest, branchDetailRequest,
    customerRestLocRequest
} from '../../../redux/user/user.action'
import { selectedRestaurantAndLocIdAct } from '../../../redux/order/order.action'
import { categoryListRequest } from '../../../redux/category/category.action'
import { productListRequest } from '../../../redux/product/product.action'
import { addItemInCart, reduceItemQty } from '../../../redux/cart/cart.action'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import { config } from "../../../config";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import ButtonRow from "../../../components/button/button-row/buttonRow";

const PlaceOrderScreen = props => {
    const { user, branchListSuccess, branchListError,
        restuarantListSuccess, restuarantListError, employeeSelectedRestaurant,
        employeeSelectedLocation, restaurantDetailSuccess, restaurantDetailError,
        branchDetailSuccess, branchDetailError, locationId, restaurantId } = useSelector(state => state.user)
    const { categoryList, categoryListError } = useSelector(state => state.category)
    const { productListSuccess, productListError } = useSelector(state => state.product)
    const { cartItem } = useSelector(state => state.cart)
    const [allProduct, setAllProduct] = useState(true)
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [isLoading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const dispatch = useDispatch()
    let isFirstTime = false
    let navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState()
    const [showOptionPriceModal, setShowOptionPriceModal] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'EMPLOYEE_PLACE_ORDER',
            screenClass: 'EMPLOYEE_PLACE_ORDER'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.EMPLOYEE_PLACE_ORDER, true)
            isFirstTime = true
            let data = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess)) {
                dispatch(restaurantListRequest(data))
            }
        }
        if (isLoggedInUserEmployee(user) === true) {
            fetchCategoryList(employeeSelectedRestaurant, employeeSelectedLocation)

        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.EMPLOYEE_PLACE_ORDER) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.EMPLOYEE_PLACE_ORDER, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(branchListSuccess)) {
                setLoading(false)
                setSelectedLocation(branchListSuccess.data[0])
            } else if (!isNull(branchListError)) {
                setLoading(false)
            }
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchCategoryList(selectedRestaurant.businessId, selectedLocation.locationId)
        }
    }, [selectedLocation])

    const fetchCategoryList = (restaurantId1, locationId1) => {
        setLoading(true)
        const data = {
            businessId: restaurantId1,
            locationId: locationId1
        }
        dispatch(categoryListRequest(data))
        fetchRestaurantData(restaurantId1)
        fetchRestaurantLocationData(locationId1)
        if (isEmpty(restaurantId) && isEmpty(locationId)) {
            const data1 = {
                restaurantId: Number(restaurantId1),
                locationId: Number(locationId1)
            }
            dispatch(customerRestLocRequest(data1))
        }

    }

    useEffect(() => {
        if (!isNull(categoryList)) {
            setLoading(false)
            setSelectedCategory(categoryList.data[0])
        } else if (!isNull(categoryListError)) {
            setLoading(false)
            if (categoryListError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [categoryList, categoryListError])

    const categoryClick = item => {
        setSelectedCategory(item)
        dispatch({ type: APP_CONSTANTS.PRODUCT_CLEAR_DATA })
        setLoading(true)
        const data = {
            productCode: APP_CONSTANTS.PRODUCT_NAME,
            categoryId: item.categoryId
        }
        dispatch(productListRequest(data))
    }

    useEffect(() => {
        if (!isNull(productListSuccess)) {
            setLoading(false)
        } else if (!isNull(productListError)) {
            setLoading(false)
            if (productListError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [productListSuccess, productListError])

    const fetchRestaurantData = (restaurantId) => {
        setLoading(true)
        const data = {
            businessId: restaurantId
        }
        dispatch(restaurantDetailRequest(data))
    }

    const fetchRestaurantLocationData = (locationId) => {
        setLoading(true)
        dispatch(branchDetailRequest(locationId))
    }

    const handleAddToCartClick = item => {
        if (isNull(item)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            if (item.status === 0) {
                displayErrorToast('Food item is not available')
            } else {
                displayConsoleLog('===item', item)
                const data = {
                    itemId: item.itemId,
                    itemCode: item.itemCode,
                    categoryId: item.categoryId,
                    name: item.name,
                    price: item.price,
                    finalPrice: item.finalPrice,
                    quantity: 1,
                    discount: item.discount,
                    tax: item.isTaxable === true ? item.tax : 0,
                    isTaxable: item.isTaxable,
                    currencyLabel: item.currencyLabel,
                    currencySymbol: item.currencySymbol,
                    currencyRegion: item.currencyRegion,
                    measurementUnit: item.measurementUnit,
                    measurementQuantity: item.measurementQuantity
                }
                dispatch(addItemInCart(data))
                displaySuccessToast('Item added in cart')
            }
        }
    }

    const handleReviewOrderClick = () => {
        navigate(ROUTES.cartReview)
    }

    const getImageClassName = index => {
        const lastDigit = index % 10;
        // return `cmc-img-container${lastDigit}`
        return `cmc-img-container-white`
    }

    const displayCustomizeButton = (item) => {
        if (isEmpty(item.addOns) && isEmpty(item.options))
            return false
        else return true
    }

    const handleOpenCustomizeScreen = (item) => {
        navigate(ROUTES.menuProductCustomize, { state: item })
    }

    const addClick = item => {
        setSelectedItem(item)
        if (!item.isOptionBasedPricing) {
            addItem(item)
        } else {
            setShowOptionPriceModal(true)
        }

    }

    const addItem = item => {
        if (isNull(item)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            displayConsoleLog('===item', item)
            const data = {
                itemId: item.itemId,
                itemCode: item.itemCode,
                categoryId: item.categoryId,
                name: item.name,
                isOptionBasedPricing: false,
                price: item.price,
                finalPrice: item.finalPrice,
                quantity: 1,
                isFromCart: false,
                discount: item.discount,
                tax: item.isTaxable === true ? item.tax : 0,
                isTaxable: item.isTaxable,
                currencyLabel: item.currencyLabel,
                currencySymbol: item.currencySymbol,
                currencyRegion: item.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: item.measurementUnit,
                measurementQuantity: item.measurementQuantity
            }
            if (!isEmpty(item.points)) {
                data.points = Number(item.points)
                data.productPoints = Number(item.points)
            } else {
                data.points = 0
                data.productPoints = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast('Item added in cart')
            // dispatch(displayAddCartBannerAct(''))
        }
    }

    const handleAddWithPrice = item => {
        if (isNull(selectedItem)) {
            displayErrorToast('Error while adding item. Please try again')
        } else {
            const data = {
                itemId: selectedItem.itemId,
                itemCode: selectedItem.itemCode,
                categoryId: selectedItem.categoryId,
                name: `${selectedItem.name} - ${item.label}`,
                isOptionBasedPricing: true,
                price: item.price,
                finalPrice: item.price,
                quantity: 1,
                isFromCart: false,
                discount: 0,
                tax: 0,
                isTaxable: false,
                currencyLabel: selectedItem.currencyLabel,
                currencySymbol: selectedItem.currencySymbol,
                currencyRegion: selectedItem.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: selectedItem.measurementUnit,
                measurementQuantity: selectedItem.measurementQuantity
            }
            if (!isEmpty(selectedItem.points)) {
                data.points = Number(selectedItem.points)
                data.productPoints = Number(selectedItem.points)
            } else {
                data.points = 0
                data.productPoints = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast('Item added in cart')
            // dispatch(displayAddCartBannerAct(''))
            setShowOptionPriceModal(false)
        }
    }

    const isValidPoints = points => {
        if (!isEmpty(points) && points > 0) {
            return true
        } else {
            return false
        }
    }

    const updateQty = element => {
        const item = {
            ...element,
            isFromCart: true
        }
        dispatch(addItemInCart(item))
    }

    const reduceQty = element => {
        dispatch(reduceItemQty(element))
    }

    return (
        <>
            <div className="plo-container">
                <ScreenLabel label='Place Order' />
                {isLoggedInUserBusiness(user) === true && <div className="ols-select-row-container">
                    <div className="ols-select-container">
                        <div className="ols-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='ols-list-select'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    {isLoggedInUserBusiness(user) === true && <div className="ols-select-container">
                        <div className="ols-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='ols-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>}
                </div>}
                {!isNull(categoryList) && <div className="mcp-category-parent-container">
                    <div className="mcp-category-scroll-container">
                        {categoryList.data.map(e => (
                            <div className={`mcp-category-item ${selectedCategory?.categoryId === e.categoryId ? 'mcp-category-item-selected' : ''}`}
                                onClick={() => { categoryClick(e) }}>{e.name}</div>
                        ))}
                    </div>
                </div>}
                {!isNull(categoryListError) && <div className="content-error">{categoryListError.message}</div>}
                {/* {categoryList && categoryList.data && <div className="plo-cat-container">
                    {categoryList.data.map(cat => (
                        <div className="plo-cat-item" onClick={() => { categoryClick(cat) }}>{cat.name}</div>
                    ))}
                </div>}
                {!isNull(categoryListError) && <div className="content-error">{categoryListError.message}</div>} */}
                <div className="row">
                    <div className="col-sm-6">
                        {!isNull(productListError) && <div className="content-error">{productListError.message}</div>}
                        {!isNull(productListSuccess) && !isEmpty(productListSuccess.data) && <div className="mcp-product-list-parent-container">
                            {productListSuccess.data.map((element, index) => (
                                <div className="mcp-product-row-container">
                                    <div className="mcp-product-row-item-container">
                                        <div className="mcp-product-text-parent-container">

                                            <div className="mcp-product-text-container">
                                                <div className="mcp-product-name">{element.name}</div>
                                                {element.status === 1 && !element.isOptionBasedPricing && <div>
                                                    {element.discount > 0 ? (<div className="mcp-product-price-name"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>) : (<div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                                                </div>}
                                                {element.status === 1 && isValidPoints(element.points) && <div className="mcp-points-container">&#9733; Earn {element.points ? element.points.toLocaleString('en-US') : '0'} points</div>}
                                                {element.status === 1 && element.isOptionBasedPricing && <div className="mcp-option-based-pricing-container">
                                                    {element.optionBasedPricing.map(e => (
                                                        <div className="mcp-option-price-label">{e.label} - {priceWithSymbol(element, e.price)}</div>
                                                    ))}
                                                </div>}
                                                {element.status === 0 && <div className="out-of-stock">Out Of Stock</div>}
                                            </div>
                                        </div>
                                        <div className="mcp-product-image-container">
                                            {isImageAvailable(element.image) ? (<img src={`${config.imageUrl}${element.image}`} className="pl-img" alt="..." />) : (
                                                <div className={`${getImageClassName(index)}`} />
                                            )}
                                            {element.status === 1 && displayCustomizeButton(element) === false && <div className="edit-image-button-bl" onClick={() => addClick(element)}>Add</div>}
                                            {element.status === 1 && displayCustomizeButton(element) === true && <div className="edit-image-button-bl" onClick={() => handleOpenCustomizeScreen(element)}>Customize</div>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>
                    <div className="col-sm-6">
                        <div className="cmc-content-container plo-center-container">
                            {!isNull(cartItem) && <div className="plo-cart-label">Your Cart</div>}
                            {!isNull(cartItem) && cartItem.map(element => (
                                <div className="add-crl-row plo-row-item-container">
                                    <div className="crl-item-label">{element.name}</div>
                                    {/* {<div className="crl-amount-label">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>} */}
                                    {element.discount > 0 ? (<div className="crl-amount-label"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>) : (<div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                                    {!isEmpty(element.options) && <div className="mt-1">
                                        <div className="crl-customization-label">Options</div>
                                        <div className="crl-customization-container">
                                            {element.options.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                        </div>
                                    </div>}
                                    {!isEmpty(element.addOns) && <div className="mt-1">
                                        <div className="crl-customization-label">Add-ons</div>
                                        <div className="crl-customization-container">
                                            {element.addOns.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                        </div>
                                    </div>}
                                    <div className="add-crl-btn-row">
                                        <div className="crl-update-qty-container">
                                            <div className="add-crl-img-container" onClick={() => reduceQty(element)}>
                                                <img className="add-crl-img" src={require('../../../assets/minus.png')} alt="minus" />
                                            </div>
                                            <div className="crl-qty-update-label">{element.quantity}</div>
                                            <div className="add-crl-img-container" onClick={() => updateQty(element)}>
                                                <img className="add-crl-img" src={require('../../../assets/plus.png')} alt="plus" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {!isNull(cartItem) && <ButtonRow>
                            <CommonButton label='Review Order' onClick={() => handleReviewOrderClick()} />
                        </ButtonRow>}
                    </div>
                </div>

            </div>
            <Modal
                show={showOptionPriceModal}
                onHide={() => setShowOptionPriceModal(!showOptionPriceModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="mp-button-container">
                        {!isNull(selectedItem) && selectedItem.optionBasedPricing.map(e => (
                            <CommonButton
                                label={`${e.label} - ${e.price}`}
                                onClick={() => { handleAddWithPrice(e) }}
                            />
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default PlaceOrderScreen