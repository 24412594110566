import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import {
    orderDetailRequest, orderGeneratePaymentLinkRequest, orderGeneratePaymentLinkRequestStatus,
    updateCustomerActionRequest, updateCustomerActionRequestStatus
} from '../../../redux/order/order.action'
import { addFeedbackRequest, addFeedbackRequestStatus } from '../../../redux/product/product.action'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import { useLocation, useNavigate } from 'react-router-dom';
import { convertToPascal, displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, isEmpty, isImageAvailable, isNull, priceWithSymbol, removeCustomerPayAtEndOrderInfo, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import Modal from 'react-modal';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import ReactStars from "react-rating-stars-component";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import { SocketContext } from '../../../SocketCtx'
import { config } from '../../../config'

let stripeAccountId = ''
const CustomerOrderDetail = props => {
    const dispatch = useDispatch()
    const { orderDetailSuccess, orderDetailError,
        orderGeneratePaymentLinkReqSuccess, orderGeneratePaymentLinkReqError, orderGeneratePaymentLinkReqStatus,
        updateCustomerActionSuccess, updateCustomerActionError, updateCustomerActionReqStatus } = useSelector(state => state.order)
    const { addFeedbackSuccess, addFeedbackError, addFeedbackStatus } = useSelector(state => state.product)
    const { restaurantDetailSuccess, locationId, restaurantId } = useSelector(state => state.user)
    const [isLoading, setLoading] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false)
    const { state } = useLocation()
    const [name, setName] = useState('')
    const [feedback, setFeedback] = useState('')
    const [rating, setRating] = useState(0)
    let navigate = useNavigate();
    const [displayStripePaymentMode, setDisplayStripePaymentMode] = useState(false)
    const [displayOfflinePaymentMode, setDisplayOfflinePaymentMode] = useState(false)
    const [paymentModeOffline, setPaymentModeOffline] = useState(false)
    const [paymentModeOnline, setPaymentModeOnline] = useState(false)
    const socket = useContext(SocketContext)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_ORDER_DETAIL',
            screenClass: 'CUSTOMER_ORDER_DETAIL'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) {
            if (restaurantDetailSuccess.data.orderPaymentModes) {
                const onlinePG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
                if (!isNull(onlinePG)) {
                    const stripePG = onlinePG.optionType.find(b => b.type === APP_CONSTANTS.STRIPE)
                    if (!isNull(stripePG)) {
                        setDisplayStripePaymentMode(true)
                        stripeAccountId = stripePG.accountId
                    }
                }

                const cashPG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE)
                if (!isNull(cashPG)) {
                    setDisplayOfflinePaymentMode(true)
                }
            }
        }
    }, [])

    useEffect(() => {
        fetchOrderDetails()
    }, [])

    const fetchOrderDetails = () => {
        setLoading(true)
        const data = {
            orderId: state
        }
        dispatch(orderDetailRequest(data))
    }

    useEffect(() => {
        if (orderGeneratePaymentLinkReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            window.open(orderGeneratePaymentLinkReqSuccess.data.onlinePaymentLink, '_self')
        } else if (orderGeneratePaymentLinkReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
        }

        if (orderGeneratePaymentLinkReqStatus.length) {
            dispatch(orderGeneratePaymentLinkRequestStatus(''))
        }
    }, [orderGeneratePaymentLinkReqSuccess, orderGeneratePaymentLinkReqError, orderGeneratePaymentLinkReqStatus])

    useEffect(() => {
        if (!isNull(orderDetailSuccess)) {
            setLoading(false)
            if (orderDetailSuccess.data.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID) {
                removeCustomerPayAtEndOrderInfo()
            }
        } else if (!isNull(orderDetailError)) {
            setLoading(false)
            if (orderDetailError.status === 401) {
                displayErrorToast('Session Expired. Try Login Again.')
                sessionStorage.clear()
                dispatch({ type: APP_CONSTANTS.LOGOUT })
                navigate(-2)
            }
        }
    }, [orderDetailSuccess, orderDetailError])

    useEffect(() => {
        if (addFeedbackStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addFeedbackSuccess.message)
            sessionStorage.removeItem(APP_CONSTANTS.PRODUCT_ITEM)
        } else if (addFeedbackStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addFeedbackError.message)
        }

        if (addFeedbackStatus.length) {
            dispatch(addFeedbackRequestStatus(''))
        }
    }, [addFeedbackSuccess, addFeedbackError, addFeedbackStatus])

    useEffect(() => {
        if (updateCustomerActionReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateCustomerActionSuccess.message)
            fetchOrderDetails()
        } else if (updateCustomerActionReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateCustomerActionError.message)
        }
        if (updateCustomerActionReqStatus?.length) {
            dispatch(updateCustomerActionRequestStatus(''))
        }
    }, [updateCustomerActionSuccess, updateCustomerActionError, updateCustomerActionReqStatus])

    const handleShowFeedback = (element) => {
        sessionStorage.setItem(APP_CONSTANTS.PRODUCT_ITEM, element.itemId)
        setShowFeedback(true)
    }

    const handleSubmitFeedback = () => {
        if (isEmpty(name)) {
            displayErrorToast('Name cannot be blank')
        } else if (isEmpty(feedback)) {
            displayErrorToast('Review cannot be blank')
        } else {
            setLoading(true)
            setShowFeedback(false)
            const data = {
                itemId: sessionStorage.getItem(APP_CONSTANTS.PRODUCT_ITEM),
                review: feedback,
                rating: rating,
                userName: name
            }
            dispatch(addFeedbackRequest(data))
        }
    }

    const handleGetStatus = () => {
        fetchOrderDetails()
    }

    const handlePayBill = () => {
        if (paymentModeOnline === false && paymentModeOffline === false) {
            displayErrorToast('Payment mode not selected')
        }
    }

    const handleViewBill = () => {
        navigate(ROUTES.viewBill, { state: state })
    }

    const isOnGoingOrder = () => {
        if (!isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID))) {
            return true
        } else {
            return false
        }
    }

    const handlePaymentModeChange = (type) => {
        if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE) {
            setPaymentModeOnline(false)
            setPaymentModeOffline(true)
        }
        if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE) {
            setPaymentModeOffline(false)
            setPaymentModeOnline(true)
        }
    }

    const getPaymentModelLabel = type => {
        if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE) {
            return APP_CONSTANTS.ORDER_PAYMENT_MODE_LABEL.ONLINE
        } else if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE) {
            return APP_CONSTANTS.ORDER_PAYMENT_MODE_LABEL.OFFLINE
        } else if (type === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END) {
            return APP_CONSTANTS.ORDER_PAYMENT_MODE_LABEL.PAY_AT_END
        } else return ''
    }

    const displayOtherDetailsLabel = () => {
        if (!isNull(orderDetailSuccess) && !isNull(orderDetailSuccess.data)) {
            let flag = false
            if (!isNull(orderDetailSuccess.data.dineInTableNumber)) {
                flag = true
            }
            if (!isNull(orderDetailSuccess.data.curbsideSlotNumber)) {
                flag = true
            }
            if (!isNull(orderDetailSuccess.data.preparationTime)) {
                flag = true
            }
            if (!isNull(orderDetailSuccess.data.cancelReason)) {
                flag = true
            }
            return flag
        } else {
            return false
        }
    }

    const handleCallWaiterForPayment = () => {
        const data = {
            restaurantId: restaurantId,
            message: `Kindly collect order payment for order number ${orderDetailSuccess ? orderDetailSuccess.data.orderNumber : ''} from table ${orderDetailSuccess ? orderDetailSuccess.data.dineInTableNumber : ''}`
        }
        socket.emit('collect-order-payment', data)
    }

    const handlePayBillOnline = () => {
        if (!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && !isEmpty(restaurantDetailSuccess.data.orderPaymentModes)) {
            const onlinePG = restaurantDetailSuccess.data.orderPaymentModes.find(b => b.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
            if (!isNull(onlinePG)) {
                const stripePG = onlinePG.optionType.find(b => b.type === APP_CONSTANTS.STRIPE)
                if (!isNull(stripePG)) {
                    setLoading(true)
                    const data = {
                        orderId: state,
                        successUrl: `${config.clientUrl}${ROUTES.orderPaymentSuccessUrl}`,
                        cancelUrl: `${config.clientUrl}${ROUTES.orderPaymentCancelUrl}`,
                        paymentMode: APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE,
                        paymentModeName: stripePG.type,
                        stripeAccountId: stripeAccountId
                    }
                    dispatch(orderGeneratePaymentLinkRequest(data))
                } else {
                    displayErrorToast('Error occurred while fetching online payment mode details.')
                }
            } else {
                displayErrorToast('Error occurred while fetching online payment mode details.')
            }
        } else {
            displayErrorToast('Unable to fetch online payment mode')
        }

    }

    const getCurrencySymbol = () => {
        if (!isNull(orderDetailSuccess) && !isNull(orderDetailSuccess.data)) {
            return orderDetailSuccess.data.currencySymbol
        } else {
            return ''
        }
    }

    const getCurrencyRegion = () => {
        if (!isNull(orderDetailSuccess) && !isNull(orderDetailSuccess.data)) {
            return orderDetailSuccess.data.currencyRegion
        } else {
            return ''
        }
    }
    const getCurrencyLabel = () => {
        if (!isNull(orderDetailSuccess) && !isNull(orderDetailSuccess.data)) {
            return orderDetailSuccess.data.currencyLabel
        } else {
            return ''
        }
    }

    const handleCustomerActionClick = type => {
        setLoading(true)
        const data = {
            orderId: state,
            customerAction: type
        }
        dispatch(updateCustomerActionRequest(data))
    }

    return (
        <>
            <div className="col-d-container mb-4">
                {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                    <div className="cmc-label-container mt-2">
                        <div className="mcp-logo-container">
                            {isImageAvailable(restaurantDetailSuccess.data.image) ?
                                (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} alt="logo" className="img" />) :
                                <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />}
                        </div>
                        <div className="mcp-restaurant-label">Order Detail</div>
                    </div>
                ) :
                    (<div className="cmc-label-container">
                        <ScreenLabel label='Order Detail' />
                    </div>)
                }
                {orderDetailSuccess && orderDetailSuccess.data && <div className="col-d-data-container">
                    {(orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.PLACED || orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION || orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED) ?
                        (<div className="col-d-status-container col-d-status-container-yellow">{removeSpecialCharAndChangeToPascal(orderDetailSuccess.data.orderStatus)}</div>) : null}
                    {(orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.ACCEPTED || orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARING || orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.INPROCESS || orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARED) ?
                        (<div className="col-d-status-container col-d-status-container-orange">{orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARED ? removeSpecialCharAndChangeToPascal(APP_CONSTANTS.ORDER_STATUS.READY_TO_SERVE) : convertToPascal(orderDetailSuccess.data.orderStatus)}</div>) : null}
                    {orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.DELIVERED ?
                        (<div className="col-d-status-container col-d-status-container-green">{convertToPascal(orderDetailSuccess.data.orderStatus)}</div>) : null}
                    {orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.CANCELLED ?
                        (<div className="col-d-status-container">{convertToPascal(orderDetailSuccess.data.orderStatus)}</div>) : null}
                    {orderDetailSuccess.data.orderNumber && <div className="col-d-item-label">Order Number </div>}
                    {orderDetailSuccess.data.orderNumber && <div>{orderDetailSuccess.data.orderNumber}</div>}
                    <div className="col-d-item-label">Order Code </div>
                    <div>{orderDetailSuccess.data.orderCode}</div>
                    <div className="col-d-item-label">Name</div>
                    <div>{orderDetailSuccess.data.contactPersonName}</div>
                    <div className="col-d-item-label">Mobile Number</div>
                    <div>{orderDetailSuccess.data.contactPersonNumber}</div>
                    {!isEmpty(orderDetailSuccess.data.otherInformation) && <div>
                        <div className="col-d-item-label">Special Request</div>
                        <div>{orderDetailSuccess.data.otherInformation}</div>
                    </div>}
                    {!isEmpty(orderDetailSuccess.data.paymentMode) && <div>
                        <div className="col-d-item-label">Payment Mode</div>
                        <div>{getPaymentModelLabel(orderDetailSuccess.data.paymentMode)}</div>
                    </div>}
                    {!isEmpty(orderDetailSuccess.data.paymentStatus) && <div>
                        <div className="col-d-item-label">Payment Status</div>
                        <div>{removeSpecialCharAndChangeToPascal(orderDetailSuccess.data.paymentStatus)}</div>
                    </div>}
                    {!isEmpty(orderDetailSuccess.data.paymentModeName) && <div>
                        <div className="col-d-item-label">Payment Mode Name</div>
                        <div>{orderDetailSuccess.data.paymentModeName}</div>
                    </div>}

                    {displayOtherDetailsLabel() && <div className="col-d-item-label">Other details </div>}
                    {!isNull(orderDetailSuccess.data.dineInTableNumber) ? (
                        <div className="col-d-item-row-container">
                            <div className="col-d-item-row-item">Table Number</div>
                            <div className="col-d-item-row-item">{orderDetailSuccess.data.dineInTableNumber}</div>
                        </div>
                    ) : null}
                    {!isNull(orderDetailSuccess.data.curbsideSlotNumber) ? (
                        <div className="col-d-item-row-container">
                            <div className="col-d-item-row-item">Curbside Number</div>
                            <div className="col-d-item-row-item">{orderDetailSuccess.data.curbsideSlotNumber}</div>
                        </div>
                    ) : null}
                    {!isNull(orderDetailSuccess.data.preparationTime) ? (
                        <div className="col-d-item-row-container">
                            <div className="col-d-item-row-item">Preparation Time</div>
                            <div className="col-d-item-row-item">{orderDetailSuccess.data.preparationTime}</div>
                        </div>
                    ) : null}
                    {!isNull(orderDetailSuccess.data.cancelReason) ? (
                        <div>
                            <div className="col-d-item-label">Cancel Reason</div>
                            {orderDetailSuccess.data.cancelReason}
                        </div>) : null}
                    <div className="col-d-item-label">Ordered Items </div>
                    <div className="cmc-content-container">
                        {orderDetailSuccess.data.processedItems && orderDetailSuccess.data.processedItems.length > 0 && orderDetailSuccess.data.processedItems.map(element =>
                            <div className="add-col-d-row">
                                <div className={element.status === APP_CONSTANTS.ORDER_STATUS.DELIVERED ? "col-d-item-status-green" : 'col-d-item-status'}>{removeSpecialCharAndChangeToPascal(element.status)}</div>
                                <div className="col-d-item-label">{element.name}</div>
                                <div className="col-d-amount-label-light">Price {priceWithSymbol(element, element.price)}</div>
                                <div className="col-d-amount-label">Total Price {priceWithSymbol(element, element.finalPrice)}</div>
                                {!isEmpty(element.options) && <div className="mt-1">
                                    <div className="crl-customization-label">Options</div>
                                    <div className="crl-customization-container">
                                        {element.options.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                {!isEmpty(element.addOns) && <div className="mt-1">
                                    <div className="crl-customization-label">Add-ons</div>
                                    <div className="crl-customization-container">
                                        {element.addOns.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                <div className="col-d-item-label-container">
                                    <div className="col-d-amount-label">Qty: {element.quantity} </div>
                                </div>
                                {(orderDetailSuccess.data.orderStatus === 'DELIVERED' || orderDetailSuccess.data.orderStatus === "CANCELLED") ?
                                    (<div className="add-review-label" onClick={() => { handleShowFeedback(element) }}>Add Review</div>) : null
                                }


                            </div>)}
                        {isEmpty(orderDetailSuccess.data.processedItems) && orderDetailSuccess.data.itemsList && orderDetailSuccess.data.itemsList.map(element =>
                            <div className="add-col-d-row">
                                <div className={element.status === APP_CONSTANTS.ORDER_STATUS.DELIVERED ? "col-d-item-status-green" : 'col-d-item-status'}>{removeSpecialCharAndChangeToPascal(element.status)}</div>
                                <div className="col-d-item-label">{element.name}</div>
                                <div className="col-d-amount-label-light">Price {priceWithSymbol(element, element.price)}</div>
                                <div className="col-d-amount-label">Total Price {priceWithSymbol(element, element.finalPrice)}</div>
                                {!isEmpty(element.options) && <div className="mt-1">
                                    <div className="crl-customization-label">Options</div>
                                    <div className="crl-customization-container">
                                        {element.options.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                {!isEmpty(element.addOns) && <div className="mt-1">
                                    <div className="crl-customization-label">Add-ons</div>
                                    <div className="crl-customization-container">
                                        {element.addOns.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                <div className="col-d-item-label-container">
                                    <div className="col-d-amount-label">Qty: {element.quantity} </div>
                                </div>
                                {(orderDetailSuccess.data.orderStatus === 'DELIVERED' || orderDetailSuccess.data.orderStatus === "CANCELLED") ?
                                    (<div className="add-review-label" onClick={() => { handleShowFeedback(element) }}>Add Review</div>) : null
                                }


                            </div>)}
                    </div>
                    {orderDetailSuccess.data.unavailableItemList && orderDetailSuccess.data.unavailableItemList.length > 0 && <div className="col-d-item-label">Unavailable Items </div>}
                    {orderDetailSuccess.data.unavailableItemList && orderDetailSuccess.data.unavailableItemList.length > 0 && <div className="cmc-content-container">
                        {orderDetailSuccess.data.unavailableItemList.map(element =>
                            <div className="add-col-d-row">
                                <div className="col-d-item-label">{element.name}</div>
                                <div className="col-d-amount-label-light">Price {priceWithSymbol(element, element.price)}</div>
                                <div className="col-d-amount-label">Total Price {priceWithSymbol(element, element.finalPrice)}</div>
                                {!isEmpty(element.options) && <div className="mt-1">
                                    <div className="crl-customization-label">Options</div>
                                    <div className="crl-customization-container">
                                        {element.options.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                {!isEmpty(element.addOns) && <div className="mt-1">
                                    <div className="crl-customization-label">Add-ons</div>
                                    <div className="crl-customization-container">
                                        {element.addOns.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                                    </div>
                                </div>}
                                <div className="col-d-item-label-container">
                                    <div className="col-d-amount-label">Qty: {element.quantity} </div>
                                </div>
                            </div>)}
                    </div>}
                    <div className="col-d-price-container">Sub total: {priceWithSymbol(orderDetailSuccess.data, orderDetailSuccess.data.subTotal)}</div>
                    <div className="col-d-price-container">Total Discount: {priceWithSymbol(orderDetailSuccess.data, orderDetailSuccess.data.discountAmount)}</div>
                    <div className="col-d-price-container">Total Tax: {priceWithSymbol(orderDetailSuccess.data, orderDetailSuccess.data.taxAmount)}</div>
                    <div className="col-d-price-container col-d-price-container-gt">Grand Total: {priceWithSymbol(orderDetailSuccess.data, orderDetailSuccess.data.grandTotal)}</div>
                    <div className="col-d-price-container col-d-price-container-gt">Refund Amount: {priceWithSymbol(orderDetailSuccess.data, orderDetailSuccess.data.refundAmount)}</div>
                    {!isNull(orderDetailSuccess) && orderDetailSuccess.data.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && <div className="cr-item-label mt-3">Payment Modes</div>}
                    {!isNull(orderDetailSuccess) && orderDetailSuccess.data.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && <div className="cr-payment-mode-container">
                        {displayOfflinePaymentMode && <label className="cr-payment-mode-label-container">
                            <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE} checked={paymentModeOffline} onChange={e => handlePaymentModeChange(e.target.value)} />
                            <span className="cr-payment-mode-label">{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE)}</span>
                        </label>}
                        {displayStripePaymentMode && <label className="cr-payment-mode-label-container">
                            <input type='radio' value={APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE} checked={paymentModeOnline} onChange={e => handlePaymentModeChange(e.target.value)} />
                            <span className='cr-payment-mode-label'>{getPaymentModelLabel(APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)}</span>
                        </label>}
                    </div>}
                    {!isNull(orderDetailSuccess) && orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION &&
                        <div className="col-btn-container-column">
                            <button type='button' onClick={() => handleCustomerActionClick(APP_CONSTANTS.ORDER_STATUS.CUSTOMER_ACTION.CONTINUE)} className='btn add-col-d-btn add-col-d-btn-left add-col-d-btn-column'>Continue</button>
                            <button type='button' onClick={() => handleCustomerActionClick(APP_CONSTANTS.ORDER_STATUS.CUSTOMER_ACTION.CANCEL)} className='btn add-col-d-btn add-col-d-btn-left add-col-d-btn-column'>Cancel</button>
                        </div>
                    }
                    <button type='button' onClick={handleGetStatus} className='btn add-col-d-btn add-col-d-btn-left'>Get Status</button>
                    {orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.DELIVERED || orderDetailSuccess.data.orderStatus === APP_CONSTANTS.ORDER_STATUS.CANCELLED ?
                        (<button type='button' onClick={handleViewBill} className='btn add-col-d-btn add-col-d-btn-left add-col-d-btn-column'>View Bill</button>) :
                        null
                    }

                    {!isNull(orderDetailSuccess) && orderDetailSuccess.data.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && paymentModeOffline === false && paymentModeOnline === false && <button type='button' onClick={handlePayBill} className='btn add-col-d-btn add-col-d-btn-left add-col-d-btn-column'>Pay Bill</button>}
                    {!isNull(orderDetailSuccess) && orderDetailSuccess.data.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && paymentModeOnline === true && <button type='button' onClick={handlePayBillOnline} className='btn add-col-d-btn add-col-d-btn-left add-col-d-btn-column'>Pay Online</button>}
                    {!isNull(orderDetailSuccess) && orderDetailSuccess.data.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && paymentModeOffline === true && <button type='button' onClick={handleCallWaiterForPayment} className='btn add-col-d-btn add-col-d-btn-left add-col-d-btn-column'>Notify Desk</button>}
                </div>}
                {orderDetailError && <div>No Details Found</div>}
            </div>
            <Modal
                isOpen={showFeedback}
                className='col-d-modal'
                overlayClassName='col-d-modal-overlay'
                contentLabel="Feedback">
                <div className="col-d-modal-container">
                    <div className="col-d-image-container" onClick={() => setShowFeedback(!showFeedback)}>
                        <img className="col-d-img" src={require('../../../assets/close.png')} alt="close" />
                    </div>
                    <div className="col-d-card-title">Continue with mobile number</div>
                    <div className="form-floating mt-2 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required />
                        <label for="email">Name</label>
                    </div>
                    <div className="form-floating mt-2 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Review"
                            value={feedback}
                            onChange={e => setFeedback(e.target.value)}
                            required />
                        <label for="email">Review</label>
                    </div>
                    <ReactStars
                        count={5}
                        onChange={e => setRating(e)}
                        size='30'
                        activeColor="#ffd700"
                        isHalf={true}
                    />
                    <button type='button' onClick={handleSubmitFeedback} className='btn add-col-d-btn'>Submit</button>
                </div>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerOrderDetail