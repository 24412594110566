import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import { useLocation } from 'react-router-dom';
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import Modal from 'react-bootstrap/Modal';
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import {
    updateInvProductRequest, updateInvProductRequestStatus, updateInvProductConsumptionRequest,
    updateInvProductConsumptionRequestStatus, invProductDetailRequest
} from '../../../../redux/inventory/product/product.action'
import moment from "moment";

const InvProductDetail = props => {
    const { updateInvProductSuccess, updateInvProductError, updateInvProductReqStatus,
        updateInvProductConsumptionSuccess, updateInvProductConsumptionError, updateInvProductConsumptionReqStatus,
        invProductDetailSuccess, invProductDetailError } = useSelector(state => state.invProduct)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { state } = useLocation()
    const [showUpdateQtyModal, setShowUpdateQtyModal] = useState(false)
    const [showConsumeQtyModal, setShowConsumeQtyModal] = useState(false)
    const [quantity, setQuantity] = useState('')
    const [consumeQty, setConsumeQty] = useState('')
    const [personName, setPersonName] = useState('')
    const [personId, setPersonId] = useState('')

    useEffect(() => {
        fetchProductDetails()
    }, [])

    const fetchProductDetails = () => {
        setLoading(true)
        dispatch(invProductDetailRequest(state))
    }

    useEffect(() => {
        if (!isEmpty(invProductDetailSuccess)) {
            setLoading(false)
        } else if (!isEmpty(invProductDetailError)) {
            setLoading(false)
        }
    }, [invProductDetailSuccess, invProductDetailError])

    useEffect(() => {
        if (updateInvProductReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateInvProductSuccess.message)
            clearFields()
            fetchProductDetails()
        } else if (updateInvProductReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateInvProductError.message)
        }
        if (updateInvProductReqStatus?.length) {
            dispatch(updateInvProductRequestStatus(''))
        }
    }, [updateInvProductSuccess, updateInvProductError, updateInvProductReqStatus])

    useEffect(() => {
        if (updateInvProductConsumptionReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateInvProductConsumptionSuccess.message)
            clearFields()
            fetchProductDetails()
        } else if (updateInvProductConsumptionReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateInvProductConsumptionError.message)
        }
        if (updateInvProductConsumptionReqStatus?.length) {
            dispatch(updateInvProductConsumptionRequestStatus(''))
        }
    }, [updateInvProductConsumptionSuccess, updateInvProductConsumptionError, updateInvProductConsumptionReqStatus])

    const clearFields = () => {
        setQuantity('')
        setConsumeQty('')
        setPersonName('')
        setPersonId('')
    }

    const handleUpdateQuantitySubmitClick = () => {
        if (isEmpty(invProductDetailSuccess)) {
            displayErrorToast('Product information not available. Please try again')
        } else if (isEmpty(quantity)) {
            displayErrorToast('Product quantity cannot be blank')
        } else if (parseFloat(quantity) < 0) {
            displayErrorToast('Invalid product quantity')
        } else {
            setLoading(true)
            const data = {
                invProductId: invProductDetailSuccess.data.invProductId,
                availabelQuantity: parseFloat(quantity)
            }
            if (parseFloat(quantity) > 0) {
                data.isAvailable = true
            }
            dispatch(updateInvProductRequest(data))
            setShowUpdateQtyModal(false)
        }
    }

    const handleConsumeQuantitySubmitClick = () => {
        if (isEmpty(invProductDetailSuccess)) {
            displayErrorToast('Product information not available. Please try again')
        } else if (isEmpty(consumeQty)) {
            displayErrorToast('Consume quantity cannot be blank')
        } else if (parseFloat(consumeQty) < 0) {
            displayErrorToast('Invalid consume quantity')
        } else if (isEmpty(personName)) {
            displayErrorToast('Person name cannot be blank')
        } else {
            setLoading(true)
            const data = {
                invProductId: invProductDetailSuccess.data.invProductId,
                quantity: parseFloat(consumeQty),
                name: personName
            }
            if (isEmpty(personId)) {
                data.empCode = personId
            }
            dispatch(updateInvProductConsumptionRequest(data))
            setShowConsumeQtyModal(false)
        }
    }

    const handleConsumeProductClick = () => {
        if (!isEmpty(invProductDetailSuccess)) {
            if (parseFloat(invProductDetailSuccess.data.availabelQuantity) <= 0) {
                displayErrorToast('This product is out of stock')
            } else {
                setShowConsumeQtyModal(true)
            }
        } else {
            displayErrorToast('Product details not available')
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Product Details' />
                </div>
                {!isEmpty(invProductDetailSuccess) && !isEmpty(invProductDetailSuccess.data) && <CommonFormBorder>
                    <div className="inv-pd-container">
                        <div className={invProductDetailSuccess.data.isAvailable === true ? 'inv-pd-active' : 'inv-pd-in-active'}>{invProductDetailSuccess.data.isAvailable ? 'Active' : 'In Active'}</div>
                        <div className="ipd-product-label">{invProductDetailSuccess.data.name}</div>
                        <FormFieldLabel label='Product Code' />
                        <div className="ipd-product-item-val">{invProductDetailSuccess.data.productCode}</div>
                        {!isEmpty(invProductDetailSuccess.data.description) && <div>
                            <FormFieldLabel label='Description' />
                            <div className="ipd-product-item-val">{invProductDetailSuccess.data.description}</div>
                        </div>}
                        {!isEmpty(invProductDetailSuccess.data.categoryDetails) && !isEmpty(invProductDetailSuccess.data.categoryDetails.categorySubType) && <div>
                            <FormFieldLabel label='Category Sub Type' />
                            <div className="ipd-product-item-val">{invProductDetailSuccess.data.categoryDetails.categorySubType}</div>
                        </div>}
                        <FormFieldLabel label='Brand' />
                        <div className="ipd-product-item-val">{invProductDetailSuccess.data.brand}</div>
                        <FormFieldLabel label='Available Quantity' />
                        <div className="ipd-product-item-val">{invProductDetailSuccess.data.availabelQuantity} {invProductDetailSuccess.data.productQuantityType}</div>
                        <FormFieldLabel label='Threshold Quantity' />
                        <div className="ipd-product-item-val">{invProductDetailSuccess.data.thresholdQuantity}</div>
                        <div className="ipd-row-container">
                            <div className="ipd-row-item">Product Type</div>
                            <div className="ipd-row-item">{removeSpecialCharAndChangeToPascal(invProductDetailSuccess.data.productType)}</div>
                        </div>
                        <div className="ipd-row-container">
                            <div className="ipd-row-item">Product Sub-Type</div>
                            <div className="ipd-row-item">{removeSpecialCharAndChangeToPascal(invProductDetailSuccess.data.productSubType)}</div>
                        </div>
                        <div className="ipd-row-container">
                            <div className="ipd-row-item">Product Selling Type</div>
                            <div className="ipd-row-item">{removeSpecialCharAndChangeToPascal(invProductDetailSuccess.data.productSellingType)}</div>
                        </div>
                        <FormFieldLabel className='up-bold-label' label='Pricing Information' />
                        <FormFieldLabel label='Purchase Price' />
                        <div className="ipd-row-container">
                            <div className="ipd-row-item">Price</div>
                            <div className="ipd-row-item">{invProductDetailSuccess.data.purchasePrice}</div>
                        </div>
                        <div className="ipd-row-container">
                            <div className="ipd-row-item">Tax Included</div>
                            <div className="ipd-row-item">{invProductDetailSuccess.data.purchaseTaxIncluded ? 'Yes' : 'No'}</div>
                        </div>
                        {invProductDetailSuccess.data.purchaseTaxIncluded === true && <div className="ipd-row-container">
                            <div className="ipd-row-item">Tax %</div>
                            <div className="ipd-row-item">{invProductDetailSuccess.data.purchaseTaxPercentage}</div>
                        </div>}
                        <div className="ipd-row-container">
                            <div className="ipd-row-item">Final Price</div>
                            <div className="ipd-row-item">{invProductDetailSuccess.data.purchaseFinalPrice}</div>
                        </div>
                        {invProductDetailSuccess.data.categoryDetails.categoryType === 'CONSUMABLE' && <div>
                            <FormFieldLabel label='Selling Price' />
                            <div className="ipd-row-container">
                                <div className="ipd-row-item">Price</div>
                                <div className="ipd-row-item">{invProductDetailSuccess.data.sellingPrice}</div>
                            </div>
                            <div className="ipd-row-container">
                                <div className="ipd-row-item">Tax Included</div>
                                <div className="ipd-row-item">{invProductDetailSuccess.data.sellingTaxIncluded ? 'Yes' : 'No'}</div>
                            </div>
                            {invProductDetailSuccess.data.sellingTaxIncluded === true && <div className="ipd-row-container">
                                <div className="ipd-row-item">Tax %</div>
                                <div className="ipd-row-item">{invProductDetailSuccess.data.sellingTaxPercentage}</div>
                            </div>}
                            <div className="ipd-row-container">
                                <div className="ipd-row-item">Final Price</div>
                                <div className="ipd-row-item">{invProductDetailSuccess.data.sellingFinalPrice}</div>
                            </div>
                        </div>}
                        <FormFieldLabel className='up-bold-label' label='Consumption List' />
                        {isEmpty(invProductDetailSuccess.data.consumptionQuantityList) && <div className="content-error">No consumption record found</div>}
                        {!isEmpty(invProductDetailSuccess.data.consumptionQuantityList) && <div>
                            {invProductDetailSuccess.data.consumptionQuantityList.map(element => (<div className="ipd-consumption-container">
                                <div className="ipd-date-text">{moment(element.date).utc().format('DD MMM YYYY')}</div>
                                <div className="ipd-content-text">{element.name} {!isEmpty(element.empCode) ? element.empCode : ''}</div>
                                <div className="ipd-quantity-label">Quantity: {element.quantity}</div>
                            </div>))}
                        </div>}
                        <ButtonRow isVertical={true}>
                            <CommonButton isBig={true} label='Update Quantity' onClick={() => { setShowUpdateQtyModal(true) }} />
                            <CommonButton isBig={true} label='Consume Product' onClick={() => { handleConsumeProductClick() }} />
                        </ButtonRow>
                    </div>
                </CommonFormBorder>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowUpdateQtyModal(!showUpdateQtyModal) }}
                show={showUpdateQtyModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Quantity
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='number'
                        id='quantity'
                        placeholder='Enter Quantity'
                        value={quantity}
                        onChange={e => setQuantity(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleUpdateQuantitySubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowConsumeQtyModal(!showConsumeQtyModal) }}
                show={showConsumeQtyModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Consume Product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='number'
                        id='consumeQty'
                        placeholder='Enter Quantity'
                        value={consumeQty}
                        onChange={e => setConsumeQty(e.target.value)}
                        isSubtext={true}
                        subText='Quantity to consume' />
                    <CommonInputFull type='text'
                        id='personName'
                        placeholder='Person Name'
                        value={personName}
                        onChange={e => setPersonName(e.target.value)}
                        isSubtext={true}
                        subText='Person consuming product' />
                    <CommonInputFull type='text'
                        id='personId'
                        placeholder='Person Id'
                        value={personId}
                        onChange={e => setPersonId(e.target.value)}
                        isSubtext={true}
                        subText='Ex. Employee Id/Person Id' />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleConsumeQuantitySubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvProductDetail