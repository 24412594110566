import { DeliveryTerminalActionTypes } from './delivery-terminal.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addTerminalSuccess: null,
    addTerminalError: null,
    addTerminalStatus: '',
    temrinalListSuccess: null,
    temrinalListError: null,
    updateTerminalStatusSuccess: null,
    updateTerminalStatusError: null,
    updateTerminalStatusReqStatus: ''
}

const deliveryTerminalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST_SUCCESS:
            return {
                ...state,
                addTerminalSuccess: action.payload,
                addTerminalError: null,
                addTerminalStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST_ERROR:
            return {
                ...state,
                addTerminalSuccess: null,
                addTerminalError: action.payload,
                addTerminalStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST_STATUS:
            return {
                ...state,
                addTerminalStatus: ''
            }
        case DeliveryTerminalActionTypes.TERMINAL_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                temrinalListSuccess: action.payload,
                temrinalListError: null
            }
        case DeliveryTerminalActionTypes.TERMINAL_LIST_REQUEST_ERROR:
            return {
                ...state,
                temrinalListSuccess: null,
                temrinalListError: action.payload
            }
        case DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateTerminalStatusSuccess: action.payload,
                updateTerminalStatusError: null,
                updateTerminalStatusReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateTerminalStatusSuccess: null,
                updateTerminalStatusError: action.payload,
                updateTerminalStatusReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateTerminalStatusReqStatus: ''
            }
        case DeliveryTerminalActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default deliveryTerminalReducer