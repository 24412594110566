import { OrderInvoiceActionTypes } from './orderInvoice.types'
import { API_CONSTANTS } from '../../../utils/AppConstants'

const INITIAL_STATE = {
    invAddOrderInvoiceSuccess: null,
    invAddOrderInvoiceError: null,
    invAddOrderInvoiceReqStatus: '',
    invOrderInvoiceDetailSuccess: null,
    invOrderInvoiceDetailError: null,
    invOrderInvoiceListSuccess: null,
    invOrderInvoiceListError: null,
    updateInvOrderInvoicePaymentSuccess: null,
    updateInvOrderInvoicePaymentError: null,
    updateInvOrderInvoicePaymentReqStatus: ''
}

const invOrderInvoiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST_SUCCESS:
            return {
                ...state,
                invAddOrderInvoiceSuccess: action.payload,
                invAddOrderInvoiceError: null,
                invAddOrderInvoiceReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST_ERROR:
            return {
                ...state,
                invAddOrderInvoiceSuccess: null,
                invAddOrderInvoiceError: action.payload,
                invAddOrderInvoiceReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST_STATUS:
            return {
                ...state,
                invAddOrderInvoiceReqStatus: ''
            }
        case OrderInvoiceActionTypes.INV_ORDER_INVOICE_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                invOrderInvoiceDetailSuccess: action.payload,
                invOrderInvoiceDetailError: null
            }
        case OrderInvoiceActionTypes.INV_ORDER_INVOICE_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                invOrderInvoiceDetailSuccess: null,
                invOrderInvoiceDetailError: action.payload
            }
        case OrderInvoiceActionTypes.INV_ORDER_INVOICE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                invOrderInvoiceListSuccess: action.payload,
                invOrderInvoiceListError: null
            }
        case OrderInvoiceActionTypes.INV_ORDER_INVOICE_LIST_REQUEST_ERROR:
            return {
                ...state,
                invOrderInvoiceListSuccess: null,
                invOrderInvoiceListError: action.payload
            }
        case OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                updateInvOrderInvoicePaymentSuccess: action.payload,
                updateInvOrderInvoicePaymentError: null,
                updateInvOrderInvoicePaymentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_ERROR:
            return {
                ...state,
                updateInvOrderInvoicePaymentSuccess: null,
                updateInvOrderInvoicePaymentError: action.payload,
                updateInvOrderInvoicePaymentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_STATUS:
            return {
                ...state,
                updateInvOrderInvoicePaymentReqStatus: ''
            }
        case OrderInvoiceActionTypes.LOGOUT:
            return INITIAL_STATE
        case OrderInvoiceActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default invOrderInvoiceReducer