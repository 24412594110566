import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { requestTicketListRequest } from '../../../redux/request-ticket/request-ticket.action'
import { convertToPascal, displayErrorToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import CommonButton from "../../../components/button/common-button/commonButton";
import RefreshButton from "../../../components/button/refresh-button/refreshButton";
import ScreenLabel from "../../../components/screen-label/screenLabel";

const SupportRequestTicketListScreen = props => {
    const { user } = useSelector(state => state.user)
    const { requestTicketListSuccess, requestTicketListError } = useSelector(state => state.requestTicket)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    let isFirstTime = false
    let navigate = useNavigate();
    const [restaurantId, setRestaurantId] = useState('')
    const [locationId, setLocationId] = useState('')

    useEffect(() => {
        const data = {
            screen: 'SUPPORT_REQUEST_TICKET_LIST',
            screenClass: 'SUPPORT_REQUEST_TICKET_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(requestTicketListSuccess)) {
            setLoading(false)
        } else if (!isNull(requestTicketListError)) {
            setLoading(false)

        }
    }, [requestTicketListSuccess, requestTicketListError])

    useEffect(() => {
        fetchAllRequestTicketList()
    }, [])

    const fetchAllRequestTicketList = () => {
        setLoading(true)
        const data = {
            productName: APP_CONSTANTS.PRODUCT_NAME
        }
        dispatch(requestTicketListRequest(data))
    }

    const fetchRequestTicketList = () => {
        setLoading(true)
        const data = {
            productName: APP_CONSTANTS.PRODUCT_NAME
        }
        if (!isEmpty(restaurantId)) {
            data.businessId = restaurantId
        }
        if (!isEmpty(locationId)) {
            data.locationId = locationId
        }
        dispatch(requestTicketListRequest(data))
    }

    const handleCardClick = item => {
        navigate(ROUTES.sRequestTicketDetail, { state: item.ticketId });
    }

    const handleResetClick = () => {
        setRestaurantId('')
        setLocationId('')
        fetchAllRequestTicketList()
    }

    return (
        <>
            <div className="srqtl-list-container">
                <div className="srqtl-label-container">
                    <ScreenLabel label='Tickets' />
                    <RefreshButton label='Refresh'
                        onClick={() => { handleResetClick() }} />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 srqtl-row-margin">
                            <div className="form-floating srqtl-input">
                                <input
                                    type="name"
                                    className="form-control srqtl-input-text"
                                    id="name"
                                    placeholder="Restaurant Id"
                                    value={restaurantId}
                                    onChange={e => setRestaurantId(e.target.value)}
                                    required />
                                <label for="name">Restaurant Id</label>
                            </div>
                        </div>
                        <div className="col-sm-4 srqtl-row-margin">
                            <div className="form-floating srqtl-input">
                                <input
                                    type="name"
                                    className="form-control srqtl-input-text"
                                    id="name"
                                    placeholder="Location Id"
                                    value={locationId}
                                    onChange={e => setLocationId(e.target.value)}
                                    required />
                                <label for="name">Location Id</label>
                            </div>
                        </div>
                        <div className="col-sm-4 srqtl-button-container srqtl-row-margin">
                            <CommonButton label='Search' onClick={() => fetchRequestTicketList()} />
                            <CommonButton label='Reset' isInverted={true} onClick={() => handleResetClick()} />
                        </div>
                    </div>
                </div>

                {requestTicketListSuccess && requestTicketListSuccess.data && <table class="table table-striped table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col" className="rl-table-col-title">Title</th>
                            <th scope="col" className="rl-table-col-title text-center">Priority</th>
                            <th scope="col" className="rl-table-col-title text-center">Status</th>
                            <th scope="col" className="rl-table-col-title text-center">Last Updated</th>
                            <th scope="col" className="rl-table-col-title text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requestTicketListSuccess.data.map(element => (
                            <tr className="rl-table-row-container">
                                <td className="rl-table-col-item">{element.title}</td>
                                <td className="rl-table-col-item text-center">{convertToPascal(element.priority)}</td>
                                <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.ticketStatus)}</td>
                                <td className="rl-table-col-item text-center">{moment(element.updatedOn).utc().format('DD MMM YYYY')}</td>
                                <td className="rl-table-col-item text-center" onClick={() => { handleCardClick(element) }}>Detail</td>
                            </tr>
                        ))}

                    </tbody>
                </table>}
                {requestTicketListError && <div>No Ticket Found</div>}

            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SupportRequestTicketListScreen