import React, { useEffect, useState } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { addItemInCart, reduceItemQty } from '../../../redux/cart/cart.action'
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import {
    calculateDiscountedPrice,
    displayCurrencySymbolLeft, displayErrorToast, getProductMeasurmentUnit, isEmpty, isImageAvailable, isNull, priceWithSymbol, screenCaptureAnalytics
} from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import { customerLoginRequest } from '../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import FormFieldLabel from "../../../components/form-field-label/formFieldLabel";
import CommonButton from "../../../components/button/common-button/commonButton";
import { config } from "../../../config";

const CartList = props => {
    const dispatch = useDispatch()
    const { customerLoginSuccess, customerLoginError, restaurantDetailSuccess } = useSelector(state => state.user)
    const { cartItem } = useSelector(state => state.cart)
    const [isLoading, setLoading] = useState(false)
    let navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(false)
    const [mobileNumber, setMobileNumber] = useState()
    const [openCartReview, setOpenCartReview] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_CART_LIST',
            screenClass: 'CUSTOMER_CART_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(customerLoginSuccess)) {
            setLoading(false)
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(customerLoginSuccess.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, customerLoginSuccess.data.authToken)

            if (openCartReview === true) {
                setOpenCartReview(false)
                navigate(ROUTES.cartReview)
            }
        } else if (!isNull(customerLoginError)) {
            setLoading(false)
            displayErrorToast(customerLoginError.message)
        }
    }, [customerLoginSuccess, customerLoginError])

    const updateQty = element => {
        const item = {
            ...element,
            isFromCart: true
        }
        dispatch(addItemInCart(item))
    }

    const reduceQty = element => {
        dispatch(reduceItemQty(element))
    }

    const handlePlaceOrderClick = () => {
        if (isNull(cartItem)) {
            displayErrorToast('No item in cart found. Please add and then place order')
        } else {
            if (!isNull(customerLoginSuccess)) {
                navigate(ROUTES.cartReview)
            } else {
                setOpenCartReview(true)
                setShowLogin(true)
            }
        }
    }

    const handleSignIn = () => {
        // const numberRegex = new RegExp('^[0-9]+$')
        if (isNull(mobileNumber)) {
            displayErrorToast('Mobile number cannot be blank')
        }
        // else if (!numberRegex.test(mobileNumber)) {
        //     displayErrorToast('Invalid mobile number')
        // }
        else {
            setLoading(true)
            setShowLogin(false)
            const mobile = mobileNumber.replace('+', "")
            const data = {
                mobile: Number(mobile),
                notificationToken: !isEmpty(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN)) ? localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN) : ''
            }
            dispatch(customerLoginRequest(data))
        }
    }

    return (
        <div className="crl-container mb-4">
            {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                <div className="cmc-label-container mt-2">
                    <div className="mcp-logo-container">
                        {isImageAvailable(restaurantDetailSuccess.data.image) ?
                            (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} alt="restaurant" className="img" />) :
                            <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="restaurant" />}
                    </div>
                    <div className="mcp-restaurant-label">Cart</div>
                </div>
            ) :
                (<div className="cmc-label-container">
                    <ScreenLabel label='Cart' />
                </div>)
            }
            <div className="cmc-content-container mt-2">
                {cartItem && cartItem.map(element => (
                    <div className="add-crl-row">
                        <div className="crl-item-label">{element.name}</div>
                        {/* {<div className="crl-amount-label">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>} */}
                        {element.discount > 0 ? (<div className="crl-amount-label"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>) : (<div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                        {!isEmpty(element.options) && <div className="mt-1">
                            <div className="crl-customization-label">Options</div>
                            <div className="crl-customization-container">
                                {element.options.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                            </div>
                        </div>}
                        {!isEmpty(element.addOns) && <div className="mt-1">
                            <div className="crl-customization-label">Add-ons</div>
                            <div className="crl-customization-container">
                                {element.addOns.map(e => (<div className="crl-customization-item">{e.name}</div>))}
                            </div>
                        </div>}
                        <div className="add-crl-btn-row">
                            <div className="crl-update-qty-container">
                                <div className="add-crl-img-container" onClick={() => reduceQty(element)}>
                                    <img className="add-crl-img" src={require('../../../assets/minus.png')} alt="minus" />
                                </div>
                                <div className="crl-qty-update-label">{element.quantity}</div>
                                <div className="add-crl-img-container" onClick={() => updateQty(element)}>
                                    <img className="add-crl-img" src={require('../../../assets/plus.png')} alt="plus" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {!cartItem && <div>Your cart is empty</div>}
            {cartItem &&
                <button type="button" className="btn add-crl-btn crl-right-margin" onClick={() => { handlePlaceOrderClick() }}>Continue</button>
            }
            <Modal
                onHide={() => { setShowLogin(!showLogin) }}
                show={showLogin}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Continue with mobile number' />
                    <PhoneInput
                        defaultCountry="US"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={setMobileNumber} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={handleSignIn} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </div>
    )
}

export default CartList