import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addOrderInvoice, orderInvoiceDetail, orderInvoiceList, updateOrderInvoicePayment
} from '../../api-requests/inventory/orderInvoice';
import {
    addInvOrderInvoiceRequestError, addInvOrderInvoiceRequestSuccess, invOrderInvoiceDetailRequestError,
    invOrderInvoiceDetailRequestSuccess, invOrderInvoiceListRequestError, invOrderInvoiceListRequestSuccess,
    updateInvOrderInvoicePaymentRequestError, updateInvOrderInvoicePaymentRequestSuccess
} from './orderInvoice.action';
import { OrderInvoiceActionTypes } from './orderInvoice.types'
import { displayConsoleLog, isNull } from '../../../utils/Utils'
import { decryptResponseData } from '../../../utils/DataDecryptionUtils';

function* handleUpdateInvOrderInvoicePayment({ payload }) {
    try {
        const response = yield call(updateOrderInvoicePayment, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateInvOrderInvoicePaymentRequestSuccess(decryptedData))
            } else {
                yield put(updateInvOrderInvoicePaymentRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(updateInvOrderInvoicePaymentRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(updateInvOrderInvoicePaymentRequestError(error.response.data))
        }
    }
}

export function* updateInvOrderInvoicePaymentReq() {
    yield takeLatest(OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST, handleUpdateInvOrderInvoicePayment)
}

function* handleInvOrderInvoiceList({ payload }) {
    try {
        const response = yield call(orderInvoiceList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invOrderInvoiceListRequestSuccess(decryptedData))
            } else {
                yield put(invOrderInvoiceListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(invOrderInvoiceListRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(invOrderInvoiceListRequestError(error.response.data))
        }
    }
}

export function* invOrderInvoiceListReq() {
    yield takeLatest(OrderInvoiceActionTypes.INV_ORDER_INVOICE_LIST_REQUEST, handleInvOrderInvoiceList)
}

function* handleInvOrderInvoiceDetail({ payload }) {
    try {
        const response = yield call(orderInvoiceDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invOrderInvoiceDetailRequestSuccess(decryptedData))
            } else {
                yield put(invOrderInvoiceDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(invOrderInvoiceDetailRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(invOrderInvoiceDetailRequestError(error.response.data))
        }
    }
}

export function* invOrderInvoiceDetailReq() {
    yield takeLatest(OrderInvoiceActionTypes.INV_ORDER_INVOICE_DETAIL_REQUEST, handleInvOrderInvoiceDetail)
}

function* handleAddInvOrderInvoice({ payload }) {
    try {
        const response = yield call(addOrderInvoice, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addInvOrderInvoiceRequestSuccess(decryptedData))
            } else {
                yield put(addInvOrderInvoiceRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(addInvOrderInvoiceRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(addInvOrderInvoiceRequestError(error.response.data))
        }
    }
}

export function* addInvOrderInvoiceReq() {
    yield takeLatest(OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST, handleAddInvOrderInvoice)
}