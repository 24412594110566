import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../../components/screen-label/screenLabel";
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import CommonForm from "../../../../../components/common-form/commonForm";
import SubTextLabel from "../../../../../components/sub-text-label/subTextLabel";
import CommonColorForm from "../../../../../components/common-color-form/commonColorForm";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import CommonInputRow from "../../../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../../../components/common-input-half/commonInputHalf";
import CommonInputFull from "../../../../../components/common-input-full/commonInputFull";
import ButtonRow from "../../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../../components/button/common-button/commonButton";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, isValidEmail } from "../../../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../../utils/AppConstants";
import { registerBusinessUserRequest, registerBusinessUserRequestStatus } from '../../../../../redux/user/user.action'

const AddBusinessOwner = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState()
    const { registerBusinessUserSuccess, registerBusinessUserError, registerBusinessUserReqStatus } = useSelector(state => state.user)

    useEffect(() => {
        if (registerBusinessUserReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(registerBusinessUserSuccess.message)
            clearFields()
        } else if (registerBusinessUserReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(registerBusinessUserError.message)
        }
        if (registerBusinessUserReqStatus?.length) {
            dispatch(registerBusinessUserRequestStatus(''))
        }
    }, [registerBusinessUserSuccess, registerBusinessUserError, registerBusinessUserReqStatus])

    const clearFields = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setMobile()
    }

    const handleSubmitClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(email)) {
            displayErrorToast('Invalid email id')
        } else if (isNull(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else {
            setLoading(true)
            const mobileWithPlusSymbol = mobile.replace('+', "")
            const data = {
                email: email.toLowerCase(),
                mobile: parseInt(mobileWithPlusSymbol),
                firstName: firstName,
                lastName: lastName,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(registerBusinessUserRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Business Owner' />
                <CommonColorForm>
                    <SubTextLabel label='Enter details to add register new user' />
                    <CommonInputRow>
                        <CommonInputHalf
                            type='text'
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder='First Name'
                            value={firstName}
                        />
                        <CommonInputHalf
                            type='text'
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name'
                            value={lastName}
                        />
                    </CommonInputRow>
                    <CommonInputFull
                        type='text'
                        id='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Email'
                        value={email}
                    />
                    <div className="select-full-width">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <ButtonRow>
                        <CommonButton
                            label='Submit'
                            onClick={() => { handleSubmitClick() }}
                        />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddBusinessOwner