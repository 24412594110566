export const RequestTicketType = [
    {
        key: 'LOW',
        value: 'Low'
    },
    {
        key: 'MEDIUM',
        value: 'Medium'
    },
    {
        key: 'HIGH',
        value: 'High'
    },
    {
        key: 'CRITICAL',
        value: 'Critical'
    },
    {
        key: 'BLOCKER',
        value: 'Blocker'
    }
]

export const RequestTicketStatus = [
    {
        key: 'IN_PROCESS',
        value: 'In Process'
    },
    {
        key: 'CUSTOMER_ACTION',
        value: 'Customer Action'
    },
    {
        key: 'CANCELLED',
        value: 'Cancelled'
    },
    {
        key: 'COMPLETED',
        value: 'Complete'
    }
]