import { OrderInvoiceActionTypes } from './orderInvoice.types'

//Add Order Invoice
export const addInvOrderInvoiceRequest = data => ({
    type: OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST,
    payload: data
})

export const addInvOrderInvoiceRequestSuccess = data => ({
    type: OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST_SUCCESS,
    payload: data
})

export const addInvOrderInvoiceRequestError = data => ({
    type: OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST_ERROR,
    payload: data
})

export const addInvOrderInvoiceRequestStatus = data => ({
    type: OrderInvoiceActionTypes.INV_ADD_ORDER_INVOICE_REQUEST_STATUS,
    payload: data
})

//Order Invoice Detail
export const invOrderInvoiceDetailRequest = data => ({
    type: OrderInvoiceActionTypes.INV_ORDER_INVOICE_DETAIL_REQUEST,
    payload: data
})

export const invOrderInvoiceDetailRequestSuccess = data => ({
    type: OrderInvoiceActionTypes.INV_ORDER_INVOICE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const invOrderInvoiceDetailRequestError = data => ({
    type: OrderInvoiceActionTypes.INV_ORDER_INVOICE_DETAIL_REQUEST_ERROR,
    payload: data
})

//Order Invoice List
export const invOrderInvoiceListRequest = data => ({
    type: OrderInvoiceActionTypes.INV_ORDER_INVOICE_LIST_REQUEST,
    payload: data
})

export const invOrderInvoiceListRequestSuccess = data => ({
    type: OrderInvoiceActionTypes.INV_ORDER_INVOICE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const invOrderInvoiceListRequestError = data => ({
    type: OrderInvoiceActionTypes.INV_ORDER_INVOICE_LIST_REQUEST_ERROR,
    payload: data
})

//Update Order Invoice Payment
export const updateInvOrderInvoicePaymentRequest = data => ({
    type: OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST,
    payload: data
})

export const updateInvOrderInvoicePaymentRequestSuccess = data => ({
    type: OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_SUCCESS,
    payload: data
})

export const updateInvOrderInvoicePaymentRequestError = data => ({
    type: OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_ERROR,
    payload: data
})

export const updateInvOrderInvoicePaymentRequestStatus = data => ({
    type: OrderInvoiceActionTypes.INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_STATUS,
    payload: data
})