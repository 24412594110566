export const ROUTES = {
    home: `/`,
    signIn: `/signIn`,
    signUp: `/signUp`,
    dashboard: '/dashboard',
    terminalDashboard: '/terminalDashboard',
    employeeDashboard: '/employeeDashboard',
    categoryList: '/categoryList',
    addCategory: '/addCategory',
    subCategoryList: '/subCategoryList',
    addSubCategory: '/addSubCategory',
    productList: '/productList',
    addProduct: '/addProduct',
    addRestaurant: '/addRestaurant',
    restaurantList: '/restaurantList',
    addLocation: '/addLocation',
    locationList: '/locationList',
    updateProduct: '/updateProduct',
    qrCode: '/qrCode',
    orderList: '/orderList',
    employeeList: '/employeeList',
    addEmployee: '/addEmployee',
    menuCatList: '/customer/menuCat',
    menuSubCatList: '/customer/menuSubCat',
    menuItemList: '/customer/menuItemList',
    cartList: '/customer/cartList',
    customerOrderList: '/customer/orderList',
    customerOrderDetail: '/customer/orderDetail',
    privacyPolicy: '/privacyPolicy',
    cookiePolicy: '/cookiePolicy',
    termsOfService: '/termsOfService',
    cartReview: '/cartReview',
    forgotPassword: '/forgotPassword',
    editLocation: '/editLocation',
    agentLogin: '/agentLogin',
    businessList: '/businessList',
    viewBill: '/viewBill',
    deliveryTerminalList: '/deliveryTerminalList',
    addDeliveryTerminal: '/addDeliveryTerminal',
    deliveryTerminalLogin: '/deliveryTerminalLogin',
    employeeLogin: '/employeeLogin',
    placeOrder: '/placeOrder',
    employeeReviewOrder: '/employeeReviewOrder',
    orderListBoard: '/orderListBoard',
    orderPaymentSuccessUrl: '/orderPaymentSuccess',
    orderPaymentCancelUrl: '/orderPaymentCancel',
    placeReservation: '/placeReservation',
    reservationList: '/reservationList',
    reservationDetail: '/reservationDetail',
    selectWorkLocation: '/selectWorkLocation',
    requestTicketList: '/requestTicketList',
    raiseRequestTicket: '/raiseRequestTicket',
    requestTicketDetail: '/requestTicketDetail',
    supportRegistration: '/supportRegistration',
    sRequestTicketList: '/sRequestTicketList',
    sRequestTicketDetail: '/sRequestTicketDetail',
    homeNew: '/homeNew',
    contactUs: '/contactUs',
    features: '/features',
    about: '/about',
    supportBulkMenuUpload: '/sBulkMenuUpload',
    contactUsList: '/contactUsList',
    contactUsDetail: '/contactUsDetail',
    featureRequest: '/featureRequest',
    addFeatureRequest: '/addFeatureRequest',
    featureRequestDetail: '/featureRequestDetail',
    updatProfile: '/updateProfile',
    menuProductCustomize: '/menuProductCustomize',
    kitchenOrderList: '/kitchenOrderList',
    locationDetail: '/locationDetail',
    orderPaymentBoard: '/orderPaymentBoard',
    operatorRegister: '/operatorRegister',
    operatorList: '/operatorList',
    operatorDetail: '/operatorDetail',
    operatorBusinessList: '/operator/businessList',
    operatorAddBusiness: '/operator/addBusiness',
    operatorBusinessDetail: '/operator/businessDetail',
    operatorMenuList: '/operator/menuList',
    operatorAddMenuItem: '/operator/addMenuItem',
    operatorAddBusinessOwner: '/operator/addOwner',
    operatorEditMenuItem: '/operator/editMenuItem',
    customerProfile: '/customerProfile',
    subscription: {
        addSubscriptionPlan: '/subscription/addSubscriptionPlan',
        subscriptionPlanList: '/subscription/subscriptionPlanList'
    },
    inventory: {
        categoryList: '/inventory/categoryList',
        addCategory: '/inventory/addCategory',
        productList: '/inventory/productList',
        addProduct: '/inventory/addProduct',
        productDetail: '/inventory/productDetail',
        sellerList: '/inventory/sellerList',
        addSeller: '/inventory/addSeller',
        orderInvoiceList: '/inventory/orderInvoiceList',
        addOrderInvoice: '/inventory/addOrderInvoice',
        orderInvoiceDetail: '/inventory/orderInvoiceDetail'
    }
}

export const API_CONSTANTS = {
    SUCCESS_STATUS: 'SUCCESS_STATUS',
    ERROR_STATUS: 'ERROR_STATUS'
}

export const ORGANISATION_DETAIL = {
    ORGANISATION_NAME: 'GoMoBites LLC',
    ORG_ADDRESS_LINE1: '11501 Dublin Blvd Suite 200',
    ORG_ADDRESS_LINE2: 'Dublin, CA 94568',
    ORG_SUPPORT_EMAIL: 'support@gomobites.com'
}

export const APP_CONSTANTS = {
    PRODUCT_NAME: 'GOMOBITES',
    USER_TYPE: {
        ADMIN: 'ADMIN',
        EMPLOYEE: 'EMPLOYEE',
        BUSINESS: 'BUSINESS',
        SUPPORT: 'SUPPORT',
        KITCHEN: 'KITCHEN',
        ORDER_BOARD: 'ORDER_BOARD',
        ORDER_PAYMENT_BOARD: 'ORDER_PAYMENT_BOARD',
        OPERATOR: 'OPERATOR',
        OTHER_ROLE: {
            CUSTOMER: 'CUSTOMER',
            WAITER: 'WAITER'
        }
    },
    USER: 'USER',
    USER_TOKEN: 'USER_TOKEN',
    LOGOUT: 'LOGOUT',
    CUSTOMER_LOGOUT: 'CUSTOMER_LOGOUT',
    RESTAURANT_ID: 'RESTAURANT_ID',
    LOCATION_ID: 'LOCATION_ID',
    CART_ITEM: 'CART_ITEM',
    CLEAR_CART: 'CLEAR_CART',
    QR_CODE: 'QR_CODE',
    PRODUCT_CLEAR_DATA: 'CLEAR_DATA',
    PRODUCT_ITEM: 'PRODUCT_ITEM',
    FB_PRODUCT_ITEM: 'FB_PRODUCT_ITEM',
    DASHBOARD: 'DASHBOARD',
    ORDER_LIST: 'ORDER_LIST',
    EMPLOYEE_LIST: 'EMPLOYEE_LIST',
    REQUEST_TICKET_LIST: 'REQUEST_TICKET_LIST',
    RESERVATION_LIST: 'RESERVATION_LIST',
    PRODUCT_LIST: 'PRODUCT_LIST',
    LOCATION_LIST: 'LOCATION_LIST',
    RESET_STATE_CITY: 'RESET_STATE_CITY',
    DELIVERY_TERMINAL_LIST: 'DELIVERY_TERMINAL_LIST',
    PER_CONNECTION: 'PER_CONNECTION',
    UPDATE_CALL_STATE: 'UPDATE_CALL_STATE',
    EMPLOYEE_PLACE_ORDER: 'EMPLOYEE_PLACE_ORDER',
    EMPLOYEE_REVIEW_ORDER: 'EMPLOYEE_REVIEW_ORDER',
    DISPLAY_COOKIE_CONSENT: 'DISPLAY_COOKIE_CONSENT',
    STRIPE: 'STRIPE',
    CASH: 'CASH',
    PAY_AT_END: 'PAY_AT_END',
    SUCCESS_ORDER_ID: 'SUCCESS_ORDER_ID',
    SUCCESS_ORDER_NUMBER: 'SUCCESS_ORDER_NUMBER',
    SUCCESS_ORDER_BILL_ID: 'SUCCESS_ORDER_BILL_ID',
    SUCCESS_ORDER_BILL_TRANSACTION_ID: 'SUCCESS_ORDER_BILL_TRANSACTION_ID',
    ORDER_STATUS: {
        PLACED: 'PLACED',
        CUSTOMER_RESPONDED: 'CUSTOMER_RESPONDED',
        PREPARING: 'PREPARING',
        INPROCESS: 'INPROCESS',
        PREPARED: 'PREPARED',
        SERVED: 'SERVED',
        DELIVERED: 'DELIVERED',
        CANCELLED: 'CANCELLED',
        ACCEPTED: 'ACCEPTED',
        AWAITING_ONLINE_PAYMENT: 'AWAITING_ONLINE_PAYMENT',
        AWAITING_CUSTOMER_ACTION: 'AWAITING_CUSTOMER_ACTION',
        ACCEPT_REJECT_ACTION: {
            ACCEPT: 'ACCEPT',
            REJECT: 'REJECT',
            WAIT: 'WAIT'
        },
        READY_TO_SERVE: 'READY_TO_SERVE',
        CUSTOMER_ACTION: {
            CONTINUE: 'CONTINUE',
            CANCEL: 'CANCEL'
        }
    },
    ORDER_PAYMENT_STATUS: {
        PENDING: 'PENDING',
        PAID: 'PAID',
        ERROR: 'ERROR'
    },
    ORDER_PAYMENT_MODE: {
        ONLINE: 'ONLINE',
        OFFLINE: 'OFFLINE',
        PAY_AT_END: 'PAY_AT_END'
    },
    RESERVATION_STATUS: {
        PENDING: 'PENDING',
        CONFIRMED: 'CONFIRMED',
        CANCELLED: 'CANCELLED',
        REJECTED: 'REJECTED',
        CLOSED: 'CLOSED'
    },
    RESERVATION_TYPE_EVENT: 'EVENT',
    MENU_ITEM_TYPE: {
        LOCATION: 'LOCATION',
        QRCODE: 'QRCODE',
        PRODUCT: 'PRODUCT',
        ORDER: 'ORDER',
        EMPLOYEE: 'EMPLOYEE',
        RESERVATION: 'RESERVATION',
        DASHBOARD: 'DASHBOARD',
        REQUEST_TICKET: 'REQUEST_TICKET',
        INVENTORY: {
            CATEGORY_LIST: 'CATEGORY_LIST',
            PRODUCT_LIST: 'PRODUCT_LIST',
            SELLER_LIST: 'SELLER_LIST',
            ORDER_INVOICE: 'ORDER_INVOICE'
        }
    },
    MENU_ITEM_DISPLAY_NAME: {
        DASHBOARD: 'Dashboard',
        RESTAURANTS: 'Restaurants',
        LOCATIONS: 'Locations',
        QRCODE: 'QR Code',
        MENU: 'Menu',
        ORDERS: 'Orders',
        EMPLOYEES: 'Employees',
        OPERATORS: 'Operators',
        SUBSCRIPTIONS: 'Subscriptions',
        RESERVATIONS: 'Reservations',
        LOGOUT: 'Log Out',
        REQUEST_TICKET: 'Request Ticket',
        BULK_MENU_UPLOAD: 'Menu Upload',
        CONTACT_MESSAGE_LIST: 'Contact Messages',
        ADD_OWNER: 'Add Owner',
        INVENTORY: {
            CATEGORY_LIST: 'Categories',
            PRODUCT_LIST: 'Products',
            SELLER_LIST: 'Sellers',
            ORDER_INVOICE: 'Order Invoices'
        }
    },
    REQUEST_TICKET_PRIORITY: {
        LOW: 'LOW',
        MEDIUM: 'MEDIUM',
        HIGH: 'HIGH',
        CRITICAL: 'CRITICAL',
        BLOCKER: 'BLOCKER'
    },
    REQUEST_TICKET_STATUS: {
        OPEN: 'OPEN',
        IN_PROCESS: 'IN_PROCESS',
        CUSTOMER_ACTION: 'CUSTOMER_ACTION',
        CANCELLED: 'CANCELLED',
        COMPLETED: 'COMPLETED'
    },
    CONTACT_US_MESSAGE_STATUS: {
        OPEN: 'OPEN',
        IN_PROCESS: 'IN_PROCESS',
        ON_HOLD: 'ON_HOLD',
        CLOSED: 'CLOSED'
    },
    UNAUTHORIZE_TO_PERFORM_OPERATION_MSG: 'You are authorized to perform this operation',
    ENVIRONMENT: {
        LOCAL: 'LOCAL',
        LOCAL_CUSTOMER: 'LOCAL_CUSTOMER',
        DEV: 'DEV',
        PROD: 'PROD'
    },
    ORDER_DELIVERY_TYPE: {
        NOT_APPLICABLE: 'NOT_APPLICABLE',
        DINE_IN: 'DINE_IN',
        CURBSIDE: 'CURBSIDE',
        ROOM: 'ROOM'
    },
    ORDER_PAYMENT_MODE_LABEL: {
        ONLINE: 'Online Payments (Powered By Stripe)',
        OFFLINE: 'Offline Payments (Pay At The Counter)',
        PAY_AT_END: 'Pay At The End'
    },
    KEY_FCM_TOKEN: 'KEY_FCM_TOKEN',
    ITEM_TYPE: {
        VEG: 'VEG',
        NON_VEG: 'NON_VEG',
        VEGAN: 'VEGAN'
    },
    WAITER_REQUEST_TYPE: {
        TABLE_NUMBER: 'TABLE_NUMBER',
        ROOM_NUMBER: 'ROOM_NUMBER'
    },
    KEY_ADD_ON: 'KEY_ADD_ON',
    KEY_OPTION: 'KEY_OPTION',
    SESSION_EXPIRE_MESSAGE: 'Your session expire. Please try login again',
    COMMON_ERROR: 'COMMON_ERROR',
    SHOW_CALL_WAITER_REQUEST: 'SHOW_CALL_WAITER_REQUEST',
    YES: 'YES',
    NO: 'NO',
    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE',
    SCREEN_LABEL: {
        EDIT_PRODUCT: 'EDIT_PRODUCT'
    },
    REDUCER_ACTIONS: {
        CLEAR_PRODUCT_DETAIL: 'CLEAR_PRODUCT_DETAIL'
    },
    ORDER_ITEM_NOT_AVAILABLE_OPTION: {
        CANCEL_ORDER: 'CANCEL_ORDER',
        CONTINUE_WTIH_AVAILABLE_ITEMS: 'CONTINUE_WTIH_AVAILABLE_ITEMS',
        ASK_THE_CUSTOMER: 'ASK_THE_CUSTOMER'
    },
    EMPLOYEE_TYPE: {
        OPERATIONS: 'OPERATIONS',
        CAPTAIN: 'CAPTAIN',
        WAITER: 'WAITER',
        OTHER: 'OTHER'
    },
    INVENTORY: {
        CATEGORY_LIST: 'CATEGORY_LIST',
        ADD_CATEGORY: 'ADD_CATEGORY',
        SELLER_LIST: 'SELLER_LIST',
        ADD_SELLER: 'ADD_SELLER',
        PRODUCT_LIST: 'PRODUCT_LIST',
        ADD_PRODUCT: 'ADD_PRODUCT',
        UPDATE_PRODUCT: 'UPDATE_PRODUCT',
        ORDER_INVOICE_LIST: 'ORDER_INVOICE_LIST',
        ADD_ORDER_INVOICE: 'ADD_ORDER_INVOICE'
    }
}