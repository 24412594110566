import { CategoryActionTypes } from './category.types'

//Add Category
export const addInvCategoryRequest = data => ({
    type: CategoryActionTypes.INV_ADD_CATEGORY_REQUEST,
    payload: data
})

export const addInvCategoryRequestSuccess = data => ({
    type: CategoryActionTypes.INV_ADD_CATEGORY_REQUEST_SUCCESS,
    payload: data
})

export const addInvCategoryRequestError = data => ({
    type: CategoryActionTypes.INV_ADD_CATEGORY_REQUEST_ERROR,
    payload: data
})

export const addInvCategoryRequestStatus = data => ({
    type: CategoryActionTypes.INV_ADD_CATEGORY_REQUEST_STATUS,
    payload: data
})

//Category List
export const invCategoryListRequest = data => ({
    type: CategoryActionTypes.INV_CATEGORY_REQUEST_LIST,
    payload: data
})

export const invCategoryListRequestSuccess = data => ({
    type: CategoryActionTypes.INV_CATEGORY_REQUEST_LIST_SUCCESS,
    payload: data
})

export const invCategoryListRequestError = data => ({
    type: CategoryActionTypes.INV_CATEGORY_REQUEST_LIST_ERROR,
    payload: data
})

//Update Category
export const updateInvCategoryRequest = data => ({
    type: CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST,
    payload: data
})

export const updateInvCategoryRequestSuccess = data => ({
    type: CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST_SUCCESS,
    payload: data
})

export const updateInvCategoryRequestError = data => ({
    type: CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST_ERROR,
    payload: data
})

export const updateInvCategoryRequestStatus = data => ({
    type: CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST_STATUS,
    payload: data
})