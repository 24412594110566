export const CategoryActionTypes = {
    INV_ADD_CATEGORY_REQUEST: 'INV_ADD_CATEGORY_REQUEST',
    INV_ADD_CATEGORY_REQUEST_SUCCESS: 'INV_ADD_CATEGORY_REQUEST_SUCCESS',
    INV_ADD_CATEGORY_REQUEST_ERROR: 'INV_ADD_CATEGORY_REQUEST_ERROR',
    INV_ADD_CATEGORY_REQUEST_STATUS: 'INV_ADD_CATEGORY_REQUEST_STATUS',

    INV_CATEGORY_REQUEST_LIST: 'INV_CATEGORY_REQUEST_LIST',
    INV_CATEGORY_REQUEST_LIST_SUCCESS: 'INV_CATEGORY_REQUEST_LIST_SUCCESS',
    INV_CATEGORY_REQUEST_LIST_ERROR: 'INV_CATEGORY_REQUEST_LIST_ERROR',

    INV_UPDATE_CATEGORY_REQUEST: 'INV_UPDATE_CATEGORY_REQUEST',
    INV_UPDATE_CATEGORY_REQUEST_SUCCESS: 'INV_UPDATE_CATEGORY_REQUEST_SUCCESS',
    INV_UPDATE_CATEGORY_REQUEST_ERROR: 'INV_UPDATE_CATEGORY_REQUEST_ERROR',
    INV_UPDATE_CATEGORY_REQUEST_STATUS: 'INV_UPDATE_CATEGORY_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE'
}