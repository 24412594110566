import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isLoggedInUserBusiness, isNull, screenCaptureAnalytics
} from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { categoryListRequest } from '../../../../redux/category/category.action'
import { restaurantListRequest } from '../../../../redux/user/user.action'
import { config } from '../../../../config'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import Select from 'react-select'
import moment from "moment";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";

const CategoryListScreen = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    let navigate = useNavigate();
    const { user, restuarantListSuccess, restuarantListError, employeeSelectedRestaurant } = useSelector(state => state.user)
    const { categoryList, categoryListError } = useSelector(state => state.category)
    const [selectedRestaurant, setSelectedRestaurant] = useState()

    useEffect(() => {
        const data = {
            screen: 'CATEGORY_LIST',
            screenClass: 'CATEGORY_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        setLoading(true)
        let data1 = {
            userId: user.data.userDetails.userId
        }
        dispatch(restaurantListRequest(data1))
    }, [])

    useEffect(() => {
        if (!isNull(restuarantListSuccess)) {
            setLoading(false)
            setSelectedRestaurant(restuarantListSuccess.data[0])
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            fetchCategoryList()
        }
    }, [selectedRestaurant])

    const fetchCategoryList = () => {
        const data = {
            businessId: selectedRestaurant.businessId
        }
        dispatch(categoryListRequest(data))
    }

    useEffect(() => {
        if (!isNull(categoryList)) {
            setLoading(false)
        } else if (!isNull(categoryListError)) {
            setLoading(false)
        }
    }, [categoryList, categoryListError])

    const addCategoryClickHandle = () => {
        navigate(ROUTES.addCategory)
    }

    const enableClickHandle = () => {

    }
    const disableClickHandle = () => {

    }
    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Categories' />
                </div>
                {isLoggedInUserBusiness(user) === true && <div>
                    <div className="p-list-select-heading">Select Restaurant</div>
                    {restuarantListSuccess && restuarantListSuccess.data && <Select
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                </div>}
                <div className="table-scroll-container">
                    {categoryList && categoryList.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Image</th>
                                <th scope="col" className="rl-table-col-title text-center">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categoryList.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="c-image-container">
                                        <img src={`${config.imageUrl}${element.image}`} className="img" alt="..." />
                                    </td>
                                    <td className="rl-table-col-item text-center">{element.name}</td>
                                    <td className={`rl-table-col-item text-center ${element.status === 1 ? 'c-active' : 'c-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {categoryListError && <div className="content-error">No Category Found</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CategoryListScreen