import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { addInvCategoryRequest, addInvCategoryRequestStatus } from '../../../../redux/inventory/category/category.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";

const InvAddCategory = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { addInvCategorySuccess, addInvCategoryError, addInvCategoryReqStatus } = useSelector(state => state.invCategory)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    let navigate = useNavigate();
    const [categoryName, setCategoryName] = useState('')
    const [selectedCategoryType, setSelectedCategoryType] = useState()
    const [categorySubType, setCategorySubType] = useState('')
    const CATEGORY_TYPE_LIST = [
        { key: 'CONSUMABLE', value: 'Consumable' },
        { key: 'NON_CONSUMABLE', value: 'Non-Consumable' },
    ]

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_CATEGORY, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.INVENTORY.ADD_CATEGORY) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_CATEGORY, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (addInvCategoryReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addInvCategorySuccess.message)
            clearFields()
        } else if (addInvCategoryReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addInvCategoryError.message)
        }
        if (addInvCategoryReqStatus?.length) {
            dispatch(addInvCategoryRequestStatus(''))
        }
    }, [addInvCategorySuccess, addInvCategoryError, addInvCategoryReqStatus])

    const clearFields = () => {
        setCategoryName('')
        setCategorySubType('')
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    const handleSubmitClick = () => {
        if (isEmpty(categoryName)) {
            displayErrorToast('Category name cannot be blank')
        } else if (isEmpty(selectedCategoryType)) {
            displayErrorToast('Category type not selected')
        } else if (isEmpty(categorySubType)) {
            displayErrorToast('Category sub type cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else {
            setLoading(true)
            const data = {
                name: categoryName,
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                locationId: isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation,
                productCode: user.data.userDetails.productCode,
                categoryType: selectedCategoryType.key,
                categorySubType: categorySubType
            }
            dispatch(addInvCategoryRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Add Category' />
                </div>
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new category' />
                    <CommonInputFull
                        type='text'
                        id='name'
                        onChange={e => setCategoryName(e.target.value)}
                        placeholder='Category Name'
                        value={categoryName}
                    />
                    <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedCategoryType}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={CATEGORY_TYPE_LIST}
                        placeholder='Select Category Type'
                        onChange={e => setSelectedCategoryType(e)} />
                    {!isEmpty(selectedCategoryType) && <CommonInputFull
                        type='text'
                        id='categorySubType'
                        onChange={e => setCategorySubType(e.target.value)}
                        placeholder='Category Sub Type'
                        value={categorySubType}
                        isSubtext={true}
                        subText='Ex. Assets/Crockery/Raw Vegetables'

                    />}
                    {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Restaurant" />}
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="content-error">No restaurant found</div>}
                    {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Location" />}
                    {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="content-error">No location found</div>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            isBig={true}
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonFormBorder>

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvAddCategory