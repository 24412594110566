import { ProductActionTypes } from './product.types'

//Add Product
export const addProductRequest = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST,
    payload: data
})

export const addProductRequestSuccess = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST_SUCCESS,
    payload: data
})

export const addProductRequestError = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST_ERROR,
    payload: data
})

export const addProductRequestStatus = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST_STATUS,
    payload: data
})

//Product List
export const productListRequest = data => ({
    type: ProductActionTypes.PRODUCT_LIST_REQUEST,
    payload: data
})

export const productListRequestSuccess = data => ({
    type: ProductActionTypes.PRODUCT_LIST_REQUEST_SUCCESS,
    payload: data
})

export const productListRequestError = data => ({
    type: ProductActionTypes.PRODUCT_LIST_REQUEST_ERROR,
    payload: data
})

//Product Detail
export const productDetailRequest = data => ({
    type: ProductActionTypes.PRODUCT_DETAIL_REQUEST,
    payload: data
})

export const productDetailRequestSuccess = data => ({
    type: ProductActionTypes.PRODUCT_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const productDetailRequestError = data => ({
    type: ProductActionTypes.PRODUCT_DETAIL_REQUEST_ERROR,
    payload: data
})

//Product Status Update
export const productStatusUpdateRequest = data => ({
    type: ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST,
    payload: data
})

export const productStatusUpdateRequestSuccess = data => ({
    type: ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST_SUCCESS,
    payload: data
})

export const productStatusUpdateRequestError = data => ({
    type: ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST_ERROR,
    payload: data
})

export const productStatusUpdateRequestStatus = data => ({
    type: ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST_STATUS,
    payload: data
})

//Product Detail Update
export const productUpdateRequest = data => ({
    type: ProductActionTypes.PRODUCT_UPDATE_REQUEST,
    payload: data
})

export const productUpdateRequestSuccess = data => ({
    type: ProductActionTypes.PRODUCT_UPDATE_REQUEST_SUCCESS,
    payload: data
})

export const productUpdateRequestError = data => ({
    type: ProductActionTypes.PRODUCT_UPDATE_REQUEST_ERROR,
    payload: data
})

export const productUpdateRequestStatus = data => ({
    type: ProductActionTypes.PRODUCT_UPDATE_REQUEST_STATUS,
    payload: data
})

//Add feedback
export const addFeedbackRequest = data => ({
    type: ProductActionTypes.ADD_FEEDBACK_REQUEST,
    payload: data
})

export const addFeedbackRequestSuccess = data => ({
    type: ProductActionTypes.ADD_FEEDBACK_REQUEST_SUCCESS,
    payload: data
})

export const addFeedbackRequestError = data => ({
    type: ProductActionTypes.ADD_FEEDBACK_REQUEST_ERROR,
    payload: data
})

export const addFeedbackRequestStatus = data => ({
    type: ProductActionTypes.ADD_FEEDBACK_REQUEST_STATUS,
    payload: data
})

//Bulk upload menu
export const bulkMenuUploadRequest = data => ({
    type: ProductActionTypes.BULK_UPLOAD_MENU_REQUEST,
    payload: data
})

export const bulkMenuUploadRequestSuccess = data => ({
    type: ProductActionTypes.BULK_UPLOAD_MENU_REQUEST_SUCCESS,
    payload: data
})

export const bulkMenuUploadRequestError = data => ({
    type: ProductActionTypes.BULK_UPLOAD_MENU_REQUEST_ERROR,
    payload: data
})

export const bulkMenuUploadRequestStatus = data => ({
    type: ProductActionTypes.BULK_UPLOAD_MENU_REQUEST_STATUS,
    payload: data
})