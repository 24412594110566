import { DeliveryTerminalActionTypes } from './delivery-terminal.types'

//Add Terminal
export const addTerminalRequest = data => ({
    type: DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST,
    payload: data
})

export const addTerminalRequestSuccess = data => ({
    type: DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST_SUCCESS,
    payload: data
})

export const addTerminalRequestError = data => ({
    type: DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST_ERROR,
    payload: data
})

export const addTerminalRequestStatus = data => ({
    type: DeliveryTerminalActionTypes.ADD_TERMINAL_REQUEST_STATUS,
    payload: data
})

//Terminal List
export const terminalListRequest = data => ({
    type: DeliveryTerminalActionTypes.TERMINAL_LIST_REQUEST,
    payload: data
})

export const terminalListRequestSuccess = data => ({
    type: DeliveryTerminalActionTypes.TERMINAL_LIST_REQUEST_SUCCESS,
    payload: data
})

export const terminalListRequestError = data => ({
    type: DeliveryTerminalActionTypes.TERMINAL_LIST_REQUEST_ERROR,
    payload: data
})

//Update Terminal Status
export const updateTerminalStatusRequest = data => ({
    type: DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST,
    payload: data
})

export const updateTerminalStatusRequestSuccess = data => ({
    type: DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const updateTerminalStatusRequestError = data => ({
    type: DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST_ERROR,
    payload: data
})

export const updateTerminalStatusRequestStatus = data => ({
    type: DeliveryTerminalActionTypes.UPDATE_TERMINAL_STATUS_REQUEST_STATUS,
    payload: data
})