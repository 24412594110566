import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    userDetailRequest, customerLoginRequest, displayBottomBarRequest,
    updateCustomerDetailsRequest, updateCustomerDetailsRequestStatus
} from '../../../redux/user/user.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isImageAvailable, isNull } from "../../../utils/Utils";
import Modal from 'react-bootstrap/Modal';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonForm from "../../../components/common-form/commonForm";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/common-input-half/commonInputHalf";
import CommonInputFull from "../../../components/common-input-full/commonInputFull";
import FormFieldLabel from "../../../components/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { config } from "../../../config";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";

const CustomerProfile = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const { customerLoginSuccess, customerLoginError,
        userDetailSuccess, userDetailError,
        restaurantDetailSuccess, restaurantDetailError,
        updateCustomerDetailsSuccess, updateCustomerDetailsError, updateCustomerDetailsReqStatus } = useSelector(state => state.user)
    const [showLogin, setShowLogin] = useState(false)
    const [mobileNumber, setMobileNumber] = useState()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState()
    const [email, setEmail] = useState('')

    useEffect(() => {
        if (!isNull(customerLoginSuccess)) {
            setLoading(true)
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(customerLoginSuccess.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, customerLoginSuccess.data.authToken)
            dispatch(displayBottomBarRequest(true))
            fetchUserDetails()
        } else if (!isNull(customerLoginError)) {
            setLoading(false)
            displayErrorToast(customerLoginError.message)
        }

        if (isNull(customerLoginSuccess))
            setShowLogin(true)
    }, [customerLoginSuccess, customerLoginError])

    useEffect(() => {
        if (updateCustomerDetailsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateCustomerDetailsSuccess.message)
            fetchUserDetails()
        } else if (updateCustomerDetailsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateCustomerDetailsError.message)
        }
        if (updateCustomerDetailsReqStatus?.length) {
            dispatch(updateCustomerDetailsRequestStatus(''))
        }
    }, [updateCustomerDetailsSuccess, updateCustomerDetailsError, updateCustomerDetailsReqStatus])

    useEffect(() => {
        if (!isNull(userDetailSuccess)) {
            setLoading(false)
            if (!isEmpty(userDetailSuccess.data.firstName))
                setFirstName(userDetailSuccess.data.firstName)
            if (!isEmpty(userDetailSuccess.data.lastName))
                setLastName(userDetailSuccess.data.lastName)
            if (!isEmpty(userDetailSuccess.data.email))
                setEmail(userDetailSuccess.data.email)
        } else if (!isNull(userDetailError)) {
            setLoading(false)
        }
    }, [userDetailSuccess, userDetailError])

    const fetchUserDetails = () => {
        if (!isNull(customerLoginSuccess) && !isNull(customerLoginSuccess.data)) {
            setLoading(true)
            const data = {
                userId: customerLoginSuccess.data.userDetails.userId
            }
            dispatch(userDetailRequest(data))
        }
    }

    const handleSignIn = () => {
        // const numberRegex = new RegExp('^[0-9]+$')
        if (isNull(mobileNumber)) {
            displayErrorToast('Mobile number cannot be blank')
        }
        // else if (!numberRegex.test(mobileNumber)) {
        //     displayErrorToast('Invalid mobile number')
        // }
        else {
            setLoading(true)
            setShowLogin(false)
            const mobileNum = mobileNumber.replace('+', "")
            const data = {
                mobile: Number(mobileNum),
                notificationToken: !isEmpty(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN) ? localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN) : '')
            }
            dispatch(customerLoginRequest(data))
        }
    }

    const handleLogoutClick = () => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
        dispatch(displayBottomBarRequest(true))
    }

    const showUpdateProfileButton = (data) => {
        if (isEmpty(data.firstName) || isEmpty(data.email) || isEmpty(data.mobile)) {
            return true
        } else {
            return false
        }
    }

    const handleUpdateProfileClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email id cannot be blank')
        } else {
            setLoading(true)
            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                userId: customerLoginSuccess.data.userDetails.userId
            }
            dispatch(updateCustomerDetailsRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                    <div className="cmc-label-container mt-2">
                        <div className="mcp-logo-container">
                            {isImageAvailable(restaurantDetailSuccess.data.image) ?
                                (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} alt="logo" className="img" />) :
                                <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />}
                        </div>
                        <div className="mcp-restaurant-label">{restaurantDetailSuccess.data.businessName}</div>
                    </div>
                ) :
                    (<div className="cmc-label-container">
                        <ScreenLabel label='Profile' />
                    </div>)
                }
                {!isNull(userDetailSuccess) && !isNull(userDetailSuccess.data) && <div>
                    <div className="cp-name-label">{userDetailSuccess.data.firstName} {userDetailSuccess.data.lastName}</div>
                    <CommonColorForm>
                        {isEmpty(userDetailSuccess.data.firstName) && <CommonInputRow>
                            <CommonInputHalf
                                type='text'
                                id='firstName'
                                onChange={e => setFirstName(e.target.value)}
                                placeholder='First Name'
                                value={firstName} />
                            <CommonInputHalf
                                type='text'
                                id='lastName'
                                onChange={e => setLastName(e.target.value)}
                                placeholder='Last Name'
                                value={lastName} />
                        </CommonInputRow>}
                        {isEmpty(userDetailSuccess.data.email) && <CommonInputFull
                            type='text'
                            id='email'
                            onChange={e => setEmail(e.target.value)}
                            placeholder='Email'
                            value={email}
                        />}
                        {!isEmpty(userDetailSuccess.data.email) && <div>
                            <FormFieldLabel className='label-bold' label='Email' />
                            <div className="cp-email-label">{userDetailSuccess.data.email}</div>
                        </div>}
                        {isEmpty(userDetailSuccess.data.mobile) && <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />}
                        {!isEmpty(userDetailSuccess.data.mobile) && <div>
                            <FormFieldLabel className='label-bold' label='Mobile' />
                            <div className="cp-email-label">{userDetailSuccess.data.mobile}</div>
                        </div>}
                        <FormFieldLabel className='label-bold' label='Points' />
                        <div className="cp-points-value">{!isEmpty(userDetailSuccess.data.points) ? userDetailSuccess.data.points : '0'}</div>
                        <div>

                        </div>
                        <ButtonRow isVertical={true}>
                            {showUpdateProfileButton(userDetailSuccess.data) && <CommonButton label='Update Profile'
                                onClick={() => { handleUpdateProfileClick() }} />}

                            <CommonButton label='Logout'
                                onClick={() => { handleLogoutClick() }} />
                        </ButtonRow>
                    </CommonColorForm>
                </div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowLogin(!showLogin) }}
                show={showLogin}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Continue with mobile number' />
                    <PhoneInput
                        defaultCountry="US"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={setMobileNumber} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={handleSignIn} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerProfile