import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { SocketContext, socket } from './SocketCtx'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <SocketContext.Provider value={socket}>
            <App />
          </SocketContext.Provider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Register the service worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function () {
//     const filePath = `${process.env.PUBLIC_URL}/service-worker.js`
//     // const filePath = `./service-worker.js`
//     // navigator.serviceWorker.register('./serviceWorker.js').then(function (registration) {
//     navigator.serviceWorker.register(filePath).then(function (registration) {
//       console.log('Service worker registered:', registration);
//     }, function (error) {
//       console.log('Service worker registration failed:', error);
//     });

//     navigator.serviceWorker.addEventListener('controllerchange', function () {
//       window.location.reload(true);
//     });
//   });
// }