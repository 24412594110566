import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addTerminal(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.deliveryTerminal.addTerminal}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function terminalList(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.deliveryTerminal.terminalList}`,
        params: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function updateTerminalStatus(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.deliveryTerminal.updateTerminalStatus}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}