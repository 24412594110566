import React, { useEffect, useState, useContext } from "react";
import RefreshButton from "../../../components/button/refresh-button/refreshButton";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import {
    orderListRequest, updateOrderStatusRequest,
    updateOrderStatusRequestStatus
} from '../../../redux/order/order.action'
import { clearOrderStatusNotif } from '../../../redux/socket/socket.action'
import { convertToPascal, displayErrorToast, displaySuccessToast, isEmpty, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import moment from "moment";
import { Modal } from 'react-bootstrap'
import CommonButton from "../../../components/button/common-button/commonButton";
import { SocketContext } from '../../../SocketCtx'

const KitchenOrderList = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { orderListSuccess, orderListError,
        updateOrderSuccess, updateOrderError, updateOrderStatus } = useSelector(state => state.order)
    const { orderStatusUpdateNotif } = useSelector(state => state.socket)
    const [processingOrder, setProcessingOrder] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [displayItemModal, setDisplayItemModal] = useState(false)
    const KEY = 'KEY'
    const socket = useContext(SocketContext)

    useEffect(() => {
        const data = {
            screen: 'KITCHEN_ORDER_LIST',
            screenClass: 'KITCHEN_ORDER_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(orderStatusUpdateNotif) && !isNull(user)) {
            if (orderStatusUpdateNotif.restaurantId === user.data.userDetails.restaurantId &&
                orderStatusUpdateNotif.locationId === user.data.userDetails.restaurantLocationId) {
                fetchOrderList()
                dispatch(clearOrderStatusNotif(""))
            }
        }
    }, [orderStatusUpdateNotif])

    useEffect(() => {
        if (!isNull(orderListSuccess)) {
            setLoading(false)
            const processingOrderList = orderListSuccess.data.filter(item => (item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.DELIVERED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.PLACED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.CANCELLED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.AWAITING_ONLINE_PAYMENT && item.orderStatus !== ''))
            setProcessingOrder(processingOrderList)
        } else if (!isNull(orderListError)) {
            setLoading(false)
            setProcessingOrder(null)
        }
    }, [orderListSuccess, orderListError])

    useEffect(() => {
        if (updateOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateOrderSuccess.message)
            fetchOrderList()
            const data = {
                restaurantId: selectedItem.businessId,
                locationId: selectedItem.locationId
            }
            socket.emit('order-update-status', data)
        } else if (updateOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateOrderError.message)
        }
        if (updateOrderStatus.length) {
            dispatch(updateOrderStatusRequestStatus(''))
        }
    }, [updateOrderSuccess, updateOrderError, updateOrderStatus])

    const fetchOrderList = () => {
        setLoading(true)
        const data = {
            businessId: user.data.userDetails.restaurantId,
            locationId: user.data.userDetails.restaurantLocationId
        }
        dispatch(orderListRequest(data))
    }

    const updateStatus = (status) => {
        setDisplayItemModal(false)
        const data = {
            orderId: selectedItem.orderId,
            orderStatus: status
        }
        setLoading(true)
        dispatch(updateOrderStatusRequest(data))
    }

    return (
        <>
            <CommonScreenContent>
                <div className="ols-label-container">
                    <ScreenLabel label='Orders' />
                    <RefreshButton label='Refresh'
                        onClick={() => { fetchOrderList() }} />
                </div>
                {!isNull(processingOrder) &&
                    <div className="p-table-container">
                        <table class="table table-striped table-hover mt-4">
                            <thead>
                                <tr>
                                    <th scope="col" className="rl-table-col-title">Order Number</th>
                                    <th scope="col" className="rl-table-col-title text-center">Order Code</th>
                                    <th scope="col" className="rl-table-col-title text-center">Status</th>
                                    <th scope="col" className="rl-table-col-title text-center">Date</th>
                                    <th scope="col" className="rl-table-col-title text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processingOrder.map(element => (
                                    <tr className="rl-table-row-container">
                                        <td className="rl-table-col-item">{element.orderNumber}</td>
                                        <td className="rl-table-col-item text-center">{element.orderCode}</td>
                                        <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.orderStatus)}</td>
                                        <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                        <td className="rl-table-col-item text-center" onClick={() => {
                                            setSelectedItem(element)
                                            setDisplayItemModal(true)
                                        }}><div className="kol-update-btn">View &amp; Update</div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>}
                {isNull(processingOrder) ? (<div>No order found</div>) : null}
            </CommonScreenContent>
            <Modal
                show={displayItemModal}
                onHide={() => setDisplayItemModal(!displayItemModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedItem && selectedItem.processedItems.length > 0 && <div className="ols-card-label-blue-bold mt-2">Order Items</div>}
                    {selectedItem && selectedItem.processedItems.length > 0 && <table class="table table-responsive table-hover">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedItem.processedItems.map(item => (
                                <tr className="rl-table-row-container">
                                    <td className="ols-card-label-val">
                                        <div className="ols-card-label-name-container-table">
                                            <div>{item.name}</div>
                                            {!isEmpty(item.options) && <div className="mt-1">
                                                <div className="ols-customization-label">Options</div>
                                                <div className="crl-customization-container">
                                                    {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                </div>
                                            </div>}
                                            {!isEmpty(item.addOns) && <div className="mt-1">
                                                <div className="ols-customization-label">Add-ons</div>
                                                <div className="crl-customization-container">
                                                    {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                </div>
                                            </div>}
                                        </div>
                                    </td>
                                    <td className="ols-card-label-val">
                                        <div className="ols-table-row-center">
                                            {item.quantity}
                                        </div></td>
                                </tr>))}
                        </tbody>
                    </table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Ready to Serve' onClick={() => {
                        updateStatus(APP_CONSTANTS.ORDER_STATUS.PREPARED)
                    }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default KitchenOrderList