import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { orderDetailRequest } from '../../../redux/order/order.action'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import { useLocation, useNavigate } from 'react-router-dom';
import { convertToPascal, displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, isNull } from "../../../utils/Utils";
import { APP_CONSTANTS } from "../../../utils/AppConstants";
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import ReactToPrint from 'react-to-print';

const CustomerOrderViewBill = props => {
    const dispatch = useDispatch()
    const { orderDetailSuccess, orderDetailError } = useSelector(state => state.order)
    const [isLoading, setLoading] = useState(false)
    const { state } = useLocation()
    const componentRef = useRef();

    useEffect(() => {
        fetchOrderDetails()
    }, [])

    const fetchOrderDetails = () => {
        setLoading(true)
        const data = {
            orderId: state
        }
        dispatch(orderDetailRequest(data))
    }

    useEffect(() => {
        if (!isNull(orderDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(orderDetailError)) {
            setLoading(false)
        }
    }, [orderDetailSuccess, orderDetailError])

    const handleDownloadBill = () => {
        displayConsoleLog('Download bill clicked')
        const input = document.getElementById('divToPrint')
        var HTML_Width = 2480;
        var HTML_Height = 3508;
        // var HTML_Width = $(".divToPrint").width();
        // var HTML_Height = $(".divToPrint").height();
        var top_left_margin = 15;
        var PDF_Width = HTML_Width + (top_left_margin * 2);
        var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;
        var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        // html2canvas(input, { scale: 0.5 })
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/png');
        //         const pdf = new jsPDF();
        //         // pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        //         pdf.addImage(imgData, 'JPEG', 0, 0);
        //         // pdf.output('dataurlnewwindow');
        //         pdf.save("bill.pdf");
        //     })

        html2canvas(input).then(function (canvas) {
            var imgData = canvas.toDataURL("image/jpeg", 1.0);
            var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage(PDF_Width, PDF_Height);
                pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
            }
            pdf.save("bill.pdf");
        });
    }

    const billComponent = (orderDetailSuccess) => {
        return (
            <div className="col-vb-data-container" id="divToPrint" ref={componentRef}>
                <div className="col-vb-brand-label">Powered by GoMoBites</div>
                <div className="col-vb-rest-name mt-4">{orderDetailSuccess.data.businessDetails.businessName}</div>
                <div className="col-vb-rest-add">{orderDetailSuccess.data.locationDetails.city}, {orderDetailSuccess.data.locationDetails.state}, {orderDetailSuccess.data.locationDetails.country}</div>
                {orderDetailSuccess.data.orderNumber && <div className="col-vb-item-label">Order Number </div>}
                {orderDetailSuccess.data.orderNumber && <div>{orderDetailSuccess.data.orderNumber}</div>}
                <div className="col-vb-item-label">Order Code </div>
                <div>{orderDetailSuccess.data.orderCode}</div>
                {orderDetailSuccess.data.billDetails && <div>
                    <div className="col-vb-item-label">Bill Number </div>
                    <div>{orderDetailSuccess.data.billDetails.billNumber}</div>
                </div>}
                <div className="col-vb-item-label">Name</div>
                <div>{orderDetailSuccess.data.contactPersonName}</div>
                <div className="col-vb-item-label">Mobile Number</div>
                <div>{orderDetailSuccess.data.contactPersonNumber}</div>
                <div className="col-vb-item-label">Special Request</div>
                <div>{orderDetailSuccess.data.otherInformation}</div>
                <div className="col-vb-item-label">Other details </div>
                {orderDetailSuccess.data.orderStatus === 'DELIVERED' ?
                    (
                        <div className="col-vb-item-row-container">
                            <div className="col-vb-item-row-item">Order Status</div>
                            <div className="col-vb-item-row-item">{convertToPascal(orderDetailSuccess.data.orderStatus)}</div>
                        </div>

                    ) : null}
                {orderDetailSuccess.data.orderStatus === 'CANCELLED' ?
                    (
                        <div className="col-vb-item-row-container">
                            <div className="col-vb-item-row-item">Order Status</div>
                            <div className="col-vb-item-row-item">{convertToPascal(orderDetailSuccess.data.orderStatus)}</div>
                        </div>

                    ) : null}
                <div className="col-vb-item-row-container">
                    <div className="col-vb-item-row-item">Payment Mode</div>
                    <div className="col-vb-item-row-item">{orderDetailSuccess.data.paymentMode ? orderDetailSuccess.data.paymentMode : '-'}</div>
                </div>
                <div className="col-vb-item-row-container">
                    <div className="col-vb-item-row-item">Payment Status</div>
                    <div className="col-vb-item-row-item">{orderDetailSuccess.data.paymentStatus ? convertToPascal(orderDetailSuccess.data.paymentStatus) : '-'}</div>
                </div>
                {!isNull(orderDetailSuccess.data.dineInTableNumber) ? (
                    <div className="col-vb-item-row-container">
                        <div className="col-vb-item-row-item">Table Number</div>
                        <div className="col-vb-item-row-item">{orderDetailSuccess.data.dineInTableNumber}</div>
                    </div>
                ) : null}
                {!isNull(orderDetailSuccess.data.curbsideSlotNumber) ? (
                    <div className="col-vb-item-row-container">
                        <div className="col-vb-item-row-item">Curbside Number</div>
                        <div className="col-vb-item-row-item">{orderDetailSuccess.data.curbsideSlotNumber}</div>
                    </div>
                ) : null}
                {!isNull(orderDetailSuccess.data.preparationTime) ? (
                    <div className="col-vb-item-row-container">
                        <div className="col-vb-item-row-item">Preparation Time</div>
                        <div className="col-vb-item-row-item">{orderDetailSuccess.data.preparationTime}</div>
                    </div>
                ) : null}
                {!isNull(orderDetailSuccess.data.cancelReason) ? (
                    <div>
                        <div className="col-vb-item-label">Cancel Reason</div>
                        {orderDetailSuccess.data.cancelReason}
                    </div>) : null}
                <div className="col-vb-item-label">Billed Items </div>
                {orderDetailSuccess.data.availableItemList && <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Item</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetailSuccess.data.availableItemList.map(element =>
                            <tr>
                                <th scope="row">{element.name}</th>
                                <td>{element.quantity}</td>
                                <td>
                                    {
                                        displayCurrencySymbolLeft(orderDetailSuccess.data.currencyRegion) === true ?
                                            (<div className="col-vb-amount-label">{orderDetailSuccess.data.currencySymbol} {element.price.toFixed(2)}</div>)
                                            : (<div className="col-vb-amount-label">{element.price.toFixed(2)} {orderDetailSuccess.data.currencySymbol}</div>)}
                                </td>

                            </tr>)}
                    </tbody>
                </table>}
                {
                    displayCurrencySymbolLeft(orderDetailSuccess.data.currencyRegion) === true ?
                        (<div className="col-vb-price-container">Sub total: {orderDetailSuccess.data.currencySymbol} {orderDetailSuccess.data.subTotal.toFixed(2)}</div>)
                        : (<div className="col-vb-price-container">Sub total: {orderDetailSuccess.data.subTotal.toFixed(2)} {orderDetailSuccess.data.currencySymbol}</div>)}
                {
                    displayCurrencySymbolLeft(orderDetailSuccess.data.currencyRegion) === true ?
                        (<div className="col-vb-price-container">Total Discount: {orderDetailSuccess.data.currencySymbol} {orderDetailSuccess.data.discountAmount.toFixed(2)}</div>)
                        : (<div className="col-vb-price-container">Total Discount: {orderDetailSuccess.data.discountAmount.toFixed(2)} {orderDetailSuccess.data.currencySymbol}</div>)}
                {
                    displayCurrencySymbolLeft(orderDetailSuccess.data.currencyRegion) === true ?
                        (<div className="col-vb-price-container">Total tax: {orderDetailSuccess.data.currencySymbol} {orderDetailSuccess.data.taxAmount.toFixed(2)}</div>)
                        : (<div className="col-vb-price-container">Total tax: {orderDetailSuccess.data.taxAmount.toFixed(2)} {orderDetailSuccess.data.currencySymbol}</div>)}
                {
                    displayCurrencySymbolLeft(orderDetailSuccess.data.currencyRegion) === true ?
                        (<div className="col-vb-price-container col-vb-price-container-gt">Grand Total: {orderDetailSuccess.data.currencySymbol} {orderDetailSuccess.data.grandTotal.toFixed(2)}</div>)
                        : (<div className="col-vb-price-container col-vb-price-container-gt">Grand Total: {orderDetailSuccess.data.grandTotal.toFixed(2)} {orderDetailSuccess.data.currencySymbol}</div>)}
            </div>
        )
    }

    return (
        <>
            <div className="col-vb-container mb-4">
                <div className="col-vb-screen-label">Bill Details</div>
                {orderDetailSuccess && orderDetailSuccess.data && <div>
                    {billComponent(orderDetailSuccess)}
                    <ReactToPrint
                        trigger={() => <button className="btn add-col-vb-btn add-col-vb-btn-left add-col-vb-btn-column">Download Bill</button>}
                        content={() => componentRef.current}
                        pageStyle={`@page { margin: 20mm 15mm 20mm 15mm !important; }`}
                    />
                </div>}

                {orderDetailError && <div>No Details Found</div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerOrderViewBill