import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addSellerDetails, sellerList, updateSellerDetails
} from '../../api-requests/inventory/sellerDetails';
import {
    addInvSellerRequestError, addInvSellerRequestSuccess, invSellerListRequestError,
    invSellerListRequestSuccess, updateInvSellerRequestError, updateInvSellerRequestSuccess
} from './seller.action';
import { SellerActionTypes } from './seller.types'
import { displayConsoleLog, isNull } from '../../../utils/Utils'
import { decryptResponseData } from '../../../utils/DataDecryptionUtils';

function* handleUpdateInvSeller({ payload }) {
    try {
        const response = yield call(updateSellerDetails, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateInvSellerRequestSuccess(decryptedData))
            } else {
                yield put(updateInvSellerRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(updateInvSellerRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(updateInvSellerRequestError(error.response.data))
        }
    }
}

export function* updateInvSellerReq() {
    yield takeLatest(SellerActionTypes.INV_UPDATE_SELLER_REQUEST, handleUpdateInvSeller)
}

function* handleInvSellerList({ payload }) {
    try {
        const response = yield call(sellerList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invSellerListRequestSuccess(decryptedData))
            } else {
                yield put(invSellerListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(invSellerListRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(invSellerListRequestError(error.response.data))
        }
    }
}

export function* invSellerListReq() {
    yield takeLatest(SellerActionTypes.INV_SELLER_LIST_REQUEST, handleInvSellerList)
}

function* handleAddInvSeller({ payload }) {
    try {
        const response = yield call(addSellerDetails, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addInvSellerRequestSuccess(decryptedData))
            } else {
                yield put(addInvSellerRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (!isNull(error.response) && error.response.status === 401) {
            const decryptedData = decryptResponseData(error.response.request._response)
            yield put(addInvSellerRequestError(decryptedData))
        } else {
            displayConsoleLog('error', error.response)
            yield put(addInvSellerRequestError(error.response.data))
        }
    }
}

export function* addInvSellerReq() {
    yield takeLatest(SellerActionTypes.INV_ADD_SELLER_REQUEST, handleAddInvSeller)
}