import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { contactUsRecordListRequest } from '../../../../redux/contactUs/contactUs.action'
import { APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { convertToPascal, isNull, removeSpecialCharAndChangeToPascal } from "../../../../utils/Utils";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import moment from "moment";
import { useNavigate } from 'react-router-dom';

const ContactUsList = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { recordListSuccess, recordListError } = useSelector(state => state.contactUs)
    let navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        const data = {
            productCode: APP_CONSTANTS.PRODUCT_NAME
        }
        dispatch(contactUsRecordListRequest(data))
    }, [])

    useEffect(() => {
        if (!isNull(recordListSuccess)) {
            setLoading(false)
        } else if (!isNull(recordListError)) {
            setLoading(false)
        }
    }, [recordListSuccess, recordListError])

    const handleCardClick = item => {
        navigate(ROUTES.contactUsDetail, { state: item.recordId })
    }

    return (
        <>
            <div className="cul-list-container">
                <ScreenLabel label='Message List' />
                {recordListSuccess && recordListSuccess.data && <table class="table table-striped table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col" className="rl-table-col-title">Name</th>
                            <th scope="col" className="rl-table-col-title text-center">Mobile</th>
                            <th scope="col" className="rl-table-col-title text-center">Status</th>
                            <th scope="col" className="rl-table-col-title text-center">Date</th>
                            <th scope="col" className="rl-table-col-title text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recordListSuccess.data.map(element => (
                            <tr className="rl-table-row-container">
                                <td className="rl-table-col-item">{element.name}</td>
                                <td className="rl-table-col-item text-center">{element.mobile}</td>
                                <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.recordStatus)}</td>
                                <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                <td className="rl-table-col-item text-center" onClick={() => { handleCardClick(element) }}>Detail</td>
                            </tr>
                        ))}

                    </tbody>
                </table>}
                {recordListError && <div>No Message Found</div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ContactUsList