import { SocketActionTypes } from './socket.types'

const INITIAL_STATE = {
    newOrderPlacedNotif: null,
    callMade: null,
    answerMade: null,
    userCalled: null,
    callAccepted: null,
    callEnded: null,
    makeCall: null,
    collectOrderPaymentNotif: null,
    customerPayAtEndSuccessNotif: null,
    orderStatusUpdateNotif: null,
    callWaiterRequestNotif: null
}

const socketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SocketActionTypes.NEW_ORDER_PLACED:
            return {
                ...state,
                newOrderPlacedNotif: action.payload
            }
        case SocketActionTypes.CLEAR_NEW_ORDER_DATA:
            return {
                ...state,
                newOrderPlacedNotif: null
            }
        case SocketActionTypes.CALL_WAITER_REQUEST:
            return {
                ...state,
                callWaiterRequestNotif: action.payload
            }
        case SocketActionTypes.CLEAR_CALL_WAITER_REQUEST:
            return {
                ...state,
                callWaiterRequestNotif: null
            }
        case SocketActionTypes.COLLECT_ORDER_PAYMENT:
            return {
                ...state,
                collectOrderPaymentNotif: action.payload
            }
        case SocketActionTypes.CLEAR_COLLECT_ORDER_PAYMENT:
            return {
                ...state,
                collectOrderPaymentNotif: null
            }
        case SocketActionTypes.CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS:
            return {
                ...state,
                customerPayAtEndSuccessNotif: action.payload
            }
        case SocketActionTypes.CLEAR_CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS:
            return {
                ...state,
                customerPayAtEndSuccessNotif: null
            }
        case SocketActionTypes.ORDER_STATUS_UPDATE:
            return {
                ...state,
                orderStatusUpdateNotif: action.payload
            }
        case SocketActionTypes.CLEAR_ORDER_STATUS_UPDATE:
            return {
                ...state,
                orderStatusUpdateNotif: null
            }
        case SocketActionTypes.CALL_MADE:
            return {
                ...state,
                callMade: action.payload
            }
        case SocketActionTypes.ANSWER_MADE:
            return {
                ...state,
                answerMade: action.payload
            }
        case SocketActionTypes.USER_CALLED:
            return {
                ...state,
                userCalled: action.payload,
                callAccepted: null
            }
        case SocketActionTypes.CALL_ACCEPTED:
            return {
                ...state,
                callAccepted: action.payload
            }
        case SocketActionTypes.CALL_ENDED:
            return {
                ...state,
                callEnded: action.payload
            }
        case SocketActionTypes.MAKE_CALL:
            return {
                ...state,
                makeCall: action.payload
            }
        case SocketActionTypes.UPDATE_CALL_STATE:
            return INITIAL_STATE
        case SocketActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default socketReducer