import { all, call } from 'redux-saga/effects'
import {
    customerLoginReq, loginUserReq, registerUserReq,
    branchListReq, subscriptionListReq, userDetailReq,
    addBranchReq, addRestaurantReq, restaurantListReq, updateBranchReq,
    restuarantDetailReq, changePasswordReq, forgotPasswordReq,
    cityListReq, countryListReq, stateListReq, agentLoginReq,
    businessListReq, terminalLoginReq, employeeLoginReq, supportRegistrationReq,
    ipLocationReq, userRegistrationReq, userUpdateUserNotificationTokenReq,
    restaurantAccessRegisterReq, branchDetailReq, operatorDetailReq, operatorListReq, operatorRegisterReq, updateOperatorLocationReq,
    businessListByLocationReq, updateBranchOrderingStatusReq, updateBusinessOrderingStatusReq,
    businessUserListReq, mapUserWithBusinessReq, registerBusinessUserReq, updateCustomerDetailsReq,
    updateRestaurantDetailsReq, addSubcriptionReq
} from './user/user.saga'

import {
    addProductReq, productDetailReq, productListReq, productStatusUpdateReq, productUpdateReq,
    addFeedbackReq, bulkMenuUploadReq
} from './product/product.saga'

import {
    addCategoryReq, addSubCategoryReq, categoryListReq,
    subCategoryListReq
} from './category/category.saga'

import {
    acceptRejectOrderReq, orderDetailReq, orderListReq,
    placeOrderReq, updateOrderStatusReq, updateOrderTransactionReq, orderDashboardReq,
    orderGeneratePaymentLinkReq, updateExistingOrderReq, acceptPayAtEndPaymentReq,
    findPayAtEndOrderReq, updateCustomerActionReq
} from './order/order.saga'

import {
    addEmployeeReq, employeeDetailReq, employeeListReq, removeEmployeeReq
} from './employee/employee.saga'

import {
    addTerminalReq, terminalListReq, updateTerminalStatusReq
} from './delivery-terminal/delivery-terminal.saga'

import {
    placeReservationReq, reservationDetailReq, reservationListReq, updateReservationStatusReq
} from './reservation/reservation.saga'

import {
    addRequestTicketReq, requestTicketDetailReq, requestTicketListReq, requestTicketUpdateDetailReq
} from './request-ticket/request-ticket.saga'

import { contactUsAddRecordReq, contactUsDetailReq, contactUsListReq, contactUsUpdateStatusReq } from './contactUs/contactUs.saga'

import {
    addFeatureRequestCommentReq, addFeatureRequestReq, featureRequestDetailReq,
    featureRequestListReq, featureRequestUpVoteReq
} from './feature-request/featureRequest.saga'

import { addInvCategoryReq, invCategoryListReq, updateInvCategoryReq } from './inventory/category/category.saga'
import { addInvOrderInvoiceReq, invOrderInvoiceDetailReq, invOrderInvoiceListReq, updateInvOrderInvoicePaymentReq } from './inventory/order-invoice/orderInvoice.saga'
import { addInvProductReq, invProductListReq, updateInvProductConsumptionReq, updateInvProductReq, invProductDetailReq } from './inventory/product/product.saga'
import { addInvSellerReq, invSellerListReq, updateInvSellerReq } from './inventory/seller/seller.saga'

export default function* rootSaga() {
    yield all([
        call(customerLoginReq),
        call(loginUserReq),
        call(registerUserReq),
        call(branchListReq),
        call(subscriptionListReq),
        call(addCategoryReq),
        call(addSubCategoryReq),
        call(categoryListReq),
        call(subCategoryListReq),
        call(addProductReq),
        call(productDetailReq),
        call(productListReq),
        call(productStatusUpdateReq),
        call(productUpdateReq),
        call(userDetailReq),
        call(acceptRejectOrderReq),
        call(orderDetailReq),
        call(orderListReq),
        call(placeOrderReq),
        call(updateOrderStatusReq),
        call(updateOrderTransactionReq),
        call(orderDashboardReq),
        call(addBranchReq),
        call(addRestaurantReq),
        call(restaurantListReq),
        call(updateBranchReq),
        call(restuarantDetailReq),
        call(addFeedbackReq),
        call(changePasswordReq),
        call(forgotPasswordReq),
        call(cityListReq),
        call(countryListReq),
        call(stateListReq),
        call(agentLoginReq),
        call(businessListReq),
        call(addEmployeeReq),
        call(employeeDetailReq),
        call(employeeListReq),
        call(removeEmployeeReq),
        call(addTerminalReq),
        call(terminalListReq),
        call(terminalLoginReq),
        call(updateTerminalStatusReq),
        call(employeeLoginReq),
        call(orderGeneratePaymentLinkReq),
        call(placeReservationReq),
        call(reservationDetailReq),
        call(reservationListReq),
        call(updateReservationStatusReq),
        call(addRequestTicketReq),
        call(requestTicketDetailReq),
        call(requestTicketListReq),
        call(requestTicketUpdateDetailReq),
        call(supportRegistrationReq),
        call(bulkMenuUploadReq),
        call(contactUsAddRecordReq),
        call(contactUsDetailReq),
        call(contactUsListReq),
        call(contactUsUpdateStatusReq),
        call(addFeatureRequestCommentReq),
        call(addFeatureRequestReq),
        call(featureRequestDetailReq),
        call(featureRequestListReq),
        call(featureRequestUpVoteReq),
        call(updateExistingOrderReq),
        call(acceptPayAtEndPaymentReq),
        call(ipLocationReq),
        call(userRegistrationReq),
        call(userUpdateUserNotificationTokenReq),
        call(restaurantAccessRegisterReq),
        call(branchDetailReq),
        call(findPayAtEndOrderReq),
        call(operatorDetailReq),
        call(operatorListReq),
        call(operatorRegisterReq),
        call(updateOperatorLocationReq),
        call(businessListByLocationReq),
        call(updateBranchOrderingStatusReq),
        call(updateBusinessOrderingStatusReq),
        call(businessUserListReq),
        call(mapUserWithBusinessReq),
        call(registerBusinessUserReq),
        call(updateCustomerDetailsReq),
        call(updateRestaurantDetailsReq),
        call(addSubcriptionReq),
        call(updateCustomerActionReq),
        call(addInvCategoryReq),
        call(invCategoryListReq),
        call(updateInvCategoryReq),
        call(addInvOrderInvoiceReq),
        call(invOrderInvoiceDetailReq),
        call(invOrderInvoiceListReq),
        call(updateInvOrderInvoicePaymentReq),
        call(addInvProductReq),
        call(invProductListReq),
        call(updateInvProductConsumptionReq),
        call(updateInvProductReq),
        call(invProductDetailReq),
        call(addInvSellerReq),
        call(invSellerListReq),
        call(updateInvSellerReq),
    ])
}