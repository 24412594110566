import React, { useEffect, useState, useRef } from "react";
import './styles.css'
// import QRCode from 'qrcode.react'
// import QRCode from 'react-qr-code'
// import { QRCode } from 'react-qrcode-logo';
import { toPng } from "html-to-image";
import download from "downloadjs";
import QRCode from 'qrcode.react';
import { config } from '../../../config'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { restaurantListRequest, branchListRequest } from '../../../redux/user/user.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../utils/Utils";
import { APP_CONSTANTS } from "../../../utils/AppConstants";
import notificationTone from "../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../redux/socket/socket.action'
import QrCodeButton from '../../../components/button/qr-code-button/qrCodeButton'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import html2canvas from 'html2canvas'
import CommonInputFull from "../../../components/common-input-full/commonInputFull";

const QrCodeGenerate = props => {
    const { user, restuarantListSuccess, restuarantListError, branchListSuccess,
        branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    // let qrCode = `${config.qrCodeUrl}`
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [isLoading, setLoading] = useState(false)
    const [displayQRCode, setDisplayQRCode] = useState(false)
    const dispatch = useDispatch()
    // const [qrText, setQrText] = useState("");
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const svgRef = useRef(null);
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [qrSize, setQrSize] = useState(256)
    const [labelSize, setLabelSize] = useState(14)
    const [restaurantLabelSize, setRestaurantLabelSize] = useState(25)
    const [restaurantLocationLabelSize, setRestaurantLocationLabelSize] = useState(18)

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        const data = {
            screen: 'GENERATE_QR_CODE',
            screenClass: 'GENERATE_QR_CODE'
        }
        screenCaptureAnalytics(data)
    }, [])

    const getQRCode = () => {
        if (isEmpty(sessionStorage.getItem(APP_CONSTANTS.QR_CODE)) || sessionStorage.getItem(APP_CONSTANTS.QR_CODE) === 'null') {
            return `${config.qrCodeUrl}`
        } else return sessionStorage.getItem(APP_CONSTANTS.QR_CODE)
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            setLoading(true)
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(restaurantListRequest(data))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            setSelectedRestaurant(restuarantListSuccess.data[0])
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            setSelectedLocation(branchListSuccess.data[0])
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            // setDisplayQRCode(false)
            // setQrText('')
            setQRCodeUrl('')
            setSelectedLocation(null)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            // qrCode = `${config.qrCodeUrl}/${selectedRestaurant.businessId}/${selectedLocation.locationId}?restaurantId=${selectedRestaurant.businessId}&locationId=${selectedLocation.locationId}`
            // sessionStorage.setItem(APP_CONSTANTS.QR_CODE, qrCode)
            // displayConsoleLog('qrCode', qrCode)
            // setQrText(qrCode)
            // setDisplayQRCode(true)
            setQrSize(256)
            setLabelSize(14)
            setRestaurantLabelSize(25)
            setRestaurantLocationLabelSize(18)
            let qrCode = `${config.qrCodeUrl}/${selectedRestaurant.businessId}/${selectedLocation.locationId}?restaurantId=${selectedRestaurant.businessId}&locationId=${selectedLocation.locationId}`
            sessionStorage.setItem(APP_CONSTANTS.QR_CODE, qrCode)
            console.log('url', qrCode);
            setQRCodeUrl(qrCode)
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            // qrCode = `${config.qrCodeUrl}/${employeeSelectedRestaurant}/${employeeSelectedLocation}?restaurantId=${employeeSelectedRestaurant}&locationId=${employeeSelectedLocation}`
            // sessionStorage.setItem(APP_CONSTANTS.QR_CODE, qrCode)
            // displayConsoleLog('qrCode', qrCode)
            // setQrText(qrCode)
            setQrSize(256)
            setLabelSize(14)
            setRestaurantLabelSize(25)
            setRestaurantLocationLabelSize(18)
            let qrCode = `${config.qrCodeUrl}/${employeeSelectedRestaurant}/${employeeSelectedLocation}?restaurantId=${employeeSelectedRestaurant}&locationId=${employeeSelectedLocation}`
            sessionStorage.setItem(APP_CONSTANTS.QR_CODE, qrCode)
            console.log('url', qrCode);
            setQRCodeUrl(qrCode)
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const downloadQR = async () => {
        // const canvas = document.getElementById('qrCode')
        // const pngUrl = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream")
        // let downloadLink = document.createElement("a");
        // downloadLink.href = pngUrl;
        // downloadLink.download = "GoMoBiteQrCode.png";
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);

        // const svg = document.getElementById("qrCode");
        // const svgData = new XMLSerializer().serializeToString(svg);
        // const canvas = document.createElement("canvas");
        // const ctx = canvas.getContext("2d");
        // const img = new Image();
        // img.onload = () => {
        //     canvas.width = img.width;
        //     canvas.height = img.height;
        //     ctx.drawImage(img, 0, 0);
        //     const pngFile = canvas.toDataURL("image/png");
        //     const downloadLink = document.createElement("a");
        //     downloadLink.download = "GoMoBitesQRCode";
        //     downloadLink.href = `${pngFile}`;
        //     downloadLink.click();
        // };
        // img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
        // setLoading(true)
        // const element = document.getElementById('qrCode'),
        //     canvas = await html2canvas(element),
        //     data = canvas.toDataURL('image/jpg'),
        //     link = document.createElement('a');

        // link.href = data;
        // link.download = 'GoMoBitesQRCode.jpg';

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // setLoading(false)
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        } else {
            const width = svgRef.current.offsetWidth;
            toPng(svgRef.current, { width })
                .then(function (dataUrl) {
                    download(dataUrl, "qr-code.png");
                })
                .catch(function (error) {
                    console.error("oops, something went wrong!", error);
                });
        }
    }

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newLabelSize = 4 + (4 * percentChange)
        setLabelSize(newLabelSize / 2)
        let newLabelSize1 = 8 + (8 * percentChange)
        setRestaurantLabelSize(newLabelSize1 / 2)
        let newLabelSize2 = 5 + (5 * percentChange)
        setRestaurantLocationLabelSize(newLabelSize2 / 2)
    }, [qrSize])

    const handleOpenOrderBoard = () => {
        window.open(config.orderListBoardUrl, "_blank")
    }

    return (
        <>
            <div className="qc-container">
                <ScreenLabel label='Restaurant QR Code' />
                {isLoggedInUserBusiness(user) === true && <SubTextLabel label='Select restaurant and location to generate QR Code' />}
                {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                    className="select-dropdown"
                    value={selectedRestaurant}
                    getOptionLabel={e => e.businessName}
                    getOptionValue={e => e}
                    options={restuarantListSuccess.data}
                    placeholder='Select Restaurant'
                    onChange={e => setSelectedRestaurant(e)} />}
                {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div>No Restaurant found</div>}
                {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                    className="select-dropdown"
                    value={selectedLocation}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e}
                    options={branchListSuccess.data}
                    placeholder='Select Location'
                    onChange={e => setSelectedLocation(e)} />}
                {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div>No location found</div>}

                <div className="qr-item-row-container">
                    <div className="qr-detail-item-label">Resturant Id</div>
                    <div className="qr-detail-item-val">{isLoggedInUserBusiness(user) === true ? selectedRestaurant ? selectedRestaurant.businessId : '-' : employeeSelectedRestaurant}</div>
                    <div className="qr-detail-item-label">Location Id</div>
                    <div className="qr-detail-item-val">{isLoggedInUserBusiness(user) === true ? selectedLocation ? selectedLocation.locationId : '-' : employeeSelectedLocation}</div>
                    <CommonInputFull
                        onChange={t => setQrSize(t.target.value)}
                        placeholder='Desired QR Code Size (In pixels)'
                        type='number'
                        value={qrSize}
                        isSubtext={true}
                        subText='Minimum size should be 38px (1cm)' />
                </div>
                {qrCodeUrl.length > 0 &&
                    <div className="qc-content mt-5">
                        <div id="qrCode" className="qr-code-container" ref={svgRef}>
                            <div className="qr-business-label" style={{ fontSize: restaurantLabelSize }}>{selectedRestaurant ? selectedRestaurant.businessName : ''}</div>
                            <div className="qr-business-location-label" style={{ fontSize: restaurantLocationLabelSize }}>{`${selectedLocation ? !isEmpty(selectedLocation.city) ? selectedLocation.city : '-' : '-'}, ${selectedLocation ? !isEmpty(selectedLocation.state) ? selectedLocation.state : '-' : '-'}`}</div>
                            <div className="qrCodeContainer">
                                <QRCode value={qrCodeUrl} size={qrSize < 38 ? 38 : qrSize}
                                    renderAs='svg'
                                />
                                <div className="qr-qme-label" style={{ fontSize: labelSize }}>Powered By <span className="qr-qme-name-label" style={{ fontSize: labelSize }}>GoMoBites</span></div>
                                {/* <div className="oml-powered-label" style={{ fontSize: labelSize }}>Powered By GoMoBites</div> */}
                            </div>
                            {/* <div className="qrCodeContainer" ref={svgRef}>
                                <QRCode value={qrText} size={256}
                                    logoImage={require('../../../assets/NewLogoCircle.png')}
                                    logoWidth={60}
                                    logoHeight={60}
                                    removeQrCodeBehindLogo={true}
                                />
                            </div> */}
                            {/* <div className="qr-qme-label">Powered By <span className="qr-qme-name-label">GoMoBites</span></div> */}
                        </div>

                        {/* <a onClick={downloadQR}> Download QR </a> */}
                        <QrCodeButton
                            label='Download QR Code'
                            onClick={() => downloadQR()} />
                        <QrCodeButton
                            label='Open Order Board'
                            onClick={() => handleOpenOrderBoard()} />
                    </div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default QrCodeGenerate