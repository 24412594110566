import React, { useEffect, useState } from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import FeatureContainer from "../feature-container/featureContainer";
import PartnerComponent from "../partner-component/partnerComponent";
import ScreenFooter from "../screen-footer/screenFooter";
import NavBarComponent from "../navbar-component/navBarComponent";
import RestaurantFlow from "../carousel/restaurant-flow/restaurantFlow";

const AboutPage = props => {
    let navigate = useNavigate();
    return (
        <div>
            <NavBarComponent navigate={navigate} />
            <header className="contact-hero">
                <div className="content">
                    <div className="contact-label-container">
                        <div className="contact-label">About</div>
                        <div className="h-line-white" />
                    </div>
                    <div className="have-question-label">Changing the restaurant ordering game.</div>
                    <div className="have-question-sub-text">Expect only the best solution from us. GoMoBites provides a variety of solutions for both the restaurants and their customers.</div>
                </div>
            </header>
            <div className="about-container">
                <div className="about-main-label">GoMoBites <span className="about-main-label-sub"> provide you a no-app restaurant order management solution with a bundle of solutions which enables you as a restaurant to step-up your ordering system from others. From digitalizing menu card to customer placing order(s), solution is pack up with other features like employee access, where the employee will be provided with permission to access the system to fastup daily tasks, other features are location based menu, reservation management and many more...</span></div>
            </div>
            <div className="reasons-heading">Few good reasons, why restaurant should use it</div>
            <div className="home-step-container mb-2">
                <div className="step-text-container">Better service, as customer is able to order, when ready not when waiter is available.</div>
                <div className="step-text-container">Right order, no confusion, no miscommunication on what was actually ordered.</div>
                <div className="step-text-container">Waiter is able to service more customers in less time, as they are only delivering orders and collecting payments.</div>
                <div className="step-text-container">Restaurant is able to service more customers due to; Quicker turn around, less waiting.</div>
                <div className="step-text-container">Hygiene factor, no touching of menus cards.</div>
            </div>
            <div className="reasons-heading">Restaurant/Food Vendor</div>
            {/* <div className="home-step-container mb-2">
                <div className="step-img-container">
                    <img className="img" src={require('../../../assets/step1.png')} alt="logo" />
                </div>
                <div className="step-img-container">
                    <img className="img" src={require('../../../assets/step2.png')} alt="logo" />
                </div>
                <div className="step-img-container">
                    <img className="img" src={require('../../../assets/step3.png')} alt="logo" />
                </div>
                <div className="step-img-container">
                    <img className="img" src={require('../../../assets/step4.png')} alt="logo" />
                </div>
            </div> */}
            <RestaurantFlow />
            <PartnerComponent />
            <ScreenFooter navigate={navigate} />
        </div>
    )
}

export default AboutPage