import { CartActionTypes } from './cart.types'
import { displayConsoleLog, isEmpty, isNull } from '../../utils/Utils'

const INITIAL_STATE = {
    cartItem: null,
    totalAmount: 0,
    subTotal: 0,
    taxAmount: 0,
    discountAmt: 0,
    displayAddCartBanner: false
}

const cartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CartActionTypes.ADD_ITEM_IN_CART:
            let item = action.payload
            if (isItemAlreadyInCart(state.cartItem, item)) {
                const currentItem = state.cartItem.find(e => e.itemId === item.itemId)
                let quantity = item.isFromCart === true ? Number(currentItem.quantity) + 1 : Number(currentItem.quantity) + Number(item.quantity)
                let pointEarned = Number(currentItem.productPoints) * Number(quantity)
                let updatedItem = {
                    ...currentItem,
                    quantity: quantity,
                    points: pointEarned,
                    finalPrice: calculateItemFinalPrice(quantity, currentItem.price, currentItem.discount,
                        currentItem.tax, currentItem.addOns, currentItem.options)
                }

                let currentItems = state.cartItem.filter(e => e.itemId !== currentItem.itemId)
                currentItems.push(updatedItem)
                return {
                    ...state,
                    cartItem: currentItems,
                    totalAmount: calculateCartTotalAmount(currentItems),
                    subTotal: calculateCartSubTotal(currentItems),
                    taxAmount: calculateCartTotalTax(currentItems),
                    discountAmt: calculateCartTotalDiscount(currentItems)
                }
            } else {
                const itemFinalPrice = calculateItemFinalPrice(item.quantity,
                    item.price, item.discount, item.tax, item.addOns, item.options)
                let updatedItem = {
                    ...item,
                    finalPrice: itemFinalPrice
                }
                let currentItems = isNull(state.cartItem) ? [] : state.cartItem
                currentItems.push(updatedItem)
                return {
                    ...state,
                    cartItem: currentItems,
                    totalAmount: calculateCartTotalAmount(currentItems),
                    subTotal: calculateCartSubTotal(currentItems),
                    taxAmount: calculateCartTotalTax(currentItems),
                    discountAmt: calculateCartTotalDiscount(currentItems)
                }
            }
        // if (isNull(state.cartItem)) {
        //     const cartArr = []
        //     cartArr.push(item)
        //     let itemPrice = item.price
        //     item.addOns.forEach(e => {
        //         itemPrice = Number(itemPrice) + Number(e.price)
        //     })
        //     item.options.forEach(e => {
        //         itemPrice = Number(itemPrice) + Number(e.price)
        //     })
        //     let subTotal = itemPrice * item.quantity
        //     let discountAmt = (item.discount * subTotal / 100)
        //     let taxAmount = 0
        //     if (item.isTaxable === true) {
        //         taxAmount = (item.tax * subTotal / 100)
        //     }
        //     let priceAfterDiscount = subTotal - discountAmt
        //     let totalAmt = +priceAfterDiscount + +taxAmount

        //     return {
        //         ...state,
        //         cartItem: cartArr,
        //         totalAmount: totalAmt,
        //         subTotal: subTotal,
        //         taxAmount: taxAmount,
        //         discountAmt: discountAmt
        //     }
        // } else {
        //     const index = state.cartItem.findIndex(_item => _item.itemId === item.itemId)
        //     if (index > -1) {
        //         state.cartItem[index] = {
        //             ...state.cartItem[index],
        //             quantity: +state.cartItem[index].quantity + 1,
        //             finalPrice: state.cartItem[index].price * (state.cartItem[index].quantity + 1)
        //         }
        //     } else {
        //         state.cartItem.push(item)
        //     }
        //     let subTotal = 0
        //     let totalAmt = 0
        //     let taxAmount = 0
        //     let discountAmt = 0
        //     state.cartItem.forEach(element => {
        //         let iSubTotal = element.price * element.quantity
        //         let iDiscountAmt = (element.discount * iSubTotal / 100)
        //         let iTaxAmount = 0
        //         if (element.isTaxable === true) {
        //             iTaxAmount = (element.tax * iSubTotal / 100)
        //         }
        //         let priceAfterDiscount = iSubTotal - iDiscountAmt
        //         let iTotalAmt = +priceAfterDiscount + +iTaxAmount

        //         subTotal = +subTotal + +iSubTotal
        //         discountAmt = +discountAmt + +iDiscountAmt
        //         taxAmount = +taxAmount + +iTaxAmount
        //         totalAmt = +totalAmt + +iTotalAmt
        //     });
        //     return {
        //         ...state,
        //         cartItem: state.cartItem,
        //         totalAmount: totalAmt,
        //         subTotal: subTotal,
        //         taxAmount: taxAmount,
        //         discountAmt: discountAmt
        //     }
        // }
        case CartActionTypes.REDUCE_QUANTITY:
            let item1 = action.payload
            let newCartArr = state.cartItem.map(element => element.itemId === item1.itemId ?
                {
                    ...element,
                    quantity: element.quantity - 1,
                    points: Number(element.productPoints) * Number(element.quantity - 1),
                    finalPrice: calculateItemFinalPrice((element.quantity - 1),
                        element.price, element.discount, element.tax,
                        element.addOns, element.options)
                }
                : element
            )
            newCartArr = newCartArr.filter(element => element.quantity !== 0)
            let totalAmt = calculateCartTotalAmount(newCartArr)
            let subTotal = calculateCartSubTotal(newCartArr)
            let taxAmount = calculateCartTotalTax(newCartArr)
            let discountAmt = calculateCartTotalDiscount(newCartArr)
            return {
                ...state,
                cartItem: newCartArr.length > 0 ? newCartArr : null,
                totalAmount: totalAmt,
                subTotal: subTotal,
                taxAmount: taxAmount,
                discountAmt: discountAmt
            }
        case CartActionTypes.DISPLAY_ADD_CART_BANNER:
            return {
                ...state,
                displayAddCartBanner: true
            }
        case CartActionTypes.HIDE_ADD_CART_BANNER:
            return {
                ...state,
                displayAddCartBanner: false
            }
        case CartActionTypes.LOGOUT:
            return INITIAL_STATE
        case CartActionTypes.CLEAR_CART:
            return INITIAL_STATE
        case CartActionTypes.CUSTOMER_LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

const isItemAlreadyInCart = (cartList, item) => {
    if (isEmpty(cartList)) {
        return false
    } else {
        const itemInCart = cartList.find(e => e.itemId === item.itemId)
        if (isNull(itemInCart))
            return false
        else {
            if (item.isCustomized === true && itemInCart.isCustomized === false) {
                return false
            } else if (item.isCustomized === false && itemInCart.isCustomized === true) {
                return false
            } else {
                let flag = true
                item.addOns.forEach(e => {
                    itemInCart.addOns.forEach(e1 => {
                        if (e.name === e1.name) {
                            flag = true
                        } else {
                            flag = false
                        }
                    })
                })
                item.options.forEach(e => {
                    itemInCart.options.forEach(e1 => {
                        if (e.name === e1.name) {
                            flag = true
                        } else {
                            flag = false
                        }
                    })
                })
                return flag
            }
        }
    }
}

const calculateItemFinalPrice = (quantity, price, discount, tax, addOns, options) => {
    let newPrice = price
    if (!isEmpty(addOns)) {
        addOns.forEach(e => {
            newPrice = Number(newPrice) + Number(e.price)
        })
    }
    if (!isEmpty(options)) {
        options.forEach(e => {
            newPrice = Number(newPrice) + Number(e.price)
        })
    }
    let subTotal = newPrice * quantity
    let discountAmt = (discount * subTotal / 100)
    let taxAmount = (tax * subTotal / 100)
    let priceAfterDiscount = subTotal - discountAmt
    let totalAmt = Number(priceAfterDiscount) + Number(taxAmount)
    return (Math.round(totalAmt * 1e2) / 1e2)
}

const calculateCartSubTotal = (items) => {
    let newPrice = 0
    items.forEach(e => {
        let tempTotal = calculateItemSubTotal(e)
        newPrice = Number(newPrice) + Number(tempTotal)
    })
    return (Math.round(newPrice * 1e2) / 1e2)
}

const calculateCartTotalDiscount = (items) => {
    let discountAmt = 0
    items.forEach(e => {
        let tempTotal = calculateItemSubTotal(e)
        let iDiscountAmt = (e.discount * tempTotal / 100)

        discountAmt = Number(discountAmt) + Number(iDiscountAmt)
    });
    return (Math.round(discountAmt * 1e2) / 1e2)
}

const calculateCartTotalTax = (items) => {
    let taxAmount = 0
    items.forEach(e => {
        let tempTotal = calculateItemSubTotal(e)
        let iTaxAmount = (e.tax * tempTotal / 100)

        taxAmount = Number(taxAmount) + Number(iTaxAmount)
    });
    return (Math.round(taxAmount * 1e2) / 1e2)
}

const calculateCartTotalAmount = (items) => {
    let totalAmt = 0
    items.forEach(e => {
        let tempTotal = calculateItemSubTotal(e)

        let iDiscountAmt = (e.discount * tempTotal / 100)
        let iTaxAmount = (e.tax * tempTotal / 100)

        let priceAfterDiscount = tempTotal - iDiscountAmt
        let iTotalAmt = Number(priceAfterDiscount) + Number(iTaxAmount)

        totalAmt = Number(totalAmt) + Number(iTotalAmt)
    });
    return (Math.round(totalAmt * 1e2) / 1e2)
}

const calculateItemSubTotal = e => {
    let tempTotal = e.price
    if (!isEmpty(e.addOns)) {
        e.addOns.forEach(e1 => {
            tempTotal = Number(tempTotal) + Number(e1.price)
        })
    }
    if (!isEmpty(e.options)) {
        e.options.forEach(e1 => {
            tempTotal = Number(tempTotal) + Number(e1.price)
        })
    }

    tempTotal = tempTotal * e.quantity
    return tempTotal
}

export default cartReducer