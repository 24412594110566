import axios from 'axios'
import API_ENDPOINTS from './endpoints'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import { config } from '../../config'

export function addRequestTicketAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.requestTicket.raiseTicket}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid,
            "Content-Type": "multipart/form-data"
        }
    })
}

export function requestTicketListAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.requestTicket.ticketList}`,
        params: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function requestTicketDetailAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.requestTicket.ticketDetail}/${data}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateRequestTicketDetailAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.requestTicket.updateTicket}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}