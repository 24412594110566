import { CategoryActionTypes } from './category.types'

//Add Category
export const addCategoryRequest = data => ({
    type: CategoryActionTypes.ADD_CATEGORY_REQUEST,
    payload: data
})

export const addCategoryRequestSuccess = data => ({
    type: CategoryActionTypes.ADD_CATEGORY_REQUEST_SUCCESS,
    payload: data
})

export const addCategoryRequestError = data => ({
    type: CategoryActionTypes.ADD_CATEGORY_REQUEST_ERROR,
    payload: data
})

export const addCategoryRequestStatus = data => ({
    type: CategoryActionTypes.ADD_CATEGORY_REQUEST_STATUS,
    payload: data
})

//Category list
export const categoryListRequest = data => ({
    type: CategoryActionTypes.CATEGORY_REQUEST_LIST,
    payload: data
})

export const categoryListRequestSuccess = data => ({
    type: CategoryActionTypes.CATEGORY_REQUEST_LIST_SUCCESS,
    payload: data
})

export const categoryListRequestError = data => ({
    type: CategoryActionTypes.CATEGORY_REQUEST_LIST_ERROR,
    payload: data
})

//Add SubCategory
export const addSubCategoryRequest = data => ({
    type: CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST,
    payload: data
})

export const addSubCategoryRequestSuccess = data => ({
    type: CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST_SUCCESS,
    payload: data
})

export const addSubCategoryRequestError = data => ({
    type: CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST_ERROR,
    payload: data
})

export const addSubCategoryRequestStatus = data => ({
    type: CategoryActionTypes.ADD_SUB_CATEGORY_REQUEST_STATUS,
    payload: data
})

//SubCategory List
export const subCategoryListRequest = data => ({
    type: CategoryActionTypes.SUB_CATEGORY_LIST,
    payload: data
})

export const subCategoryListRequestSuccess = data => ({
    type: CategoryActionTypes.SUB_CATEGORY_LIST_SUCCESS,
    payload: data
})

export const subCategoryListRequestError = data => ({
    type: CategoryActionTypes.SUB_CATEGORY_LIST_ERROR,
    payload: data
})