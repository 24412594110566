import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonForm from "../../../components/common-form/commonForm";
import SubTextLabel from "../../../components/sub-text-label/subTextLabel";
import { restaurantListRequest, branchListRequest, businessListByLocationRequest } from '../../../redux/user/user.action'
import { bulkMenuUploadRequest, bulkMenuUploadRequestStatus } from '../../../redux/product/product.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull, isLoggedInUserBusiness, isLoggedInUserOperator, isLoggedInUserSupport } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { CurrencyLabel } from "../../../utils/CurrencyLabel";
import CommonButton from "../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../components/form-field-label/formFieldLabel";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import Modal from 'react-bootstrap/Modal';

const SupportBulkMenuUpload = props => {
    const [isLoading, setLoading] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const dispatch = useDispatch()
    const { user, businessListByLocationSuccess, businessListByLocationError, branchListSuccess, branchListError,
        restuarantListSuccess, restuarantListError } = useSelector(state => state.user)
    const { bulkUploadMenuSuccess, bulkUploadMenuError, bulkUploadMenuStatus } = useSelector(state => state.product)
    const [menuFilePath, setMenuFilePath] = useState(null)
    const [menuFileName, setMenuFileName] = useState('')

    const [images, setImages] = useState([])
    const [itemImage, setItemImage] = useState(null);
    const [itemImageName, setItemImageName] = useState('');
    const [selectedImagesList, setSelectedImagesList] = useState([])
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [selectedBusiness, setSelectedBusiness] = useState(null)
    const [showTutorialModal, setShowTutorialModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        let data = {}
        if (isLoggedInUserBusiness(user) === true) {
            data.userId = user.data.userDetails.userId
            dispatch(restaurantListRequest(data))
        } else if (isLoggedInUserSupport(user) === true) {
            data.userId = 'ALL'
            data.payload = { productCode: APP_CONSTANTS.PRODUCT_NAME }
            dispatch(restaurantListRequest(data))
        }

    }, [])

    const fetchRestaurantList = (country = null, state = null, city = null) => {
        setLoading(true)
        const data = {}
        if (!isEmpty(country)) {
            data.country = country
        }
        if (!isEmpty(state)) {
            data.state = state
        }
        if (!isEmpty(city)) {
            data.city = city
        }
        dispatch(businessListByLocationRequest(data))
    }

    useEffect(() => {
        if (!isNull(businessListByLocationSuccess)) {
            setLoading(false)
        } else if (!isNull(businessListByLocationError)) {
            setLoading(false)
        }
    }, [businessListByLocationSuccess, businessListByLocationError])

    useEffect(() => {
        if (!isNull(restuarantListSuccess)) {
            setLoading(false)
            setSelectedRestaurant(restuarantListSuccess.data[0])
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (!isNull(branchListSuccess)) {
            setLoading(false)
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            if (isLoggedInUserOperator(user)) {
                fetchBranchListReq(selectedRestaurant.otherDetails[0].businessId)
            } else {
                fetchBranchListReq(selectedRestaurant.businessId)
            }

        }
    }, [selectedRestaurant])

    const fetchBranchListReq = (restId) => {
        setLoading(true)
        const data = {
            businessId: restId
        }
        dispatch(branchListRequest(data))
    }

    const handleMenuFileChange = event => {
        setMenuFilePath(event.target.files[0])
        setMenuFileName(event.target.files[0].name)
    }

    useEffect(() => {
        if (bulkUploadMenuStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(bulkUploadMenuSuccess.message)
        } else if (bulkUploadMenuStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(bulkUploadMenuError.message)
        }
        if (bulkUploadMenuStatus.length) {
            dispatch(bulkMenuUploadRequestStatus(''))
        }
    }, [bulkUploadMenuSuccess, bulkUploadMenuError, bulkUploadMenuStatus])

    const uploadMenuHandle = () => {
        if (isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant is not selected')
        } else if (isNull(selectedLocation)) {
            displayErrorToast('Restaurant location is not selected')
        } else if (isNull(selectedCurrency)) {
            displayErrorToast('Item curreny type is not selected')
        } else if (isNull(menuFilePath)) {
            displayErrorToast('Menu file is not selected')
        } else if (isEmpty(menuFileName)) {
            displayErrorToast('Menu file is not selected')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('currencyLabel', selectedCurrency.code)
            fd.append('currencySymbol', selectedCurrency.symbol)
            fd.append('currencyRegion', selectedCurrency.region)
            fd.append('businessId', selectedRestaurant.businessId)
            fd.append('locationId', selectedLocation.locationId)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('businessCode', selectedRestaurant.businessCode)
            if (!isNull(menuFilePath) && !isEmpty(menuFileName))
                fd.append('menu', menuFilePath, menuFileName)
            if (images.length > 0) {
                images.forEach(element => {
                    fd.append('multipleImage', element)
                })

            }
            dispatch(bulkMenuUploadRequest(fd))
        }
    }

    useEffect(() => {
        if (itemImage !== undefined && itemImage !== null) {
            const existingList = images
            existingList.push(itemImage)
            setImages(existingList)
            setItemImage(null)
        }
    }, [itemImage])

    const handleOtherImageChange = event => {
        if (!isEmpty(event.target.files)) {
            const file = new File(event.target.files, `${selectedRestaurant.businessCode}${event.target.files[0].name}`,
                {
                    type: event.target.files[0].type,
                    lastModified: event.target.files[0].lastModified
                })
            displayConsoleLog('e11', file)
            displayConsoleLog('e1', event.target.files[0])
            // setItemImage(event.target.files[0])
            setItemImage(file)
            let currentImages = selectedImagesList
            currentImages.push(event.target.files[0].name)
            setSelectedImagesList(currentImages)
        }
    }

    return (
        <>
            <div className='sbmu-contentArea'>
                <ScreenLabel label='Upload Menu' />

                <CommonForm>
                    <SubTextLabel label='Fill details to upload menu' />
                    {isLoggedInUserOperator(user) && <div>
                        <FormFieldLabel className='up-bold-label' label='Select Location' />
                        {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                        {!isEmpty(user.data.userDetails.accessLocations) &&
                            <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={countryDropdown}
                                getOptionLabel={e => e.country}
                                getOptionValue={e => e}
                                options={user.data.userDetails.accessLocations}
                                placeholder='Select Country'
                                onChange={e => {
                                    setCountryDropdown(e)
                                    if (isEmpty(e.stateCity)) {
                                        fetchRestaurantList(e.country)
                                    }
                                }} />
                        }
                        {!isEmpty(user.data.userDetails.accessLocations) && !isNull(countryDropdown) &&
                            !isEmpty(countryDropdown?.stateCity) &&
                            <CommonInputRow className='mt-2'>
                                <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={stateDropdown}
                                    getOptionLabel={e => e.state}
                                    getOptionValue={e => e}
                                    options={countryDropdown.stateCity}
                                    placeholder='Select State'
                                    onChange={e => {
                                        setStateDropdown(e)
                                        if (isEmpty(e.city)) {
                                            fetchRestaurantList(countryDropdown.country, e.state)
                                        }
                                    }} />
                                {!isEmpty(stateDropdown?.city) && <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={cityDropdown}
                                    getOptionLabel={e => e.value}
                                    getOptionValue={e => e}
                                    options={stateDropdown.city.map(city => {
                                        return { key: city, value: city };
                                    })}
                                    placeholder='Select City'
                                    onChange={e => {
                                        setCityDropdown(e)
                                        fetchRestaurantList(countryDropdown.country, stateDropdown.state, e.value)
                                    }} />}
                            </CommonInputRow>}
                    </div>}
                    {(isLoggedInUserOperator(user)) && <div>
                        <div className="sbmu-field-label">Select Restaurant</div>
                        {businessListByLocationSuccess && businessListByLocationSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.otherDetails[0].businessName}
                            getOptionValue={e => e}
                            options={businessListByLocationSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => {
                                setSelectedRestaurant(e);
                            }} />}
                    </div>}
                    {(isLoggedInUserBusiness(user) || isLoggedInUserSupport(user)) && <div>
                        <div className="sbmu-field-label">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => {
                                setSelectedRestaurant(e);
                            }} />}
                    </div>}
                    {!isNull(selectedRestaurant) && <div>
                        <div className="sbmu-field-label">Select Restaurant's Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => {
                                setSelectedLocation(e)
                            }} />}
                    </div>}
                    {!isNull(selectedLocation) && <div>
                        <div className="sbmu-field-label">Select Currency</div>
                        {CurrencyLabel && <Select
                            className='select-full-width-no-border'
                            value={selectedCurrency}
                            getOptionLabel={e => `${e.currency} (${e.symbol})`}
                            getOptionValue={e => e}
                            options={CurrencyLabel}
                            placeholder='Select Currency'
                            onChange={e => setSelectedCurrency(e)} />}
                        <div className="sbmu-field-label">Select Menu File</div>
                        <div className="input-group">
                            <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleMenuFileChange} />
                        </div>
                    </div>}

                    {/* <div className="sbmu-field-label">Select Images</div>
                    <div className="sbmu-icon-container">
                        <label htmlFor="productFileInputOtherImg">
                            <img src={require('../../../assets/camera_icon.png')} className="img" alt="logo" />
                        </label>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            onChange={handleOtherImageChange}
                            id='productFileInputOtherImg'
                        />
                    </div> */}
                    {selectedImagesList && selectedImagesList.length > 0 && <div className="sbmu-field-label">Selected Images</div>}
                    {selectedImagesList && selectedImagesList.length > 0 && <table class="table table-striped table-hover mt-1">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">SNo.</th>
                                <th scope="col" className="rl-table-col-title">Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedImagesList.map((element, index) => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{index + 1}</td>
                                    <td className="rl-table-col-item">{element}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                    <ButtonRow isVertical={true}>
                        <CommonButton label='Upload Menu'
                            onClick={() => { uploadMenuHandle() }} />
                        <a href={require('../../../assets/GoMoBitesProductUpload.xlsx')} target="_blank" className="btn normal-btn download-link-btn-container" download='Menu_Format_File.xlsx'>Download Format</a>
                        <CommonButton label='View Tutorial'
                            onClick={() => { setShowTutorialModal(true) }} />
                    </ButtonRow>
                </CommonForm>
            </div>
            <Modal
                show={showTutorialModal}
                onHide={() => setShowTutorialModal(!showTutorialModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <img src={require('../../../assets/bulk_menu_sample_image.png')} className="img" alt="sample" />
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SupportBulkMenuUpload