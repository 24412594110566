import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog, displayCurrencySymbolLeft,
    displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, isEmpty, isImageAvailable, isNull, screenCaptureAnalytics,
    calculateDiscountedPrice,
    isLoggedInUserBusiness,
    isLoggedInUserEmployee
} from '../../../utils/Utils'
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_CONSTANTS } from '../../../utils/AppConstants';
import ScreenLabel from "../../../components/screen-label/screenLabel";
import { config } from "../../../config";
import FormFieldLabel from "../../../components/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";
import { addItemInCart, displayAddCartBannerAct } from '../../../redux/cart/cart.action'
import Modal from 'react-bootstrap/Modal';

const MenuProductCustomize = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const { state } = useLocation()
    const { user, restaurantDetailSuccess } = useSelector(state => state.user)
    const [quantity, setQuantity] = useState(0)
    const [selectedOption, setSelectedOption] = useState([])
    const [selectedAddOn, setSelectedAddOn] = useState([])
    const [counter, setCounter] = useState(0)
    const [showOptionPriceModal, setShowOptionPriceModal] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_MENU_PRODUCT_CUSTOMIZE',
            screenClass: 'CUSTOMER_MENU_PRODUCT_CUSTOMIZE'
        }
        screenCaptureAnalytics(data)
    }, [])

    const isNoImage = (item) => {
        return item.includes("no-image")
    }

    const getImageClassName = index => {
        const lastDigit = index % 10;
        return `mpc-img-container-color`
    }

    const increaseQuantity = () => {
        setQuantity(Number(quantity) + 1)
    }

    const descreaseQuantity = () => {
        if (quantity === 0) {
            setQuantity(0)
        } else {
            setQuantity(Number(quantity) - 1)
        }
    }

    const addItem = () => {
        if (isNull(state)) {
            displayErrorToast('Error while adding item. Please try again')
        } else if (quantity <= 0) {
            displayErrorToast('Item quantity should be greater than 0')
        } else {
            if (!state.isOptionBasedPricing) {
                const data = {
                    itemId: state.itemId,
                    itemCode: state.itemCode,
                    categoryId: state.categoryId,
                    name: state.name,
                    price: state.price,
                    finalPrice: Number(state.finalPrice) * Number(quantity),
                    quantity: quantity,
                    discount: state.discount,
                    isOptionBasedPricing: false,
                    isFromCart: false,
                    tax: state.isTaxable === true ? state.tax : 0,
                    isTaxable: state.isTaxable,
                    currencyLabel: state.currencyLabel,
                    currencySymbol: state.currencySymbol,
                    currencyRegion: state.currencyRegion,
                    addOns: selectedAddOn,
                    options: selectedOption,
                    measurementUnit: state.measurementUnit,
                    measurementQuantity: state.measurementQuantity
                }
                if (!isEmpty(state.points)) {
                    data.points = Number(state.points) * Number(quantity)
                    data.productPoints = Number(state.points)
                } else {
                    data.points = 0
                    data.productPoints = 0
                }
                if (isEmpty(selectedOption) && isEmpty(selectedAddOn))
                    data.isCustomized = false
                else data.isCustomized = true
                dispatch(addItemInCart(data))
                displaySuccessToast('Item added in cart')
                if (isLoggedInUserBusiness(user) === false && isLoggedInUserEmployee(user) === false) {
                    dispatch(displayAddCartBannerAct(''))
                }
            } else {
                setShowOptionPriceModal(true)
            }

        }
    }

    const handleAddWithPrice = item => {
        const data = {
            itemId: state.itemId,
            itemCode: state.itemCode,
            categoryId: state.categoryId,
            name: `${state.name} - ${item.label}`,
            isOptionBasedPricing: state.isOptionBasedPricing,
            price: item.price,
            finalPrice: Number(item.price) * Number(quantity),
            quantity: quantity,
            discount: 0,
            isFromCart: false,
            tax: 0,
            isTaxable: false,
            currencyLabel: state.currencyLabel,
            currencySymbol: state.currencySymbol,
            currencyRegion: state.currencyRegion,
            addOns: selectedAddOn,
            options: selectedOption,
            measurementUnit: state.measurementUnit,
            measurementQuantity: state.measurementQuantity
        }
        if (!isEmpty(state.points)) {
            data.points = Number(state.points) * Number(quantity)
            data.productPoints = Number(state.points)
        } else {
            data.points = 0
            data.productPoints = 0
        }
        if (isEmpty(selectedOption) && isEmpty(selectedAddOn))
            data.isCustomized = false
        else data.isCustomized = true
        dispatch(addItemInCart(data))
        displaySuccessToast('Item added in cart')
        dispatch(displayAddCartBannerAct(''))
        setShowOptionPriceModal(false)
    }

    const isItemSelected = (item, type) => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            if (isEmpty(selectedAddOn))
                return false
            const selItem = selectedAddOn.find(e => e.name === item.name)
            if (!isNull(selItem))
                return true
            else return false
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            if (isEmpty(selectedOption))
                return false
            const selItem = selectedOption.find(e => e.name === item.name)
            if (!isNull(selItem))
                return true
            else return false
        }
    }

    useEffect(() => {
        console.log('selectedAddOn', selectedAddOn)
    }, [selectedAddOn])

    useEffect(() => {
        console.log('selectedOption', selectedOption)
    }, [selectedOption])

    const handleSelectItem = (item, type, optionLabel = '') => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            if (isEmpty(selectedAddOn)) {
                const tempList = []
                tempList.push(item)
                setSelectedAddOn(tempList)
            } else {
                const item1 = selectedAddOn.find(e => e.name === item.name)
                if (isNull(item1)) {
                    const tempList = selectedAddOn
                    tempList.push(item)
                    setSelectedAddOn(tempList)
                    let count = counter
                    setCounter(count + 1)
                } else {
                    const tempList = selectedAddOn.filter(e => e.name !== item.name)
                    setSelectedAddOn(tempList)
                }
            }
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            if (isEmpty(selectedOption)) {
                const tempList = [{ ...item, optionLabel: optionLabel }]
                setSelectedOption(tempList)
            } else {
                const item1 = selectedOption.find(e => e.name === item.name)
                if (isNull(item1)) {
                    const itemWithSameLabel = selectedOption.find(e => e.optionLabel === optionLabel)
                    if (isNull(itemWithSameLabel)) {
                        const tempList = selectedOption
                        tempList.push({ ...item, optionLabel: optionLabel })
                        setSelectedOption(tempList)
                        let count = counter
                        setCounter(count + 1)
                    } else {
                        const tempList = selectedOption.filter(e => e.name !== itemWithSameLabel.name)
                        tempList.push({ ...item, optionLabel: optionLabel })
                        setSelectedOption(tempList)
                    }
                } else {
                    const tempList = selectedOption.filter(e => e.name !== item.name)
                    setSelectedOption(tempList)
                }
            }
        }
    }

    return (<>
        <div className="pl-container mb-4 mpc-content-container">
            {(!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data)) ? (
                <div className="cmc-label-container mt-2">
                    <div className="mcp-logo-container">
                        {isImageAvailable(restaurantDetailSuccess.data.image) ?
                            (<img src={`${config.imageUrl}${restaurantDetailSuccess.data.image}`} alt="logo" className="img" />) :
                            <img className="cmc-img" src={require('../../../assets/NewLogoCircle.png')} alt="logo" />}
                    </div>
                    <div className="mcp-restaurant-label">{restaurantDetailSuccess.data.businessName}</div>
                </div>
            ) :
                (<div className="cmc-label-container">
                    <ScreenLabel label='Product Details' />
                </div>)
            }
            <div className="mpc-image-container">
                {!isNoImage(state.image) ? (<img src={`${config.imageUrl}${state.image}`} className="pl-img" alt="..." />) : (
                    <div className={`${getImageClassName(0)}`} />
                )}
            </div>
            {/* {state.isVeg === true ? (<div className="mpc-option-type-container">VEG</div>) : null} */}
            {/* {state.isNonVeg === true ? (<div className="mpc-option-type-container">NON VEG</div>) : null} */}
            {/* {state.isVegan === true ? (<div className="mpc-option-type-container">VEGAN</div>) : null} */}
            <div className="mpc-title">{state.name}</div>
            <div className="mpc-description">{state.description}</div>
            {!state.isOptionBasedPricing && <div>
                {state.discount <= 0 && <div> {displayCurrencySymbolLeft(state.currencyRegion) === true ?
                    (<div className="pl-price-label">{state.currencySymbol} {state.price.toFixed(2)} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)
                    : (<div className="pl-price-label">{state.price.toFixed(2)} {state.currencySymbol} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)}
                </div>}
            </div>}
            {!state.isOptionBasedPricing && <div>
                {state.discount > 0 && <div>
                    {displayCurrencySymbolLeft(state.currencyRegion) === true ?
                        (<div className="pl-price-label">{state.currencySymbol} <span className="pl-price-label-striked">{state.price.toFixed(2)}</span> {calculateDiscountedPrice(state.price, state.discount)} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)
                        : (<div className="pl-price-label">{state.price.toFixed(2)} {state.currencySymbol} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)}
                </div>}
            </div>}
            {state.isOptionBasedPricing && <div>
                {state.optionBasedPricing.map(e => (
                    <div>
                        {displayCurrencySymbolLeft(state.currencyRegion) === true ?
                            (<div className="pl-price-label">{e.label} - {state.currencySymbol} {e.price.toFixed(2)}</div>)
                            : (<div className="pl-price-label">{e.label} - {e.price.toFixed(2)} {state.currencySymbol}</div>)}
                    </div>
                ))}
            </div>}

            <FormFieldLabel className='label-bold' label='Available Options' />
            {!isEmpty(state.options) && <div>{
                state.options.map((e, index) => (<div>
                    <div className="mpc-option-item-name">{index + 1}. {e.title}</div>
                    <div className="mpc-option-item-container">
                        {e.items.map(e1 => (
                            <div className="ap-option-item">
                                <div className="mpc-center-div mpc-div-justify-space mb-2">
                                    {isItemSelected(e1, APP_CONSTANTS.KEY_OPTION) && <div className="mpc-selected-img-container">
                                        <img src={require('../../../assets/ic_green_check.png')} className="img" alt="check" />
                                    </div>}
                                    <div>
                                        <div className="mpc-option-type-description">{e1.isVeg === true ? <span>Veg</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isNonVeg === true ? <span>Non Veg</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isVegan === true ? <span>Vegan</span> : null}</div>
                                    </div>
                                </div>
                                <div className="ap-option-item-name">{e1.name}</div>
                                {e1.price === 0 ? (<div className="pl-price-label">Free</div>) :
                                    (<div>{displayCurrencySymbolLeft(state.currencyRegion) === true ?
                                        (<div className="pl-price-label">{state.currencySymbol} {e1.price}</div>)
                                        : (<div className="pl-price-label">{e1.price} {state.currencySymbol}</div>)}</div>)
                                }
                                <div className="ap-option-item-description">{!isEmpty(e1.description) ? <span>{e1.description}</span> : <span>&nbsp;</span>}</div>
                                <div className="mpc-add-btn" onClick={() => { handleSelectItem(e1, APP_CONSTANTS.KEY_OPTION, e.title) }}>{isItemSelected(e1, APP_CONSTANTS.KEY_OPTION) === true ? 'Remove' : 'Select'}</div>
                            </div>
                        ))}
                    </div>
                </div>))}
            </div>}
            <FormFieldLabel className='label-bold' label='Available Add-Ons' />
            {!isEmpty(state.addOns) && <div>{
                state.addOns.map((e, index) => (<div>
                    <div className="mpc-option-item-name">{index + 1}. {e.title}</div>
                    <div className="mpc-option-item-container">
                        {e.items.map(e1 => (
                            <div className="ap-option-item">
                                <div className="mpc-center-div mpc-div-justify-space mb-2">
                                    {isItemSelected(e1, APP_CONSTANTS.KEY_ADD_ON) && <div className="mpc-selected-img-container">
                                        <img src={require('../../../assets/ic_green_check.png')} className="img" alt="check" />
                                    </div>}
                                    <div>
                                        <div className="mpc-option-type-description">{e1.isVeg === true ? <span>Veg</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isNonVeg === true ? <span>Non Veg</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isVegan === true ? <span>Vegan</span> : null}</div>
                                    </div>
                                </div>
                                <div className="ap-option-item-name">{e1.name}</div>
                                <div className="ap-option-item-description">{!isEmpty(e1.description) ? <span>{e1.description}</span> : <span>&nbsp;</span>}</div>
                                {e1.price === 0 ? (<div className="pl-price-label">Free</div>) :
                                    (<div>{displayCurrencySymbolLeft(state.currencyRegion) === true ?
                                        (<div className="pl-price-label">{state.currencySymbol} {e1.price}</div>)
                                        : (<div className="pl-price-label">{e1.price} {state.currencySymbol}</div>)}</div>)
                                }
                                <div className="mpc-add-btn" onClick={() => { handleSelectItem(e1, APP_CONSTANTS.KEY_ADD_ON) }}>{isItemSelected(e1, APP_CONSTANTS.KEY_ADD_ON) === true ? 'Remove' : 'Add'}</div>
                            </div>
                        ))}
                    </div>
                </div>))}
            </div>}
            <FormFieldLabel className='label-bold' label='Select Quantity' />
            <div className="mpc-center-div">
                <div className="mpc-quantity-img-container" onClick={() => { descreaseQuantity() }}>
                    <img src={require('../../../assets/icon_sq_minus.png')} className="img" alt="minus" />
                </div>
                <div className="mpc-quantity-val">{quantity}</div>
                <div className="mpc-quantity-img-container" onClick={() => { increaseQuantity() }}>
                    <img src={require('../../../assets/icon_sq_plus.png')} alt="plus" className="img" />
                </div>
            </div>
            {!isNull(restaurantDetailSuccess) && !isNull(restaurantDetailSuccess.data) && restaurantDetailSuccess.data.allowOnlineOrdering === true && <ButtonRow>
                <CommonButton label='Add To Cart'
                    onClick={() => { addItem() }} />
            </ButtonRow>}
        </div>
        <Modal
            show={showOptionPriceModal}
            onHide={() => setShowOptionPriceModal(!showOptionPriceModal)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="mp-button-container">
                    {!isNull(state) && state.optionBasedPricing.map(e => (
                        <CommonButton
                            label={`${e.label} - ${e.price}`}
                            onClick={() => { handleAddWithPrice(e) }}
                        />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
        <ToastContainer />
        <Loader active={isLoading} text='Please wait...' />
    </>)
}

export default MenuProductCustomize