export const SellerActionTypes = {
    INV_ADD_SELLER_REQUEST: 'INV_ADD_SELLER_REQUEST',
    INV_ADD_SELLER_REQUEST_SUCCESS: 'INV_ADD_SELLER_REQUEST_SUCCESS',
    INV_ADD_SELLER_REQUEST_ERROR: 'INV_ADD_SELLER_REQUEST_ERROR',
    INV_ADD_SELLER_REQUEST_STATUS: 'INV_ADD_SELLER_REQUEST_STATUS',

    INV_SELLER_LIST_REQUEST: 'INV_SELLER_LIST_REQUEST',
    INV_SELLER_LIST_REQUEST_SUCCESS: 'INV_SELLER_LIST_REQUEST_SUCCESS',
    INV_SELLER_LIST_REQUEST_ERROR: 'INV_SELLER_LIST_REQUEST_ERROR',

    INV_UPDATE_SELLER_REQUEST: 'INV_UPDATE_SELLER_REQUEST',
    INV_UPDATE_SELLER_REQUEST_SUCCESS: 'INV_UPDATE_SELLER_REQUEST_SUCCESS',
    INV_UPDATE_SELLER_REQUEST_ERROR: 'INV_UPDATE_SELLER_REQUEST_ERROR',
    INV_UPDATE_SELLER_REQUEST_STATUS: 'INV_UPDATE_SELLER_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE'
}