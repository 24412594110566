import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import {
    orderListRequest, acceptRejectOrderRequest, updateOrderStatusRequest,
    updateOrderStatusRequestStatus, acceptRejectOrderRequestStatus, acceptPayAtEndPaymentRequest,
    acceptPayAtEndPaymentRequestStatus
} from '../../../../redux/order/order.action'
import { convertToPascal, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, getProductMeasurmentUnitOrder, isEmployeeTypeWaiter, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, priceWithSymbol, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import notificationTone from "../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../redux/socket/socket.action'
import RefreshButton from "../../../../components/button/refresh-button/refreshButton";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import { SocketContext } from '../../../../SocketCtx'
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import { useNavigate } from 'react-router-dom';

const OrderListScreen = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { orderListSuccess, orderListError,
        updateOrderSuccess, updateOrderError, updateOrderStatus,
        acceptRejectOrderSuccess, acceptRejectOrderError, acceptRejectOrderStatus,
        acceptPayAtEndPaymentSuccess, acceptPayAtEndPaymentError, acceptPayAtEndPaymentReqStatus } = useSelector(state => state.order)
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [newOrder, setNewOrder] = useState(null)
    const [processingOrder, setProcessingOrder] = useState(null)
    const [deliveredOrder, setDeliveredOrder] = useState(null)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false)
    const [preparationTimeHr, setPreparationTimeHr] = useState(0)
    const [preparationTimeMin, setPreparationTimeMin] = useState(0)
    const [cancelReason, setCancelReason] = useState('')
    const KEY = 'KEY'
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    const [selectedPaymentMode, setSelectedPaymentMode] = useState()
    const [availableItemList, setAvailableItemList] = useState([])
    const [unavailableItemList, setUnavailableItemList] = useState([])
    const [isSelectedPaymentModeOffline, setIsSelectedPaymentModeOffline] = useState(false)
    const [isSelectedPaymentModePayAtEnd, setIsSelectedPaymentModePayAtEnd] = useState(false)
    const [isPaymentReceived, setIsPaymentReceived] = useState(false)
    const [paymentModeName, setPaymentModeName] = useState('')
    const [displayPaymentRecievedCheck, setDisplayPaymentReceivedCheck] = useState(false)

    const [isPaymentReceivedOffline, setIsPaymentReceivedOffline] = useState(false)
    const [paymentModeNameOffline, setPaymentModeNameOffline] = useState('')

    const [payAtEndPaymentModeName, setPayAtEndPaymentModeName] = useState('')
    const socket = useContext(SocketContext)
    const [selectedCustomerOrderId, setSelectedCustomerOrderId] = useState('')
    const [isOrderStatusMarkPrepared, setIsOrderStatusMarkPrepared] = useState(false)
    const [isOrderStatusMarkDelivered, setIsOrderStatusMarkDelivered] = useState(false)
    const [isOrderStatusMarkServed, setIsOrderStatusMarkServed] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [selectedOrderItem, setSelectedOrderItem] = useState()
    let navigate = useNavigate();

    const ORDER_LIST = 'ORDER_LIST'
    const UPDATE_STATUS = 'UPDATE_STATUS'
    const PLACE_ORDER = 'PLACE_ORDER'

    useEffect(() => {
        const data = {
            screen: 'ORDER_LIST',
            screenClass: 'ORDER_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        fetchOrderList()
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        if (!isNull(orderListSuccess)) {
            setLoading(false)
            const newOrderList = orderListSuccess.data.filter(item => (item.orderStatus === APP_CONSTANTS.ORDER_STATUS.PLACED || item.orderStatus === APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION || item.orderStatus === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED))
            setNewOrder(newOrderList)
            const deliveredOrderList = orderListSuccess.data.filter(item => (item.orderStatus === APP_CONSTANTS.ORDER_STATUS.DELIVERED || item.orderStatus === APP_CONSTANTS.ORDER_STATUS.CANCELLED))
            setDeliveredOrder(deliveredOrderList)
            const processingOrderList = orderListSuccess.data.filter(item => (item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.DELIVERED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.PLACED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.CANCELLED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED && item.orderStatus !== APP_CONSTANTS.ORDER_STATUS.AWAITING_ONLINE_PAYMENT && item.orderStatus !== ''))
            setProcessingOrder(processingOrderList)
        } else if (!isNull(orderListError)) {
            setLoading(false)
            setNewOrder(null)
            setDeliveredOrder(null)
            setProcessingOrder(null)
        }
    }, [orderListSuccess, orderListError])

    useEffect(() => {
        if (updateOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateOrderSuccess.message)
            fetchOrderList()
            setIsOrderStatusMarkDelivered(false)
            setIsOrderStatusMarkPrepared(false)
            setIsPaymentReceivedOffline(false)
            const item = JSON.parse(window.sessionStorage.getItem(KEY))
            const data = {
                restaurantId: item.businessId,
                locationId: item.locationId
            }
            socket.emit('order-update-status', data)
        } else if (updateOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateOrderError.message)
        }
        if (updateOrderStatus.length) {
            dispatch(updateOrderStatusRequestStatus(''))
        }
    }, [updateOrderSuccess, updateOrderError, updateOrderStatus])

    useEffect(() => {
        if (acceptPayAtEndPaymentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(acceptPayAtEndPaymentSuccess.message)
            fetchOrderList()
            const data = {
                customerId: selectedCustomerOrderId,
                message: 'Your order payment is successfully processed.'
            }
            socket.emit('pay-at-end-payment-success', data)
        } else if (acceptPayAtEndPaymentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(acceptPayAtEndPaymentError.message)
        }
        if (acceptPayAtEndPaymentReqStatus.length) {
            dispatch(acceptPayAtEndPaymentRequestStatus(''))
        }
    }, [acceptPayAtEndPaymentSuccess, acceptPayAtEndPaymentError, acceptPayAtEndPaymentReqStatus])

    useEffect(() => {
        if (acceptRejectOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(acceptRejectOrderSuccess.message)
            fetchOrderList()
            const item = JSON.parse(window.sessionStorage.getItem(KEY))
            const data = {
                restaurantId: item.businessId,
                locationId: item.locationId
            }
            socket.emit('order-update-status', data)
        } else if (acceptRejectOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(acceptRejectOrderError.message)
        }
        if (acceptRejectOrderStatus.length) {
            dispatch(acceptRejectOrderRequestStatus(''))
        }
    }, [acceptRejectOrderSuccess, acceptRejectOrderError, acceptRejectOrderStatus])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.ORDER_LIST, true)
            isFirstTime = true
            const data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchOrderList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(orderListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else if (isLoggedInUserEmployee(user) === true) {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            if (isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user) === true) {
                data.employeeId = user.data.userDetails?.employeeDetails?.employeeDetailId
            }
            dispatch(orderListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.ORDER_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.ORDER_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(branchListSuccess)) {
                setLoading(false)
                setSelectedLocation(branchListSuccess.data[0])
            } else if (!isNull(branchListError)) {
                setLoading(false)
            }
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedLocation)) {
            fetchOrderList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchOrderList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const rejectOrder = () => {
        if (checkFunctionalityPermission(UPDATE_STATUS) === true) {
            if (isEmpty(cancelReason)) {
                displayErrorToast('Cancel reason cannot be blank')
            } else if (isPaymentReceived === true && isEmpty(paymentModeName)) {
                displayErrorToast('Payment mode cannot be blank')
            } else {
                setShowRejectModal(false)
                const item = JSON.parse(window.sessionStorage.getItem(KEY))
                const data = {
                    orderId: item.orderId,
                    status: APP_CONSTANTS.ORDER_STATUS.ACCEPT_REJECT_ACTION.REJECT,
                    reason: cancelReason,
                    unavailableItemList: availableItemList
                }
                if (item.paymentMode !== APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END) {
                    if (isPaymentReceived === true) {
                        data.isPaid = true
                        data.paymentModeName = paymentModeName
                    } else {
                        data.isPaid = true
                    }
                }
                setLoading(true)
                dispatch(acceptRejectOrderRequest(data))
            }
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const acceptOrder = () => {
        if (checkFunctionalityPermission(UPDATE_STATUS) === true) {
            const numberRegex = new RegExp('-?[0-9]+(\.[0-9]+)?')
            if (isNull(preparationTimeHr)) {
                displayErrorToast('Invalid preparation time in hour')
            } else if (!numberRegex.test(preparationTimeHr)) {
                displayErrorToast('Preparation time in hour can only be number')
            } else if (isNull(preparationTimeMin)) {
                displayErrorToast('Invalid preparation time in min')
            } else if (!numberRegex.test(preparationTimeMin)) {
                displayErrorToast('Preparation time in min can only be number')
            } else {
                setShowAcceptModal(false)
                const item = JSON.parse(window.sessionStorage.getItem(KEY))
                const data = {
                    status: APP_CONSTANTS.ORDER_STATUS.ACCEPT_REJECT_ACTION.ACCEPT,
                    orderId: item.orderId,
                    preparationTimeHour: preparationTimeHr,
                    preparationTimeMinute: preparationTimeMin,
                    unavailableItemList: unavailableItemList,
                    availableItemList: availableItemList
                }
                if (isPaymentReceived === true) {
                    data.isPaid = true
                    data.paymentModeName = paymentModeName
                } else {
                    data.isPaid = false
                }
                setLoading(true)
                dispatch(acceptRejectOrderRequest(data))
            }
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleAskCustomerClick = () => {
        if (checkFunctionalityPermission(UPDATE_STATUS) === true) {
            setShowAcceptModal(false)
            const item = JSON.parse(window.sessionStorage.getItem(KEY))
            const data = {
                status: APP_CONSTANTS.ORDER_STATUS.ACCEPT_REJECT_ACTION.WAIT,
                orderId: item.orderId,
                unavailableItemList: unavailableItemList,
                availableItemList: availableItemList
            }
            if (isPaymentReceived === true) {
                data.isPaid = true
                data.paymentModeName = paymentModeName
            } else {
                data.isPaid = false
            }
            setLoading(true)
            dispatch(acceptRejectOrderRequest(data))

        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const updateStatus = (item) => {
        if (isOrderStatusMarkPrepared === false && isOrderStatusMarkDelivered === false && isOrderStatusMarkServed === false) {
            displayErrorToast('Order status not selected')
            return
        }
        window.sessionStorage.setItem(KEY, JSON.stringify(item))
        if (checkFunctionalityPermission(UPDATE_STATUS) === true) {
            if (isOrderStatusMarkDelivered === true) {
                if (item.paymentMode !== APP_CONSTANTS.PAY_AT_END) {
                    if (item.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID) {
                        const data = {
                            orderId: item.orderId,
                            orderStatus: APP_CONSTANTS.ORDER_STATUS.DELIVERED
                        }
                        setLoading(true)
                        dispatch(updateOrderStatusRequest(data))
                    } else {
                        if (isPaymentReceivedOffline === false) {
                            displayErrorToast('Payment received confirmation checkbox is unchecked. Please check.')
                        } else if (isEmpty(paymentModeNameOffline)) {
                            displayErrorToast('Payment received mode name cannot be blank')
                        } else {
                            const data = {
                                orderId: item.orderId,
                                orderStatus: APP_CONSTANTS.ORDER_STATUS.DELIVERED,
                                paymentModeName: paymentModeNameOffline,
                                isPaid: true
                            }
                            setLoading(true)
                            dispatch(updateOrderStatusRequest(data))
                        }
                    }
                } else {
                    const data = {
                        orderId: item.orderId,
                        orderStatus: APP_CONSTANTS.ORDER_STATUS.DELIVERED
                    }
                    setLoading(true)
                    dispatch(updateOrderStatusRequest(data))
                }
            } else {
                const data = {
                    orderId: item.orderId
                }
                if (isOrderStatusMarkPrepared === true) {
                    data.orderStatus = APP_CONSTANTS.ORDER_STATUS.PREPARED
                }
                if (isOrderStatusMarkServed === true) {
                    data.orderStatus = APP_CONSTANTS.ORDER_STATUS.SERVED
                }
                setLoading(true)
                dispatch(updateOrderStatusRequest(data))
            }
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserBusiness(user) === true) {
            return true;
        } else if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.ORDER && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === ORDER_LIST) {
                    if (itemObj.orderList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === UPDATE_STATUS) {
                    if (itemObj.updateOrder === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === PLACE_ORDER) {
                    if (itemObj.placeOrder === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return false
        }
    }

    const handleShowAcceptModalClick = element => {
        setSelectedOrderItem(element)
        if (element.orderStatus === APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED) {
            setUnavailableItemList(element.unavailableItemList)
            setAvailableItemList(element.availableItemList)
        } else {
            setUnavailableItemList([])
            setAvailableItemList(element.itemsList)
        }

        window.sessionStorage.setItem(KEY, JSON.stringify(element))
        if (element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE) {
            setIsSelectedPaymentModeOffline(true)
        }
        if (element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END) {
            setIsSelectedPaymentModePayAtEnd(true)
        }
        if (element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE && element.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID) {
            setDisplayPaymentReceivedCheck(false)
        } else {
            setDisplayPaymentReceivedCheck(true)
        }
        setShowAcceptModal(true)
    }

    const handleShowRejectModalClick = element => {
        if (showAcceptModal === true) {
            setShowAcceptModal(false)
        }
        setUnavailableItemList([])
        setAvailableItemList(element.itemsList)
        window.sessionStorage.setItem(KEY, JSON.stringify(element))
        if (element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE) {
            setIsSelectedPaymentModeOffline(true)
        }
        if (element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END) {
            setIsSelectedPaymentModePayAtEnd(true)
        }
        if (element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE && element.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID) {
            setDisplayPaymentReceivedCheck(false)
        } else {
            setDisplayPaymentReceivedCheck(true)
        }
        setShowRejectModal(true)
    }

    const handleMarkNotAvailabel = item => {
        let tempAvailableItemList = []
        availableItemList.forEach(e => {
            if (e.itemId === item.itemId && e.quantity === item.quantity) {

            } else {
                tempAvailableItemList.push(e)
            }
        })
        setAvailableItemList(tempAvailableItemList)
        let listArr = unavailableItemList
        listArr.push(item)
        setUnavailableItemList(listArr)
    }

    const handleAcceptPayAtEndPayment = (item) => {
        setSelectedCustomerOrderId(item.customerId)
        if (isEmpty(payAtEndPaymentModeName)) {
            displayErrorToast('Payment mode name cannot be blank')
        } else {
            const data = {
                orderId: item.orderId,
                paymentModeName: payAtEndPaymentModeName
            }
            setLoading(true)
            dispatch(acceptPayAtEndPaymentRequest(data))
        }
    }

    const handleOrderStatusChange = (type, item) => {
        sessionStorage.setItem('selectedId', item.orderId)
        if (type === APP_CONSTANTS.ORDER_STATUS.PREPARED) {
            setIsOrderStatusMarkPrepared(true)
            setIsOrderStatusMarkDelivered(false)
            setIsOrderStatusMarkServed(false)
        } else if (type === APP_CONSTANTS.ORDER_STATUS.SERVED) {
            setIsOrderStatusMarkServed(true)
            setIsOrderStatusMarkPrepared(false)
            setIsOrderStatusMarkDelivered(false)
        } else if (type === APP_CONSTANTS.ORDER_STATUS.DELIVERED) {
            setIsOrderStatusMarkPrepared(false)
            setIsOrderStatusMarkDelivered(true)
            setIsOrderStatusMarkServed(false)
        }
    }

    const handlePlaceOrderClick = () => {
        navigate(ROUTES.placeOrder)
    }

    const getSelectedOrderId = () => {
        const selectedOrderId = sessionStorage.getItem('selectedId')
        if (!isEmpty(selectedOrderId)) {
            return parseFloat(selectedOrderId)
        } else {
            return -1
        }
    }

    return (
        <>
            <div className="ols-list-container">
                <div className="ols-label-container">
                    <ScreenLabel label='Orders' />
                    <RefreshButton label='Refresh'
                        onClick={() => { fetchOrderList() }} />
                </div>
                {isLoggedInUserBusiness(user) === true && <div className="ols-select-row-container">
                    <div className="ols-select-container">
                        <div className="ols-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='ols-list-select'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    {isLoggedInUserBusiness(user) === true && <div className="ols-select-container">
                        <div className="ols-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='ols-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>}
                </div>}
                {checkFunctionalityPermission(ORDER_LIST) === true && <Tabs defaultActiveKey="newOrders" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="newOrders" title="New Orders">
                        <div className="ols-card-container">
                            {newOrder && newOrder.map(element => (
                                <div className="card ols-card">
                                    <div className="ols-card-title">Order # {element.orderNumber}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Order Code</div>
                                    <div className="ols-card-title-lightols-card-title mt-0">{element.orderCode}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Status</div>
                                    <div className="ols-card-title-light mt-0">{element.paymentStatus ? removeSpecialCharAndChangeToPascal(element.paymentStatus) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Mode</div>
                                    <div className="ols-card-title-light mt-0">{element.paymentMode ? removeSpecialCharAndChangeToPascal(element.paymentMode) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Order Status</div>
                                    <div className="ols-card-title-light mt-0">{element.orderStatus ? removeSpecialCharAndChangeToPascal(element.orderStatus) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">If one or some items not available?</div>
                                    <div className="ols-card-title-light mt-0">{element.itemNotAvailableOption ? removeSpecialCharAndChangeToPascal(element.itemNotAvailableOption) : '-'}</div>
                                    {!isEmpty(element.customerAction) && <div>
                                        <div className="ols-card-label-blue-bold mt-1">Customer Action</div>
                                        <div className="ols-card-title-light mt-0">{element.customerAction === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_ACTION.CONTINUE ? 'Continue with available items' : 'Cancel order'}</div>
                                    </div>}
                                    <div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Name</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{element.contactPersonName ? element.contactPersonName : '-'}</div>
                                    </div>
                                    {!isEmpty(element.dineInTableNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Table Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.dineInTableNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.curbsideSlotNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Slot Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.curbsideSlotNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.roomNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Room Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.roomNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.deliveryTerminalNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Delivery Terminal</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.deliveryTerminalNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.otherInformation) && <div className="ols-card-label-blue-bold mt-2">Special Request</div>}
                                    {!isEmpty(element.otherInformation) && <div className="ols-card-label ols-card-label-blue">{element.otherInformation}</div>}
                                    {element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PLACED && <div>
                                        <div className="ols-card-label-blue-bold mt-2">Items</div>
                                        <div>
                                            {element.itemsList.map(item => (
                                                <div className="ols-card-btn-row h-padding ols-card-btn-row-border">
                                                    <div className="ols-card-label-grow ols-card-label-name-container">
                                                        <div>{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</div>
                                                        {!isEmpty(item.options) && <div className="mt-1">
                                                            <div className="ols-customization-label">Options</div>
                                                            <div className="crl-customization-container">
                                                                {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                            </div>
                                                        </div>}
                                                        {!isEmpty(item.addOns) && <div className="mt-1">
                                                            <div className="ols-customization-label">Add-ons</div>
                                                            <div className="crl-customization-container">
                                                                {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                            </div>
                                                        </div>}
                                                    </div> <div className="ols-card-label-small ms-1 me-1">x</div> <div className="ols-card-label-small">{item.quantity}</div>
                                                </div>))}
                                        </div>
                                    </div>}
                                    {(element.orderStatus === APP_CONSTANTS.ORDER_STATUS.AWAITING_CUSTOMER_ACTION || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED) && <div>
                                        {element.availableItemList && element.availableItemList.length > 0 && <div className="ols-card-label-blue-bold mt-2">Items to Deliver</div>}
                                        {element.availableItemList && element.availableItemList.length > 0 && <table class="table table-responsive table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="rl-table-col-title">Name</th>
                                                    <th scope="col" className="rl-table-col-title text-center">Qty</th>
                                                    <th scope="col" className="rl-table-col-title text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {element.availableItemList.map(item => (
                                                    <tr className="rl-table-row-container">
                                                        <td className="rl-table-col-item ols-card-label-val">{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</td>
                                                        <td className="rl-table-col-item ols-card-label-val text-center">{item.quantity}</td>
                                                        <td className="rl-table-col-item ols-card-label-val text-center">{removeSpecialCharAndChangeToPascal(item.status)}</td>
                                                    </tr>))}
                                            </tbody>
                                        </table>}
                                        {element.unavailableItemList && element.unavailableItemList.length > 0 && <div className="ols-card-label-blue-bold mt-2">Un-available Items</div>}
                                        {element.unavailableItemList && element.unavailableItemList.length > 0 && <div>
                                            {element.unavailableItemList.map(item => (
                                                <div className="ols-card-btn-row h-padding ols-card-btn-row-border">
                                                    <div className="ols-card-label-grow ols-card-label-name-container">
                                                        <div>{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</div>
                                                        {!isEmpty(item.options) && <div className="mt-1">
                                                            <div className="ols-customization-label">Options</div>
                                                            <div className="crl-customization-container">
                                                                {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                            </div>
                                                        </div>}
                                                        {!isEmpty(item.addOns) && <div className="mt-1">
                                                            <div className="ols-customization-label">Add-ons</div>
                                                            <div className="crl-customization-container">
                                                                {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                            </div>
                                                        </div>}
                                                    </div> <div className="ols-card-label-small ms-1 me-1">x</div> <div className="ols-card-label-small">{item.quantity}</div>
                                                </div>))}
                                        </div>}
                                    </div>}
                                    {(isLoggedInUserBusiness(user) || (isLoggedInUserEmployee(user) && !isEmployeeTypeWaiter(employeeSelectedRestaurant, employeeSelectedLocation, user))) && <div className="ols-card-btn-row ols-card-btn-row-btn mt-3">
                                        <div className="btn ols-card-btn" onClick={() => {
                                            handleShowRejectModalClick(element)
                                        }}>Reject</div>
                                        <div className="btn ols-card-btn" onClick={() => {
                                            // window.sessionStorage.setItem(KEY, JSON.stringify(element))
                                            // setShowAcceptModal(true)
                                            handleShowAcceptModalClick(element)
                                        }}>Proceed</div>
                                    </div>}
                                </div>
                            ))}
                            {isNull(newOrder) ? (<div>No new order found</div>) : null}
                        </div>
                    </Tab>
                    <Tab eventKey="onGoing" title="On Going">
                        <div className="ols-card-container">
                            {processingOrder && processingOrder.map(element => (
                                <div className="card ols-card">
                                    <div className="ols-card-title">Order # {element.orderNumber}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Order Code</div>
                                    <div className="ols-card-title mt-0">{element.orderCode}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Order Status</div>
                                    <div className="ols-card-title mt-0">{removeSpecialCharAndChangeToPascal(element.orderStatus)}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Status</div>
                                    <div className="ols-card-title mt-0">{element.paymentStatus ? removeSpecialCharAndChangeToPascal(element.paymentStatus) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Mode</div>
                                    <div className="ols-card-title mt-0">{element.paymentMode ? removeSpecialCharAndChangeToPascal(element.paymentMode) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Mode Name</div>
                                    <div className="ols-card-title mt-0">{element.paymentModeName ? element.paymentModeName : '-'}</div>
                                    <div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Name</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{element.contactPersonName ? element.contactPersonName : '-'}</div>
                                    </div>
                                    {!isEmpty(element.dineInTableNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Table Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.dineInTableNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.curbsideSlotNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Slot Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.curbsideSlotNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.roomNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Room Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.roomNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.deliveryTerminalNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Delivery Terminal</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.deliveryTerminalNumber}</div>
                                        </div>
                                    ) : null}
                                    <div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Preparation Time</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{element.preparationTime}</div>
                                    </div>
                                    {!isEmpty(element.otherInformation) && <div className="ols-card-label-blue-bold mt-2">Special Request</div>}
                                    {!isEmpty(element.otherInformation) && <div className="ols-card-label ols-card-label-blue">{element.otherInformation}</div>}
                                    {element.processedItems && element.processedItems.length > 0 && <div className="ols-card-label-blue-bold mt-2">Items to Deliver</div>}
                                    {element.processedItems && element.processedItems.length > 0 && <table class="table table-responsive table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="rl-table-col-title">Name</th>
                                                <th scope="col" className="rl-table-col-title text-center">Qty</th>
                                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {element.processedItems.map(item => (
                                                <tr className="rl-table-row-container">
                                                    <td className="rl-table-col-item ols-card-label-val">{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</td>
                                                    <td className="rl-table-col-item ols-card-label-val text-center">{item.quantity}</td>
                                                    <td className="rl-table-col-item ols-card-label-val text-center">{removeSpecialCharAndChangeToPascal(item.status)}</td>
                                                </tr>))}
                                        </tbody>
                                    </table>}
                                    {element.unavailableItemList && element.unavailableItemList.length > 0 && <div className="ols-card-label-blue-bold mt-2">Un-available Items</div>}
                                    {element.unavailableItemList && element.unavailableItemList.length > 0 && <div>
                                        {element.unavailableItemList.map(item => (
                                            <div className="ols-card-btn-row h-padding ols-card-btn-row-border">
                                                <div className="ols-card-label-grow ols-card-label-name-container">
                                                    <div>{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</div>
                                                    {!isEmpty(item.options) && <div className="mt-1">
                                                        <div className="ols-customization-label">Options</div>
                                                        <div className="crl-customization-container">
                                                            {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                        </div>
                                                    </div>}
                                                    {!isEmpty(item.addOns) && <div className="mt-1">
                                                        <div className="ols-customization-label">Add-ons</div>
                                                        <div className="crl-customization-container">
                                                            {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                        </div>
                                                    </div>}
                                                </div> <div className="ols-card-label-small ms-1 me-1">x</div> <div className="ols-card-label-small">{item.quantity}</div>
                                            </div>))}
                                    </div>}
                                    <div className="ols-card-btn-row mt-2">
                                        <div className="ols-card-label ols-card-row-item-border">Total</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{priceWithSymbol(element, element.grandTotal)}</div>
                                        {/* {
                                            displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                                (<div className="ols-card-label-val ols-card-row-item-border">{priceWithSymbol(element, element.grandTotal)} {element.currencySymbol} {element.grandTotal.toFixed(2)}</div>)
                                                : (<div className="ols-card-label-val ols-card-row-item-border">{element.grandTotal.toFixed(2)} {element.currencySymbol}</div>)} */}
                                    </div>
                                    {element.refundAmount && element.refundAmount > 0 ? (<div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Refund Amount</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{priceWithSymbol(element, element.refundAmount)}</div>
                                        {/* {
                                            displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                                (<div className="ols-card-label-val ols-card-row-item-border">{element.currencySymbol} {element.refundAmount.toFixed(2)}</div>)
                                                : (<div className="ols-card-label-val ols-card-row-item-border">{element.refundAmount.toFixed(2)} {element.currencySymbol}</div>)} */}
                                    </div>) : null}
                                    {!isEmpty(element.cancelReason) ? (
                                        <>
                                            <div className="ols-card-label ols-card-label-blue mt-2">Cancel Reason</div>
                                            <div className="ols-card-label ols-card-label-blue mt-1">{element.cancelReason}</div>
                                        </>
                                    ) : null}
                                    {element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE && element.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PENDING ? (<div className="su-input-full mt-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input su-input-text"
                                            id="name"
                                            checked={isPaymentReceivedOffline}
                                            value={isPaymentReceivedOffline}
                                            onChange={() => {
                                                setIsPaymentReceivedOffline(!isPaymentReceivedOffline)
                                            }}
                                            required />
                                        <label for="name" className="su-checkbox-label">Order Payment received?</label>
                                    </div>) : null}
                                    {isPaymentReceivedOffline === true && <div className="form-floating mt-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            placeholder="Payment Mode"
                                            value={paymentModeNameOffline}
                                            onChange={e => setPaymentModeNameOffline(e.target.value)}
                                            required />
                                        <label for="email">Payment Mode</label>
                                    </div>}
                                    <FormFieldLabel className='up-bold-label' label='Select Order Status' />
                                    {element.orderStatus !== APP_CONSTANTS.ORDER_STATUS.DELIVERED && element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARING && <div>
                                        <label className="ols-order-status-label-container">
                                            <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.PREPARED} checked={element.orderId === getSelectedOrderId() ? isOrderStatusMarkPrepared : false} onChange={e => {
                                                handleOrderStatusChange(e.target.value, element)
                                            }} />
                                            <span className='ols-order-status-label'>Ready To Serve</span>
                                        </label>
                                        <label className="ols-order-status-label-container">
                                            <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.DELIVERED} checked={element.orderId === getSelectedOrderId() ? isOrderStatusMarkDelivered : false} onChange={e => {
                                                handleOrderStatusChange(e.target.value, element)
                                            }} />
                                            <span className='ols-order-status-label'>Mark Delivered</span>
                                        </label>
                                    </div>}
                                    {element.orderStatus !== APP_CONSTANTS.ORDER_STATUS.DELIVERED && (element.orderStatus === APP_CONSTANTS.ORDER_STATUS.PREPARED || element.orderStatus === APP_CONSTANTS.ORDER_STATUS.SERVED) && <div>
                                        {element.orderStatus !== APP_CONSTANTS.ORDER_STATUS.SERVED && <label className="ols-order-status-label-container">
                                            <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.SERVED} checked={element.orderId === getSelectedOrderId() ? isOrderStatusMarkServed : false} onChange={e => {
                                                handleOrderStatusChange(e.target.value, element)
                                            }} />
                                            <span className='ols-order-status-label'>Served To Customer</span>
                                        </label>}
                                        <label className="ols-order-status-label-container">
                                            <input type='radio' value={APP_CONSTANTS.ORDER_STATUS.DELIVERED} checked={element.orderId === getSelectedOrderId() ? isOrderStatusMarkDelivered : false} onChange={e => {
                                                handleOrderStatusChange(e.target.value, element)
                                            }} />
                                            <span className='ols-order-status-label'>Mark Delivered</span>
                                        </label>
                                    </div>}

                                    <div className="btn ols-card-btn ols-button-right-align mt-2" onClick={() => updateStatus(element)}>Update</div>
                                    {/* <div className="ols-card-btn-row ols-card-btn-row-btn mt-3">
                                        {element.orderStatus !== 'DELIVERED' && element.orderStatus === 'PREPARED' ? (<div className="btn ols-card-btn" onClick={() => updateStatus(element, APP_CONSTANTS.ORDER_STATUS.DELIVERED)}>Mark Delivered</div>) : null}
                                        {element.orderStatus !== 'DELIVERED' && element.orderStatus === 'PREPARING' ? (<div className="btn ols-card-btn" onClick={() => updateStatus(element, APP_CONSTANTS.ORDER_STATUS.PREPARED)}>Ready To Serve</div>) : null}
                                    </div> */}
                                    {/* {(element.orderStatus === 'ACCEPTED') ? (<div className="btn ols-card-btn" onClick={() => updateStatus(element, APP_CONSTANTS.ORDER_STATUS.PREPARING)}>Mark In-Process</div>) : null} */}
                                </div>
                            ))}
                            {isNull(processingOrder) ? (<div>No ongoing order found</div>) : null}
                        </div>
                    </Tab>
                    <Tab eventKey="delivered" title="Delivered">
                        <div className="ols-card-container">
                            {deliveredOrder && deliveredOrder.map(element => (
                                <div className="card ols-card">
                                    <div className="ols-card-title">Order # {element.orderNumber}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Order Code</div>
                                    <div className="ols-card-title mt-0">{element.orderCode}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Order Status</div>
                                    <div className="ols-card-title mt-0">{removeSpecialCharAndChangeToPascal(element.orderStatus)}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Status</div>
                                    <div className="ols-card-title mt-0">{element.paymentStatus ? removeSpecialCharAndChangeToPascal(element.paymentStatus) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Mode</div>
                                    <div className="ols-card-title mt-0">{element.paymentMode ? removeSpecialCharAndChangeToPascal(element.paymentMode) : '-'}</div>
                                    <div className="ols-card-label-blue-bold mt-1">Payment Mode Name</div>
                                    <div className="ols-card-title mt-0">{element.paymentModeName ? element.paymentModeName : '-'}</div>
                                    <div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Name</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{element.contactPersonName ? element.contactPersonName : '-'}</div>
                                    </div>
                                    <div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Contact</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{element.contactPersonNumber ? `+${element.contactPersonNumber}` : '-'}</div>
                                    </div>
                                    {!isEmpty(element.dineInTableNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Table Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.dineInTableNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.curbsideSlotNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Slot Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.curbsideSlotNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.roomNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Room Number</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.roomNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.deliveryTerminalNumber) ? (
                                        <div className="ols-card-btn-row">
                                            <div className="ols-card-label ols-card-row-item-border">Delivery Terminal</div>
                                            <div className="ols-card-label-val ols-card-row-item-border">{element.deliveryTerminalNumber}</div>
                                        </div>
                                    ) : null}
                                    {!isEmpty(element.otherInformation) && <div className="ols-card-label-blue-bold mt-2">Special Request</div>}
                                    {!isEmpty(element.otherInformation) && <div className="ols-card-label ols-card-label-blue">{element.otherInformation}</div>}
                                    {element.processedItems && element.processedItems.length > 0 && <div className="ols-card-label-blue-bold mt-2">Delivered Items</div>}
                                    {/* <div>
                                        {element.itemsList.map(item => (
                                            <div className="ols-card-btn-row h-padding ols-card-btn-row-border">
                                                <div className="ols-card-label-grow">{item.name}</div> <div className="ols-card-label-small ms-1 me-1">x</div> <div className="ols-card-label-small">{item.quantity}</div>
                                            </div>))}
                                    </div> */}
                                    {element.processedItems && element.processedItems.length > 0 && <table class="table table-responsive table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="rl-table-col-title">Name</th>
                                                <th scope="col" className="rl-table-col-title text-center">Qty</th>
                                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {element.processedItems.map(item => (
                                                <tr className="rl-table-row-container">
                                                    <td className="rl-table-col-item ols-card-label-val">{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</td>
                                                    <td className="rl-table-col-item ols-card-label-val text-center">{item.quantity}</td>
                                                    <td className="rl-table-col-item ols-card-label-val text-center">{removeSpecialCharAndChangeToPascal(item.status)}</td>
                                                </tr>))}
                                        </tbody>
                                    </table>}
                                    {element.unavailableItemList && element.unavailableItemList.length > 0 && <div className="ols-card-label-blue-bold">Un-available Items</div>}
                                    {element.unavailableItemList && element.unavailableItemList.length > 0 && <div>
                                        {element.unavailableItemList.map(item => (
                                            <div className="ols-card-btn-row h-padding ols-card-btn-row-border">
                                                <div className="ols-card-label-grow ols-card-label-name-container">
                                                    <div>{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</div>
                                                    {!isEmpty(item.options) && <div className="mt-1">
                                                        <div className="ols-customization-label">Options</div>
                                                        <div className="crl-customization-container">
                                                            {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                        </div>
                                                    </div>}
                                                    {!isEmpty(item.addOns) && <div className="mt-1">
                                                        <div className="ols-customization-label">Add-ons</div>
                                                        <div className="crl-customization-container">
                                                            {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                        </div>
                                                    </div>}
                                                </div> <div className="ols-card-label-small ms-1 me-1">x</div> <div className="ols-card-label-small">{item.quantity}</div>
                                            </div>))}
                                    </div>}
                                    <div className="ols-card-btn-row mt-2">
                                        <div className="ols-card-label ols-card-row-item-border">Total</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{priceWithSymbol(element, element.grandTotal)}</div>
                                        {/* {
                                            displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                                (<div className="ols-card-label-val ols-card-row-item-border">{element.currencySymbol} {element.grandTotal.toFixed(2)}</div>)
                                                : (<div className="ols-card-label-val ols-card-row-item-border">{element.grandTotal.toFixed(2)} {element.currencySymbol}</div>)} */}
                                    </div>
                                    {element.refundAmount && element.refundAmount > 0 ? (<div className="ols-card-btn-row">
                                        <div className="ols-card-label ols-card-row-item-border">Refund Amount</div>
                                        <div className="ols-card-label-val ols-card-row-item-border">{priceWithSymbol(element, element.refundAmount)}</div>
                                        {/* {
                                            displayCurrencySymbolLeft(element.currencyRegion) === true ?
                                                (<div className="ols-card-label-val ols-card-row-item-border">{element.currencySymbol} {element.refundAmount.toFixed(2)}</div>)
                                                : (<div className="ols-card-label-val ols-card-row-item-border">{element.refundAmount.toFixed(2)} {element.currencySymbol}</div>)} */}
                                    </div>) : null}
                                    {!isEmpty(element.cancelReason) ? (
                                        <>
                                            <div className="ols-card-label ols-card-label-blue ols-card-label-blue-bold mt-2">Cancel Reason</div>
                                            <div className="ols-card-label ols-card-label-blue mt-1">{element.cancelReason}</div>
                                        </>
                                    ) : null}
                                    {element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && element.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PENDING && <div className="form-floating mt-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            placeholder="Payment Mode"
                                            value={payAtEndPaymentModeName}
                                            onChange={e => setPayAtEndPaymentModeName(e.target.value)}
                                            required />
                                        <label for="email">Enter Payment Mode</label>
                                    </div>}
                                    <div className="ols-card-btn-row ols-card-btn-row-btn mt-3">
                                        <div className="btn ols-card-btn">{element.orderStatus}</div>
                                        {element.paymentMode === APP_CONSTANTS.ORDER_PAYMENT_MODE.PAY_AT_END && element.paymentStatus === APP_CONSTANTS.ORDER_PAYMENT_STATUS.PENDING && <div className="btn ols-card-btn" onClick={() => { handleAcceptPayAtEndPayment(element) }}>Update Payment</div>}
                                    </div>
                                </div>
                            ))}
                            {isNull(deliveredOrder) ? (<div>No delivered order found</div>) : null}
                        </div>
                    </Tab>
                </Tabs>}
                {checkFunctionalityPermission(PLACE_ORDER) === true && <div className="fab-container" onClick={() => { handlePlaceOrderClick() }}>
                    <img src={require('../../../../assets/ic_place_order_fab.png')} className="img" />
                </div>}
                {checkFunctionalityPermission(ORDER_LIST) === false && <div>You are authorized to view orders list</div>}
                <Modal
                    show={showRejectModal}
                    onHide={() => setShowRejectModal(!showRejectModal)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reject Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="ols-refund-label">Kindly proccess order payment refund manually, if payment status is paid</div>
                            {(isSelectedPaymentModeOffline === true || isSelectedPaymentModePayAtEnd === true) && displayPaymentRecievedCheck === true ? (<div className="su-input-full mt-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input su-input-text"
                                    id="name"
                                    checked={isPaymentReceived}
                                    value={isPaymentReceived}
                                    onChange={() => {
                                        setIsPaymentReceived(!isPaymentReceived)
                                    }}
                                    required />
                                <label for="name" className="su-checkbox-label">Order Payment received?</label>
                            </div>) : null}
                            {isPaymentReceived === true && <div className="form-floating mt-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Payment Mode"
                                    value={paymentModeName}
                                    onChange={e => setPaymentModeName(e.target.value)}
                                    required />
                                <label for="email">Payment Mode</label>
                            </div>}
                            <div className="form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Cancel Reason"
                                    value={cancelReason}
                                    onChange={e => setCancelReason(e.target.value)}
                                    required />
                                <label for="email">Cancel Reason</label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => rejectOrder()}>
                            Reject Order
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAcceptModal}
                    onHide={() => setShowAcceptModal(!showAcceptModal)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Accept Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {availableItemList.length > 0 && <div className="ols-card-label-blue-bold mt-2">Order Items</div>}
                        {availableItemList.length > 0 && <table class="table table-responsive table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" className="rl-table-col-title">Name</th>
                                    <th scope="col" className="rl-table-col-title text-center">Qty</th>
                                    <th scope="col" className="rl-table-col-title text-center">Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                {availableItemList.map(item => (
                                    <tr className="rl-table-row-container">
                                        <td className="ols-card-label-val">
                                            <div className="ols-card-label-name-container-table">
                                                <div>{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</div>
                                                {!isEmpty(item.options) && <div className="mt-1">
                                                    <div className="ols-customization-label">Options</div>
                                                    <div className="crl-customization-container">
                                                        {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                    </div>
                                                </div>}
                                                {!isEmpty(item.addOns) && <div className="mt-1">
                                                    <div className="ols-customization-label">Add-ons</div>
                                                    <div className="crl-customization-container">
                                                        {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                    </div>
                                                </div>}
                                            </div>
                                        </td>
                                        <td className="ols-card-label-val">
                                            <div className="ols-table-row-center">
                                                {item.quantity}
                                            </div></td>
                                        <td className="ols-not-available-container">
                                            <div className="ols-table-row-center">
                                                <div className="ols-close-img-container-close" onClick={() => { handleMarkNotAvailabel(item) }}>
                                                    <img src={require('../../../../assets/close.png')} alt="close" className='img' />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>))}
                            </tbody>
                        </table>}
                        {unavailableItemList.length > 0 && <div className="ols-card-label-blue-bold mt-2">Unavailable Items</div>}
                        {unavailableItemList.length > 0 && <table class="table table-responsive table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" className="rl-table-col-title">Name</th>
                                    <th scope="col" className="rl-table-col-title text-center">Qty</th>
                                    <th scope="col" className="rl-table-col-title text-center">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unavailableItemList.map(item => (
                                    <tr className="rl-table-row-container">
                                        <td className="rl-table-col-item ols-card-label-val ols-card-label-name-container">
                                            <div>{item.name} {getProductMeasurmentUnitOrder(item.measurementQuantity, item.measurementUnit)}</div>
                                            {!isEmpty(item.options) && <div className="mt-1">
                                                <div className="ols-customization-label">Options</div>
                                                <div className="crl-customization-container">
                                                    {item.options.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                </div>
                                            </div>}
                                            {!isEmpty(item.addOns) && <div className="mt-1">
                                                <div className="ols-customization-label">Add-ons</div>
                                                <div className="crl-customization-container">
                                                    {item.addOns.map(e => (<div className="ols-customization-item">{e.name}</div>))}
                                                </div>
                                            </div>}</td>
                                        <td className="rl-table-col-item ols-card-label-val text-center">{item.quantity}</td>
                                        <td className="rl-table-col-item ols-card-label-val text-center">{item.finalPrice}</td>
                                    </tr>))}
                            </tbody>
                        </table>}
                        {(isSelectedPaymentModeOffline === true || isSelectedPaymentModePayAtEnd === true) && displayPaymentRecievedCheck === true ? (<div className="su-input-full mt-2">
                            <input
                                type="checkbox"
                                className="form-check-input su-input-text"
                                id="name"
                                checked={isPaymentReceived}
                                value={isPaymentReceived}
                                onChange={() => {
                                    setIsPaymentReceived(!isPaymentReceived)
                                }}
                                required />
                            <label for="name" className="su-checkbox-label">Order Payment received?</label>
                        </div>) : null}
                        {isPaymentReceived === true && <div className="form-floating mt-2">
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Payment Mode"
                                value={paymentModeName}
                                onChange={e => setPaymentModeName(e.target.value)}
                                required />
                            <label for="email">Payment Mode</label>
                        </div>}
                        <p className="ols-card-label-blue-bold mt-2">Preparation Time</p>
                        <div className="ols-input-row">
                            <div className="form-floating ols-input">
                                <input
                                    type="number"
                                    className="form-control input-text"
                                    id="name"
                                    placeholder="Hours"
                                    value={preparationTimeHr}
                                    onChange={e => setPreparationTimeHr(e.target.value)}
                                    required />
                                <label for="name">Hours</label>
                            </div>
                            <div className="form-floating ols-input">
                                <input
                                    type="number"
                                    className="form-control input-text"
                                    id="name"
                                    value={preparationTimeMin}
                                    onChange={e => setPreparationTimeMin(e.target.value)}
                                    placeholder='Minutes'
                                    required />
                                <label for="name">Minutes</label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* {unavailableItemList.length > 0 && <Button variant="outline-warning" onClick={() => acceptOrder()}>
                            Ask Customer
                        </Button>} */}
                        {!isEmpty(unavailableItemList) && !isNull(selectedOrderItem) && selectedOrderItem.itemNotAvailableOption === APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CANCEL_ORDER && <Button variant="outline-success" onClick={() => handleShowRejectModalClick(selectedOrderItem)}>
                            Cancel Order
                        </Button>}
                        {!isNull(selectedOrderItem) && (selectedOrderItem.itemNotAvailableOption === APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.CONTINUE_WTIH_AVAILABLE_ITEMS || selectedOrderItem.orderStatus === APP_CONSTANTS.ORDER_STATUS.PLACED || selectedOrderItem.orderStatus === APP_CONSTANTS.ORDER_STATUS.CUSTOMER_RESPONDED) && <Button variant="outline-success" onClick={() => acceptOrder()}>
                            Accept Order
                        </Button>}
                        {!isEmpty(unavailableItemList) && !isNull(selectedOrderItem) && selectedOrderItem.itemNotAvailableOption === APP_CONSTANTS.ORDER_ITEM_NOT_AVAILABLE_OPTION.ASK_THE_CUSTOMER && <Button variant="outline-success" onClick={() => handleAskCustomerClick()}>
                            Ask Customer
                        </Button>}
                        {/* <Button variant="outline-success" onClick={() => acceptOrder()}>
                            Accept Order
                        </Button> */}
                    </Modal.Footer>
                </Modal>
            </div >
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OrderListScreen