import { EmployeeActionTypes } from './employee.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addEmployeeSuccess: null,
    addEmployeeError: null,
    addEmployeeStatus: '',
    employeeListSuccess: null,
    employeeListError: null,
    employeeDetailSuccess: null,
    employeeDetailError: null,
    removeEmployeeSuccess: null,
    removeEmployeeError: null,
    removeEmployeeStatus: ''
}

const employeeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_SUCCESS:
            return {
                ...state,
                addEmployeeSuccess: action.payload,
                addEmployeeError: null,
                addEmployeeStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_ERROR:
            return {
                ...state,
                addEmployeeSuccess: null,
                addEmployeeError: action.payload,
                addEmployeeStatus: API_CONSTANTS.ERROR_STATUS
            }
        case EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_STATUS:
            return {
                ...state,
                addEmployeeStatus: ''
            }
        case EmployeeActionTypes.EMPLOYEE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                employeeListSuccess: action.payload,
                employeeListError: null
            }
        case EmployeeActionTypes.EMPLOYEE_LIST_REQUEST_ERROR:
            return {
                ...state,
                employeeListSuccess: null,
                employeeListError: action.payload
            }
        case EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                employeeDetailSuccess: action.payload,
                employeeDetailError: null
            }
        case EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                employeeDetailSuccess: null,
                employeeDetailError: action.payload
            }
        case EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_SUCCESS:
            return {
                ...state,
                removeEmployeeSuccess: action.payload,
                removeEmployeeError: null,
                removeEmployeeStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_ERROR:
            return {
                ...state,
                removeEmployeeSuccess: null,
                removeEmployeeError: action.payload,
                removeEmployeeStatus: API_CONSTANTS.ERROR_STATUS
            }
        case EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_STATUS:
            return {
                ...state,
                removeEmployeeStatus: ''
            }
        case EmployeeActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default employeeReducer