export const ReservationActionTypes = {
    LOGOUT: 'LOGOUT',
    
    PLACE_RESERVATION_REQUEST: 'PLACE_RESERVATION_REQUEST',
    PLACE_RESERVATION_REQUEST_SUCCESS: 'PLACE_RESERVATION_REQUEST_SUCCESS',
    PLACE_RESERVATION_REQUEST_ERROR: 'PLACE_RESERVATION_REQUEST_ERROR',
    PLACE_RESERVATION_REQUEST_STATUS: 'PLACE_RESERVATION_REQUEST_STATUS',

    RESERVATION_LIST_REQUEST: 'RESERVATION_LIST_REQUEST',
    RESERVATION_LIST_REQUEST_SUCCESS: 'RESERVATION_LIST_REQUEST_SUCCESS',
    RESERVATION_LIST_REQUEST_ERROR: 'RESERVATION_LIST_REQUEST_ERROR',

    RESERVATION_DETAIL_REQUEST: 'RESERVATION_DETAIL_REQUEST',
    RESERVATION_DETAIL_REQUEST_SUCCESS: 'RESERVATION_DETAIL_REQUEST_SUCCESS',
    RESERVATION_DETAIL_REQUEST_ERROR: 'RESERVATION_DETAIL_REQUEST_ERROR',

    UPDATE_RESERVATION_STATUS_REQUEST: 'UPDATE_RESERVATION_STATUS_REQUEST',
    UPDATE_RESERVATION_STATUS_REQUEST_SUCCESS: 'UPDATE_RESERVATION_STATUS_REQUEST_SUCCESS',
    UPDATE_RESERVATION_STATUS_REQUEST_ERROR: 'UPDATE_RESERVATION_STATUS_REQUEST_ERROR',
    UPDATE_RESERVATION_STATUS_REQUEST_STATUS: 'UPDATE_RESERVATION_STATUS_REQUEST_STATUS'
}
