import { ContactUsActionTypes } from './contactUs.types'

//Add Record
export const contactUsAddRecordRequest = data => ({
    type: ContactUsActionTypes.CONTACT_US_ADD_REQUEST,
    payload: data
})

export const contactUsAddRecordRequestSuccess = data => ({
    type: ContactUsActionTypes.CONTACT_US_ADD_REQUEST_SUCCESS,
    payload: data
})

export const contactUsAddRecordRequestError = data => ({
    type: ContactUsActionTypes.CONTACT_US_ADD_REQUEST_ERROR,
    payload: data
})

export const contactUsAddRecordRequestStatus = data => ({
    type: ContactUsActionTypes.CONTACT_US_ADD_REQUEST_STATUS,
    payload: data
})

//Record list
export const contactUsRecordListRequest = data => ({
    type: ContactUsActionTypes.CONTACT_US_LIST_REQUEST,
    payload: data
})

export const contactUsRecordListRequestSuccess = data => ({
    type: ContactUsActionTypes.CONTACT_US_LIST_REQUEST_SUCCESS,
    payload: data
})

export const contactUsRecordListRequestError = data => ({
    type: ContactUsActionTypes.CONTACT_US_LIST_REQUEST_ERROR,
    payload: data
})

//Record detail
export const contactUsRecordDetailRequest = data => ({
    type: ContactUsActionTypes.CONTACT_US_DETAIL,
    payload: data
})

export const contactUsRecordDetailRequestSuccess = data => ({
    type: ContactUsActionTypes.CONTACT_US_DETAIL_SUCCESS,
    payload: data
})

export const contactUsRecordDetailRequestError = data => ({
    type: ContactUsActionTypes.CONTACT_US_DETAIL_ERROR,
    payload: data
})

//Record Update status
export const contactUsRecordUpdateStatusRequest = data => ({
    type: ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST,
    payload: data
})

export const contactUsRecordUpdateStatusRequestSuccess = data => ({
    type: ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const contactUsRecordUpdateStatusRequestError = data => ({
    type: ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST_ERROR,
    payload: data
})

export const contactUsRecordUpdateStatusRequestStatus = data => ({
    type: ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST_STATUS,
    payload: data
})