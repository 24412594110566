import { CategoryActionTypes } from './category.types'
import { API_CONSTANTS } from '../../../utils/AppConstants'

const INITIAL_STATE = {
    addInvCategorySuccess: null,
    addInvCategoryError: null,
    addInvCategoryReqStatus: '',
    invCategoryListSuccess: null,
    invCategoryListError: null,
    updateInvCategorySuccess: null,
    updateInvCategoryError: null,
    updateInvCategoryReqStatus: ''
}

const invCategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CategoryActionTypes.INV_ADD_CATEGORY_REQUEST_SUCCESS:
            return {
                ...state,
                addInvCategorySuccess: action.payload,
                addInvCategoryError: null,
                addInvCategoryReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CategoryActionTypes.INV_ADD_CATEGORY_REQUEST_ERROR:
            return {
                ...state,
                addInvCategorySuccess: null,
                addInvCategoryError: action.payload,
                addInvCategoryReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CategoryActionTypes.INV_ADD_CATEGORY_REQUEST_STATUS:
            return {
                ...state,
                addInvCategoryReqStatus: ''
            }
        case CategoryActionTypes.INV_CATEGORY_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                invCategoryListSuccess: action.payload,
                invCategoryListError: null
            }
        case CategoryActionTypes.INV_CATEGORY_REQUEST_LIST_ERROR:
            return {
                ...state,
                invCategoryListSuccess: null,
                invCategoryListError: action.payload
            }
        case CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST_SUCCESS:
            return {
                ...state,
                updateInvCategorySuccess: action.payload,
                updateInvCategoryError: null,
                updateInvCategoryReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST_ERROR:
            return {
                ...state,
                updateInvCategorySuccess: null,
                updateInvCategoryError: action.payload,
                updateInvCategoryReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CategoryActionTypes.INV_UPDATE_CATEGORY_REQUEST_STATUS:
            return {
                ...state,
                updateInvCategoryReqStatus: ''
            }
        case CategoryActionTypes.LOGOUT:
            return INITIAL_STATE
        case CategoryActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default invCategoryReducer