import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../../components/common-input-row/commonInputRow";
import ScreenLabel from "../../../../../components/screen-label/screenLabel";
import TopButton from "../../../../../components/button/top-button/topButton";
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isNull } from "../../../../../utils/Utils";
import FormFieldLabel from "../../../../../components/form-field-label/formFieldLabel";
import { API_CONSTANTS, ROUTES } from "../../../../../utils/AppConstants";
import {
    businessListByLocationRequest, businesUserListRequest,
    mapUserWithBusinesRequest, mapUserWithBusinesRequestStatus,
    updateBusinessOrderingStatusRequest, updateBusinessOrderingStatusRequestStatus,
    updateRestaurantDetailsRequest, updateRestaurantDetailsRequestStatus
} from '../../../../../redux/user/user.action'
import Modal from 'react-bootstrap/Modal';
import CommonInputFull from "../../../../../components/common-input-full/commonInputFull";
import ButtonRow from "../../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../../components/button/common-button/commonButton";

const OperatorBusinessList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, businessListByLocationSuccess, businessListByLocationError,
        businessUserListSuccess, businessUserListError,
        mapUserWithBusinessSuccess, mapUserWithBusinessError, mapUserWithBusinessReqStatus,
        updateBusinessOrderingStatusSuccess, updateBusinessOrderingStatusError, updateBusinessOrderingStatusReqStatus,
        updateRestaurantDetailsSuccess, updateRestaurantDetailsError, updateRestaurantDetailsReqStatus } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [selectedBusiness, setSelectedBusiness] = useState(null)
    const [showDetailsModal, setShowDetailModal] = useState(false)
    const [emailQuery, setEmailQuery] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')

    const handleAddRestaurantClick = () => {
        navigate(ROUTES.operatorAddBusiness)
    }

    const fetchRestaurantList = (country = null, state = null, city = null) => {
        setLoading(true)
        const data = {}
        if (!isEmpty(country)) {
            data.country = country
        }
        if (!isEmpty(state)) {
            data.state = state
        }
        if (!isEmpty(city)) {
            data.city = city
        }
        dispatch(businessListByLocationRequest(data))
    }

    useEffect(() => {
        if (!isNull(businessListByLocationSuccess)) {
            setLoading(false)
        } else if (!isNull(businessListByLocationError)) {
            setLoading(false)
        }
    }, [businessListByLocationSuccess, businessListByLocationError])

    useEffect(() => {
        if (mapUserWithBusinessReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(mapUserWithBusinessSuccess.message)
            fetchRestaurantList(!isNull(countryDropdown) ? countryDropdown.country : null, !isNull(stateDropdown) ? stateDropdown.state : null, !isNull(cityDropdown) ? cityDropdown.value : null)
        } else if (mapUserWithBusinessReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(mapUserWithBusinessError.message)
        }
        if (mapUserWithBusinessReqStatus?.length) {
            dispatch(mapUserWithBusinesRequestStatus(''))
        }
    }, [mapUserWithBusinessSuccess, mapUserWithBusinessError, mapUserWithBusinessReqStatus])

    useEffect(() => {
        if (updateBusinessOrderingStatusReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateBusinessOrderingStatusSuccess.message)
            fetchRestaurantList(!isNull(countryDropdown) ? countryDropdown.country : null, !isNull(stateDropdown) ? stateDropdown.state : null, !isNull(cityDropdown) ? cityDropdown.value : null)
        } else if (updateBusinessOrderingStatusReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateBusinessOrderingStatusError.message)
        }
        if (updateBusinessOrderingStatusReqStatus?.length) {
            dispatch(updateBusinessOrderingStatusRequestStatus(''))
        }
    }, [updateBusinessOrderingStatusSuccess, updateBusinessOrderingStatusError, updateBusinessOrderingStatusReqStatus])

    useEffect(() => {
        if (updateRestaurantDetailsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateRestaurantDetailsSuccess.message)
            fetchRestaurantList(!isNull(countryDropdown) ? countryDropdown.country : null, !isNull(stateDropdown) ? stateDropdown.state : null, !isNull(cityDropdown) ? cityDropdown.value : null)
        } else if (updateRestaurantDetailsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateRestaurantDetailsError.message)
        }
        if (updateRestaurantDetailsReqStatus?.length) {
            dispatch(updateRestaurantDetailsRequestStatus(''))
        }
    }, [updateRestaurantDetailsSuccess, updateRestaurantDetailsError, updateRestaurantDetailsReqStatus])

    useEffect(() => {
        if (!isNull(businessUserListSuccess)) {
            setLoading(false)
        } else if (!isNull(businessUserListError)) {
            setLoading(false)
        }
    }, [businessUserListSuccess, businessUserListError])

    const handleCardClick = item => {
        navigate(ROUTES.operatorMenuList, {
            state: item.otherDetails[0].businessId
        })
    }

    const handleDetailsClick = item => {
        setSelectedBusiness(item)
        setShowDetailModal(true)
    }

    const handleSearchClick = () => {
        setLoading(true)
        const data = {
            email: emailQuery.toLowerCase()
        }
        dispatch(businesUserListRequest(data))
    }

    const handleAssignClick = userId => {
        if (isNull(selectedBusiness)) {
            displayErrorToast('Business information not found')
        } else {
            if (!isEmpty(selectedBusiness.userList) && selectedBusiness.userList.includes(userId)) {
                displayErrorToast('User already assigned on restaurant')
            } else {
                setLoading(true)
                const data = {
                    userId: userId,
                    businessId: selectedBusiness.otherDetails[0].businessId
                }
                dispatch(mapUserWithBusinesRequest(data))
                setShowDetailModal(false)
            }
        }
    }

    const handleEnableOrdering = () => {
        if (isEmpty(selectedBusiness.userList)) {
            displayErrorToast('No owner assigned on restaurant. Please assign owner first then try enabling online ordering.')
        } else if (selectedBusiness.otherDetails[0].allowOnlineOrdering === true) {
            displayErrorToast('Online ordering for restaurant is already enabled.')
        } else {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.otherDetails[0].businessId,
                allowOnlineOrdering: true
            }
            dispatch(updateBusinessOrderingStatusRequest(data))
            setShowDetailModal(false)
        }
    }

    const handleImageChange = event => {
        if (!isNull(event) && !isNull(event.target) && !isEmpty(event.target.files)) {
            setImagePath(event.target.files[0])
            setImageName(event.target.files[0].name)
        }
    }

    const handleUpdateRestaurantImage = () => {
        if (isNull(selectedBusiness)) {
            displayErrorToast('Restaurant information not available')
        } else if (isNull(imagePath)) {
            displayErrorToast('Image not selected')
        } else if (isEmpty(imageName)) {
            displayErrorToast('Image not selected')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('image', imagePath, imageName)
            fd.append('businessId', selectedBusiness.otherDetails[0].businessId)
            dispatch(updateRestaurantDetailsRequest(fd))
            setShowDetailModal(false)
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Restaurants' />
                    <TopButton label='Add Restaurant'
                        isSmall={true}
                        onClick={() => {
                            handleAddRestaurantClick()
                        }} />
                </CommonInputRow>
                {isLoggedInUserOperator(user) && <div>
                    <FormFieldLabel className='up-bold-label' label='Select Location' />
                    {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                    {!isEmpty(user.data.userDetails.accessLocations) &&
                        <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={countryDropdown}
                            getOptionLabel={e => e.country}
                            getOptionValue={e => e}
                            options={user.data.userDetails.accessLocations}
                            placeholder='Select Country'
                            onChange={e => {
                                setCountryDropdown(e)
                                if (isEmpty(e.stateCity)) {
                                    fetchRestaurantList(e.country)
                                }
                            }} />
                    }
                    {!isEmpty(user.data.userDetails.accessLocations) && !isNull(countryDropdown) &&
                        !isEmpty(countryDropdown?.stateCity) &&
                        <CommonInputRow className='mt-2'>
                            <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={stateDropdown}
                                getOptionLabel={e => e.state}
                                getOptionValue={e => e}
                                options={countryDropdown.stateCity}
                                placeholder='Select State'
                                onChange={e => {
                                    setStateDropdown(e)
                                    if (isEmpty(e.city)) {
                                        fetchRestaurantList(countryDropdown.country, e.state)
                                    }
                                }} />
                            {!isEmpty(stateDropdown?.city) && <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={cityDropdown}
                                getOptionLabel={e => e.value}
                                getOptionValue={e => e}
                                options={stateDropdown.city.map(city => {
                                    return { key: city, value: city };
                                })}
                                placeholder='Select City'
                                onChange={e => {
                                    setCityDropdown(e)
                                    fetchRestaurantList(countryDropdown.country, stateDropdown.state, e.value)
                                }} />}
                        </CommonInputRow>}
                </div>}
                {!isNull(businessListByLocationSuccess) && !isEmpty(businessListByLocationSuccess.data) &&
                    <div className="table-scroll-container">
                        <table class="table table-hover margin-top-10">
                            <thead>
                                <tr>
                                    <th scope="col" className="rl-table-col-title">Name</th>
                                    <th scope="col" className="rl-table-col-title">Online Ordering</th>
                                    <th scope="col" className="rl-table-col-title">Location</th>
                                    <th scope="col" className="rl-table-col-title text-center">Status</th>
                                    <th scope="col" className="rl-table-col-title text-center">Date</th>
                                    <th scope="col" className="rl-table-col-title text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {businessListByLocationSuccess.data.map(element => (
                                    <tr className="rl-table-row-container">
                                        <td className="rl-table-col-item align-middle">{element.otherDetails[0].businessName}</td>
                                        <td className="rl-table-col-item align-middle">{element.otherDetails[0].allowOnlineOrdering ? 'Enabled' : 'Not Available'}</td>
                                        <td className="rl-table-col-item align-middle">{element.city}, {element.state}, {element.country}</td>
                                        <td className={`rl-table-col-item text-center align-middle ${element.otherDetails[0].status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.otherDetails[0].status === 1 ? 'Active' : 'In-Active'}</td>
                                        <td className="rl-table-col-item text-center align-middle">{moment(element.otherDetails[0].createdOn).utc().format('DD MMM YYYY')}</td>
                                        <td className="rl-table-col-item text-center align-middle">
                                            <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>View Menu</div>
                                            <div className="resl-btn-container mt-2" onClick={() => { handleDetailsClick(element) }}>Details</div>
                                            {/* {!element.otherDetails[0].allowOnlineOrdering && <div className="resl-btn-container mt-2">Enable Ordering</div>} */}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>}
                {!isNull(businessListByLocationError) && <div className="content-error">{businessListByLocationError.message}</div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowDetailModal(!showDetailsModal) }}
                show={showDetailsModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Restaurant Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isNull(selectedBusiness) && <div>
                        <FormFieldLabel className='up-bold-label' label='Restaurant Name' />
                        {<div>{selectedBusiness.otherDetails[0].businessName}</div>}
                        <FormFieldLabel className='up-bold-label' label='Status' />
                        <div>{selectedBusiness.otherDetails[0].status === 1 ? 'Active' : 'In-Active'}</div>
                        <FormFieldLabel className='up-bold-label' label='Online Ordering' />
                        <div>{selectedBusiness.otherDetails[0].allowOnlineOrdering ? 'Available' : 'Not Available'}</div>
                        <FormFieldLabel className='up-bold-label' label='User Details' />
                        {isEmpty(selectedBusiness.userList) && <div>No Restaurant Owner found</div>}
                        {!isEmpty(selectedBusiness.userList) && <div className="obl-user-row-parent-container">
                            {selectedBusiness.userList.map(e => (
                                <div className="obl-user-row-container">
                                    <div className="obl-user-row-text-container">
                                        <div>{e.firstName} {e.lastName}</div>
                                        <div>{e.email}</div>
                                        <div>{e.mobile}</div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                        <FormFieldLabel className='up-bold-label' label='Assign Restaurant Owner' />
                        <div className="obl-search-parent-container">
                            <CommonInputFull
                                type='text'
                                id='emailQuery'
                                onChange={e => setEmailQuery(e.target.value)}
                                placeholder='Search by email id'
                                value={emailQuery}
                            />
                            <div className="obl-search-container" onClick={() => { handleSearchClick() }}>
                                <img src={require('../../../../../assets/ic_search_icon.png')} className='img' alt="search" />
                            </div>
                        </div>
                        {!isNull(businessUserListSuccess) && !isNull(businessUserListSuccess.data) && <div className="obl-user-row-parent-container">
                            {businessUserListSuccess.data.map(e => (
                                <div className="obl-user-row-container">
                                    <div className="obl-user-row-text-container">
                                        <div>{e.firstName} {e.lastName}</div>
                                        <div>{e.email}</div>
                                    </div>
                                    <div className="obl-user-row-btn-parent-container">
                                        <div className="obl-user-row-btn-container" onClick={() => { handleAssignClick(e.userId) }}>Assign</div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                        {!isNull(businessUserListError) && <div>{businessUserListError.message}</div>}
                        <FormFieldLabel className='up-bold-label' label='Select new restaurant image' />
                        <div className="input-group">
                            <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                        </div>
                        <ButtonRow isVertical={!isEmpty(selectedBusiness.userList)}>
                            {!isEmpty(selectedBusiness.userList) && <CommonButton
                                label='Enable Ordering'
                                onClick={() => { handleEnableOrdering() }}
                            />}
                            <CommonButton
                                label='Update Logo'
                                onClick={() => { handleUpdateRestaurantImage() }}
                            />
                        </ButtonRow>
                    </div>}
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OperatorBusinessList