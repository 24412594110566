import React from "react";
import './styles.css'

const RefreshButton = ({ onClick, label }) => {
    return (
        <div className="refresh-container" onClick={onClick}>
            <div className="refresh-img-container">
                <img className="refresh-img" alt="Refresh" src={require('../../../assets/refresh2.png')} />
            </div>
            <div className="refresh-label">{label}</div>
        </div>
    )
}

export default RefreshButton