import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { requestTicketListRequest } from '../../../redux/request-ticket/request-ticket.action'
import { convertToPascal, displayErrorToast, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../components/button/top-button/topButton'
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../components/common-input-row/commonInputRow";

const RequestTicketListScreen = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { requestTicketListSuccess, requestTicketListError } = useSelector(state => state.requestTicket)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    let isFirstTime = false
    let navigate = useNavigate();
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()

    const REQUEST_TICKET_LIST = 'REQUEST_TICKET_LIST'
    const RAISE_REQUEST_TICKET = 'RAISE_REQUEST_TICKET'

    useEffect(() => {
        const data = {
            screen: 'REQUEST_TICKET_LIST',
            screenClass: 'REQUEST_TICKET_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (!isNull(requestTicketListSuccess)) {
            setLoading(false)
        } else if (!isNull(requestTicketListError)) {
            setLoading(false)

        }
    }, [requestTicketListSuccess, requestTicketListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.REQUEST_TICKET_LIST, true)
            isFirstTime = true
            const data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchRequestTicketList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(requestTicketListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(requestTicketListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.REQUEST_TICKET_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.REQUEST_TICKET_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(selectedLocation)) {
            fetchRequestTicketList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true &&
            !isNull(employeeSelectedRestaurant) &&
            !isNull(employeeSelectedLocation)) {
            fetchRequestTicketList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const handleCardClick = item => {
        navigate(ROUTES.requestTicketDetail, { state: item.ticketId });
    }

    const handleRaiseTicketClick = () => {
        if (checkFunctionalityPermission(RAISE_REQUEST_TICKET) === true) {
            navigate(ROUTES.raiseRequestTicket)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        if (isLoggedInUserEmployee(user) === true) {
            const itemObj = user.data.userDetails.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.REQUEST_TICKET && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
            if (itemObj !== undefined && !isNull(itemObj)) {
                if (itemType === REQUEST_TICKET_LIST) {
                    if (itemObj.ticketList === true) {
                        return true
                    } else {
                        return false
                    }
                } else if (itemType === RAISE_REQUEST_TICKET) {
                    if (itemObj.raiseRequestTicket === true) {
                        return true
                    } else {
                        return false
                    }
                }
            } else return false
        } else {
            return true
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Tickets' />
                    <TopButton label='Raise Ticket'
                        onClick={() => { handleRaiseTicketClick() }} />
                </CommonInputRow>
                {isLoggedInUserBusiness(user) === true && <CommonInputRow>
                    <div className="rqtl-select-container">
                        <div className="rqtl-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='select-full-width-no-border'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    <div className="rqtl-select-container">
                        <div className="rqtl-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>
                </CommonInputRow>}
                {checkFunctionalityPermission(REQUEST_TICKET_LIST) === true && <div className="table-scroll-container">
                    {requestTicketListSuccess && requestTicketListSuccess.data && <table class="table margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Title</th>
                                <th scope="col" className="rl-table-col-title text-center">Priority</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Last Updated</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestTicketListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.title}</td>
                                    <td className="rl-table-col-item text-center">{convertToPascal(element.priority)}</td>
                                    <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.ticketStatus)}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.updatedOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center">
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>Detail</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(REQUEST_TICKET_LIST) === true && requestTicketListError && <div className="content-error">No Ticket Found</div>}
                {checkFunctionalityPermission(REQUEST_TICKET_LIST) === false && <div className="content-error">You are not authorized to view employee list</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default RequestTicketListScreen