import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { invCategoryListRequest } from '../../../../redux/inventory/category/category.action'
import { addInvProductRequest, addInvProductRequestStatus } from '../../../../redux/inventory/product/product.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";

const InvAddProduct = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { invCategoryListSuccess, invCategoryListError } = useSelector(state => state.invCategory)
    const { addInvProductSuccess, addInvProductError, addInvProductReqStatus } = useSelector(state => state.invProduct)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [productName, setProductName] = useState('')
    const [productCode, setProductCode] = useState('')
    const [brand, setBrand] = useState('')
    const [selectedProductType, setSelectedProductType] = useState()
    const [selectedProductSellingType, setSelectedProductSellingType] = useState()
    const [productSubType, setProductSubType] = useState('')
    const [purchasePrice, setPurchasePrice] = useState('')
    const [purchasePriceIncludeTax, setPurchasePriceIncludeTax] = useState(false)
    const [purchasePriceTaxPercentage, setPurchasePriceTaxPercentage] = useState('')
    const [purchaseFinalPrice, setPurchaseFinalPrice] = useState('')
    const [sellingPrice, setSellingPrice] = useState('')
    const [sellingPriceIncludeTax, setSellingPriceIncludeTax] = useState(false)
    const [sellingPriceTaxPercentage, setSellingPriceTaxPercentage] = useState('')
    const [sellingFinalPrice, setSellingFinalPrice] = useState('')
    const [productQuantity, setProductQuantity] = useState('')
    const [produtQuantityType, setProductQuantityType] = useState('')
    const [thresholdQuantity, setThresholdQuantity] = useState('')

    const PRODUCT_TYPE_LIST = [
        { key: 'CONSUMABLE', value: 'Consumable' },
        { key: 'NON_CONSUMABLE', value: 'Non-Consumable' },
    ]

    const PRODUCT_SELLING_TYPE_LIST = [
        { key: 'SELLABLE', value: 'Sellable' },
        { key: 'NON_SELLABLE', value: 'Non-Sellable' },
    ]

    let isFirstTime = false
    let navigate = useNavigate();

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_PRODUCT, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.INVENTORY.ADD_PRODUCT) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_PRODUCT, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (addInvProductReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addInvProductSuccess.message)
            clearFields()
        } else if (addInvProductReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addInvProductError.message)
        }
        if (addInvProductReqStatus?.length) {
            dispatch(addInvProductRequestStatus(''))
        }
    }, [addInvProductSuccess, addInvProductError, addInvProductReqStatus])

    const clearFields = () => {
        setProductName('')
        setProductCode('')
        setBrand('')
        setProductSubType('')
        setPurchasePrice('')
        setPurchasePriceIncludeTax(false)
        setPurchasePriceTaxPercentage('')
        setPurchaseFinalPrice('')
        setSellingPrice('')
        setSellingPriceIncludeTax(false)
        setSellingPriceTaxPercentage('')
        setSellingFinalPrice('')
        setProductQuantity('')
        setProductQuantityType('')
        setThresholdQuantity('')
    }

    useEffect(() => {
        if (!isNull(invCategoryListSuccess)) {
            setLoading(false)
        } else if (!isNull(invCategoryListError)) {
            setLoading(false)
        }
    }, [invCategoryListSuccess, invCategoryListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchCategoryList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchCategoryList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const fetchCategoryList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(invCategoryListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(invCategoryListRequest(data))
        }
    }

    const handleSubmitClick = () => {
        if (isEmpty(productName)) {
            displayErrorToast('Product name cannot be blank')
        } else if (isEmpty(selectedCategory)) {
            displayErrorToast('Category not selected')
        } else if (isEmpty(brand)) {
            displayErrorToast('Product brand cannot be blank')
        } else if (isEmpty(selectedProductType)) {
            displayErrorToast('Product type not selected')
        } else if (isEmpty(selectedProductSellingType)) {
            displayErrorToast('Product selling type not selected')
        } else if (isEmpty(productSubType)) {
            displayErrorToast('Product sub type cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else if (isEmpty(purchasePrice)) {
            displayErrorToast('Purchase price cannot be blank')
        } else if (parseFloat(purchasePrice) < 0) {
            displayErrorToast('Purchase price is invalid')
        } else if (isEmpty(purchaseFinalPrice)) {
            displayErrorToast('Purchase final price cannot be blank')
        } else if (parseFloat(purchaseFinalPrice) < 0) {
            displayErrorToast('Purchase final price is invalid')
        } else if (isEmpty(productQuantity)) {
            displayErrorToast('Product quantity cannot be blank')
        } else if (parseFloat(productQuantity) < 0) {
            displayErrorToast('Product quantity is invalid')
        } else if (isEmpty(produtQuantityType)) {
            displayErrorToast('Product quantity type cannot be blank')
        } else if (!isEmpty(thresholdQuantity) && parseFloat(thresholdQuantity) < 0) {
            displayErrorToast('Invalid Product threshold quantity')
        } else {
            setLoading(true)
            const data = {
                name: productName,
                brand: !isEmpty(brand) ? brand : '',
                productCode: !isEmpty(productCode) ? productCode : '',
                invCategoryId: selectedCategory.invCategoryId,
                businessId: isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant,
                locationId: isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation,
                productType: selectedProductType.key,
                productSellingType: selectedProductSellingType.key,
                productSubType: !isEmpty(productSubType) ? productSubType : '',
                purchasePrice: parseFloat(purchasePrice),
                purchaseTaxPercentage: purchasePriceIncludeTax ? parseFloat(purchasePriceTaxPercentage) : 0,
                purchaseFinalPrice: parseFloat(purchaseFinalPrice),
                purchaseTaxIncluded: purchasePriceIncludeTax,
                sellingPrice: !isEmpty(sellingPrice) ? parseFloat(sellingPrice) : 0,
                sellingTaxPercentage: sellingPriceIncludeTax ? parseFloat(sellingPriceTaxPercentage) : 0,
                sellingFinalPrice: !isEmpty(sellingFinalPrice) ? parseFloat(sellingFinalPrice) : 0,
                sellingTaxIncluded: sellingPriceIncludeTax,
                availabelQuantity: parseFloat(productQuantity),
                productQuantityType: produtQuantityType,
                thresholdQuantity: thresholdQuantity,
                appProductCode: user.data.userDetails.productCode
            }
            dispatch(addInvProductRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="margin-top-10">
                    <ScreenLabel label='Add Product' />
                </div>
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new product' />
                    <CommonInputFull
                        type='text'
                        id='name'
                        onChange={e => setProductName(e.target.value)}
                        placeholder='Product Name'
                        value={productName}
                    />
                    <CommonInputRow>
                        <CommonInputHalf
                            type='text'
                            id='productCode'
                            onChange={e => setProductCode(e.target.value)}
                            placeholder='Product Code'
                            value={productCode}
                            subText='Ex. Barcode/Item Code'
                            isSubtext={true}
                        />
                        <CommonInputHalf
                            type='text'
                            id='brand'
                            onChange={e => setBrand(e.target.value)}
                            placeholder='Brand'
                            value={brand}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                            }}
                            menuPlacement="auto"
                            className='select-half-width'
                            value={selectedProductType}
                            getOptionLabel={e => e.value}
                            getOptionValue={e => e}
                            options={PRODUCT_TYPE_LIST}
                            placeholder='Select Product Type'
                            onChange={e => setSelectedProductType(e)} />
                        {!isEmpty(selectedProductType) && <CommonInputHalf
                            type='text'
                            id='productSubType'
                            onChange={e => setProductSubType(e.target.value)}
                            placeholder='Product Sub Type'
                            value={productSubType}
                            isSubtext={true}
                            subText='Ex. Assets/Crockery/Raw Vegetables'

                        />}
                    </CommonInputRow>
                    <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border margin-top-5'
                        value={selectedProductSellingType}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={PRODUCT_SELLING_TYPE_LIST}
                        placeholder='Select Selling Type'
                        onChange={e => setSelectedProductSellingType(e)} />
                    {isLoggedInUserBusiness(user) === true && invCategoryListSuccess && invCategoryListSuccess.data && <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border margin-top-10'
                        value={selectedCategory}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={invCategoryListSuccess.data}
                        placeholder='Select Category'
                        onChange={e => setSelectedCategory(e)} />}
                    {isLoggedInUserBusiness(user) === true && !isEmpty(invCategoryListError) && !isEmpty(invCategoryListError.data) && <div className="content-error">{invCategoryListError.message}</div>}
                    {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Restaurant" />}
                    {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedRestaurant}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={restuarantListSuccess.data}
                        placeholder='Select Restaurant'
                        onChange={e => setSelectedRestaurant(e)} />}
                    {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="content-error">No restaurant found</div>}
                    {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Location" />}
                    {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                        }}
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="content-error">No location found</div>}
                    <FormFieldLabel className='up-bold-label' label="Quantity Information" />
                    <CommonInputRow>
                        <CommonInputHalf
                            type='number'
                            id='quantity'
                            onChange={e => setProductQuantity(e.target.value)}
                            placeholder='Quantity'
                            value={productQuantity}
                        />
                        <CommonInputHalf
                            type='text'
                            id='quantityType'
                            onChange={e => setProductQuantityType(e.target.value)}
                            placeholder='Quantity Type'
                            value={produtQuantityType}
                            subText='Product measurement unit'
                            isSubtext={true}
                        />
                    </CommonInputRow>
                    <CommonInputFull
                        type='number'
                        id='thresholdQuantity'
                        onChange={e => setThresholdQuantity(e.target.value)}
                        placeholder='Threshold Quantity'
                        value={thresholdQuantity}
                        subText='Quantity below which to show alert'
                        isSubtext={true}
                    />
                    <FormFieldLabel className='up-bold-label' label="Pricing Information" />
                    <FormFieldLabel label="Purchase Price" />
                    <CommonInputFull
                        type='number'
                        id='purchasePrice'
                        onChange={e => setPurchasePrice(e.target.value)}
                        placeholder='Price'
                        value={purchasePrice}
                    />
                    <CommonInputRow>
                        <div className="iap-row-input">
                            <input
                                type="checkbox"
                                className="form-check-input iap-input-text"
                                id="name"
                                checked={purchasePriceIncludeTax}
                                value={purchasePriceIncludeTax}
                                onChange={() => {
                                    setPurchasePriceIncludeTax(!purchasePriceIncludeTax)
                                }}
                                required />
                            <label for="name" className="iap-checkbox-label">Is Taxable?</label>
                        </div>
                        {purchasePriceIncludeTax === true && <CommonInputHalf
                            type='number'
                            id='purchasePriceTax'
                            onChange={e => setPurchasePriceTaxPercentage(e.target.value)}
                            placeholder='Tax Percentage'
                            value={purchasePriceTaxPercentage}
                        />}
                    </CommonInputRow>
                    <CommonInputFull
                        type='number'
                        id='purchaseFinalPrice'
                        onChange={e => setPurchaseFinalPrice(e.target.value)}
                        placeholder='Final Price'
                        value={purchaseFinalPrice}
                    />
                    {!isEmpty(selectedProductSellingType) && selectedProductSellingType.key === 'SELLABLE' && <div>
                        <FormFieldLabel label="Selling Price" />
                        <CommonInputFull
                            type='number'
                            id='sellingPrice'
                            onChange={e => setSellingPrice(e.target.value)}
                            placeholder='Price'
                            value={sellingPrice}
                        />
                        <CommonInputRow>
                            <div className="p-row-input">
                                <input
                                    type="checkbox"
                                    className="form-check-input p-input-text"
                                    id="name"
                                    checked={sellingPriceIncludeTax}
                                    value={sellingPriceIncludeTax}
                                    onChange={() => {
                                        setSellingPriceIncludeTax(!sellingPriceIncludeTax)
                                    }}
                                    required />
                                <label for="name" className="p-checkbox-label">Is Taxable?</label>
                            </div>
                            {sellingPriceIncludeTax === true && <CommonInputHalf
                                type='number'
                                id='sellingPriceTax'
                                onChange={e => setSellingPriceTaxPercentage(e.target.value)}
                                placeholder='Tax Percentage'
                                value={sellingPriceTaxPercentage}
                            />}
                        </CommonInputRow>
                        <CommonInputFull
                            type='number'
                            id='sellingFinalPrice'
                            onChange={e => setSellingFinalPrice(e.target.value)}
                            placeholder='Final Price'
                            value={sellingFinalPrice}
                        />
                    </div>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            isBig={true}
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonFormBorder>

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvAddProduct