export const CartActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_ITEM_IN_CART: 'ADD_ITEM_IN_CART',
    REDUCE_QUANTITY: 'REDUCE_QUANTITY',

    CLEAR_CART: 'CLEAR_CART',
    CUSTOMER_LOGOUT: 'CUSTOMER_LOGOUT',

    DISPLAY_ADD_CART_BANNER: 'DISPLAY_ADD_CART_BANNER',
    HIDE_ADD_CART_BANNER: 'HIDE_ADD_CART_BANNER'
}