import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, screenCaptureAnalytics } from "../../../../../utils/Utils";
import { categoryListRequest } from '../../../../../redux/category/category.action'
import { productDetailRequest, productUpdateRequest, productUpdateRequestStatus } from '../../../../../redux/product/product.action'
import { restaurantListRequest, branchListRequest } from '../../../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../../utils/AppConstants";
import Select from 'react-select'
import { useLocation } from 'react-router-dom';
import { CurrencyLabel } from '../../../../../utils/CurrencyLabel'
import notificationTone from "../../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../../redux/socket/socket.action'
import CommonButton from "../../../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../../../components/screen-label/screenLabel";
import SubTextLabel from "../../../../../components/sub-text-label/subTextLabel";
import CommonForm from "../../../../../components/common-form/commonForm";
import CommonFormBorder from "../../../../../components/common-form-border/commonFormBorder";
import FormFieldLabel from "../../../../../components/form-field-label/formFieldLabel";
import CommonInputFull from "../../../../../components/common-input-full/commonInputFull";
import CommonInputHalf from "../../../../../components/common-input-half/commonInputHalf";
import CommonInputRow from "../../../../../components/common-input-row/commonInputRow";
import ButtonRow from "../../../../../components/button/button-row/buttonRow";
import Modal from 'react-bootstrap/Modal';
import { PRODUCT_MEASUREMENT_UNIT_LIST } from "../../../../../utils/ReservationType";
import { useNavigate } from 'react-router-dom';

const EditMenuItemOperator = props => {
    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [discount, setDiscount] = useState('')
    const [tax, setTax] = useState('')
    const [isTaxable, setIsTaxable] = useState(false)
    const [selectedCat, setSelectedCat] = useState()
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, branchListSuccess, branchListError } = useSelector(state => state.user)
    const { categoryList, categoryListError } = useSelector(state => state.category)
    const { updateProductSuccess, updateProductError, updateProductStatus, productDetailSuccess, productDetailError } = useSelector(state => state.product)
    const { state } = useLocation()
    const [selectedLocation, setSelectedLocation] = useState()
    const [isBreakfast, setIsBreakfast] = useState(false)
    const [isLunch, setIsLunch] = useState(false)
    const [isDinner, setIsDinner] = useState(false)
    const [is24Hour, setIs24Hour] = useState(false)
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)
    const [itemVeg, setItemVeg] = useState(false)
    const [itemNonVeg, setItemNonVeg] = useState(false)
    const [itemVegan, setItemVegan] = useState(false)

    const [optionModalShow, setOptionModalShow] = useState(false)
    const [optionLabel, setOptionLabel] = useState('')
    const [optionList, setOptionList] = useState([])
    const [optionTypeModalShow, setOptionTypeModalShow] = useState(false)
    const [optionTypeName, setOptionTypeName] = useState('')
    const [optionTypeDescription, setOptionTypeDescription] = useState('')
    const [optionTypePrice, setOptionTypePrice] = useState(0)
    const [optionTypeDefault, setOptionTypeDefault] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')

    const [optionTypeVeg, setOptionTypeVeg] = useState(false)
    const [optionTypeNonVeg, setOptionTypeNonVeg] = useState(false)
    const [optionTypeVegan, setOptionTypeVegan] = useState(false)

    const [addOnModalShow, setAddOnModalShow] = useState(false)
    const [addOnLabel, setAddOnLabel] = useState('')
    const [addOnList, setAddOnList] = useState([])
    const [addOnTypeModalShow, setAddOnTypeModalShow] = useState(false)
    const [addOnTypeName, setAddOnTypeName] = useState('')
    const [addOnTypeDescription, setAddOnTypeDescription] = useState('')
    const [addOnTypePrice, setAddOnTypePrice] = useState(0)
    const [addOnTypeDefault, setAddOnTypeDefault] = useState(false)
    const [selectedAddOn, setSelectedAddOn] = useState('')

    const [addOnTypeVeg, setAddOnTypeVeg] = useState(false)
    const [addOnTypeNonVeg, setAddOnTypeNonVeg] = useState(false)
    const [addOnTypeVegan, setAddOnTypeVegan] = useState(false)
    const [counter, setCounter] = useState(0)
    const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState()
    const [measurementUnitQty, setMeasurementUnitQty] = useState()

    const [isOptionBasedPrice, setIsOptionBasedPrice] = useState(false)
    const [optionBasedPriceList, setOptionBasedPriceList] = useState([])
    const [optionBasedName, setOptionBasedName] = useState('')
    const [optionBasedPrice, setOptionBasedPrice] = useState('')
    const [productPoints, setProductPoints] = useState('')

    let navigate = useNavigate()

    const clearProductDetail = () => {
        dispatch({ type: APP_CONSTANTS.REDUCER_ACTIONS.CLEAR_PRODUCT_DETAIL })
    }

    useEffect(() => {
        setLoading(true)
        const data = {
            itemId: state
        }
        dispatch(productDetailRequest(data))

        const handlePopstate = () => {
            clearProductDetail()
        }

        window.addEventListener('popstate', handlePopstate);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [])

    useEffect(() => {
        if (!isEmpty(selectedLocation) && !isNull(productDetailSuccess) && !isNull(productDetailSuccess.data)) {
            console.log('selectedLocation', selectedLocation)
            setLoading(true)
            const data = {
                businessId: productDetailSuccess.data.businessId,
                locationId: selectedLocation[0].locationId
            }
            dispatch(categoryListRequest(data))
        }
    }, [selectedLocation])

    useEffect(() => {
        if (!isNull(productDetailSuccess)) {
            setLoading(false)
            setCode(productDetailSuccess.data.itemCode)
            setName(productDetailSuccess.data.name)
            setDescription(productDetailSuccess.data.description)
            setPrice(productDetailSuccess.data.price ? productDetailSuccess.data.price : '')
            setDiscount(productDetailSuccess.data.discount ? productDetailSuccess.data.discount : '')
            setTax(productDetailSuccess.data.tax ? productDetailSuccess.data.tax : '')
            setIsTaxable(productDetailSuccess.data.isTaxable)
            setIs24Hour(productDetailSuccess.data.is24Hour)
            setIsBreakfast(productDetailSuccess.data.isBreakfast)
            setIsDinner(productDetailSuccess.data.isDinner)
            setIsLunch(productDetailSuccess.data.isLunch)
            setIsOptionBasedPrice(productDetailSuccess.data.isOptionBasedPricing ? productDetailSuccess.data.isOptionBasedPricing : false)
            setOptionBasedPriceList(productDetailSuccess.data.optionBasedPricing ? productDetailSuccess.data.optionBasedPricing : [])
            setProductPoints(productDetailSuccess.data.points ? productDetailSuccess.data.points : '')
            if (productDetailSuccess.data.measurementUnit) {
                let mUnitObj = PRODUCT_MEASUREMENT_UNIT_LIST.find(e => e.key === productDetailSuccess.data.measurementUnit)
                if (!isNull(mUnitObj))
                    setSelectedMeasurementUnit(mUnitObj)
            }
            if (productDetailSuccess.data.measurementQuantity) {
                setMeasurementUnitQty(productDetailSuccess.data.measurementQuantity)
            }
            if (productDetailSuccess.data.options) {
                setOptionList(productDetailSuccess.data.options)
            }
            if (productDetailSuccess.data.addOns) {
                setAddOnList(productDetailSuccess.data.addOns)
            }
            if (productDetailSuccess.data.isVeg)
                setItemVeg(productDetailSuccess.data.isVeg)
            if (productDetailSuccess.data.isNonVeg)
                setItemNonVeg(productDetailSuccess.data.isNonVeg)
            if (productDetailSuccess.data.isVegan)
                setItemVegan(productDetailSuccess.data.isVegan)
            const data1 = {
                businessId: Number(productDetailSuccess.data.businessId)
            }
            dispatch(branchListRequest(data1))
        } else if (!isNull(productDetailError)) {
            setLoading(false)
            displayErrorToast(productDetailError.message)
        }
    }, [productDetailSuccess, productDetailError])

    useEffect(() => {
        if (!isNull(branchListSuccess)) {
            if (!isNull(productDetailSuccess)) {
                setLoading(false)
                let selLocations = []
                console.log('productDetailSuccess.data.locationId', productDetailSuccess.data.locationId)
                productDetailSuccess.data.locationId.forEach(element => {
                    const selLoc = branchListSuccess.data.find(rest => Number(rest.locationId) === Number(element))
                    selLocations.push(selLoc)
                });
                console.log('selLocations', selLocations)
                setSelectedLocation(selLocations)
            }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(categoryList)) {
            setLoading(false)
            if (!isNull(productDetailSuccess)) {
                const cat = categoryList.data.find(cat => cat.categoryId === productDetailSuccess.data.categoryId)
                setSelectedCat(cat)
            }
        } else if (!isNull(categoryListError)) {
            setLoading(false)
        }
    }, [categoryList, categoryListError])

    useEffect(() => {
        if (updateProductStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateProductSuccess.message)
            setTimeout(() => {
                navigate(-1)
            }, 300)
        } else if (updateProductStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateProductError.message)
        }

        if (updateProductStatus.length) {
            dispatch(productUpdateRequestStatus(''))
        }
    }, [updateProductSuccess, updateProductError, updateProductStatus])

    const nameHandle = e => {
        const name = e.target.value
        setName(name)
        let nameUp = name.toUpperCase()
        nameUp = nameUp.replace(/[^a-zA-Z0-9]/g, "")
        setCode(nameUp)
    }

    const codeHandle = e => {
        const name = e.target.value
        let nameUp = name.toUpperCase()
        nameUp = nameUp.replace(/[^a-zA-Z0-9]/g, "")
        setCode(nameUp)
    }

    const descriptionHandle = e => {
        setDescription(e.target.value)
    }

    const priceHandle = e => {
        setPrice(parseFloat(e.target.value))
    }

    const discountHandle = e => {
        setDiscount(parseFloat(e.target.value))
    }

    const taxHandle = e => {
        setTax(parseFloat(e.target.value))
    }

    const handleImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setImagePath(event.target.files[0])
            setImageName(event.target.files[0].name)
        } else {
            setImagePath(null)
            setImageName('')
        }
    }

    const handleSelectedCatChange = event => {
        setSelectedCat(event)
    }

    const submitHandler = () => {
        const numberRegex = new RegExp('-?[0-9]+(\.[0-9]+)?')
        if (isEmpty(name)) {
            displayErrorToast('Product name cannot be blank')
        } else if (!isEmpty(productPoints) && Number(productPoints) < 0) {
            displayErrorToast("Invalid product points")
        } else if (isOptionBasedPrice === false && isNull(price)) {
            displayErrorToast('Product price cannot be blank')
        } else if (isOptionBasedPrice === false && !numberRegex.test(price)) {
            displayErrorToast('Invalid product price')
        } else if (isOptionBasedPrice === false && isNull(discount)) {
            displayErrorToast('Invalid product discount %')
        } else if (isOptionBasedPrice === false && !numberRegex.test(discount)) {
            displayErrorToast('Invalid product discount %')
        } else if (isOptionBasedPrice === false && isTaxable === true && isNull(tax)) {
            displayErrorToast('Invalid product tax %')
        } else if (isOptionBasedPrice === false && isTaxable === true && !numberRegex.test(tax)) {
            displayErrorToast('Invalid product tax %')
        } else if (isOptionBasedPrice === true && isEmpty(optionBasedPriceList)) {
            displayErrorToast('Product option price(s) cannot be blank')
        } else if (isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location is not selected')
        } else if (isNull(selectedCat)) {
            displayErrorToast('Product category is not selected')
        } else if (isBreakfast === false && isLunch === false && isDinner === false && is24Hour === false) {
            displayErrorToast('Please select availability')
        } else if (isNull(selectedMeasurementUnit)) {
            displayErrorToast('Item serving type not selected')
        } else if (isNull(measurementUnitQty)) {
            displayErrorToast('Item serving type quantity cannot be blank')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('itemId', state)
            fd.append('itemCode', code)
            fd.append('name', name)
            if (!isEmpty(description))
                fd.append('description', description)
            fd.append('measurementUnit', selectedMeasurementUnit.key)
            fd.append('measurementQuantity', measurementUnitQty)
            fd.append('categoryId', selectedCat.categoryId)
            // fd.append('discount', discount)
            // fd.append('price', price)
            // fd.append('isTaxable', isTaxable === true ? 1 : 0)
            if (isOptionBasedPrice === false) {
                fd.append('isOptionBasedPricing', 'NO')
                fd.append('discount', !isEmpty(discount) ? parseFloat(discount) : 0)
                fd.append('price', !isEmpty(price) ? parseFloat(price) : 0)
                fd.append('isTaxable', isTaxable === true ? 1 : 0)
                if (isTaxable === true)
                    fd.append('tax', !isEmpty(tax) ? parseFloat(tax) : 0)
                else fd.append('tax', 0)
            }
            if (isOptionBasedPrice === true) {
                fd.append('isOptionBasedPricing', 'YES')
                fd.append('optionBasedPricing', JSON.stringify(optionBasedPriceList))
            }
            if (!isEmpty(productPoints)) {
                fd.append('points', parseFloat(productPoints))
            } else {
                fd.append('points', 0)
            }
            fd.append('isVeg', itemVeg === true ? 1 : 0)
            fd.append('isNonVeg', itemNonVeg === true ? 1 : 0)
            fd.append('isVegan', itemVegan === true ? 1 : 0)
            if (is24Hour === true) {
                fd.append('isBreakfast', 1)
                fd.append('isDinner', 1)
                fd.append('isLunch', 1)
                fd.append('is24Hour', 1)
            } else {
                fd.append('is24Hour', 0)
                fd.append('isBreakfast', isBreakfast === true ? 1 : 0)
                fd.append('isDinner', isDinner === true ? 1 : 0)
                fd.append('isLunch', isLunch === true ? 1 : 0)
            }
            // fd.append('isBreakfast', isBreakfast === true ? 1 : 0)
            // fd.append('isDinner', isDinner === true ? 1 : 0)
            // fd.append('isLunch', isLunch === true ? 1 : 0)
            // fd.append('is24Hour', is24Hour === true ? 1 : 0)
            // if (isTaxable === true)
            //     fd.append('tax', tax)
            // else fd.append('tax', 0)
            fd.append('businessId', Number(productDetailSuccess.data.businessId))
            if (!isEmpty(selectedLocation)) {
                selectedLocation.forEach(e => {
                    fd.append('locationId[]', e.locationId)
                })
            }
            if (!isEmpty(optionList))
                fd.append('options', JSON.stringify(optionList))
            if (!isEmpty(addOnList))
                fd.append('addOns', JSON.stringify(addOnList))
            // fd.append('locationId', selectedLocation.locationId)
            fd.append('productCode', user.data.userDetails.productCode)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            // fd.append('tags', [])
            dispatch(productUpdateRequest(fd))
        }
    }

    const handleItemTypeChoiceChange = type => {
        if (type === APP_CONSTANTS.ITEM_TYPE.VEG) {
            setItemVeg(true)
            setItemNonVeg(false)
            setItemVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.NON_VEG) {
            setItemVeg(false)
            setItemNonVeg(true)
            setItemVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.VEGAN) {
            setItemVeg(false)
            setItemNonVeg(false)
            setItemVegan(true)
        }
    }

    const handleOptionTypeChoiceChange = type => {
        if (type === APP_CONSTANTS.ITEM_TYPE.VEG) {
            setOptionTypeVeg(true)
            setOptionTypeNonVeg(false)
            setOptionTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.NON_VEG) {
            setOptionTypeVeg(false)
            setOptionTypeNonVeg(true)
            setOptionTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.VEGAN) {
            setOptionTypeVeg(false)
            setOptionTypeNonVeg(false)
            setOptionTypeVegan(true)
        }
    }

    const handleAddOptionClick = () => {
        if (isEmpty(optionLabel)) {
            displayErrorToast('Option label cannot be blank')
        } else {
            setOptionModalShow(false)
            const optionObj = optionList.find(e => e.title === optionLabel)
            if (!isNull(optionObj)) {
                displayErrorToast('Option already added. Try with different name')
            } else {
                let currentList = optionList
                const data = {
                    title: optionLabel,
                    items: []
                }
                currentList.push(data)
                setOptionList(currentList)
                console.log('currentList', currentList)
                setOptionLabel('')
            }
        }
    }

    const handleAddOptionDetailClick = () => {
        if (isEmpty(optionTypeName)) {
            displayErrorToast('Option name cannot be blank')
        } if (isEmpty(optionTypePrice) || optionTypePrice < 0) {
            displayErrorToast('Invalid option price')
        } else {
            setOptionTypeModalShow(false)
            const optionObj = optionList.find(e => e.title === selectedOption)
            if (isNull(optionObj)) {
                displayErrorToast('Some error occurred. Please try again.')
            } else {
                const optionItemObj = optionObj.items.find(e => e.name === optionTypeName)
                if (!isNull(optionItemObj)) {
                    displayErrorToast('Option with name already added. Please try with different name.')
                } else {
                    let newList = []
                    optionList.forEach(e => {
                        if (e.title === selectedOption) {
                            let currentItemList = e.items
                            currentItemList.push({
                                name: optionTypeName,
                                description: optionTypeDescription ? optionTypeDescription : '',
                                price: optionTypePrice,
                                isDefault: optionTypeDefault,
                                isVeg: optionTypeVeg === true ? true : false,
                                isNonVeg: optionTypeNonVeg === true ? true : false,
                                isVegan: optionTypeVegan === true ? true : false
                            })
                            const data = {
                                title: e.title,
                                items: currentItemList
                            }
                            newList.push(data)
                        } else {
                            newList.push(e)
                        }
                    })
                    setOptionList(newList)
                    setOptionTypeName('')
                    setOptionTypeDescription('')
                    setOptionTypePrice(0)
                    setOptionTypeDefault(false)

                }
            }
        }
    }

    const handleAddAddOnClick = () => {
        if (isEmpty(addOnLabel)) {
            displayErrorToast('Add-on label cannot be blank')
        } else {
            setAddOnModalShow(false)
            const addOnObj = addOnList.find(e => e.title === addOnLabel)
            if (!isNull(addOnObj)) {
                displayErrorToast('Add-on already added. Try with different name')
            } else {
                let currentList = addOnList
                const data = {
                    title: addOnLabel,
                    items: []
                }
                currentList.push(data)
                setAddOnList(currentList)
                setAddOnLabel('')
            }
        }
    }

    const handleAddAddOnDetailClick = () => {
        if (isEmpty(addOnTypeName)) {
            displayErrorToast('Add-on name cannot be blank')
        } if (isEmpty(addOnTypePrice) || addOnTypePrice < 0) {
            displayErrorToast('Invalid add-on price')
        } else {
            setAddOnTypeModalShow(false)
            const addOnObj = addOnList.find(e => e.title === selectedAddOn)
            if (isNull(addOnObj)) {
                displayErrorToast('Some error occurred. Please try again.')
            } else {
                const addOnItemObj = addOnObj.items.find(e => e.name === addOnTypeName)
                if (!isNull(addOnItemObj)) {
                    displayErrorToast('Add-on with name already added. Please try with different name.')
                } else {
                    let newList = []
                    addOnList.forEach(e => {
                        if (e.title === selectedAddOn) {
                            let currentItemList = e.items
                            currentItemList.push({
                                name: addOnTypeName,
                                description: addOnTypeDescription ? addOnTypeDescription : '',
                                price: addOnTypePrice,
                                isVeg: addOnTypeVeg === true ? true : false,
                                isNonVeg: addOnTypeNonVeg === true ? true : false,
                                isVegan: addOnTypeVegan === true ? true : false
                            })
                            const data = {
                                title: e.title,
                                items: currentItemList
                            }
                            newList.push(data)
                        } else {
                            newList.push(e)
                        }
                    })
                    setAddOnList(newList)
                    setAddOnTypeName('')
                    setAddOnTypeDescription('')
                    setAddOnTypePrice(0)

                }
            }
        }
    }

    const handleAddOnTypeChoiceChange = type => {
        if (type === APP_CONSTANTS.ITEM_TYPE.VEG) {
            setAddOnTypeVeg(true)
            setAddOnTypeNonVeg(false)
            setAddOnTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.NON_VEG) {
            setAddOnTypeVeg(false)
            setAddOnTypeNonVeg(true)
            setAddOnTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.VEGAN) {
            setAddOnTypeVeg(false)
            setAddOnTypeNonVeg(false)
            setAddOnTypeVegan(true)
        }
    }

    const removeCompleteOption = (title, type) => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            const filteredList = addOnList.filter(e => e.title !== title)
            setAddOnList(filteredList)
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            const filteredList = optionList.filter(e => e.title !== title)
            setOptionList(filteredList)
        }
    }

    const removeSelectedItem = (name, title, type) => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            const addOnObj = addOnList.find(e => e.title === title)
            if (!isNull(addOnObj)) {
                const filteredItemList = addOnObj.items.filter(e1 => e1.name !== name)
                let tempList = addOnList
                const objIndex = tempList.findIndex(item => item.title === title)
                if (objIndex > -1) {
                    tempList[objIndex] = {
                        ...tempList[objIndex],
                        items: filteredItemList
                    }
                    setAddOnList(tempList)
                    let count = counter
                    setCounter(count + 1)
                }
            }
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            const optionObj = optionList.find(e => e.title === title)
            if (!isNull(optionObj)) {
                const filteredItemList = optionObj.items.filter(e1 => e1.name !== name)
                let tempList = optionList
                const objIndex = tempList.findIndex(item => item.title === title)
                if (objIndex > -1) {
                    tempList[objIndex] = {
                        ...tempList[objIndex],
                        items: filteredItemList
                    }
                    setOptionList(tempList)
                    let count = counter
                    setCounter(count + 1)
                }
            }
        }
    }

    const handleOptionBasedPriceAddClick = () => {
        if (isEmpty(optionBasedName)) {
            displayErrorToast('Option name cannot be blank')
        } else if (isEmpty(optionBasedPrice)) {
            displayErrorToast('Option price cannot be blank')
        } else if (Number(optionBasedPrice) < 0) {
            displayErrorToast('Option price cannot be less than 0')
        } else {
            const data = {
                label: optionBasedName,
                price: Number(optionBasedPrice)
            }
            let currentList = optionBasedPriceList
            currentList.push(data)
            setOptionBasedPriceList(currentList)
            setOptionBasedName('')
            setOptionBasedPrice('')
        }
    }

    const handleOptionBasedPriceRemoveClick = (item) => {
        setOptionBasedPriceList(optionBasedPriceList.filter((e) => e.label !== item.label && parseFloat(e.price) !== parseFloat(item.price)));
        setOptionBasedName('')
        setOptionBasedPrice('')
    }

    return (
        <>
            <div className="add-ep-container">
                <div className="resl-label-container ap-padding">
                    <ScreenLabel label='Update Details' />
                </div>
                <CommonFormBorder>
                    <FormFieldLabel className='label-bold' label='Product Details' />
                    <CommonInputFull
                        id='name'
                        onChange={nameHandle}
                        value={name}
                        placeholder='Product Name'
                        type='text' />
                    <CommonInputRow>
                        <CommonInputHalf
                            type="text"
                            id="description"
                            placeholder="Description"
                            value={description}
                            onChange={descriptionHandle} />
                        <CommonInputHalf
                            type="text"
                            id="name"
                            placeholder="Product Code"
                            value={code}
                            onChange={codeHandle} />
                    </CommonInputRow>
                    <CommonInputFull
                        type='number'
                        id='points'
                        onChange={e => setProductPoints(e.target.value)}
                        placeholder='Points'
                        value={productPoints}
                    />
                    <CommonInputRow>
                        <div className="select-half-width">
                            <FormFieldLabel className='align-left mt-0' label='Serving Type' />
                            {PRODUCT_MEASUREMENT_UNIT_LIST && <Select
                                className='select-full-width-no-border'
                                value={selectedMeasurementUnit}
                                getOptionLabel={e => e.value}
                                getOptionValue={e => e}
                                options={PRODUCT_MEASUREMENT_UNIT_LIST}
                                placeholder='Serving Type'
                                onChange={e => setSelectedMeasurementUnit(e)} />}
                        </div>
                        <CommonInputHalf type='number'
                            id='measurementUnitQty'
                            onChange={e => setMeasurementUnitQty(e.target.value)}
                            placeholder='Serving Quantity'
                            value={measurementUnitQty} />
                    </CommonInputRow>
                </CommonFormBorder>
                <CommonFormBorder>
                    <FormFieldLabel className='label-bold' label='Pricing Information' />
                    <div className="p-row-input mb-2">
                        <input
                            type="checkbox"
                            className="form-check-input p-input-text"
                            id="name"
                            checked={isOptionBasedPrice}
                            value={isOptionBasedPrice}
                            onChange={() => {
                                setIsOptionBasedPrice(!isOptionBasedPrice)
                            }}
                            required />
                        <label for="name" className="p-checkbox-label">Is Option Based Pricing?</label>
                    </div>
                    {isOptionBasedPrice && <div className="option-price-parent-container">
                        <CommonInputRow className='option-price-field-container'>
                            <CommonInputHalf
                                type="text"
                                id="optionName"
                                placeholder="Option Name"
                                value={optionBasedName}
                                onChange={e => setOptionBasedName(e.target.value)}
                            />
                            <CommonInputHalf
                                type="number"
                                id="optionNamePrice"
                                placeholder="Price"
                                value={optionBasedPrice}
                                onChange={e => setOptionBasedPrice(parseFloat(e.target.value))}
                            />
                        </CommonInputRow>
                        <div className="ap-add-icon-container ms-2" onClick={() => { handleOptionBasedPriceAddClick() }}>
                            <img src={require('../../../../../assets/plus.png')} className='img' alt="plus" />
                        </div>
                    </div>}
                    {isOptionBasedPrice && !isEmpty(optionBasedPriceList) &&
                        <div className="option-price-list-container">
                            {optionBasedPriceList.map(e => (
                                <div className="option-price-list-row-item">
                                    <div className="option-price-list-row-item-text">{e.label} - {e.price}</div>
                                    <div className="emi-delete-img" onClick={() => handleOptionBasedPriceRemoveClick(e)}><img src={require('../../../../../assets/ic_delete_blue.png')} className="img" alt="delete" /></div>
                                </div>
                            ))}
                        </div>
                    }
                    {!isOptionBasedPrice && <div>
                        <div className="ep-input-row">
                            <div className="form-floating ep-row-input">
                                <input
                                    type="number"
                                    className="form-control ep-input-text"
                                    id="name"
                                    placeholder="Price"
                                    value={price}
                                    onChange={priceHandle}
                                    required />
                                <label for="name">Price</label>
                            </div>
                            <div className="form-floating ep-row-input">
                                <input
                                    type="number"
                                    className="form-control ep-input-text"
                                    id="name"
                                    placeholder="Discount"
                                    value={discount}
                                    onChange={discountHandle} />
                                <label for="name">Discount %</label>
                            </div>
                        </div>
                        <div className="ep-input-row">
                            <div className="ep-row-input">
                                <input
                                    type="checkbox"
                                    className="form-check-input ep-input-text"
                                    id="name"
                                    checked={isTaxable}
                                    value={isTaxable}
                                    onChange={() => {
                                        setIsTaxable(!isTaxable)
                                    }}
                                    required />
                                <label for="name" className="ep-checkbox-label">Is Taxable?</label>
                            </div>
                            {isTaxable === true ? (<div className="form-floating ep-row-input">
                                <input
                                    type="number"
                                    className="form-control ep-input-text"
                                    id="name"
                                    placeholder="Tax %"
                                    value={tax}
                                    onChange={taxHandle} />
                                <label for="name">Tax %</label>
                            </div>) : null}
                        </div>
                    </div>}
                </CommonFormBorder>
                <CommonFormBorder>
                    <CommonInputRow>
                        <FormFieldLabel className='label-bold' label='Options Details' />
                        <div className="ap-add-icon-container" onClick={() => { setOptionModalShow(true) }}>
                            <img src={require('../../../../../assets/plus.png')} alt="plus" className='img' />
                        </div>
                    </CommonInputRow>
                    {!isEmpty(optionList) && optionList.map((element, index) => (
                        <div className="ap-option-list">
                            <CommonInputRow>
                                <div className="ep-option-with-icon-container">
                                    <div className="ap-option-item-name">{index + 1}. {element.title}</div>
                                    <div className="ep-remove-image-container" onClick={() => { removeCompleteOption(element.title, APP_CONSTANTS.KEY_OPTION) }}>
                                        <img src={require('../../../../../assets/ic_remove_circle.png')} alt="remove" className="img" />
                                    </div>
                                </div>
                                <div className="ap-add-icon-container" onClick={() => {
                                    setSelectedOption(element.title)
                                    setOptionTypeModalShow(true)
                                }}>
                                    <img src={require('../../../../../assets/plus.png')} alt="plus" className='img' />
                                </div>
                            </CommonInputRow>
                            {!isEmpty(element.items) && <div className="ap-option-item-container">
                                {element.items.map(e => (
                                    <div className="ap-option-item">
                                        <div className="ap-option-item-default-container">{e.isDefault === true ? <span className="ap-option-item-default">Default</span> : <span className="ap-option-item-default"> &nbsp;</span>}</div>
                                        <div className="ap-option-item-name">{e.name}</div>
                                        <div className="ap-option-item-description">{!isEmpty(e.description) ? <span>{e.description}</span> : <span>&nbsp;</span>}</div>
                                        <div className="ap-option-item-description">{e.isVeg === true ? <span>Veg</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isNonVeg === true ? <span>Non Veg</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isVegan === true ? <span>Vegan</span> : null}</div>
                                        <div className="ap-option-item-price">{e.price === 0 ? 'Free' : `Price ${e.price}`}</div>
                                        <div className="ep-item-remove-container" onClick={() => {
                                            removeSelectedItem(e.name, element.title, APP_CONSTANTS.KEY_OPTION)
                                        }}>Remove</div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </CommonFormBorder>
                <CommonFormBorder>
                    <CommonInputRow>
                        <FormFieldLabel className='label-bold' label='Add-Ons Details' />
                        <div className="ap-add-icon-container" onClick={() => { setAddOnModalShow(true) }}>
                            <img src={require('../../../../../assets/plus.png')} alt="plus" className='img' />
                        </div>
                    </CommonInputRow>
                    {!isEmpty(addOnList) && addOnList.map((element, index) => (
                        <div className="ap-option-list">
                            <CommonInputRow>
                                <div className="ep-option-with-icon-container">
                                    <div className="ap-option-item-name">{index + 1}. {element.title}</div>
                                    <div className="ep-remove-image-container" onClick={() => { removeCompleteOption(element.title, APP_CONSTANTS.KEY_ADD_ON) }}>
                                        <img src={require('../../../../../assets/ic_remove_circle.png')} alt="remove" className="img" />
                                    </div>
                                </div>
                                <div className="ap-add-icon-container" onClick={() => {
                                    setSelectedAddOn(element.title)
                                    setAddOnTypeModalShow(true)
                                }}>
                                    <img src={require('../../../../../assets/plus.png')} alt="plus" className='img' />
                                </div>
                            </CommonInputRow>
                            {!isEmpty(element.items) && <div className="ap-option-item-container">
                                {element.items.map(e => (
                                    <div className="ap-option-item">
                                        <div className="ap-option-item-name">{e.name}</div>
                                        <div className="ap-option-item-description">{!isEmpty(e.description) ? <span>{e.description}</span> : <span>&nbsp;</span>}</div>
                                        <div className="ap-option-item-description">{e.isVeg === true ? <span>Veg</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isNonVeg === true ? <span>Non Veg</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isVegan === true ? <span>Vegan</span> : null}</div>
                                        <div className="ap-option-item-price">{e.price === 0 ? 'Free' : `Price ${e.price}`}</div>
                                        <div className="ep-item-remove-container" onClick={() => {
                                            removeSelectedItem(e.name, element.title, APP_CONSTANTS.KEY_ADD_ON)
                                        }}>Remove</div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </CommonFormBorder>
                <CommonFormBorder>
                    <FormFieldLabel className='label-bold' label='Other Information' />
                    <FormFieldLabel className='align-left' label='Select Location' />
                    {branchListSuccess && branchListSuccess.data && <Select
                        isMulti={true}
                        className='ep-select ep-select-no-margin'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={branchListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {!branchListSuccess && <div className="p-text">No location found</div>}
                    <FormFieldLabel className='align-left' label='Select Category' />
                    {categoryList && categoryList.data && <Select
                        className='ep-select'
                        value={selectedCat}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={categoryList.data}
                        placeholder='Select Category'
                        onChange={handleSelectedCatChange} />}
                    {!categoryList && <div>No Category found</div>}
                    <FormFieldLabel className='align-left' label='Select Availability' />
                    <div className="ep-input-row ep-input-row-wrap">
                        <div className="ep-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input ep-input-text"
                                id="name"
                                checked={isBreakfast}
                                value={isBreakfast}
                                onChange={() => {
                                    setIsBreakfast(!isBreakfast)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Breakfast</label>
                        </div>
                        <div className="ep-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input ep-input-text"
                                id="name"
                                checked={isLunch}
                                value={isLunch}
                                onChange={() => {
                                    setIsLunch(!isLunch)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Lunch</label>
                        </div>
                        <div className="ep-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input ep-input-text"
                                id="name"
                                checked={isDinner}
                                value={isDinner}
                                onChange={() => {
                                    setIsDinner(!isDinner)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">Dinner</label>
                        </div>
                        <div className="ep-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input ep-input-text"
                                id="name"
                                checked={is24Hour}
                                value={is24Hour}
                                onChange={() => {
                                    setIs24Hour(!is24Hour)
                                }}
                                required />
                            <label for="name" className="ep-checkbox-label">24 Hours</label>
                        </div>
                    </div>
                    <FormFieldLabel className='align-left' label='Select Option Type' />
                    <div className="ap-option-item-container">
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEG} checked={itemVeg} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Vegeterian</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.NON_VEG} checked={itemNonVeg} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Non Vegeterian</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEGAN} checked={itemVegan} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Vegan</span>
                        </label>
                    </div>
                    <FormFieldLabel className='align-left' label='Select Product Image' />
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                </CommonFormBorder>
                <ButtonRow>
                    <CommonButton
                        label='Update'
                        onClick={() => submitHandler()} />
                </ButtonRow>
            </div>
            <Modal
                onHide={() => { setOptionModalShow(!optionModalShow) }}
                show={optionModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Option Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='text'
                        id='optionLabel'
                        placeholder='Option Label'
                        value={optionLabel}
                        onChange={e => setOptionLabel(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleAddOptionClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setOptionTypeModalShow(!optionTypeModalShow) }}
                show={optionTypeModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Option
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-check-box">
                        <input
                            type="checkbox"
                            className="form-check-input p-input-text"
                            id="name"
                            checked={optionTypeDefault}
                            value={optionTypeDefault}
                            onChange={() => {
                                setOptionTypeDefault(!optionTypeDefault)
                            }}
                            required />
                        <label for="name" className="p-checkbox-label">Is Default</label>
                    </div>
                    <CommonInputFull type='text'
                        id='optionName'
                        placeholder='Name'
                        value={optionTypeName}
                        onChange={e => setOptionTypeName(e.target.value)} />
                    <CommonInputFull type='text'
                        id='optionDescription'
                        placeholder='Description'
                        value={optionTypeDescription}
                        onChange={e => setOptionTypeDescription(e.target.value)} />
                    <CommonInputFull type='number'
                        id='optionPrice'
                        placeholder='Price'
                        value={optionTypePrice}
                        onChange={e => setOptionTypePrice(e.target.value)} />
                    <FormFieldLabel className='align-left' label='Select Option Type' />
                    <div className="ap-option-item-container">
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEG} checked={optionTypeVeg} onChange={e => handleOptionTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Vegeterian</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.NON_VEG} checked={optionTypeNonVeg} onChange={e => handleOptionTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Non Vegeterian</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEGAN} checked={optionTypeVegan} onChange={e => handleOptionTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Vegan</span>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleAddOptionDetailClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setAddOnModalShow(!addOnModalShow) }}
                show={addOnModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Add-On Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='text'
                        id='addOnLabel'
                        placeholder='Add-On Label'
                        value={addOnLabel}
                        onChange={e => setAddOnLabel(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleAddAddOnClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setAddOnTypeModalShow(!addOnTypeModalShow) }}
                show={addOnTypeModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Add-On Item
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='text'
                        id='addOnName'
                        placeholder='Name'
                        value={addOnTypeName}
                        onChange={e => setAddOnTypeName(e.target.value)} />
                    <CommonInputFull type='text'
                        id='addOnDescription'
                        placeholder='Description'
                        value={addOnTypeDescription}
                        onChange={e => setAddOnTypeDescription(e.target.value)} />
                    <CommonInputFull type='number'
                        id='addOnPrice'
                        placeholder='Price'
                        value={addOnTypePrice}
                        onChange={e => setAddOnTypePrice(e.target.value)} />
                    <FormFieldLabel className='align-left' label='Select Option Type' />
                    <div className="ap-option-item-container">
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEG} checked={addOnTypeVeg} onChange={e => handleAddOnTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Vegeterian</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.NON_VEG} checked={addOnTypeNonVeg} onChange={e => handleAddOnTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Non Vegeterian</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEGAN} checked={addOnTypeVegan} onChange={e => handleAddOnTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">Vegan</span>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleAddAddOnDetailClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EditMenuItemOperator