import React from "react";
import { APP_CONSTANTS, ORGANISATION_DETAIL, ROUTES } from "../../../utils/AppConstants";
import './styles.css'
import { config } from '../../../config'

const ScreenFooter = ({ navigate }) => {
    const handlePrivacyPolicyClick = () => {
        navigate(ROUTES.privacyPolicy)
    }
    const handleCookiePolicyClick = () => {
        navigate(ROUTES.cookiePolicy)
    }

    const mailToSupportClickHandler = () => {
        window.location = "mailto:support@gomobites.com"
    }

    const handleLiveDemoClick = () => {
        window.open('https://try.gomobites.com/', '_blank')
    }

    const handleContactUsClick = () => {
        navigate(ROUTES.contactUs)
    }

    const handleFeatureRequestClick = () => {
        navigate(ROUTES.featureRequest)
    }

    const handleTermsClick = () => {
        navigate(ROUTES.termsOfService)
    }

    return (<div className="screenFooter">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 colElementItemCenter">
                    <div className="footer-logo-container">
                        <img src={require('../../../assets/NewLogo1.png')} className="img" alt="logo" />
                    </div>
                </div>
                <div className="col-sm-3 colElement">
                    <div className="colElementHeading">Links</div>
                    <div className="h-sub-link-text" onClick={() => { handlePrivacyPolicyClick() }}>Privacy Policy</div>
                    <div className="h-sub-link-text" onClick={() => { handleCookiePolicyClick() }}>Cookie Policy</div>
                    <div className="h-sub-link-text" onClick={() => { handleTermsClick() }}>Terms &amp; Conditions</div>
                </div>
                <div className="col-sm-3 colElement">
                    <div className="colElementHeading">Support</div>
                    {config.environment === APP_CONSTANTS.ENVIRONMENT.PROD ? (<div className="h-sub-link-text" onClick={() => { handleLiveDemoClick() }} >Try Live Demo</div>) : null}
                    <div className="h-sub-link-text" onClick={() => { handleContactUsClick() }} >Contact Us</div>
                    <div className="h-sub-link-text" onClick={() => { handleFeatureRequestClick() }} >Feature Request</div>
                </div>
                <div className="col-sm-3 colElementCenter">
                    <div className="colElementHeadingLogo">Contact</div>
                    <div className="colElement14Label" onClick={() => { mailToSupportClickHandler() }}>support@gomobites.com</div>
                    {/* <div className="colElementHeadingLogoLocation">Location</div> */}
                    {/* <div className="colElement15Label labelBlackBold">{ORGANISATION_DETAIL.ORGANISATION_NAME}</div> */}
                    {/* <div className="colElement14Label">{ORGANISATION_DETAIL.ORG_ADDRESS_LINE1}</div> */}
                    {/* <div className="colElement14Label">{ORGANISATION_DETAIL.ORG_ADDRESS_LINE2}</div> */}
                </div>
            </div>
        </div>
        <div className="credit-container">
            <div>Created In</div>
            <div className="flag-container">
                <img className="img" src={require('../../../assets/united-states.png')} alt="country" />
            </div>
        </div>
        <div className="footerElem footerElem-item">&copy; {new Date().getFullYear()} GoMoBites LLC. All Rights Reserved.</div>
    </div>)
}

export default ScreenFooter