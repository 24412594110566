import React, { useEffect, useState } from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import NavBarComponent from "../../home-page/navbar-component/navBarComponent";
import ScreenFooter from "../../home-page/screen-footer/screenFooter";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    featureRequestListReq, featureRequestUpVoteReq, featureRequestUpVoteReqStatus
} from '../../../redux/feature-request/featureRequest.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import moment from "moment";

const FeatureRequest = props => {
    let navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('')
    const { featureRequestListSuccess, featureRequestListError,
        upVoteReqSuccess, upVoteReqError, upVoteReqStaus } = useSelector(state => state.featureRequest)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        fetchFeatureList()
    }, [])

    const fetchFeatureList = () => {
        setLoading(true)
        const data = {
            productCode: APP_CONSTANTS.PRODUCT_NAME
        }
        dispatch(featureRequestListReq(data))
    }

    useEffect(() => {
        if (isEmpty(searchQuery)) {
            fetchFeatureList()
        }
    }, [searchQuery])

    useEffect(() => {
        if (upVoteReqStaus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(upVoteReqSuccess.message)
            fetchFeatureList()
        } else if (upVoteReqStaus == API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(upVoteReqError.message)
        }

        if (upVoteReqStaus.length) {
            dispatch(featureRequestUpVoteReqStatus(''))
        }
    }, [upVoteReqSuccess, upVoteReqError, upVoteReqStaus])

    useEffect(() => {
        if (!isNull(featureRequestListSuccess)) {
            setLoading(false)
        } else if (featureRequestListError) {
            setLoading(false)
        }
    }, [featureRequestListSuccess, featureRequestListError])

    const handleAddFeatureRequest = () => {
        navigate(ROUTES.addFeatureRequest)
    }

    const onSearchClick = () => {
        if (isEmpty(searchQuery)) {
            displayErrorToast('Search query cannot be blank')
        } else {
            setLoading(true)
            const data = {
                searchQuery: searchQuery,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(featureRequestListReq(data))
        }
    }

    const handleUpVoteClick = element => {
        let callApi = true
        const upVoteStatus = localStorage.getItem('UpVote')
        if (isEmpty(upVoteStatus)) {
            callApi = true
        } else {
            if (upVoteStatus === element.featureRequestId) {
                callApi = false
            } else {
                callApi = true
            }
        }
        if (callApi === true) {
            localStorage.setItem('UpVote', element.featureRequestId)
            setLoading(true)
            const data = {
                featureRequestId: element.featureRequestId
            }
            dispatch(featureRequestUpVoteReq(data))
        } else {
            displayErrorToast('You have already upvoted this feature request')
        }
    }

    const handleAddCommentClick = (element) => {
        navigate(ROUTES.featureRequestDetail, { state: element.featureRequestId })
    }

    return (<>
        <header className="contact-hero">
            <div className="content">
                <div className="contact-label-container">
                    <div className="contact-label">Feature Request</div>
                    <div className="h-line-white" />
                </div>
                <div className="have-question-label">Customer suggestions for GoMoBites.</div>
                <div className="have-question-sub-text">Tell us about your ideas how we can improve GoMoBites for you.</div>
            </div>
        </header>
        <div className="feature-heading-container">
            <div className="need-help-label">Feature Request; <span className="need-help-label-sub">use search field to check if your idea is already listed. If yes add comment with your valuable suggestions or <span className="click-here-label" onClick={() => { handleAddFeatureRequest() }}>Click Here</span> to submit your idea</span></div>
        </div>
        <div className="search-container mb-4">
            <input type="search"
                className="form-control search-bar"
                placeholder='Search'
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)} />
            <div className="search-icon-container" onClick={() => onSearchClick()}>
                <img src={require('../../../assets/ic_search_icon.png')} alt="search" className='img' />
            </div>
        </div>
        {featureRequestListSuccess && featureRequestListSuccess.data && featureRequestListSuccess.data.map(element => (
            <div className="fr-card-container">
                <div className="fr-date-label">{moment(element.createdOn).utc().format('ddd DD, MMM YYYY')}</div>
                <div className="fr-title">{element.title}</div>
                <div className="fr-description">{element.description}</div>
                <div className="fr-count-container">
                    <div className="fr-vote-label">{element.voteCount} Votes</div>
                    <div className="fr-vote-label ms-4">{element.comments.length} Comments</div>
                </div>
                <div className="fr-comment-container">
                    <div className="fr-btn-label" onClick={() => handleUpVoteClick(element)}>Up Vote</div>
                    <div className="fr-btn-label ms-4" onClick={() => handleAddCommentClick(element)}>View Details</div>
                </div>

            </div>
        ))}
        {featureRequestListError && <div className="fr-error-lable">No feature request available. Please submit your idea</div>}
        <ScreenFooter navigate={navigate} />
        <ToastContainer />
        <Loader active={isLoading} text='Please wait...' />
    </>)
}

export default FeatureRequest