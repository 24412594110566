import React from "react";
import './styles.css'

const CarouselCard = ({ image, caption }) => {
    return (
        <div className="carousel-container">
            <div className="carousel-image-container">
                <img src={image} className='img' alt="img" />
            </div>
            <div className="caption-label">{caption}</div>
        </div>
    )
}

export default CarouselCard