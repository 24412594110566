export const OrderInvoiceActionTypes = {
    INV_ADD_ORDER_INVOICE_REQUEST: 'INV_ADD_ORDER_INVOICE_REQUEST',
    INV_ADD_ORDER_INVOICE_REQUEST_SUCCESS: 'INV_ADD_ORDER_INVOICE_REQUEST_SUCCESS',
    INV_ADD_ORDER_INVOICE_REQUEST_ERROR: 'INV_ADD_ORDER_INVOICE_REQUEST_ERROR',
    INV_ADD_ORDER_INVOICE_REQUEST_STATUS: 'INV_ADD_ORDER_INVOICE_REQUEST_STATUS',

    INV_ORDER_INVOICE_LIST_REQUEST: 'INV_ORDER_INVOICE_LIST_REQUEST',
    INV_ORDER_INVOICE_LIST_REQUEST_SUCCESS: 'INV_ORDER_INVOICE_LIST_REQUEST_SUCCESS',
    INV_ORDER_INVOICE_LIST_REQUEST_ERROR: 'INV_ORDER_INVOICE_LIST_REQUEST_ERROR',

    INV_ORDER_INVOICE_DETAIL_REQUEST: 'INV_ORDER_INVOICE_DETAIL_REQUEST',
    INV_ORDER_INVOICE_DETAIL_REQUEST_SUCCESS: 'INV_ORDER_INVOICE_DETAIL_REQUEST_SUCCESS',
    INV_ORDER_INVOICE_DETAIL_REQUEST_ERROR: 'INV_ORDER_INVOICE_DETAIL_REQUEST_ERROR',

    INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST: 'INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST',
    INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_SUCCESS: 'INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_SUCCESS',
    INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_ERROR: 'INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_ERROR',
    INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_STATUS: 'INV_UPDATE_ORDER_INVOICE_PAYMENT_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE'
}