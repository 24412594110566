import { UserActionTypes } from './user.types'

//Login
export const loginUserRequest = data => ({
    type: UserActionTypes.LOGIN_USER_REQUEST,
    payload: data
})

export const loginSuccess = user => ({
    type: UserActionTypes.LOGIN_USER_SUCCESS,
    payload: user
})

export const loginError = data => ({
    type: UserActionTypes.LOGIN_USER_ERROR,
    payload: data
})

//Register
export const registerRequest = data => ({
    type: UserActionTypes.REGISTER_REQUEST,
    payload: data
})

export const registerRequestSuccess = data => ({
    type: UserActionTypes.REGISTER_REQUEST_SUCCESS,
    payload: data
})

export const registerRequestError = data => ({
    type: UserActionTypes.REGISTER_REQUEST_ERROR,
    payload: data
})

export const registerRequestStatus = data => ({
    type: UserActionTypes.REGISTER_REQUEST_STATUS,
    payload: data
})


//Customer login
export const customerLoginRequest = data => ({
    type: UserActionTypes.CUSTOMER_LOGIN_REQUEST,
    payload: data
})

export const customerLoginRequestSuccess = data => ({
    type: UserActionTypes.CUSTOMER_LOGIN_REQUEST_SUCCESS,
    payload: data
})

export const customerLoginRequestError = data => ({
    type: UserActionTypes.CUSTOMER_LOGIN_REQUEST_ERROR,
    payload: data
})

//Subscription list
export const subscriptionListRequest = () => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST
})

export const subscriptionListRequestSuccess = data => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST_SUCCESS,
    payload: data
})

export const subscriptionListRequestError = data => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST_ERROR,
    payload: data
})

//Branch list
export const branchListRequest = data => ({
    type: UserActionTypes.BRANCH_LIST_REQUEST,
    payload: data
})

export const branchListRequestSuccess = data => ({
    type: UserActionTypes.BRANCH_LIST_REQUEST_SUCCESS,
    payload: data
})

export const branchListRequestError = data => ({
    type: UserActionTypes.BRANCH_LIST_REQUEST_ERROR,
    payload: data
})

//User Detail
export const userDetailRequest = data => ({
    type: UserActionTypes.USER_DETAIL_REQUEST,
    payload: data
})

export const userDetailRequestSuccess = data => ({
    type: UserActionTypes.USER_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const userDetailRequestError = data => ({
    type: UserActionTypes.USER_DETAIL_REQUEST_ERROR,
    payload: data
})

//Restuarant List
export const restaurantListRequest = data => ({
    type: UserActionTypes.RESTAURANT_LIST_REQUEST,
    payload: data
})

export const restaurantListRequestSuccess = data => ({
    type: UserActionTypes.RESTAURANT_LIST_REQUEST_SUCCESS,
    payload: data
})

export const restaurantListRequestError = data => ({
    type: UserActionTypes.RESTAURANT_LIST_REQUEST_ERROR,
    payload: data
})

//Add Restaurant
export const addRestaurantRequest = data => ({
    type: UserActionTypes.ADD_RESTAURANT_REQUEST,
    payload: data
})

export const addRestaurantRequestSuccess = data => ({
    type: UserActionTypes.ADD_RESTAURANT_REQUEST_SUCCESS,
    payload: data
})

export const addRestaurantRequestError = data => ({
    type: UserActionTypes.ADD_RESTAURANT_REQUEST_ERROR,
    payload: data
})

export const addRestaurantRequestStatus = data => ({
    type: UserActionTypes.ADD_RESTAURANT_REQUEST_STATUS,
    payload: data
})

//Add branch
export const addBranchRequest = data => ({
    type: UserActionTypes.ADD_BRANCH_REQUEST,
    payload: data
})

export const addBranchRequestSuccess = data => ({
    type: UserActionTypes.ADD_BRANCH_REQUEST_SUCCESS,
    payload: data
})

export const addBranchRequestError = data => ({
    type: UserActionTypes.ADD_BRANCH_REQUEST_ERROR,
    payload: data
})

export const addBranchRequestStatus = data => ({
    type: UserActionTypes.ADD_BRANCH_REQUEST_STATUS,
    payload: data
})

//Update branch
export const updateBranchRequest = data => ({
    type: UserActionTypes.UPDATE_BRANCH_REQUEST,
    payload: data
})

export const updateBranchRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_BRANCH_REQUEST_SUCCESS,
    payload: data
})

export const updateBranchRequestError = data => ({
    type: UserActionTypes.UPDATE_BRANCH_REQUEST_ERROR,
    payload: data
})

export const updateBranchRequestStatus = data => ({
    type: UserActionTypes.UPDATE_BRANCH_REQUEST_STATUS,
    payload: data
})

//Restaurant Detail
export const restaurantDetailRequest = data => ({
    type: UserActionTypes.RESTAURANT_DETAIL_REQUEST,
    payload: data
})

export const restaurantDetailRequestError = data => ({
    type: UserActionTypes.RESTAURANT_DETAIL_REQUEST_ERROR,
    payload: data
})

export const restaurantDetailRequestSuccess = data => ({
    type: UserActionTypes.RESTAURANT_DETAIL_REQUEST_SUCCESS,
    payload: data
})

//Display bottom var
export const displayBottomBarRequest = data => ({
    type: UserActionTypes.DISPLAY_BOTTOM_BAR,
    payload: data
})

//Forgot Password
export const forgotPasswordRequest = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST,
    payload: data
})

export const forgotPasswordRequestSuccess = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload: data
})

export const forgotPasswordRequestError = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_ERROR,
    payload: data
})

export const forgotPasswordRequestStatus = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_STATUS,
    payload: data
})

//Change Password
export const changePasswordRequest = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST,
    payload: data
})

export const changePasswordRequestSuccess = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload: data
})

export const changePasswordRequestError = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_ERROR,
    payload: data
})

export const changePasswordRequestStatus = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_STATUS,
    payload: data
})

//Customer restaurant location
export const customerRestLocRequest = data => ({
    type: UserActionTypes.CUSTOMER_RESTAURANT_LOCATION,
    payload: data
})

//Country List
export const countryListRequest = () => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST
})

export const countryListRequestSuccess = data => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const countryListRequestError = data => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST_ERROR,
    payload: data
})

//State List
export const stateListRequest = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST,
    payload: data
})

export const stateListRequestSuccess = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const stateListRequestError = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST_ERROR,
    payload: data
})

//City List
export const cityListRequest = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST,
    payload: data
})

export const cityListRequestSuccess = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const cityListRequestError = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST_ERROR,
    payload: data
})

//Agent Login
export const agentLoginRequest = data => ({
    type: UserActionTypes.AGENT_LOGIN_REQUEST,
    payload: data
})

export const agentLoginRequestSuccess = data => ({
    type: UserActionTypes.AGENT_LOGIN_REQUEST_SUCCESS,
    payload: data
})

export const agentLoginRequestError = data => ({
    type: UserActionTypes.AGENT_LOGIN_REQUEST_ERROR,
    payload: data
})

//Business list
export const businessListRequest = data => ({
    type: UserActionTypes.BUSINESS_LIST_REQUEST,
    payload: data
})

export const businessListRequestSuccess = data => ({
    type: UserActionTypes.BUSINESS_LIST_REQUEST_SUCCESS,
    payload: data
})

export const businessListRequestError = data => ({
    type: UserActionTypes.BUSINESS_LIST_REQUEST_ERROR,
    payload: data
})

//Login Terminal
export const loginTerminalRequest = data => ({
    type: UserActionTypes.TERMINAL_LOGIN_REQUEST,
    payload: data
})

export const loginTerminalRequestSuccess = data => ({
    type: UserActionTypes.TERMINAL_LOGIN_REQUEST_SUCCESS,
    payload: data
})

export const loginTerminalRequestError = data => ({
    type: UserActionTypes.TERMINAL_LOGIN_REQUEST_ERROR,
    payload: data
})

//Login Employee
export const loginEmployeeRequest = data => ({
    type: UserActionTypes.EMPLOYEE_LOGIN_REQUEST,
    payload: data
})

export const loginEmployeeRequestSuccess = data => ({
    type: UserActionTypes.EMPLOYEE_LOGIN_REQUEST_SUCCESS,
    payload: data
})

export const loginEmployeeRequestError = data => ({
    type: UserActionTypes.EMPLOYEE_LOGIN_REQUEST_ERROR,
    payload: data
})

//Order place success payload
export const orderPlaceSuccessPayload = data => ({
    type: UserActionTypes.ORDER_PLACE_SUCCESS_PAYLOAD,
    payload: data
})

//Employee Restaurant and location selection
export const employeeRestAndLocSelectionPayload = data => ({
    type: UserActionTypes.EMPLOYEE_RESTAURANT_LOCATION_SELECTION,
    payload: data
})

//Support Register
export const supportRegisterRequest = data => ({
    type: UserActionTypes.SUPPORT_REGISTRATION_REQUEST,
    payload: data
})

export const supportRegisterRequestSuccess = data => ({
    type: UserActionTypes.SUPPORT_REGISTRATION_REQUEST_SUCCESS,
    payload: data
})

export const supportRegisterRequestError = data => ({
    type: UserActionTypes.SUPPORT_REGISTRATION_REQUEST_ERROR,
    payload: data
})

export const supportRegisterRequestStatus = data => ({
    type: UserActionTypes.SUPPORT_REGISTRATION_REQUEST_STATUS,
    payload: data
})

export const displayTourAction = data => ({
    type: UserActionTypes.DISPLAY_TOUR,
    payload: data
})

export const ipLocationRequest = data => ({
    type: UserActionTypes.IP_LOCATION_REQUEST,
    payload: data
})

export const ipLocationRequestSuccess = data => ({
    type: UserActionTypes.IP_LOCATION_REQUEST_SUCCESS,
    payload: data
})

export const ipLocationRequestError = data => ({
    type: UserActionTypes.IP_LOCATION_REQUEST_ERROR,
    payload: data
})

//User Register
export const userRegistrationRequest = data => ({
    type: UserActionTypes.USER_REGISTRATION_REQUEST,
    payload: data
})

export const userRegistrationRequestSuccess = data => ({
    type: UserActionTypes.USER_REGISTRATION_REQUEST_SUCCESS,
    payload: data
})

export const userRegistrationRequestError = data => ({
    type: UserActionTypes.USER_REGISTRATION_REQUEST_ERROR,
    payload: data
})

export const displaySideBarAct = data => ({
    type: UserActionTypes.DISPLAY_SIDE_BAR,
    payload: data
})

//User update notification token
export const updateUserNotificationTokenRequest = data => ({
    type: UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST,
    payload: data
})

export const updateUserNotificationTokenRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS,
    payload: data
})

export const updateUserNotificationTokenRequestError = data => ({
    type: UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR,
    payload: data
})

//Restaurant access register request
export const restaurantAccessRegisterRequest = data => ({
    type: UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST,
    payload: data
})

export const restaurantAccessRegisterRequestSuccess = data => ({
    type: UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST_SUCCESS,
    payload: data
})

export const restaurantAccessRegisterRequestError = data => ({
    type: UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST_ERROR,
    payload: data
})

export const restaurantAccessRegisterRequestStatus = data => ({
    type: UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST_STATUS,
    payload: data
})

//branch detail request
export const branchDetailRequest = data => ({
    type: UserActionTypes.BRANCH_DETAIL_REQUEST,
    payload: data
})

export const branchDetailRequestSuccess = data => ({
    type: UserActionTypes.BRANCH_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const branchDetailRequestError = data => ({
    type: UserActionTypes.BRANCH_DETAIL_REQUEST_ERROR,
    payload: data
})

//Operator Registration
export const operatorRegistrationRequest = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST,
    payload: data
})

export const operatorRegistrationRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST_SUCCESS,
    payload: data
})

export const operatorRegistrationRequestError = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST_ERROR,
    payload: data
})

export const operatorRegistrationRequestStatus = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST_STATUS,
    payload: data
})

//Operator List
export const operatorListRequest = () => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST
})

export const operatorListRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST_SUCCESS,
    payload: data
})

export const operatorListRequestError = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST_ERROR,
    payload: data
})

//Operator Detail
export const operatorDetailRequest = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST,
    payload: data
})

export const operatorDetailRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const operatorDetailRequestError = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update Operator Location
export const updateOperatorLocationRequest = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST,
    payload: data
})

export const updateOperatorLocationRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS,
    payload: data
})

export const updateOperatorLocationRequestError = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR,
    payload: data
})

export const updateOperatorLocationRequestStatus = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS,
    payload: data
})

//Business list by location
export const businessListByLocationRequest = data => ({
    type: UserActionTypes.BUSINESS_LIST_BY_LOCATION_REQUEST,
    payload: data
})

export const businessListByLocationRequestSuccess = data => ({
    type: UserActionTypes.BUSINESS_LIST_BY_LOCATION_REQUEST_SUCCESS,
    payload: data
})

export const businessListByLocationRequestError = data => ({
    type: UserActionTypes.BUSINESS_LIST_BY_LOCATION_REQUEST_ERROR,
    payload: data
})

//Update Business ordering status
export const updateBusinessOrderingStatusRequest = data => ({
    type: UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST,
    payload: data
})

export const updateBusinessOrderingStatusRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const updateBusinessOrderingStatusRequestError = data => ({
    type: UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_ERROR,
    payload: data
})

export const updateBusinessOrderingStatusRequestStatus = data => ({
    type: UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_STATUS,
    payload: data
})

//Update Branch ordering status
export const updateBranchOrderingStatusRequest = data => ({
    type: UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST,
    payload: data
})

export const updateBranchOrderingStatusRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const updateBranchOrderingStatusRequestError = data => ({
    type: UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST_ERROR,
    payload: data
})

export const updateBranchOrderingStatusRequestStatus = data => ({
    type: UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST_STATUS,
    payload: data
})

//Register Business user
export const registerBusinessUserRequest = data => ({
    type: UserActionTypes.REGISTER_BUSINESS_USER_REQUEST,
    payload: data
})

export const registerBusinessUserRequestSuccess = data => ({
    type: UserActionTypes.REGISTER_BUSINESS_USER_REQUEST_SUCCESS,
    payload: data
})

export const registerBusinessUserRequestError = data => ({
    type: UserActionTypes.REGISTER_BUSINESS_USER_REQUEST_ERROR,
    payload: data
})

export const registerBusinessUserRequestStatus = data => ({
    type: UserActionTypes.REGISTER_BUSINESS_USER_REQUEST_STATUS,
    payload: data
})

//Map user with business
export const mapUserWithBusinesRequest = data => ({
    type: UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST,
    payload: data
})

export const mapUserWithBusinesRequestSuccess = data => ({
    type: UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST_SUCCESS,
    payload: data
})

export const mapUserWithBusinesRequestError = data => ({
    type: UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST_ERROR,
    payload: data
})

export const mapUserWithBusinesRequestStatus = data => ({
    type: UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST_STATUS,
    payload: data
})

//business user list
export const businesUserListRequest = data => ({
    type: UserActionTypes.BUSINESS_USER_LIST_REQUEST,
    payload: data
})

export const businesUserListRequestSuccess = data => ({
    type: UserActionTypes.BUSINESS_USER_LIST_REQUEST_SUCCESS,
    payload: data
})

export const businesUserListRequestError = data => ({
    type: UserActionTypes.BUSINESS_USER_LIST_REQUEST_ERROR,
    payload: data
})

//Update customer details
export const updateCustomerDetailsRequest = data => ({
    type: UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST,
    payload: data
})

export const updateCustomerDetailsRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const updateCustomerDetailsRequestError = data => ({
    type: UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST_ERROR,
    payload: data
})

export const updateCustomerDetailsRequestStatus = data => ({
    type: UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST_STATUS,
    payload: data
})

export const callWaiterRequest = data => ({
    type: UserActionTypes.CALL_WAITER_REQUEST,
    payload: data
})

//Update Restaurant details
export const updateRestaurantDetailsRequest = data => ({
    type: UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST,
    payload: data
})

export const updateRestaurantDetailsRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const updateRestaurantDetailsRequestError = data => ({
    type: UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST_ERROR,
    payload: data
})

export const updateRestaurantDetailsRequestStatus = data => ({
    type: UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST_STATUS,
    payload: data
})

//Add Subscription
export const addSubscriptionRequest = data => ({
    type: UserActionTypes.ADD_SUBSCRIPTION_REQUEST,
    payload: data
})

export const addSubscriptionRequestSuccess = data => ({
    type: UserActionTypes.ADD_SUBSCRIPTION_REQUEST_SUCCESS,
    payload: data
})

export const addSubscriptionRequestError = data => ({
    type: UserActionTypes.ADD_SUBSCRIPTION_REQUEST_ERROR,
    payload: data
})

export const addSubscriptionRequestStatus = data => ({
    type: UserActionTypes.ADD_SUBSCRIPTION_REQUEST_STATUS,
    payload: data
})