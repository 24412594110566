import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addRequestTicketAPI, requestTicketDetailAPI, requestTicketListAPI,
    updateRequestTicketDetailAPI
} from '../api-requests/requestTicket';
import {
    raiseTicketRequestError, raiseTicketRequestSuccess, requestTicketDetailRequestError,
    requestTicketDetailRequestSuccess, requestTicketListRequestError,
    requestTicketListRequestSuccess, requestTicketUpdateDetailRequestError,
    requestTicketUpdateDetailRequestSuccess
} from './request-ticket.action';
import { RequestTicketActionTypes } from './request-ticket.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleUpdateRequestTicketDetail({ payload }) {
    try {
        const response = yield call(updateRequestTicketDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(requestTicketUpdateDetailRequestSuccess(decryptedData))
            } else {
                yield put(requestTicketUpdateDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(requestTicketUpdateDetailRequestError(error.response.data))
    }
}

export function* requestTicketUpdateDetailReq() {
    yield takeLatest(RequestTicketActionTypes.REQUEST_TICKET_UPDATE_DETAIL_REQUEST, handleUpdateRequestTicketDetail)
}

function* handleRequestTicketList({ payload }) {
    try {
        const response = yield call(requestTicketListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(requestTicketListRequestSuccess(decryptedData))
            } else {
                yield put(requestTicketListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(requestTicketListRequestError(error.response.data))
    }
}

export function* requestTicketListReq() {
    yield takeLatest(RequestTicketActionTypes.REQUEST_TICKET_LIST_REQUEST, handleRequestTicketList)
}

function* handleRequestTicketDetail({ payload }) {
    try {
        const response = yield call(requestTicketDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(requestTicketDetailRequestSuccess(decryptedData))
            } else {
                yield put(requestTicketDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(requestTicketDetailRequestError(error.response.data))
    }
}

export function* requestTicketDetailReq() {
    yield takeLatest(RequestTicketActionTypes.REQUEST_TICKET_DETAIL_REQUEST, handleRequestTicketDetail)
}

function* handleAddRequestTicket({ payload }) {
    try {
        const response = yield call(addRequestTicketAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(raiseTicketRequestSuccess(decryptedData))
            } else {
                yield put(raiseTicketRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(raiseTicketRequestError(error.response.data))
    }
}

export function* addRequestTicketReq() {
    yield takeLatest(RequestTicketActionTypes.RAISE_REQUEST_TICKET_REQUEST, handleAddRequestTicket)
}