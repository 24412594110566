import axios from 'axios'
import { config } from '../../../config'
import { APP_CONSTANTS } from '../../../utils/AppConstants'
import API_ENDPOINTS from '../endpoints'

export function addCategory(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.category.addCategory}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function categoryList(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.category.categoryList}`,
        params: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}
export function updateCategory(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.inventory.category.updateCategory}`,
        data: payload,
        headers: {
            'authToken': user.authToken
        }
    })
}