import { SocketActionTypes } from './socket.types'

//Place order
export const newOrderPlacedNotif = data => ({
    type: SocketActionTypes.NEW_ORDER_PLACED,
    payload: data
})

export const clearNewOrderPlacedNotif = data => ({
    type: SocketActionTypes.CLEAR_NEW_ORDER_DATA,
    payload: data
})

//Call waiter
export const callWaiterRequest = data => ({
    type: SocketActionTypes.CALL_WAITER_REQUEST,
    payload: data
})

export const clearCallWaiterNotif = data => ({
    type: SocketActionTypes.CLEAR_CALL_WAITER_REQUEST,
    payload: data
})

export const collectOrderPaymentNotif = data => ({
    type: SocketActionTypes.COLLECT_ORDER_PAYMENT,
    payload: data
})

export const clearCollectOrderPaymentNotif = data => ({
    type: SocketActionTypes.CLEAR_COLLECT_ORDER_PAYMENT,
    payload: data
})

export const customerPayAtEndPaymentSuccessNotif = data => ({
    type: SocketActionTypes.CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS,
    payload: data
})

export const clearCustomerPayAtEndPaymentSuccessNotif = data => ({
    type: SocketActionTypes.CLEAR_CUSTOMER_PAY_AT_END_PAYMENT_SUCCESS,
    payload: data
})

export const orderStatusUpdateNotif = data => ({
    type: SocketActionTypes.ORDER_STATUS_UPDATE,
    payload: data
})

export const clearOrderStatusNotif = data => ({
    type: SocketActionTypes.CLEAR_ORDER_STATUS_UPDATE,
    payload: data
})

export const callMadeAct = data => ({
    type: SocketActionTypes.CALL_MADE,
    payload: data
})

export const answerMadeAct = data => ({
    type: SocketActionTypes.ANSWER_MADE,
    payload: data
})

export const userCalledAct = data => ({
    type: SocketActionTypes.USER_CALLED,
    payload: data
})

export const callAcceptedAct = data => ({
    type: SocketActionTypes.CALL_ACCEPTED,
    payload: data
})

export const callEndedAct = data => ({
    type: SocketActionTypes.CALL_ENDED,
    payload: data
})

export const makeCallAct = data => ({
    type: SocketActionTypes.MAKE_CALL,
    payload: data
})