import { ContactUsActionTypes } from './contactUs.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addRecordSuccess: null,
    addRecordError: null,
    addRecordReqStatus: '',
    recordListSuccess: null,
    recordListError: null,
    recordDetailSuccess: null,
    recordDetailError: null,
    recordUpdateStatusSuccess: null,
    recordUpdateStatusError: null,
    recordUpdateStatusReqStatus: ''
}

const contactUsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ContactUsActionTypes.CONTACT_US_ADD_REQUEST_SUCCESS:
            return {
                ...state,
                addRecordSuccess: action.payload,
                addRecordError: null,
                addRecordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ContactUsActionTypes.CONTACT_US_ADD_REQUEST_ERROR:
            return {
                ...state,
                addRecordSuccess: null,
                addRecordError: action.payload,
                addRecordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ContactUsActionTypes.CONTACT_US_ADD_REQUEST_STATUS:
            return {
                ...state,
                addRecordReqStatus: ''
            }
        case ContactUsActionTypes.CONTACT_US_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                recordListSuccess: action.payload,
                recordListError: null
            }
        case ContactUsActionTypes.CONTACT_US_LIST_REQUEST_ERROR:
            return {
                ...state,
                recordListSuccess: null,
                recordListError: action.payload
            }
        case ContactUsActionTypes.CONTACT_US_DETAIL_SUCCESS:
            return {
                ...state,
                recordDetailSuccess: action.payload,
                recordDetailError: null
            }
        case ContactUsActionTypes.CONTACT_US_DETAIL_ERROR:
            return {
                ...state,
                recordDetailSuccess: null,
                recordDetailError: action.payload
            }
        case ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                recordUpdateStatusSuccess: action.payload,
                recordUpdateStatusError: null,
                recordUpdateStatusReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST_ERROR:
            return {
                ...state,
                recordUpdateStatusSuccess: null,
                recordUpdateStatusError: action.payload,
                recordUpdateStatusReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST_STATUS:
            return {
                ...state,
                recordUpdateStatusReqStatus: ''
            }
        case ContactUsActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default contactUsReducer