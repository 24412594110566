import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isNull, removeCustomerPayAtEndOrderInfo, screenCaptureAnalytics } from "../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader/loader.component'
import { updateOrderStatusRequest, updateOrderStatusRequestStatus } from '../../../redux/order/order.action'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../SocketCtx'
import { config } from "../../../config";

const OrderPaymentSuccessScreen = props => {
    const dispatch = useDispatch()
    const { orderNumber, orderRestaurantId, orderNumberId, customerLoginSuccess, restaurantDetailSuccess, locationId, restaurantId } = useSelector(state => state.user)
    const { updateOrderSuccess, updateOrderError, updateOrderStatus } = useSelector(state => state.order)
    const [isLoading, setLoading] = useState(false)
    let navigate = useNavigate();
    const socket = useContext(SocketContext)

    useEffect(() => {
        const data = {
            screen: 'CUSTOMER_ORDER_PAYMENT_SUCCESS',
            screenClass: 'CUSTOMER_ORDER_PAYMENT_SUCCESS'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        const apiData = {
            paymentMode: APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE,
            paymentModeName: APP_CONSTANTS.STRIPE,
            isPaid: true
        }
        if (isOnGoingOrder()) {
            apiData.orderId = localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)
        } else {
            apiData.orderId = orderNumberId
            apiData.orderStatus = APP_CONSTANTS.ORDER_STATUS.PLACED
        }
        setLoading(true)
        dispatch(updateOrderStatusRequest(apiData))
        //update order status to placed and transaction status to paid
    }, [])

    const isOnGoingOrder = () => {
        if (!isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_ID)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.SUCCESS_ORDER_BILL_TRANSACTION_ID))) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (updateOrderStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            if (isOnGoingOrder()) {
                removeCustomerPayAtEndOrderInfo()
                displaySuccessToast('Order Payment Successfull')
            } else {
                const data = {
                    orderNumber: orderNumber,
                    restaurantId: orderRestaurantId
                }
                socket.emit('order-placed-success', data)
            }
            setTimeout(() => {
                navigate(`${ROUTES.menuCatList}/${restaurantId}/${locationId}`, { replace: true });
            }, 1000)
        } else if (updateOrderStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
        }

        if (updateOrderStatus.length) {
            dispatch(updateOrderStatusRequestStatus(''))
        }
    }, [updateOrderSuccess, updateOrderError, updateOrderStatus])

    return (
        <>
            <div className="ops-container mb-4">
                <div className="ops-modal-img-container">
                    <img className="add-c-img" src={require('../../../assets/success_icon.png')} alt="success" />
                </div>
                <div className="ops-modal-order-success">Order Payment Success</div>
                <div className="ops-modal-order-subtext">Your order payment is success and order status will be updated by restaurant shortly.</div>
                {isOnGoingOrder() === false && <div className="ops-modal-order-number">Order Number</div>}
                {isOnGoingOrder() === false && <div className="ops-modal-order-number ops-modal-order-number-val">
                    {orderNumber ? orderNumber : ''}
                </div>}
                <div className="ops-modal-order-subtext">Redirecting to menu screen.</div>
                {/* <div className="ops-close-btn" onClick={() => { handleGoHomeClick() }}>Go Home</div> */}

            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OrderPaymentSuccessScreen