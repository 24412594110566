import { FeatureRequestActionTypes } from './featureRequest.types'

//Add feature request
export const addFeatureRequestReq = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST,
    payload: data
})

export const addFeatureRequestReqSuccess = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST_SUCCESS,
    payload: data
})

export const addFeatureRequestReqError = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST_ERROR,
    payload: data
})

export const addFeatureRequestReqStatusUpdate = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_REQUEST_STATUS,
    payload: data
})

//Feature request list
export const featureRequestListReq = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_LIST_REQUEST,
    payload: data
})

export const featureRequestListReqSuccess = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_LIST_REQUEST_SUCCESS,
    payload: data
})

export const featureRequestListReqError = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_LIST_REQUEST_ERROR,
    payload: data
})

//Feature request detail
export const feautreRequestDetailReq = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_DETAIL_REQUEST,
    payload: data
})

export const feautreRequestDetailReqSuccess = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const feautreRequestDetailReqError = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_DETAIL_REQUEST_ERROR,
    payload: data
})

//Feature request add comment
export const featureRequestAddCommentReq = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST,
    payload: data
})

export const featureRequestAddCommentReqSuccess = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST_SUCCESS,
    payload: data
})

export const featureRequestAddCommentReqError = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST_ERROR,
    payload: data
})

export const featureRequestAddCommentReqStatusReq = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_ADD_COMMENT_REQUEST_STATUS,
    payload: data
})

//Feature request upvote
export const featureRequestUpVoteReq = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST,
    payload: data
})

export const featureRequestUpVoteReqSuccess = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST_SUCCESS,
    payload: data
})

export const featureRequestUpVoteReqError = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST_ERROR,
    payload: data
})

export const featureRequestUpVoteReqStatus = data => ({
    type: FeatureRequestActionTypes.FEATURE_REQUEST_UPVOTE_REQUEST_STATUS,
    payload: data
})