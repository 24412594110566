import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDTbhGLlT7h6ixna6jRMAMIitYGkxyfBAw",
    authDomain: "gomobites-web-console.firebaseapp.com",
    projectId: "gomobites-web-console",
    storageBucket: "gomobites-web-console.appspot.com",
    messagingSenderId: "783782446630",
    appId: "1:783782446630:web:8ed9acd39b4ce7d54fc8e7",
    measurementId: "G-5JTJWH756V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });