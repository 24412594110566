import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { invSellerListRequest } from '../../../../redux/inventory/seller/seller.action'
import { addInvOrderInvoiceRequest, addInvOrderInvoiceRequestStatus } from '../../../../redux/inventory/order-invoice/orderInvoice.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import Select from 'react-select'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../../components/sub-text-label/subTextLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonInputHalf from "../../../../components/common-input-half/commonInputHalf";

const InvAddOrderInvoice = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError, employeeSelectedRestaurant,
        employeeSelectedLocation } = useSelector(state => state.user)
    const { invSellerListSuccess, invSellerListError } = useSelector(state => state.invSeller)
    const { invAddOrderInvoiceSuccess, invAddOrderInvoiceError, invAddOrderInvoiceReqStatus } = useSelector(state => state.invOrderInvoice)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [selectedSeller, setSelectedSeller] = useState()
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState()
    const [paymentMode, setPaymentMode] = useState('')
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [billAmount, setBillAmount] = useState('')
    const [billIncludeTax, setBillIncludeTax] = useState(false)
    const [taxPercentage, setTaxPercentage] = useState('')
    const [billFinalAmount, setBillFinalAmount] = useState('')
    const [comments, setComments] = useState('')
    let isFirstTime = false
    const [imageList, setImageList] = useState([])
    const fileInputRef = useRef(null);

    const PAYMENT_STATUS_LIST = [
        { key: 'PAID', value: 'Paid' },
        { key: 'PENDING', value: 'Pending' },
        { key: 'NOT_APPLICABLE', value: 'Not Applicable' },
    ]

    useEffect(() => {
        if (invAddOrderInvoiceReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(invAddOrderInvoiceSuccess.message)
            clearFields()
        } else if (invAddOrderInvoiceReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(invAddOrderInvoiceError.message)
        }
        if (invAddOrderInvoiceReqStatus?.length) {
            dispatch(addInvOrderInvoiceRequestStatus(''))
        }
    }, [invAddOrderInvoiceSuccess, invAddOrderInvoiceError, invAddOrderInvoiceReqStatus])

    const clearFields = () => {
        setPaymentMode('')
        setInvoiceNumber('')
        setBillAmount('')
        setBillIncludeTax(false)
        setTaxPercentage('')
        setBillFinalAmount('')
        setComments('')
        setImageList([])
        if (fileInputRef.current) {
            fileInputRef.current.value = null
        }
    }

    useEffect(() => {
        if (!isNull(invSellerListSuccess)) {
            setLoading(false)
        } else if (!isNull(invSellerListError)) {
            setLoading(false)
        }
    }, [invSellerListSuccess, invSellerListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_ORDER_INVOICE, true)
            isFirstTime = true
            let data1 = {
                userId: user.data.userDetails.userId
            }
            if (isNull(restuarantListSuccess))
                dispatch(restaurantListRequest(data1))
        }
    }, [])

    const fetchSellerList = () => {
        if (isLoggedInUserBusiness(user) === true) {
            if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedRestaurant.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(invSellerListRequest(data))
            } else {
                displayErrorToast('Restaurant and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedRestaurant,
                locationId: employeeSelectedLocation
            }
            dispatch(invSellerListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.INVENTORY.ADD_ORDER_INVOICE) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.INVENTORY.ADD_ORDER_INVOICE, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (isLoggedInUserBusiness(user) === true && !isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            setSelectedLocation(branchListSuccess.data[0])
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchSellerList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedRestaurant) && !isNull(employeeSelectedLocation)) {
            fetchSellerList()
        }
    }, [employeeSelectedRestaurant, employeeSelectedLocation])

    const handleSubmitClick = () => {
        if (isEmpty(invoiceNumber)) {
            displayErrorToast('Invoice number cannot be blank')
        } else if (isLoggedInUserBusiness(user) === true && isNull(selectedRestaurant)) {
            displayErrorToast('Restaurant not selected')
        } else if (isLoggedInUserBusiness(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Restaurant location not selected')
        } else if (isEmpty(selectedSeller)) {
            displayErrorToast('Seller not selected')
        } else if (isEmpty(billAmount)) {
            displayErrorToast('Bill amount cannot be blank')
        } else if (billIncludeTax === true && isEmpty(taxPercentage)) {
            displayErrorToast('Tax percentage cannot be blank')
        } else if (isEmpty(billFinalAmount)) {
            displayErrorToast('Bill final amount cannot be blank')
        } else if (isEmpty(selectedPaymentStatus)) {
            displayErrorToast('Payment status not selected')
        } else if (selectedPaymentStatus.key === 'PAID' && isEmpty(paymentMode)) {
            displayErrorToast('Payment mode cannot be blank')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('orderInvoiceNumber', invoiceNumber)
            fd.append('businessId', isLoggedInUserBusiness(user) === true ? selectedRestaurant.businessId : employeeSelectedRestaurant)
            fd.append('locationId', isLoggedInUserBusiness(user) === true ? selectedLocation.locationId : employeeSelectedLocation)
            fd.append('productCode', user.data.userDetails.productCode)
            fd.append('billAmount', parseFloat(billAmount))
            fd.append('billAmountTaxIncluded', billIncludeTax)
            fd.append('billAmountTaxPercentage', billIncludeTax === true ? taxPercentage : 0)
            fd.append('billAmountFinalPrice', parseFloat(billFinalAmount))
            fd.append('orderInvoicePaymentStatus', selectedPaymentStatus.key)
            if (!isEmpty(paymentMode)) {
                fd.append('orderInvoicePaymentMode', paymentMode)
            }
            fd.append('sellerDetailsId', selectedSeller.invSellerId)
            if (!isEmpty(comments)) {
                fd.append('comments', comments)
            }
            if (imageList.length > 0) {
                imageList.forEach(element => {
                    fd.append('attachments', element)
                })
            }
            dispatch(addInvOrderInvoiceRequest(fd))
        }
    }

    const handleImageChange = (e) => {
        if (!isEmpty(e.target.files)) {
            const files = e.target.files;
            const newSelectedFiles = Array.from(files);
            let currentImageList = imageList
            newSelectedFiles.forEach((file) => {
                currentImageList.push(file)
            });
            setImageList(currentImageList)
        }
    };

    return (<>
        <CommonScreenContent>
            <div className="margin-top-10">
                <ScreenLabel label='Add Order Invoice' />
            </div>
            <CommonFormBorder>
                <SubTextLabel label='Enter details to add new order invoice' />
                <CommonInputFull
                    type='text'
                    id='invoiceNumber'
                    onChange={e => setInvoiceNumber(e.target.value)}
                    placeholder='Invoice Number'
                    value={invoiceNumber}
                />
                {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Restaurant" />}
                {isLoggedInUserBusiness(user) === true && restuarantListSuccess && restuarantListSuccess.data && <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                    }}
                    menuPlacement="auto"
                    className='select-full-width-no-border'
                    value={selectedRestaurant}
                    getOptionLabel={e => e.businessName}
                    getOptionValue={e => e}
                    options={restuarantListSuccess.data}
                    placeholder='Select Restaurant'
                    onChange={e => setSelectedRestaurant(e)} />}
                {isLoggedInUserBusiness(user) === true && !restuarantListSuccess && <div className="content-error">No restaurant found</div>}
                {isLoggedInUserBusiness(user) === true && <FormFieldLabel className='up-bold-label' label="Select Location" />}
                {isLoggedInUserBusiness(user) === true && branchListSuccess && branchListSuccess.data && <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                    }}
                    menuPlacement="auto"
                    className='select-full-width-no-border'
                    value={selectedLocation}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e}
                    options={branchListSuccess.data}
                    placeholder='Select Location'
                    onChange={e => setSelectedLocation(e)} />}
                {isLoggedInUserBusiness(user) === true && !branchListSuccess && <div className="content-error">No location found</div>}
                <FormFieldLabel className='up-bold-label' label="Select Seller" />
                {!isEmpty(invSellerListSuccess) && !isEmpty(invSellerListSuccess.data) && <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                    }}
                    menuPlacement="auto"
                    className='select-full-width-no-border'
                    value={selectedSeller}
                    getOptionLabel={e => e.sellerName}
                    getOptionValue={e => e}
                    options={invSellerListSuccess.data}
                    placeholder='Select Seller'
                    onChange={e => setSelectedSeller(e)} />}
                {!isEmpty(invSellerListError) && <div className="content-error">{invSellerListError.message}</div>}
                <CommonInputFull
                    type='number'
                    id='billAmount'
                    onChange={e => setBillAmount(e.target.value)}
                    placeholder='Bill Amount'
                    value={billAmount}
                    isSubtext={true}
                    subText='Bill amount without tax(if any)'
                />
                <CommonInputRow className='margin-bottom-5'>
                    <div className="iap-row-input">
                        <input
                            type="checkbox"
                            className="form-check-input iap-input-text"
                            id="name"
                            checked={billIncludeTax}
                            value={billIncludeTax}
                            onChange={() => {
                                setBillIncludeTax(!billIncludeTax)
                            }}
                            required />
                        <label for="name" className="iap-checkbox-label">Is Tax Included?</label>
                    </div>
                    {billIncludeTax === true && <CommonInputHalf
                        type='number'
                        id='taxPercentage'
                        onChange={e => setTaxPercentage(e.target.value)}
                        placeholder='Tax Percentage'
                        value={taxPercentage}
                    />}
                </CommonInputRow>
                <CommonInputFull
                    type='number'
                    id='billFinalAmount'
                    onChange={e => setBillFinalAmount(e.target.value)}
                    placeholder='Bill Final Amount'
                    value={billFinalAmount}
                    isSubtext={true}
                    subText='Bill amount including Tax(if any)'
                />
                <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (provided) => ({ ...provided, zIndex: 5 })
                    }}
                    menuPlacement="auto"
                    className='select-full-width-no-border'
                    value={selectedPaymentStatus}
                    getOptionLabel={e => e.value}
                    getOptionValue={e => e}
                    options={PAYMENT_STATUS_LIST}
                    placeholder='Select Payment Status'
                    onChange={e => setSelectedPaymentStatus(e)} />
                <CommonInputFull
                    type='text'
                    id='paymentMode'
                    onChange={e => setPaymentMode(e.target.value)}
                    placeholder='Payment Mode'
                    value={paymentMode}
                    subText='Ex. Cash / Card / Bank Transfer'
                    isSubtext={true}
                />
                <CommonInputFull
                    type='text'
                    id='comments'
                    onChange={e => setComments(e.target.value)}
                    placeholder='Comments'
                    value={comments}
                    multiline={true}
                    rows={2}
                />
                <FormFieldLabel className='up-bold-label' label="Attachments" />
                <div className="input-group margin-bottom-10">
                    <input type="file" multiple ref={fileInputRef} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Select File" onChange={handleImageChange} />
                </div>
                <ButtonRow>
                    <CommonButton label='Submit'
                        onClick={() => { handleSubmitClick() }} />
                </ButtonRow>
            </CommonFormBorder>
        </CommonScreenContent>
        <ToastContainer />
        <Loader active={isLoading} text='Please wait...' />
    </>)
}

export default InvAddOrderInvoice