import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { restaurantListRequest, updateBusinessOrderingStatusRequest, updateBusinessOrderingStatusRequestStatus } from '../../../../redux/user/user.action'
import { useNavigate } from 'react-router-dom';
import { ROUTES, API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { config } from "../../../../config";
import notificationTone from "../../../../assets/notification.mp3"
import { clearNewOrderPlacedNotif, clearCollectOrderPaymentNotif } from '../../../../redux/socket/socket.action'
import moment from "moment";
import TopButton from "../../../../components/button/top-button/topButton";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";

const RestaurantListScreen = props => {
    const { user, restuarantListSuccess, restuarantListError,
        updateBusinessOrderingStatusSuccess, updateBusinessOrderingStatusError, updateBusinessOrderingStatusReqStatus } = useSelector(state => state.user)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { newOrderPlacedNotif, collectOrderPaymentNotif } = useSelector(state => state.socket)

    useEffect(() => {
        if (newOrderPlacedNotif !== null) {
            if (isLoggedInUserBusiness(user)) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === newOrderPlacedNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast("A new order is placed by customer")
                        dispatch(clearNewOrderPlacedNotif(""))
                    }
                }
            }
        }
    }, [newOrderPlacedNotif])

    useEffect(() => {
        if (collectOrderPaymentNotif !== null) {
            if (isLoggedInUserBusiness(user) === true) {
                if (restuarantListSuccess !== null && restuarantListSuccess.data !== null) {
                    const restObj = restuarantListSuccess.data.find(element => element.businessId === collectOrderPaymentNotif.restaurantId)
                    if (restObj !== undefined && restObj !== null) {
                        const audio = new Audio(notificationTone)
                        audio.play()
                        displaySuccessToast(collectOrderPaymentNotif.message)
                        dispatch(clearCollectOrderPaymentNotif(""))
                    }
                }
            }
        }
    }, [collectOrderPaymentNotif])

    useEffect(() => {
        const data = {
            screen: 'RESTAURANT_LIST',
            screenClass: 'RESTAURANT_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        fetchRestaurantList()
    }, [])

    const fetchRestaurantList = () => {
        setLoading(true)
        const data1 = {
            userId: user.data.userDetails.userId
        }
        dispatch(restaurantListRequest(data1))
    }

    useEffect(() => {
        if (updateBusinessOrderingStatusReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateBusinessOrderingStatusSuccess.message)
            fetchRestaurantList()
        } else if (updateBusinessOrderingStatusReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateBusinessOrderingStatusError.message)
        }
        if (updateBusinessOrderingStatusReqStatus?.length) {
            dispatch(updateBusinessOrderingStatusRequestStatus(''))
        }
    }, [updateBusinessOrderingStatusSuccess, updateBusinessOrderingStatusError, updateBusinessOrderingStatusReqStatus])

    useEffect(() => {
        if (!isNull(restuarantListSuccess)) {
            setLoading(false)
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    const handleCardClick = (element) => {

    }

    const addRestaurantClickHandle = (element) => {
        navigate(ROUTES.addRestaurant)
    }

    const handleEnableOrdering = (element) => {
        setLoading(true)
        const data = {
            businessId: element.businessId,
            allowOnlineOrdering: true
        }
        dispatch(updateBusinessOrderingStatusRequest(data))
    }

    const handleDisableOrdering = (element) => {
        setLoading(true)
        const data = {
            businessId: element.businessId,
            allowOnlineOrdering: false
        }
        dispatch(updateBusinessOrderingStatusRequest(data))
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='mt-2'>
                    <ScreenLabel label='Restaurants' />
                    <TopButton label='Add Restaurant'
                        onClick={() => addRestaurantClickHandle()} />
                </CommonInputRow>
                <div className="table-scroll-container">
                    {restuarantListSuccess && restuarantListSuccess.data && <table class="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Brand</th>
                                <th scope="col" className="rl-table-col-title text-center">Online Ordering</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {restuarantListSuccess.data.map(element => (
                                <tr className="rl-table-row-container" onClick={() => { handleCardClick(element) }}>
                                    <td className="rl-table-col-item align-middle">{element.businessName}</td>
                                    <td className="rl-table-col-item text-center align-middle">{element.businessCode}</td>
                                    <td className="rl-table-col-item text-center align-middle">{element.allowOnlineOrdering ? 'Enabled' : 'Not Available'}</td>
                                    {/* <td className="rl-table-col-item text-center">{element.locationList ? element.locationList.length : 0}</td> */}
                                    <td className={`rl-table-col-item text-center align-middle ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item text-center align-middle">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center align-middle">
                                        {!element.allowOnlineOrdering && <div className="resl-btn-container" onClick={() => { handleEnableOrdering(element) }}>Enable Ordering</div>}
                                        {element.allowOnlineOrdering && <div className="resl-btn-container" onClick={() => { handleDisableOrdering(element) }}>Disable Ordering</div>}
                                        
                                        {/* {!element.otherDetails[0].allowOnlineOrdering && <div className="resl-btn-container mt-2">Enable Ordering</div>} */}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {restuarantListError && <div className="content-error">No Restaurant Found</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default RestaurantListScreen