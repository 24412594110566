export const ProductActionTypes = {
    ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
    ADD_PRODUCT_REQUEST_SUCCESS: 'ADD_PRODUCT_REQUEST_SUCCESS',
    ADD_PRODUCT_REQUEST_ERROR: 'ADD_PRODUCT_REQUEST_ERROR',
    ADD_PRODUCT_REQUEST_STATUS: 'ADD_PRODUCT_REQUEST_STATUS',

    PRODUCT_LIST_REQUEST: 'PRODUCT_LIST_REQUEST',
    PRODUCT_LIST_REQUEST_SUCCESS: 'PRODUCT_LIST_REQUEST_SUCCESS',
    PRODUCT_LIST_REQUEST_ERROR: 'PRODUCT_LIST_REQUEST_ERROR',

    PRODUCT_DETAIL_REQUEST: 'PRODUCT_DETAIL_REQUEST',
    PRODUCT_DETAIL_REQUEST_SUCCESS: 'PRODUCT_DETAIL_REQUEST_SUCCESS',
    PRODUCT_DETAIL_REQUEST_ERROR: 'PRODUCT_DETAIL_REQUEST_ERROR',

    PRODUCT_STATUS_UPDATE_REQUEST: 'PRODUCT_STATUS_UPDATE_REQUEST',
    PRODUCT_STATUS_UPDATE_REQUEST_SUCCESS: 'PRODUCT_STATUS_UPDATE_REQUEST_SUCCESS',
    PRODUCT_STATUS_UPDATE_REQUEST_ERROR: 'PRODUCT_STATUS_UPDATE_REQUEST_ERROR',
    PRODUCT_STATUS_UPDATE_REQUEST_STATUS: 'PRODUCT_STATUS_UPDATE_REQUEST_STATUS',

    PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUEST',
    PRODUCT_UPDATE_REQUEST_SUCCESS: 'PRODUCT_UPDATE_REQUEST_SUCCESS',
    PRODUCT_UPDATE_REQUEST_ERROR: 'PRODUCT_UPDATE_REQUEST_ERROR',
    PRODUCT_UPDATE_REQUEST_STATUS: 'PRODUCT_UPDATE_REQUEST_STATUS',

    ADD_FEEDBACK_REQUEST: 'ADD_FEEDBACK_REQUEST',
    ADD_FEEDBACK_REQUEST_SUCCESS: 'ADD_FEEDBACK_REQUEST_SUCCESS',
    ADD_FEEDBACK_REQUEST_ERROR: 'ADD_FEEDBACK_REQUEST_ERROR',
    ADD_FEEDBACK_REQUEST_STATUS: 'ADD_FEEDBACK_REQUEST_STATUS',

    BULK_UPLOAD_MENU_REQUEST: 'BULK_UPLOAD_MENU_REQUEST',
    BULK_UPLOAD_MENU_REQUEST_SUCCESS: 'BULK_UPLOAD_MENU_REQUEST_SUCCESS',
    BULK_UPLOAD_MENU_REQUEST_ERROR: 'BULK_UPLOAD_MENU_REQUEST_ERROR',
    BULK_UPLOAD_MENU_REQUEST_STATUS: 'BULK_UPLOAD_MENU_REQUEST_STATUS',

    CLEAR_DATA: 'CLEAR_DATA',

    LOGOUT: 'LOGOUT',

    CLEAR_GLOBAL_STORE: 'CLEAR_GLOBAL_STORE',

    CLEAR_PRODUCT_DETAIL: 'CLEAR_PRODUCT_DETAIL'
}