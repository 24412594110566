import { OrderActionTypes } from './order.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    orderListSuccess: null,
    orderListError: null,
    orderDetailSuccess: null,
    orderDetailError: null,
    placeOrderSuccess: null,
    placeOrderError: null,
    placeOrderStatus: '',
    updateOrderSuccess: null,
    updateOrderError: null,
    updateOrderStatus: '',
    acceptRejectOrderSuccess: null,
    acceptRejectOrderError: null,
    acceptRejectOrderStatus: '',
    updateTransactionSuccess: null,
    updateTransactionError: null,
    updateTransactionStatus: '',
    dashboardSuccess: null,
    dashboardError: null,
    selectedRestaurantId: null,
    selectedLocationId: null,
    selectedRestaurantCode: null,
    orderGeneratePaymentLinkReqSuccess: null,
    orderGeneratePaymentLinkReqError: null,
    orderGeneratePaymentLinkReqStatus: '',
    updateExistingOrderSuccess: null,
    updateExistingOrderError: null,
    updateExistingOrderReqStatus: '',
    acceptPayAtEndPaymentSuccess: null,
    acceptPayAtEndPaymentError: null,
    acceptPayAtEndPaymentReqStatus: '',
    findPayAtEndOrderSuccess: null,
    findPayAtEndOrderError: null,
    updateCustomerActionSuccess: null,
    updateCustomerActionError: null,
    updateCustomerActionReqStatus: ''
}

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderActionTypes.ORDER_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                orderListSuccess: action.payload,
                orderListError: null
            }
        case OrderActionTypes.ORDER_LIST_REQUEST_ERROR:
            return {
                ...state,
                orderListSuccess: null,
                orderListError: action.payload
            }
        case OrderActionTypes.ORDER_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                orderDetailSuccess: action.payload,
                orderDetailError: null
            }
        case OrderActionTypes.ORDER_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                orderDetailSuccess: null,
                orderDetailError: action.payload
            }
        case OrderActionTypes.PLACE_ORDER_REQUEST_SUCCESS:
            return {
                ...state,
                placeOrderSuccess: action.payload,
                placeOrderError: null,
                placeOrderStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.PLACE_ORDER_REQUEST_ERROR:
            return {
                ...state,
                placeOrderSuccess: null,
                placeOrderError: action.payload,
                placeOrderStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.PLACE_ORDER_REQUEST_STATUS:
            return {
                ...state,
                placeOrderStatus: ''
            }
        case OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST_SUCCESS:
            return {
                ...state,
                updateExistingOrderSuccess: action.payload,
                updateExistingOrderError: null,
                updateExistingOrderReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST_ERROR:
            return {
                ...state,
                updateExistingOrderSuccess: null,
                updateExistingOrderError: action.payload,
                updateExistingOrderReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.UPDATE_EXISTING_ORDER_REQUEST_STATUS:
            return {
                ...state,
                updateExistingOrderReqStatus: ''
            }
        case OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateOrderSuccess: action.payload,
                updateOrderError: null,
                updateOrderStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateOrderSuccess: null,
                updateOrderError: action.payload,
                updateOrderStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.UPDATE_ORDER_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateOrderStatus: ''
            }
        case OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST_SUCCESS:
            return {
                ...state,
                acceptRejectOrderSuccess: action.payload,
                acceptRejectOrderError: null,
                acceptRejectOrderStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST_ERROR:
            return {
                ...state,
                acceptRejectOrderSuccess: null,
                acceptRejectOrderError: action.payload,
                acceptRejectOrderStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.ACCEPT_REJECT_ORDER_REQUEST_STATUS:
            return {
                ...state,
                acceptRejectOrderStatus: ''
            }
        case OrderActionTypes.UPDATE_TRANSACTION_REQUEST_SUCCESS:
            return {
                ...state,
                updateTransactionSuccess: action.payload,
                updateTransactionError: null,
                updateTransactionStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.UPDATE_TRANSACTION_REQUEST_ERROR:
            return {
                ...state,
                updateTransactionSuccess: null,
                updateTransactionError: action.payload,
                updateTransactionStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.UPDATE_TRANSACTION_REQUEST_STATUS:
            return {
                ...state,
                updateTransactionStatus: ''
            }
        case OrderActionTypes.ORDER_DASHBOARD_REQUEST_SUCCESS:
            return {
                ...state,
                dashboardSuccess: action.payload,
                dashboardError: null
            }
        case OrderActionTypes.ORDER_DASHBOARD_REQUEST_ERROR:
            return {
                ...state,
                dashboardSuccess: null,
                dashboardError: action.payload
            }
        case OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                orderGeneratePaymentLinkReqSuccess: action.payload,
                orderGeneratePaymentLinkReqError: null,
                orderGeneratePaymentLinkReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST_ERROR:
            return {
                ...state,
                orderGeneratePaymentLinkReqSuccess: null,
                orderGeneratePaymentLinkReqError: action.payload,
                orderGeneratePaymentLinkReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.ORDER_GENERATE_PAYMENT_LINK_REQUEST_STATUS:
            return {
                ...state,
                orderGeneratePaymentLinkReqStatus: ''
            }
        case OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                acceptPayAtEndPaymentSuccess: action.payload,
                acceptPayAtEndPaymentError: null,
                acceptPayAtEndPaymentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST_ERROR:
            return {
                ...state,
                acceptPayAtEndPaymentSuccess: null,
                acceptPayAtEndPaymentError: action.payload,
                acceptPayAtEndPaymentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.ACCEPT_PAY_AT_END_PAYMENT_REQUEST_STATUS:
            return {
                ...state,
                acceptPayAtEndPaymentReqStatus: ''
            }
        case OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST_SUCCESS:
            return {
                ...state,
                updateCustomerActionSuccess: action.payload,
                updateCustomerActionError: null,
                updateCustomerActionReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST_ERROR:
            return {
                ...state,
                updateCustomerActionSuccess: null,
                updateCustomerActionError: action.payload,
                updateCustomerActionReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OrderActionTypes.UPDATE_CUSTOMER_ACTION_REQUEST_STATUS:
            return {
                ...state,
                updateCustomerActionReqStatus: ''
            }
        case OrderActionTypes.FIND_PAY_AT_END_ORDER_REQUEST_SUCCESS:
            return {
                ...state,
                findPayAtEndOrderSuccess: action.payload,
                findPayAtEndOrderError: null
            }
        case OrderActionTypes.FIND_PAY_AT_END_ORDER_REQUEST_ERROR:
            return {
                ...state,
                findPayAtEndOrderSuccess: null,
                findPayAtEndOrderError: action.payload
            }
        case OrderActionTypes.SELECTED_RESTAURANT_AND_LOC_ID:
            return {
                ...state,
                selectedRestaurantId: action.payload.restaurantId,
                selectedLocationId: action.payload.locationId,
                selectedRestaurantCode: action.payload.selectedRestaurantCode
            }
        case OrderActionTypes.LOGOUT:
            return INITIAL_STATE
        case OrderActionTypes.CUSTOMER_LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default orderReducer