import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, removeSpecialCharAndChangeToPascal, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/common-input-row/commonInputRow";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import { useLocation } from 'react-router-dom';
import FormFieldLabel from "../../../../components/form-field-label/formFieldLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import Modal from 'react-bootstrap/Modal';
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import {
    invOrderInvoiceDetailRequest, updateInvOrderInvoicePaymentRequest, updateInvOrderInvoicePaymentRequestStatus
} from '../../../../redux/inventory/order-invoice/orderInvoice.action'
import moment from "moment";
import Select from 'react-select'
import { config } from "../../../../config";

const InvOrderInvoiceDetail = props => {
    const { invOrderInvoiceDetailSuccess, invOrderInvoiceDetailError, updateInvOrderInvoicePaymentSuccess,
        updateInvOrderInvoicePaymentError, updateInvOrderInvoicePaymentReqStatus } = useSelector(state => state.invOrderInvoice)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { state } = useLocation()
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [selectPaymentStatus, setSelectedPaymentStatus] = useState()
    const [paymentMode, setPaymentMode] = useState('')
    const [comment, setComment] = useState('')
    const PAYMENT_STATUS_LIST = [
        { key: 'PAID', value: 'Paid' },
        { key: 'PENDING', value: 'Pending' },
        { key: 'NOT_APPLICABLE', value: 'Not Applicable' },
    ]
    const [showImageModal, setShowImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() => {
        fetchInvoiceOrderDetails()
    }, [])

    const fetchInvoiceOrderDetails = () => {
        setLoading(true)
        dispatch(invOrderInvoiceDetailRequest(state))
    }

    useEffect(() => {
        if (!isEmpty(invOrderInvoiceDetailSuccess)) {
            setLoading(false)
        } else if (!isEmpty(invOrderInvoiceDetailError)) {
            setLoading(false)
        }
    }, [invOrderInvoiceDetailSuccess, invOrderInvoiceDetailError])

    useEffect(() => {
        if (updateInvOrderInvoicePaymentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateInvOrderInvoicePaymentSuccess.message)
            clearFields()
            fetchInvoiceOrderDetails()
        } else if (updateInvOrderInvoicePaymentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateInvOrderInvoicePaymentError.message)
        }
        if (updateInvOrderInvoicePaymentReqStatus?.length) {
            dispatch(updateInvOrderInvoicePaymentRequestStatus(''))
        }
    }, [updateInvOrderInvoicePaymentSuccess, updateInvOrderInvoicePaymentError, updateInvOrderInvoicePaymentReqStatus])

    const clearFields = () => {
        setSelectedPaymentStatus()
        setPaymentMode('')
        setComment('')
    }

    const handleUpdateSubmitClick = () => {
        if (isEmpty(invOrderInvoiceDetailSuccess)) {
            displayErrorToast('Invoice detail not available. Please try again')
        } else if (isEmpty(invOrderInvoiceDetailSuccess.data)) {
            displayErrorToast('Invoice detail not available. Please try again')
        } else if (isEmpty(invOrderInvoiceDetailSuccess.data.orderInvoicePaymentStatus === 'PAID')) {
            displayErrorToast('Invoice already paid')
        } else if (isEmpty(selectPaymentStatus)) {
            displayErrorToast('Payment status not selected')
        } else if (selectPaymentStatus.key === 'PAID' && isEmpty(paymentMode)) {
            displayErrorToast('Payment mode cannot be blank')
        } else {
            setLoading(true)
            const data = {
                invOrderInvoiceId: invOrderInvoiceDetailSuccess.data.invOrderInvoiceId,
                paymentStatus: selectPaymentStatus.key,
                paymentMode: paymentMode
            }
            if (!isEmpty(comment)) {
                data.comments = comment
            }
            dispatch(updateInvOrderInvoicePaymentRequest(data))
            setShowUpdateModal(false)
        }
    }

    const handleImageClick = (item) => {
        setSelectedImage(item)
        setShowImageModal(true)
    }

    return (<>
        <CommonScreenContent>
            <div className="margin-top-10">
                <ScreenLabel label='Product Details' />
            </div>
            {!isEmpty(invOrderInvoiceDetailSuccess) && !isEmpty(invOrderInvoiceDetailSuccess.data) && <CommonFormBorder>
                <div className="inv-pd-container">
                    <div className="ipd-date-text">{moment(invOrderInvoiceDetailSuccess.createdOn).utc().format('DD MMM YYYY')}</div>
                    <div className="ipd-product-label">{invOrderInvoiceDetailSuccess.data.orderInvoiceNumber}</div>
                    <FormFieldLabel label='Payment Status' />
                    <div className="ipd-product-item-val">{removeSpecialCharAndChangeToPascal(invOrderInvoiceDetailSuccess.data.orderInvoicePaymentStatus)}</div>
                    {!isEmpty(invOrderInvoiceDetailSuccess.data.orderInvoicePaymentMode) && <div>
                        <FormFieldLabel label='Payment Mode' />
                        <div className="ipd-product-item-val">{invOrderInvoiceDetailSuccess.data.orderInvoicePaymentMode}</div>
                    </div>}
                    {!isEmpty(invOrderInvoiceDetailSuccess.data.comments) && <div>
                        <FormFieldLabel label='Comments' />
                        <div className="ipd-product-item-val">{invOrderInvoiceDetailSuccess.data.comments}</div>
                    </div>}
                    <FormFieldLabel className='up-bold-label' label='Bill Information' />
                    <div className="ipd-row-container">
                        <div className="ipd-row-item">Bill Amount</div>
                        <div className="ipd-row-item">{invOrderInvoiceDetailSuccess.data.billAmount}</div>
                    </div>
                    <div className="ipd-row-container">
                        <div className="ipd-row-item">Tax Included</div>
                        <div className="ipd-row-item">{invOrderInvoiceDetailSuccess.data.billAmountTaxIncluded ? 'Yes' : 'No'}</div>
                    </div>
                    {invOrderInvoiceDetailSuccess.data.billAmountTaxIncluded === true && <div className="ipd-row-container">
                        <div className="ipd-row-item">Tax %</div>
                        <div className="ipd-row-item">{invOrderInvoiceDetailSuccess.data.billAmountTaxPercentage}</div>
                    </div>}
                    <div className="ipd-row-container">
                        <div className="ipd-row-item">Final Amount</div>
                        <div className="ipd-row-item">{invOrderInvoiceDetailSuccess.data.billAmountFinalPrice}</div>
                    </div>
                    {!isEmpty(invOrderInvoiceDetailSuccess.data.sellerDetails) && <div>
                        <FormFieldLabel label='Seller Information' className='up-bold-label' />
                        <div className="ipd-product-item-val">{invOrderInvoiceDetailSuccess.data.sellerDetails.sellerName}</div>
                        <div className="ipd-product-item-val">{invOrderInvoiceDetailSuccess.data.sellerDetails.sellerAddress}</div>
                        <div className="ipd-product-item-val">{invOrderInvoiceDetailSuccess.data.sellerDetails.sellerCity} {invOrderInvoiceDetailSuccess.data.sellerDetails.sellerState} {invOrderInvoiceDetailSuccess.data.sellerDetails.sellerCountry}</div>
                    </div>}
                    <FormFieldLabel label='Attahments' className='up-bold-label' />
                    {!isEmpty(invOrderInvoiceDetailSuccess.data.attachments) && <div className="ioid-image-row-container">
                        {invOrderInvoiceDetailSuccess.data.attachments.map(e => (<div className="ioid-image-row-item" onClick={() => { handleImageClick(e) }}>
                            <img src={`${config.imageUrl}${e}`} className="img" />
                        </div>))}
                    </div>}
                    {isEmpty(invOrderInvoiceDetailSuccess.data.attachments) && <div>No attachments found</div>}
                    {invOrderInvoiceDetailSuccess.data.orderInvoicePaymentStatus !== 'PAID' && <ButtonRow isVertical={true}>
                        <CommonButton isBig={true} label='Update Payment Status' onClick={() => { setShowUpdateModal(true) }} />
                    </ButtonRow>}
                </div>
            </CommonFormBorder>}
        </CommonScreenContent>
        <Modal
            onHide={() => { setShowUpdateModal(!showUpdateModal) }}
            show={showUpdateModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Payment Status
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    menuPlacement="auto"
                    className='select-full-width-no-border'
                    value={selectPaymentStatus}
                    getOptionLabel={e => e.value}
                    getOptionValue={e => e}
                    options={PAYMENT_STATUS_LIST}
                    placeholder='Select Payment Status'
                    onChange={e => setSelectedPaymentStatus(e)} />
                <CommonInputFull type='text'
                    id='paymentMode'
                    placeholder='Payment Mode'
                    value={paymentMode}
                    onChange={e => setPaymentMode(e.target.value)} />
                {!isEmpty(invOrderInvoiceDetailSuccess) && !isEmpty(invOrderInvoiceDetailSuccess.data) && isEmpty(invOrderInvoiceDetailSuccess.data.comments) && <CommonInputFull
                    type='text'
                    id='comments'
                    onChange={e => setComment(e.target.value)}
                    placeholder='Comments'
                    value={comment}
                    multiline={true}
                    rows={2}
                />}
            </Modal.Body>
            <Modal.Footer>
                <CommonButton label='Submit'
                    onClick={() => { handleUpdateSubmitClick() }} />
            </Modal.Footer>
        </Modal>
        <Modal
            onHide={() => { setShowImageModal(!showImageModal) }}
            show={showImageModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body>
                {!isNull(selectedImage) && <div className="ioid-image-overlay-container">
                    <img src={`${config.imageUrl}${selectedImage}`} className="img" />
                </div>}
            </Modal.Body>
        </Modal>
        <ToastContainer />
        <Loader active={isLoading} text='Please wait...' />
    </>)
}

export default InvOrderInvoiceDetail