import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { businessListRequest } from '../../../redux/user/user.action'
import moment from "moment";
import { APP_CONSTANTS } from "../../../utils/AppConstants";
import ScreenLabel from "../../../components/screen-label/screenLabel";
import CommonButton from "../../../components/button/common-button/commonButton";
import TopButton from "../../../components/button/top-button/topButton";

const BusinessList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, businessListSuccess, businessListError } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        let data = {
            userId: user.data.userDetails.userId,
            params: {
                agentPhoneNumber: user.data.userDetails.mobile
            }
        }
        setLoading(true)
        dispatch(businessListRequest(data))
    }, [])

    useEffect(() => {
        if (businessListSuccess) {
            setLoading(false)
        } else if (businessListError) {
            setLoading(false)
        }
    }, [businessListSuccess, businessListError])

    const handleLogout = () => {
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }

    return (
        <>
            <div className="bl-container">
                <ScreenLabel label='Restaurant List' />
                <div className="bl-button-container">
                    <TopButton
                        label='Logout'
                        onClick={() => handleLogout()} />
                </div>
                {businessListError &&
                    <div className="bl-erroLabelContainer">
                        <div className="bl-errorLabel">{businessListError.message}</div>
                    </div>
                }
                {businessListSuccess && businessListSuccess.data && <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Email</th>
                                <th scope="col">Business Name</th>
                                <th scope="col">Registration Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                businessListSuccess.data.map(element => (
                                    <tr>
                                        <td>{element.firstName} {element.lastName}</td>
                                        <td>{element.mobile}</td>
                                        <td>{element.email}</td>
                                        <td>{element.businessList.map(e => (<div>{e.businessName}</div>))}</td>
                                        <td>{moment(element.createdOn).utc().format('ddd DD, MMM YYYY')}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default BusinessList