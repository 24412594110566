import React, { useEffect, useState } from 'react'
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { loginEmployeeRequest } from '../../../redux/user/user.action'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import SubTextLabel from '../../../components/sub-text-label/subTextLabel';
import CommonForm from '../../../components/common-form/commonForm';

const EmployeeLoginScreen = props => {
    const [mobile, setMobile] = useState()
    const [isLoading, setLoading] = useState(false)
    const { loginEmployeeSuccess, loginEmployeeError, user, isLogin } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'EMPLOYEE_LOGIN',
            screenClass: 'EMPLOYEE_LOGIN'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('Login successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            navigate(ROUTES.deliveryTerminalList, { replace: true });
        } else {
            if (loginEmployeeError) {
                setLoading(false)
                displayErrorToast(loginEmployeeError.message)
            }
        }
    }, [user, isLogin, loginEmployeeError])

    const handleSignIn = () => {
        if (!isNull(mobile)) {
            setLoading(true)
            const mobileNum = mobile.replace('+', "")
            const data = {
                mobile: Number(mobileNum),
            }
            dispatch(loginEmployeeRequest(data))
        } else {
            displayErrorToast('Mobile number cannot be blank', true)
        }
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    return (
        <>
            <div className='contentAreaEmpLogin'>
                <div className="empl-img-contain" onClick={() => { navigateToHome() }}>
                    {/* <img src={require('../../../assets/GoMoBites.png')} className="empl-img" /> */}
                    <img src={require('../../../assets/NewLogo1.png')} className="empl-img" alt="logo" />
                </div>
                <CommonForm>
                    <SubTextLabel label='Login with your phone number' />
                    <div className="mb-1 empl-input">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <div className='empl-button-row'>
                        <button type='button' onClick={() => handleSignIn()} className='btn empl-button'>Login</button>
                    </div>
                </CommonForm>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeLoginScreen