import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addContactUsRecord, contactUsDetail, contactUsList, updateContactUsStatus
} from '../api-requests/contactUs';
import {
    contactUsAddRecordRequestError, contactUsAddRecordRequestSuccess, contactUsRecordDetailRequestError,
    contactUsRecordDetailRequestSuccess, contactUsRecordListRequestError, contactUsRecordListRequestSuccess,
    contactUsRecordUpdateStatusRequestError, contactUsRecordUpdateStatusRequestSuccess
} from './contactUs.action';
import { ContactUsActionTypes } from './contactUs.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleContactUsUpdateStatus({ payload }) {
    try {
        const response = yield call(updateContactUsStatus, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(contactUsRecordUpdateStatusRequestSuccess(decryptedData))
            } else {
                yield put(contactUsRecordUpdateStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(contactUsRecordUpdateStatusRequestError(error.response.data))
    }
}

export function* contactUsUpdateStatusReq() {
    yield takeLatest(ContactUsActionTypes.CONTACT_US_UPDATE_STATUS_REQUEST, handleContactUsUpdateStatus)
}

function* handleContactUsList({ payload }) {
    try {
        const response = yield call(contactUsList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(contactUsRecordListRequestSuccess(decryptedData))
            } else {
                yield put(contactUsRecordListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(contactUsRecordListRequestError(error.response.data))
    }
}

export function* contactUsListReq() {
    yield takeLatest(ContactUsActionTypes.CONTACT_US_LIST_REQUEST, handleContactUsList)
}

function* handleContactUsDetail({ payload }) {
    try {
        const response = yield call(contactUsDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(contactUsRecordDetailRequestSuccess(decryptedData))
            } else {
                yield put(contactUsRecordDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(contactUsRecordDetailRequestError(error.response.data))
    }
}

export function* contactUsDetailReq() {
    yield takeLatest(ContactUsActionTypes.CONTACT_US_DETAIL, handleContactUsDetail)
}

function* handleContactUsAddRecord({ payload }) {
    try {
        const response = yield call(addContactUsRecord, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(contactUsAddRecordRequestSuccess(decryptedData))
            } else {
                yield put(contactUsAddRecordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(contactUsAddRecordRequestError(error.response.data))
    }
}

export function* contactUsAddRecordReq() {
    yield takeLatest(ContactUsActionTypes.CONTACT_US_ADD_REQUEST, handleContactUsAddRecord)
}