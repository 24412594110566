import { UserActionTypes } from './user.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    user: null,
    isLogin: false,
    loginErrorData: null,
    registerSuccess: null,
    registerError: null,
    registerStatus: '',
    customerLoginSuccess: null,
    customerLoginError: null,
    isCustomerLogin: false,
    subscriptionListSuccess: null,
    subscriptionListError: null,
    branchListSuccess: null,
    branchListError: null,
    userDetailSuccess: null,
    userDetailError: null,
    restuarantListSuccess: null,
    restuarantListError: null,
    addRestaurantSuccess: null,
    addRestaurantError: null,
    addRestaurantStatus: '',
    addBranchSuccess: null,
    addBranchError: null,
    addBranchStatus: '',
    updateBranchSuccess: null,
    updateBranchError: null,
    updateBranchStatus: '',
    restaurantDetailSuccess: null,
    restaurantDetailError: null,
    displayBottomBar: false,
    forgotPasswordSuccess: null,
    forgotPasswordError: null,
    forgotPasswordStatus: '',
    changePasswordSuccess: null,
    changePasswordError: null,
    changePasswordStatus: '',
    restaurantId: null,
    locationId: null,
    countryListSuccess: null,
    countryListError: null,
    stateListSuccess: null,
    stateListError: null,
    cityListSuccess: null,
    cityListError: null,
    agentLoginSuccess: null,
    agentLoginError: null,
    businessListSuccess: null,
    businessListError: null,
    loginTerminalSuccess: null,
    loginTerminalError: null,
    loginEmployeeSuccess: null,
    loginEmployeeError: null,
    orderNumber: '',
    orderRestaurantId: '',
    orderNumberId: '',
    employeeSelectedRestaurant: null,
    employeeSelectedLocation: null,
    displayEmployeeSidebar: false,
    supportRegistrationSuccess: null,
    supportRegistrationError: null,
    supportRegistrationReqStatus: '',
    displayTour: false,
    ipLocationSuccess: null,
    ipLocationError: null,
    displaySideBar: false,
    updateNotificationTokenSuccess: null,
    updateNotificationTokenError: null,
    restaurantRegisterRequestSuccess: null,
    restaurantRegisterRequestError: null,
    restaurantRegisterRequestReqStatus: '',
    branchDetailSuccess: null,
    branchDetailError: null,
    operatorRegistrationSuccess: null,
    operatorRegistrationError: null,
    operatorRegistrationReqStatus: '',
    operatorListSuccess: null,
    operatorListError: null,
    operatorDetailSuccess: null,
    operatorDetailError: null,
    updateOperatorLocationSuccess: null,
    updateOperatorLocationError: null,
    updateOperatorLocationReqStatus: '',
    commonError: null,
    businessListByLocationSuccess: null,
    businessListByLocationError: null,
    updateBusinessOrderingStatusSuccess: null,
    updateBusinessOrderingStatusError: null,
    updateBusinessOrderingStatusReqStatus: '',
    updateBranchOrderingStatusSuccess: null,
    updateBranchOrderingStatusError: null,
    updateBranchOrderingStatusReqStatus: '',
    registerBusinessUserSuccess: null,
    registerBusinessUserError: null,
    registerBusinessUserReqStatus: '',
    mapUserWithBusinessSuccess: null,
    mapUserWithBusinessError: null,
    mapUserWithBusinessReqStatus: '',
    businessUserListSuccess: null,
    businessUserListError: null,
    updateCustomerDetailsSuccess: null,
    updateCustomerDetailsError: null,
    updateCustomerDetailsReqStatus: '',
    showCallWaiterRequest: false,
    updateRestaurantDetailsSuccess: null,
    updateRestaurantDetailsError: null,
    updateRestaurantDetailsReqStatus: '',
    addSubscriptionSuccess: null,
    addSubscriptionError: null,
    addSubscriptionReqStatus: ''
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.LOGIN_USER_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.AGENT_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                agentLoginSuccess: action.payload,
                isLogin: true
            }
        case UserActionTypes.AGENT_LOGIN_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                agentLoginSuccess: null,
                isLogin: false,
                agentLoginError: action.payload
            }
        case UserActionTypes.REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                registerSuccess: action.payload,
                registerError: null,
                registerStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.REGISTER_REQUEST_ERROR:
            return {
                ...state,
                registerSuccess: null,
                registerError: action.payload,
                registerStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.REGISTER_REQUEST_STATUS:
            return {
                ...state,
                registerStatus: ''
            }
        case UserActionTypes.CUSTOMER_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                customerLoginSuccess: action.payload,
                customerLoginError: null,
                isCustomerLogin: true
            }
        case UserActionTypes.CUSTOMER_LOGIN_REQUEST_ERROR:
            return {
                ...state,
                customerLoginSuccess: null,
                customerLoginError: action.payload,
                isCustomerLogin: false
            }
        case UserActionTypes.SUBSCRIPTION_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                subscriptionListSuccess: action.payload,
                subscriptionListError: null
            }
        case UserActionTypes.SUBSCRIPTION_LIST_REQUEST_ERROR:
            return {
                ...state,
                subscriptionListSuccess: null,
                subscriptionListError: action.payload
            }
        case UserActionTypes.BRANCH_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                branchListSuccess: action.payload,
                branchListError: null
            }
        case UserActionTypes.BRANCH_LIST_REQUEST_ERROR:
            return {
                ...state,
                branchListSuccess: null,
                branchListError: action.payload
            }
        case UserActionTypes.USER_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                userDetailSuccess: action.payload,
                userDetailError: null
            }
        case UserActionTypes.USER_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                userDetailSuccess: null,
                userDetailError: action.payload
            }
        case UserActionTypes.RESTAURANT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                restuarantListSuccess: action.payload,
                restuarantListError: null
            }
        case UserActionTypes.RESTAURANT_LIST_REQUEST_ERROR:
            return {
                ...state,
                restuarantListSuccess: null,
                restuarantListError: action.payload
            }
        case UserActionTypes.ADD_RESTAURANT_REQUEST_SUCCESS:
            return {
                ...state,
                addRestaurantSuccess: action.payload,
                addRestaurantError: null,
                addRestaurantStatus: API_CONSTANTS.SUCCESS_STATUS,
                restuarantListSuccess: null,
                restuarantListError: null
            }
        case UserActionTypes.ADD_RESTAURANT_REQUEST_ERROR:
            return {
                ...state,
                addRestaurantSuccess: null,
                addRestaurantError: action.payload,
                addRestaurantStatus: API_CONSTANTS.ERROR_STATUS,
                restuarantListSuccess: null,
                restuarantListError: null
            }
        case UserActionTypes.ADD_RESTAURANT_REQUEST_STATUS:
            return {
                ...state,
                addRestaurantStatus: ''
            }
        case UserActionTypes.ADD_BRANCH_REQUEST_SUCCESS:
            return {
                ...state,
                addBranchSuccess: action.payload,
                addBranchError: null,
                addBranchStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.ADD_BRANCH_REQUEST_ERROR:
            return {
                ...state,
                addBranchSuccess: null,
                addBranchError: action.payload,
                addBranchStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.ADD_BRANCH_REQUEST_STATUS:
            return {
                ...state,
                addBranchStatus: ''
            }
        case UserActionTypes.UPDATE_BRANCH_REQUEST_SUCCESS:
            return {
                ...state,
                updateBranchSuccess: action.payload,
                updateBranchError: null,
                updateBranchStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_BRANCH_REQUEST_ERROR:
            return {
                ...state,
                updateBranchSuccess: null,
                updateBranchError: action.payload,
                updateBranchStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_BRANCH_REQUEST_STATUS:
            return {
                ...state,
                updateBranchStatus: ''
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                forgotPasswordSuccess: action.payload,
                forgotPasswordError: null,
                forgotPasswordStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                forgotPasswordSuccess: null,
                forgotPasswordError: action.payload,
                forgotPasswordStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                forgotPasswordStatus: ''
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                changePasswordSuccess: action.payload,
                changePasswordError: null,
                changePasswordStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                changePasswordSuccess: null,
                changePasswordError: action.payload,
                changePasswordStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                changePasswordStatus: ''
            }
        case UserActionTypes.RESTAURANT_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                restaurantDetailSuccess: action.payload,
                restaurantDetailError: null
            }
        case UserActionTypes.RESTAURANT_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                restaurantDetailSuccess: null,
                restaurantDetailError: action.payload
            }
        case UserActionTypes.DISPLAY_BOTTOM_BAR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: null,
                displayBottomBar: action.payload
            }
        case UserActionTypes.LOGOUT:
            return {
                ...INITIAL_STATE,
                restaurantDetailSuccess: state.restaurantDetailSuccess,
                restaurantDetailError: state.restaurantDetailError,
                displayBottomBar: state.displayBottomBar,
                restaurantId: state.restaurantId,
                locationId: state.locationId,
                branchDetailSuccess: state.branchDetailSuccess,
                branchDetailError: state.branchDetailError,

            }
        case UserActionTypes.CUSTOMER_LOGOUT:
            return {
                ...state,
                customerLoginSuccess: null,
                customerLoginError: null,
                isCustomerLogin: false
            }
        case UserActionTypes.CUSTOMER_RESTAURANT_LOCATION:
            return {
                ...state,
                restaurantId: action.payload.restaurantId,
                locationId: action.payload.locationId
            }
        case UserActionTypes.COUNTRY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                countryListSuccess: action.payload,
                countryListError: null
            }
        case UserActionTypes.COUNTRY_LIST_REQUEST_ERROR:
            return {
                ...state,
                countryListSuccess: null,
                countryListError: action.payload
            }
        case UserActionTypes.STATE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                stateListSuccess: action.payload,
                stateListError: null
            }
        case UserActionTypes.STATE_LIST_REQUEST_ERROR:
            return {
                ...state,
                stateListSuccess: null,
                stateListError: action.payload
            }
        case UserActionTypes.CITY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                cityListSuccess: action.payload,
                cityListError: null
            }
        case UserActionTypes.CITY_LIST_REQUEST_ERROR:
            return {
                ...state,
                cityListSuccess: null,
                cityListError: action.payload
            }
        case UserActionTypes.BUSINESS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                businessListSuccess: action.payload,
                businessListError: null
            }
        case UserActionTypes.BUSINESS_LIST_REQUEST_ERROR:
            return {
                ...state,
                businessListSuccess: null,
                businessListError: action.payload
            }
        case UserActionTypes.RESET_STATE_CITY:
            return {
                ...state,
                stateListSuccess: null,
                stateListError: null,
                cityListSuccess: null,
                cityListError: null,
                countryListSuccess: null,
                countryListError: null
            }
        case UserActionTypes.TERMINAL_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginTerminalSuccess: action.payload,
                loginTerminalError: null
            }
        case UserActionTypes.TERMINAL_LOGIN_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginTerminalSuccess: null,
                loginTerminalError: action.payload
            }
        case UserActionTypes.EMPLOYEE_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginEmployeeSuccess: action.payload,
                loginEmployeeError: null
            }
        case UserActionTypes.EMPLOYEE_LOGIN_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginEmployeeSuccess: null,
                loginEmployeeError: action.payload
            }
        case UserActionTypes.ORDER_PLACE_SUCCESS_PAYLOAD:
            return {
                ...state,
                orderNumber: action.payload.orderNumber,
                orderRestaurantId: action.payload.orderRestaurantId,
                orderNumberId: action.payload.orderNumberId
            }
        case UserActionTypes.EMPLOYEE_RESTAURANT_LOCATION_SELECTION:
            return {
                ...state,
                employeeSelectedRestaurant: action.payload.businessId,
                employeeSelectedLocation: action.payload.locationId,
                displayEmployeeSidebar: true
            }
        case UserActionTypes.SUPPORT_REGISTRATION_REQUEST_SUCCESS:
            return {
                ...state,
                supportRegistrationSuccess: action.payload,
                supportRegistrationError: null,
                supportRegistrationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.SUPPORT_REGISTRATION_REQUEST_ERROR:
            return {
                ...state,
                supportRegistrationSuccess: null,
                supportRegistrationError: action.payload,
                supportRegistrationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.IP_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                ipLocationSuccess: action.payload,
                ipLocationError: null
            }
        case UserActionTypes.IP_LOCATION_REQUEST_ERROR:
            return {
                ...state,
                ipLocationSuccess: null,
                ipLocationError: action.payload
            }
        case UserActionTypes.SUPPORT_REGISTRATION_REQUEST_STATUS:
            return {
                ...state,
                supportRegistrationReqStatus: ''
            }
        case UserActionTypes.DISPLAY_TOUR:
            return {
                ...state,
                displayTour: action.payload
            }
        case UserActionTypes.USER_REGISTRATION_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.USER_REGISTRATION_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.DISPLAY_SIDE_BAR:
            return {
                ...state,
                displaySideBar: action.payload
            }
        case UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                updateNotificationTokenSuccess: action.payload,
                updateNotificationTokenError: null
            }
        case UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR:
            return {
                ...state,
                updateNotificationTokenSuccess: null,
                updateNotificationTokenError: action.payload
            }
        case UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                restaurantRegisterRequestSuccess: action.payload,
                restaurantRegisterRequestError: null,
                restaurantRegisterRequestReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST_ERROR:
            return {
                ...state,
                restaurantRegisterRequestSuccess: null,
                restaurantRegisterRequestError: action.payload,
                restaurantRegisterRequestReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST_STATUS:
            return {
                ...state,
                restaurantRegisterRequestReqStatus: ''
            }
        case UserActionTypes.BRANCH_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                branchDetailSuccess: action.payload,
                branchDetailError: null
            }
        case UserActionTypes.BRANCH_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                branchDetailSuccess: null,
                branchDetailError: action.payload
            }
        case UserActionTypes.OPERATOR_REGISTRATION_REQUEST_SUCCESS:
            return {
                ...state,
                operatorRegistrationSuccess: action.payload,
                operatorRegistrationError: null,
                operatorRegistrationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.OPERATOR_REGISTRATION_REQUEST_ERROR:
            return {
                ...state,
                operatorRegistrationSuccess: null,
                operatorRegistrationError: action.payload,
                operatorRegistrationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.OPERATOR_REGISTRATION_REQUEST_STATUS:
            return {
                ...state,
                operatorRegistrationReqStatus: ''
            }
        case UserActionTypes.OPERATOR_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                operatorListSuccess: action.payload,
                operatorListError: null
            }
        case UserActionTypes.OPERATOR_LIST_REQUEST_ERROR:
            return {
                ...state,
                operatorListSuccess: null,
                operatorListError: action.payload
            }
        case UserActionTypes.OPERATOR_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                operatorDetailSuccess: action.payload,
                operatorDetailError: null
            }
        case UserActionTypes.OPERATOR_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                operatorDetailSuccess: null,
                operatorDetailError: action.payload
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS:
            return {
                ...state,
                updateOperatorLocationSuccess: action.payload,
                updateOperatorLocationError: null,
                updateOperatorLocationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR:
            return {
                ...state,
                updateOperatorLocationSuccess: null,
                updateOperatorLocationError: action.payload,
                updateOperatorLocationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS:
            return {
                ...state,
                updateOperatorLocationReqStatus: ''
            }
        case UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateBusinessOrderingStatusSuccess: action.payload,
                updateBusinessOrderingStatusError: null,
                updateBusinessOrderingStatusReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateBusinessOrderingStatusSuccess: null,
                updateBusinessOrderingStatusError: action.payload,
                updateBusinessOrderingStatusReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateBusinessOrderingStatusReqStatus: ''
            }
        case UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateBranchOrderingStatusSuccess: action.payload,
                updateBranchOrderingStatusError: null,
                updateBranchOrderingStatusReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateBranchOrderingStatusSuccess: null,
                updateBranchOrderingStatusError: action.payload,
                updateBranchOrderingStatusReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateBranchOrderingStatusReqStatus: ''
            }
        case UserActionTypes.REGISTER_BUSINESS_USER_REQUEST_SUCCESS:
            return {
                ...state,
                registerBusinessUserSuccess: action.payload,
                registerBusinessUserError: null,
                registerBusinessUserReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.REGISTER_BUSINESS_USER_REQUEST_ERROR:
            return {
                ...state,
                registerBusinessUserSuccess: null,
                registerBusinessUserError: action.payload,
                registerBusinessUserReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.REGISTER_BUSINESS_USER_REQUEST_STATUS:
            return {
                ...state,
                registerBusinessUserReqStatus: ''
            }
        case UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST_SUCCESS:
            return {
                ...state,
                mapUserWithBusinessSuccess: action.payload,
                mapUserWithBusinessError: null,
                mapUserWithBusinessReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST_ERROR:
            return {
                ...state,
                mapUserWithBusinessSuccess: null,
                mapUserWithBusinessError: action.payload,
                mapUserWithBusinessReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST_STATUS:
            return {
                ...state,
                mapUserWithBusinessReqStatus: ''
            }
        case UserActionTypes.BUSINESS_LIST_BY_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                businessListByLocationSuccess: action.payload,
                businessListByLocationError: null
            }
        case UserActionTypes.BUSINESS_LIST_BY_LOCATION_REQUEST_ERROR:
            return {
                ...state,
                businessListByLocationSuccess: null,
                businessListByLocationError: action.payload
            }
        case UserActionTypes.BUSINESS_USER_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                businessUserListSuccess: action.payload,
                businessUserListError: null
            }
        case UserActionTypes.BUSINESS_USER_LIST_REQUEST_ERROR:
            return {
                ...state,
                businessUserListSuccess: null,
                businessUserListError: action.payload
            }
        case UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                updateCustomerDetailsSuccess: action.payload,
                updateCustomerDetailsError: null,
                updateCustomerDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                updateCustomerDetailsSuccess: null,
                updateCustomerDetailsError: action.payload,
                updateCustomerDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                updateCustomerDetailsReqStatus: ''
            }
        case UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                updateRestaurantDetailsSuccess: action.payload,
                updateRestaurantDetailsError: null,
                updateRestaurantDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                updateRestaurantDetailsSuccess: null,
                updateRestaurantDetailsError: action.payload,
                updateRestaurantDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                updateRestaurantDetailsReqStatus: ''
            }
        case UserActionTypes.COMMON_ERROR:
            return {
                ...state,
                commonError: action.errData
            }
        case UserActionTypes.SHOW_CALL_WAITER_REQUEST:
            return {
                ...state,
                showCallWaiterRequest: action.payload
            }
        case UserActionTypes.ADD_SUBSCRIPTION_REQUEST_SUCCESS:
            return {
                ...state,
                addSubscriptionSuccess: action.payload,
                addSubscriptionError: null,
                addSubscriptionReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.ADD_SUBSCRIPTION_REQUEST_ERROR:
            return {
                ...state,
                addSubscriptionSuccess: null,
                addSubscriptionError: action.payload,
                addSubscriptionReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.ADD_SUBSCRIPTION_REQUEST_STATUS:
            return {
                ...state,
                addSubscriptionReqStatus: ''
            }
        case UserActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default userReducer