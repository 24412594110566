export const OrderActionTypes = {
    PLACE_ORDER_REQUEST: 'PLACE_ORDER_REQUEST',
    PLACE_ORDER_REQUEST_SUCCESS: 'PLACE_ORDER_REQUEST_SUCCESS',
    PLACE_ORDER_REQUEST_ERROR: 'PLACE_ORDER_REQUEST_ERROR',
    PLACE_ORDER_REQUEST_STATUS: 'PLACE_ORDER_REQUEST_STATUS',

    ORDER_LIST_REQUEST: 'ORDER_LIST_REQUEST',
    ORDER_LIST_REQUEST_SUCCESS: 'ORDER_LIST_REQUEST_SUCCESS',
    ORDER_LIST_REQUEST_ERROR: 'ORDER_LIST_REQUEST_ERROR',

    ORDER_DETAIL_REQUEST: 'ORDER_DETAIL_REQUEST',
    ORDER_DETAIL_REQUEST_SUCCESS: 'ORDER_DETAIL_REQUEST_SUCCESS',
    ORDER_DETAIL_REQUEST_ERROR: 'ORDER_DETAIL_REQUEST_ERROR',

    UPDATE_ORDER_STATUS_REQUEST: 'UPDATE_ORDER_STATUS_REQUEST',
    UPDATE_ORDER_STATUS_REQUEST_SUCCESS: 'UPDATE_ORDER_STATUS_REQUEST_SUCCESS',
    UPDATE_ORDER_STATUS_REQUEST_ERROR: 'UPDATE_ORDER_STATUS_REQUEST_ERROR',
    UPDATE_ORDER_STATUS_REQUEST_STATUS: 'UPDATE_ORDER_STATUS_REQUEST_STATUS',

    ACCEPT_REJECT_ORDER_REQUEST: 'ACCEPT_REJECT_ORDER_REQUEST',
    ACCEPT_REJECT_ORDER_REQUEST_SUCCESS: 'ACCEPT_REJECT_ORDER_REQUEST_SUCCESS',
    ACCEPT_REJECT_ORDER_REQUEST_ERROR: 'ACCEPT_REJECT_ORDER_REQUEST_ERROR',
    ACCEPT_REJECT_ORDER_REQUEST_STATUS: 'ACCEPT_REJECT_ORDER_REQUEST_STATUS',

    UPDATE_TRANSACTION_REQUEST: 'UPDATE_TRANSACTION_REQUEST',
    UPDATE_TRANSACTION_REQUEST_SUCCESS: 'UPDATE_TRANSACTION_REQUEST_SUCCESS',
    UPDATE_TRANSACTION_REQUEST_ERROR: 'UPDATE_TRANSACTION_REQUEST_ERROR',
    UPDATE_TRANSACTION_REQUEST_STATUS: 'UPDATE_TRANSACTION_REQUEST_STATUS',

    ORDER_DASHBOARD_REQUEST: 'ORDER_DASHBOARD_REQUEST',
    ORDER_DASHBOARD_REQUEST_SUCCESS: 'ORDER_DASHBOARD_REQUEST_SUCCESS',
    ORDER_DASHBOARD_REQUEST_ERROR: 'ORDER_DASHBOARD_REQUEST_ERROR',

    LOGOUT: 'LOGOUT',
    CUSTOMER_LOGOUT: 'CUSTOMER_LOGOUT',

    SELECTED_RESTAURANT_AND_LOC_ID: 'SELECTED_RESTAURANT_AND_LOC_ID',

    ORDER_GENERATE_PAYMENT_LINK_REQUEST: 'ORDER_GENERATE_PAYMENT_LINK_REQUEST',
    ORDER_GENERATE_PAYMENT_LINK_REQUEST_SUCCESS: 'ORDER_GENERATE_PAYMENT_LINK_REQUEST_SUCCESS',
    ORDER_GENERATE_PAYMENT_LINK_REQUEST_ERROR: 'ORDER_GENERATE_PAYMENT_LINK_REQUEST_ERROR',
    ORDER_GENERATE_PAYMENT_LINK_REQUEST_STATUS: 'ORDER_GENERATE_PAYMENT_LINK_REQUEST_STATUS',

    UPDATE_EXISTING_ORDER_REQUEST: 'UPDATE_EXISTING_ORDER_REQUEST',
    UPDATE_EXISTING_ORDER_REQUEST_SUCCESS: 'UPDATE_EXISTING_ORDER_REQUEST_SUCCESS',
    UPDATE_EXISTING_ORDER_REQUEST_ERROR: 'UPDATE_EXISTING_ORDER_REQUEST_ERROR',
    UPDATE_EXISTING_ORDER_REQUEST_STATUS: 'UPDATE_EXISTING_ORDER_REQUEST_STATUS',

    ACCEPT_PAY_AT_END_PAYMENT_REQUEST: 'ACCEPT_PAY_AT_END_PAYMENT_REQUEST',
    ACCEPT_PAY_AT_END_PAYMENT_REQUEST_SUCCESS: 'ACCEPT_PAY_AT_END_PAYMENT_REQUEST_SUCCESS',
    ACCEPT_PAY_AT_END_PAYMENT_REQUEST_ERROR: 'ACCEPT_PAY_AT_END_PAYMENT_REQUEST_ERROR',
    ACCEPT_PAY_AT_END_PAYMENT_REQUEST_STATUS: 'ACCEPT_PAY_AT_END_PAYMENT_REQUEST_STATUS',

    FIND_PAY_AT_END_ORDER_REQUEST: 'FIND_PAY_AT_END_ORDER_REQUEST',
    FIND_PAY_AT_END_ORDER_REQUEST_SUCCESS: 'FIND_PAY_AT_END_ORDER_REQUEST_SUCCESS',
    FIND_PAY_AT_END_ORDER_REQUEST_ERROR: 'FIND_PAY_AT_END_ORDER_REQUEST_ERROR',

    UPDATE_CUSTOMER_ACTION_REQUEST: 'UPDATE_CUSTOMER_ACTION_REQUEST',
    UPDATE_CUSTOMER_ACTION_REQUEST_SUCCESS: 'UPDATE_CUSTOMER_ACTION_REQUEST_SUCCESS',
    UPDATE_CUSTOMER_ACTION_REQUEST_ERROR: 'UPDATE_CUSTOMER_ACTION_REQUEST_ERROR',
    UPDATE_CUSTOMER_ACTION_REQUEST_STATUS: 'UPDATE_CUSTOMER_ACTION_REQUEST_STATUS'
}