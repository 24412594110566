import React, { useEffect, useState, useContext, useRef } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import {
    terminalListRequest, updateTerminalStatusRequest, updateTerminalStatusRequestStatus
} from '../../../../redux/delivery-terminal/delivery-terminal.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";
import { restaurantListRequest, branchListRequest } from '../../../../redux/user/user.action'
import { makeCallAct } from '../../../../redux/socket/socket.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../../SocketCtx'
import Peer from "simple-peer"
import ScreenLabel from "../../../../components/screen-label/screenLabel";

const DeliveryTerminalListScreen = props => {
    const { user, restuarantListSuccess, restuarantListError,
        branchListSuccess, branchListError } = useSelector(state => state.user)
    const { temrinalListSuccess, temrinalListError, updateTerminalStatusSuccess,
        updateTerminalStatusError, updateTerminalStatusReqStatus } = useSelector(state => state.deliveryTerminal)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const KEY = 'KEY'
    const [selectedRestaurant, setSelectedRestaurant] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let isFirstTime = false
    let navigate = useNavigate();
    const [filteredBranchList, setFilteredBranchList] = useState([])
    const socket = useContext(SocketContext)
    const { callAccepted, makeCall } = useSelector(state => state.socket)
    // const [stream, setStream] = useState()
    // const [callEnded, setCallEnded] = useState(false)
    // const [callAcceptedd, setCallAcceptedd] = useState(false)
    // const myVideo = useRef()
    // const userVideo = useRef()
    // const terminalId = useRef()
    // const connectionRef = useRef()
    // const [audioMuted, setAudioMuted] = useState(false)
    // const [videoMuted, setVideoMuted] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'DELIVERY_TERMINAL_LIST',
            screenClass: 'DELIVERY_TERMINAL_LIST'
        }
        screenCaptureAnalytics(data)
        // navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        //     .then((stream) => {
        //         setStream(stream)
        //         myVideo.current.srcObject = stream
        //     })
    }, [])

    useEffect(() => {
        if (!isNull(temrinalListSuccess)) {
            setLoading(false)
        } else if (!isNull(temrinalListError)) {
            setLoading(false)

        }
    }, [temrinalListSuccess, temrinalListError])

    useEffect(() => {
        sessionStorage.setItem(APP_CONSTANTS.DELIVERY_TERMINAL_LIST, true)
        isFirstTime = true
        let data1 = {}
        if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
            data1.payload = {
                isEmployee: 1,
                employeeId: user.data.userDetails.userId
            }
            data1.userId = user.data.userDetails.userId
        } else {
            data1.userId = user.data.userDetails.userId
        }
        if (isNull(restuarantListSuccess))
            dispatch(restaurantListRequest(data1))
    }, [])

    const fetchTerminalList = () => {
        if (!isNull(selectedRestaurant) && !isNull(selectedLocation)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId,
                locationId: selectedLocation.locationId
            }
            dispatch(terminalListRequest(data))
        } else {
            displayErrorToast('Restaurant and location is not selected')
        }
    }

    useEffect(() => {
        if (!isNull(restuarantListSuccess)) {
            setLoading(false)
            // if (isFirstTime === true && restuarantListSuccess.data.length === 1) {
            if (sessionStorage.getItem(APP_CONSTANTS.DELIVERY_TERMINAL_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.DELIVERY_TERMINAL_LIST, false)
                isFirstTime = false
                setSelectedRestaurant(restuarantListSuccess.data[0])
            }
        } else if (!isNull(restuarantListError)) {
            setLoading(false)
        }
    }, [restuarantListSuccess, restuarantListError])

    useEffect(() => {
        if (!isNull(branchListSuccess)) {
            setLoading(false)
            // if (branchListSuccess.data.length === 1) {
            if (user !== null && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE) {
                let filteredBranchList = branchListSuccess.data.filter(item => user.data.userDetails.employeeDetails.locationId.includes(item.locationId))
                setFilteredBranchList(filteredBranchList)
            } else {
                setSelectedLocation(branchListSuccess.data[0])
            }
            // }
        } else if (!isNull(branchListError)) {
            setLoading(false)
        }
    }, [branchListSuccess, branchListError])

    useEffect(() => {
        if (!isNull(filteredBranchList)) {
            setSelectedLocation(filteredBranchList[0])
        }
    }, [filteredBranchList])

    useEffect(() => {
        if (!isNull(selectedRestaurant)) {
            setLoading(true)
            const data = {
                businessId: selectedRestaurant.businessId
            }
            dispatch(branchListRequest(data))
        }
    }, [selectedRestaurant])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchTerminalList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (updateTerminalStatusReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateTerminalStatusSuccess.message)
            fetchTerminalList()
        }
        if (updateTerminalStatusReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displaySuccessToast(updateTerminalStatusError.message)
        }
        if (updateTerminalStatusReqStatus.length) {
            dispatch(updateTerminalStatusRequestStatus(''))
        }

    }, [updateTerminalStatusSuccess, updateTerminalStatusError, updateTerminalStatusReqStatus])

    const handleCardClick = item => {
        if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS) {
            let data = {
                terminalId: item.terminalId
            }
            if (item.status === 1) {
                data.status = 0
            } else {
                data.status = 1
            }
            setLoading(true)
            dispatch(updateTerminalStatusRequest(data))
        } else {
            if (!isNull(makeCall)) {
                displayErrorToast('You are already in a call.')
            } else {
                dispatch(makeCallAct(item.terminalId))
            }
            //working code
            // const peer = new Peer({
            //     initiator: true,
            //     trickle: false,
            //     stream: stream
            // })
            // connectionRef.current = peer
            // terminalId.current = item.terminalId

            // connectionRef.current.on("signal", (data) => {
            //     console.log('+signal', data);
            //     socket.emit("callUser", {
            //         userToCall: item.terminalId,
            //         signalData: data,
            //         from: user.data.userDetails.employeeId
            //     })
            // })
            // connectionRef.current.on("stream", (stream) => {
            //     console.log('on stream', stream);
            //     userVideo.current.srcObject = stream
            // })
            //end
            // connectionRef.current.on("stream", (stream) => {
            //     console.log('on stream', stream);
            //     userVideo.current.srcObject = stream
            // })
            // socket.on("callAccepted", (signal) => {
            //     setCallAccepted(true)
            //     peer.signal(signal)
            // })


        }
    }

    // useEffect(() => {
    //     if (connectionRef.current) {
    //         connectionRef.current.on("stream", (stream) => {
    //             console.log('on stream', stream);
    //             userVideo.current.srcObject = stream
    //         })
    //     }
    // })

    // useEffect(() => {
    //     if (connectionRef.current) {
    //         console.log(';connectionRef.current', connectionRef.current);
    //         connectionRef.current.on("stream", (stream) => {
    //             console.log('--stream', stream);
    //             userVideo.current.srcObject = stream

    //             // var video = document.querySelector('video')

    //             // if ('srcObject' in video) {
    //             //     video.srcObject = stream
    //             // } else {
    //             //     video.src = window.URL.createObjectURL(stream) // for older browsers
    //             // }

    //             // video.play()
    //         })
    //     }
    // })

    //working code
    // useEffect(() => {
    //     if (!isNull(callAccepted)) {
    //         setCallAcceptedd(true)
    //         console.log('callAccepted.signal', callAccepted.signal);
    //         console.log('connectionRef.current', connectionRef.current);
    //         connectionRef.current.signal(callAccepted.signal)
    //     }
    // }, [callAccepted])

    const handleAddDelTerminalClick = () => {
        navigate(ROUTES.addDeliveryTerminal)
    }

    // const endCall = () => {
    //     connectionRef.current.destroy()
    //     console.log('terminalId.current', terminalId.current);
    //     socket.emit("endCall", {
    //         to: terminalId.current
    //     })
    //     window.location.reload()
    // }

    // const muteAudio = () => {
    //     if (stream) {
    //         setAudioMuted(!audioMuted)
    //         stream.getAudioTracks()[0].enabled = audioMuted
    //     }
    // }

    // function stopWebcam() {
    //     if (stream) {
    //         setVideoMuted(!videoMuted)
    //         stream.getVideoTracks()[0].enabled = videoMuted
    //     }
    // }

    // const shareScreen = () => {
    //     navigator.mediaDevices.getDisplayMedia({ cursor: true })
    //         .then(screenStream => {
    //             connectionRef.current.replaceTrack(stream.getVideoTracks()[0], screenStream.getVideoTracks()[0], stream)
    //             myVideo.current.srcObject = screenStream
    //             screenStream.getTracks()[0].onended = () => {
    //                 connectionRef.current.replaceTrack(screenStream.getVideoTracks()[0], stream.getVideoTracks()[0], stream)
    //                 myVideo.current.srcObject = stream
    //             }
    //         })
    // }

    return (
        <>
            <div className="dtl-list-container">
                <div className="dtl-label-container">
                    <ScreenLabel label='Delivery Terminal' />
                    {user && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS && <button type="button" className="btn add-dtl-btn" onClick={() => { handleAddDelTerminalClick() }}>Add Terminal</button>}
                </div>

                <div className="dtl-select-row-container">
                    <div className="dtl-select-container">
                        <div className="dtl-select-heading">Select Restaurant</div>
                        {restuarantListSuccess && restuarantListSuccess.data && <Select
                            className='dtl-list-select'
                            value={selectedRestaurant}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={restuarantListSuccess.data}
                            placeholder='Select Restaurant'
                            onChange={e => setSelectedRestaurant(e)} />}
                        {!restuarantListSuccess && <div className="p-text">No restaurant found</div>}
                    </div>
                    {user !== null && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS && <div className="dtl-select-container">
                        <div className="dtl-select-heading">Select Location</div>
                        {branchListSuccess && branchListSuccess.data && <Select
                            className='dtl-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={branchListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!branchListSuccess && <div className="p-text">No location found</div>}
                    </div>}
                    {user !== null && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.EMPLOYEE && <div className="dtl-select-container">
                        <div className="dtl-select-heading">Select Location</div>
                        {filteredBranchList && <Select
                            className='dtl-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={filteredBranchList}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {isEmpty(filteredBranchList) && <div className="p-text">No location found</div>}
                    </div>}
                </div>

                {temrinalListSuccess && temrinalListSuccess.data && <table class="table table-striped table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col" className="rl-table-col-title">Name</th>
                            <th scope="col" className="rl-table-col-title text-center">Code</th>
                            <th scope="col" className="rl-table-col-title text-center">Status</th>
                            <th scope="col" className="rl-table-col-title text-center">Date</th>
                            <th scope="col" className="rl-table-col-title text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {temrinalListSuccess.data.map(element => (
                            <tr className="rl-table-row-container" onClick={() => { handleCardClick(element) }}>
                                <td className="rl-table-col-item">{element.title}</td>
                                <td className="rl-table-col-item text-center">{element.terminalCode}</td>
                                <td className="rl-table-col-item text-center">{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                {(user && user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS) ?
                                    (<td className="rl-table-col-item text-center">{element.status === 1 ? 'Mark Disable' : 'Mark Enable'}</td>) :
                                    (<td className="rl-table-col-item text-center">Connect</td>)}
                            </tr>
                        ))}

                    </tbody>
                </table>}
                {/* <div className="dtl-video-container">
                    <div>
                        {stream && <video playsInline ref={myVideo} autoPlay className='dtl-list-video-player' id="local-video" />}
                        {callAcceptedd && !callEnded ?
                            <video playsInline ref={userVideo} autoPlay className='dtl-list-remote-video-player ms-2' id="remote-video" /> :
                            null}
                    </div>
                    {callAcceptedd && !callEnded && <div className="dtl-call-option-row">
                        <div className="dtl-call-option-icon-container" onClick={() => {
                            shareScreen()
                        }}>
                            <img src={require('../../../../assets/startScreenShare.png')} className='dtl-call-option-icon' />
                        </div>
                        {videoMuted === false ?
                            (<div className="dtl-call-option-icon-container" onClick={() => {
                                stopWebcam()
                            }}>
                                <img src={require('../../../../assets/showWebcam.png')} className='dtl-call-option-icon' />
                            </div>) :
                            (<div className="dtl-call-option-icon-container" onClick={() => {
                                stopWebcam()
                            }}>
                                <img src={require('../../../../assets/stopWebcam.png')} className='dtl-call-option-icon' />
                            </div>)}


                        {audioMuted === false ?
                            (<div className="dtl-call-option-icon-container" onClick={() => {
                                muteAudio()
                            }}>
                                <img src={require('../../../../assets/openMic.png')} className='dtl-call-option-icon' />
                            </div>) :
                            (<div className="dtl-call-option-icon-container" onClick={() => {
                                muteAudio()
                            }}>
                                <img src={require('../../../../assets/muteMic.png')} className='dtl-call-option-icon' />
                            </div>)}


                        <div className="dtl-call-option-icon-container" onClick={() => {
                            endCall()
                        }}>
                            <img src={require('../../../../assets/callEnd.png')} className='dtl-call-option-icon' />
                        </div>
                    </div>}
                </div> */}
                {/* {stream && <video playsInline ref={myVideo} autoPlay className='dtl-list-video-player' id="local-video" />} */}
                {/* {callAcceptedd && !callEnded ?
                    <video playsInline ref={userVideo} autoPlay className='dtl-list-remote-video-player' id="remote-video" /> :
                    null} */}
                {temrinalListError && <div>No Delivery Terminal Found</div>}

            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default DeliveryTerminalListScreen