import { ProductActionTypes } from './product.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addProductSuccess: null,
    addProductError: null,
    addProductStatus: '',
    productListSuccess: null,
    productListError: null,
    productDetailSuccess: null,
    productDetailError: null,
    updateProductSuccess: null,
    updateProductError: null,
    updateProductStatus: '',
    productStatusUpdateSuccess: null,
    productStatusUpdateError: null,
    productStatusUpdateStatus: '',
    addFeedbackSuccess: null,
    addFeedbackError: null,
    addFeedbackStatus: '',
    bulkUploadMenuSuccess: null,
    bulkUploadMenuError: null,
    bulkUploadMenuStatus: ''
}

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.ADD_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                addProductSuccess: action.payload,
                addProductError: null,
                addProductStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.ADD_PRODUCT_REQUEST_ERROR:
            return {
                ...state,
                addProductSuccess: null,
                addProductError: action.payload,
                addProductStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.ADD_PRODUCT_REQUEST_STATUS:
            return {
                ...state,
                addProductStatus: ''
            }
        case ProductActionTypes.PRODUCT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                productListSuccess: action.payload,
                productListError: null,
            }
        case ProductActionTypes.PRODUCT_LIST_REQUEST_ERROR:
            return {
                ...state,
                productListSuccess: null,
                productListError: action.payload,
            }
        case ProductActionTypes.PRODUCT_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                productDetailSuccess: action.payload,
                productDetailError: null
            }
        case ProductActionTypes.PRODUCT_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                productDetailSuccess: null,
                productDetailError: action.payload
            }
        case ProductActionTypes.CLEAR_PRODUCT_DETAIL:
            return {
                ...state,
                productDetailSuccess: null,
                productDetailError: null
            }
        case ProductActionTypes.PRODUCT_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                updateProductSuccess: action.payload,
                updateProductError: null,
                updateProductStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.PRODUCT_UPDATE_REQUEST_ERROR:
            return {
                ...state,
                updateProductSuccess: null,
                updateProductError: action.payload,
                updateProductStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.PRODUCT_UPDATE_REQUEST_STATUS:
            return {
                ...state,
                updateProductStatus: ''
            }
        case ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                productStatusUpdateSuccess: action.payload,
                productStatusUpdateError: null,
                productStatusUpdateStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST_ERROR:
            return {
                ...state,
                productStatusUpdateSuccess: null,
                productStatusUpdateError: action.payload,
                productStatusUpdateStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.PRODUCT_STATUS_UPDATE_REQUEST_STATUS:
            return {
                ...state,
                productStatusUpdateStatus: ''
            }
        case ProductActionTypes.ADD_FEEDBACK_REQUEST_SUCCESS:
            return {
                ...state,
                addFeedbackSuccess: action.payload,
                addFeedbackError: null,
                addFeedbackStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.ADD_FEEDBACK_REQUEST_ERROR:
            return {
                ...state,
                addFeedbackSuccess: null,
                addFeedbackError: action.payload,
                addFeedbackStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.ADD_FEEDBACK_REQUEST_STATUS:
            return {
                ...state,
                addFeedbackStatus: ''
            }
        case ProductActionTypes.BULK_UPLOAD_MENU_REQUEST_SUCCESS:
            return {
                ...state,
                bulkUploadMenuSuccess: action.payload,
                bulkUploadMenuError: null,
                bulkUploadMenuStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.BULK_UPLOAD_MENU_REQUEST_ERROR:
            return {
                ...state,
                bulkUploadMenuSuccess: null,
                bulkUploadMenuError: action.payload,
                bulkUploadMenuStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.BULK_UPLOAD_MENU_REQUEST_STATUS:
            return {
                ...state,
                bulkUploadMenuStatus: ''
            }
        case ProductActionTypes.CLEAR_DATA:
            return {
                ...state,
                productListSuccess: null,
                productListError: null,
                productDetailSuccess: null,
                productDetailError: null
            }
        case ProductActionTypes.LOGOUT:
            return {
                ...INITIAL_STATE,
                productListSuccess: state.productListSuccess,
                productListError: state.productListError
            }
        case ProductActionTypes.CLEAR_GLOBAL_STORE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default productReducer