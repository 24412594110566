import React from "react";
import CarouselCard from "../carousel-card/carouselCard";
// import { Carousel } from '3d-react-carousal';
import { Carousel } from '../../../../components/carousel-component/carouselComponent';

const RestaurantFlow = props => {
    let flowSlide = [
        <CarouselCard
            image={require('../../../../assets/img_landing.png')}
            caption='Open GoMoBites and head to Create Account' />,
        <CarouselCard
            image={require('../../../../assets/img_create_account.png')}
            caption='Input your restaurant/food vendor details to register on our platform' />,
        <CarouselCard
            image={require('../../../../assets/img_add_product.png')}
            caption='Create digital menu by inputing menu item details.' />,
        <CarouselCard
            image={require('../../../../assets/img_generate_qr_code.png')}
            caption='Generate and place the QR code on table or restaurant entry for no app menu for consumers.' />,
        <CarouselCard
            image={require('../../../../assets/img_accept_order.png')}
            caption='Confirms order acceptance and acknowledges any special request or comments' />,
        <CarouselCard
            image={require('../../../../assets/img_update_progress.png')}
            caption='Process the order as per pre-existing processes.' />,
        <CarouselCard
            image={require('../../../../assets/img_deliver_order.png')}
            caption='Deliver the order to happy customer' />
    ]
    return (
        <div className="mt-2 mb-2">
            <Carousel slides={flowSlide} autoplay={true} interval={8000} arrows={true} />
        </div>
        // <div id="carouselExampleCaptions" class="carousel carousel-dark slide" data-bs-ride="carousel">
        //     <div class="carousel-indicators">
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
        //         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
        //     </div>
        //     <div class="carousel-inner">
        //         <div class="carousel-item active" data-bs-interval="10000">
        //             <div>
        //                 <div className="d-flex justify-content-center align-items-center">
        //                     <img src={require('../../../../assets/img_landing.png')} alt="logo" />
        //                 </div>
        //                 <div class="carousel-caption d-none d-md-block">
        //                     <p className="light-label">Open GoMoBites and head to Create Account</p>
        //                 </div>
        //             </div>
        //         </div>
        //         <div class="carousel-item" data-bs-interval="2000">
        //             <img src={require('../../../../assets/img_create_account.png')} class="d-block" alt="..." />
        //             <div class="carousel-caption d-none d-md-block">
        //                 <p className="dark-label">Input your restaurant/food vendor details to register on our platform</p>
        //             </div>
        //         </div>
        //         <div class="carousel-item">
        //             <img src={require('../../../../assets/img_add_product.png')} class="d-block" alt="..." />
        //             <div class="carousel-caption d-none d-md-block" >
        //                 <p className="dark-label">Create digital menu by inputing menu item details.</p>
        //             </div>
        //         </div>
        //         <div class="carousel-item">
        //             <img src={require('../../../../assets/img_generate_qr_code.png')} class="d-block" alt="..." />
        //             <div class="carousel-caption d-none d-md-block" >
        //                 <p className="dark-label">Generate and place the QR code on table or restaurant entry for no app menu for consumers.</p>
        //             </div>
        //         </div>
        //         <div class="carousel-item">
        //             <img src={require('../../../../assets/img_accept_order.png')} class="d-block" alt="..." />
        //             <div class="carousel-caption d-none d-md-block" >
        //                 <p className="dark-label">Confirms order acceptance and acknowledges any special request or comments.</p>
        //             </div>
        //         </div>
        //         <div class="carousel-item">
        //             <img src={require('../../../../assets/img_update_progress.png')} class="d-block" alt="..." />
        //             <div class="carousel-caption d-none d-md-block" >
        //                 <p className="dark-label">Process the order as per pre-existing processes.</p>
        //             </div>
        //         </div>
        //         <div class="carousel-item">
        //             <img src={require('../../../../assets/img_deliver_order.png')} class="d-block" alt="..." />
        //             <div class="carousel-caption d-none d-md-block" >
        //                 <p className="dark-label">Deliver the order to happy customer.</p>
        //             </div>
        //         </div>
        //     </div>
        //     <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        //         <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        //         <span class="visually-hidden">Previous</span>
        //     </button>
        //     <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        //         <span class="carousel-control-next-icon" aria-hidden="true"></span>
        //         <span class="visually-hidden">Next</span>
        //     </button>
        // </div>
    )
}

export default RestaurantFlow