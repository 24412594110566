import axios from 'axios'
import API_ENDPOINTS from './endpoints'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import { config } from '../../config'

export function addCategory(formData) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.category.addCategory}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user.authToken
        }
    })
}

export function categoryList(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.category.categoryList}`,
        params: data,
        headers: {
            // 'authToken': user ? user.authToken : config.cid
            'authToken': config.cid
        }
    })
}

export function addSubCategory(formData) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.subCategory.addSubCategory}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user.authToken
        }
    })
}

export function subCategoryList(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.subCategory.subCategoryList}/${data.categoryId}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function addProduct(formData) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.addProduct}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user.authToken
        }
    })
}

export function bulkUploadMenu(formData) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.bulkUploadMenu}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user.authToken
        }
    })
}

export function productList(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.productList}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        },
        params: data
    })
}

export function updateProduct(formData) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.updateProduct}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user.authToken
        }
    })
}

export function updateProductStatus(formData) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.updateProductStatus}`,
        data: formData,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function productDetail(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.productDetail}/${data.itemId}`,
        headers: {
            'authToken': user.authToken
        }
    })
}

export function addFeedback(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.product.addFeedback}`,
        data: data,
        headers: {
            'authToken': user.authToken
        }
    })
}