import axios from 'axios'
import { config } from '../../config'
import API_ENDPOINTS from './endpoints'

export function addContactUsRecord(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.contactUs.addRecord}`,
        data: payload
    })
}

export function contactUsList(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.contactUs.recordList}`,
        params: payload
    })
}

export function contactUsDetail(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.contactUs.recordDetail}/${payload}`
    })
}

export function updateContactUsStatus(payload) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.contactUs.updateStatus}`,
        data: payload
    })
}