import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    contactUsRecordDetailRequest, contactUsRecordUpdateStatusRequest,
    contactUsRecordUpdateStatusRequestStatus
} from '../../../../redux/contactUs/contactUs.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { convertToPascal, displayErrorToast, displaySuccessToast, isEmpty, isNull, removeSpecialCharAndChangeToPascal } from "../../../../utils/Utils";
import { useLocation } from 'react-router-dom';
import ScreenLabel from "../../../../components/screen-label/screenLabel";
import moment from "moment";
import Select from 'react-select'
import CommonInputFull from "../../../../components/common-input-full/commonInputFull";
import { CONTACT_US_STATUS_TYPE } from "../../../../utils/ReservationType";
import CommonButton from "../../../../components/button/common-button/commonButton";

const ContactUsDetail = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { recordDetailSuccess, recordDetailError, recordUpdateStatusSuccess,
        recordUpdateStatusError, recordUpdateStatusReqStatus } = useSelector(state => state.contactUs)
    const { state } = useLocation()

    const [comment, setComment] = useState('')
    const [selectedStatus, setSelectedStatus] = useState()

    useEffect(() => {
        setLoading(true)
        dispatch(contactUsRecordDetailRequest(state))
    }, [])

    useEffect(() => {
        if (!isNull(recordDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(recordDetailError)) {
            setLoading(false)
        }
    }, [recordDetailSuccess, recordDetailError])

    useEffect(() => {
        if (recordUpdateStatusReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            displaySuccessToast(recordUpdateStatusSuccess.message)
            setComment('')
            dispatch(contactUsRecordDetailRequest(state))
        } else if (recordUpdateStatusReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(recordUpdateStatusError.message)
        }
        if (recordUpdateStatusReqStatus.length) {
            dispatch(contactUsRecordUpdateStatusRequestStatus(''))
        }
    }, [recordUpdateStatusSuccess, recordUpdateStatusError, recordUpdateStatusReqStatus])

    const handleSubmitClick = () => {
        let data = {
            recordId: state
        }
        if (!isEmpty(comment)) {
            data.comments = comment
        }
        if (!isNull(selectedStatus)) {
            data.recordStatus = selectedStatus.key
        }
        setLoading(true)
        dispatch(contactUsRecordUpdateStatusRequest(data))

    }

    return (
        <>
            <div className="cud-container">
                <ScreenLabel label='Message Details' />
                {recordDetailSuccess && recordDetailSuccess.data && <div className="mt-4">
                    <div className="cud-row-container">
                        <div className="cud-row-item">Name</div>
                        <div className="cud-row-item">{recordDetailSuccess.data.name}</div>
                    </div>
                    <div className="cud-row-container">
                        <div className="cud-row-item">Mobile</div>
                        <div className="cud-row-item">{recordDetailSuccess.data.mobile}</div>
                    </div>
                    <div className="cud-row-container">
                        <div className="cud-row-item">Status</div>
                        <div className="cud-row-item">{removeSpecialCharAndChangeToPascal(recordDetailSuccess.data.recordStatus)}</div>
                    </div>
                    <div className="cud-row-container">
                        <div className="cud-row-item">Date</div>
                        <div className="cud-row-item">{moment(recordDetailSuccess.data.createdOn).utc().format('DD MMM YYYY')}</div>
                    </div>
                    <div className="cud-field-label">Message</div>
                    <div className="cud-message">{recordDetailSuccess.data.message}</div>
                    <div className="cud-field-label">Comments</div>
                    <div className="cud-message">{!isEmpty(recordDetailSuccess.data.comments) ? recordDetailSuccess.data.comments : '-'}</div>
                    {recordDetailSuccess && recordDetailSuccess.data && recordDetailSuccess.data.recordStatus !== APP_CONSTANTS.CONTACT_US_MESSAGE_STATUS.CLOSED && <div>
                        <div className="add-update-label">Add Updates</div>
                        <CommonInputFull placeholder='Comment'
                            onChange={e => setComment(e.target.value)}
                            type='text'
                            value={comment}
                            id='comment' />
                        <div className="cud-field-label cud-field-label-margin-one">Update Status</div>
                        {CONTACT_US_STATUS_TYPE && <Select
                            className='select-full-width mt-2'
                            value={selectedStatus}
                            getOptionLabel={e => e.value}
                            getOptionValue={e => e}
                            options={CONTACT_US_STATUS_TYPE}
                            placeholder='Select Status'
                            onChange={e => {
                                setSelectedStatus(e);

                            }} />}
                        <div className='edit-loc-button-row'>
                            <CommonButton label='Submit'
                                onClick={() => handleSubmitClick()} />
                        </div>
                    </div>}

                </div>}
                {recordDetailError && <div>{recordDetailError.message}</div>}
            </div>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ContactUsDetail